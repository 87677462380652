<template>
  <div class="text-center">
    <p class="lead">
      Para ver o agregar los documentos necesitas entrar al detalle del usuario
    </p>
    <a :href="clientDocumentUrl(element.client.id)" class="btn btn-primary" target='_blank'>
      Ir a documentos
    </a>
  </div>
</template>
<script>
  export default {
    name: 'card-document',
    props: [
      'element'
    ],
    methods: {
      clientDocumentUrl: function(slug) {
        return `/clients/${slug}#client-documents`
      }
    }
  }
</script>
