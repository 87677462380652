<template>
  <!-- <div class="col-span-8 sm:grid sm:grid-cols-8"> -->
    <div class="row">
      <div class="col" style="height: 600px; overflow-y: auto;">
        <h3> Titular </h3>
        <table class="table-auto table-borderless text-xs text-start">
          <thead>
            <tr>
              <th class="text-base font-bold px-1 py-2">Requisito</th>
              <th class="text-base font-bold px-1 py-2">Estado</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="requisite in this.element.evaluation_holder">
              <td class="px-1 py-2">{{ requisite.requisite_name| }}</td>
              <td class="text-center px-1 py-2"> <evaluation-status-sign :status='requisite.requisite_status' /></td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-for="(guarantor, index) in this.element.evaluation_guarantors" :key="guarantor" class="col" >
        <h3> Aval #{{index+1}} </h3>
        <table class="table-auto table-borderless text-xs text-start">
          <thead>
            <tr>
              <th class="text-base font-bold px-1 py-2">Requisito</th>
              <th class="text-base font-bold px-1 py-2">Estado</th>
            </tr>
          </thead>
          <tbody >
            <tr v-for="(status, requisite) in guarantor['guarantor']" :key="requisite">
              <td class="px-1 py-2">{{ status['requisite_name'] }}</td>
              <td class="text-center px-1 py-2"><evaluation-status-sign :status="status['requisite_status']" /></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  <!-- </div> -->
</template>
<script>
  import EvaluationStatusSign from './EvaluationStatusSign.vue';
  export default {
    name: 'evaluation-requisites',
    component: {EvaluationStatusSign},
    props: [
      'element'
    ]
  }

</script>
