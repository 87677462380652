<template>
  <div class="relative">
    <button class="btn dropdown-toggle btn-link text-decoration-none second-button dropdown-custom dropdown-icon-custom">
      <div class="flex">
        <div class="color-circle" aria-label="Circle" v-bind:style="{ background: color }"></div>
        <span class="mip-5" aria-label="Color">{{ color }}</span>
      </div>
    </button>
    <input class="color-picker" type="color" v-model="color">
  </div>
</template>
<script>
export default {
  name: 'color-picker',
  props: {
    onChange: Function,
    colorSelected: {
      type: String,
      default: undefined
    }
  },
  data() {
    return {
      color: '#000',
    }
  },
  watch: {
    color() {
      this.$emit('onChange', {color: this.color})
    },
    colorSelected() {
      if(this?.colorSelected)
        this.color = this.colorSelected
    }
  },
}
</script>
<style>
.color-picker {
  width: 100%;
  height: 40px;
  position: absolute;
  top: 0px;
  opacity: 0;
}

.color-circle {
  width: 15px;
  height: 15px;
  border-radius: 50px;
  cursor: pointer;
}
</style>
