<template>
  <div>
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-sm-12">
            <b-button
              block
              variant="success"
              @click="saveSimulation"
              size="lg"
              :disabled="loading"
            >
              <b-spinner v-if="loading" />
              <span v-else>Guardar</span>
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import commercialExecutiveButtonMixin from '../mixins/commercialExecutiveCalculator'
import apiSimulation from '../../api/simulations'

export default {
  name: 'commercial-executive-button',
  mixins: [commercialExecutiveButtonMixin],
  data () {
    return {
      loading: false
    }
  },
  props: [
    'pipedrivePersonId',
    'pipedriveDealId',
    'client',
    'simulationId',
  ],
  methods: {
    saveSimulation: async function() {
      this.loading = true
      const response = await apiSimulation.update(this.simulationId, { status: 'unconfirmed', client_id: this.client.id, pipedrive_id: this.pipedriveDealId })

      this.loading = false
      // @TODO: Add error/success message
      window.location.href = `${window.location.origin}/simulations/${response.id}`
    }
  }
}
</script>
