import I18n from 'i18n-js'

  export const tableFields = [
    {
      key: 'period',
      label: '#',
    },
    {
      key: 'outstanding_balance',
      label: 'Saldo insoluto',
    },
    {
      key: 'capital',
      label: 'Capital',
    },
    {
      key: 'interest_paid',
      label: 'Intereses pagados',
    },
    {
      key: 'vat',
      label: 'Valor IVA'
    },
    {
      key: 'installment_value',
      label: 'Valor cuota',
    },
    {
      key: 'days',
      label: 'Días'
    },
    {
      key: 'interest_rate_per_month',
      label: 'Tasa interés',
    },
    {
      key: 'due_date',
      label: 'Vencimiento',
    },
  ]

export const paymentDaysDropDownList = [
  { value: 1, text: '1'},
  { value: 5, text: '5'},
  { value: 10, text: '10'},
  { value: 15, text: '15'},
  { value: 18, text: '18'},
  { value: 20, text: '20'},
]

export const creditTypeDropDownList = [
  { value: 'reprogram', text: 'Reprogramación' },
  { value: 'normal', text: 'Crédito' },
  { value: 'leasing', text: 'Leasing' }
]

export const paymentFrecuencyDropDownList = [
  { value: 'weekly', text: 'Semanal' },
  { value: 'byweekly', text: 'Quincenal' },
  { value: 'monthly', text: 'Mensual' }
]

export const insuranceTranslations = {
  bike_insurance: 'bikeInsurance',
  liability_insurance: 'liabilityInsurance',
  lien_insurance: 'lienInsurance',
  lien_insurance_dealer: 'lienInsuranceDealer',
  panels_lien_insurance: 'panelsLienInsurance',
  panels_50_lien_insurance: 'panels50LienInsurance',
  life_insurance: 'lifeInsurance',
  taxes: 'stampDuty',
  planuf3_insurance: 'planUf3Insurance',
  planuf6_insurance: 'planUf6Insurance',
  fogape: 'fogape',
  pledge_agreement: 'pledgeAgreement',
  severance_insurance: 'severanceInsurance',
  prohibition: 'prohibition',
  green_tax: 'greenTax',
  circulation_permit: 'circulationPermit',
  warranty: 'warranty',
  minor_repairs: 'minorRepairs',
  total_loss: 'totalLoss',
  digital_signature: 'digitalSignature'
}

export const GUARANTORS_COUNT = [
  {id: null, name: I18n.t('simulator.credit_simulator.credit_type.select_guarantor'), disabled: true},
  {id: 0, name: 'Sin Aval'},
  {id: 1, name: '1'},
  {id: 2, name: '2'},
  {id: 3, name: '3'},
  {id: 4, name: '4'}
]

export const FIXED_LEASING_INSTALLMENTS_COUNT = 52
export const FIXED_CREDIT_INSTALLMENTS_COUNT = 12
export const DEFAULT_MONTHS_FOR_LIEN_INSURANCE = 12
export const UNEMPLOYMENT_PLANUF_3_INSURANCE_RATE = 0.26
export const UNEMPLOYMENT_PLANUF_6_INSURANCE_RATE = 0.46
