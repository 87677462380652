<template>
  <div class="col-span-8 sm:grid sm:grid-cols-8">
    <div class="col-span-8" style="height: 600px; overflow-y: auto;">
      <table class="table-auto text-xs text-center">
        <thead>
          <tr>
            <th class="px-1 py-2">#</th>
            <th class="px-1 py-2">Saldo insoluto</th>
            <th class="px-1 py-2">Capital</th>
            <th class="px-1 py-2">Intereses pagados</th>
            <th class="px-1 py-2">Valor IVA</th>
            <th class="px-1 py-2">Valor cuota</th>
            <th class="px-1 py-2">Días</th>
            <th class="px-1 py-2">Tasa interés</th>
            <th class="px-1 py-2">Vencimiento</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(installment, index) in this.element.installments" :key="installment.id">
            <td class="border px-1 py-2">{{ installment.period }}</td>
            <td class="border px-1 py-2">{{ installment.outstanding_balance | currency('$', 0, { thousandsSeparator: '.', decimalSeparator: ',' }) }}</td>
            <td class="border px-1 py-2">{{ installment.capital  | currency('$', 0, { thousandsSeparator: '.', decimalSeparator: ',' }) }}</td>
            <td class="border px-1 py-2">{{ installment.interest_paid  | currency('$', 0, { thousandsSeparator: '.', decimalSeparator: ',' })}}</td>
            <td class="border px-1 py-2">{{ installment.vat  | currency('$', 0, { thousandsSeparator: '.', decimalSeparator: ',' }) }}</td>
            <td class="border px-1 py-2">{{ installment.installment_value | currency('$', 0, { thousandsSeparator: '.', decimalSeparator: ',' })}}</td>
            <td class="border px-1 py-2">{{ installment.days }}</td>
            <td class="border px-1 py-2">{{ installment.interest_rate_per_month }}</td>
            <td class="border px-1 py-2">{{ installment.due_date }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'card-payment-table',
    props: [
      'element'
    ]
  }
</script>
