import axios from 'axios'
import { config } from '../constants/api'

export default {
  async getDownPaymentStatus(id) {
    const path = `/down_payment_details/status/${id}.json`

    return await axios.get(path, config)
      .then(res => res.data)
      .catch((error) => (error.response))
  },

  async bulkUpdateStatuses(params) {
    const path = `/down_payment_details/bulk_payment.json`

    return await axios.put(path, params, config)
      .then(res => res)
      .catch((error) => (error.response))
  },

  async receive(params) {
    const path = `/down_payment_details/receive.json`

    return await axios.post(path, params, config)
      .then(res => res)
      .catch((error) => (error.response))
  },

  async updateStatus(downPaymentIds, transition) {
    const path = `/down_payment_details/update_status.json`
    const params = { ids: downPaymentIds, transition: transition }
    return await axios.post(path, params, config)
      .then((res) => res)
      .catch((error) => error.response)
  },
}
