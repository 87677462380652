<template>
  <div>
    <b-alert v-if="showAlert" class="ml-3 mr-3" show :variant="alertVariant">{{alertText}}</b-alert>

    <box :title="title" md="10" sm="12">
      <template slot="content">
        <div class="col-md-10 col-sm-12">
          <div v-if="this.isEditting" class="row">
            <div>
              <p v-if="inventory_item.credit_display_code" class="associated-link-container" >{{ t('inventory_item.associated_op') }} <a :href="`/credits/${inventory_item.credit_display_code}`" > {{ inventory_item.credit_display_code }} </a> </p>
              <p v-else class="not-associated-link-container" > {{ t('inventory_item.associated_without_op') }} </p>
            </div>
            <div>
              <p v-if="inventory_item.simulation_id" class="associated-link-container" > {{ t('inventory_item.associated_sim') }} <a :href="`/simulations/${inventory_item.simulation_id}`" >{{ inventory_item.simulation_id }} </a> </p>
              <p v-else class="not-associated-link-container" > {{ t('inventory_item.associated_without_sim') }} </p>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-2 m-0 p-0">

              <b-form-group label="Producto" aria-describedby="inventory-type-id-live-feedback" :state="errors.inventory_type_id">
                <b-form-radio
                  :id="`inventory_type_id-${getUUID4()}`"
                  v-for="option in inventoryTypesOptions" :key="option.value"
                  v-model="inventory_item.inventory_type_id"
                  name="inventory_item.inventory_type_id"
                  :value="option.value"
                  aria-describedby="inventory-type-id-live-feedback"
                  class="form-check-input ml-1"
                  :state="errors.inventory_type_id"
                >
                  {{option.text}}
                </b-form-radio>
              </b-form-group>

              <b-form-invalid-feedback id="inventory-type-id-live-feedback">
                Es requerido
              </b-form-invalid-feedback>

              <span class="text-danger" v-if="errors.inventory_type_id && typeof errors.inventory_type_id === 'string'">{{errors.inventory_type_id}}</span>
            </div>

            <div class="col-3">
              <b-form-group label="Destino" aria-describedby="operation-intent-live-feedback" :state="errors.inventory_type_id">
                <b-form-radio
                  :disabled="isReadOnly()"
                  :id="`operation_intent-${getUUID4()}`"
                  v-for="option in operationIntentOptions" :key="option.value"
                  v-model="inventory_item.operation_intent"
                  name="inventory_item.operation_intent"
                  :value="option.value"
                  aria-describedby="operation-intent-live-feedback"
                  class="form-check-input ml-1"
                  :state="errors.operation_intent"
                >
                  {{option.text}}
                </b-form-radio>
              </b-form-group>

              <b-form-invalid-feedback id="operation-intent-live-feedback">
                Es requerido
              </b-form-invalid-feedback>

              <span class="text-danger" v-if="errors.operation_intent && typeof errors.operation_intent === 'string'">{{errors.operation_intent}}</span>
            </div>

          </div>

          <div class="row flex-column mb-2">
            <div class="card">
              <div class="card-body m-3 p-0">
                <div class="card-title d-flex mb-0 color-gray">
                  <b-form-checkbox
                    id="is_notificable"
                    name="checkbox-is_notificable"
                    v-model="inventory_item.is_notificable"
                    :unchecked-value="false"
                    :value="true"
                    size="lg"
                    switch
                  ></b-form-checkbox>

                  <strong>{{ t('inventory_item.is_notificable_text') }}</strong>
                  <b-button v-b-tooltip.hover.top="t('inventory_item.is_notificable_tooltip')" class="p-0" variant="link" size="sm">
                    <i class="fas fa-info-circle"   ></i>
                  </b-button >
                </div>
              </div>
            </div>
          </div>


          <div class="row flex-column mb-2">
            <div :class="`${specificFieldsOpen ? 'active': 'inactive'} collapsible-item`" >

              <div @click="toggleSpecificFieldsOpen" class="collapsible-button" >
                <div class="d-flex" >
                  <div v-show="specificFieldsOk" class="text-success mr-2" > <i class="fas fa-check-circle"></i> </div>
                  <div v-show="!specificFieldsOk" :class="`${specificFieldsOpen ? 'dot-active' : 'dot-inactive'}`">1</div>
                  <strong> Datos producto y especificaciones</strong>
                </div>

                <span v-show="specificFieldsOpen">
                  <i class="fas fa-chevron-up"></i>
                </span>

                <span v-show="!specificFieldsOpen" >
                  <i class="fas fa-chevron-down"></i>
                </span>
              </div>

              <b-collapse v-model="specificFieldsOpen" id="collapse-1" class="mt-2">
                <hr>
                <div class="row col">

                  <div class="col-4">
                    <b-form-group
                      label="Número de inventario"
                      label-class=""
                    >
                      <b-form-input :readonly="isReadOnly()" type="text" v-model="inventory_item.inventory_number"></b-form-input>
                      <span class="text-danger" v-if="errors.inventory_number && typeof errors.inventory_number === 'string'">{{errors.inventory_number}}</span>
                    </b-form-group>
                  </div>

                  <div class="col-4">
                    <b-form-group
                      label="Estado logístico"
                      label-class=""
                      aria-describedby="warehouse-status-live-feedback"
                    >

                      <b-form-select
                        :disabled="isReadOnly()"
                        :id="`warehouse_status-${this.getUUID4()}`"
                        aria-describedby="warehouse-status-live-feedback"
                        :state="errors.warehouse_status_id"
                        v-model="inventory_item.warehouse_status_id"
                        :options="warehouseStatusOptions"
                      ></b-form-select>
                      <b-form-invalid-feedback id="warehouse-status-live-feedback">
                        Es requerido
                      </b-form-invalid-feedback>
                      <span class="text-danger" v-if="errors.warehouse_status_id && typeof errors.warehouse_status_id === 'string'">{{errors.warehouse_status_id}}</span>

                    </b-form-group>
                  </div>

                  <div class="col-4">
                    <b-form-group
                      label="Disponibilidad para la venta"
                      label-class=""
                      aria-describedby="commercial-status-live-feedback"
                    >

                      <b-form-select
                        :disabled="inventory_item.credit_id || isReadOnly()"
                        :id="`commercial_status-${this.getUUID4()}`"
                        aria-describedby="commercial-status-live-feedback"
                        :state="errors.commercial_status"
                        v-model="inventory_item.commercial_status"
                        :options="commercialStatusOptions"
                      ></b-form-select>
                      <b-form-invalid-feedback id="commercial-status-live-feedback">
                        Es requerido
                      </b-form-invalid-feedback>
                      <span class="text-danger" v-if="errors.commercial_status && typeof errors.commercial_status === 'string'">{{errors.commercial_status}}</span>

                    </b-form-group>
                  </div>

                  <div class="col-4">
                    <b-form-group
                      label="Disponible en"
                      label-class=""
                    >
                      <b-form-select
                        :disabled="isReadOnly()"
                        v-model="inventory_item.inventory_location_id"
                        :options="locationOptions"
                      ></b-form-select>
                      <span class="text-danger" v-if="errors.inventory_location_id && typeof errors.inventory_location_id === 'string'">{{errors.inventory_location_id}}</span>
                    </b-form-group>
                  </div>

                  <div class="col-4">
                    <b-form-group
                      label="Fecha de reserva"
                      label-class=""
                    >
                      <b-form-datepicker :readonly="isReadOnly()" v-model="inventory_item.reserved_at" placeholder="Seleccionar" ></b-form-datepicker>
                      <span class="text-danger" v-if="errors.reserved_at && typeof errors.reserved_at === 'string'">{{errors.reserved_at}}</span>
                    </b-form-group>
                  </div>


                  <div class="col-4">
                    <b-form-group
                      label="Fecha de recepción"
                      label-class=""
                    >
                      <b-form-datepicker :readonly="isReadOnly()" v-model="inventory_item.reception_date" placeholder="Seleccionar" ></b-form-datepicker>
                      <span class="text-danger" v-if="errors.reception_date && typeof errors.reception_date === 'string'">{{errors.reception_date}}</span>
                    </b-form-group>
                  </div>

                  <div class="col-4">
                    <b-form-group
                      :disabled='true'
                      label="Fecha compromiso de entrega"
                      label-class="text-muted"
                    >
                      <b-form-datepicker :readonly="isReadOnly()" v-model="inventory_item.approx_delivery_date" placeholder="Seleccionar" ></b-form-datepicker>
                      <span class="text-danger" v-if="errors.approx_delivery_date && typeof errors.approx_delivery_date === 'string'">{{errors.approx_delivery_date}}</span>
                    </b-form-group>
                  </div>

                  <div class="col-4">
                    <b-form-group
                      label="Fecha de entrega"
                      label-class=""
                    >
                      <b-form-datepicker :readonly="isReadOnly()" v-model="inventory_item.delivered_at" placeholder="Seleccionar" ></b-form-datepicker>
                      <span class="text-danger" v-if="errors.delivered_at && typeof errors.delivered_at === 'string'">{{errors.delivered_at}}</span>
                    </b-form-group>
                  </div>

                  <div class="col-4">
                    <b-form-group
                      label="Dealer"
                      label-class=""
                    >
                      <b-form-select
                        :disabled="isReadOnly()"
                        v-model="inventory_item.dealer_id"
                        :options="dealerOptions"
                      ></b-form-select>
                      <span class="text-danger" v-if="errors.dealer_id && typeof errors.dealer_id === 'string'">{{errors.dealer_id}}</span>
                    </b-form-group>
                  </div>

                  <div class="col-4">
                    <b-form-group
                      :label="t('activerecord.models.country_origin')"
                      label-class=""
                    >
                      <b-form-select
                        :disabled="isReadOnly()"
                        v-model="inventory_item.country_origin_id"
                        :options="countryOriginOptions"
                      ></b-form-select>
                      <span class="text-danger" v-if="errors.country_origin_id && typeof errors.country_origin_id === 'string'">{{errors.country_origin_id}}</span>
                    </b-form-group>
                  </div>

                  <div class="col-4">
                    <b-form-group
                      :label="t('activerecord.models.condition_use')"
                      label-class=""
                      aria-describedby="condition_use-live-feedback"
                    >
                      <b-form-select
                        :disabled="isReadOnly()"
                        aria-describedby="condition_use-live-feedback"
                        :state="errors.condition_use_id"
                        v-model="inventory_item.condition_use_id"
                        :options="conditionUseOptions"
                      ></b-form-select>

                      <b-form-invalid-feedback id="condition_use-live-feedback">
                        {{ t('inventory_item.required') }}
                      </b-form-invalid-feedback>

                      <span class="text-danger" v-if="errors.condition_use_id && typeof errors.condition_use_id === 'string'">{{errors.condition_use_id}}</span>
                    </b-form-group>
                  </div>

                  <div class="col-4">
                    <b-form-group
                      :label="t('activerecord.models.provider')"
                      label-class=""
                      aria-describedby="provider-live-feedback"
                    >
                      <b-form-select
                        :disabled="isReadOnly()"
                        aria-describedby="provider-live-feedback"
                        :state="errors.provider_id"
                        v-model="inventory_item.provider_id"
                        :options="providerOptions"
                      ></b-form-select>

                      <b-form-invalid-feedback id="provider-live-feedback">
                        {{ t('inventory_item.required') }}
                      </b-form-invalid-feedback>

                      <span class="text-danger" v-if="errors.provider_id && typeof errors.provider_id === 'string'">{{errors.provider_id}}</span>
                    </b-form-group>
                  </div>
                </div>

                <hr>

                <div class="row col">
                  <div class="col-4">
                    <b-form-group
                      label="Marca"
                      label-class=""
                      aria-describedby="brand-live-feedback"
                    >
                      <b-form-select
                        :disabled="isReadOnly()"
                        aria-describedby="brand-live-feedback"
                        :state="errors.brand_id"
                        v-model="inventory_item.brand_id"
                        :options="brandOptions"
                      ></b-form-select>
                      <b-form-invalid-feedback id="brand-live-feedback">
                        Es requerido
                      </b-form-invalid-feedback>

                      <span class="text-danger" v-if="errors.brand_id && typeof errors.brand_id === 'string'">{{errors.brand_id}}</span>
                    </b-form-group>
                  </div>

                  <div class="col-4">
                    <b-form-group
                      label="Modelo"
                      label-class=""
                      aria-describedby="model-live-feedback"
                    >
                      <b-form-select
                        :disabled="isReadOnly()"
                        aria-describedby="model-live-feedback"
                        :state="errors.product_model_id"
                        v-model="inventory_item.product_model_id"
                        :options="modelOptions"
                      ></b-form-select>

                      <b-form-invalid-feedback id="model-live-feedback">
                        Es requerido
                      </b-form-invalid-feedback>

                      <span class="text-danger" v-if="errors.product_model_id && typeof errors.product_model_id === 'string'">{{errors.product_model_id}}</span>
                    </b-form-group>
                  </div>

                  <div class="col-4">
                    <b-form-group
                      label="Color"
                      label-class=""
                      aria-describedby="color-live-feedback"
                    >
                      <b-form-select
                        :disabled="isReadOnly()"
                        aria-describedby="color-live-feedback"
                        :state="errors.color_id"
                        v-model="inventory_item.color_id"
                        :options="colorsOptions"
                      ></b-form-select>
                      <b-form-invalid-feedback id="color-live-feedback">
                        Es requerido
                      </b-form-invalid-feedback>

                      <span class="text-danger" v-if="errors.color_id && typeof errors.color_id === 'string'">{{errors.color_id}}</span>
                    </b-form-group>
                  </div>
                </div>

                <hr>

                <div class="row col" v-if="fieldsDetails.length > 0">
                  <div class="col-4" v-for="field in fieldsDetails" :key="`${field.name}${field.id}`" >
                    <component
                      :readonly="isReadOnlyForDynamicFields()"
                      :is="field.field_type.name"
                      :fieldType="field.field_type.type"
                      :fieldName="field.name"
                      :fieldRequired="field.required && !isReadOnlyForDynamicFields()"
                      :fieldOptions="field.field_options"
                      :fieldId="`${field.name}${field.id}`"
                      :fieldValue="field.value"
                      :fieldDescription="field.description"
                      @fieldsValid="fieldsValid"
                      @changeValue="changeValue"
                    ></component>
                  </div>
                </div>

                <div class="row col mb-3">
                  <div class="col-4">
                    <b-button variant="primary" class=""  type="button" :disabled="isSaving" @click="validateSpecificFields">
                      <b-spinner variant="light" type="grow" v-if="isSaving"></b-spinner>
                      Siguiente
                    </b-button>
                  </div>
                </div>
              </b-collapse>

            </div>
          </div>

          <div class="row flex-column mb-2">
            <div :class="`${facturationFieldsOpen ? 'active': 'inactive'} collapsible-item`" >

              <div @click="toggleFacturationFieldsOpen" class="collapsible-button" >
                <div class="d-flex" >
                  <div v-show="facturationFieldsOk" class="text-success mr-2" > <i class="fas fa-check-circle"></i> </div>
                  <div v-show="!facturationFieldsOk" :class="`${facturationFieldsOpen ? 'dot-active' : 'dot-inactive'}`"> 2 </div>
                  <strong> Datos de facturación</strong>
                </div>

                <span v-show="facturationFieldsOpen">
                  <i class="fas fa-chevron-up"></i>
                </span>

                <span v-show="!facturationFieldsOpen" >
                  <i class="fas fa-chevron-down"></i>
                </span>
              </div>

              <b-collapse v-model="facturationFieldsOpen" id="collapse-2" class="mt-2">
                <hr>

                <div class="row col">

                  <div class="col-4">
                    <b-form-group
                      label="Fecha de factura de compra"
                      label-class=""
                    >
                      <b-form-datepicker :readonly="isReadOnly()" v-model="inventory_item.purchased_at" placeholder="Seleccionar" locale="es" ></b-form-datepicker>
                      <span class="text-danger" v-if="errors.purchased_at && typeof errors.purchased_at === 'string'">{{errors.purchased_at}}</span>
                    </b-form-group>
                  </div>

                  <div class="col-4">
                    <b-form-group
                      label="Número de factura de compra"
                      label-class=""
                    >
                      <b-form-input :readonly="isReadOnly()" type="number" v-model="inventory_item.invoice_id"></b-form-input>
                      <span class="text-danger" v-if="errors.invoice_id && typeof errors.invoice_id === 'string'">{{errors.invoice_id}}</span>
                    </b-form-group>
                  </div>

                  <div class="col-4">
                    <b-form-group
                      label="Precio neto compra"
                      label-class=""
                    >

                      <b-form-input :readonly="isReadOnly()" type="number" v-model="inventory_item.net_amount"></b-form-input>
                      <span class="text-danger" v-if="errors.net_amount && typeof errors.net_amount === 'string'">{{errors.net_amount}}</span>
                    </b-form-group>
                  </div>

                  <div class="col-4">
                    <b-form-group
                      label="IVA factura compra"
                      label-class=""
                    >
                      <b-form-input type="number" disabled v-model="inventory_item.vat"></b-form-input>
                      <span class="text-danger" v-if="errors.vat && typeof errors.vat === 'string'">{{errors.vat}}</span>
                    </b-form-group>
                  </div>

                  <div class="col-4">
                    <b-form-group
                      label="Fecha estimada de disponibilidad"
                      label-class=""
                    >
                      <b-form-datepicker :readonly="isReadOnly()" v-model="inventory_item.availability_date" placeholder="Seleccionar" locale="es" ></b-form-datepicker>
                      <span class="text-danger" v-if="errors.availability_date && typeof errors.availability_date === 'string'">{{errors.availability_date}}</span>
                    </b-form-group>
                  </div>

                </div>

                <hr>

                <div class="row col">

                  <div class="col-4">
                    <b-form-group
                      label="Número de factura de venta"
                      label-class=""
                    >
                      <b-form-input :readonly="isReadOnly()" type="number" v-model="inventory_item.sales_invoice_id"></b-form-input>
                      <span class="text-danger" v-if="errors.sales_invoice_id && typeof errors.sales_invoice_id === 'string'">{{errors.sales_invoice_id}}</span>
                    </b-form-group>
                  </div>

                  <div class="col-4">
                    <b-form-group
                      label="Fecha de factura de venta"
                      label-class=""
                    >
                      <b-form-datepicker :readonly="isReadOnly()" v-model="inventory_item.sales_invoice_date" placeholder="Seleccionar" locale="es" ></b-form-datepicker>
                      <span class="text-danger" v-if="errors.sales_invoice_date && typeof errors.sales_invoice_date === 'string'">{{errors.sales_invoice_date}}</span>
                    </b-form-group>
                  </div>

                  <div class="col-4">
                    <b-form-group
                      label="Precio neto de venta"
                      label-class=""
                    >

                      <b-form-input :readonly="isReadOnly()" type="number" v-model="inventory_item.sales_invoice_net_amount"></b-form-input>
                      <span class="text-danger" v-if="errors.sales_invoice_net_amount && typeof errors.sales_invoice_net_amount === 'string'">{{errors.sales_invoice_net_amount}}</span>
                    </b-form-group>
                  </div>

                  <div class="col-4">
                    <b-form-group
                      label="IVA factura de venta"
                      label-class=""
                    >
                      <b-form-input type="number" disabled v-model="inventory_item.sales_invoice_vat"></b-form-input>
                      <span class="text-danger" v-if="errors.sales_invoice_vat && typeof errors.sales_invoice_vat === 'string'">{{errors.sales_invoice_vat}}</span>
                    </b-form-group>
                  </div>

                </div>

                <div class="row col mb-3">
                  <div class="col-4">
                    <b-button variant="primary" class=""  type="button" :disabled="isSaving" @click="validateFacturationFields">
                      <b-spinner variant="light" type="grow" v-if="isSaving"></b-spinner>
                      Siguiente
                    </b-button>
                  </div>
                </div>
              </b-collapse>

            </div>
          </div>

          <div class="row flex-column mb-2">
            <div :class="`${paymentProvidersOpen ? 'active': 'inactive'} collapsible-item`" >

              <div @click="togglePaymentProvidersOpen" class="collapsible-button" >
                <div class="d-flex" >
                  <div v-show="paymentProvidersOk" class="text-success mr-2" > <i class="fas fa-check-circle"></i> </div>
                  <div v-show="!paymentProvidersOk" :class="`${paymentProvidersOpen ? 'dot-active' : 'dot-inactive'}`"> 3 </div>
                  <strong>{{t(`activerecord.attributes.provider.payment_providers`)}}</strong>
                </div>

                <span v-show="paymentProvidersOpen">
                  <i class="fas fa-chevron-up"></i>
                </span>

                <span v-show="!paymentProvidersOpen" >
                  <i class="fas fa-chevron-down"></i>
                </span>
              </div>

              <b-collapse v-model="paymentProvidersOpen" id="collapse-2" class="mt-2">
                <hr>

                <div class="row col">
                    <div class="col-4">
                      <b-form-group
                        :label="`${t(`activerecord.models.provider`)}`"
                        label-class=""
                      >

                        <b-form-input :disabled="true" type="text" v-model="selectedProvider"></b-form-input>
                      </b-form-group>
                    </div>
                </div>

                <div class="mb-3" v-for="(payment, index) in paymentsInventoryProvider" :key="payment.id">
                  <PaymentProvider
                    :payment="payment"
                    :index="index"
                    @deletePaymentItem="deletePaymentItem"
                  />
                </div>

                <div class="ml-3">
                  <span class="text-danger" v-if="validatePaymentWithNetAmount">
                    <i class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>
                      {{t(`activerecord.attributes.provider.less_than_billing`)}}
                  </span>
                </div>

                <div class="m-3 ">
                  <button class="btn btn-outline-primary dashed-button" @click="newPayment">
                    <i class="fas fa-plus-circle"></i>
                    <i class="m-0 font-s14">{{t(`activerecord.attributes.provider.add_payment`)}}</i>
                  </button>
                </div>

                <div class="row col mb-3">
                  <div class="col-4">
                    <b-button variant="primary" class=""  type="button" :disabled="isSaving" @click="validatePaymentProviders">
                      <b-spinner variant="light" type="grow" v-if="isSaving"></b-spinner>
                      Siguiente
                    </b-button>
                  </div>
                </div>
              </b-collapse>

            </div>
          </div>

          <div class="row flex-column mb-2">
            <div :class="`${discountOpen ? 'active': 'inactive'} collapsible-item`" >

              <div @click="toggleDiscountOpen" class="collapsible-button" >
                <div class="d-flex" >
                  <div class="text-primary mr-2"> <i class="fas fa-tags"></i> </div>
                  <strong>{{t(`inventory_item.discount_title`)}}</strong>
                </div>

                <span v-show="discountOpen">
                  <i class="fas fa-chevron-up"></i>
                </span>

                <span v-show="!discountOpen" >
                  <i class="fas fa-chevron-down"></i>
                </span>
              </div>

              <b-collapse v-model="discountOpen" id="collapse-2" class="mt-2">
                <hr>
                  <p class="text-muted ml-4 mt-3">
                    {{t(`inventory_item.discount_comment`, {list_price: `${inventory_item.product_model_list_price}`, price_with_discount: `${price_with_discount}`})}}
                  </p>
                  <div class="row col p-0 ml-4">
                    <div class="col-4 pl-0 ml-2">
                      <b-form-group
                        :label="`${t(`inventory_item.discount_in_pesos`)}`"
                        label-class=""
                      >
                        <b-form-input
                          :readonly="isReadOnly()"
                          type="number"
                          v-model="inventory_item.discount"
                          aria-describedby="model-live-feedback"
                          :state="errors.discount"
                        ></b-form-input>

                        <b-form-invalid-feedback id="model-live-feedback">
                          {{ t('inventory_item.required') }}
                        </b-form-invalid-feedback>

                        <span class="text-danger" v-if="errors.discount && typeof errors.discount === 'string'">{{errors.discount}}</span>
                      </b-form-group>
                    </div>

                    <div class="col-4">
                      <b-form-group
                        :label="`${t(`inventory_item.campaign_name`)}`"
                        label-class=""
                      >
                        <b-form-input
                          :readonly="isReadOnly()"
                          type="text"
                          v-model="inventory_item.discount_description"
                          aria-describedby="model-live-feedback"
                          :state="errors.discount_description"
                        ></b-form-input>

                        <b-form-invalid-feedback id="model-live-feedback">
                          {{ t('inventory_item.required') }}
                        </b-form-invalid-feedback>

                        <span class="text-danger" v-if="errors.discount_description && typeof errors.discount_description === 'string'">{{errors.discount_description}}</span>
                      </b-form-group>
                    </div>
                  </div>
              </b-collapse>

            </div>
          </div>

          <div class="row flex-column mb-2">
            <div :class="`${singlePriceOpen ? 'active': 'inactive'} collapsible-item`" >

              <div @click="toggleSinglePriceOpen" class="collapsible-button" >
                <div class="d-flex" >
                  <div class="text-primary mr-2"> <i class="fas fa-money-check-alt"></i> </div>
                  <strong>{{t(`inventory_item.single_price_title`)}}</strong>
                </div>

                <span v-show="singlePriceOpen">
                  <i class="fas fa-chevron-up"></i>
                </span>

                <span v-show="!singlePriceOpen" >
                  <i class="fas fa-chevron-down"></i>
                </span>
              </div>
              <b-collapse v-model="singlePriceOpen" id="collapse-2" class="mt-2">
                <hr>
                  <p class="text-muted ml-4 mt-3">{{`${t(`inventory_item.price_comment`)}`}}</p>
                  <div class="col-4 ml-3">
                    <b-form-group
                      :label="`${t(`inventory_item.single_price`)}`"
                      label-class=""
                    >
                      <b-form-input
                        :disabled="validateAssignmentOperation()"
                        :min="0"
                        oninput="validity.valid||(value='');"
                        type="number"
                        v-model="inventory_item.price">
                      </b-form-input>
                      <span class="text-danger" v-if="errors.price && typeof errors.price === 'string'">{{errors.price}}</span>
                    </b-form-group>
                  </div>

                <div class="ml-3 mb-3">
                  <span class="text-danger" v-if="validateAssignmentOperation()">
                    <i class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>
                      {{t(`inventory_item.price_restriction_message`)}}
                  </span>
                </div>
              </b-collapse>

            </div>
          </div>

          <b-form-group v-if="!isReadOnly()" class="row flex-column mb-2 mt-4" >
            <b-link href="/inventory_items" class="btn btn-outline-primary">Cancelar</b-link>
            <b-button variant="primary" class=""  type="submit" :disabled="isSaving || !facturationFieldsOk || !specificFieldsOk" @click="saveInventoryItem">
              <b-spinner variant="light" type="grow" v-if="isSaving"></b-spinner>
              Agregar stock
            </b-button>
            <b-link v-if="inventory_item.id" :href="`/inventory_items/${inventory_item.id}/change_history/`" class="">Historial de cambios</b-link>
          </b-form-group>
        </div>
      </template>
    </box>
  </div>
</template>

<script>
  import i18n from "../mixins/i18n"
  import { v4 as uuidv4 } from 'uuid';
  import moment from 'moment'
  import InputField    from './fields/InputField.vue';
  import CheckboxField from './fields/CheckboxField.vue';
  import SelectField   from './fields/SelectField.vue';
  import TextAreaField from './fields/TextAreaField.vue';
  import PaymentProvider from './PaymentProvider.vue';

  import inventory from '../../api/inventory';
  import inventory_types from '../../api/inventory_types';

  const NAME_REQUIRED_FIELDS = ['warehouse_status_id', 'commercial_status','operation_intent', 'product_model_id', 'brand_id', 'inventory_type_id', 'condition_use_id']

  const INVENTORY_ITEM_FIELDS = {
    warehouse_status_id: null,
    is_notificable: false,
    commercial_status: null,
    inventory_location_id: null,
    inventory_number: '',
    operation_intent: null,
    reserved_at: '',
    delivered_at: '',
    documents_delivered: '',
    documents_delivered_at: '',
    purchased_at: '',
    availability_date: '',
    discount: null,
    discount_description: null,
    product_model_list_price: 0,
    credit_display_code: '',
    simulation_id: '',
    invoice_id: '',
    net_amount: '',
    vat: '',
    extra_info: '',
    dealer_id: '',
    usage: '',
    credit_id: null,
    color_id: null,
    product_model_id: null,
    brand_id: null,
    inventory_type_id: null,
    bill_sale: '',
    sales_invoice_date: '',
    sales_invoice_id: '',
    sales_invoice_net_amount: '',
    sales_invoice_vat: '',
    reception_date: '',
    approx_delivery_date: '',
    country_origin_id: null,
    condition_use_id: null,
    provider_id: null,
    price: null,
    details: {
      fields: []
    },
  }

  export default {
    name: 'InventoryTypeForm',
    components: {
      InputField, CheckboxField, SelectField, TextAreaField, PaymentProvider
    },
    mixins: [i18n],
    props: [
      'inventoryItem',
      'providerPayments',
      'warehouseStatusOptions',
      'commercialStatusOptions',
      'locationOptions',
      'operationIntentOptions',
      'dealerOptions',
      'colorsOptions',
      'inventoryTypesOptions',
      'countryOriginOptions',
      'conditionUseOptions',
      'providerOptions',
      'isNew',
      'role'
    ],
    data() {
      return {
        inventory_item: {
          ...INVENTORY_ITEM_FIELDS,
          details: {
            fields: []
          },
        },
        errors: {
          ...INVENTORY_ITEM_FIELDS,
          discount: null,
          discount_description: null,
          details: {
            fields: []
          },
        },
        fieldsDetails: [],
        specificFieldsOpen: false,
        facturationFieldsOpen: false,
        paymentProvidersOpen: false,
        singlePriceOpen: false,
        discountOpen:false,
        specificFieldsOk: false,
        facturationFieldsOk: false,
        paymentProvidersOk: false,
        isEditting: false,
        isSaving: false,
        modelOptions: [],
        brandOptions: [
          { value: '', text: 'Seleccionar' }
        ],
        currentModelsOptions: [],
        yesNoOptions: [
          { value: true, text: 'Si' },
          { value: false, text: 'No' }
        ],
        title: '',
        showAlert: false,
        paymentsInventoryProvider: [],
        isSavingPaymentsProviders: false
      }
    },
    mounted: async function() {
      if(this.inventoryItem?.id) {
        this.title = `${this.t('activerecord.models.inventory_item')} - ${this.inventoryItem?.inventory_number}`
        this.isEditting = true
        let keys = Object.keys(INVENTORY_ITEM_FIELDS)
        for (let i = 0; i < keys.length; i++) {
          const element = keys[i];
          if(this.inventoryItem[element]) {
            this.inventory_item[element] = this.inventoryItem[element]
          }
        }

        if(this.inventory_item?.delivered_at)
          this.inventory_item.delivered_at = moment.parseZone(this.inventory_item.delivered_at).format('YYYY-MM-DD')

        if(this.inventory_item?.reserved_at)
          this.inventory_item.reserved_at = moment.parseZone(this.inventory_item.reserved_at).format('YYYY-MM-DD')

        if(this.providerPayments.length > 0){
          this.paymentsInventoryProvider = this.providerPayments.map(item => {
            return {...item}
          })
        }

        this.inventory_item.id = this.inventoryItem.id

      } else {
        this.title = 'Editar stock manual'
        let item = { id: null, amount: null, payment_date: null}
        this.paymentsInventoryProvider.push(item)
      }

      if(this.isNew){
        this.alertText = `Stock de inventario creado correctamente`
        this.alertVariant = 'success'
        this.showAlert = true
      }
    },
    watch: {
      'inventory_item.brand_id': async function(newValue, oldValue){
        if(!newValue) { return }

        this.currentModelsOptions = await inventory.modelListByBrand(newValue)
        this.modelOptions = this.currentModelsOptions.map((m) => ({ value: m.id, text: `${m.name} ${m.year}`}))

        if(this.inventory_item.product_model_id)
          this.inventory_item.product_model_list_price = this.currentModelsOptions.find(model => model.id === this.inventory_item.product_model_id )?.list_price

      },
      'inventory_item.product_model_id': async function(newValue, oldValue){
        if(!newValue) { return }

        let model = this.currentModelsOptions.find((m) => newValue === m.id)
        this.inventory_item.product_model_list_price = model?.list_price || 0
      },
      'inventory_item.inventory_type_id': async function(newValue, oldValue){
        if(!newValue) { return }

        let response = await inventory_types.get(newValue)

        let fields = this.inventory_item.details.fields

        this.fieldsDetails = (response?.item_details?.fields || []).map( (f) => {
          let index = fields.findIndex((obj => obj.id === f.id));
          let value = ''

          if(index > -1){
            value = fields[index].value
          }

          return {...f, value }
        })

        this.specificFieldsOpen = true

        this.getBrandOptions()

      }
    },
    computed: {
      price_with_discount() {
        return this.inventory_item.product_model_list_price - this.inventory_item.discount
      },
      selectedProvider(){
        if(this.inventory_item.provider_id){
          let showProvider = this.providerOptions.find(po => po.value === this.inventory_item.provider_id)
          return showProvider.text
        }else{
          return `${this.t('activerecord.attributes.provider.not_selected')}`
        }
      },
      validatePaymentWithNetAmount(){
        let cleanPaymentsInventoryProvider = this.paymentsInventoryProvider.filter( item => item.amount && item.payment_date)
        if(!this.inventoryItem.net_amount) { return false}
        if(!this.inventory_item.net_amount) { return false}
        if(cleanPaymentsInventoryProvider.length == 0) { return false}

        let totalBill
        if(this.inventoryItem.net_amount == this.inventory_item.net_amount){
          totalBill = this.inventoryItem.net_amount * 1.19
        }else{
          totalBill = this.inventory_item.net_amount * 1.19
        }

        let totalPayment = cleanPaymentsInventoryProvider.reduce((sum, item) => sum + parseInt(item.amount), 0)

        return totalPayment > totalBill
      },
    },
    methods: {
      saveInventoryItem: async function() {
        if(!this.valid()){ return }

        this.isSaving = true

        if(this.isReadOnlyForDynamicFields()) delete this.inventory_item.details
        else this.inventory_item.details['fields'] = this.fieldsDetails

        let response;
        if(this.isSavingPaymentsProviders){
          this.inventory_item['payments_inventory_providers_attributes'] = {...this.paymentsInventoryProvider}
        }
        if(this.isEditting) {
          response = await inventory.update(this.inventory_item.id, { inventory_item: this.inventory_item })
        } else {
          response = await inventory.post({ inventory_item: this.inventory_item })
        }
        if(response.status === 422){
          let keys = Object.keys(response?.data)
          for (let i = 0; i < keys.length; i++) {
            const element = keys[i];
            this.errors[element] = response?.data[element].join(', ')
          }
          this.showAlert = false
        } else if (response.id){
          if(this.isEditting){
            this.alertText = `Stock de inventario actualizado correctamente`
            this.alertVariant = 'success'
            this.showAlert = true
          } else{
            window.location.href = `/inventory_items/${response.id}/edit?new=true`
          }
        }
        this.isSaving = false
      },

      valid() {
        let valid = true
        for (let index = 0; index < NAME_REQUIRED_FIELDS.length; index++) {
          const element = NAME_REQUIRED_FIELDS[index];
          if (!this.validateField(element)) valid = false
        }

        this.errors.details.fields.forEach(element => {
          valid = valid && (element?.value === false ? false : true)
        });

        if((this.inventory_item.discount && parseInt(this.inventory_item.discount) != 0 && !this.inventory_item.discount_description) ||
           ((!this.inventory_item.discount || parseInt(this.inventory_item.discount) == 0) && this.inventory_item.discount_description)){
          valid = false
          if(!this.inventory_item.discount && parseInt(this.inventory_item.discount) != 0) this.errors['discount'] = false
          if(!this.inventory_item.discount_description) this.errors['discount_description'] = false
        }else{
          this.errors['discount'] = true
          this.errors['discount_description'] = true
        }

        return valid
      },

      validateField(name) {
        if(!this.inventory_item[name]){
          this.errors[name] = false
          return false
        } else {
          this.errors[name] = true
          return true
        }
      },
      fieldsValid(data) {
        let index = this.errors.details.fields.findIndex((obj => obj.id === data.id))
        if( index < 0 ) {
          this.errors.details.fields.push(data)
        } else {
          this.errors.details.fields[index].value = data.value
        }

      },

      changeValue(data) {
        let index = this.fieldsDetails.findIndex((obj => `${obj.name}${obj.id}` === data.id));
        this.fieldsDetails[index].value = data.value
      },

      getUUID4() {
        return uuidv4()
      },

      toggleSpecificFieldsOpen() {
        this.specificFieldsOpen = !this.specificFieldsOpen
      },
      toggleFacturationFieldsOpen() {
        this.facturationFieldsOpen = !this.facturationFieldsOpen
      },
      togglePaymentProvidersOpen() {
        this.paymentProvidersOpen = !this.paymentProvidersOpen
      },
      toggleSinglePriceOpen() {
        this.singlePriceOpen = !this.singlePriceOpen
      },
      toggleDiscountOpen() {
        this.discountOpen = !this.discountOpen
      },

      validateSpecificFields() {
        if(this.valid()){
          this.specificFieldsOpen = false
          this.facturationFieldsOpen = true
          this.paymentProvidersOpen = false
          this.singlePriceOpen = false
          this.discountOpen = false

          this.specificFieldsOk = true
        }
      },
      validateFacturationFields() {
        this.specificFieldsOpen = false
        this.facturationFieldsOpen = false
        this.singlePriceOpen = false
        this.discountOpen = false
        this.paymentProvidersOpen = true

        this.specificFieldsOk = true
        this.facturationFieldsOk = true

      },
      validatePaymentProviders(){
        this.specificFieldsOpen = false
        this.facturationFieldsOpen = false
        this.singlePriceOpen = false
        this.discountOpen = false
        this.paymentProvidersOpen = true
        this.validFieldsPaymentsProvider()

        if(this.valid() && !this.validatePaymentWithNetAmount){
          this.specificFieldsOk = true
          this.facturationFieldsOk = true
          this.paymentProvidersOk = true
          this.paymentProvidersOpen = false
        }else{
          this.specificFieldsOk = false
          this.facturationFieldsOk = false
          this.paymentProvidersOk = false
        }
      },
      isReadOnly() {
        return ['sales_team_leader', 'sales_exec', 'cx_boss', 'cx_executive'].includes(this.role)
      },
      getBrandOptions: async function () {
        let response = await inventory.brandsList(1, this.inventory_item.inventory_type_id)
        this.brandOptions = [
          { value: '', text: 'Seleccionar' }
        ].concat(response.map(val => ({value: val.id, text: val.name})))

      },
      isReadOnlyForDynamicFields(){
        return ['logistics_exec', 'sales_exec','sales_team_leader', 'operations', 'operations_boss', 'manager', 'cx_boss', 'cx_executive'].includes(this.role)
      },
      newPayment(){
        let item = { id: null, amount: null, payment_date: null }
        this.paymentsInventoryProvider.push(item)
      },
      validFieldsPaymentsProvider(){
        let cleanPaymentsInventoryProvider = this.paymentsInventoryProvider.filter( item => item.amount && item.payment_date)
        if(cleanPaymentsInventoryProvider.length == 0) { return }

        this.paymentsInventoryProvider = cleanPaymentsInventoryProvider
        let originPaymentsProvider = this.providerPayments ? this.providerPayments.map(item => item ) : []

        if(this.paymentsInventoryProvider.length > originPaymentsProvider.length ){
          this.isSavingPaymentsProviders = true
          return
        }

        for (const key in originPaymentsProvider) {
          if(originPaymentsProvider[key].amount != parseInt(this.paymentsInventoryProvider[key]?.amount) ||
             originPaymentsProvider[key].payment_date != this.paymentsInventoryProvider[key].payment_date){
              this.isSavingPaymentsProviders = true
              break
          }
        }
      },
      deletePaymentItem: async function(index, id) {
        if(id){
          const response = await inventory.deletePaymentsInventoryProvider(id)
          if (!response) { return }
        }

        this.paymentsInventoryProvider.splice(index, 1)
        this.$bvModal.hide('modal_delete_payment_item')
      },
      validateAssignmentOperation(){
        return (this.inventory_item.credit_display_code) ? true : false;
      }
    }
  }
</script>
