import axios from 'axios'
import { config } from '../constants/api'

const buildConfig = (extraconfig) => {
  return { ...config, ...extraconfig }
}

export default {
  async post(data) {
    const path = '/errand_invoice_templates.json'

    return axios.post(path, data, config).then(res => res.data).catch(err => {
      return err.response
    })
  },
  async update(errandInvoicetemplateId, data) {
    const path = `/errand_invoice_templates/${errandInvoicetemplateId}.json`

    return axios.patch(path, data, config)
      .then((res) => res.data)
      .catch((error) => error.response)
  },
  async get(errandInvoicetemplateId) {
    const path = `/errand_invoice_templates/${errandInvoicetemplateId}.json`

    return axios.get(path, config)
      .then((res) => res.data)
      .catch((error) => error.response)
  },
  async list(inventoryTypeId) {
    const path = `/errand_invoice_templates.json?inventory_type_id=${inventoryTypeId}`

    return axios.get(path, config)
      .then((res) => res.data)
      .catch((error) => error.response)
  },
  async dictionary(inventoryTypeId) {
    const path = `/errand_invoice_templates/dictionary.json?inventory_type_id=${inventoryTypeId}`

    return axios.get(path, config)
      .then((res) => res.data)
      .catch((error) => error.response)
  },
  async buildInvoice(errandTypeId, inventoryTypeId, creditId) {
    const path = `/errand_invoice_templates/build_invoice.json`
    const data = {
      errand_type_id: errandTypeId,
      inventory_type_id: inventoryTypeId,
      credit_id: creditId
    }

    return axios.post(path, data, config)
      .then((res) => res.data)
      .catch((error) => error.response)
  }
}
