<template>
  <div v-if="isClientInformation || isGuarantorInformation || clientMedicalLeave">
    <h1 class="mb-3">{{t('activerecord.attributes.simulation.evaluation.personal_information')}}</h1>

    <b-row>
      <b-col cols lg="6" sm="12" v-if="isClientInformation">
        <personal-information-table
          :title="t('activerecord.attributes.simulation.evaluation.client')"
          :personal-information="clientInfo" />
      </b-col>
      <b-col cols lg="6" sm="12" v-if="isGuarantorInformation">
        <personal-information-table
          :title="t('activerecord.attributes.simulation.evaluation.guarantor')"
          :personal-information="guarantorInfo" />
      </b-col>
    </b-row>

    <b-row>
      <b-col cols lg="12" sm="12">
        <section class="card shadow-light-20 mb-4">
          <div class="card-header flex align-items-center justify-content-between">
            <span class="subtitle neutral-70">
              {{ t('activerecord.attributes.simulation.evaluation.client_medical_leave') }}
              <span class="badge badge-success badge-pill">Nuevo</span>
            </span>
          </div>
          <div class="card-body" v-if="clientMedicalLeave">
            <section v-if="clientMedicalLeave.length">
              <b-table
                class="m-0"
                :items="clientMedicalLeaveList"
                :fields="medicalLicensesFields"
                :current-page="currentPage"
                :per-page="perPage">
                <template #cell(start_date)="data">
                  {{ data.value.toLocaleDateString() }}
                </template>
                <template #cell(end_date)="data">
                  {{ data.value.toLocaleDateString() }}
                </template>
              </b-table>
              <div class="flex-vertical-center justify-content-end row-sm-block mtp-20">
                <per-page :per-page.sync="perPage" />
                <b-pagination
                  v-model="currentPage"
                  class="sm-center"
                  style="margin-bottom: unset"
                  :total-rows="rows"
                  :per-page="perPage"
                  :prev-text="`‹ ${t('views.previous')}`"
                  :next-text="`${t('views.next')} ›`"
                  hide-goto-end-buttons
                  hide-ellipsis>
                </b-pagination>
              </div>
            </section>

            <strong class="flex m-4" v-else>{{t('activerecord.attributes.simulation.evaluation.no_medical_leave')}}</strong>
          </div>
          <div v-else>
            <strong class="flex m-4">{{t('activerecord.attributes.simulation.evaluation.no_medical_leave_yet')}}</strong>
          </div>
        </section>
      </b-col>
    </b-row>
  </div>
  <div v-else>
    <strong>{{t('activerecord.attributes.simulation.evaluation.no_kayak_personal_information')}}</strong>
  </div>
</template>
<script>
import PersonalInformationTable from "./PersonalInformationTable.vue"
import i18n from "../mixins/i18n"
import moment from 'moment';
import PerPage from '../dynamics_form/PerPage.vue';

export default {
  name: "kayak-personal-information",
  mixins: [i18n],
  components: {
    PersonalInformationTable,
    PerPage
  },
  props: [
    'personalInformation',
    'clientMedicalLeave'
  ],
  data() {
    return {
      information: JSON.parse(this.personalInformation),
      clientInfo: null,
      guarantorInfo: null,
      medicalLicensesFields: this.translateFields(['folio_number', 'start_date', 'end_date', 'duration_in_days', 'current_state', 'employer_rut', 'employer_name']),
      clientMedicalLeaveList: [],
      rows: this.clientMedicalLeave?.length ?? 0,
      currentPage: 1,
      perPage: 5,
    }
  },
  methods: {
    translateFields(fields) {
      return fields.map((field) => ({
        key: field,
        label: this.t(`activerecord.attributes.simulation.evaluation.${field}`)
      }))
    },
    formatDates() {
      this.clientMedicalLeaveList = this.clientMedicalLeave.map(info => ({
        ...info,
        start_date: this.formatDate(info.start_date),
        end_date: this.formatDate(info.end_date)
      }))

      this.sortDates()
    },
    formatDate(stringDate) {
      const date = stringDate.length > 10 ?
        new Date(stringDate) :
        moment(stringDate, "DD-MM-YYYY").toDate()

      return date
    },
    sortDates() {
      this.clientMedicalLeaveList = this.clientMedicalLeaveList.sort((a, b) => b.start_date - a.start_date)
    }
  },
  computed: {
    isClientInformation() {
      return !!this.clientInfo
    },
    isGuarantorInformation() {
      return !!this.guarantorInfo
    }
  },
  mounted() {
    this.clientInfo = this.information.client
    this.guarantorInfo = this.information.guarantor

    if(this.clientMedicalLeave?.length)
      this.formatDates()
  }
}
</script>
