
<template>
  <b-modal
    ref="myModal"
    @hide="onClose"
    id="modal-1"
    v-model="show"
    :title='`Agregar ítems a la lista "{${name}}" `'
  >
    <template slot="modal-ok">
      Listo
    </template>
    <template slot="modal-cancel">
      Cancelar
    </template>
    <b-row>
      <b-col cols="12" v-if="field_options.length > 0">
        <table class="table">
          <thead>
            <tr>
              <th class="border-0 table-header">Nombre</th>
              <th class="border-0 table-header"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="option, index in field_options" v-bind:key="option">
              <td>
                <options-form
                  :option="option"
                  :index="index"
                  :edit="isEditingOption(index)"
                  :disabled="isDisabled"
                  @onOptionAdded="onOptionAdded"
                  @onOptionEditted="onOptionEditted"
                />
              </td>
              <td class="float-end">
                <button
                  type="button"
                  v-if="!isDisabled"
                  @click="removeOption(index)"
                  class="btn btn-outline-danger"
                >
                  <i class="fas fa-trash"></i>
                </button>
                <button type="button" v-if="!isDisabled" @click="editOption(index)" class="btn btn-outline-primary">
                  <i class="fas fa-pen"></i>
                </button>

              </td>
            </tr>
          </tbody>
        </table>
      </b-col>

      <b-col v-if="showAddItem" cols="12">
        <hr>
        <options-form
          :disabled="isDisabled"
          @onOptionAdded="onOptionAdded"
        />
      </b-col>

      <b-col cols="12">
        <hr>
        <b-button :disabled="disabledAddItem" type="submit" variant="outline-primary" class="w-100" @click="toggleShowAddItem" >
          Agregar item a la lista
        </b-button>
      </b-col>

    </b-row>
  </b-modal>
</template>

<script>
  import _ from 'lodash';
  import OptionsForm from './OptionsForm.vue';

  export default {
    name: 'list-modal',
    components: {
      OptionsForm
    },
    props: [
      'readonly',
      'name',
      'options',
      'isDisabled',
    ],
    data() {
      return {
        show: true,
        field_options: this.options || [],
        isReadonly: this.readonly || false,
        editingOption: null,
        showAddItem: false,
        disabledAddItem: false,
      }
    },
    mounted() {
    },
    computed: {
      getFieldTypeName() {
        return this.field_type?.name
      },
      isSelectField() {
        return this.getFieldTypeName === 'SelectField'
      }
    },
    watch: {
      options: function(newValue, oldValue) {
        this.field_options = newValue
      },
    },
    methods: {
      onOptionAdded(value) {
        this.$emit('onOptionAdded', value)
        this.toggleShowAddItem()
      },
      onOptionEditted(value, index){
        this.$emit('onOptionEditted', value, index)
        this.editingOption = null
      },
      removeOption(value) {
        this.$emit('removeOption', value)
      },
      toggleShowAddItem() {
        this.showAddItem = !this.showAddItem;
        this.disabledAddItem = !this.disabledAddItem
      },

      isEditingOption(index) {
        return this.editingOption === index
      },

      editOption(index) {
        this.editingOption = index
      },
      onClose() {
        this.$emit('toggleShowListModal')
      }
    }

  }
</script>
