import { dropdownStatuses } from './statuses'

export const SIMULATION_STATUSES = [
  ...dropdownStatuses
]

export const OPERATION_STATUSES = [
  { value: 'operation_date', text: 'Fecha visado' },
  // { value: 'operation_signDate', text: 'Fecha de firma' },
  { value: 'operation_lastPay', text: 'Pago de la ultima cuota de leasing' },
]

export const INVENTORY_STATUSES = [
  { value: 'on_create', text: 'Al crear ítem' },
  { value: 'planned', text: 'Planificado' },
  { value: 'transit', text: 'En tránsito' },
  { value: 'pending_assembly', text: 'Pendiente de armado' },
  { value: 'available', text: 'Disponible en Bodega' },
  { value: 'ready_delivery', text: 'Listo para Entrega' },
  { value: 'delivered', text: 'Entregado' },
  { value: 'unavailable', text: 'No Disponible' },
  { value: 'cash_sale', text: 'Venta contado' },
  { value: 'returned', text: 'Retornada' },
]

export const CREDITS_STATE_MODEL_OPTIONS = [
  { text: 'Operación', value: 'operation' },
  { text: 'Simulación', value: 'simulation' },
  { text: 'Trámite', value: 'errand_type' },
  { text: 'Documento', value: 'DocumentTemplatesProductType' },
]
export const INVENTORY_STATE_MODEL_OPTIONS = [
  { text: 'Inventario', value: 'InventoryItem' },
]

export const SIGNATURE_STATES = [
  { text: 'No firmado', value: 'unsigned' },
  { text: 'Esperando firmas', value: 'waiting_signatures' },
  { text: 'Firmado', value: 'signed' },
]

export const TARGETMODELS = {
  'product_type': CREDITS_STATE_MODEL_OPTIONS,
  'inventory_type': INVENTORY_STATE_MODEL_OPTIONS,
}

export const TARGETSTATUSES = {
  'product_type' : {
    'operation': OPERATION_STATUSES,
    'simulation': SIMULATION_STATUSES,
    'DocumentTemplatesProductType': SIGNATURE_STATES,
  },
  'inventory_type': {
    'InventoryItem': INVENTORY_STATUSES
  }
}


