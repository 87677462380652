<template>
  <div :class="`notification-container bg-${alertType} ${position}`" v-bind:style="{ display: isVisible ? 'flex !important' : 'none !important', paddingBottom: isProgressBar() ? '28px' : '19px' }">
    <span class="alert-text" v-bind:style="{ marginRight: isUndoButton && withTimer ? 'unset' : '25px' }">{{ text }}</span>
    <span class="undo-button" v-on:click="undoEvent" v-bind:style="{ display: isUndoButton && withTimer ? 'inline-flex' : 'none' }">Deshacer</span>
    <span class="close-button" v-on:click="closeEvent"><i class="fas fa-times"></i></span>
    <div class="progress-container" v-bind:style="{ display: isProgressBar() ? 'block' : 'none' }">
      <b-progress
        :max="time"
        :value="dismissCountDown"
        height="4px"></b-progress>
    </div>
  </div>
</template>
<script>
export default {
  name: "alert",
  props: {
    text: String,
    withTimer: {
      type: Boolean,
      default: true
    },
    time: {
      type: Number,
      default: 10
    },
    progressBar: {
      type: Boolean,
      default: true
    },
    isUndoButton: {
      type: Boolean,
      default: false
    },
    onUndoEvent: {
      type: Function,
      default: () => {}
    },
    onEvent: {
      type: Function,
      default: () => {}
    },
    position: {
      type: String,
      default: 'bottom-left'
    },
    alertType: {
      type: String,
      default: 'default'
    }
  },
  data() {
    return {
      isVisible: false,
      dismissCountDown: 0,
      timer: undefined,
      undo: false,
      sentEvent: false
    }
  },
  methods: {
    show() {
      this.runEvent()
      this.isVisible = true
      if(this.withTimer) {
        clearInterval(this.timer)
        this.dismissCountDown = this.time
        this.sentEvent = false
        this.timer = setInterval( () => {
          this.dismissCountDown--
          if(this.dismissCountDown === 0){
            this.closeEvent()
          }
        },1000)
      } else {
        this.runEvent()
      }
    },
    closeEvent() {
      this.isVisible = false
      if(!this.undo)
        this.runEvent('close')
      clearInterval(this.timer)
    },
    undoEvent() {
      this.$emit('onUndoEvent')
    },
    runEvent(eventType) {
      if(!this.sentEvent){
        this.$emit('onEvent', eventType)
        this.sentEvent = true
      }
    },
    isUndo() {
      this.undo = true;
      this.closeEvent()
    },
    isProgressBar() {
      if(this.progressBar)
        return this.withTimer
      return this.progressBar
    },
  },
  beforeDestroy() {
    this.closeEvent()
    window.removeEventListener('beforeunload', this.closeEvent())
  },
  created() {
    window.addEventListener('beforeunload', (e) => {
      this.closeEvent()
    })
  },
}
</script>
