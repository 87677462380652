<template>
  <b-row>
    <b-col>
      <b-form @submit="handleSave">
        <b-form-group
          label="Nombre Completo Titular Cuenta"
          label-for="input-1"
        >
          <b-form-input v-model="newBankAccount.name" />
        </b-form-group>
        <b-form-group
          label="RUT"
          label-for="input-2"
          description="Número de RUT - Digito verificador"
        >
          <b-input-group>
            <b-form-input maxLength="8" v-model="newBankAccount.rut" />
            <b-input-group-prepend is-text
              ><strong>-</strong></b-input-group-prepend
            >
            <b-form-input
              maxLength="1"
              v-model="newBankAccount.dv"
              class="dv"
            />
          </b-input-group>
        </b-form-group>
        <b-form-group label="Banco" label-for="input-1">
          <b-form-select
            v-model="newBankAccount.bank_id"
            :options="bankNames"
          />
        </b-form-group>
        <b-form-group
          label="Tipo de Cuenta"
          label-for="input-3"
        >
          <b-form-select
            v-model="newBankAccount.account_type"
            :options="bankAccountTypes"
          />
        </b-form-group>
        <b-form-group label="Número de Cuenta" label-for="input-4">
          <b-form-input v-model="newBankAccount.number" />
        </b-form-group>
        <b-button @click="handleSave" variant="primary" :disabled="isEmptyForm">
          Guardar Datos
        </b-button>
      </b-form>
    </b-col>
  </b-row>
</template>
<script>
import { BANK_ACCOUNT_TYPES_LIST, BANKS_LIST } from "../../constants/payments";

const EMPTY_BANK_ACCOUNT= {
  name: "",
  rut: "",
  dv: "",
  account_type: "",
  number: "",
  bank_id: "",
}

export default {
  name: "new-bank-account",
  components: {},
  props: {
    accountType: {
      type: Object
    },
    selectedBankAccountToEdit: {
      type: Object,
      default: EMPTY_BANK_ACCOUNT
    }
  },
  data() {
    return {
      newBankAccount: EMPTY_BANK_ACCOUNT,
      bankAccountTypes: BANK_ACCOUNT_TYPES_LIST
    };
  },
  methods: {
    handleSave() {
      this.$emit("onSaveButton", this.newBankAccount);
    },
  },
  computed: {
    bankNames() {
      return BANKS_LIST;
    },
    isEmptyForm() {
      return this.newBankAccount.name === '' || this.newBankAccount.rut === '' || this.newBankAccount.dv === '' || this.newBankAccount.account_type === '' || this.newBankAccount.number === '' || this.newBankAccount.bank_id === ''
    },
  },
  mounted() {
    if(this.selectedBankAccountToEdit !== EMPTY_BANK_ACCOUNT) this.newBankAccount = this.selectedBankAccountToEdit
  },
  watch: {
    selectedBankAccountToEdit() {
      this.newBankAccount = this.selectedBankAccountToEdit
    },
    'newBankAccount.bank_id'(value) {
      const bank = this.bankNames.find(bank => bank.value === value);

      this.bankAccountTypes = (bank.excluded)
        ? BANK_ACCOUNT_TYPES_LIST.filter(item => !bank.excluded.includes(item.value))
        : BANK_ACCOUNT_TYPES_LIST
    }
  },
};
</script>
