<template>
  <div>
    <loader ref="loader"/>
    <button class="base-button danger-button mt-3 width-fit-content" v-b-modal.modal_delete_item>{{ t(`credit.unlink_item`) }}</button>

    <b-modal id="modal_delete_item" size="sm">
      <template #modal-title>
        <span class="title t1 width-max-content">{{ t(`credit.alert_unlink_item`) }}</span>
      </template>
      <div class="color-default mtp-20">
        <b-form-radio v-model="selected" name="some-radios" value="false">
          <div v-html="t(`credit.item_unavailable`)"></div>
        </b-form-radio>
        <b-form-radio v-model="selected" name="some-radios" value="true">
          <div v-html="t(`credit.item_available`)"></div>
        </b-form-radio>
      </div>
      <template #modal-footer>
        <button class="footer-button base-button outline" style="margin-right: 12px !important;" @click="$bvModal.hide('modal_delete_item')">{{ t(`views.cancel`) }}</button>
        <button class="footer-button base-button" @click="deleteInventoryItem" :disabled="!Boolean(selected)">{{ t(`credit.unlink_item`) }}</button>
      </template>
    </b-modal>

    <b-modal id="modal_error" centered>
      <template #modal-header><div class="none"></div></template>
      <div v-bind:style="styleBodyModal">
        <i class="far fa-times-circle w-100 mbp-42" v-bind:style="styleSymbolError"></i>
        <span class="title t1">{{ t(`credit.item_not_found`) }}</span>
      </div>
      <template #modal-footer>
        <button class="footer-button base-button" @click="$bvModal.hide('modal_error')">{{ t(`views.close`) }}</button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import i18n from "../mixins/i18n"
import credit from '../../api/credits'
import Loader from '../template/Loader.vue'
import Alert from '../template/Alert.vue'

export default {
  name: "modal-delete-item",
  mixins: [i18n],
  components: { Loader, Alert },
  props: {
    inventoryItemId: String,
    creditSlug: String,
  },
  data() {
    return {
      selected: null,
      styleBodyModal: {
        marginBottom: '42px',
        marginTop: '42px',
        textAlign: 'center',
      },
      styleSymbolError: {
        fontSize: '34px',
        color: 'rgb(221, 33, 34)',
      },
    }
  },
  methods: {
    deleteInventoryItem: async function() {
      this.$refs.loader.isVisible()
      const response = await credit.deleteItem(this.inventoryItemId, this.creditSlug, this.selected)
      if(response.status == 200)
        return window.location.href = `${window.location.origin}/credits/${this.creditSlug}`
      this.$bvModal.hide('modal_delete_item')
      this.$bvModal.show('modal_error')
      this.$refs.loader.notVisible()
    }
  },
}
</script>
