<!-- Darle:
> evaluador: buildRule.evaluator
> opciones: typeOfRequirementByPolitic[buildRule.evaluator].options

Rellena:
> valueToSave: buildRule.criteria
 -->
<template>
  <div>
    <b-container fluid>
      <b-row align-h="start">
        <b-col cols="6" class="p-0">
          <b-form-group :label="t(`admission.show_requirements.evaluators.${ evaluator }`) + ' requerido'" id="`${ evaluator }Form`">
            <div class="content-select">
              <b-form-select class="default-select" v-model="valueToSave" :options="evaluatorOptions" @input="$emit('selectedOption', valueToSave)" >
                <template #first>
                  <b-form-select-option :value="{ 'operator': null, 'value': null }" disabled>
                    Selecciona {{ t(`admission.show_requirements.evaluators.${ evaluator }`) }}
                  </b-form-select-option>
                </template>
              </b-form-select>
              <i></i>
            </div>
          </b-form-group>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
  // Imports
  import i18n from "../mixins/i18n"
  import capitalize from "../mixins/capitalize"

  export default {
    mixins: [i18n, capitalize],
    name: 'options-form-component',
    components: {
      // Add components as: CoolComponent,
    },
    props: [
      // Properties
      'evaluator',
      'evaluatorOptions'
    ],
    emits: [
      'selectedOption'
    ],
    data: function() {
      return {
        savedSelectedOption: {'operator': null, 'value': null}
      }
    },
    methods: {
      // Actions
    },
    computed: {
      // To parse received data
      valueToSave: {
        get() {
          return this.savedSelectedOption
        },
        set(newVal) {
          this.savedSelectedOption = newVal
        }
      }
    },
    mounted: async function() {
    }
  }
</script>

<style>

</style>
