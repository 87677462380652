<template>
  <button
    v-if="selected"
    class="second-button button-list text-capitalize active"
    v-bind:class="{ deselectable: isDeselectable }"
    @click="onDeselectItem"
    :disabled="isDisabled">
    {{ itemText }}
  </button>
</template>
<script>
export default {
  name: 'select-list',
  props: {
    itemText: '',
    itemValue: '',
    isDisabled: {
      type: Boolean,
      default: false
    },
    isDeselectable: {
      type: Boolean,
      default: true
    },
  },
  data() {
    return {
      selected: false
    }
  },
  methods: {
    onDeselectItem() {
      if (this.isDeselectable)
        this.deselectItem()
    },
    deselectItem() {
      this.selected = false
      this.$emit('deselectItem', this.itemValue)
    },
    selectItem() {
      this.selected = true
    }
  }
}
</script>
