import axios from "axios";
import * as humps from "humps";
import { config } from "../constants/api";

export default {
  async getUnrecognizedChargeOrders(){
    const path = '/unrecognized_charge_orders.json';

    return await axios.get(path, config)
      .then(res => res.data).catch((error) => (error.response))

  },
  async updateUnrecognizedChargeOrder(installment_id, unrecognized_id) {
    const params = { installment_id: installment_id }

    const path = `/unrecognized_charge_orders/${unrecognized_id}.json`;

    return await axios.put(path, params, config)
      .then(res => res.data).catch((error) => (error.response));
  },

  async alreadyRecognized(unrecognized_id) {
    const path = `/unrecognized_charge_orders/${unrecognized_id}/already_recognized.json`;

    return axios.put(path, {}, config)
      .then(res => res.data).catch((error) => (error.response));
  }
};
