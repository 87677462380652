import axios from 'axios'
import { config } from '../constants/api'
import * as humps from 'humps'

export default {
  async documentTemplateWarnings(params) {
    const path = `/product_types/document_templates_warnings.json`
    const body = {
      custom_product_type: params
    }
    return await axios.post(path, humps.decamelizeKeys(body), config).then(res => res.data)
  },

  async update(id, params) {
    const path = `/product_types/${id}.json`
    const body = {
      ...params
    }

    return await axios.patch(path, humps.decamelizeKeys(body), config).then(res => res.data)
      .catch((error) => error.response)
  },

  async getList() {
    const path = `/product_types/list.json`;

    return axios.get(path, config)
      .then((res) => res.data)
      .catch((error) => error.message)
  },
}
