<template>
  <section>
    <div v-if="latestUpdateDate" class="flex justify-end">
      <h6 class="mr-2">{{ t('views.latest_update') }}:</h6> {{ latestUpdateDate }}
    </div>
    <div class="messages" v-if="clientIncomes">
      <div class="grid grid-cols-2 text-lg">
        <div class="shadow-inner bg-blue-100 rounded p-1 mx-1.5">
          {{t('activerecord.attributes.simulation.evaluation.client_incomes_average')}} = {{formattedClientIncomesAverage}}
        </div>
        <div v-if="hasGuarantor" class="shadow-inner bg-blue-100 rounded p-1 mx-1.5">
          {{t('activerecord.attributes.simulation.evaluation.guarantor_incomes_average')}} = {{formattedGuarantorIncomesAverage}}
        </div>
      </div>
      <div v-if="!readOnly">
        <h2> {{t('activerecord.attributes.simulation.evaluation.client_incomes_without_name')}}</h2>
        <incomes-table :client-incomes="clientIncomes" :relationship-type="'holder'"/>
        <div v-if="hasGuarantor">
          <h2> {{t('activerecord.attributes.simulation.evaluation.guarantor_incomes')}}</h2>
          <incomes-table :client-incomes="clientIncomes" :relationship-type="'guarantor'"/>
        </div>
      </div>
    </div>
    <div v-else>
      <strong>{{t('activerecord.attributes.simulation.evaluation.no_kayak_incomes_information')}}</strong>
    </div>
  </section>
</template>
<script>
import i18n from "../mixins/i18n"
import capitalize from "../mixins/capitalize"
import IncomesTable from "./IncomesTable.vue"

export default {
  name: "kayak-incomes-information",
  mixins: [i18n, capitalize],
  components: {
    IncomesTable
  },
  props: [
    'kayakPayload',
    'clientIncomes',
    'clientIncomesAverageHolder',
    'clientIncomesAverageGuarantor',
    'readOnly',
    'hasGuarantor'
  ],
  data() {
    return {
      formattedClientIncomesAverage: this.$options.filters.currency(this.clientIncomesAverageHolder, '$', 0, { thousandsSeparator: '.', decimalSeparator: ',' }),
      formattedGuarantorIncomesAverage: this.$options.filters.currency(this.clientIncomesAverageGuarantor, '$', 0, { thousandsSeparator: '.', decimalSeparator: ',' })
    }
  },
  computed: {
    latestUpdateDate() {
      if (!this.kayakPayload?.client_unique_credential_submitted_at_meta?.value) return null

      const date = new Date(this.kayakPayload.client_unique_credential_submitted_at_meta.value)
      return `${this.capitalize(this.t('date.day_names')[date.getDay()])}
       ${date.getDate()} de
       ${this.capitalize(this.t('date.month_names_js')[date.getMonth()])} de
       ${date.getFullYear()}`
    }
  }
}
</script>
