<template>
  <div class="modal-content p-0 border-0" >
    <span class="title t1 mb-4"> {{ t(`activerecord.models.errand`) }} {{ item.errand_type.name }} - {{ t(`views.number_of_operation`) }} {{ item.signature_and_operation_number.operation_number }}</span>

    <div class="container-modal mb-4 m-inline info-15">
      <h6 class="title"> {{ t('activerecord.attributes.operation.operation_sumary') }}</h6>
      <div class="flex-space-between responsive mtp-12">
        <div class="mrp-12">
          <small class="neutral-50">{{ t('activerecord.attributes.client.client_name') }}</small>
          <p>{{ item.rut_and_name.name }}</p>
        </div>
        <div class="mrp-12">
          <small class="neutral-50">{{ t('activerecord.attributes.client.rut') }}</small>
          <p>{{ item.rut_and_name.rut }}</p>
        </div>
        <div class="mrp-12">
          <small class="neutral-50">{{ t('activerecord.models.product_type') }}</small>
          <p>{{ item.financing_type }}</p>
        </div>
        <div class="mrp-12 last">
          <small class="neutral-50">{{ t('activerecord.models.credit_type') }}</small>
          <p>{{ item.credit_type }}</p>
        </div>
      </div>
    </div>
    <div class="mb-4">

      <div v-if="item.errand_type.has_template">
        <div v-for="(invItem) in validInventoryItems" :key="invItem.id" class="col-6 message-card warning mb-8 justify-content-between">
          <p>{{ t(`errand_invoice_template.generate_by_inventory_type`, {inventory_type: invItem.inventory_type_name }) }}</p>
          <div class="flex message-banner-actions">
            <button class="button button-primary"  @click="openGenerateInvoice(invItem)">{{ t(`views.generate`) }}</button>
          </div>
        </div>
      </div>

      <div v-if="canInitializeTotalCheck" class="col-6 message-card warning mb-8 justify-content-between">
        <p>{{ t(`total_check.new_process`) }}</p>
        <div class="flex message-banner-actions">
          <button class="button button-primary"  @click="$bvModal.show('total-check')">{{ t(`total_check.start_process`) }}</button>
        </div>
      </div>

      <div v-if="canUploadApproved" class="col-6 message-card warning mb-8 justify-content-between">
        <div class="flex message-banner-actions">
          <button class="button button-primary"  @click="$bvModal.show('total-check-approved')">{{ t(`total_check.upload_approved`) }}</button>
        </div>
      </div>

      <div v-if="totalCheckStatusError">{{totalCheckStatusError}}</div>

      <div v-if="isTotalCheckErrand" class="col-6 message-card warning mb-8 justify-content-between">
        <p>{{ t('errand.update_total_check_status_text', { errand_type_name: item.errand_type.name }) }}</p>
        <div class="flex message-banner-actions">
          <button class="button button-primary flex-center"  @click="updateStatus">
            <b-spinner variant="light" size="sm" v-if="isgettingStatus" class="ml-4 primary-spinner"></b-spinner>
            <span v-else > {{ t('views.update') }} </span>
          </button>
        </div>
      </div>

      <div v-if="totalCheckStatus">{{totalCheckStatus}}</div>

      <div v-if="hasRejectedStatus(item.total_check_status)" class="message-card bg-error-15 mb-8">
        <em class="fas fa-lg fa-exclamation-circle error-100 mr-3"></em>
        <p>{{ t(`total_check.errors.failed_process`) }}</p>
      </div>

      <h4 class="title t2">{{ t(`modal_mass_edit_sub_state.change_sub_state_errand`) }}</h4>
      <div class="table-responsive mtp-12 mb-4">
        <table class="table b-table">
          <thead>
            <tr>
              <th>{{ t(`activerecord.models.errand_type`) }}</th>
              <th>{{ t(`modal_mass_edit_sub_state.current_sub_state`) }}</th>
              <th></th>
              <th>{{ t(`modal_mass_edit_sub_state.change_to_sub_status`) }}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{ item.errand_type.name }}</td>
              <td>{{ item.sub_status.name }}</td>
              <td><i class="fas fa-arrow-right" v-bind:style="styleArrow"></i></td>
              <td>
                <select-input
                  :options="subStatusesList"
                  placeholder="Seleciona el sub estado"
                  input-width="auto"
                  v-on:onChange="onChangeState"/>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div v-for="(file, index) in errandFiles" :key="file.id" class="container-modal m-inline info-15">
        <div class="flex-space-between responsive">
          <div class="mrp-8">
            <a :href="file.file_url" class="title" target="_blank"> {{ file.name }} </a>
          </div>
          <div class="mrp-4">
            <button @click="deleteFile(index, file.id, 'errand')" :disabled="file.disabled" class="button button-negative button-sm">{{ t('views.remove') }}</button>
          </div>
        </div>
      </div>

      <invoice-files
        :files="bsaleInvoiceFiles"
      />

      <div :class="TotalCheckStyle(item.total_check_status)" v-if="item.total_check_status != 'inicializando'">
        <div class="flex">
          <em class="text-success fas fa-check-circle mt-2 mr-2" v-if="item.total_check_status == 'success'"></em>
          <em class="text-danger fas fa-times-circle mt-2 mr-2" v-if="item.total_check_status == 'failed'"></em>
          <div>
            <p>{{ t(`activerecord.models.errand`) }} {{ item.errand_type.name }}</p>
          </div>
        </div>
        <div class="text-primary-100">
          <span class="p-medium">{{ t(`total_check.status_message.${item.total_check_status}`).toUpperCase() }}</span>
          <em class="fas fa-solid fa-file-export primary-100 ml-4" v-if="item.total_check_status == 'initialized'"></em>
          <em class="fas fa-solid fa-envelope-open-text primary-100 ml-4" v-if="item.total_check_status == 'validation'"></em>
          <div v-if="processingTotalCheck">
            <small class="primary-60">* {{ t(`total_check.delay`) }}</small>
          </div>
        </div>
      </div>

      <b-form-file
        :disabled="errandFileInputDisabled"
        v-model="errandFile"
        :placeholder="t('errand.doc_errand_placeholder')"
        drop-placeholder="Drop file here..."
        accept="image/jpeg, image/jpg, image/png, .pdf"
        :browse-text="t('views.search').toUpperCase()"
        class="button button-dashed button-sm file rounded"
        ref="errand-input"
      >
      </b-form-file>

      <h4 class="title t2 mt-4">{{ t('modal_add_errand.errand_extra_info') }}</h4>
      <div class="table-responsive mtp-12 mb-4">
        <table class="table b-table">
          <thead>
            <tr>
              <th>{{ t('activerecord.attributes.errand.errand_type_document') }}</th>
              <th>{{ t('activerecord.attributes.errand.document_number') }}</th>
              <th>{{ t('activerecord.attributes.errand.amount') }}</th>
              <th>{{ t('activerecord.attributes.errand.errand_provider') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <b-form-select
                  id="errand-document-type"
                  v-model="costsData.errand_type_document_id"
                  :options="document_type_options"
                  required
                ></b-form-select>
              </td>
              <td>
                <b-form-input
                  id="errand-document_number"
                  type="text"
                  v-model="costsData.document_number"
                  required
                ></b-form-input>
              </td>
              <td>
                <b-form-input
                  id="errand-amount"
                  type="number"
                  v-model="costsData.amount"
                  required
                ></b-form-input>
              </td>
              <td>
                <b-form-select
                  id="errand-errand_provider"
                  v-model="costsData.errand_provider_id"
                  :options="errand_provider_options"
                  required
                ></b-form-select>
              </td>
            </tr>
          </tbody>
        </table>

        <div v-for="(file, index) in invoiceFiles" :key="file.id" class="container-modal m-inline info-15">
          <div class="flex-space-between responsive">
            <div class="mrp-8">
              <a :href="file.file_url" class="title" target="_blank"> {{ file.name }} </a>
            </div>
            <div class="mrp-4">
              <button @click="deleteFile(index, file.id, 'invoice')" :disabled="file.disabled" class="button button-negative button-sm">{{ t('views.remove') }}</button>
            </div>
          </div>
        </div>

        <b-form-file
          :disabled="invoiceFileInputDisabled"
          v-model="invoiceFile"
          :placeholder="t('errand.doc_invoice_placeholder')"
          drop-placeholder="Drop file here..."
          accept="image/jpeg, image/jpg, image/png, .pdf"
          :browse-text="t('views.search').toUpperCase()"
          class="button button-dashed button-sm file rounded"
          ref="invoice-input"
        >
        </b-form-file>
      </div>
    </div>

    <div>
      <h4 class="title t2 mb-4">{{ t(`errand.add_comment`)}}</h4>
      <b-form-textarea
        id="textarea-default"
        :placeholder="`${t('errand.input_comment')}`"
        v-model="currentComment"
      ></b-form-textarea>
    </div>

    <ChangeHistroy
      ref="changeHistoryComponent"
      :getHistoryFunction="getHistory"
      :modelId="item.id"
    />

    <div class="flex">
      <button class="footer-button base-button outline mrp-24" @click="$bvModal.hide('modal-edit-state')">{{ t(`views.cancel`) }}</button>
      <button class="footer-button base-button" @click="confirmEvent" :disabled="!canSave">{{ t(`views.change`) }}</button>
    </div>

    <b-modal id="modal-error" hide-footer size="sm">
      <div class="container-modal text-center">
        <div class="text-red-500"><em class="fas fa-exclamation-triangle fa-lg"></em></div>
        <p>
          {{ t('errand.doc_error_message') }}
        </p>

        <b-button class="btn btn-primary" variant="primary" @click="$bvModal.hide('modal-error')">{{ t('views.accept') }}</b-button>
      </div>
    </b-modal>
    <b-modal id="modal-success" hide-footer size="sm">
      <div class="container-modal text-center">
        <div class="text-green-500"><em class="fas fa-check-circle fa-lg"></em></div>
        <p>
          {{ t('errand.doc_success_message', { file_name: this.file_name_charged } ) }}
        </p>

        <b-button class="btn btn-primary" variant="primary" @click="$bvModal.hide('modal-success')">{{ t('views.accept') }}</b-button>
      </div>
    </b-modal>
    <b-modal id="modal-deleted" hide-footer size="sm">
      <div class="container-modal text-center">
        <div class="text-red-500"><em class="fas fa-trash fa-lg"></em></div>
        <p>
          {{ t('errand.doc_deleted_message') }}
        </p>

        <b-button class="btn btn-primary" variant="primary" @click="$bvModal.hide('modal-deleted')">{{ t('views.accept') }}</b-button>
      </div>
    </b-modal>
    <generate-invoice
      :client="item.rut_and_name"
      :address="item.address"
      :creditId="item.credit_id"
      :errand-id="item.id"
      :errand-type-id="item.errand_type.id"
      :inventory-item="selectedInventoryItem"
      :operational-check="item.operational_check"
      :type-credit="item.type_credit"
      @generatingInvoice="generatingNewInvoice"
      @invoiceGenerated="newInvoiceGenerated"
    />
    <new-total-check-process
      :errand-id="item.id"
      :client="item.rut_and_name"
      @processRequested="changeTotalCheckStatus"
    />

    <updaload-approved-total-check
      :errand-id="item.id"
      @processRequested="changeTotalCheckStatus"
    />

  </div>
</template>
<script>
import i18n from "../mixins/i18n"
import ChangeHistroy from "./ChangeHistory.vue"
import capitalize from "../mixins/capitalize"
import SelectInput from '../dynamics_form/SelectInput.vue';
import errands from '../../api/errands'
import GenerateInvoice from './GenerateInvoice.vue';
import InvoiceFiles from './InvoiceFiles.vue';
import UpdaloadApprovedTotalCheck from './updaloadApprovedTotalCheck.vue';

export default {
  components: { SelectInput, ChangeHistroy, GenerateInvoice, InvoiceFiles, UpdaloadApprovedTotalCheck },
  name: "modal-edit-state",
  mixins: [i18n, capitalize],
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    subStatusesList: Array,
    confirmEventModal: Function,
  },
  data() {
    return {
      selectedState: '',
      styleArrow: {
        color: '#484848',
        fontSize: '16.5px'
      },
      errandFile: null,
      errandFileInputDisabled: false,
      invoiceFile: null,
      invoiceFileInputDisabled: false,
      errandFiles: [],
      invoiceFiles: [],
      bsaleInvoiceFiles: [],
      costsData: {
        errand_type_document_id: null,
        document_number: '',
        amount: '',
        errand_provider_id: null
      },
      document_type_options: [],
      errand_provider_options: [],
      file_name_charged: '',
      currentComment: null,
      selectedInventoryItem: {},
      totalCheckStatus: '',
      isTotalCheckErrand: false,
      totalCheckStatusError: '',
      isgettingStatus: false
    }
  },
  methods: {
    onChangeState(value) {
      this.selectedState = value
    },
    clearSelectedState() {
      this.selectedState = ''
    },
    confirmEvent() {
      this.$emit(
        'confirmEventModal',
        {
          items: [this.item.id],
          status: this.selectedState,
          itemsObj: [this.item],
          costsData: this.costsData,
          hasCostsData: this.hasCostsData,
          costsDataChanged: this.costsDataChanged,
          comment: this.currentComment
        }
      )
      this.$bvModal.hide('modal-edit-state')
    },
    updateHistory: async function(){
      await this.$refs.changeHistoryComponent.getHistory()
    },
    getHistory: errands.changeHistory,

    uploadFile: async function (file, docType){
      let result = await errands.uploadFile(file, this.item.id, docType)

      if(result.status === 422)
        this.$bvModal.show('modal-error')
      else if(result.id){
        this.file_name_charged = result.name
        this.$bvModal.show('modal-success')
      }

      return result
    },
    getDocs: async function (){
      this.errandFiles = await errands.getFiles(this.item.id, 'errand')
      this.invoiceFiles = await errands.getFiles(this.item.id, 'invoice')
      this.bsaleInvoiceFiles = await errands.getFiles(this.item.id, 'bsale_invoice')
    },
    deleteFile: async function(index, id, docType){
      this[`${docType}Files`][index].disabled = true

      await errands.deleteFile(this.item.id, id)

      this[`${docType}Files`] = this[`${docType}Files`].filter((val) => val.id !== id)

      this.$bvModal.show('modal-deleted')
    },
    generatingNewInvoice(name) {
      const res = {
        name: name,
        status: 'processing'
      }
      this.bsaleInvoiceFiles.push(res)
    },
    newInvoiceGenerated(invoice) {
      const newInvoice = this.bsaleInvoiceFiles[this.bsaleInvoiceFiles.length - 1]
      newInvoice['status'] = invoice.status
      newInvoice['file_url'] = invoice.file_url
      newInvoice['data'] = invoice.data
    },
    openGenerateInvoice(invItem) {
      this.selectedInventoryItem = invItem
      this.$bvModal.show('generate-invoice')
    },
    bsaleInvoiceStyle(status) {
      const style = ['document', 'mb-3']
      if (status == 'failed') {
        style.push('failed')
      }
      return style
    },
    TotalCheckStyle(status) {
      const style = ['mrp-8', 'flex', 'document', 'mb-3']
      if (status == 'failed') {
        style.push('failed')
      } else if (status == 'success') {
        style.push('success')
      }
      return style
    },
    changeTotalCheckStatus() {
      this.item.total_check_status = 'requested'
    },
    updateTotalCheckStatusAndDocs()
    {
      this.changeTotalCheckStatus()
      this.getDocs()
    },
    updateStatus: async function(){
      this.isgettingStatus = true
      let response = (await errands.updateTotalCheckStatus(this.item.id))

      if(response.status === 400)
        this.totalCheckStatusError = response.data.message
      else if(response.status === 200){
        this.totalCheckStatus = response.data.message
        this.getDocs()
        this.isTotalCheckErrand = false
      }

      this.isgettingStatus = false
    },
    hasRejectedStatus: function(status) {
      return ['cancelado', 'devuelto', 'rechazado', 'failed'].includes(status)
    }
  },
  mounted: async function() {
    this.errand_provider_options = [{value: null, text: this.t('activerecord.models.errand_provider', {count: 1}), disabled: true }]
      .concat((await errands.getProviders()).map(provider => ({text: provider.name, value: provider.id})))
    this.document_type_options = [{value: null, text: this.t('activerecord.models.errand_type_document', {count: 1}), disabled: true }]
      .concat((await errands.getTypeDocuments()).map(provider => ({text: provider.name, value: provider.id})))

    this.getDocs()

    this.costsData = {
      errand_type_document_id: this.item?.errand_type_document_id || '',
      document_number: this.item?.document_number || '',
      amount: this.item?.amount || '',
      errand_provider_id: this.item?.errand_provider_id || ''
    }

    console.log(this.item)

    this.isTotalCheckErrand = ['totalcheck', 'total check', 'total_check'].includes(this.item.document_service_provider.toLowerCase()) && this.item.base_status !== 'Finalizado' && this.item.total_check_status == 'requested'
  },
  computed: {
    isSelected() {
      return this.selectedState === ''
    },
    canSave(){
      return this.selectedState !== '' || this.costsDataChanged || this.currentComment
    },
    hasCostsData(){
      return this.costsData?.errand_type_document_id && this.costsData?.document_number && this.costsData?.amount && this.costsData?.errand_provider_id
    },
    costsDataChanged() {
      return (
        this.costsData?.errand_type_document_id !==  this.item?.errand_type_document_id ||
        this.costsData?.document_number !== this.item?.document_number ||
        this.costsData?.amount !== this.item?.amount ||
        this.costsData?.errand_provider_id !== this.item?.errand_provider_id
      )
    },
    setHistory() {
      return this.item.history?.reduce((acc, item, ind, src) => {
        if (ind+1 == this.item.history.length)
          return acc
        return acc + `<div class="flex-space-between responsive">
                        <div class="flex align-items-center list-procedure-history">
                            <p class="procedure-history">${item?.name}</p>
                            <i class="fas fa-arrow-right"></i>
                            <p class="procedure-history">${src[ind+1]?.name}</p>
                        </div>
                        <h5 class="title t5">${item.date} - ${this.item.responsible.name} </h5>
                      </div>`
      }, '')
    },
    validInventoryItems() {
      const succesfullBsaleInvoices = this.bsaleInvoiceFiles.filter(
        file => file.status == 'success'
      )
      const successfullInventoryTypes = succesfullBsaleInvoices.map(invoice => invoice.data.inventory_type_id)
      return this.item.inventory_items.filter(
        invItem => !successfullInventoryTypes.includes(invItem.inventory_type_id)
      )
    },
    canInitializeTotalCheck() {
      return this.item.errand_type.has_document_service && this.item.total_check_status == 'inicializando'
    },
    canUploadApproved() {
      return this.item.total_check_status == 'inicializando' &&  this.item.errand_type.name.toLowerCase().includes('homologado')
    },
    processingTotalCheck() {
      const activeStatuses = ['requested', 'initialized', 'validation']
      return activeStatuses.includes(this.item.total_check_status)
    }
  },
  watch: {
    item: function(newValue) {
      this.costsData = {
        errand_type_document_id: newValue?.errand_type_document_id || '',
        document_number: newValue?.document_number || '',
        amount: newValue?.amount || '',
        errand_provider_id: newValue?.errand_provider_id || ''
      }
    },
    'item.errand_type_document_id': function(newValue){
      this.costsData.errand_type_document_id = newValue
    },
    'item.document_number': function(newValue){
      this.costsData.document_number = newValue
    },
    'item.amount': function(newValue){
      this.costsData.amount = newValue
    },
    'item.errand_provider_id': function(newValue){
      this.costsData.errand_provider_id = newValue
    },
    errandFile: async function(newValue){
      if(!newValue) return

      this.errandFileInputDisabled = true
      let res = await this.uploadFile(newValue, 'errand')
      if(res.id)
        this.errandFiles.push(res)

      this.errandFileInputDisabled = false
      this.$refs['errand-input'].reset()
    },
    invoiceFile: async function(newValue){
      if(!newValue) return

      this.invoiceFileInputDisabled = true
      let res = await this.uploadFile(newValue, 'invoice')
      if(res.id)
        this.invoiceFiles.push(res)

      this.invoiceFileInputDisabled = false
      this.$refs['invoice-input'].reset()
    }
  }
};
</script>
<style>
  .list-procedure-history .fa-arrow-right {
    width: 10px;
    color: #484848;
  }
  .list-procedure-history .procedure-history {
    width: 110px;
    margin-right: 35px !important;
  }
  .list-procedure-history .procedure-history:last-child  {
    margin-right: 0px !important;
    margin-left: 35px !important;
  }
  .container-errands-history {
    max-height: 200px;
    overflow: auto;
  }
  .document {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
    gap: 351px;
    background: #FFFFFF;
    border: 1px solid #AB8CF1;
    border-radius: 12px;
    color: #733FE7;
  }
  .failed {
    border: 1px solid #FF6766;
    color: #DD2122;
  }
  .success {
    border: 1px solid #6EB57E;
    color: #0E8428;
  }
</style>
