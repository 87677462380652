<template>
  <div>
    <div class="d-flex align-items-center mt-3 mb-3" >
      <div class="d-flex cursor-pointer" @click="setShowCreateForm">
        <div v-show="stepOneCompleted " :class="`text-success mr-2`" > <i class="fas fa-check-circle fa-lg"></i> </div>
        <div v-if="!stepOneCompleted" :class="`${!stepOneCompleted && showCreateForm ? 'dot-active' : 'dot-inactive'}`">1</div>
        <span>{{t('errand_type.form_step_one')}}</span>
      </div>

      <div class="stepper-horizontal-divider" ></div>

      <div class="d-flex cursor-pointer" @click="setShowTriggerForm" >
        <div v-show="stepTwoCompleted " class="text-success mr-2" > <i class="fas fa-check-circle fa-lg"></i> </div>
        <div v-if="!stepTwoCompleted" :class="`${!stepTwoCompleted && showTriggerForm ? 'dot-active' : 'dot-inactive'}`">2</div>
        <span>{{t('errand_type.form_step_two')}}</span>
      </div>
    </div>

    <b-alert v-if="showAlert" class="" show :variant="alertVariant">{{alertText}}</b-alert>

    <div v-if="!showTriggerForm">
      <div class="row mb-4">
        <div class="col-md-4">
            <b-form-group
              :label="t('activerecord.attributes.errand_type.name')"
              label-class="text-capitalize"
              invalid-feedback="Nombre es requerido"
            >
              <b-form-input type="text" v-model="errand_type.name"></b-form-input>
              <span class="text-danger" v-if="errors.name && typeof errors.name === 'string'">{{errors.name}}</span>
            </b-form-group>
        </div>
        <div class="col-md-3">
            <b-form-group
              disabled
              :label="t('activerecord.attributes.errand_type.limit_days')"
              label-class="text-capitalize"
            >
              <b-form-input type="number" v-model="errand_type.limit_days"></b-form-input>
              <span class="text-danger" v-if="errors.limit_days && typeof errors.limit_days === 'string'">{{errors.limit_days}}</span>
            </b-form-group>
        </div>

        <div class="col-md-1 d-flex flex-row align-items-end pb-3">
          <label for="">{{t('views.day')}}(s)</label>
        </div>


        <div class="col-md-4">
          <b-form-group
            :label="t('activerecord.attributes.errand_type.account')"
            label-class="text-capitalize"
          >
            <dropdown-filter
              :list-check="[errand_type.account_id]"
              :items="responsiblesList"
              :radio-inputs="true"
              v-on:onFilter="responsibleSelected"
              :text="t(`modal_add_errand.responsible_procedure`)"/>

              <span class="text-danger" v-if="errors.account_id && typeof errors.account_id === 'string'">{{errors.account_id}}</span>
          </b-form-group>
        </div>
      </div>

      <div class="row mb-12">
        <div class="d-flex align-items-center col-8">
          <label class="form-checkbox">
            <span class="regular">{{ t(`errand_type.with_errand_template`) }}</span>
            <input type="checkbox" v-model="errand_type.has_template"/>
            <span class="checkmark"></span>
          </label>
        </div>

        <div class="col-4">
          <b-form-group :label="t('activerecord.models.document_service')" invalid-feedback="tipo es requerido">
            <div class="d-flex">
              <b-form-select v-model="errand_type.document_service_id" :options="documentServicesOptions" required></b-form-select>
            </div>
            <span class="text-danger" v-if="errors.document_service_id" >{{ errors.document_service_id }}</span>
          </b-form-group>
        </div>
      </div>

      <div class="row mb-4">
        <div class="col-12">
          <h6>{{t('errand_type.form_step_one_title')}}</h6>
        </div>

        <div v-if="errors.sub_statuses.length > 0">
          <b-alert class="col-12" show variant="danger">
            <ul>
              <li v-for="error in errors.sub_statuses" :key="error"> {{error}} </li>
            </ul>
          </b-alert>
        </div>

        <div class="col-12" >
          <div class="table-header mb-2">
            <div class="row col ">
              <div class="col-md-2">{{t('activerecord.attributes.sub_status.order')}}</div>
              <div class="col-md-3">{{t('activerecord.attributes.sub_status.general_status')}}</div>
              <div class="col-md-3">{{t('activerecord.attributes.sub_status.name')}}</div>
              <div class="col-md-2">{{t('activerecord.attributes.sub_status.limit_days')}}</div>
              <div class="col-md-2"></div>
            </div>
          </div>
          <div>
            <draggable v-model="subStatusesOrdered" draggable=".item">
              <div
                v-for="subStatus in subStatusesOrdered" :key="subStatus.id || subStatus._uid"
                class="mb-2 border-bottom pb-2 item"
              >
                <sub-status-form
                  :readonly="true"
                  :init-field="subStatus"
                  :statuses="statuses"
                  @addSubStatus="addSubStatus"
                  @updateSubStatus="updateSubStatus"
                  @deleteSubStatus="deleteSubStatus"
                />
              </div>
            </draggable>
          </div>

          <div class="" v-if="showAddSubStatus">
              <sub-status-form
                :initial-order="subStatusesOrdered.length + 1"
                :statuses="statuses"
                @addSubStatus="addSubStatus"
              />
          </div>
          <b-form-group v-else>
            <b-button variant="btn btn-outline-primary" class="w-100"  type="submit" @click="toggleShowAddSubStatus"> {{t('views.create_with_model', {model_name: t('activerecord.models.sub_status') })}} </b-button>
          </b-form-group>
        </div>
      </div>

      <div class="col">
        <b-form-group class="row flex-column mb-2 mt-4" >
          <b-link href="#" class="btn btn-outline-primary" @click="$bvModal.hide('new-errand')" >{{t('views.cancel')}}</b-link>
          <b-button variant="primary" class=""  type="submit" :disabled="isSaving" @click="saveStepOne">
            <b-spinner variant="light" type="grow" size="sm" v-if="isSaving"></b-spinner>
            {{ t('views.continue') }}
          </b-button>
        </b-form-group>
      </div>
    </div>

    <div class="row" v-else>
      <div class="col mt-3">
        <h6>{{t('errand_type.form_step_two_title')}}</h6>
          <div>
            <div
              v-for="trigger in errand_type.errand_triggers_attributes" :key="trigger.id || trigger._id"
              class="mb-2 pb-2"
            >
              <trigger-form
                :init-field="trigger"
                :sub-statuses="errand_type.sub_statuses_attributes"
                :targetModel="modelName"
                :model-id="modelId"
                :model-name="modelName"
                :errand-type-id="errand_type.id"
                @addTrigger="addTrigger"
                @updateTrigger="updateTrigger"
                @deleteTrigger="deleteTrigger"
              />
            </div>
          </div>

        <div class="col">
          <b-form-group class="row flex-column mb-2 mt-4" >
            <b-link href="#" class="btn btn-outline-primary" @click="$bvModal.hide('new-errand')" >{{t('views.cancel')}}</b-link>
            <b-button variant="primary" class=""  type="submit" :disabled="isSaving" @click="saveStepTwo">
              <b-spinner variant="light" type="grow" v-if="isSaving"></b-spinner>
              {{ t('views.save') }}
            </b-button>
          </b-form-group>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
  import i18n from "../mixins/i18n"
  import { v4 as uuidv4 } from 'uuid';
  import draggable from 'vuedraggable'
  import Box from '../template/Box.vue'
  import CardItem from '../template/CardItem.vue'
  import TriggerForm from './TriggerForm.vue'
  import SubStatusForm from './SubStatusForm.vue'
  import errandsAPI from '../../api/errands'
  import DropdownFilter from '../dynamics_form/DropdownFilter.vue'

  const DEFAULT_ERRORS = {
    name: null,
    limit_days: null,
    sub_statuses: [],
    document_service_id: null
  }

  export default {
    name: 'errand-type-form',
    components: {
      draggable,
      Box,
      CardItem,
      SubStatusForm,
      TriggerForm,
      DropdownFilter
    },
    mixins: [i18n],
    props: [
      'errandType',
      'errandTypeId',
      'isNew',
      'title',
      'modelId',
      'modelName'
    ],
    data() {
      return {
        errand_type: {
          id: this.errandTypeId,
          product_type_ids: [],
          inventory_type_ids: [],
          name: '',
          limit_days: 0,
          sub_statuses_attributes: [],
          errand_triggers_attributes: [],
          account_id: this.errandType?.account_id || '',
          has_template: false,
          document_service_id: null
        },
        documentServicesOptions: [],
        sub_statuses_attributes: [],
        errors: DEFAULT_ERRORS,
        isSaving: false,
        showAlert: false,
        responsiblesList: Array,
        alertText: '',
        alertVariant: '',
        showAddSubStatus: false,
        // showAddTrigger: false,
        showTriggerForm: false,
        statuses: [],
        stepOneCompleted: false,
        stepTwoCompleted: false,
        showCreateForm: true
      }
    },
    mounted: async function() {
      this.errand_type[`${this.modelName}_ids`] = [this.modelId]

      this.statuses = (await errandsAPI.generalStatusList()).map((val) => ({text: val.name, value: val}))
      if(this.errandType){
        this.errand_type = this.errandType
      }

      this.documentServicesOptions = (await errandsAPI.documentServicesList()).map((val) => ({text: `${val.provider} - ${val.name}`, value: val.id}))
      this.documentServicesOptions = [{ text: ' ', value: null }, ...this.documentServicesOptions]

      if(this.errand_type.sub_statuses_attributes)
        this.sub_statuses_attributes = this.errand_type.sub_statuses_attributes.filter(se => !se._destroy).sort((a,b) => (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0))

      this.responsiblesList = await errandsAPI.responsibleAvailable()

      if(this.errand_type.errand_triggers_attributes.length == 0){
        this.addTrigger({
          triggerable_type: '',
          state: '',
          sub_status_id: '',
          model_triggerable_id: '',
          model_triggerable_type: ''
        })
      }
    },
    computed: {
      subStatusesOrdered: {
        get: function () {
          return this.sub_statuses_attributes.filter(se => !se._destroy).sort((a,b) => (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0))
        },
        // setter
        set: function (newValue) {
          this.sub_statuses_attributes = this.order([...newValue, ...this.sub_statuses_attributes.filter(se => se._destroy)])
        }

      }
    },
    methods: {
      addSubStatus(value) {
        this.sub_statuses_attributes.push({
          _uid: uuidv4(),
          ...value
        })
        this.errand_type.limit_days += Number(value.limit_days) || 0
        this.toggleShowAddSubStatus()
      },
      updateSubStatus(value) {
        let objIndex = this.sub_statuses_attributes.findIndex((obj => obj.id === value.id || obj._uid === value.id));
        this.sub_statuses_attributes[objIndex] = {...value}
      },
      deleteSubStatus(id) {
        let limitDays = 0
        this.sub_statuses_attributes = [...this.sub_statuses_attributes.reduce((acc, element) => {
          if(element.id === id) {
            limitDays = element.limit_days
            acc.push({ ...element, id: element.id, _destroy: 1 })
          } else if(element.id || element._uid !== id) {
            acc.push({...element})
          } else {
            limitDays = element.limit_days
          }

          return acc
          }, [])]

          this.errand_type.limit_days -= Number(limitDays) || 0
      },
      toggleShowAddSubStatus() {
        this.showAddSubStatus = !this.showAddSubStatus
      },
      saveErrandType: async function() {
        this.isSaving = true
        let response;

        this.errand_type.sub_statuses_attributes = this.sub_statuses_attributes.map((value) => {
          return {
            ...value,
            general_status_id: value?.general_status?.id
          }
        })

        let errand_triggers_attributes = [...this.errand_type.errand_triggers_attributes]
        this.errand_type.errand_triggers_attributes = errand_triggers_attributes.filter((value) =>
          value._destroy || (value.state && value.triggerable_type)
        )

        if(this.errand_type.id){
          response = await errandsAPI.update(this.errand_type.id, { errand_type: this.errand_type })
        } else {
          response = await errandsAPI.post({ errand_type: this.errand_type })
        }

        if(response.status === 422){
          this.errors.sub_statuses = Array.isArray(response?.data) ? response?.data : []
          this.errors.name = response?.data?.name ? response.data.name.join(', ') : ''
          this.errors.limit_days = response?.data?.limit_days ? response.data.limit_days.join(', ') : ''
          this.errors.account_id = response?.data?.account ? response.data.account.join(', ') : ''
          this.showAlert = false
          this.isSaving = false
          this.errand_type.errand_triggers_attributes = [...errand_triggers_attributes]

          if(Array.isArray(response?.data))
            this.sub_statuses_attributes = this.sub_statuses_attributes.map((value) => {
              let element = { ...value }
              delete element['_destroy']
              return element
            })

          return false

        } else if (response.id){
          this.errand_type = response
          this.errand_type.errand_triggers_attributes = this.errand_type?.errand_triggers_attributes.length === 0 ?
            [
              ...errand_triggers_attributes.map(val => {
                return { ...val, id: null }
              })
            ] :
            this.errand_type?.errand_triggers_attributes

          this.alertVariant = 'success'
          this.showAlert = true
          this.sub_statuses_attributes = this.errand_type.sub_statuses_attributes
          if(!this.errand_type.id){
            this.alertText = `${this.title} creado correctamente`
          }else{
            this.alertText = `${this.title} actualizado correctamente`
          }

          this.$emit('update')
          this.isSaving = false

          return true
        }
      },
      saveStepOne: async function() {
        let isSaved = await this.saveErrandType()
        if(isSaved){
          this.showTriggerForm = true
          this.stepOneCompleted = true
          this.showCreateForm = false
        }
      },
      saveStepTwo: async function() {
        let isSaved = await this.saveErrandType()
        if(isSaved){
          this.stepTwoCompleted = true
          this.$bvModal.hide('new-errand')
        }
      },

      addTrigger(value) {
        this.errand_type.errand_triggers_attributes.push({
          _uid: uuidv4(),
          ...value
        })

        // this.toggleShowAddTrigger()
      },
      updateTrigger(value) {
        let objIndex = this.errand_type.errand_triggers_attributes.findIndex((obj => obj.id === value.id || obj._uid === value.id));
        this.errand_type.errand_triggers_attributes[objIndex] = {...value}
      },
      deleteTrigger(id) {
        this.errand_type.errand_triggers_attributes = [...this.errand_type.errand_triggers_attributes.reduce((acc, element) => {
          if(element.id === id) {
            acc.push({
              id: element.id,
              _destroy: 1,
              triggerable_type: '',
              state: '',
              sub_status_id: null,
              model_triggerable_id: null,
              model_triggerable_type: ''
            })
          } else if(element.id || element._uid !== id) {
            acc.push({...element})
          }

          return acc
          }, [])]
      },
      setShowTriggerForm() {
        this.showTriggerForm = true
        this.showCreateForm = false
      },
      setShowCreateForm() {
        this.showTriggerForm = false
        this.showCreateForm = true
      },
      order(statusList) {
        statusList = statusList.reduce((acc, curr, currentIndex) => {
          curr.order = currentIndex + 1
          acc.push(curr)
          return acc
        }, [])
        return statusList
      },
      move(arr, old_index, new_index) {
        if (new_index >= arr.length) {
            var k = new_index - arr.length + 1;
            while (k--) {
                arr.push(undefined);
            }
        }
        arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
        return arr; // for testing
      },
      responsibleSelected(item) {
        this.errand_type.account_id = item[0]
      },
    }
  }
</script>
