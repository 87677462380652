const Inputmask = require('inputmask')
const { validate, clean, format } = require('rut.js')

$(document).on('turbolinks:load', () => {
  // TODO: change this id because it is conflicting on new operation form
  let rut = $('#client_rut')
  let btn = $('.btn')

  Inputmask({ "mask": "9[9.999.999]-[9|K|k]" }).mask(rut)
})
