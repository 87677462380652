<template>
  <div class="col-span-8 sm:grid sm:grid-cols-8 text-center">
    <div v-if="templateError" class="col-span-8 flex items-center bg-red-500 text-white text-sm font-bold px-4 py-3" role="alert">
      <svg class="fill-current w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M12.432 0c1.34 0 2.01.912 2.01 1.957 0 1.305-1.164 2.512-2.679 2.512-1.269 0-2.009-.75-1.974-1.99C9.789 1.436 10.67 0 12.432 0zM8.309 20c-1.058 0-1.833-.652-1.093-3.524l1.214-5.092c.211-.814.246-1.141 0-1.141-.317 0-1.689.562-2.502 1.117l-.528-.88c2.572-2.186 5.531-3.467 6.801-3.467 1.057 0 1.233 1.273.705 3.23l-1.391 5.352c-.246.945-.141 1.271.106 1.271.317 0 1.357-.392 2.379-1.207l.6.814C12.098 19.02 9.365 20 8.309 20z"/></svg>
      <p>No hay documentos definido para este tipo de producto</p>
    </div>
    <div v-if="isEmpty" class="col-span-8 flex items-center bg-green-500 text-white text-sm font-bold px-4 py-3" role="alert">
      <svg class="fill-current w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M12.432 0c1.34 0 2.01.912 2.01 1.957 0 1.305-1.164 2.512-2.679 2.512-1.269 0-2.009-.75-1.974-1.99C9.789 1.436 10.67 0 12.432 0zM8.309 20c-1.058 0-1.833-.652-1.093-3.524l1.214-5.092c.211-.814.246-1.141 0-1.141-.317 0-1.689.562-2.502 1.117l-.528-.88c2.572-2.186 5.531-3.467 6.801-3.467 1.057 0 1.233 1.273.705 3.23l-1.391 5.352c-.246.945-.141 1.271.106 1.271.317 0 1.357-.392 2.379-1.207l.6.814C12.098 19.02 9.365 20 8.309 20z"/></svg>
      <p>No se encontraron errores</p>
    </div>
    <warning-table v-if="!isEmpty" :warnings="warnings" :minimumRequirements="minimumRequirements"/>
  </div>
</template>
<script>
  import api from '../../api/product_types'
  import apiSimulation from '../../api/simulations'
  import WarningTable from './WarningTable'

  import 'tailwindcss/dist/base.min.css'
  import 'tailwindcss/dist/components.min.css'
  import 'tailwindcss/dist/utilities.min.css'
  import 'tailwindcss/dist/tailwind.min.css'

  export default {
    name: 'card-document-templates',
    props: [
      'element'
    ],
    components: { WarningTable },
    data: function() {
      return {
        warnings: [],
        templateError: false,
        hasWarnings: false,
        minimumRequirements: []
      }
    },
    methods: {
      getProductType: async function() {
        const params = {
          simulation_id: this.element.id,
          product_type_id: this.element.product_type.id,
          simulation_type: this.element.simulation_type
        }

        return await api.documentTemplateWarnings(params)
      },
      getMinimumRequirements: async function(){
        const params = {
          simulation_id: this.element.id,
        }
        return await apiSimulation.getMinimumRequirements(params)
      },
      productTypeWarnings: async function() {
        const documentType = await this.getProductType()
        if(documentType.document_templates_warning === null) {
          this.templateError = true
          this.hasWarnings = true
        } else {
          this.warnings = documentType.document_templates_warning
          this.hasWarnings = this.warnings.length > 0 || this.minimumRequirements.length > 0
        }
        this.$emit('updatedWarning', this.hasWarnings)
      },
      minimumRequirementWarnings: async function(){
        const minReq = await this.getMinimumRequirements()
        if(minReq !== null){
          this.minimumRequirements = minReq
          this.hasWarnings = this.minimumRequirements.length > 0
        }
        this.$emit('updatedWarning', this.hasWarnings)
      }
    },
    computed: {
      isEmpty: function() {
        return this.warnings.length + this.minimumRequirements.length === 0
      }
    },
    mounted: function() {
      this.minimumRequirementWarnings()
      this.productTypeWarnings()
    }
  }
</script>
<style scoped>
</style>
