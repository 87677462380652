<template>
  <div class="w-100 text-center" style="padding-bottom: 30px;" v-bind:style="{ display: isVisible ? 'block' : 'none' }">
    <div class="icon-search-container">
      <i class="fas fa-search"></i>
      <i class="fas fa-times"></i>
    </div>
    <span class="text-not-found">
      {{ t(`no_found_message.no_found`) }} <span v-if="textNotFound !== ''"> {{ t(`no_found_message.for`) }} <span class="specific-text">{{textNotFound}}</span></span>
    </span>
  </div>
</template>
<script>
import i18n from "../mixins/i18n"
import capitalize from "../mixins/capitalize"

export default {
  name: 'no-found',
  mixins: [i18n, capitalize],
  props: {
    isVisible: Boolean,
    textNotFound: {
      type: String,
      default: ''
    }
  }
}
</script>
<style lang="scss">
  .icon-search-container {
    position: sticky;
    width: 94px;
    height: 94px;
    border-radius: 50px;
    margin: 0 auto;
    padding: 27px;
    background-color: var(--primary-color-20);
    color: var(--primary);

    & > .fa-search {
      font-size: 40px;
    }

    & > .fa-times {
      font-size: 12.5px;
      position: absolute;
      left: 39px;
      top: 37px;
    }
  }

  .text-not-found {
    display: inline-block;
    max-width: 330px;
    margin-top: 24px;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    color: #484848;

    & .specific-text {
      color: var(--primary);
      text-decoration: underline;
    }
  }
</style>
