<template>
  <div class="autocomplete form-control">
    <input
      class="input-autocomplete"
      type="text"
      v-model="search"
      :required="required"
      @input="onChange"
      @keydown.down="onArrowDown"
      @keydown.up="onArrowUp"
      @keydown.enter="onEnter"
    />
    <ul
      class="autocomplete-results"
      v-show="isOpen"
    >
      <li
        v-if="isLoading"
        class="loading"
      >
        {{ t('views.loading') }}...
      </li>
      <li
        v-else
        class="autocomplete-result"
        v-for="(result, i) in results"
        :key="i"
        @click="setResult(result)"
        :class="{ 'is-active': i === arrowCounter }"
      >
        {{ result.text }}
      </li>
    </ul>
  </div>
</template>

<script>
  import i18n from "../../mixins/i18n"

  export default {
    mixins: [i18n],
    name: 'SearchAutocomplete',
    props: {
      items: {
        type: Array,
        required: false,
        default: () => [],
      },
      fnToCall: {
        type: Function,
        required: false,
        default: false,
      },
      value: {
        type: String,
        required: false,
        default: false,
      },
      required: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    data() {
      return {
        search: this.value || '',
        results: [],
        selected: {},
        isOpen: false,
        arrowCounter: -1,
        isLoading: false,
      };
    },
    mounted() {
      document.addEventListener('click', this.handleClickOutside);
    },
    destroyed() {
      document.removeEventListener('click', this.handleClickOutside);
    },
    methods: {
      filterResults() {
        this.results = this.items.filter(item => item.toLowerCase().indexOf(this.search.toLowerCase()) > -1);
      },
      onChange: async function() {
        if(this.search.length < 3) return

        if (this.fnToCall) {
          this.isLoading = true;
          let results = await this.fnToCall(this.search)
          this.results = results.map(val => ({ text: val.name, value: val.id }))
          this.isLoading = false;
          this.isOpen = true;
        } else {
          this.filterResults();
          this.isOpen = true;
        }
      },
      setResult(result) {
        this.search = result.text;
        this.selected = result
        this.$emit('setSelected', this.selected);
        this.isOpen = false;
      },
      handleClickOutside(event) {
        if (!this.$el.contains(event.target)) {
          this.arrowCounter = -1;
          this.isOpen = false;
        }
      },
      onArrowDown() {
        if (this.arrowCounter < this.results.length) {
          this.arrowCounter = this.arrowCounter + 1;
        }
      },
      onArrowUp() {
        if (this.arrowCounter > 0) {
          this.arrowCounter = this.arrowCounter - 1;
        }
      },
      onEnter() {
        this.search = this.results[this.arrowCounter];
        this.arrowCounter = -1;
        this.isOpen = false;
      }
    }

  };
</script>

<style>
  .autocomplete {
    position: relative;
  }

  .input-autocomplete {
    width: 100%;
    border: 0;
  }

  .autocomplete-results {
    padding: 0;
    margin: 0;
    border: 1px solid #eeeeee;
    height: 120px;
    min-height: 1em;
    max-height: 6em;
    overflow: auto;
    position: absolute;
    width: 100%;
    z-index: 100;
    left: 0;
  }

  .autocomplete-result {
    list-style: none;
    text-align: left;
    padding: 4px 2px;
    cursor: pointer;
    width: 100%;
    z-index: 1000;
    background: #e9ecef;
    border: 1px solid #ced4da;
  }

  .autocomplete-result.is-active,
  .autocomplete-result:hover {
    background-color: #4AAE9B;
    color: white;
  }
</style>
