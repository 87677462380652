<template>
  <div>
    <div class="card">
      <div class="card-body">
        <b-form-group
          id="input-group"
          label-class="font-bold"
          label-cols-sm="12"
          label-cols-md="4"
          content-cols-sm="12"
          content-cols-md="8"
          :label="`${t('activerecord.attributes.simulation.special_installments')}`"
          label-for="input-1"
        >
          <b-input-group>
            <b-input type="number" v-model="period" :placeholder="`${t('activerecord.attributes.simulation.period')}`"/>
            <b-input type="number" v-model="installment_value" :placeholder="`${t('activerecord.attributes.simulation.installment_value_with_IVA')}`"/>
            <b-button class="form-control p-0 w-0" block @click="addInstallment" variant="primary">{{ t(`views.add`) }}</b-button>
          </b-input-group>
        </b-form-group>
        <b-form-group
          id="input-group"
          label-class="font-bold"
          label-cols-sm="12"
          label-cols-md="4"
          content-cols-sm="12"
          content-cols-md="8"
          :label="`${t('activerecord.attributes.simulation.periods')}`"
          label-for="input-1"
        >
          <div class="pt-1" v-if="this.installments.length === 0">
            {{ t(`activerecord.attributes.simulation.no_periods_added`) }}
          </div>
          <div class="extra-costs-list" v-else>
            <b-badge
              class="badge-style mrp-4"
              v-for="installment in installments" :key="installment.period"
              variant="light"
              pill
            >
              {{ t('activerecord.attributes.simulation.period') }} {{ installment.period }}: {{ installment.installment_value | currency('$', 0, { thousandsSeparator: '.', decimalSeparator: ',' }) }}
              <button class="btn-delete" @click="removeInstallment(installment)">
                <i class="delete fas fa-times"></i>
              </button>
            </b-badge>
          </div>
        </b-form-group>
      </div>
    </div>
  </div>
</template>

<script>
import i18n from "../mixins/i18n"
import capitalize from "../mixins/capitalize"
import apiSimulation from '../../api/simulations'

export default {
  name: 'InstallmentsForm',
  mixins: [i18n, capitalize],
  components: {},
  props: [
    'simulationId'
  ],
  data: function() {
    return {
      installments: [],
      installment_value: null,
      period: null,
    }
  },
  methods: {
    addInstallment: function() {
      const installment = {
        period: this.period || 1,
        installment_value: this.installment_value || 0,
      }
      let answer = confirm(`¿Estás seguro(a) que quieres ingresar la cuota ${installment.period} con valor de ${installment.installment_value}?`)
      if (answer) {
        this.installments = this.installments.filter((installment) => installment.period !== this.period)

        this.installments.push(installment)
      }
      this.period = null
      this.installment_value = null
    },
    removeInstallment: function(installment) {
      const index = this.installments.indexOf(installment)
      this.installments.splice(index, 1)
    },
    getSimulation: async function() {
      const response = await apiSimulation.getSpecialInstallments(this.simulationId)
      if(response.status === 200)
        this.installments = response.data
    }
  },
  watch: {
    installments: function() {
      this.$emit('installmentsUpdated', this.installments)
    },
  },
  mounted: function() {
    this.getSimulation()
  }
}
</script>
<style>
  .badge-style {
    background-color: #6a6a6a !important;
    color: #fff !important;
    cursor: default;
    padding: 6px 8px 7px 8px !important;
  }
  .badge-style button {
    padding: unset !important;
    margin-left: 5px;
    height: auto;
  }
  .badge-style .btn-delete {
    background-color: unset;
    border: unset;
    color: #fff;
  }
  .badge-style .btn-delete:hover {
    color: rgb(255 167 167);
  }
</style>
