<template>
  <div>
    <div class="container-fluid">
      <div class="row mb-3">
        <div class="col-5 pr-0">
          <div class="card">
            <div class="card-header py-3 d-flex flex-row align-item-center justify-content-between">
              <h6 class="m-0 font-weight-bold text-primary">Datos para leasing</h6>
            </div>
            <div class="card-body">
              <dl class="row">
                <dt class="col-5">
                  Monto Bruto
                </dt>
                <dd class="col-7">{{ grossAmount | currency('$', 0, { thousandsSeparator: '.', decimalSeparator: ',' })  }}</dd>
                <dt class="col-5">
                  Monto Líquido
                </dt>
                <dd class="col-7">{{ netAmount | currency('$', 0, { thousandsSeparator: '.', decimalSeparator: ',' })  }}</dd>

                <dt class="col-5">
                  Operación:
                </dt>
                <dd class="col-7">{{ leasing.code }}</dd>

                <dt class="col-5">
                  Nombre del titular:
                </dt>
                <dd class="col-7">{{ clientName }}</dd>

                <dt class="col-5">
                  Nombre del aval:
                </dt>
                <dd class="col-7">{{ guarantorName }}</dd>
              </dl>
            </div>
          </div>
        </div>
        <div class="col-7">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <dt class="col-4">Licencia</dt>
                <dt class="col-8">
                  <input type="checkbox" id="checkbox" v-model="driversLicense">
                </dt>
              </div>
              <div class="row">
                <dt class="col-4">Tipo de Producto</dt>
                <dd class="col-8">
                  <div class="row">
                    <div class="col-8 pr-0">
                      <b-form-select
                      v-model="selectedProductTypeId"
                      :options="this.productTypesList"
                      value-field="id"
                      text-field="name"
                      ></b-form-select>
                    </div>
                    <div class="col-4 pl-1">
                      <b-button block @click="addProductTypeExtraCosts" variant="primary">Agregar costos predefinidos</b-button>
                    </div>
                  </div>
                </dd>
              </div>
              <div class="row">
                <dt class="col-4">Costos asociados</dt>
                <dd class="col-8">
                  <div class="row">
                    <div class="col-5 pr-0">
                      <b-form-select
                      v-model="selectedExtraCostName"
                      :options="this.extraCostsList"
                      value-field="name"
                      text-field="human_name"
                      ></b-form-select>
                    </div>
                    <div class="col-3 pr-0 pl-1">
                      <b-input v-model="selectedExtraCostAmount"></b-input>
                    </div>
                    <div class="col-4 pl-1">
                      <b-button block @click="addExtraCost" variant="primary">Agregar</b-button>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 mt-3">
                      <ul class="extra-costs-list">
                        <li v-for="item in selectedExtraCosts.filter((ec) => ec._destroy !== 1)" :key="item.name">
                          {{ item.human_name }}: {{ item.amount | currency('$', 0, { thousandsSeparator: '.', decimalSeparator: ',' }) }}
                          <b-button @click="removeExtraCost(item.name)" variant="link" size="sm" title="Eliminar">
                            <i class="delete fas fa-times"></i>
                          </b-button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </dd>
                <dt class="col-5">
                  Total costos extra
                </dt>
                <dd class="col-7">
                  {{ this.extraCostsForGrossAmount | currency('$', 0, { thousandsSeparator: '.', decimalSeparator: ',' }) }}
                </dd>
              </div>
            </div>
          </div>
          <br />
          <installments-form
            @installmentsUpdated="installmentsUpdated" />
        </div>
      </div>
      <div class="row mb-3">
        <credit-simulator v-if="leasing"
          simulation-type="leasing"
          :initial-net-amount="this.netAmount"
          :initial-gross-amount="this.grossAmount"
          :initial-total-net-amount="this.totalNetAmount"
          :installments-count="this.installmentsCount"
          :tmc="this.leasing.rate"
          :sign-date="this.signDate"
          :origin-credit="this.leasing.id"
          :drivers-license="this.driversLicense"
          :product-type-id="this.selectedProductTypeId"
          :extra-costs-gross-amount="this.extraCostsForGrossAmount"
          :extra-costs-net-amount="this.extraCostsForNetAmount"
          :initial-installments="this.installments"
          :selected-extra-costs="this.selectedExtraCosts"
          :product-type="selectedProductType"
          :user-role="userRole"
          @simulationCreated="updateSimulationId"
          @grossAmountUpdated="grossAmountUpdated"
          @netAmountUpdated="netAmountUpdated"
          @simulationTypeUpdated="simulationTypeUpdated"
          @installmentsAmountUpdated="installmentsAmountUpdated"
          @signDateUpdated="signDateUpdated"
          @rateUpdated="interestRatePerMonthUpdated"/>
      </div>
      <div class="row">
        <div class="col-12">
          <form :action="this.leasingAction" method="post" accept-charset="UTF-8" ref="leasingForm">
            <input type="hidden" name="authenticity_token" :value="this.csrfToken" />
            <input type="hidden" name="leasing[simulation_id]" :value="this.simulationId" />
            <input type="hidden" name="id" :value="this.leasing.id" />
            <div v-for="(obj, index) in this.selectedExtraCosts" :key="`extra-costs-${index}`">
              <input type="hidden" :name="`leasing[extra_costs_attributes][${index}][id]`" :value="obj.id" />
              <input type="hidden" :name="`leasing[extra_costs_attributes][${index}][name]`" :value="obj.name" />
              <input type="hidden" :name="`leasing[extra_costs_attributes][${index}][amount]`" :value="obj.amount" />
              <input type="hidden" :name="`leasing[extra_costs_attributes][${index}][_destroy]`" :value="obj._destroy" />
            </div>
            <input
              type="submit"
              class="btn btn-primary btn-block"
              value="Crear Leasing"
              :disabled="simulationId === null"
            />
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import InstallmentForm from '../simulator/InstallmentsForm.vue'
import CreditSimulator from './simulator'
import leasingAPI from '../../api/leasings'
import creditApi from '../../api/credits';
import calculatorMixin from '../mixins/calculator'

export default {
  name: 'LeasingForm',
  mixins: [calculatorMixin],
  components: {
    CreditSimulator,
    InstallmentForm
  },
  props: {
    leasingCode: null,
    userRole: ''
  },
  data () {
    return {
      leasing: {},
      leasingSlug: null,
      clientName: '',
      guarantorName: '',
      signDate: moment().format('YYYY-MM-DD'),
      closeReasonOptions: [],
      selectedCloseReason: null,
      netAmount: 0,
      grossAmount: 0,
      simulationId: null,
      driversLicense: false,
      installmentsCount: 0,
      extraCostsList: [],
      selectedExtraCosts: [],
      selectedExtraCostName: null,
      selectedExtraCostHumanName: null,
      selectedExtraCostAmount: null,
      leasingFormSubmitButtonDisabled: false,
      portfolioValuePlusExtraCosts: null,
      interestRatePerMonth: 0,
      rateValueError: false,
      installments: [],
      selectedProductType: {},
    }
  },
  methods: {
    setLeasingSlug: function() {
      this.leasingSlug = String(this.leasingCode).replace(".", "-")
    },
    interestRatePerMonthUpdated: function(value) {
      this.interestRatePerMonth = Number(value)
    },
    signDateUpdated: function(value) {
      this.signDate = value
    },
    getLeasing: async function() {
      this.leasing = await leasingAPI.get(this.leasingCode)
      this.installmentsCount = this.leasing.repayment_period
      this.signDate = moment(this.leasing.transaction_signed_at).format('YYYY-MM-DD')
      this.driversLicense = this.leasing.operation.drivers_license
      this.selectedExtraCosts = this.leasing.extra_costs.map((ec) => {
        return {
          id: ec.id,
          name: ec.name,
          human_name: this.extraCostsList.find((item) => item.name === ec.name).human_name,
          category: this.extraCostsList.find((item) => item.name === ec.name).category,
          amount: ec.amount,
          _destroy: 0
        }
      })
      this.netAmount = this.leasing.net_amount - this.extraCostsForNetAmount
      this.grossAmount = this.leasing.gross_amount
      this.totalNetAmount = this.leasing.net_amount

      if(this.leasing.client) {
        this.clientName = this.leasing.client.name
      }
      if(this.leasing.guarantor) {
        this.guarantorName = this.leasing.guarantor.name
      }
    },
    updateSimulationId: function(value) {
      this.simulationId = value
    },
    grossAmountUpdated: function(value) {
      this.grossAmount = value
    },
    netAmountUpdated: function(value) {
      this.netAmount = value
    },
    installmentsAmountUpdated: function(value) {
      this.installmentsAmount = value
    },
    getExtraCostsList: async function() {
      const response = await creditApi.extraCostsList()
      this.extraCostsList = response.data
    },
    setSelectedProductType: function(value) {
      this.selectedProductType = this.productTypesList.filter(productType => productType.id === value)[0]
    },
  },
  created: function() {
    this.setLeasingSlug()
  },
  mounted: function() {
    this.getExtraCostsList()
    this.getProductTypesList()
    this.getLeasing()
  },
  computed: {
    leasingAction() {
      return `/leasings/${this.leasingSlug}/update_with_simulation`
    },
    csrfToken: function() {
      return document.querySelector('meta[name="csrf-token"]').getAttribute('content')
    }
  },
  watch: {
    selectedProductTypeId: function(newValue, oldValue) {
      this.setSelectedProductType(newValue)
    }
  }
}
</script>

<style lang="scss" scoped>
ul.extra-costs-list {
  .delete {
    color: #dc3545;
  }
}
</style>
