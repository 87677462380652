<template>

  <div>
    <b-form-group
      :label="name"
      label-class="text-capitalize"
      :aria-describedby="`${helpId} ${feedbackId}`"
      :state="state"
    >

      <b-form-textarea
        :readonly="readonly"
        :id="_uid + name + id"
        v-model="value"
        :state="state"
        rows="3"
        max-rows="4"
        trim
        :aria-describedby="`${helpId} ${feedbackId}`"
      ></b-form-textarea>

      <b-form-invalid-feedback :id="feedbackId">
        Es requerido
      </b-form-invalid-feedback>

      <div class="d-block" >
        <span class="text-danger" v-if="error" >{{ error }}</span>
      </div>

      <div class="d-block" >
        <b-form-text :id="helpId">{{ description }}</b-form-text>
      </div>

    </b-form-group>
  </div>
</template>

<script>
  import fieldsMixin from '../../mixins/fields';

  export default {
    name: 'TextAreaField',
    mixins: [fieldsMixin]
  }
</script>
