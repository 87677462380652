import _ from 'lodash'
import I18n from 'i18n-js'
import moment from 'moment'
import creditApi from '../../api/credits'
import tmcApi from '../../api/tmc'
import ufApi from '../../api/uf'
import simulationApi from '../../api/simulations'
import inventoryMixin from './inventory'
import {
  FIXED_LEASING_INSTALLMENTS_COUNT,
  FIXED_CREDIT_INSTALLMENTS_COUNT,
  DEFAULT_MONTHS_FOR_LIEN_INSURANCE,
  UNEMPLOYMENT_PLANUF_3_INSURANCE_RATE,
  UNEMPLOYMENT_PLANUF_6_INSURANCE_RATE,
  GUARANTORS_COUNT
} from '../../constants/simulator'

export default {
  props: [
    'pipedrivePersonId',
    'pipedriveDealId',
    'clientId',
    'simulationId'
  ],
  mixins: [inventoryMixin],
  data: function() {
    return {
      annualTmc: null,
      bikeInsurance: false,
      bikeInsuranceAmount: 0,
      completeMandatoryExtraCosts: null,
      currentSimulation: null,
      currentUF: null,
      driversLicense: false,
      extraCostsList: [],
      grossAmount: 0,
      installments: [],
      installmentsAmount: FIXED_CREDIT_INSTALLMENTS_COUNT,
      interestRatePerMonth: 0,
      liabilityInsurance: false,
      liabilityInsuranceAmount: 0,
      lienInsurance: false,
      lienInsuranceAmount: 0,
      lifeInsurance: false,
      lifeInsuranceAmount: 0,
      planUf3Insurance: false,
      planUf3UAmount:0,
      planUf6Insurance: false,
      planUf6UAmount:0,
      mandatoryExtraCosts: [],
      monthsForLienInsurance: DEFAULT_MONTHS_FOR_LIEN_INSURANCE,
      netAmount: 0,
      selectedCreditTypeId: null,
      creditTypesList: [],
      creditTypesListFiltered: [],
      selectedGuarantorsCount: null,
      guarantorsCountList: GUARANTORS_COUNT,
      selectedHasInventory: null,
      hasInventoryOptions: [{value: null, name: I18n.t('simulator.credit_simulator.credit_type.select_inventory'), disabled: true},{value: true, name: 'Migrante'},{value: false, name: 'Dealer/Privado/Otro'}],
      productTypesList: [],
      productTypesListFiltered: [],
      productValue: 0,
      rateValueError: false,
      selectedExtraCostAmount: null,
      selectedExtraCostCategory: null,
      selectedExtraCostHumanName: null,
      selectedExtraCostName: null,
      selectedExtraCosts: [],
      selectedProductTypeId: null,
      productsPreference: [],
      signDate: moment().format('YYYY-MM-DD'),
      simpleTmc: null,
      simulationType: 'normal',
      stampDuty: false,
      stampDutyAmount: 0,
      totalNetAmount: 0,
      transferTax: false,
      transferTaxAmount: 0,
      vehicleBrandList: [],
    }
  },
  mounted() {
    this.getTmc()
    this.getUF()
  },
  methods: {
    updateSimulationId: function(value) {
      this.simulationId = value
    },
    grossAmountUpdated: function(value) {
      this.grossAmount = value
      this.recalculateGrossAmount()
    },
    updateCurrentSimulation: function(value) {
      this.currentSimulation = value
    },
    updateProductValue: function(value) {
      this.productValue = value
    },
    updateNetAmount: function(value) {
      this.netAmount = value
    },
    updateTotalNetAmount: function(value) {
      this.totalNetAmount = value
    },
    signDateUpdated: function(value) {
      this.signDate = value
    },
    simulationTypeUpdated: function(value) {
      this.simulationType = value
    },
    installmentsAmountUpdated: function(value) {
      if(_.isEmpty(value)) { return 1 }

      this.installmentsAmount = value
      this.recalculateGrossAmount()
    },
    updateMonthsForLienInsurance: function(value){
      this.monthsForLienInsurance = value
      this.calculateBikeInsurance(this.productValue)
      this.recalculateGrossAmount()
    },
    interestRatePerMonthUpdated: function(value) {
      this.interestRatePerMonth = Number(value)
    },
    getProductTypesList: async function() {
      const response = await creditApi.productTypesList()
      this.productTypesList = response.data
    },
    getCreditTypesList: async function() {
      const response = await creditApi.creditTypesList()
      this.creditTypesList = response.data.credit_types
      this.creditTypesList.unshift({ id: null, name: I18n.t('simulator.credit_simulator.credit_type.select_credit'), disabled: true})
    },
    filterProductTypeList: function () {
      this.productTypesListFiltered = this.productTypesList.filter( (pt) => {
        let condition = true
        if(this.selectedCreditTypeId)
          condition = condition && pt.credit_type_id === this.selectedCreditTypeId

        if(this.selectedGuarantorsCount >= 0)
          condition = condition &&  pt.guarantors_count === this.selectedGuarantorsCount

        if(this.selectedHasInventory !== null)
          condition = condition && pt.has_inventory === this.selectedHasInventory
        return condition
      })

      this.selectedProductTypeId = null
      this.productTypesListFiltered.unshift({ id: null, name: I18n.t('simulator.credit_simulator.product_type.select_product'), disabled: true})
    },
    getInventoryTypesByProductType: async function(productTypeId) {
      const response = await creditApi.inventoryTypesByProductType(productTypeId)
      return response.data
    },
    addProductTypeExtraCosts: async function (updateExtracostsFlag = false) {
      const response = await creditApi.productTypeExtraCosts(this.selectedProductTypeId)
      if(!updateExtracostsFlag){
        this.addExtraCostsGroup(response.data)
      }
    },
    addExtraCostsGroup: function (extra_costs_list) {
      if (_.isNil(extra_costs_list)) { return }
      this.selectedExtraCosts = extra_costs_list

      this.bikeInsurance = this.selectedExtraCosts.some((ec) => ec.name === 'bike_insurance')
      this.liabilityInsurance = this.selectedExtraCosts.some((ec) => ec.name === 'liability_insurance')
      this.lienInsurance = this.selectedExtraCosts.some((ec) => ec.name === 'lien_insurance')
      this.lifeInsurance = this.selectedExtraCosts.some((ec) => ec.name === 'life_insurance')
      this.stampDuty = this.selectedExtraCosts.some((ec) => ec.name === 'taxes')
      this.transferTax = this.selectedExtraCosts.some((ec) => ec.name === 'transfer_tax')
      this.planUf3Insurance = this.selectedExtraCosts.some((ec) => ec.name === 'planuf3_insurance')
      this.planUf6Insurance = this.selectedExtraCosts.some((ec) => ec.name === 'planuf6_insurance')
      this.containsMandatoryExtraCosts()

      this.totalNetAmount = this.netAmount + this.extraCostsForNetAmount

      this.grossAmount = this.totalNetAmount + this.extraCostsForGrossAmount
    },
    getExtraCostsList: async function() {
      const response = await creditApi.extraCostsList()
      this.extraCostsList = response.data
    },
    addExtraCost: function() {
      if (_.isNil(this.selectedExtraCostName) || _.isNil(this.selectedExtraCostAmount)) { return }
      if (this.selectedExtraCosts.find((sec) => (sec.name === this.selectedExtraCostName))) {
        if (!confirm(`¿Seguro que quieres sobreescribir el monto de ${this.selectedExtraCostHumanName}?`)){
          return
        }
      }
      // prevent duplicated extra costs
      this.selectedExtraCosts = this.selectedExtraCosts.filter((sec) => (sec.name !== this.selectedExtraCostName))

      this.selectedExtraCosts = [
        ...this.selectedExtraCosts,
        {
          name: this.selectedExtraCostName,
          human_name: this.selectedExtraCostHumanName,
          amount: Number(this.selectedExtraCostAmount),
          category: this.selectedExtraCostCategory
        }
      ]
      this.containsMandatoryExtraCosts()

      this.totalNetAmount = this.netAmount + this.extraCostsForNetAmount

      this.grossAmount = this.totalNetAmount + this.extraCostsForGrossAmount

      switch (this.selectedExtraCostName){
        case 'bike_insurance':
          this.bikeInsurance = true
          break
        case 'liability_insurance':
          this.liabilityInsurance = true
          break
        case 'lien_insurance':
          this.lienInsurance = true
          break
        case 'life_insurance':
          this.lifeInsurance = true
          break
        case 'taxes':
          this.stampDuty = true
          break
        case 'transfer_tax':
          this.transferTax = true
          break
        case 'planuf3_insurance':
          this.planUf3Insurance = true
          break
        case 'planuf6_insurance':
          this.planUf6Insurance = true
          break
      }

      this.selectedExtraCostName = null
      this.selectedExtraCostHumanName = null
      this.selectedExtraCostAmount = null
      this.selectedExtraCostCategory = null
    },
    removeExtraCost: function(name) {
      const extraCostToDestroy = this.selectedExtraCosts.find((ec) => ec.name === name)

      if (_.isNil(extraCostToDestroy)){ return }

      if (extraCostToDestroy.name == 'lien_insurance') {
        this.calculateLienInsurance(0);
        this.lienInsurance = false
      }
      if (extraCostToDestroy.name == 'taxes') {
        this.stampDuty = false
        this.calculateStampDuty(0)
      }
      if (extraCostToDestroy.name == 'transfer_tax') {
        this.transferTax = false
        this.calculateTransferTax(0)
      }
      if (extraCostToDestroy.name == 'life_insurance') {
        this.lifeInsurance = false
        this.calculateLifeInsurance(0)
      }
      if (extraCostToDestroy.name == 'liability_insurance') {
        this.liabilityInsurance = false
        this.calculateLiabilityInsurance(0)
      }
      if (extraCostToDestroy.name == 'bike_insurance') {
        this.calculateBikeInsurance(0);
        this.bikeInsurance = false
      }
      if (extraCostToDestroy.name == 'planuf3_insurance') {
        this.calculatePlanUf3Insurance();
        this.planUf3Insurance = false
      }
      if (extraCostToDestroy.name == 'planuf6_insurance') {
        this.calculatePlanUf6Insurance();
        this.planUf6Insurance = false
      }
      this.selectedExtraCosts = this.selectedExtraCosts.filter((ec) => ec.name !== name)

      this.containsMandatoryExtraCosts()

      this.totalNetAmount = this.netAmount + this.extraCostsForNetAmount

      this.grossAmount = this.totalNetAmount + this.extraCostsForGrossAmount
    },
    containsMandatoryExtraCosts: async function() {
      if (_.isNil(this.selectedProductTypeId)) {
        this.completeMandatoryExtraCosts = false
        return
      }

      const isMandatory = (currentValue) => this.selectedExtraCosts.find((ec) => ec.name === currentValue.name);
      const response = await creditApi.productTypeExtraCosts(this.selectedProductTypeId)
      this.mandatoryExtraCosts = response.data.filter((ec) => ec.mandatory === true)
      this.completeMandatoryExtraCosts = this.mandatoryExtraCosts.every(isMandatory)
    },
    calculateLifeInsurance: function(grossAmount) {
      if(grossAmount > 0) {
        this.lifeInsuranceAmount = Math.round(Number(0.056 / 100 * grossAmount * (this.monthsForLienInsurance)));
        var lifeInsuranceExtraCost = this.selectedExtraCosts.find((ec) => ec.name === 'life_insurance')
        if (typeof lifeInsuranceExtraCost !== 'undefined') {
          lifeInsuranceExtraCost.amount = this.lifeInsuranceAmount
          if(lifeInsuranceExtraCost.amount == this.lifeInsuranceAmount) {
            return
          }

        } else {
          this.selectedExtraCostName = 'life_insurance'
          this.selectedExtraCostHumanName = 'Seguro de vida'
          this.selectedExtraCostAmount = this.lifeInsuranceAmount
          this.selectedExtraCostCategory = 'gross'
          this.addExtraCost()
        }
      } else {
        this.lifeInsuranceAmount = 0;
      }
    },
    calculateLienInsurance: function(grossAmount) {
      if(grossAmount > 0) {
        let lienInsuranceRate = this.lifeInsurance ? 0.045 : 0.095;
        this.lienInsuranceAmount = Math.round(Number(lienInsuranceRate / 100 * grossAmount * (this.monthsForLienInsurance)));
        let lienInsuranceExtraCost = this.selectedExtraCosts.find((ec) => ec.name === 'lien_insurance')
        if (typeof lienInsuranceExtraCost !== "undefined") {
          lienInsuranceExtraCost.amount = this.lienInsuranceAmount
          if(lienInsuranceExtraCost.amount == this.lienInsuranceAmount) {
            return
          }

        } else {
          this.selectedExtraCostName = 'lien_insurance'
          this.selectedExtraCostHumanName = 'Seguro Desgravamen'
          this.selectedExtraCostAmount = this.lienInsuranceAmount
          this.selectedExtraCostCategory = 'gross'
          this.addExtraCost()
        }
      } else {
        this.lienInsuranceAmount = 0;
      }
      // return this.lienInsuranceAmount;
    },
    calculateStampDuty: function(grossAmount) {
      if(grossAmount > 0) {
        this.stampDutyAmount = Math.round(Number(0.8 / 100 * grossAmount))
        let stampDutyExtraCost = this.selectedExtraCosts.find((ec) => ec.name === 'taxes')
        if(typeof stampDutyExtraCost !== "undefined"){
          stampDutyExtraCost.amount = this.stampDutyAmount
        } else {
          this.selectedExtraCostName = 'taxes'
          this.selectedExtraCostHumanName = 'Impuestos'
          this.selectedExtraCostAmount = this.stampDutyAmount
          this.selectedExtraCostCategory = 'gross'
          this.addExtraCost()
        }
      } else {
        this.stampDutyAmount = 0;

      }
    },
    calculateTransferTax: function(productValue) {
      if(!this.transferTax) { return }
      if(productValue > 0) {
        this.transferTaxAmount = Math.round(Number(1.5 / 100 * this.productValue))
        let transferTaxExtraCost = this.selectedExtraCosts.find((ec) => ec.name === 'transfer_tax')
        if(typeof transferTaxExtraCost !== "undefined"){
          transferTaxExtraCost.amount = this.transferTaxAmount
        } else {
          this.selectedExtraCostName = 'transfer_tax'
          this.selectedExtraCostHumanName = 'Impuesto transferencia'
          this.selectedExtraCostAmount = this.transferTaxAmount
          this.selectedExtraCostCategory = 'gross'
          this.addExtraCost()
        }
      } else {
        this.transferTaxAmount = 0;

      }
    },
    getAmountInUF: function(value) {
      return value / this.currentUF
    },
    calculateBikeInsurance: function(productValue) {
      if(!this.bikeInsurance){ return }

      let bikeInsuranceAmountInUF = 0
      if(productValue > 0) {
        let productValueInUF = this.getAmountInUF(productValue)

        if(this.simulationType === 'leasing'){
          if(this.monthsForLienInsurance <= 12){
            if(productValueInUF <= 40){
              bikeInsuranceAmountInUF = 6
            }else if(productValueInUF <= 70){
              bikeInsuranceAmountInUF = 8.59
            }else if(productValueInUF <= 105){
              bikeInsuranceAmountInUF = 9.85
            }else{ // this is supposed to be < 140. what about greater than that?
              bikeInsuranceAmountInUF = 10.34
            }
          }else if(this.monthsForLienInsurance <= 18 ) {
            if(productValueInUF <= 40){
              bikeInsuranceAmountInUF = 9.06
            }else if(productValueInUF <= 70){
              bikeInsuranceAmountInUF = 12.98
            }else if(productValueInUF <= 105){
              bikeInsuranceAmountInUF = 14.88
            }else{ // this is supposed to be < 140. what about greater than that?
              bikeInsuranceAmountInUF = 15.61
            }
          }else if(this.monthsForLienInsurance >= 19){
            if(productValueInUF <= 40){
              bikeInsuranceAmountInUF = 12.11
            }else if(productValueInUF <= 70){
              bikeInsuranceAmountInUF = 17.36
            }else if(productValueInUF <= 105){
              bikeInsuranceAmountInUF = 19.91
            }else{ // this is supposed to be < 140. what about greater than that?
              bikeInsuranceAmountInUF = 20.88
            }
          }
        } else {
          if(this.monthsForLienInsurance <= 12){
            if(productValueInUF <= 40){
              bikeInsuranceAmountInUF = 6
            }else if(productValueInUF <= 70){
              bikeInsuranceAmountInUF = 8.59
            }else if(productValueInUF <= 105){
              bikeInsuranceAmountInUF = 9.85
            }else{ // this is supposed to be < 140. what about greater than that?
              bikeInsuranceAmountInUF = 10.34
            }
          }else if(this.monthsForLienInsurance <= 18 ) {
            if(productValueInUF <= 40){
              bikeInsuranceAmountInUF = 9.06
            }else if(productValueInUF <= 70){
              bikeInsuranceAmountInUF = 12.98
            }else if(productValueInUF <= 105){
              bikeInsuranceAmountInUF = 14.88
            }else{ // this is supposed to be < 140. what about greater than that?
              bikeInsuranceAmountInUF = 15.61
            }
          }else if(this.monthsForLienInsurance >= 19){
            if(productValueInUF <= 40){
              bikeInsuranceAmountInUF = 12.11
            }else if(productValueInUF <= 70){
              bikeInsuranceAmountInUF = 17.36
            }else if(productValueInUF <= 105){
              bikeInsuranceAmountInUF = 19.91
            }else{ // this is supposed to be < 140. what about greater than that?
              bikeInsuranceAmountInUF = 20.88
            }
          }
        }
      }

      this.bikeInsuranceAmount = bikeInsuranceAmountInUF * this.currentUF
      let bikeInsuranceExtraCost = this.selectedExtraCosts.find((ec) => ec.name === 'bike_insurance')
      if (typeof bikeInsuranceExtraCost !== "undefined") {
        if(bikeInsuranceExtraCost.amount == this.bikeInsuranceAmount) {
          return
        }else{
          bikeInsuranceExtraCost.amount = this.bikeInsuranceAmount
        }
      } else {
        this.selectedExtraCostName = 'bike_insurance'
        this.selectedExtraCostHumanName = 'Seguro de Moto'
        this.selectedExtraCostAmount = this.bikeInsuranceAmount
        this.selectedExtraCostCategory = 'net'
        this.addExtraCost()
      }
    },
    calculateLiabilityInsurance: function(productValue) {
      if (!this.liabilityInsurance){ return }

      let liabilityInsuranceAmountInUF = 0
      if (productValue > 0) {
        let productValueInUF = this.getAmountInUF(productValue)
        if (productValueInUF <= 40){
          liabilityInsuranceAmountInUF = 5
        }else if (productValueInUF <= 70){
          liabilityInsuranceAmountInUF = 7.16
        }else if (productValueInUF <= 105){
          liabilityInsuranceAmountInUF = 8.21
        }else { // this is supposed to be < 140. what about greater than that?
          liabilityInsuranceAmountInUF = 8.62
        }
      }

        this.liabilityInsuranceAmount = liabilityInsuranceAmountInUF * this.currentUF
        var liabilityInsuranceExtraCost = this.selectedExtraCosts.find((ec) => ec.name === 'liability_insurance')
        if (typeof liabilityInsuranceExtraCost !== 'undefined') {
          if(liabilityInsuranceExtraCost.amount == this.liabilityInsuranceAmount) {
            return
          }else{
            liabilityInsuranceExtraCost.amount = this.liabilityInsuranceAmount
          }
        } else {
          this.selectedExtraCostName = 'liability_insurance'
          this.selectedExtraCostHumanName = 'Seguro de responsabilidad'
          this.selectedExtraCostAmount = this.liabilityInsuranceAmount
          this.selectedExtraCostCategory = 'net'
          this.addExtraCost()
        }
    },
    calculatePlanUf3Insurance: function() {
      if (!this.planUf3Insurance){ return }

      this.planUf3UAmount = Math.ceil(Number(this.currentUF * UNEMPLOYMENT_PLANUF_3_INSURANCE_RATE * this.monthsForLienInsurance));
      let planUf3ExtraCost = this.selectedExtraCosts.find((ec) => ec.name === 'planuf3_insurance')
      if (typeof planUf3ExtraCost !== 'undefined') {
        planUf3ExtraCost.amount = this.planUf3UAmount
        if(planUf3ExtraCost.amount == this.planUf3UAmount) {
          return
        }

      } else {
        this.selectedExtraCostName = 'planuf3_insurance'
        this.selectedExtraCostHumanName = 'Seguro de cesantía plan 3xUF3'
        this.selectedExtraCostAmount = this.planUf3UAmount
        this.selectedExtraCostCategory = 'net'
        this.addExtraCost()
      }
    },
    calculatePlanUf6Insurance: function() {
      if (!this.planUf6Insurance){ return }

      this.planUf6UAmount = Math.ceil(Number(this.currentUF * UNEMPLOYMENT_PLANUF_6_INSURANCE_RATE * this.monthsForLienInsurance));
      var planUf6ExtraCost = this.selectedExtraCosts.find((ec) => ec.name === 'planuf6_insurance')
      if (typeof planUf6ExtraCost !== 'undefined') {
        planUf6ExtraCost.amount = this.planUf6UAmount
        if(planUf6ExtraCost.amount == this.planUf6UAmount) {
          return
        }
      } else {
        this.selectedExtraCostName = 'planuf6_insurance'
        this.selectedExtraCostHumanName = 'Seguro de cesantía plan 3xUF6'
        this.selectedExtraCostAmount = this.planUf6UAmount
        this.selectedExtraCostCategory = 'net'
        this.addExtraCost()
      }
    },
    recalculateGrossAmount: function() {
      if(this.stampDuty) {
        this.calculateStampDuty(this.grossAmount);
      }
      if(this.transferTax) {
        this.calculateTransferTax(this.grossAmount);
      }
      if(this.lienInsurance) {
        this.calculateLienInsurance(this.grossAmount);
      }
      if(this.lifeInsurance) {
        this.calculateLifeInsurance(this.grossAmount);
      }
      if(this.planUf3Insurance) {
        this.calculatePlanUf3Insurance();
      }
      if(this.planUf6Insurance) {
        this.calculatePlanUf6Insurance();
      }
    },
    installmentsUpdated: function(value) {
      this.installments = value
    },
    unemploymentInsuranceUpdated: function(value) {
      if(value.name === 'unemploymentInsurance' && !value.value){
        this.planUf3Insurance = false
        this.planUf3UAmount = 0
        this.planUf6Insurance = false
        this.planUf6UAmount = 0
        this.calculatePlanUf3Insurance();
        this.removeExtraCost('planuf3_insurance')
        this.calculatePlanUf6Insurance();
        this.removeExtraCost('planuf6_insurance')
      } else {
        if (value.name == 'planUf3Insurance' && value.value) {
          this.planUf3Insurance = true
          this.planUf6Insurance = false
          this.calculatePlanUf6Insurance();
          this.removeExtraCost('planuf6_insurance')
          this.calculatePlanUf3Insurance();

        } else if (value.name == 'planUf6Insurance' && value.value) {
            this.planUf6Insurance = true
          this.planUf3Insurance = false
          this.calculatePlanUf3Insurance();
          this.removeExtraCost('planuf3_insurance')
          this.calculatePlanUf6Insurance();
        }
      }
    },
    setTmc: function(value) {
      this.annualTmc = Number(value)
      this.simpleTmc = _.round(value / 12, 6)
    },
    getTmc: async function(amount) {
      const response = await tmcApi.forAmount(
        amount,
        this.signDate
      )
      this.setTmc(response.data)
      this.checkRateValue()
    },
    setUF: function(value) {
      this.currentUF = Number(value)
    },
    getUF: async function() {
      const response = await ufApi.atDate(
        this.signDate
      )
      this.setUF(response.data)
    },
    checkRateValue: function() {
      if(this.interestRatePerMonth > this.simpleTmc) {
        this.rateValueError = true
      } else {
        this.rateValueError = false
      }
    },
    setCurrentSimulation: async function() {
      if (!this.simulationId) { return }

      this.currentSimulation = await simulationApi.getSimulation(this.simulationId)
      this.installmentsAmount = this.currentSimulation.installments_amount
      this.interestRatePerMonth = this.currentSimulation.interest_rate_per_month
      this.addExtraCostsGroup(this.currentSimulation.extra_costs)
      this.netAmount = Number(this.currentSimulation.net_amount) - this.extraCostsForNetAmount - Number(this.currentSimulation.down_payment || 0)
      this.selectedInventoryItemId = this.currentSimulation.inventory_item?.id
      this.productsPreference = (this.currentSimulation?.sim_model_color_preferences || []).map(val => ({
        ...val,
        product_model_id_formatted: {
          id: val?.product_model_id,
          year: val?.product_model_year,
          list_price: val?.price,
          discount_description: val?.has_discount,
          brand_id: val?.brand_id,
          name: val?.product_model_name
        }
      }))

      if(!this.currentSimulation?.product_type?.active) return

      let selectedProductType = this.productTypesList.filter(productType => productType.id === this.currentSimulation.product_type.id)[0]
      this.selectedCreditTypeId = selectedProductType.credit_type_id
      this.selectedGuarantorsCount = selectedProductType.guarantors_count
      this.selectedHasInventory = selectedProductType.has_inventory
      this.filterProductTypeList()
      this.selectedProductTypeId = this.currentSimulation.product_type.id

    }
  },
  computed: {
    extraCostsForGrossAmount: function() {
      return Number(
        this.selectedExtraCosts
          .filter(({category}) => category === 'gross')
          .filter((ec) => ec._destroy !== 1)
          .reduce((sum, val) => Number(sum) + Number(val.amount), 0)
      )
    },
    extraCostsForNetAmount: function() {
      return Number(
        this.selectedExtraCosts
          .filter(({category}) => category === 'net')
          .filter((ec) => ec._destroy !== 1)
          .reduce((sum, val) => Number(sum) + Number(val.amount), 0)
      )
    },
    extraCostsForProductValue: function() {
      return Number(
        this.selectedExtraCosts
          .filter(({category}) => category === 'accessory')
          .filter((ec) => ec._destroy !== 1)
          .reduce((sum, val) => Number(sum) + Number(val.amount), 0)
      )
    },
  },
  watch: {
    simulationType: function(newValue, oldValue) {
      if (newValue === 'leasing') {
        this.installmentsAmount = this.installmentsAmount || FIXED_LEASING_INSTALLMENTS_COUNT
      } else {
        this.installmentsAmount = this.installmentsAmount || FIXED_CREDIT_INSTALLMENTS_COUNT
      }
    },
    selectedExtraCostName: function(newValue, oldValue) {
      if (_.isNil(newValue)) {
        this.selectedExtraCostHumanName = null
        this.selectedExtraCostCategory = null
      } else {
        this.selectedExtraCostHumanName = this.extraCostsList.find((item) => item.name === newValue).human_name
        this.selectedExtraCostCategory = this.extraCostsList.find((item) => item.name === newValue).category
      }
    },
    lienInsurance: function(newValue, oldValue) {
      if(newValue) {
        this.calculateLienInsurance(this.grossAmount)
      } else {
        this.calculateLienInsurance(0)
        this.removeExtraCost('lien_insurance')
      }
    },
    lienInsuranceAmount: function(newValue, oldValue) {
      if(newValue !== oldValue) {
        this.grossAmount = this.totalNetAmount + this.extraCostsForGrossAmount
      }
    },
    stampDuty: function(newValue, oldValue) {
      if(newValue) {
        this.calculateStampDuty(this.grossAmount)
      } else {
        this.calculateStampDuty(0)
        this.removeExtraCost('taxes')
      }
    },
    transferTax: function(newValue, oldValue) {
      if(newValue) {
        this.calculateTransferTax(this.grossAmount)
      } else {
        this.calculateTransferTax(0)
        this.removeExtraCost('transfer_tax')
      }
    },
    lifeInsurance: function(newValue, oldValue) {
      if(newValue) {
        this.calculateLifeInsurance(this.grossAmount)
      } else {
        this.calculateLifeInsurance(0)
        this.removeExtraCost('life_insurance')
      }
    },
    liabilityInsuranceAmount: function(newValue, oldValue) {
      if(newValue !== oldValue) {
        this.grossAmount = this.totalNetAmount + this.extraCostsForGrossAmount
      }
    },
    lifeInsuranceAmount: function(newValue, oldValue) {
      if(newValue !== oldValue) {
        this.grossAmount = this.totalNetAmount + this.extraCostsForGrossAmount
      }
    },
    stampDutyAmount: function(newValue, oldValue) {
      if(newValue !== oldValue) {
        this.grossAmount = this.totalNetAmount + this.extraCostsForGrossAmount
      }
    },
    transferTaxAmount: function(newValue, oldValue) {
      if(newValue !== oldValue) {
        this.grossAmount = this.totalNetAmount + this.extraCostsForGrossAmount
      }
    },
    signDate: function() {
      this.getTmc(this.grossAmount)
      this.getUF(this.signDate)
    },
    interestRatePerMonth: function() {
      this.checkRateValue()
    },
    grossAmount: function(newValue, oldValue){
      this.getTmc(newValue)
    },
    productValue: function(newValue, oldValue){
      if(this.bikeInsurance){
        this.calculateBikeInsurance(newValue)
      }
      if(this.liabilityInsurance){
        this.calculateLiabilityInsurance(newValue)
      }
      if(this.transferTax){
        this.calculateTransferTax(newValue)
      }
    },
    bikeInsurance: function(newValue, oldValue) {
      if(newValue) {
        this.calculateBikeInsurance(this.productValue)
      } else {
        this.calculateBikeInsurance(0)
        this.removeExtraCost('bike_insurance')
      }
    },
    liabilityInsurance: function(newValue, oldValue) {
      if(newValue) {
        this.calculateLiabilityInsurance(this.productValue)
      } else {
        this.calculateLiabilityInsurance(0)
        this.removeExtraCost('liability_insurance')
      }
    },
    bikeInsuranceAmount: function(newValue, oldValue) {
      if(newValue !== oldValue) {
        this.totalNetAmount = this.netAmount + this.extraCostsForNetAmount
      }
    },
  },
}

