import axios from 'axios'
import { config } from '../constants/api'

const buildConfig = (extraconfig) => {
  return { ...config, ...extraconfig }
}

export default {
  async getList(query, filter, page, limit) {
    const path = `/errands/list.json`;
    const data = {
      filters: filter,
      query: query,
      page,
      limit
    };

    return await axios.post(path, data, config)
      .then((res) => res.data)
      .catch((error) => error.message)
  },
  async getSubStatusList(errand_type_id) {
    const path = `/errands/sub_status_list.json`;
    const data = {
      errand_type_id: errand_type_id
    };

    return await axios.post(path, data,config)
      .then((res) => res.data)
      .catch((error) => error.message)
  },
  async updateBaseStatus(subStatusList, baseStatusId, auditComment = null) {
    const path = `/errands/update_base_status.json`;
    const data = {
      sub_statuses_list: subStatusList,
      general_status_id: baseStatusId,
      audit_comment: auditComment
    };

    return await axios.post(path, data, config)
      .then((res) => res.data)
      .catch((error) => error.message)
  },

  async destroy(id) {
    const path = `/errands/${id}.json`;

    return await axios.delete(path, config)
      .then((res) => res.data)
      .catch((error) => error.message)
  },
  async updateSubStatus(errands, baseStatusId, auditComment = null) {
    const path = `/errands/update_sub_status.json`;
    const data = {
      errands: errands,
      sub_status_id: baseStatusId,
      audit_comment: auditComment
    };

    return await axios.post(path, data, config)
      .then((res) => res.data)
      .catch((error) => error.message)
  },
  async updateResponsible(errands, responsibleId, auditComment = null) {
    const path = `/errands/update_responsible.json`;
    const data = {
      errands: errands,
      responsible_id: responsibleId,
      audit_comment: auditComment
    };

    return await axios.post(path, data, config)
      .then((res) => res.data)
      .catch((error) => error.message)
  },
  async updateErrand(errandId, errandData, auditComment = null) {
    const path = `/errands/${errandId}.json`;
    const data = {
      ...errandData,
      audit_comment: auditComment
    };

    return await axios.patch(path, data, config)
      .then((res) => res.data)
      .catch((error) => error.message)
  },
  async historySubStatus(errand_id) {
    const path = `/errands/history_sub_status.json`;
    const data = {
      errand_id: errand_id
    };

    return await axios.post(path, data, config)
      .then((res) => res.data)
      .catch((error) => error.message)
  },

  async changeHistory(errand_id) {
    const path = `/errands/${errand_id}/change_history.json`;

    return await axios.get(path, config)
      .then((res) => res.data)
      .catch((error) => error.message)
  },

  async errandsFilters(filters) {
    const path = `/errands/filter.json`;
    const data = { filters: filters };

    return await axios.post(path, data, config)
      .then((res) => res.data)
      .catch((error) => error.message)
  },
  async post(data) {
    const path = '/errand_types.json'

    return await axios.post(path, data, config).then(res => res.data).catch(err => {
      return err.response
    })
  },
  async update(errandTypeId, data) {
    const path = `/errand_types/${errandTypeId}.json`

    return await axios.patch(path, data, config)
      .then((res) => res.data)
      .catch((error) => error.response)
  },
  async get(errandTypeId) {
    const path = `/errand_types/${errandTypeId}.json`

    return await axios.get(path, config)
      .then((res) => res.data)
      .catch((error) => error.response)
  },
  async list(model, modelId, hasTemplate = false) {
    const path = `/errand_types.json`;

    const config = buildConfig({
      params: {
        model_name: model,
        model_id: modelId,
        has_template: hasTemplate
      }
    })

    return await axios.get(path, config)
      .then((res) => res.data)
      .catch((error) => error.message)
  },

  async generalStatusList() {
    const path = `/general_statuses.json`;

    return await axios.get(path, config)
      .then((res) => res.data)
      .catch((error) => error.message)
  },

  async documentServicesList() {
    const path = `/document_services.json`;

    return await axios.get(path, config)
      .then((res) => res.data)
      .catch((error) => error.message)
  },

  async listByErrandableType(errandable_id, errandable_type) {
    const path = `/errands/list_by_errandable_type.json`;
    const data = {
      errandable_id: errandable_id,
      errandable_type: errandable_type
    };

    return await axios.post(path, data, config)
      .then((res) => res.data)
      .catch((error) => error.message)
  },

  async responsibleAvailable() {
    const path = `/errands/responsible_available.json`;

    return await axios.get(path, config)
      .then((res) => res.data)
      .catch((error) => error.message)
  },

  async summaryErrand(errand_type_id, errandable_id, errandable_type) {
    const path = `/errands/summary_errand.json`;
    const data = {
      errand_type_id: errand_type_id,
      errandable_id: errandable_id,
      errandable_type: errandable_type
    };

    return await axios.post(path, data, config)
      .then((res) => res.data)
      .catch((error) => error.message)
  },

  async newErrand(errandable_id, errand_type_id, responsible_id, errandable_type) {
    const path = `/errands/new_errand.json`;
    const data = {
      errandable_id: errandable_id,
      errand_type_id: errand_type_id,
      responsible_id: responsible_id,
      errandable_type: errandable_type
    };

    return await axios.post(path, data, config)
      .then((res) => res.data)
      .catch((error) => error.message)
  },

  async getProviders() {
    const path = `/errand_providers.json`;

    return await axios.get(path, config)
      .then((res) => res.data)
      .catch((error) => error.message)
  },

  async getTypeDocuments() {
    const path = `/errand_type_documents.json`;

    return await axios.get(path, config)
      .then((res) => res.data)
      .catch((error) => error.message)
  },

  async getDocumentTemplate(product_type_id) {
    const path = `/document_templates/by_product_type.json`;
    const data = { product_type_id: product_type_id };

    const config = buildConfig({
      params: {
        product_type_id: product_type_id
      }
    })

    return await axios.get(path, config)
      .then((res) => res.data)
      .catch((error) => error.message)
  },

  async uploadFile(file, errand_id, doc_type) {
    const path = `/errands/${errand_id}/errand_documents.json`;

    let formData = new FormData();
    formData.append('file', file);
    formData.append('doc_type', doc_type);

    let response = await axios.post(path, formData, config)
      .then((res) => res.data)
      .catch((error) => error.response)

    return response
  },

  async uploadApproved(file, errandId) {
    const path = `/errands/${errandId}/upload_approved.json`;

    let formData = new FormData();
    formData.append('file', file);
    formData.append('errand_id', errandId);

    let response = await axios.post(path, formData, config)
      .then((res) => res.data)
      .catch((error) => error.response)

    return response
  },

  async getFiles(errand_id, doc_type) {
    if(!errand_id) return

    const path = `/errands/${errand_id}/errand_documents.json`;

    const configBuild = buildConfig({
      params: {
        doc_type
      }
    })

    let response = await axios.get(path, configBuild)
      .then((res) => res.data)
      .catch((error) => error.message)

    return response
  },

  async deleteFile(errand_id, errandDocumentId) {
    if(!errand_id) return

    const path = `/errands/${errand_id}/errand_documents/${errandDocumentId}.json`;

    let response = await axios.delete(path, config)
      .then((res) => res.data)
      .catch((error) => error.message)

    return response
  },

  async generateInvoice(errandId, invoiceData) {
    const path = `/errands/${errandId}/generate_invoice.json`;

    return axios.post(path, invoiceData, config)
      .then((res) => res.data)
      .catch((error) => error.message)
  },

  async newTotalCheckProcess(errandId, totalCheckData) {
    const path = `/errands/${errandId}/new_total_check_process.json`;

    return axios.post(path, totalCheckData, config)
      .then((res) => res.data)
      .catch((error) => error.message)
  },

  async getErrandTriggerDocuments(errandId) {
    const path = `/errands/${errandId}/errand_trigger_documents.json`

    return axios.get(path, config)
      .then((res) => res.data)
      .catch((error) => error.message)
  },

  async updateTotalCheckStatus(errandId) {
    const path = `/errands/${errandId}/update_total_check_status.json`

    return axios.get(path, config)
      .then((res) => res)
      .catch((error) => error.response)
  }
}
