<template>
  <div class="body-container">
    <loader ref="loader"/>
    <div style="padding-inline: 14px;">
      <FilterScope
        :is-inventory="true"
        @onFilterScope="getSelectedFilterScope"
        ref="childRef"
        :search-instruction="`${t(`activerecord.attributes.inventory_item.search_instruction`)}`"
      />
    </div>
    <div class="flex justify-content-between align-items-center">
      <searcher
        style="padding-inline: 14px;"
        v-on:onSearch="prepareGetInventoryItem"
        v-b-tooltip.bottomright.hover :title="`${t(`activerecord.attributes.inventory_item.tooltip_input_search`)}`"
        input-width="480px"
        :input-placeholder="`${t(`activerecord.attributes.inventory_item.text_placeholder`)}`"
      />
      <div class="flex align-items-center">
        <span class="badge badge-light flex flex-center">
          {{ t(`views.find_number`, {'number': rows}) }}
          <div class="ml-2" v-b-tooltip.hover v-b-tooltip.html :title="`${t(`activerecord.attributes.inventory_item.tooltip_item_count`)}`">
            <i class="fas fa-question-circle cursor-pointer color-default">
            </i>
          </div>
        </span>
      </div>
    </div>
    <div style="padding-left: 15px; margin-top: 20px;">
      <span class="title t4">{{ t(`filter.filter_by`) }}</span>
      <div class="mtp-14 flex align-items-center">
        <button class="second-button flex" v-b-toggle.sidebar-filter @click="openSidebarFilter">
          {{ t(`filter.all_filters`) }} <span class='selected-number' v-bind:style="{ display: isSelected }">{{ itemsSelected }}</span>
        </button>
        <div class="vertical-line"></div>
        <dropdown-filter
          :items="logistic_status_list"
          text="Estado logístico"
          title="Estados logísticos"
          v-on:onFilter="getSelectedLogisticStatus"
          :if-searcher="false"
          style="margin-right: 9px;"/>
        <dropdown-filter
          :items="commercial_status_list"
          text="Estado comercial"
          title="Estados comerciales"
          v-on:onFilter="getSelectedCommercialStatus"
          :if-searcher="false"
          :translate="true"/>
      </div>
    </div>
    <div class="mt-body" style="margin-top: 25px;">
      <div v-bind:style="{ display: searchNotFound ? 'none' : 'block' }">
        <b-table
          id="inventory_table"
          responsive
          hover
          :items="items"
          :per-page="0"
          :current-page="current_page">
          <template #head(product)>{{ t(`activerecord.attributes.inventory_item.product`) }}</template>
          <template #head(id_and_number)>ID</template>
          <template #head(brand_and_model)>{{ t(`activerecord.models.brand_and_model`) }}</template>
          <template #head(logistics_status)>{{ t(`activerecord.attributes.inventory_item.warehouse_status`) }}</template>
          <template #head(commercial_status)>{{ t(`activerecord.attributes.inventory_item.commercial_status`) }}</template>
          <template #head(operation_intent)>{{ t(`activerecord.attributes.inventory_item.operation_intent`) }}</template>
          <template #head(inventory_location)>{{ t(`activerecord.attributes.inventory_item.inventory_location`) }}</template>
          <template #head(credit_display_code)>{{ t(`activerecord.attributes.inventory_item.credit_display_code`) }}</template>
          <template #head(list_price)>{{ t(`activerecord.attributes.inventory_item.list_price`) }}</template>
          <template #head(invoice_id)>{{ t(`activerecord.attributes.inventory_item.invoice_id`) }}</template>
          <template #head(purchased_at)>{{ t(`activerecord.attributes.inventory_item.purchased_at`) }}</template>
          <template #head(purchase_price)>{{ t(`activerecord.attributes.inventory_item.purchase_price`) }}</template>
          <template #head(net_amount)>{{ t(`activerecord.attributes.inventory_item.net_amount`) }}</template>
          <template #head(availability_date)>{{ t(`activerecord.attributes.inventory_item.availability_date`) }}</template>
          <template #head(sales_invoice_date)>{{ t(`activerecord.attributes.inventory_item.sales_invoice_date`) }}</template>
          <template #head(leasing_installment_value)>{{ t(`activerecord.attributes.inventory_item.leasing_installment_value`) }}</template>
          <template #head(sim_id)>{{ t(`activerecord.attributes.inventory_item.sim_id`) }}</template>
          <template #head(executive)>{{ t(`activerecord.attributes.inventory_item.executive`) }}</template>

          <template #cell(product)="data">{{ capitalize(data.value) }}</template>
          <template #cell(id_and_number)="data"><a :href="`/inventory_items/${data.value.id}/edit`">{{ data.value.inventory_number }}</a></template>
          <template #cell(brand_and_model)="data">
            <span class="two_line_info">{{ data.value.brand }}<br> {{ data.value.model }}</span>
          </template>
          <template #cell(logistics_status)="data">{{ t(`activerecord.attributes.inventory_item.warehouse_status_list.${data.value}`, {defaultValue: data.value}) }}</template>
          <template #cell(commercial_status)="data">{{ t(`activerecord.attributes.inventory_item.commercial_status_list.${data.value}`, {defaultValue: data.value}) }}</template>
          <template #cell(operation_intent)="data">{{ t(`activerecord.attributes.inventory_item.operation_intent_list.${data.value}`, {defaultValue: data.value}) }}</template>
          <template #cell(color)="data">{{ capitalize(data.value) }}</template>
          <template #cell(credit_display_code)="data">
            <a v-if="data.value.toString().trim() !== '-'" :href="`/credits/${data.value}/`" target="_blank"> {{ data.value }} </a>
            <p v-else> - </p>
          </template>
          <template #cell(sim_id)="data"> <a :href="`/simulations/${data.value}/`" target="_blank">{{ data.value }}</a></template>
        </b-table>
        <div class="flex-vertical-center justify-content-end row-sm-block">
          <per-page :per-page.sync="per_page" />
          <b-pagination
            class="sm-center"
            style="margin-bottom: unset"
            v-model="current_page"
            :total-rows="rows"
            :per-page="per_page"
            aria-controls="inventory_table"
            prev-text="‹ Anterior"
            next-text="Siguiente ›"
            hide-goto-end-buttons
            @change="onCurrentPageChange"
            hide-ellipsis>
          </b-pagination>
        </div>
      </div>
      <not-found :is-visible="searchNotFound" :text-not-found="searchedText"/>
    </div>
    <sidebar-filter
      ref="sidebar"
      :inventory-types-list="inventory_types"
      :brands.sync="brands"
      :models.sync="models"
      :colors.sync="colors"
      :locations.sync="locations"
      :destinations.sync="destinations"
      v-on:updateDateOrder="updateDateOrder"
      v-on:inventoryTypeStep="inventoryTypeStep"
      v-on:dynamicFilterStep="dynamicFilterStep"
      v-on:brandsStepFunction="brandsStep"
      v-on:modelsStepFunction="modalsStep"
      v-on:colorsStepFunction="colorsStep"
      v-on:locationsStepFunction="locationsStep"
      v-on:destinationsStepFunction="destinationsStep"
      v-on:saveFilterParams="saveFilterParams"
      v-on:cleanFilterParams="cleanFilterParams"
      :filters-object.sync="filters_object_sidebar"/>

    <m-modal ref="m-modal" :title="titleModalInventory" status="warning" :ok-only="true">
      <template slot="body">
        <p>
          {{t(`activerecord.attributes.inventory_item.informational_modal_message_part_one`)}}
          <strong>Ej: {{t(`activerecord.attributes.inventory_item.text_placeholder`)}}</strong>
          {{t(`activerecord.attributes.inventory_item.informational_modal_message_part_two`)}}
          <strong>{{t(`activerecord.attributes.inventory_item.by_operation`)}},
            {{t(`activerecord.attributes.inventory_item.by_inventary`)}},
            {{t(`activerecord.attributes.inventory_item.by_simulation`)}}.
          </strong>
        </p>
      </template>
    </m-modal>
  </div>
</template>

<script>
import i18n from "../mixins/i18n"
import capitalize from "../mixins/capitalize"
import inventory from "../../api/inventory";
import inventory_types from "../../api/inventory_types";
import PerPage from "../dynamics_form/PerPage";
import Searcher from '../dynamics_form/Searcher';
import DropdownFilter from '../dynamics_form/DropdownFilter.vue';
import SidebarFilter from './SidebarFilter.vue';
import NotFound from '../template/NotFound.vue';
import Loader from '../template/Loader.vue';
import FilterScope from '../dynamics_form/FilterScope.vue';
import MModal from '../dynamics_form/MModal.vue';

export default {
  name: "inventory-detail",
  components: {
    PerPage,
    Searcher,
    DropdownFilter,
    SidebarFilter,
    NotFound,
    Loader,
    FilterScope,
    MModal
  },
  mixins: [i18n, capitalize],
  props: {
    filters: {
      default: {}
    }
  },
  data() {
    return {
      items: [],
      logistic_status_list: [],
      commercial_status_list: [],
      date_order_selected: '',
      inventory_types: [],
      brands: [],
      models: [],
      colors: [],
      locations: [],
      destinations: [],
      filters_object_sidebar: {},
      selected_destinations: [],
      filters_object: {},
      current_page: 1,
      per_page: 10,
      isSelected: 'none',
      itemsSelected: 0,
      searchNotFound: false,
      searchedText: '',
      rows: 0,
      filterScope: null,
      queryScopeInventory: null,
      titleModalInventory: null
    };
  },
  methods: {
    onCurrentPageChange: async function(page) {
      await this.getInventoryItem(this.searchedText, page)
    },
    getInventoryItem: async function(query = '', page = 1) {
      this.$refs.loader?.isVisible()
      let response = await inventory.searchInventoryItem(query, this.filters_object, page, this.per_page);
      this.items = response.items
      this.rows = response.total
      if(this.items == ''){
        this.items = []
        this.searchedText = query
        this.searchNotFound = true
      } else {
        this.searchNotFound = false
      }
      this.$refs.loader?.notVisible()
    },
    getInventoryInfo: async function() {
      this.inventory_types = await inventory_types.inventoryTypeList();
      this.logistic_status_list = await inventory.logisticsStatus();
      this.commercial_status_list = await inventory.commercialStatus();
    },
    closeFitlerStatus() {
      this.$refs.dropdown_filter_status.hide(true)
    },
    openSidebarFilter() {
      this.$refs.sidebar.sideBarVisible()
    },
    getSelectedLogisticStatus(list) {
      this.filters_object.warehouse_status_id = list
      this.getInventoryItem()
    },
    getSelectedCommercialStatus(list) {
      this.filters_object.commercial_status = list
      this.getInventoryItem()
    },
    cleanFilterParams() {
      this.filters_object.order_by = ''
      this.setInitFilterObject()
      this.itemsSelected = 0
      this.isSelected = 'none'
      this.getInventoryItem()
    },
    saveFilterParams() {
      this.setInitFilterObject()
      this.filters_object = Object.assign(
        {},
        this.filters_object,
        this.filters_object_sidebar,
        this.selected_destinations
      )
      this.itemsSelected = 0
      Object.values(this.filters_object).forEach(e => {
        if(e !== undefined)
          this.itemsSelected += e.length
      })
      this.isSelected = 'block'
      this.prepareGetInventoryItem(this.queryScopeInventory)
    },
    updateDateOrder(order) {
      if(order !== '')
        this.filters_object.order_by = [order]
    },
    inventoryTypeStep: async function(list) {
      this.filters_object_sidebar.inventory_type_id = list.map(i => i.id)
      this.clearProperties('inventory_type_id')
      this.brands = await inventory.inventoryItemFilters(this.filters_object_sidebar);
    },
    dynamicFilterStep: async function(item) {
      this.filters_object_sidebar.dynamic_field = [item]
      this.brands = await inventory.inventoryItemFilters(this.filters_object_sidebar);
    },
    brandsStep: async function(list) {
      this.filters_object_sidebar.brand_id = list
      this.clearProperties('brand_id')
      this.models = await inventory.inventoryItemFilters(this.filters_object_sidebar);
    },
    modalsStep: async function(list) {
      this.filters_object_sidebar.product_model_id = list
      this.clearProperties('product_model_id')
      this.colors = await inventory.inventoryItemFilters(this.filters_object_sidebar);
    },
    colorsStep: async function(list) {
      this.filters_object_sidebar.color_id = list
      this.clearProperties('color_id')
      this.locations = await inventory.inventoryItemFilters(this.filters_object_sidebar);
    },
    locationsStep: async function(list) {
      this.filters_object_sidebar.inventory_location_id = list
      this.clearProperties('location')
      this.destinations = await inventory.inventoryItemFilters(this.filters_object_sidebar);
    },
    destinationsStep(list) {
      this.selected_destinations.operation_intent = list.map(i => i.id)
    },
    clearProperties(prop) {
      let clear = false
      for (const property in this.filters_object_sidebar) {
        if(clear)
          this.filters_object_sidebar[property] = []
        if(property === prop)
          clear = true
      }
    },
    setInitFilterObject() {
      this.filters_object = {
        warehouse_status: this.filters_object.warehouse_status,
        commercial_status: this.filters_object.commercial_status,
        order_by: this.filters_object.order_by
      }
    },
    buildObjectFilter(filters){
      if(filters.length === 0){
        return {}
      }else{
        let arr = filters.split(',').map(e => parseInt(e))
        // clear query string from url so as not to interfere with future filters
        let url = window.location.href.replace(window.location.search, '');
        history.pushState({ path: url }, url, url);
        return  { inventory_type_id: [arr[0]], brand_id: [arr[1]], product_model_id: [arr[2]] }
      }
    },
    getSelectedFilterScope(scope){
      this.filterScope = scope
    },
    prepareGetInventoryItem(query){
      this.queryScopeInventory = query
      if (!this.filterScope && query && this.formatQueryScope(query).length > 1) {
        this.titleModalInventory = this.t(`activerecord.attributes.inventory_item.informational_modal_title`)
        this.$refs['m-modal'].toggleModal(true);
        return;
      }
      if (this.filterScope && this.queryScopeInventory) {
        this.queryScopeInventory = this.formatQueryScope(this.queryScopeInventory)
        this.setFilterScope(this.filterScope)
      }

      if(!query) {
        this.$refs.childRef.defaultSelect();
        if (this.filterScope) { this.clearPropertiesFiltersObject(this.filterScope) }
        this.filterScope = null;
        this.queryScopeInventory = null;
      }
      let query_needed = !this.filterScope ? query : ''
      this.getInventoryItem(query_needed)
    },
    clearPropertiesFiltersObject(key){
      delete this.filters_object[`${key}`]
    },
    setFilterScope(key){
      this.filters_object[`${key}`] = this.queryScopeInventory
    },
    formatQueryScope(query){
      if (Array.isArray(query)) { return query }
      return query.replace(/[^a-zA-Z 0-9.]+/g,',').replace(/\./g, '').replace(/ /g, ',').toLowerCase().split(',').filter(Boolean)
    }
  },
  mounted() {
    this.filters_object = this.buildObjectFilter(this.filters)
    this.getInventoryItem();
    this.getInventoryInfo();
  },
  watch: {
    per_page: async function(newValue, oldValue){
      await this.getInventoryItem()
    },
    filterScope(_newValue, _oldValue){
      if (_oldValue) { this.clearPropertiesFiltersObject(_oldValue) }
    }
  },
};
</script>
