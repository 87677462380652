<template>
  <b-row>
    <b-col>
      <b-form-group v-slot="{ ariaDescribedby }">
        <b-form-radio-group
          id="account-selector"
          v-model="accountSelected"
          :aria-describedby="ariaDescribedby"
          name="radio-bank-account-selector"
          stacked
          size="lg"
        >
          <b-form-radio v-for="(bankAccount, index) in bankAccounts" :key="index" :value="bankAccount">
            <b-row class="w-100 mb-4" @click="handleOnClick">
              <b-col cols="12" lg="5">
                <h5>{{ bankAccount.name }} - {{ bankAccount.full_rut }}</h5>
                <p class="regular ">{{ bankName(bankAccount.bank_id) }}</p>
                <p class="regular ">{{ t(`activerecord.attributes.bank_account.${bankAccount.account_type}`) }}</p>
                <p class="regular ">{{ bankAccount.number }}</p>
              </b-col>
              <b-col v-if="!hideTags">
                <div>
                  <span class="badge badge-info" v-if="isManualSubscription(bankAccount.source)">Manual</span>
                  <span class="badge badge-danger" v-if="isContingencySubscription(bankAccount.source)">Contingencia</span>
                  <span class="badge" :class="badgeColor(bankAccount.status)" >
                    {{ t(`activerecord.attributes.pac_subscription_status.${bankAccount.status}`) }}
                  </span>
                </div>
              </b-col>
            </b-row>
          </b-form-radio>
        </b-form-radio-group>
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import Vue from 'vue';
import Vue2Filters from 'vue2-filters'
Vue.use(Vue2Filters)
import i18n from "../mixins/i18n"
import { pacStatusColor } from '../../constants/statuses'
import { BANKS_LIST } from '../../constants/payments'
import includes from 'lodash/includes'

export default {
  name: "bank-account-selector",
  mixins: [Vue2Filters.mixin, i18n],
  props: [
    "bankAccounts",
    "bankAccountSelected",
    "hideTags"
  ],
  data() {
    return {
      accountSelected: this.bankAccountSelected || {}
    }
  },
  watch: {
    accountSelected() {
      this.$emit('onSelectedBankAccount', this.accountSelected);
    }
  },
  methods: {
    handleOnClick() {
      this.$emit('onSelectedBankAccount', this.accountSelected);
    },
    badgeColor(status) {
      return pacStatusColor[status]
    },
    bankName(id) {
      return BANKS_LIST.find(bank => bank.value === id).text;
    },
    isManualSubscription(item) {
      return includes(['arc', 'arc-legacy', 'manual-tugboat'], item)
    },
    isContingencySubscription(item) {
      return 'contingency' == item
    }
  }
};
</script>
