
<template>
  <form>
    <b-row>
      <b-col>
        <b-form-group
          :label="t('activerecord.attributes.errand_invoice_template.errand_type_id')"
          label-class="text-capitalize"
        >
          <b-form-select
            class="default-select"
            v-model="errandInvoiceTemplate.errand_type_id"
            :options="errandTypes"
          ></b-form-select>
          <span class="text-danger" v-if="errors.errand_type_id && typeof errors.errand_type_id === 'string'">{{errors.errand_type_id}}</span>
        </b-form-group>

        <b-form-group
          :label="t('activerecord.attributes.errand_invoice_template.name')"
          label-class="text-capitalize"
        >
          <b-form-input type="text" v-model="errandInvoiceTemplate.name"></b-form-input>
          <span class="text-danger" v-if="errors.name && typeof errors.name === 'string'">{{errors.name}}</span>
        </b-form-group>
      </b-col>

      <b-col>
        <b-form-group
          label-class="text-capitalize"
          label="Campos no editables:"
        >
          <b-row>
            <b-col class="m-3">
              <b-row class=" p-3 border rounded">
                <b-col>
                  <ul class="list-style-important">
                    <li>{{ t('errand_invoice_template.name') }} </li>
                    <li>{{ t('errand_invoice_template.rut') }} </li>
                    <li>{{ t('errand_invoice_template.order') }} </li>
                    <li>{{ t('errand_invoice_template.address') }} </li>
                    <li>{{ t('errand_invoice_template.value') }} </li>
                    <li>{{ t('errand_invoice_template.quantity') }} </li>
                    <li>{{ t('errand_invoice_template.subtotal') }} </li>
                  </ul>
                </b-col>
                <b-col>
                  <ul class="list-style-important">
                    <li>{{ t('errand_invoice_template.date') }} </li>
                    <li>{{ t('errand_invoice_template.date_end') }} </li>
                    <li>{{ t('errand_invoice_template.method_payment') }} </li>
                  </ul>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col class="dictionary">
        <b-form-group
          label-class="text-capitalize"
          label="Variables disponibles:"
        >
        </b-form-group>

        <div class="overflow-auto dictionary-content">
          <table class="table table-bordered table-sm table-hover" aria-describedby="available variables">
            <thead>
              <tr>
                <th>{{ t('errand_invoice_template.variable') }}</th>
                <th>{{ t('errand_invoice_template.content') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="value, key  in dictionary.translations" :key='key'>
                <td>{{ key }}</td>
                <td>{{ value }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </b-col>

      <b-col>
        <b-form-group
          :label="t('activerecord.attributes.errand_invoice_template.content')"
          label-class="text-capitalize"
        >
          <b-form-textarea
            id="textarea"
            v-model="errandInvoiceTemplate.content"
            placeholder=""
            rows="11"
            max-rows="11"
          >
          </b-form-textarea>

          <span class="text-danger" v-if="errors.content && typeof errors.content === 'string'">{{errors.content}}</span>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-form-group>
        <b-button
          variant="button button-secondary ml-4"
          @click="handleCancel"
        >
          {{ t('views.cancel') }}
        </b-button>
        <b-button
          variant="button button-primary"
          @click="handleSubmit"
        >
          <b-spinner v-if="loading" small />
          <p v-else>
            {{ t('views.save') }}
          </p>
        </b-button>
      </b-form-group>
    </b-row>
  </form>
</template>

<script>
  import i18n from "../mixins/i18n"
  import errandInvoiceTemplateApi from '../../api/errand_invoice_template'
  import errandsApi from '../../api/errands'

  const DEFAULT_ERRORS = {
    errand_type_id: null,
    name: null,
    content: null,
    inventory_type_id: null
  }

  export default {
    name: 'errand-invoice-template-form',
    components: {},
    mixins: [i18n],
    props: [
      'errandTemplate',
      'inventoryTypeId',
    ],
    data() {
      return {
        errandInvoiceTemplate: {
          id: this.errandTemplate?.id || null,
          errand_type_id: this.errandTemplate?.errand_type_id || null,
          name: this.errandTemplate?.name || null,
          content: this.errandTemplate?.content || null,
          inventory_type_id: this.errandTemplate?.inventory_type_id || this.inventoryTypeId
        },
        errors: DEFAULT_ERRORS,
        dictionary: {},
        errandTypes: [],
        loading: false
      }
    },
    mounted: async function() {
      this.loading = true
      this.dictionary = await errandInvoiceTemplateApi.dictionary(this.inventoryTypeId)
      this.errandTypes = (await errandsApi.list(null, null, true)).map((errandType) => { return { value: errandType.id, text: errandType.name } })
      this.loading = false
    },
    methods: {
      handleSubmit: async function() {
        this.loading = false
        let response

        if(this.errandInvoiceTemplate.id)
          response = await errandInvoiceTemplateApi.update(this.errandInvoiceTemplate.id, this.errandInvoiceTemplate)
        else
          response = await  errandInvoiceTemplateApi.post(this.errandInvoiceTemplate)

        if(response.status === 422){
          this.errors.name = response?.data?.name ? response.data.name.join(', ') : ''
          this.errors.content = response?.data?.content ? response.data.content.join(', ') : ''
          this.errors.errand_type_id = response?.data?.errand_type_id ? response.data.errand_type_id.join(', ') : ''
        } else {
          this.$emit('hideModal', true);
        }
        this.loading = false
      },

      handleCancel: async function() {
        this.$emit('hideModal');
      }
    }
  }
</script>
