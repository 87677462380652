import axios from 'axios'
import _ from 'lodash'
import { config } from '../constants/api'

const buildConfig = (extraconfig) => {
  return { ...config, ...extraconfig }
}

export default {
  async setExceptionType(exception_types_list, operationId) {
    const path = `/operations/${operationId}/update_exception_types.json`;
    const data = {
      exception_types: exception_types_list
    };

    return await axios.post(path, data, config)
      .then((res) => res)
      .catch((error) => error.response)
  }
}
