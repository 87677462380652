<template>
  <div>
    <button v-for="item in updateItems" :key="item.id"
      class="second-button button-list text-capitalize"
      v-bind:class="`${name}_options`"
      :id="`${item.name}_${item.id}_options`"
      :value-id="item.id"
      :value-name="item.name"
      @click="selectItem"
      :disabled="isDisabled">
      {{ translate ? t(`views.${item.name}`) : item.name }}
    </button>
    {{ blockSelected !== undefined ? updateState : '' }}
  </div>
</template>
<script>
import i18n from "../mixins/i18n"
import capitalize from "../mixins/capitalize"

export default {
  name: 'select-options',
  mixins: [i18n, capitalize],
  props: {
    list: Array,
    itemsSelected: {
      type: Array,
      default() {
        return []
      }
    },
    name: String,
    blockSelected: {
      type: Boolean,
      default: undefined
    },
    onSelect: {
      type: Function,
      default: () => {},
    },
    onDeselect: {
      type: Function,
      default: () => {},
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    translate: {
      type: Boolean,
      default: false
    },
  },
  methods: {
    selectItem(event) {
      let item = {
        id: event.target.getAttribute('value-id'),
        name: event.target.getAttribute('value-name')
      }
      if(event.target.classList.contains('active')) {
        event.target.classList.remove('active')
        this.removeItemFromSelected(item)
      } else {
        event.target.classList.add('active')
        this.itemsSelected.push(item)
      }
      this.$emit('update:itemsSelected', this.itemsSelected)
      if(!this.blockSelected){
        this.displayElements()
        this.$emit('onDeselect')
      }
      this.$emit('onSelect', this.itemsSelected)
    },
    displayElements() {
      let elements = document.getElementsByClassName(`${this.name}_options`)
      for (let i = 0; i < elements.length; i++)
        elements[i].style.display = 'inline-block'
    },
    removeItemFromSelected(value) {
      this.itemsSelected.forEach(function(item, index, object) {
        if(JSON.stringify(item) == JSON.stringify(value)){
          object.splice(index, 1);
          return;
        }
      });
    },
    updateSelectedItems(items) {
      this.itemsSelected.splice(0, this.itemsSelected.length)
      items.map(item => this.itemsSelected.push(item))
      this.$emit('update:itemsSelected', items)
      let itemsSelectedId = items.map(item => item.id)
      this.list.forEach(item => {
        if(itemsSelectedId.includes(item.id.toString())){
          document.getElementById(`${item.name}_${item.id}_options`).classList.add('active')
        } else {
          document.getElementById(`${item.name}_${item.id}_options`).classList.remove('active')
        }
      })
    },
    getItemsSelectedAll() {
      return this.itemsSelected.map(item => {
        if(typeof item === 'object')
          return Object.assign({}, item)
        return item
      })
    },
    getItemsSelected() {
      return this.itemsSelected.map(item => item.id)
    },
    setItemsCheck(items) {
      this.updateSelectedItems(items)
    },
    checkAllItems() {
      this.updateSelectedItems(this.list)
    }
  },
  computed: {
    updateState() {
      if(!this.blockSelected){
        this.list.forEach(item => {
          let element = document.getElementById(`${item.name}_${item.id}_options`)
          if(!element.classList.contains('active'))
            element.style.display = 'none'
        })
      } else {
        this.displayElements()
      }
      if(this.itemsSelected.length === 0) {
        let elements = document.getElementsByClassName(`${this.name}_options`)
        for (let i = 0; i < elements.length; i++)
          elements[i].classList.remove('active')
      }
    },
    updateItems() {
      if(this.list.length > 0) {
        if(typeof this.list[0] == 'string'){
          this.list.map(function(item, index, object) {
            object[index] = {
              id: item,
              name: item
            }
          })
        }
      }
      return this.list
    }
  },
  mounted() {
    this.checkAllItems()
  }
}
</script>
