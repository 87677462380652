<template>
  <column-chart :data="JSON.parse(this.data)"></column-chart>
</template>
<script>
export default {
  name: 'rate-evolution-chart',
  props: {
    data: String
  }
}
</script>
