<template>
  <b-form-radio-group
    :options="options"
    v-model="optionValue"
    class="my-2"
  />
</template>
<script>
  export default {
    name: 'guarantor-options',
    props: ['defaultOption'],
    data: function() {
      return {
        optionValue: this.defaultOption,
        options: [
          { value: 'not_new', text: 'Persona existente en ARC' },
          { value: 'new', text: 'Nueva persona en ARC' },
        ]
      }
    },
    watch: {
      optionValue: function(newValue) {
        this.$emit('checkboxValueUpdated', newValue)
      },
      defaultOption: function(newValue, oldValue) {
        this.optionValue = newValue
      }
    }
  }
</script>
