<template>
  <span style="display: contents;">
    <dd class="text-lg text-gray-900 sm:mt-0 sm:mr-8 sm:col-span col-span-2">
      <b-form-select
        v-model="selected"
        :options="options"
        size="sm"
        class="mb-3"
        :disabled="isDisabled">
      <template #first>
        <b-form-select-option :value="selected" disabled>{{ t(`activerecord.attributes.payment_status.change`) }}</b-form-select-option>
      </template>
    </b-form-select>
    </dd>
  </span>
</template>
<script>
import i18n from "../mixins/i18n"

export default {
  mixins: [i18n],
  name: 'payment-status',
  props: [
    'options',
    'isDisabled',
    'selectedStatus'
  ],
  data: function() {
    return {
      selected: this.selectedStatus
    }
  },
  computed: {
  },
  watch: {
    selected: function(newValue) {
      if (_.isNil(newValue)) { return }

      this.$emit('updateStatus', newValue)
    }
  },
}
</script>
