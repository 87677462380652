<template>
  <div>
    <button class="dropdown-item" v-b-modal.modal-referral-links>
      <em class="fas fa-user-friends mr-2 text-gray-400"></em>
      {{ t("referral_link.reffer_client_label") }}
    </button>
    <b-modal id="modal-referral-links" size="sm" hide-footer>
      <div class="flex-center flex-column">
        <img src="../../../assets/images/users-referral.svg" alt="copiar link de referido"/>
        <p class="subtitle">
          {{ t("referral_link.send_link_instruction") }}
        </p>

        <div v-if="selectOptions" class="select-container">
          <select-form v-if="selectOptions.length"
            class="form-select"
            v-model="selectValue"
            :options="selectOptions"
            value-field="code"
            text-field="name"
            :type-text="false"
            :placeholder="`${t('referral_link.pick_kind')}`"
            id="credit-type-selector"/>

          <b-alert v-else show variant="warning" class="text-center">
          {{ t("referral_link.no_credits_available") }}
          </b-alert>
        </div>

        <div v-if="errors.some(e => e.status === 422)" class="mt-3 text-center">
          <b-alert show variant="warning">
          {{ t("referral_link.no_pipedrive_id") }}
          </b-alert>
        </div>

      </div>
      <div class="options-container my-2">
        <div v-if="selectValue">
          <div class="credit-option" v-for="(item,i) in referralLinks[selectValue]" :key="i"
            @click="copyLink(Object.values(item)[0])">
            <p class="credit-type">{{ formatItem(item) }}</p>
            <button class="button button-rounded neutral copy-button" v-b-tooltip.hover title="Copiar vinculo">
              <em class="far fa-copy primary-100"/>
            </button>
          </div>
        </div>
      </div>
      <small v-if="selectOptions && selectOptions.length && !errors.length" class="text-center">
        {{ t("referral_link.remind_send") }}
      </small>
      <alert ref="notification"
        :text="alertMessage"
        :time="1"
        :position="'top-right'"
        v-on:onEvent="onEventAlert($event)"/>
    </b-modal>
  </div>
</template>

<script>
import referrals from "../../api/referral_links";
import Alert from '../template/Alert.vue'
import i18n from "../mixins/i18n"

export default {
  name: "modal-referral-link",
  components: { Alert },
  mixins: [i18n],
  data() {
      return {
        selectValue: null,
        selectOptions: null,
        referralLinks: null,
        alertMessage: 'Link copiado!',
        errors: [],
      }
    },
  methods: {
    copyLink(link) {
      navigator.clipboard.writeText(link);
      this.$refs.notification.show();
    },
    async getReferralLinks() {
      this.referralLinks = await referrals.getLinks();
      if (!this.referralLinks.error) {
        this.selectOptions = Object.keys(this.referralLinks).map(kind => {
          return {
            code: kind,
            name: `${kind[0]}${kind.slice(1).toLowerCase()}`
          }
        });
      } else {
        this.errors.push(this.referralLinks.error)
      }
    },
    formatItem(item) {
      let text = Object.keys(item)[0].replace(/_+/g, ' ');
      const avalIndex = text.indexOf('sin aval')
      if (avalIndex < 0) {
        text += ' con aval'
      }
      return text
    },
    onEventAlert(event) {
      if (event === 'close') {
        this.$bvModal.hide('modal-referral-links');
      }
    },
  },
  mounted() {
    this.$root.$on('bv::modal::show', (_bvEvent, modalId) => {
      if(modalId === 'modal-referral-links'){
        this.selectValue = null;
        this.getReferralLinks();
      }
    })
  }
}
</script>
<style lang="scss" scoped>
@import './styles.scss';

.select-container {
  margin-top: 20px;
  width: 100%;

  & .form-select {
    width: 100%;
  }
}
</style>
