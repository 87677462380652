import apiClient from '../../api/clients';

export default {
  props: [
    'pipedrivePersonId',
    'pipedriveDealId',
    'clientId'
  ],
  data: function() {
    return {
      client: {
        id: this.clientId,
        name: ''
      },
      guarantors: [],
    }
  },
  methods: {
    getClient: async function() {
      if(this.pipedriveDealId === 0 && this.pipedrivePersonId === 0) { return }

      const response = await apiClient.getByPipedrive(this.pipedrivePersonId, this.pipedriveDealId)

      if (response.status === 500) {
        this.client = {}
        this.guarantors = []
        return
      }

      this.client = response
      this.guarantors = this.client.guarantors
    },
  },
  mounted: function() {
    this.getClient()
  }
}
