<template>
  <div class="text-lg font-bold text-black">
    <dl class="py-2">
      <dt class="flex align-items-center">
        <span class="mr-2">
          Reserva:
        </span>

        <span style="display: contents;" v-if="!isNew">
          <dd class="text-lg text-gray-900 sm:mr-auto sm:mt-0 sm:col-span col-span-1 inline">
            <badge-status :color="badgeColorGeneral">
              {{ t(`activerecord.attributes.payment_status.${ preordersGeneralStatus }`) }}
            </badge-status>
            <b-button
              v-if="canRegisterPayment"
              variant="success"
              size="sm"
              @click="$bvModal.show('preorder-register-payment')">
                {{ t(`modal_receive_payment.register_payment`) }}
            </b-button>
            <b-button
              variant="link"
              size="sm"
              @click="$bvModal.show('preorder-details')">
                {{ t(`modal_receive_payment.view_details`) }}
            </b-button>
            </dd>
        </span>

          <!--Boton para crear-->
        <span v-else-if="isNew">
          <dd class="text-lg text-gray-900">
            <b-button
              variant="primary"
              size="sm"
              @click="toggleShowForm()"
              :disabled="canNotCreate"
              :hidden="showForm">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
              </svg>
            </b-button>
          </dd>
          <div class="flex align-items-center">
            <preorder-form
              v-if="showForm"
              :preorder_id="null"
              :amount="null"
              :simulation-id="simulationId"
              @toggleShowForm="toggleShowForm"
              @element="setElement"
              @amount="setAmount"
            />
          </div>
        </span>
      </dt>
    </dl>

    <b-modal id="preorder-details" hide-footer class="modal-xl" size="xl">
      <b-table :items="element" :fields="fields">
        <template #cell(status)="data" :aria-hidden="showForm">
          <badge-status :color="badgeColor(data.value)">
            {{ t(`activerecord.attributes.payment_status.${ data.value }`) }}
          </badge-status>
        </template>
        <template #cell(paid_at)="data">
          {{ data.value ? dateParse(data.value) : '-' }}
        </template>
        <template #cell(amount)="data">
          {{ data.value | currency('$', 0, { thousandsSeparator: '.', decimalSeparator: ',' })}}
        </template>
        <template #cell(action)="data">
          <!-- Botón editar -->
          <preorder-edit
            :data="data"
            :can-update-amount="canUpdateAmount"
            :preorder-status="data.item.status"
            :simulation-status="simulationStatus"
            :can-update-status="canUpdateStatus"
            @onSetAmount="setAmount"
            @onSetElement="setElement"
            @onChangeStatus="changeStatus"
          />
        </template>
      </b-table>
    </b-modal>

    <!-- Modal to register full or partial payments -->
    <b-modal id="preorder-register-payment"
      hide-footer
      size="sm"
      @show="resetModal"
      @hidden="resetModal"
      centered>
      <h4>
        Pendiente de pago {{ sumAmountByStatus('pending') | currency('$', 0, { thousandsSeparator: '.', decimalSeparator: ',' })}}
      </h4>
      <div class="mt-2" >
        <b-form-group :label="t(`modal_receive_payment.amount_to_pay`)">
          <b-form-input
            id="type-amount"
            v-model="paidAmount"
            type="number"
            placeholder="ej: 100000"
            label="Monto"
            required>
          </b-form-input>
        </b-form-group>
        <b-form-group>
          <b-form-select
            v-model="selectedPaymentSystem"
            :options="paymentSystemsList"
            :disabled="paidAmount === null"
            required>
            <template #first>
              <b-form-select-option
                :value="null"
                disabled>
                {{ t('modal_receive_payment.select_payment_method') }}
                </b-form-select-option>
            </template>
          </b-form-select>
        </b-form-group>
          <b-form-group
          :label="t('modal_receive_payment.payment_date')"
          label-for="datepicker">
          <b-form-datepicker
            id="datepicker"
            v-model="paymentDate"
            :max="maxSelectDate"
            locale="es"
            placeholder="dd/mm/aaaa"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            :disabled="paidAmount === null"
            required>
          </b-form-datepicker>
        </b-form-group>

        </div>
        <div class="flex">
          <div class="mt-4">
            <b-button
              variant="primary"
              size="sm"
              @click="registerPayment()"
              :disabled="paidAmount === null">
              {{ t('modal_receive_payment.register_payment')}}
            </b-button>
          </div>
        </div>

    </b-modal>
    <m-modal ref="m-modal" :title="titleModalInformative" :status="statusModalInformative" :ok-only="true" size="md">
      <template slot="body">
        <p v-html="bodyModalInformative"></p>
      </template>
    </m-modal>
  </div>
</template>
<script>
  import preorderApi from '../../api/preorders';
  import i18n from "../mixins/i18n"
  import PreorderForm from './PreorderForm.vue';
  import BadgeStatus from './BadgeStatus'
  import { badgeColorPaymentStatuses, paymentStatuses, simulationStatusesForPreorder } from '../../constants/statuses'
  import includes from 'lodash/includes';
  import isEmpty from 'lodash/isEmpty';
  import moment from 'moment';
  import PreorderEdit from './PreorderEdit.vue';
  import { CHARGE_ORDER_PAYMENT_SYSTEMS_LIST } from '../../constants/payments';
  import MModal from '../dynamics_form/MModal.vue';

  export default {
  components: {
    PreorderForm,
    BadgeStatus,
    PreorderEdit,
    MModal
  },
    mixins: [i18n],
    name: 'preorder-detail',
    props: [
      'simulationId',
      'simulationStatus',
      'hasInventoryItem',
      'userRole',
      'type',
      'userRolifyRoles'
    ],
    data: function () {
      const maxDate = new Date();
      return {
        element: [],
        showForm: false,
        paymentStatuses: paymentStatuses,
        selectedPaymentStatus: null,
        preordersGeneralStatus: null,
        selectedToEdit: null,
        isNew: false,
        paidAmount: null,
        selectedPaymentSystem: null,
        paymentSystemsList: CHARGE_ORDER_PAYMENT_SYSTEMS_LIST,
        paymentDate: '',
        maxSelectDate: maxDate,
        titleModalInformative: null,
        bodyModalInformative: null,
        statusModalInformative: null,
        fields: [
          {
            key: 'internal_code',
            label: this.t(`modal_down_payment_details.code`),
          },
          {
            key: 'status',
            label: this.t(`modal_down_payment_details.status`),
          },
          {
            key: 'payment_system',
            label: this.t(`modal_down_payment_details.payment_system`),
          },
          {
            key: 'paid_at',
            label: this.t(`modal_down_payment_details.paid_at`),
          },
          {
            key: 'amount',
            label: this.t(`modal_down_payment_details.amount`),
          },
          {
            key: 'action',
            label: this.t(`modal_down_payment_details.action`),
          },
          {
            key: 'edit',
            label: ''
          },
        ],
        rolesUpdateStatus: [
          'operations_manager',
          'operations_coordinator',
          'operations_visator',
          'operations_executive',
          'operations_boss',
          'manager',
        ],
        rolesUpdateAmount: [
          'manager',
          'sales_exec',
          'sales_team_leader',
          'admissions',
          'operations_exec',
          'operations',
          'operations_boss',
          'collections',
          'collections_boss',
          'human_resources',
          'project_engineer'
        ],
      }
    },
    methods: {
      setPreorder: async function() {
        const response = await preorderApi.getPreorderFromSimulation(this.simulationId)

        if (isEmpty(response)) {
          this.isNew = true
          return
        }

        this.element = response
        this.isNew = false
      },
      sumAmountByStatus: function(status) {
        let sum = 0
        this.element.forEach(element => {
          if (element.status === status) {
            sum += element.amount
          }
        })
        return sum
      },
      toggleShowForm: function(){
        this.showForm = !this.showForm
      },
      setAmount: function(amount, id) {
        this.element.find(e => e.id == id).amount = amount
      },
      setElement: function(value) {
        this.element = [value]
        this.isNew = false
        this.preordersGeneralStatus = 'pending'
      },
      validSimulationStatuses: function(statuses) {
        return includes(statuses, this.simulationStatus)
      },
      isAuthorized: function(authorizedRoles) {
        return includes(authorizedRoles, this.userRole)
          && includes(this.userRolifyRoles, 'can_register_payment')
      },
      isAuthorizedToUpdateAmount(authorizedRoles){
        return includes(authorizedRoles, this.userRole)
      },
      isAdmin: function() {
        return this.userRole === 'admin'
      },
      isTechSupport() {
        return this.userRole === 'tech_support'
      },
      registerPayment: async function() {
        const response = await preorderApi.registerPayment(this.paidAmount,
          this.simulationId,
          this.selectedPaymentSystem,
          this.paymentDate)
        this.$bvModal.hide('preorder-register-payment')
        if (response.status === 422) {
          let message = this.t(`modal_receive_payment.message.preorder.invalid_transition`)
          alert(message)
        } else {
          let message = this.t(`modal_receive_payment.message.preorder.success`)
          alert(message)
          this.element = response
          this.setPreorderGeneralStatus()
        }
      },
      dateParse: function(value) {
        return moment(value).format('DD-MM-YYYY');
      },
      setPreorderGeneralStatus: async function() {
        const response = await preorderApi.getGeneralStatus(this.simulationId)
        this.preordersGeneralStatus = response.general_status
      },
      badgeColor(status) {
        return badgeColorPaymentStatuses[status];
      },
      resetModal: function() {
        this.selectedPaymentStatus = null
        this.selectedPaymentSystem = null
        this.paidAmount = null
        this.paymentDate = ''
      },
      changeStatus: async function(preorder_id, transition) {
        let response = await preorderApi.updateStatus(preorder_id, transition)
        if(response.status == 200){
          this.bodyModalInformative = ''
          this.titleModalInformative = this.t(`mmodal.preorder.title.success`)
          this.statusModalInformative = 'success'
          this.$refs['m-modal'].toggleModal(true)
          this.setPreorderGeneralStatus()
          this.setPreorder()
        }else{
          this.bodyModalInformative = this.t(`mmodal.preorder.body_text.error.generic`)
          if(response.status == 422) {
            this.bodyModalInformative =  this.getResponseErrors(response.data)
          }
          this.titleModalInformative = this.t(`mmodal.preorder.title.error`)
          this.statusModalInformative = 'error'
          this.$refs['m-modal'].toggleModal(true)
          setTimeout(() => location.reload(), 4000);
        }
      },
      getResponseErrors: function(data){
        if(typeof data[0].attribute == 'object'){ return this.t(`mmodal.preorder.body_text.error.generic`) }
        if(typeof data[0].attribute == 'string' ) { return this.t(`mmodal.preorder.body_text.error.${ data[0].attribute }`) }
      }
    },
    mounted() {
      this.setPreorderGeneralStatus()
      this.setPreorder()
    },
    computed: {
      badgeColorGeneral: function() {
        return badgeColorPaymentStatuses[this.preordersGeneralStatus]
      },
      // shows the select-options if is an autorized role, if the preorder status is NOT refunded and the simulation has
      // a valid status
      canUpdateStatus() {
        return ((this.isAuthorized(this.rolesUpdateStatus) || this.isAdmin() || this.isTechSupport())
          && (this.element.status !== 'refunded' && this.element.status !== 'moved') )
      },
      canUpdateAmount() {
        return ((this.isAuthorizedToUpdateAmount(this.rolesUpdateAmount) || this.isAdmin() || this.isTechSupport())
          && (this.element.status !== 'refunded' && this.element.status !== 'moved') )
      },
      // return false to the disabled HTML attribute if the simulation has a valid status, hasInventoryItem
      // and is not Leasing
      canNotCreate: function() {
        return !(this.validSimulationStatuses(simulationStatusesForPreorder)
          && this.hasInventoryItem && this.type != 'leasing')
      },
      canRegisterPayment: function() {
        return this.preordersGeneralStatus === 'pending'
          && (this.isAuthorized(['operations_boss', 'manager'])
            || this.isAdmin()
            || this.isTechSupport())
      },
    }
  }
</script>
