<template>
  <div class="col-span-8 sm:grid sm:grid-cols-8">
    <div class="col-span-8">
      <div class="py-2 sm:grid sm:grid-cols-8">
        <dt class="text-base font-bold text-black col-span-1">
          Primer nombre:
        </dt>
        <dd class="text-base text-black col-span-3 ml-4">
          {{ this.element.client.first_name }}
        </dd>
        <dt class="text-base font-bold text-black col-span-1">
          Segundo nombre:
        </dt>
        <dd class="text-base text-black col-span-3 ml-4">
          {{ this.element.client.middle_name }}
        </dd>
        <dt class="text-base font-bold text-black col-span-1">
          Primer apellido:
        </dt>
        <dd class="text-base text-black col-span-3 ml-4">
          {{ this.element.client.last_name }}
        </dd>
        <dt class="text-base font-bold text-black col-span-1">
          Segundo apellido:
        </dt>
        <dd class="text-base text-black col-span-3 ml-4">
          {{ this.element.client.second_last_name }}
        </dd>
        <dt class="text-base font-bold text-black col-span-1">
          Teléfono:
        </dt>
        <dd class="text-base text-black col-span-3 ml-4">
          {{ this.element.client.phone }}
        </dd>
        <dt class="text-base font-bold text-black col-span-1">
          RUT:
        </dt>
        <dd class="text-base text-black sm:mt-0 sm:col-span col-span-3 ml-4">
          {{ this.element.client.rut }}-{{ this.element.client.dv }}
        </dd>
      </div>
      <div class="py-2 sm:grid sm:grid-cols-8">
        <dt class="text-base font-bold text-black col-span-1">
          Pasaporte:
        </dt>
        <dd class="text-base text-black sm:mt-0 sm:col-span col-span-3 ml-4">
          {{ this.element.client.passport }}
        </dd>
        <dt class="text-base font-bold text-black col-span-1">
          Género:
        </dt>
        <dd class="text-base text-black sm:mt-0 sm:col-span col-span-3 ml-4">
          {{ this.element.client.gender }}
        </dd>
        <dt class="text-medium font-bold text-black col-span-1">
          Nacionalidad:
        </dt>
        <dd class="text-base text-black sm:mt-0 sm:col-span col-span-3 ml-4">
          {{ this.element.client.nationality }}
        </dd>
        <dt class="text-base font-bold text-black col-span-1">
          Correo:
        </dt>
        <dd class="text-base text-black sm:mt-0 sm:col-span col-span-3 ml-4">
          {{ this.element.client.email }}
        </dd>
      </div>
      <div class="py-2 sm:grid sm:grid-cols-8">
        <h4 class="text-lg leading-6 font-bold text-gray-900">
          Dirección
        </h4>
      </div>
      <div v-if="this.canShowClientAddress()" class="py-2 sm:grid sm:grid-cols-8">
        <dt class="text-base font-bold text-black col-span-1">
          Calle:
        </dt>
        <dd class="text-base text-black sm:mt-0 sm:col-span col-span-3 ml-4">
          <span >
            {{  this.element.client_address.street_name }}
          </span>
        </dd>
        <dt class="text-base font-bold text-black col-span-1">
          Número:
        </dt>
        <dd class="text-base text-black sm:mt-0 sm:col-span col-span-3 ml-4">
          <span v-if="typeof this.element.client_address.street_name !== 'undefined'">
            {{ this.element.client_address.street_number }}
          </span>
        </dd>
        <dt class="text-base font-bold text-black col-span-1">
          Dpto:
        </dt>
        <dd class="text-base text-black sm:mt-0 sm:col-span col-span-3 ml-4">
          <span v-if="typeof this.element.client_address.street_name !== 'undefined'">
            {{ this.element.client_address.door_number }}
          </span>
        </dd>
        <dt class="text-base font-bold text-black col-span-1">
          Comuna:
        </dt>
        <dd class="text-base text-black sm:mt-0 sm:col-span col-span-3 ml-4">
          <span v-if="typeof this.element.client_address.street_name !== 'undefined'">
            {{ this.element.client_address.commune }}
          </span>
        </dd>
        <dt class="text-base font-bold text-black col-span-1">
          Ciudad:
        </dt>
        <dd class="text-base text-black sm:mt-0 sm:col-span col-span-3 ml-4">
          <span v-if="typeof this.element.client_address.street_name !== 'undefined'">
            {{ this.element.client_address.city_name }}
          </span>
        </dd>
      </div>
    </div>
  </div>
</template>
<script>
  import _ from 'lodash';

  export default {
    name: 'CardPerson',
    props: ['element'],
    methods: {
      canShowClientAddress: function(){
        return !_.isNil(this.element.client_address)
      }
    }
  }
</script>
