<template>
  <span class="w-2 text-white h-2 rounded mr-1 text-sm p-1 pl-2 pr-2" :class="`${color}`">
    <slot></slot>
  </span>
</template>
<script>
export default {
  name: 'badge-status',
  props: {
    color: {
      type: String,
      default: "teal"
    }
  }
};
</script>
