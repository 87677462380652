<template>
  <div class="d-inline">
    <button v-b-modal.modalone class="btn btn-outline-primary dashed-button ml-1" type="button">
      <i class="fas fa-file-upload" ></i>
      <p class="m-0 font-s14" >
        Carga masiva de inventario
      </p>

    </button>

    <b-modal id="modalone" hide-footer title="Carga masiva de inventario">
      <b-form-group label="Selecciona la linea de producto a subir" v-slot="{ ariaDescribedby }">
        <b-form-radio
          v-for="line in lines"
          :key="line.id"
          v-model="selected"
          :aria-describedby="ariaDescribedby"
          name="line"
          :value="line"
        >
          {{line.name}}
        </b-form-radio>
      </b-form-group>

        <div class="d-flex">
          <b-button class="w-100 " @click="$bvModal.hide('modalone')" variant="outline-primary">
            Cancelar
          </b-button>

          <b-button class="w-100 ml-1" v-b-modal.modalfile variant="primary" :disabled="!selected">
            Continuar
          </b-button>
        </div>

    </b-modal>



    <b-modal id="modalfile" hide-footer :title="`Carga masiva de línea de producto ${selected.name}`">

      <b-alert variant="success" show v-if="successMessage">{{successMessage}}</b-alert>
      <b-alert variant="danger" show v-if="errorMessages" v-html="errorMessages"></b-alert>

      <b-form-file
        v-model="file"
        :state="Boolean(file)"
        placeholder="Arrastra y suelta tu archivo aqui, o haz clic para buscar en tu computador"
        drop-placeholder="Drop file here..."
        accept="text/csv"
        browse-text="BUSCAR"
        class="font-s8"
      >
        <template slot="placeholder">
          <small>Arrastra y suelta tu archivo aqui, o haz clic para buscar en tu computador.</small>
        </template>
      </b-form-file>

      <div class="mt-4">
        <small>¿Necesitas ayuda? Descarga el archivo tipo de la línea de producto {{selected.name}}</small>
      </div>

      <div class="mt-1 mb-4">
        <small>
          <b-link :href="selected.template_url">
            Descargar archivo CSV <i class="fas fa-download" ></i>
          </b-link>
          </small>
      </div>

        <div class="d-flex">
          <b-button class="w-100 " @click="$bvModal.hide('modalfile')" variant="outline-primary">
            Cancelar
          </b-button>

          <b-button class="w-100 ml-1" @click="submitFile" variant="primary" :disabled="!Boolean(file)">
            Continuar
          </b-button>
        </div>

    </b-modal>
  </div>
</template>

<script>
  import inventory from '../../api/inventory';

  export default {
    name: 'bulk-upload',
    components: {},
    props: [
      'inventoryTypes'
    ],
    data() {
      return {
        selected: '',
        file: null,
        successMessage: '',
        errorMessages: '',
        lines: this.inventoryTypes || []
      }
    },
    computed: {

    },
    mounted: async function() {},
    methods: {
      submitFile: async function() {
        const resp =  await inventory.bulkUpload(this.file, this.selected.id)

        if(resp.message)
          this.successMessage = resp.message

        if(resp.errors)
          this.errorMessages = resp.errors

      }
    }
  }
</script>
