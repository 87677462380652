import _ from 'lodash'
import moment from 'moment'
import creditApi from '../../api/credits'
import simulationApi from '../../api/simulations'
import inventoryMixin from './inventory'
import {
  FIXED_LEASING_INSTALLMENTS_COUNT,
  FIXED_CREDIT_INSTALLMENTS_COUNT,
  insuranceTranslations
} from '../../constants/simulator'

export default {
  props: [
    'pipedrivePersonId',
    'pipedriveDealId',
    'clientId',
    'simulationId'
  ],
  mixins: [inventoryMixin],
  data: function() {
    return {
      currentSimulation: null,
      driversLicense: false,
      installments: [],
      extraCostsList: [],
      installmentsAmount: FIXED_CREDIT_INSTALLMENTS_COUNT,
      productTypesList: [],
      selectedProductTypeId: null,
      selectedVehicleModelId: null,
      selectedColorId: null,
      signDate: moment().format('YYYY-MM-DD'),
      simulationType: 'normal',
      selectedExtraCosts: [],
      selectedExtraCostName: null,
      selectedExtraCostAmount: null,
      selectedExtraCostCategory: null,
      selectedExtraCostHumanName: null,
      // bikeInsurance: null,
      liabilityInsurance: null,
      lienInsurance: null,
      lienInsuranceDealer: null,
      panelsLienInsurance: null,
      panels50LienInsurance: null,
      lifeInsurance: null,
      planUf3Insurance: null,
      planUf6Insurance: null,
      severanceInsurance: null,
      stampDuty: null,
      transferTax: null,
      fogape: null,
      pledgeAgreement: null,
      prohibition: null,
      warranty: null,
      minorRepairs: null,
      totalLoss: null,
      digitalSignature: null,
      greenTax: null,
      circulationPermit: null,
      mandatoryExtraCosts: [],
      completeMandatoryExtraCosts: null,
    }
  },
  methods: {
    updateSimulationId: function(value) {
      this.simulationId = value
    },
    updateCurrentSimulation: function(value) {
      this.currentSimulation = value
    },
    updateNetAmount: function(value) {
      this.netAmount = value
    },
    signDateUpdated: function(value) {
      this.signDate = value
    },
    simulationTypeUpdated: function(value) {
      this.simulationType = value
    },
    updateMonthsForLienInsurance: function(value){
      this.monthsForLienInsurance = value
      this.calculateBikeInsurance(this.productValue)
      // this.recalculateGrossAmount()
    },
    getProductTypesList: async function() {
      const response = await creditApi.productTypesList()
      this.productTypesList = response.data
    },
    setCurrentSimulation: async function() {
      if (!this.simulationId) { return }

      this.currentSimulation = await simulationApi.getSimulation(this.simulationId)
      this.selectedProductTypeId = this.currentSimulation.product_type.id
      this.installmentsAmount = this.currentSimulation.installments_amount
      this.interestRatePerMonth = this.currentSimulation.interest_rate_per_month
      this.addExtraCostsGroup(this.currentSimulation.extra_costs)
      this.selectedInventoryItemId = this.currentSimulation.inventory_item?.id
      this.$emit('updateProductValue', this.currentSimulation.product_amount)
    },
    addExtraCost: function() {
      if (_.isNil(this.selectedExtraCostName) || _.isNil(this.selectedExtraCostAmount)) { return }
      if (this.selectedExtraCosts.find((sec) => (sec.name === this.selectedExtraCostName))) {
        if (!confirm(`¿Seguro que quieres sobreescribir el monto de ${this.selectedExtraCostHumanName}?`)){
          return
        }
      }
      // prevent duplicated extra costs
      this.selectedExtraCosts = this.selectedExtraCosts.filter((sec) => (sec.name !== this.selectedExtraCostName))
      this.selectedExtraCosts = [
        ...this.selectedExtraCosts,
        {
          name: this.selectedExtraCostName,
          human_name: this.selectedExtraCostHumanName,
          amount: this.selectedExtraCostAmount,
          category: this.selectedExtraCostCategory
        }
      ]
      this.containsMandatoryExtraCosts()

      this[insuranceTranslations[this.selectedExtraCostName]] = true

      this.selectedExtraCostName = null
      this.selectedExtraCostHumanName = null
      this.selectedExtraCostAmount = null
      this.selectedExtraCostCategory = null
    },
    addExtraCostsGroup: function (extra_costs_list) {
      if (_.isNil(extra_costs_list)) { return }
      this.selectedExtraCosts = extra_costs_list
      // this.bikeInsurance = this.selectedExtraCosts.some((ec) => ec.name === 'bike_insurance')
      this.liabilityInsurance = this.selectedExtraCosts.some((ec) => ec.name === 'liability_insurance')
      this.lienInsurance = this.selectedExtraCosts.some((ec) => ec.name === 'lien_insurance')
      this.lienInsuranceDealer = this.selectedExtraCosts.some((ec) => ec.name === 'lien_insurance_dealer')
      this.panelsLienInsurance = this.selectedExtraCosts.some((ec) => ec.name === 'panels_lien_insurance')
      this.panels50LienInsurance = this.selectedExtraCosts.some((ec) => ec.name === 'panels_50_lien_insurance')
      this.lifeInsurance = this.selectedExtraCosts.some((ec) => ec.name === 'life_insurance')
      this.stampDuty = this.selectedExtraCosts.some((ec) => ec.name === 'taxes')
      this.planUf3Insurance = this.selectedExtraCosts.some((ec) => ec.name === 'planuf3_insurance')
      this.planUf6Insurance = this.selectedExtraCosts.some((ec) => ec.name === 'planuf6_insurance')
      this.fogape = this.selectedExtraCosts.some((ec) => ec.name === 'fogape')
      this.pledgeAgreement = this.selectedExtraCosts.some((ec) => ec.name === 'pledge_agreement')
      this.prohibition = this.selectedExtraCosts.some((ec) => ec.name === 'prohibition')
      this.warranty = this.selectedExtraCosts.some((ec) => ec.name === 'warranty')
      this.minorRepairs = this.selectedExtraCosts.some((ec) => ec.name === 'minor_repairs')
      this.totalLoss = this.selectedExtraCosts.some((ec) => ec.name === 'total_loss')
      this.digitalSignature = this.selectedExtraCosts.some((ec) => ec.name === 'digital_signature')
      this.greenTax = this.selectedExtraCosts.some((ec) => ec.name === 'green_tax')
      this.circulationPermit = this.selectedExtraCosts.some((ec) => ec.name === 'circulation_permit')
      this.severanceInsurance = this.selectedExtraCosts.some((ec) => ec.name === 'severance_insurance')

      this.containsMandatoryExtraCosts()
    },
    containsMandatoryExtraCosts: async function() {
      if (_.isNil(this.selectedProductTypeId)) {
        this.completeMandatoryExtraCosts = false
        return
      }

      const isMandatory = (currentValue) => this.selectedExtraCosts.find((ec) => ec.name === currentValue.name);
      const response = await creditApi.productTypeExtraCosts(this.selectedProductTypeId)
      this.mandatoryExtraCosts = response.data.filter((ec) => ec.mandatory === true)
      this.completeMandatoryExtraCosts = this.mandatoryExtraCosts.every(isMandatory)
    },
    getExtraCostsList: async function() {
      const response = await creditApi.extraCostsList()
      this.extraCostsList = response.data
    },
    addProductTypeExtraCosts: async function () {
      const response = await creditApi.productTypeExtraCosts(this.selectedProductTypeId)
      this.addExtraCostsGroup(response.data)
    },
    removeExtraCost: function(name) {
      const extraCostToDestroy = this.selectedExtraCosts.find((ec) => ec.name === name)

      if (_.isNil(extraCostToDestroy)){ return }

      if (extraCostToDestroy.name == 'lien_insurance') {
        this.lienInsurance = false
      }
      if (extraCostToDestroy.name == 'lien_insurance_dealer') {
        this.lienInsuranceDealer = false
      }
      if (extraCostToDestroy.name == 'panels_lien_insurance') {
        this.panelsLienInsurance = false
      }
      if (extraCostToDestroy.name == 'panels_50_lien_insurance') {
        this.panels50LienInsurance = false
      }
      if (extraCostToDestroy.name == 'taxes') {
        this.stampDuty = false
      }
      if (extraCostToDestroy.name == 'life_insurance') {
        this.lifeInsurance = false
      }
      if (extraCostToDestroy.name == 'liability_insurance') {
        this.liabilityInsurance = false
      }
      // if (extraCostToDestroy.name == 'bike_insurance') {
      //   this.bikeInsurance = false
      // }
      if (extraCostToDestroy.name == 'fogape') {
        this.fogape = false
      }
      if (extraCostToDestroy.name == 'pledge_agreement') {
        this.pledgeAgreement = false
      }
      if (extraCostToDestroy.name == 'severance_insurance') {
        this.severanceInsurance = false
      }
      if (extraCostToDestroy.name == 'prohibition') {
        this.prohibition = false
      }
      if (extraCostToDestroy.name == 'warranty') {
        this.warranty = false
      }
      if (extraCostToDestroy.name == 'minor_repairs') {
        this.minorRepairs = false
      }
      if (extraCostToDestroy.name == 'total_loss') {
        this.totalLoss = false
      }
      if (extraCostToDestroy.name == 'digital_signature') {
        this.digitalSignature = false
      }
      if (extraCostToDestroy.name == 'green_tax') {
        this.greenTax = false
      }
      if (extraCostToDestroy.name == 'circulation_permit') {
        this.circulationPermit = false
      }

      this.selectedExtraCosts = this.selectedExtraCosts.filter((ec) => ec.name !== name)
      this.containsMandatoryExtraCosts()
    },
    installmentsUpdated: function(value) {
      this.installments = value
    },
    unemploymentInsuranceUpdated: function(value) {
      if(value.name === 'unemploymentInsurance' && !value.value){
        this.planUf3Insurance = false
        this.planUf6Insurance = false
        this.removeExtraCost('planuf3_insurance')
        this.removeExtraCost('planuf6_insurance')
      } else {
        if (value.name == 'planUf3Insurance' && value.value) {
          this.planUf3Insurance = true
          this.planUf6Insurance = false
          this.removeExtraCost('planuf6_insurance')

        } else if (value.name == 'planUf6Insurance' && value.value) {
          this.planUf6Insurance = true
          this.planUf3Insurance = false
          this.removeExtraCost('planuf3_insurance')
        }
      }
    }
  },
  watch: {
    simulationType: function(newValue, oldValue) {
      this.installmentsAmount =  this.installmentsAmount || newValue === 'leasing' ? FIXED_LEASING_INSTALLMENTS_COUNT : FIXED_CREDIT_INSTALLMENTS_COUNT
    },
    selectedExtraCostName: function(newValue, oldValue) {
      if (_.isNil(newValue)) {
        this.selectedExtraCostHumanName = null
        this.selectedExtraCostCategory = null
      } else {
        this.selectedExtraCostHumanName = this.extraCostsList.find((item) => item.name === newValue).human_name
        this.selectedExtraCostCategory = this.extraCostsList.find((item) => item.name === newValue).category
      }
    },
    lifeInsurance: function(newValue, oldValue) {
      if(newValue && oldValue !== null) {
        this.selectedExtraCostName = 'life_insurance'
        this.selectedExtraCostHumanName = 'Seguro de vida'
        this.selectedExtraCostAmount = 'calcular'
        this.selectedExtraCostCategory = 'gross'
        this.addExtraCost()
      } else if(oldValue !== null) {
        this.removeExtraCost('life_insurance')
      }
    },
    // bikeInsurance: function(newValue, oldValue) {
    //   if(newValue && oldValue !== null) {
    //     this.selectedExtraCostName = 'bike_insurance'
    //     this.selectedExtraCostHumanName = 'Seguro de Moto'
    //     this.selectedExtraCostAmount = 'calcular'
    //     this.selectedExtraCostCategory = 'net'
    //     this.addExtraCost()
    //   } else if(oldValue !== null) {
    //     this.removeExtraCost('bike_insurance')
    //   }
    // },
    liabilityInsurance: function(newValue, oldValue) {
      if(newValue && oldValue !== null) {
        this.selectedExtraCostName = 'liability_insurance'
        this.selectedExtraCostHumanName = 'Seguro de responsabilidad'
        this.selectedExtraCostAmount = 'calcular'
        this.selectedExtraCostCategory = 'net'
        this.addExtraCost()
      } else if(oldValue !== null) {
        this.removeExtraCost('liability_insurance')
      }
    },
    fogape: function(newValue, oldValue) {
      if(newValue && oldValue !== null) {
        this.selectedExtraCostName = 'fogape'
        this.selectedExtraCostHumanName = 'FOGAPE'
        this.selectedExtraCostAmount = 'calcular'
        this.selectedExtraCostCategory = 'gross'
        this.addExtraCost()
      } else if(oldValue !== null) {
        this.removeExtraCost('fogape')
      }
    },
    stampDuty: function(newValue, oldValue) {
      if(newValue && oldValue !== null) {
        this.selectedExtraCostName = 'taxes'
        this.selectedExtraCostHumanName = 'Impuestos'
        this.selectedExtraCostAmount = 'calcular'
        this.selectedExtraCostCategory = 'gross'
        this.addExtraCost()
      } else if(oldValue !== null) {
        this.removeExtraCost('taxes')
      }
    },
    lienInsurance: function(newValue, oldValue) {
      if(newValue && oldValue !== null) {
        this.selectedExtraCostName = 'lien_insurance'
        this.selectedExtraCostHumanName = 'Seguro Desgravamen'
        this.selectedExtraCostAmount = 'calcular'
        this.selectedExtraCostCategory = 'gross'
        this.addExtraCost()
      } else if(oldValue !== null) {
        this.removeExtraCost('lien_insurance')
      }
    },
    lienInsuranceDealer: function(newValue, oldValue) {
      if(newValue && oldValue !== null) {
        this.selectedExtraCostName = 'lien_insurance_dealer'
        this.selectedExtraCostHumanName = 'Seguro Desgravamen dealer'
        this.selectedExtraCostAmount = 'calcular'
        this.selectedExtraCostCategory = 'gross'
        this.addExtraCost()
      } else if(oldValue !== null) {
        this.removeExtraCost('lien_insurance_dealer')
      }
    },
    panelsLienInsurance: function(newValue, oldValue) {
      if(newValue && oldValue !== null) {
        this.selectedExtraCostName = 'panels_lien_insurance'
        this.selectedExtraCostHumanName = 'Seguro Desgravamen Paneles'
        this.selectedExtraCostAmount = 'calcular'
        this.selectedExtraCostCategory = 'gross'
        this.addExtraCost()
      } else if(oldValue !== null) {
        this.removeExtraCost('panels_lien_insurance')
      }
    },
    panels50LienInsurance: function(newValue, oldValue) {
      if(newValue && oldValue !== null) {
        this.selectedExtraCostName = 'panels_50_lien_insurance'
        this.selectedExtraCostHumanName = 'Seguro Desgravamen Paneles'
        this.selectedExtraCostAmount = 'calcular'
        this.selectedExtraCostCategory = 'gross'
        this.addExtraCost()
      } else if(oldValue !== null) {
        this.removeExtraCost('panels_50_lien_insurance')
      }
    },
    planUf3Insurance: function(newValue, oldValue) {
      if(newValue) {
        this.selectedExtraCostName = 'planuf3_insurance'
        this.selectedExtraCostHumanName = 'Seguro de cesantía plan 3xUF3'
        this.selectedExtraCostAmount = 'calcular'
        this.selectedExtraCostCategory = 'gross'
        this.addExtraCost()
      } else if(oldValue !== null) {
        this.removeExtraCost('planuf3_insurance')
      }
    },
    planUf6Insurance: function(newValue, oldValue) {
      if(newValue) {
        this.selectedExtraCostName = 'planuf6_insurance'
        this.selectedExtraCostHumanName = 'Seguro de cesantía plan 3xUF6'
        this.selectedExtraCostAmount = 'calcular'
        this.selectedExtraCostCategory = 'gross'
        this.addExtraCost()
      } else if(oldValue !== null) {
        this.removeExtraCost('planuf6_insurance')
      }
    },
    pledgeAgreement: function(newValue, oldValue) {
      if(newValue && oldValue !== null) {
        this.selectedExtraCostName = 'pledge_agreement'
        this.selectedExtraCostHumanName = 'Inscripción Prenda'
        this.selectedExtraCostAmount = 'calcular'
        this.selectedExtraCostCategory = 'gross'
        this.addExtraCost()
      } else if(oldValue !== null) {
        this.removeExtraCost('pledge_agreement')
      }
    },
    severanceInsurance: function(newValue, oldValue) {
      if(newValue && oldValue !== null) {
        this.selectedExtraCostName = 'severance_insurance'
        this.selectedExtraCostHumanName = 'Seguro de cesantía'
        this.selectedExtraCostAmount = 'calcular'
        this.selectedExtraCostCategory = 'gross'
        this.addExtraCost()
      } else if(oldValue !== null) {
        this.removeExtraCost('severance_insurance')
      }
    },
    prohibition: function(newValue, oldValue) {
      if(newValue && oldValue !== null) {
        this.selectedExtraCostName = 'prohibition'
        this.selectedExtraCostHumanName = 'Prohibición'
        this.selectedExtraCostAmount = 'calcular'
        this.selectedExtraCostCategory = 'gross'
        this.addExtraCost()
      } else if(oldValue !== null) {
        this.removeExtraCost('prohibition')
      }
    },
    minorRepairs: function(newValue, oldValue) {
      if(newValue && oldValue !== null) {
        this.selectedExtraCostName = 'minor_repairs'
        this.selectedExtraCostHumanName = 'Reparaciones menores'
        this.selectedExtraCostAmount = 'calcular'
        this.selectedExtraCostCategory = 'gross'
        this.addExtraCost()
      } else if(oldValue !== null) {
        this.removeExtraCost('minor_repairs')
      }
    },
    totalLoss: function(newValue, oldValue) {
      if(newValue && oldValue !== null) {
        this.selectedExtraCostName = 'total_loss'
        this.selectedExtraCostHumanName = 'Pérdida total'
        this.selectedExtraCostAmount = 'calcular'
        this.selectedExtraCostCategory = 'gross'
        this.addExtraCost()
      } else if(oldValue !== null) {
        this.removeExtraCost('total_loss')
      }
    },
    digitalSignature: function(newValue, oldValue) {
      if(newValue && oldValue !== null) {
        this.selectedExtraCostName = 'digital_signature'
        this.selectedExtraCostHumanName = 'Firma digital'
        this.selectedExtraCostAmount = 'calcular'
        this.selectedExtraCostCategory = 'gross'
        this.addExtraCost()
      } else if(oldValue !== null) {
        this.removeExtraCost('digital_signature')
      }
    },
    warranty: function(newValue, oldValue) {
      if(newValue && oldValue !== null) {
        this.selectedExtraCostName = 'warranty'
        this.selectedExtraCostHumanName = 'Garantía'
        this.selectedExtraCostAmount = 'calcular'
        this.selectedExtraCostCategory = 'gross'
        this.addExtraCost()
      } else if(oldValue !== null) {
        this.removeExtraCost('warranty')
      }
    },
    greenTax: function(newValue, oldValue) {
      if(newValue && oldValue !== null) {
        this.selectedExtraCostName = 'green_tax'
        this.selectedExtraCostHumanName = 'Impuesto Verde'
        this.selectedExtraCostAmount = 'calcular'
        this.selectedExtraCostCategory = 'net'
        this.addExtraCost()
      } else if(oldValue !== null) {
        this.removeExtraCost('green_tax')
      }
    },
    circulationPermit: function(newValue, oldValue) {
      if(newValue && oldValue !== null) {
        this.selectedExtraCostName = 'circulation_permit'
        this.selectedExtraCostHumanName = 'Permiso de Circulación'
        this.selectedExtraCostAmount = 'calcular'
        this.selectedExtraCostCategory = 'net'
        this.addExtraCost()
      } else if(oldValue !== null) {
        this.removeExtraCost('circulation_permit')
      }
    }
  },
}
