<template>
  <div class="row col">

    <div class="col-4">
      <b-form-group
        :label="`${t(`activerecord.attributes.provider.payment`)} ${parseInt(index) + 1}`"
        label-class=""
      >

        <b-form-input
          :min="0"
          oninput="validity.valid||(value='');"
          onpaste="return false"
          type="number"
          v-model="payment.amount"
          :placeholder="`${t(`activerecord.attributes.provider.payment`)}`"
        ></b-form-input>
      </b-form-group>
    </div>

    <div class="col-6">
      <b-form-group
        :label="`${t(`activerecord.attributes.provider.payment_date`)} ${parseInt(index) + 1}`"
        label-class=""
      >
        <b-form-datepicker
          :max="setEndDateMax()"
          v-model="payment.payment_date"
          :placeholder="`${t(`activerecord.attributes.provider.payment_date`)}`"
          locale="es"
        ></b-form-datepicker>
      </b-form-group>
    </div>

    <div class="col-2 d-flex justify-content-center">
      <button class="mt-4" @click="$bvModal.show('modal_delete_payment_item')"><i class="fas fa-times fa-1x"></i></button>
    </div>

      <b-modal id="modal_delete_payment_item" size="sm">
        <template #modal-title>
          <h4 class="title t1 width-max-content">{{ t(`activerecord.attributes.provider.confirm_delete_item`) }}</h4>
        </template>
          <div class="color-default mtp-20">{{ t(`activerecord.attributes.provider.text_delete_item`) }}</div>
        <template #modal-footer>
          <button class="footer-button base-button outline" style="margin-right: 12px !important;" @click="$bvModal.hide('modal_delete_payment_item')">
            {{ t(`simulations.cancel_unlink`) }}
          </button>
          <button class="footer-button base-button" @click="deletePaymentItem(parseInt(index), payment.id)">{{ t(`activerecord.attributes.provider.delete_payment`) }}</button>
        </template>
      </b-modal>
  </div>
</template>

<script>
import i18n from "../mixins/i18n"

export default {
  name: 'PaymentProvider',
  props: {
    payment: Object,
    index: Number
  },
  mixins: [i18n],
  methods: {
    setEndDateMax() {
      const now = new Date()
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
      return today.toISOString().split('T')[0]
    },
    deletePaymentItem(index, id){
      this.$emit('deletePaymentItem', index, id);
    },
  }
}
</script>
