<template>
  <div>
    <b-form-group
      :label="name"
      label-class="text-capitalize"
      :aria-describedby="`${helpId} ${feedbackId}`"
      :state="state"
    >
      <b-form-input
        :readonly="readonly"
        :id="_uid + name + id"
        :type="type"
        v-model="value"
        :state="state"
        trim
        :aria-describedby="`${helpId} ${feedbackId}`"
      ></b-form-input>

      <b-form-invalid-feedback :id="feedbackId">
        Es requerido
      </b-form-invalid-feedback>

      <div class="d-block" >
        <span class="text-danger" v-if="error" >{{ error }}</span>
      </div>

      <div class="d-block" >
        <b-form-text :id="helpId">{{ description }}</b-form-text>
      </div>

    </b-form-group>
  </div>
</template>

<script>
  import moment from 'moment';
  import fieldsMixin from '../../mixins/fields';

  export default {
    name: 'InputField',
    mixins: [fieldsMixin],
    mounted: function() {
      if(this.type === 'date' && this.value){
        if(['invalid date', 'Invalid date', '0'].includes(this.value))
          this.value = ''
        else
          this.value = moment(this.value).format('YYYY-MM-DD')
      }
    }
  }
</script>
