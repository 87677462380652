export default {
  data: function() {
    return {
      icons: [
        {
          class: 'glass-martini',
          code: 'fas fa-glass-martini',
          translate: 'alcohol bar bebidas beber licor'
        },
        {
          class: 'music',
          code: 'fas fa-music',
          translate: 'letra melodía nota cantar sonido'
        },
        {
          class: 'search',
          code: 'fas fa-search',
          translate: 'más grande agrandar encontrar magnificar vista previa zoom buscar busqueda'
        },
        {
          class: 'heart',
          code: 'fas fa-heart',
          translate: 'favorito como amor relacion san valentin'
        },
        {
          class: 'star',
          code: 'fas fa-star',
          translate: 'logro premio favorito importante noche calificación puntuación'
        },
        {
          class: 'user',
          code: 'fas fa-user',
          translate: 'cuenta avatar cabeza humano hombre persona perfil'
        },
        {
          class: 'check',
          code: 'fas fa-check',
          translate: 'aceptar de acuerdo marca de verificación confirmar correcto hecho aviso notificación notificar ok seleccionar éxito marcar todo sí'
        },
        {
          class: 'times',
          code: 'fas fa-times',
          translate: 'cerrar cruce error salir incorrecto aviso notificación notificar problema mal x'
        },
        {
          class: 'cog',
          code: 'fas fa-cog',
          translate: 'engranaje mecánico ajustes rueda dentada'
        },
        {
          class: 'home',
          code: 'fas fa-home',
          translate: 'morada edificio casa principal'
        },
        {
          class: 'clock',
          code: 'fas fa-clock',
          translate: 'fecha tarde programar hora temporizador marca de tiempo ver'
        },
        {
          class: 'lock',
          code: 'fas fa-lock',
          translate: 'administrador bloquear abrir contraseña privado proteger seguridad'
        },
        {
          class: 'flag',
          code: 'fas fa-flag',
          translate: 'país aviso notificación notificar poste informe símbolo'
        },
        {
          class: 'headphones',
          code: 'fas fa-headphones',
          translate: 'audio escuchar música sonido altavoz'
        },
        {
          class: 'tag',
          code: 'fas fa-tag',
          translate: 'precio de etiqueta de descuento de compras'
        },
        {
          class: 'tags',
          code: 'fas fa-tags',
          translate: 'precio de etiqueta de descuento de compras'
        },
        {
          class: 'book',
          code: 'fas fa-book',
          translate: 'diario documentación diario biblioteca leer'
        },
        {
          class: 'bookmark',
          code: 'fas fa-bookmark',
          translate: 'marcador favorito leer recordar guardar'
        },
        {
          class: 'camera',
          code: 'fas fa-camera',
          translate: 'la imagen de la lente foto foto registro del obturador de vídeo'
        },
        {
          class: 'video',
          code: 'fas fa-video',
          translate: 'cámara de película de película de registro de cámara de video'
        },
        {
          class: 'map-marker',
          code: 'fas fa-map-marker',
          translate: 'dirección coordenadas destino gps localizar ubicación mapa navegación papel alfiler lugar punto de interés posición ruta viaje'
        },
        {
          class: 'play',
          code: 'fas fa-play',
          translate: 'audio música reproduciendo sonido iniciar video'
        },
        {
          class: 'stop',
          code: 'fas fa-stop',
          translate: 'cuadro de bloque cuadrado'
        },
        {
          class: 'asterisk',
          code: 'fas fa-asterisk',
          translate: 'anotación detalles referencia estrella'
        },
        {
          class: 'gift',
          code: 'fas fa-gift',
          translate: 'navidad generosidad dando vacaciones partido presentes envuelto navidad'
        },
        {
          class: 'leaf',
          code: 'fas fa-leaf',
          translate: 'eco flora naturaleza planta vegano'
        },
        {
          class: 'fire',
          code: 'fas fa-fire',
          translate: 'quemar caliente llama calentar caliente populares'
        },
        {
          class: 'eye',
          code: 'fas fa-eye',
          translate: 'mirar óptica ver visto mostrar vista vistas visible'
        },
        {
          class: 'plane',
          code: 'fas fa-plane',
          translate: 'avión destino mosca ubicación modo viaje viaje'
        },
        {
          class: 'calendar-alt',
          code: 'fas fa-calendar-alt',
          translate: 'calendario fecha evento programar hora cuando'
        },
        {
          class: 'comment',
          code: 'fas fa-comment',
          translate: 'burbuja chat comentando conversación retroalimentación mensaje nota notificación sms habla mensajes de texto'
        },
        {
          class: 'magnet',
          code: 'fas fa-magnet',
          translate: 'Atraer herramienta de piedra imán'
        },
        {
          class: 'shopping-cart',
          code: 'fas fa-shopping-cart',
          translate: 'comprar caja de abarrotes pago compra'
        },
        {
          class: 'camera-retro',
          code: 'fas fa-camera-retro',
          translate: 'la imagen de la lente foto foto registro del obturador de vídeo'
        },
        {
          class: 'key',
          code: 'fas fa-key',
          translate: 'bloquear contraseña privado secreto desbloquear'
        },
        {
          class: 'cogs',
          code: 'fas fa-cogs',
          translate: 'engranajes ajustes mecánicos rueda dentada'
        },
        {
          class: 'thumbtack',
          code: 'fas fa-thumbtack',
          translate: 'las coordenadas de ubicación marcador pasador pulgar tachuela'
        },
        {
          class: 'trophy',
          code: 'fas fa-trophy',
          translate: 'ganador del juego de la copa del premio al logro'
        },
        {
          class: 'lemon',
          code: 'fas fa-lemon',
          translate: 'tarta de lima limonada cítrica'
        },
        {
          class: 'phone',
          code: 'fas fa-phone',
          translate: 'llamada auricular número apoyo teléfono voz'
        },
        {
          class: 'phone-square',
          code: 'fas fa-phone-square',
          translate: 'llamada auricular número apoyo teléfono voz'
        },
        {
          class: 'unlock',
          code: 'fas fa-unlock',
          translate: 'contraseña de bloqueo de administrador protección privada'
        },
        {
          class: 'credit-card',
          code: 'fas fa-credit-card',
          translate: 'comprar caja -de-crédito-alt débito dinero pago compra'
        },
        {
          class: 'certificate',
          code: 'fas fa-certificate',
          translate: 'insignia estrella verificada'
        },
        {
          class: 'globe',
          code: 'fas fa-globe',
          translate: 'todas las coordenadas país tierra global gps idioma localizar ubicación mapa en línea lugar planeta traducir viaje mundo'
        },
        {
          class: 'wrench',
          code: 'fas fa-wrench',
          translate: 'la construcción del arreglo mecánico de plomería ajustes llave inglesa herramienta de actualización'
        },
        {
          class: 'filter',
          code: 'fas fa-filter',
          translate: 'opciones de embudo ordenar por separado'
        },
        {
          class: 'briefcase',
          code: 'fas fa-briefcase',
          translate: 'bolsa negocio equipaje oficina trabajo'
        },
        {
          class: 'users',
          code: 'fas fa-users',
          translate: 'amigos grupo personas personas perfiles equipo'
        },
        {
          class: 'link',
          code: 'fas fa-link',
          translate: 'adjuntar cadena adjunta conectar'
        },
        {
          class: 'cloud',
          code: 'fas fa-cloud',
          translate: 'atmósfera niebla nublado guardar subir clima'
        },
        {
          class: 'flask',
          code: 'fas fa-flask',
          translate: 'vaso de precipitados ciencia laboratorios experimentales'
        },
        {
          class: 'cut',
          code: 'fas fa-cut',
          translate: '	clip tijeras recorte'
        },
        {
          class: 'copy',
          code: 'fas fa-copy',
          translate: 'clonar archivos de archivos duplicados -o pasta de papel'
        },
        {
          class: 'paperclip',
          code: 'fas fa-paperclip',
          translate: 'adjuntar adjunto conectar enlace'
        },
        {
          class: 'magic',
          code: 'fas fa-magic',
          translate: 'autocompletar automático mago magia deletrear varita bruja mago'
        },
        {
          class: 'truck',
          code: 'fas fa-truck',
          translate: 'vehículo de envío de entrega de carga'
        },
        {
          class: 'money-bill',
          code: 'fas fa-money-bill',
          translate: 'comprar efectivo checkout dinero pago precio compra'
        },
        {
          class: 'envelope',
          code: 'fas fa-envelope',
          translate: 'correo electrónico correo electrónico carta correo mensaje notificación apoyo'
        },
        {
          class: 'gavel',
          code: 'fas fa-gavel',
          translate: 'martillo juez ley abogado opinión'
        },
        {
          class: 'bolt',
          code: 'fas fa-bolt',
          translate: 'electricidad relámpago clima zap'
        },
        {
          class:'umbrella',
          code: 'fas fa-umbrella',
          translate: 'protección lluvia tormenta mojado'
        },
        {
          class: 'lightbulb',
          code: 'fas fa-lightbulb',
          translate: 'energía idea inspiración luz'
        },
        {
          class: 'suitcase',
          code: 'fas fa-suitcase',
          translate: 'equipaje equipaje movimiento maleta de viaje viaje'
        },
        {
          class: 'bell',
          code: 'fas fa-bell',
          translate: 'alarma alerta carillón notificación recordatorio'
        },
        {
          class: 'coffee',
          code: 'fas fa-coffee',
          translate: 'bebidas desayuno cafe copa caída por la mañana taza de temporada té'
        },
        {
          class: 'beer',
          code: 'fas fa-beer',
          translate: 'alcohol bar bebida beber cervecería cerveza inglesa licor jarra jarra'
        },
        {
          class: 'laptop',
          code: 'fas fa-laptop',
          translate: 'computadora UPC dell demostración dispositivo mac macbook máquina ordenador personal'
        },
        {
          class: 'circle',
          code: 'fas fa-circle',
          translate: 'círculo-delgado diámetro punto elipse notificación ronda'
        },
        {
          class: 'gamepad',
          code: 'fas fa-gamepad',
          translate: 'video del joystick del controlador d-pad'
        },
        {
          class: 'flag-checkered',
          code: 'fas fa-flag-checkered',
          translate: 'aviso notificación notificar poste carreras informe símbolo'
        },
        {
          class: 'microphone',
          code: 'fas fa-microphone',
          translate: 'audio podcast grabar cantar sonido voz'
        },
        {
          class: 'calendar',
          code: 'fas fa-calendar',
          translate: 'calendario-o fecha evento programar hora cuando'
        },
        {
          class: 'fire-extinguisher',
          code: 'fas fa-fire-extinguisher',
          translate: 'quemar caliente bombero llama calor caliente rescate'
        },
        {
          class: 'rocket',
          code: 'fas fa-rocket',
          translate: 'aeronave aplicación jet espacio lanzamiento nasa'
        },
        {
          class: 'anchor',
          code: 'fas fa-anchor',
          translate: 'atracar barco muelle incrustar enlace marítimo páramo seguro'
        },
        {
          class: 'female',
          code: 'fas fa-female',
          translate: 'humano persona perfil usuario mujer'
        },
        {
          class: 'male',
          code: 'fas fa-male',
          translate: 'humano hombre persona perfil usuario'
        },
        {
          class: 'gratipay',
          code: 'fab fa-gratipay',
          translate: 'corazón favorito como el amor'
        },
        {
          class: 'sun',
          code: 'fas fa-sun',
          translate: 'alegrar contraste día encendedor sol solar estrella clima'
        },
        {
          class: 'moon',
          code: 'fas fa-moon',
          translate: 'contraste media luna oscura noche lunar'
        },
        {
          class: 'building',
          code: 'fas fa-building',
          translate: 'apartamento negocio ciudad empresa oficina trabajo'
        },
        {
          class: 'child',
          code: 'fas fa-child',
          translate: 'niño niña niño pequeño joven'
        },
        {
          class: 'paw',
          code: 'fas fa-paw',
          translate: 'animal gato perro mascota impresión'
        },
        {
          class: 'cube',
          code: 'fas fa-cube',
          translate: '3d bloque dados paquete cuadrado tesseract'
        },
        {
          class: 'car',
          code: 'fas fa-car',
          translate: 'auto automóvil sedán transporte viajes vehículo carro'
        },
        {
          class: 'taxi',
          code: 'fas fa-taxi',
          translate: 'taxi taxista coche servicio de coche lyft máquina transporte viaje uber vehículo'
        },
        {
          class: 'tree',
          code: 'fas fa-tree',
          translate: 'corteza otoño flora bosque naturaleza planta estacional'
        },
        {
          class: 'bicycle',
          code: 'fas fa-bicycle',
          translate: 'bicicleta engranajes pedal transporte vehículo'
        },
        {
          class: 'bus',
          code: 'fas fa-bus',
          translate: 'transporte público transporte de viaje vehículo'
        },
        {
          class: 'angellist',
          code: 'fab fa-angellist',
          translate: 'paz manos'
        },
        {
          class: 'ship',
          code: 'fas fa-ship',
          translate: 'barco de agua de mar'
        },
        {
          class: 'user-secret',
          code: 'fas fa-user-secret',
          translate: '	ropa capa sombrero incognito persona intimidad espiar susurro'
        },
        {
          class: 'motorcycle',
          code: 'fas fa-motorcycle',
          translate: 'bicicleta máquina transporte vehículo moto'
        },
        {
          class: 'street-view',
          code: 'fas fa-street-view',
          translate: 'direcciones ubicación mapa navegación'
        },
        {
          class: 'tv',
          code: 'fas fa-tv',
          translate: 'computadora pantalla monitor televisión'
        },
        {
          class: 'hashtag',
          code: 'fas fa-hashtag',
          translate: 'Twitter instagram libra etiqueta de redes sociales'
        },
        {
          class: 'envelope-open',
          code: 'fas fa-envelope-open',
          translate: 'correo electrónico correo electrónico carta correo mensaje notificación apoyo'
        },
        {
          class: 'snowflake',
          code: 'fas fa-snowflake',
          translate: 'precipitación lluvia invierno'
        },
        {
          class: 'poo',
          code: 'fas fa-poo',
          translate: 'mierda caca mierda sonrisa turd'
        },
        {
          class: 'pen',
          code: 'fas fa-pen',
          translate: 'diseño editar actualizar escribir lapiz'
        },
        {
          class: 'clipboard',
          code: 'fas fa-clipboard',
          translate: 'copiar notas pegar registro'
        },
        {
          class: 'accessible-icon',
          code: 'fab fa-accessible-icon',
          translate: 'accesibilidad minusvalía persona silla de ruedas silla de ruedas-alt'
        },
        {
          class: 'mobile-alt',
          code: 'fas fa-mobile-alt',
          translate: 'manzana llamada teléfono celular teléfono celular dispositivo iphone número pantalla teléfono'
        },
        {
          class: 'rocketchat',
          code: 'fab fa-rocketchat',
          translate: 'nave cohete'
        },
        {
          class: 'slack-hash',
          code: 'fab fa-slack-hash',
          translate: 'hashtag de hash de anclaje'
        },
        {
          class: 'sticker-mule',
          code: 'fab fa-sticker-mule',
          translate: 'mula caballo'
        },
        {
          class: 'tablet-alt',
          code: 'fas fa-tablet-alt',
          translate: 'apple dispositivo ipad kindle pantalla'
        },
        {
          class: 'tachometer-alt',
          code: 'fas fa-tachometer-alt',
          translate: 'tablero rápido odómetro velocidad velocímetro'
        },
        {
          class: 'user-alt',
          code: 'fas fa-user-alt',
          translate: 'cuenta avatar cabeza humano hombre persona perfil'
        },
        {
          class: 'font-awesome-flag',
          code: 'fab fa-font-awesome-flag',
          translate: 'bandera'
        },
        {
          class: 'baseball-ball',
          code: 'fas fa-baseball-ball',
          translate: 'foul hardball liga cuero mlb sofbol deporte'
        },
        {
          class: 'bowling-ball',
          code: 'fas fa-bowling-ball',
          translate: 'callejón candelabro alcantarilla carril strike tenpin'
        },
        {
          class: 'dolly',
          code: 'fas fa-dolly',
          translate: 'llevar envío transporte'
        },
        {
          class: 'shipping-fast',
          code: 'fas fa-shipping-fast',
          translate: 'expresar Federal Express electrónico durante la noche de paquetes UPS'
        },
        {
          class: 'smoking',
          code: 'fas fa-smoking',
          translate: 'cáncer cigarrillo nicotina tabaquismo tabaco'
        },
        {
          class: 'warehouse',
          code: 'fas fa-warehouse',
          translate: 'almacenamiento de inventario de garaje de capacidad de construcción'
        },
        {
          class: 'couch',
          code: 'fas fa-couch',
          translate: 'silla cojín muebles relajarse sofá'
        },
        {
          class: 'piggy-bank',
          code: 'fas fa-piggy-bank',
          translate: 'banco guardar ahorros'
        },
        {
          class: 'readme',
          code: 'fab fa-readme',
          translate: "leeme libro"
        },
        {
          class: 'truck-moving',
          code: 'fas fa-truck-moving',
          translate: 'vehículo de alquiler de inventario de carga'
        },
        {
          class: 'teamspeak',
          code: 'fab fa-teamspeak',
          translate: 'cintillo escucha team'
        },
        {
          class: 'user-astronaut',
          code: 'fas fa-user-astronaut',
          translate: 'avatar ropa cosmonauta nasa traje espacial'
        },
        {
          class: 'user-friends',
          code: 'fas fa-user-friends',
          translate: 'grupo personas persona equipo usuarios'
        },
        {
          class: 'user-graduate',
          code: 'fas fa-user-graduate',
          translate: 'estudiante gorra graduación vestido graduación persona ropa'
        },
        {
          class: 'user-ninja',
          code: 'fas fa-user-ninja',
          translate: 'asesino avatar peligroso mortal astuto'
        },
        {
          class: 'blender',
          code: 'fas fa-blender',
          translate: 'cóctel batido mezclador puré batido'
        },
        {
          class: 'broom',
          code: 'fas fa-broom',
          translate: 'limpiar firebolt mosca halloween nimbus 2000 quidditch barrido bruja'
        },
        {
          class: 'church',
          code: 'fas fa-church',
          translate: 'edificio catedral capilla comunidad religión'
        },
        {
          class: 'crown',
          code: 'fas fa-crown',
          translate: 'premio favorito rey reina tiara real'
        },
        {
          class: 'dice',
          code: 'fas fa-dice',
          translate: 'oportunidad de juego juego de rollo'
        },
        {
          class: 'feather',
          code: 'fas fa-feather',
          translate: 'ave luz pluma arrancada escribir'
        },
        {
          class: 'frog',
          code: 'fas fa-frog',
          translate: 'anfibios rana toro fauna salto kermit beso prince ribbit sapo verruga'
        },
        {
          class: 'glasses',
          code: 'fas fa-glasses',
          translate: 'hipster nerd lectura vista gafas visión'
        },
        {
          class: 'helicopter',
          code: 'fas fa-helicopter',
          translate: 'airwolf apache chopper vuelo volar viajes'
        },
        {
          class: 'skull',
          code: 'fas fa-skull',
          translate: 'huesos esqueleto rayos x yorick'
        },
        {
          class: 'tshirt',
          code: 'fas fa-tshirt',
          translate: 'ropa moda prenda camisa'
        },
        {
          class: 'walking',
          code: 'fas fa-walking',
          translate: 'ejercicio salud podómetro persona pasos'
        },
        {
          class: 'award',
          code: 'fas fa-award',
          translate: 'honor alabanza premio reconocimiento cinta trofeo'
        },
        {
          class: 'bong',
          code: 'fas fa-bong',
          translate: 'aparato cannabis marihuana pipa humo fumar'
        },
        {
          class: 'brush',
          code: 'fas fa-brush',
          translate: 'arte cerdas color mango pintura'
        },
        {
          class: 'bus-alt',
          code: 'fas fa-bus-alt',
          translate: 'mta transporte público transporte de viaje vehículo'
        },
        {
          class: 'cookie-bite',
          code: 'fas fa-cookie-bite',
          translate: 'horneado bueno mordido patatas fritas chocolate comer merienda dulce tratar'
        },
        {
          class: 'fish',
          code: 'fas fa-fish',
          translate: 'fauna oro marisco nadando'
        },
        {
          class: 'glass-martini-alt',
          code: 'fas fa-glass-martini-alt',
          translate: 'alcohol bar bebidas beber licor'
        },
        {
          class: 'globe-americas',
          code: 'fas fa-globe-americas',
          translate: '	todo país tierra global gps idioma localizar ubicación mapa en línea lugar planeta traducir viaje mundo'
        },
        {
          class: 'swimmer',
          code: 'fas fa-swimmer',
          translate: 'agua atleta cabeza hombre juegos olímpicos persona piscina'
        },
        {
          class: 'umbrella-beach',
          code: 'fas fa-umbrella-beach',
          translate: 'protección recreación arena sombra verano sol'
        },
        {
          class: 'car-side',
          code: 'fas fa-car-side',
          translate: 'auto automóvil sedán transporte viajes vehículo'
        },
        {
          class: 'truck-monster',
          code: 'fas fa-truck-monster',
          translate: 'rueda de vehículo todoterreno'
        },
        {
          class: 'tractor',
          code: 'fas fa-tractor',
          translate: 'vehículo agrícola agrícola'
        },
        {
          class: 'cheese',
          code: 'fas fa-cheese',
          translate: 'cuajada de queso cheddar queso gouda derretido sándwich de parmesano cuña suiza'
        },
        {
          class: 'hard-hat',
          code: 'fas fa-hard-hat',
          translate: 'construcción de casco de seguridad casco de seguridad'
        },
        {
          class: 'biking',
          code: 'fas fa-biking',
          translate: 'bicicleta bicicleta de ciclo de la bicicleta paseo en la rueda'
        },
        {
          class: 'fan',
          code: 'fas fa-fan',
          translate: 'ac aire acondicionado aspa soplador frío caliente'
        }
      ]
    }
  },
  methods: {
    renderIcon: function(icon, iconKey, iconId) {
      let iconDom = document.getElementById(iconId)
      iconDom.innerHTML = `<i class="${icon}"></i>`
      iconKey++
    }
  }
}
