<template>
  <div v-if="hasCMFInformation">
    <h1>{{t('activerecord.attributes.simulation.evaluation.cmf_information')}}</h1>
    <div v-if="hasClientData">
      <h3>{{t('activerecord.attributes.simulation.evaluation.client')}}</h3>
        <conditional-cmf-table
          :has-information="hasAnyClientDebt"
          :direct-debt="clientDirectDebt"
          :indirect-debt="clientIndirectDebt"
          :credit-lines="clientCreditLines"
          :other-credits="clientOtherCredits"
          :no-info-message="t('activerecord.attributes.simulation.evaluation.no_client_cmf_debts')"
        />
    </div>
    <div v-if="hasGuarantor">
      <hr>
      <h3>{{t('activerecord.attributes.simulation.evaluation.guarantor')}}</h3>
        <conditional-cmf-table
          :has-information="hasAnyGuarantorDebt"
          :direct-debt="guarantorDirectDebt"
          :indirect-debt="guarantorIndirectDebt"
          :credit-lines="guarantorCreditLines"
          :other-credits="guarantorOtherCredits"
          :no-info-message="t('activerecord.attributes.simulation.evaluation.no_guarantor_cmf_debts')"
        />
    </div>
  </div>
  <div v-else>
    <strong>{{t('activerecord.attributes.simulation.evaluation.no_kayak_cmf_information')}}</strong>
  </div>
</template>
<script>
import ConditionalCmfTable from "./ConditionalCmfTable"
import i18n from "../mixins/i18n"

export default {
  name: "kayak-information-cmf",
  mixins: [i18n],
  components: {
    ConditionalCmfTable
  },
  props: [
    'debtsInformation'
  ],
  data() {
    let debtsInformation = JSON.parse(this.debtsInformation);
    let clientDebtsInformation = debtsInformation.client;
    let guarantorDebtsInformation = debtsInformation.guarantor;
    let clientDirectDebt = clientDebtsInformation?.direct_debt;
    let clientIndirectDebt = clientDebtsInformation?.indirect_debt;
    let clientCreditLines = clientDebtsInformation?.credit_lines;
    let clientOtherCredits = clientDebtsInformation?.other_credits;
    let hasAnyClientDebt = !!(clientDirectDebt?.length) || !!(clientIndirectDebt?.length) || !!(clientCreditLines?.length) || !!(clientOtherCredits?.length);
    let guarantorDirectDebt = guarantorDebtsInformation?.direct_debt;
    let guarantorIndirectDebt = guarantorDebtsInformation?.indirect_debt;
    let guarantorCreditLines = guarantorDebtsInformation?.credit_lines;
    let guarantorOtherCredits = guarantorDebtsInformation?.other_credits;
    let hasAnyGuarantorDebt = !!(guarantorDirectDebt?.length) || !!(guarantorIndirectDebt?.length) || !!(guarantorCreditLines?.length) || !!(guarantorOtherCredits?.length);
      return {
        hasCMFInformation: !!clientDebtsInformation || !!guarantorDebtsInformation,
        hasClientData: !!clientDebtsInformation,
        hasAnyClientDebt,
        clientDirectDebt,
        clientIndirectDebt,
        clientCreditLines,
        clientOtherCredits,
        hasGuarantor: !!guarantorDebtsInformation,
        hasAnyGuarantorDebt,
        guarantorDirectDebt,
        guarantorIndirectDebt,
        guarantorCreditLines,
        guarantorOtherCredits
    }
  }
}
</script>
