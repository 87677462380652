<template>
  <div class="col-span-8">
    <b-alert variant="danger" show >
      <p class="col-span-8 mb-2 mt-2 text-sm">No podrás crear un crédito hasta solucionar estas incidencias</p>
    </b-alert>
    <ul>
      <li v-for="(warning) in warnings">
        <span class="text-base font-bold text-black">{{ warning }}</span>
      </li>
      <li v-for="(minimumRequirement) in minimumRequirements">
        <span class="text-base font-bold text-black">{{ minimumRequirement }}</span>
      </li>
    </ul>
  </div>
</template>
<script>
  export default {
    name: 'warnings-table',
    props: ['warnings','minimumRequirements']
  }
</script>
