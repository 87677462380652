import axios from 'axios'
import { config } from '../constants/api'

const buildConfig = (extraconfig) => {
  return { ...config, ...extraconfig }
}

export default {
  // async  signDoc(documentId, signMethod, creditId) {
  //   const path = `/credits/${creditId}/sign_doc.json`;
  //   const data = {
  //     doc_id: documentId,
  //     sign_method: signMethod
  //   };

  //   return await axios.post(path, data, config).then(res => res).catch((error) => (error.response))
  // },
  async  signPresentialDoc(documentId, holderId, creditId) {
    const path = `/credits/${creditId}/sign_presential_doc.json`;
    const data = {
      doc_id: documentId,
      holder_id: holderId
    };

    return await axios.post(path, data, config).then(res => res).catch((error) => (error.response))
  },

  async  default_sign_methods() {
    const path = `/document_templates/default_sign_methods.json`;

    return await axios.get(path, config)
      .then((res) => res.data)
      .catch((error) => error.message)
  },

  async create_sign_process(creditId) {
    const path = `/credits/${creditId}/create_sign_process.json`;

    return await axios.post(path, {}, config).then(res => res).catch((error) => (error.response))
  },

  async updateDocsStatus(creditId) {
    const path = `/credits/${creditId}/update_docs_status.json`;

    return await axios.post(path, {}, config).then(res => res).catch((error) => (error.response))
  }
}
