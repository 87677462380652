<template>
  <div class="body-container">
    <loader ref="loader"/>

    <modal-edit-state
      ref='editForm'
      :item="item"
      :sub-statuses-list="subStateList"
      v-on:confirmEventModal="confirmEditSubState"/>

    <alert
      ref="notification"
      :text="textNotification"
      v-on:onUndoEvent="onUndoEventAlert"
      v-on:onEvent="onEventAlert"
      :progress-bar="alertProgressBar"
      :is-undo-button="alertUndoButton"/>

  </div>
</template>
<script>
import i18n from "../mixins/i18n"
import errands from "../../api/errands";
import Loader from '../template/Loader.vue';
import ModalEditState from './ModalEditState.vue';
import Alert from '../template/Alert.vue';

export default {
  name: "errand-edit",
  mixins: [i18n],
  components: {
    Loader,
    ModalEditState,
    Alert
  },
  props: {
    errand: Object
  },
  data: function() {
    return {
      item: this.errand,
      isEditableSubStatus: false,
      textNotification: '',
      alertProgressBar: true,
      alertUndoButton: true,
      undoEventAlert: () => { console.log('not implemented yet') },
      eventAlert: () => { console.log('not implemented yet') },
      baseStateList: [],
      subStateList: [],
      errandToDelete: null,
    }
  },
  watch: {
  },
  methods: {
    getSubStatuses: async function(errandTypeId) {
      this.subStateList = await errands.getSubStatusList(errandTypeId);
      this.subStateList = this.formatOptionsLabel(this.subStateList)
    },

    confirmEditSubState: async function(modalObject) {
      this.$refs.loader.isVisible()

      this.textNotification = `Has cambiado el sub estado de ${modalObject.items.length} trámites.`
      this.$refs.notification.show()

      this.waitingItemEdit = modalObject
      this.eventAlert = async () => {
        const response = await errands.updateSubStatus(this.waitingItemEdit.items, this.waitingItemEdit.status.id, this.waitingItemEdit.comment)
        if(this.waitingItemEdit?.costsDataChanged || (this.waitingItemEdit.comment && !response)) {
          await errands.updateErrand(this.waitingItemEdit.itemsObj[0].id, this.waitingItemEdit.costsData, this.waitingItemEdit.comment)
        }
        await this.$refs.editForm.updateHistory()
      }

      this.undoEventAlert = () => {
        this.$refs.notification.isUndo()
      }

      this.$refs.loader.notVisible()
    },
    onEventAlert() {
      this.eventAlert()
    },
    onUndoEventAlert() {
      this.undoEventAlert()
    },
    formatOptionsLabel(options) {
      return options.map(option => {
        return {
          label: option.name_base_status,
          options: this.formatOptions(option.sub_status_list)
        }
      })
    },
    formatOptions(options) {
      return options.map(option => {
        return {value: option, text: option.name}
      })
    }
  },
  mounted: async function() {
    this.$refs.loader.isVisible()
    await this.getSubStatuses(this.item.errand_type.id)
    this.$refs.loader.notVisible()
  },
}
</script>
