import axios from 'axios'
import { config } from '../constants/api'

const buildConfig = (extraconfig) => {
  return { ...config, ...extraconfig }
}

export default {
  async getList() {
    const path = `/general_statuses/list.json`;

    return await axios.get(path, config)
      .then((res) => res.data)
      .catch((error) => error.message)
  }
}
