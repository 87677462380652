<template>
  <b-form key="field">
    <b-row class="col" >

      <b-col md="2" sm="12" :class="`${ isReadonly ? 'd-flex align-items-center' : ''}`">
        <b-form-group v-if="!isReadonly" :label="t('activerecord.attributes.sub_status.order')" invalid-feedback="Orden es requerido">
          <div class="d-flex">
            <b-form-input type="number" v-model="order" :placeholder="t('activerecord.attributes.sub_status.order')" :disabled="true" required></b-form-input>
          </div>
          <span class="text-danger" v-if="errors.order" >{{ errors.order }}</span>
        </b-form-group>
        <div class="cursor-pointer" v-else>
          <i class="fas fa-grip-vertical text-muted fa-sm"></i>
          {{ thisOrder }}
        </div>
      </b-col>

      <b-col md="3" sm="12" :class="`${ isReadonly ? 'd-flex align-items-center' : ''}`">
        <b-form-group v-if="!isReadonly" :label="t('activerecord.attributes.sub_status.general_status')" invalid-feedback="Estado base es requerido">
          <div class="d-flex">
            <b-form-select v-model="generalStatus" :options="statusOptions" :disabled="isDisabled" required></b-form-select>
          </div>
          <span class="text-danger" v-if="errors.generalStatus" >{{ errors.generalStatus }}</span>
        </b-form-group>
        <div v-else>
          {{ generalStatus.name }}
        </div>
      </b-col>

      <b-col md="3" sm="12" :class="`${ isReadonly ? 'd-flex align-items-center' : ''}`">
        <b-form-group v-if="!isReadonly" :label="t('activerecord.attributes.sub_status.name')" invalid-feedback="Nombre es requerido">
          <b-form-input v-model="name" :placeholder="t('activerecord.attributes.sub_status.name')" :disabled="isDisabled" required></b-form-input>
          <span class="text-danger" v-if="errors.name" >{{ errors.name }}</span>
        </b-form-group>
        <div v-else>
          {{ name }}
        </div>
      </b-col>

      <b-col md="2" sm="12" :class="`${ isReadonly ? 'd-flex align-items-center' : ''}`">
        <b-form-group v-if="!isReadonly" :label="t('activerecord.attributes.sub_status.limit_days')" invalid-feedback="Nombre es requerido">
          <b-form-input type="number" v-model="limitDays" :placeholder="t('activerecord.attributes.sub_status.limit_days')" :disabled="isDisabled"></b-form-input>
          <span class="text-danger" v-if="errors.limitDays" >{{ errors.limitDays }}</span>
        </b-form-group>
        <div v-else>
          {{ limitDays }}
        </div>
      </b-col>


      <b-col md="2" sm="12" :class="`${ isReadonly ? 'd-flex align-items-center' : 'd-flex justify-center flex-column'}`">
        <div class="w-100 " v-if="isNew && !readonly">
          <b-button variant="primary" class="rounded" block type="submit" @click="onSubmit"> {{t('views.create')}} </b-button>
        </div>
        <div v-else>
          <b-form-group v-if="isEditing">
            <b-button variant="primary" block type="button" @click="onSubmitEdit"> {{t('views.change')}} </b-button>
          </b-form-group>

          <div v-if="isReadonly">
            <b-button variant="outline-primary" type="button" @click="toggleEditing" > <i class="fas fa-pen"></i> </b-button>
            <b-button variant="outline-danger" type="button" @click="onDelete"> <i class="fas fa-trash"></i> </b-button>
          </div>
        </div>
      </b-col>

    </b-row>
  </b-form>

</template>

<script>
  import i18n from "../mixins/i18n"
  import _ from 'lodash';
  const NAME_REQUIRED_FIELDS = ['name', 'generalStatus']

  export default {
    name: 'sub-status-form',
    components: {
    },
    mixins: [i18n],
    props: [
      'initField',
      'readonly',
      'statuses',
      'initialOrder'
    ],
    data() {
      return {
        isNew: _.isNil(this.initField?.id),
        id: this.initField?.id || this.initField?._uid,
        name: this.initField?.name || '',
        order: this.initField?.order || this.initialOrder || 0,
        limitDays: this.initField?.limit_days || null,
        generalStatus: { ...this.initField?.general_status } || null,
        statusOptions: this.statuses || [],
        isEditing: false,
        isReadonly: this.readonly || false,
        showAddItem: false,
        disabledAddItem: false,
        errors: {
          name: '',
          limitDays: null,
          generalStatus: '',
        },
      }
    },
    mounted() {
    },
    computed: {
      isDisabled() {
        return !this.isNew && !this.isEditing  ? true : false
      },
      thisOrder() {
        this.order = this.initField?.order || this.initialOrder || 0
        return this.order
      }
    },
    methods: {
      onSubmit(event) {
        event.preventDefault()
        if(!this.valid()) return;

        this.$emit('addSubStatus', {
          name: this.name,
          limit_days: this.limitDays,
          general_status: this.generalStatus,
          order: this.order
        });

        this.id = null
        this.name = ''
        this.limitDays = null
        this.generalStatus = null
      },
      onSubmitEdit(){
        if(!this.valid()) return;
        this.$emit('updateSubStatus', {
          id: this.id,
          name: this.name,
          limit_days: this.limitDays,
          general_status: this.generalStatus,
          order: this.order
        });

        this.toggleEditing()
      },
      toggleEditing(event) {
        this.isEditing = !this.isEditing
        this.isReadonly = !this.isReadonly
      },
      onDelete() {
        this.$emit('deleteSubStatus', this.id)
      },
      valid() {
        let valid = true
        for (let index = 0; index < NAME_REQUIRED_FIELDS.length; index++) {
          const element = NAME_REQUIRED_FIELDS[index];
          if(!this[element]){
            this.errors[element] = 'Es requerido'
            valid = false
          } else {
            this.errors[element] = ''
          }
        }

        return valid
      }
    }

  }
</script>
