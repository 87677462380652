<template>
  <div>
    <b-form @submit.prevent="saveAddress">
      <b-row>
        <b-col cols="12">
          <b-alert variant="success" show dismissible v-if="this.showSuccessMessage">{{ t('address.success_message') }}</b-alert>

          <b-input-group id="input-group-1" label-for="input-1">
            <template #prepend style="width: 35%">
              <b-input-group-text>{{ t('address.country') }} <span class="star-red">*</span> </b-input-group-text>
            </template>
            <b-form-input id="input-1" v-model="address.country_name" placeholder="Chile" required></b-form-input>
          </b-input-group>
        </b-col>
        <b-col cols="12">
          <b-input-group id="input-group-1" label-for="input-1">
            <template #prepend>
              <b-input-group-text >{{ t('address.region_name') }} <span class="star-red">*</span> </b-input-group-text>
            </template>
            <search-autocomplete
              :required="true"
              :fn-to-call="regionFunction"
              :value="address.region_name"
              @setSelected="setSelectedRegion"
            />
          </b-input-group>
        </b-col>
        <b-col cols="12">
          <b-input-group id="input-group-1" label-for="input-1">
            <template #prepend>
              <b-input-group-text >{{ t('address.city_name') }} <span class="star-red">*</span> </b-input-group-text>
            </template>
            <search-autocomplete
              :required="true"
              :fn-to-call="cityFunction"
              :value="address.city_name"
              @setSelected="setSelectedCity"
            />
          </b-input-group>
        </b-col>
        <b-col cols="12">
          <b-input-group id="input-group-1" label-for="input-1">
            <template #prepend>
              <b-input-group-text >{{ t('address.commune_name') }} <span class="star-red">*</span> </b-input-group-text>
            </template>
            <search-autocomplete
              :required="true"
              :fn-to-call="communeFunction"
              :value="address.commune_name"
              @setSelected="setSelectedCommune"
            />
          </b-input-group>
        </b-col>
        <b-col cols="12">
          <b-input-group id="input-group-1" label-for="input-1">
            <template #prepend>
              <b-input-group-text >{{ t('address.street_name') }} <span class="star-red">*</span> </b-input-group-text>
            </template>
            <b-form-input id="input-1" v-model="address.street_name" required placeholder=""></b-form-input>
          </b-input-group>
        </b-col>
        <b-col cols="12">
          <b-input-group id="input-group-1" label-for="input-1">
            <template #prepend>
              <b-input-group-text >{{ t('address.street_number') }} <span class="star-red">*</span> </b-input-group-text>
            </template>
            <b-form-input id="input-1" v-model="address.street_number" required placeholder=""></b-form-input>
          </b-input-group>
        </b-col>
        <b-col cols="12">
          <b-input-group id="input-group-1" label-for="input-1">
            <template #prepend>
              <b-input-group-text >{{ t('address.door_number') }}</b-input-group-text>
            </template>
            <b-form-input id="input-1" v-model="address.door_number" placeholder=""></b-form-input>
          </b-input-group>
        </b-col>
        <b-col cols="12">
          <b-input-group id="input-group-1" label-for="input-1">
            <template #prepend>
              <b-input-group-text >{{ t('address.other') }}</b-input-group-text>
            </template>
            <b-form-input id="input-1" v-model="address.other" placeholder="" disabled></b-form-input>
          </b-input-group>
        </b-col>
        <b-col cols="12">
          <b-input-group id="input-group-1" label-for="input-1">
            <template #prepend>
              <b-input-group-text >{{ t('address.verification_file') }}</b-input-group-text>
            </template>
            <b-link class="link-file text-center" v-if="verificationFile" :href="verificationFile" >{{ t('address.show_file') }}</b-link>
            <b-form-file
              v-model="address.verification_file"
              placeholder="Choose a file or drop it here..."
              drop-placeholder="Drop file here..."
            ></b-form-file>
          </b-input-group>
        </b-col>
        <b-col cols="12">
          <b-button type="submit" class="btn btn-success mt-2" >
            <b-spinner size="sm" v-if="isLoading" />
            <span v-else>
              {{ t('views.save') }}
            </span>
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>
<script>
  import i18n from "../mixins/i18n"
  import addressApi from '../../api/address'
  import clientsApi from '../../api/clients'
  import SearchAutocomplete from '../dynamics_form/fields/SearchAutocomplete.vue';

  export default {
    components: {SearchAutocomplete},
    mixins: [i18n],
    props: [
      'id',
      'country_name',
      'region_name',
      'city_name',
      'commune_name',
      'street_name',
      'street_number',
      'door_number',
      'other',
      'verificationFile',
      'slug'
    ],
    data() {
      return {
        currentForm: null,
        address: {
          id: this.id,
          country_name: this.country_name || '',
          region_name: this.region_name || '',
          city_name: this.city_name || '',
          commune_name: this.commune_name || '',
          street_name: this.street_name || '',
          street_number: this.street_number || '',
          door_number: this.door_number || '',
          other: this.other || '',
          verification_file: this.verification_file
        },
        regionFunction: addressApi.getRegions,
        cityFunction: addressApi.getCities,
        communeFunction: addressApi.getCommunes,
        showSuccessMessage: false,
        isLoading: false
      }
    },
    mounted() {
      this.communeFunction = async (valueFunction) => {
        return addressApi.getCommunes(null, valueFunction)
      }

      this.cityFunction = async function(value){
        return addressApi.getCities(null, value)
      }
    },
    methods: {
      saveAddress: async function(){
        this.isLoading = true
        const response = await clientsApi.updateAddressClient(this.address, this.slug)
        if(response.ok) this.showSuccessMessage = true

        this.isLoading = false
      },
      setSelectedRegion: async function(regionValue){
        this.address.region_name = regionValue.text

        this.cityFunction = async (valueFunction) => {
          return addressApi.getCities(regionValue.value, valueFunction)
        }
      },
      setSelectedCity: async function(cityValue){
        this.address.city_name = cityValue.text

        this.communeFunction = async (valueFunction) => {
          return addressApi.getCommunes(cityValue.value, valueFunction)
        }
      },
      setSelectedCommune: async function(value){
        this.address.commune_name = value.text
      }
    }
  }
</script>

<style scoped>
.input-group-prepend{
  width: 35%;
}
.input-group-text{
  width: 100%;
}
.link-file {
  width: 32%;
}
.star-red{
  color: red
}
</style>
