<template>
  <div>
    <div v-for="(file) in files" :key="file.id" :class="bsaleInvoiceStyle(file.status)">
      <div class="mrp-8 flex">
        <em :class="iconStyle(file.status)"></em>
        <div>
          <p>{{ file.name }}</p>
          <small class="error-100" >{{ errorMessage(file) }}</small>
        </div>
      </div>
      <span class="text-danger" v-if="file.byte_size == 0"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i>{{ t(`errand_invoice_template.invoice_warning`).toUpperCase() }}</span>
      <div class="mrp-4 flex" v-if="canVisualize(file.status)">
        <button v-if="file.status == 'success'" class="button button-negative button-sm" @click="confirmAnnul(file)">{{ t(`errand_invoice_template.annul_invoice.annul`).toUpperCase() }}</button>
        <a :href="file.file_url"
           target="_blank"
           class="button button-secondary button-sm"
           v-if="file.byte_size > 0"
        >{{ t(`views.visualize`).toUpperCase() }}</a>
        <a :href="file.data.bsale_invoice_url"
           target="_blank"
           class="button button-negative button-sm d-inline-block"
           v-if="file.byte_size == 0"
        >{{ t(`activerecord.attributes.client_file.download`).toUpperCase() }}</a>
      </div>
      <div class="flex text-primary-100" v-if="file.status == 'processing'">
        <span class="p-medium primary-100">{{ t(`errand_invoice_template.generating_invoice`).toUpperCase() }}</span>
        <b-spinner class="ml-4 primary-spinner"></b-spinner>
      </div>
    </div>

    <b-modal
      id="modal-confirm-annul-invoice"
    >
      <template #modal-title>
        <strong>{{ t(`errand_invoice_template.annul_invoice.confirmation_title`) }}</strong>
      </template>
      <p class="color-default mtp-20 text-center">{{ t(`errand_invoice_template.annul_invoice.confirmation_message`) }}</p>
      <template #modal-footer>
        <button class="button button-secondary" @click="$bvModal.hide('modal-confirm-annul-invoice')" >{{ t(`views.cancel`) }}</button>
        <button class="button button-primary" @click="annulInvoice(selectedInvoice)">{{ t(`errand_invoice_template.annul_invoice.confirm`) }}</button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import i18n from "../mixins/i18n";
import invoicesApi from "../../api/invoices";

export default {
  name: 'invoice-files',
  mixins: [i18n],
  props: {
    files: Array,
    default: []
  },
  data() {
    return {
      selectedInvoice: null
    }

  },
  methods: {
    bsaleInvoiceStyle(status) {
      const style = ['invoice', 'mb-3']
      if (status == 'failed' || status == 'annulled') {
        style.push('failed')
      }
      return style
    },
    iconStyle(status) {
      const style = ['mt-2', 'mr-2']
      switch (status) {
        case 'success':
          style.push('text-success', 'fas', 'fa-check-circle')
          break;
        case 'failed':
          style.push('text-danger', 'fas', 'fa-exclamation-circle')
          break;
        case 'annulled':
          style.push('text-danger', 'fas', 'fa-minus-circle')
          break;
      }
      return style;
    },
    async annulInvoice(file) {
      await invoicesApi.annul(file.invoice_id, file.id)
      file.status = 'annulled'
      file.data['annulled_at'] = new Date()
      this.$bvModal.hide('modal-confirm-annul-invoice')
    },
    errorMessage(file) {
      if (file.status == 'failed')
        return this.t(`errand_invoice_template.invoice_error`)

      if (file.status == 'annulled') {
        const completeDate = new Date(file.data['annulled_at'])
        const date = completeDate.toLocaleDateString()
        const time = completeDate.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'});
        return this.t(`errand_invoice_template.annul_invoice.annulled`, { date: date, time: time })
      }

      return ''
    },
    canVisualize(status) {
      return status == 'success' || status == 'annulled';
    },
    confirmAnnul(file) {
      this.selectedInvoice = file
      this.$bvModal.show('modal-confirm-annul-invoice')
    }
  }
}
</script>

<style>
  .invoice {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
    gap: 100px;
    background: #FFFFFF;
    border: 1px solid #AB8CF1;
    border-radius: 12px;
  }
  .failed {
    border: 1px solid #FF3433;
  }
</style>
