<template>
  <div class="d-flex justify-content-center mb-3 loader-container" v-bind:style="{ display: isOn ? 'flex !important' : 'none !important' }">
    <b-spinner class="color-main-blue" style="width: 3rem; height: 3rem;" label="Large Spinner"></b-spinner>
  </div>
</template>
<script>
export default {
  name: "loader",
  data() {
    return {
      isOn: false,
    }
  },
  methods: {
    isVisible() {
      this.isOn = true
    },
    notVisible() {
      this.isOn = false
    },
    getStatus() {
      return this.isOn
    }
  },
}
</script>
<style>
  .loader-container {
    position: fixed;
    z-index: 10000;
    width: 100%;
    height: 100%;
    background-color: #88868661;
    top: 0px;
    right: 0px;
    align-items: center;
  }
</style>
