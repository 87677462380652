import axios from 'axios'
import * as humps from "humps"

export default {
  async get(query) {
    const path = `/guarantors.json?query=${encodeURI((query === null ? '' : query))}`
    const headers = {
      'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
    }

    return await axios.get(path, { headers }).then(res => res.data)
  },
}
