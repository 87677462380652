<template>
  <section class="card shadow-light-20 mb-4">
    <div class="card-header flex align-items-center justify-content-between">
      <span class="subtitle neutral-70">
        {{ title }}
      </span>
    </div>
    <div class="card-body">
      <table class="table" aria-describedby="personal information" role="presentation">
        <tbody>
          <tr>
            <td class="neutral-50">{{ t('activerecord.attributes.simulation.evaluation.full_name') }}</td>
            <td>{{ personalInformation.full_name }}</td>
          </tr>
          <tr>
            <td class="neutral-50">{{ t('activerecord.attributes.simulation.evaluation.email') }}</td>
            <td>{{ personalInformation.email }}</td>
          </tr>
          <tr>
            <td class="neutral-50">{{ t('activerecord.attributes.simulation.evaluation.phone') }}</td>
            <td>{{ fullPhone() }}</td>
          </tr>
          <tr>
            <td class="neutral-50">{{ t('activerecord.attributes.simulation.evaluation.rut') }}</td>
            <td>{{ personalInformation.rut }}</td>
          </tr>
          <tr>
            <td class="neutral-50">{{ t('activerecord.attributes.simulation.evaluation.full_street') }}</td>
            <td>{{ fullStreet() }}</td>
          </tr>
          <tr>
            <td class="neutral-50">{{ t('activerecord.attributes.simulation.evaluation.education_level') }}</td>
            <td>{{ personalInformation.education_level }}</td>
          </tr>
          <tr>
            <td class="w-1/3">
              <span class="neutral-50 mr-3">{{ t('activerecord.attributes.simulation.evaluation.birthday') }}</span>
              <span>{{ personalInformation.birthday }}</span>
            </td>
            <td>
              <span class="neutral-50 mr-3">{{ t('activerecord.attributes.simulation.evaluation.age') }}</span>
              <span>{{ getAge() }}</span>
            </td>
            <td class="w-2/12">
              <span class="neutral-50 mr-3">{{ t('activerecord.attributes.simulation.evaluation.sex') }}</span>
              <span>{{ personalInformation.sex }}</span>
            </td>
          </tr>
          <tr>
            <td class="neutral-50">{{ t('activerecord.attributes.simulation.evaluation.birth_place') }}</td>
            <td>{{ fullBirthPlace() }}</td>
          </tr>
          <tr>
            <td class="neutral-50">{{ t('activerecord.attributes.simulation.evaluation.civil_state') }}</td>
            <td>{{ personalInformation.civil_state }}</td>
          </tr>
          <tr>
            <td class="neutral-50">{{ t('activerecord.attributes.simulation.evaluation.afp') }}</td>
            <td>{{ personalInformation.afp }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </section>
</template>
<script>
import i18n from "../mixins/i18n"

export default {
  name: "personal-information-table",
  mixins: [i18n],
  props: [
    'title',
    'personalInformation'
  ],
  methods: {
    conditionalString: function(string) {
      return !!string ? string : ''
    },
    fullPhone() {
      return `${this.conditionalString(this.personalInformation.country_code)} ${this.conditionalString(this.personalInformation.phone)}`
    },
    fullStreet() {
      const [street, streetNumber, doorNumber, communeName, regionName] = [
        'street',
        'street_number',
        'door_number',
        'commune_name',
        'region_name',
      ].map((key) => this.getInfoFromKey(key));

      return `${street} ${streetNumber} ${doorNumber}, ${communeName} ${regionName}`;
    },
    fullBirthPlace() {
      const [birthCountry, birthCommune, birthRegion] = [
        'birth_country',
        'birth_commune',
        'birth_region',
      ].map((key) => this.getInfoFromKey(key));

      return `${birthCountry} ${birthCommune} ${birthRegion}`;
    },
    getInfoFromKey(key) {
      return this.conditionalString(this.personalInformation[key]);
    },
    getAge() {
      let today = new Date();
      const birthday = this.personalInformation.birthday.split('-')
      let birthDate = new Date(birthday[2], birthday[1], birthday[0]);
      let age = today.getFullYear() - birthDate.getFullYear();
      if (this.isBirthdayBeforeCurrentDate(birthDate, today))
        age--;
      return age;
    },
    isBirthdayBeforeCurrentDate(birthDate, currentDate) {
      const monthsUntilBirthday = currentDate.getMonth() - birthDate.getMonth();
      const isBeforeBirthdayMonth = monthsUntilBirthday < 0;
      const isSameMonth = monthsUntilBirthday === 0;
      const isBeforeBirthday = currentDate.getDate() < birthDate.getDate();

      return isBeforeBirthdayMonth || (isSameMonth && isBeforeBirthday);
    }
  }
}
</script>
