import axios from 'axios'
import { config } from '../constants/api'

const buildConfig = (extraconfig) => {
  return { ...config, ...extraconfig }
}

export default {
  async getRegions(value) {
    const path = `/addresses/regions.json?search=${value}`;

    return axios.get(path, config)
      .then((res) => res.data)
      .catch((error) => error.message)
  },

  async getProvinces(addressRegionId, value) {
    const path = `/addresses/provinces.json?address_region_id=${addressRegionId ? addressRegionId : ''}&search=${value}`;

    return axios.get(path, config)
      .then((res) => res.data)
      .catch((error) => error.message)
  },

  async getCities(addressRegionId, value) {
    const path = `/addresses/cities.json?address_region_id=${addressRegionId ? addressRegionId : ''}&search=${value}`;

    return axios.get(path, config)
      .then((res) => res.data)
      .catch((error) => error.message)
  },

  async getCommunes(addressCityId, value) {
    const path = `/addresses/communes.json?address_city_id=${addressCityId ? addressCityId : ''}&search=${value}`;

    return axios.get(path, config)
      .then((res) => res.data)
      .catch((error) => error.message)
  },
}
