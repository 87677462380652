<template>
  <span style="display: contents;" >
    <dd class="text-lg text-gray-900 sm:mt-0 sm:m-auto sm:col-span col-span-1">
        <b-form-input
          class="w-40"
          id="type-amount"
          v-model="newAmount"
          type="number"
          placeholder="ej: 100000"
          required>
        </b-form-input>
    </dd>
    <dd class="text-lg text-gray-900 mx-2">
      <b-button variant="success" size="sm" @click="createOrUpdatePreorder()">
        <em class="fas fa-check"></em>
      </b-button>
    </dd>
    <dd class="text-lg text-gray-900">
      <b-button variant="secondary" size="sm" @click="close()">
        <em class="fas fa-times"></em>
      </b-button>
    </dd>
  </span>
</template>
<script>
  import preorderApi from '../../api/preorders';
  import i18n from "../mixins/i18n"

  export default {
    mixins: [i18n],
    name: 'preorder-form',
    props: [
      'preorder_id',
      'amount',
      'simulationId'
    ],
    data: function () {
      return {
        newAmount: this.amount,
        event: null
      }
    },
    methods: {
      toggleShowForm: function(){
        this.$emit('toggleShowForm')
      },
      toggleShowEditForm: function(){
        this.$emit('toggleShowEditForm')
      },
      createPreorder: async function(){
        let params = {
          amount: this.newAmount,
          simulation_id: this.simulationId
        }
        let response = await preorderApi.create(params)
        if (response.status === 200) {
          this.$emit('element', response.data)
          this.toggleShowForm()
        } else {
          let error_type = response.data[0].type
          let message = this.t(`activerecord.attributes.preorder.errors.${ error_type }`)
          alert(message)
        }
      },
      updatePreorder: async function(){
        let params = {
          amount: this.newAmount,
        }

        let response = await preorderApi.update(this.preorder_id, params)
        if (response.status === 200) {
          this.$emit('amount', response.data.amount, this.preorder_id)
          this.toggleShowEditForm()
        } else {
          let error_type = response.data[0].type
          let message = this.t(`activerecord.attributes.preorder.errors.${ error_type }`)
          alert(message)
        }
      },
      createOrUpdatePreorder: function(){
        if (this.preorder_id) {
          this.updatePreorder()
        } else {
          this.createPreorder()
        }
      },
      close: function() {
        if (this.preorder_id) {
          this.toggleShowEditForm()
        } else {
          this.toggleShowForm()
        }
      }
    }
  }
</script>
