<template>
  <b-col :cols="cols" :sm="sm" :md="md" :lg="lg" :xl="xl">
    <div :class="`d-flex justify-content-between pt-4 mb-4 ${headerClasses}`">
      <h5 class="${titleClasses} font-weight-bold m-0">
        {{title}}
      </h5>
      <div>
        <slot name="headerContent"></slot>
      </div>
    </div>

    <slot name="content"></slot>
  </b-col>

</template>

<script>
  export default {
    name: 'card-item',
    components: {},
    props: [
      'headerClasses',
      'title',
      'cols',
      'sm',
      'md',
      'lg',
      'xl'
    ],
    data() {
      return {}
    },
    mounted: async function() {
      console.log(this.$props)
    },
    methods: {}
  }
</script>
