import axios from 'axios';
import * as humps from 'humps';
import { config } from '../constants/api'

export default {
  async getRequirements() {
    const path = `/admission/requirements`

    return await axios.get(path, config)
      .then((res) => res.data)
      .catch((error) => [])
  },
  async getProductTypes() {
    const path = `/admission/requirements/product_types`

    return await axios.get(path, config)
      .then((res) => res.data)
      .catch((error) => [])
  },
  async getCreditTypes() {
    const path = `/admission/requirements/credit_types`

    return await axios.get(path, config)
      .then((res) => res.data)
      .catch((error) => [])
  },
  async getEmploymentStatuses() {
    const path = `/admission/requirements/employment_statuses`

    return await axios.get(path, config)
      .then((res) => res.data)
      .catch((error) => [])
  },
  async getJobSalaryCompositions() {
    const path = `/admission/requirements/job_salary_compositions`

    return await axios.get(path, config)
      .then((res) => res.data)
      .catch((error) => [])
  },
  async getClientSimulationRelations() {
    const path = `/admission/requirements/client_simulation_relations`

    return await axios.get(path, config)
      .then((res) => res.data)
      .catch((error) => [])
  },
  async getClientLoyalty() {
    const path = `/admission/requirements/client_loyalty_options`

    return await axios.get(path, config)
      .then((res) => res.data)
      .catch((error) => [])
  },
  async getJobContracts() {
    const path = `/admission/requirements/job_contracts`

    return await axios.get(path, config)
      .then((res) => res.data)
      .catch((error) => [])
  },
  async addApprovalOption(identifiers, rule_set, original=true, has_exceptions=false) {
    const path = `/admission/requirements/create`
    const data = {
      approval_option:
        {
          identifiers: identifiers,
          rule_set: rule_set,
          has_exceptions: has_exceptions
        }
    }
    try {
      const res = await axios.post(path, data, config)
      return res.data
    } catch(error) {
      return error.response
    }
  },
  async deleteApprovalOption(approval_options) {
    const path = `/admission/requirements/delete_approval_option`
    const data = {
      approval_options
    }
    try {
      const res = await axios.post(path, data, config)
      return res.data
    } catch(error) {
      return error.response
    }
  }
}
