<template>
  <li :class="`cursor-pointer mb-4 list-style-type-none ${liClasses}`" @click="handleElementClick" >
    <a @click="handleHrefClick" :href="linkHref" class="btn w-100 p-0 text-left">
      <div :class="`card card-body d-flex justify-content-between flex-row align-items-center ${cardClasses}`">
        <div :class="`${contentContainerClasses || 'd-flex '}`">
          <slot></slot>
        </div>

        <slot  name="icon"><i v-if="showIcon" class="fas fa-chevron-right fa-lg float-right"></i></slot>

      </div>
    </a>
  </li>
</template>

<script>
  export default {
    name: 'card-item',
    components: {},
    props: [
      'cardClasses',
      'liClasses',
      'contentContainerClasses',
      'href',
      'noIcon'
    ],
    data() {
      return {
        linkHref: this.href || '/#',
        showIcon: !this.noIcon
      }
    },
    mounted: async function() {
    },
    methods: {
      handleElementClick() {
        this.$emit('click')
      },
      handleHrefClick(event) {
        if(this.linkHref.includes('/#')) { event.preventDefault() }
      }
    }
  }
</script>
