<template>
  <div>
    <h4 class="mb-2">{{ t('activerecord.attributes.simulation.simulation_states') }}</h4>
    <label v-for="(column, index) in columns" :key="column.title">
      <div class="flex align-items-center">
        <label class="chip primary w-max mb-0">
          <input type="checkbox" v-model="column.show"/>
          <span class="control-input check" style="font-size: 9px;">{{ column.title }}</span>
        </label>
        <i v-if="index != columns.length - 1" class="fas fa-angle-right mx-1" style="font-size: 15px;"></i>
      </div>
    </label>

    <div class="horizontal-line mt-2"></div>

    <div class="flex justify-center">
      <div class="overflow-x-scroll">
        <div class="flex">
          <div v-for="column in filterColumns" :key="column.key" class="px-2 column-width border-r border-l border-b">
            <p class="text-center font-semibold my-2">
              {{ column.title }}
            </p>
          </div>
        </div>
        <div style="height: calc(100vh - 300px);">
          <div id="infinite-list" class="overflow-y-scroll flex w-max h-100">
            <div v-for="(column, index) in filterColumns" :key="`${column.key}${index}`">
              <div class="column-width" ref="column">
                <simulation-card
                  v-if="simulationList"
                  :status="column.status"
                  @reloadColumns="loadColumns"
                  :array-hash.sync="simulationList[column.status]"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SimulationCard from '../simulations/Card.vue'
import SimulationFilter from '../simulations/Filter.vue'
import i18n from "../mixins/i18n"
import apiSimulation from '../../api/simulations'
import Loader from '../template/Loader.vue'

export default {
  name: 'simulations-board',
  mixins: [i18n],
  props: [
    'userRole',
  ],
  components: {
    SimulationCard,
    SimulationFilter,
    Loader
  },
  data: function() {
    return {
      columns: JSON.parse(localStorage.getItem('cardColumns') || '[]'),
      columnKey: 0,
      simulationList: null,
      loading: false,
      offset: 0,
      items: []
    }
  },
  methods: {
    loadColumns: function(value) {
      this.columnKey++
      let index = this.columns.findIndex(column => column.status == value.newValue.status)
      this.columns[index].key = `${this.columns[index].status}-${this.columnKey}`
      if(Object.keys(value.oldValue).length) {
        let index = this.columns.findIndex(column => column.status == value.oldValue.status)
        this.columns[index].key = `${this.columns[index].status}-${this.columnKey}`
      }
    },
    getSimulations: async function() {
      const simulationStatuses = JSON.parse(localStorage.getItem('cardColumns')).filter(column => column.show).map(state => state.status)
      const response = await apiSimulation.findSimulations(simulationStatuses, this.offset)
      if(this.simulationList && this.offset !== 0)
        this.filterColumns.forEach(column => {
          if (!this.simulationList[column.status])
            this.simulationList[column.status] = []
          this.simulationList[column.status] = this.simulationList[column.status].concat(response.simulations[column.status])
        })
      else
        this.simulationList = response.simulations
    },
    loadMore: async function() {
      await this.getSimulations()
      this.offset += 6
    }
  },
  computed: {
    filterColumns: function() {
      return this.columns.filter(column => column.show)
    }
  },
  watch: {
    columns: {
      handler: function(newValue, _oldValue) {
        localStorage.setItem('cardColumns', JSON.stringify(newValue))
        this.offset = 0
        this.getSimulations()
      },
      deep: true
    }
  },
  mounted: function() {
    if(!localStorage.getItem('cardColumns')) {
      const columns = [
        { status: 'unconfirmed', title: this.t('simulations.status/unconfirmed'), show: false, total: 0, key: 'unconfirmed' },
        { status: 'waiting_documentation', title: this.t('simulations.status/waiting_documentation'), show: true, total: 0, key: 'waiting_documentation' },
        { status: 'waiting_evaluation', title: this.t('activerecord.attributes.simulation.status/waiting_evaluation'), show: true, total: 0, key: 'waiting_evaluation' },
        { status: 'evaluating', title: this.t('activerecord.attributes.simulation.status/evaluating'), show: true, total: 0, key: 'evaluating' },
        { status: 'exception', title: this.t('activerecord.attributes.simulation.status/exception'), show: true, total: 0, key: 'exception' },
        { status: 'committee', title: this.t('pipedrive.stage.evaluation_committee'), show: true, total: 0, key: 'committee' },
        { status: 'approved', title: this.t('pipedrive.stage.approval'), show: true, total: 0, key: 'approved'},
        { status: 'does_not_accept_evaluation', title: this.t('simulations.status/does_not_accept_evaluation'), show: true, total: 0, key: 'does_not_accept_evaluation'},
        { status: 'rejected', title: this.t('activerecord.attributes.credit_document_signature.status_list.rejected'), show: true, total: 0, key: 'rejected'},
        { status: 'reserved', title: this.t('pipedrive.stage.approval_reserved'), show: true, total: 0, key: 'reserved'},
        { status: 'repair', title: this.t('pipedrive.stage.executive_repair'), show: true, total: 0, key: 'repair'},
        { status: 'operational_previous_review', title: this.t('pipedrive.stage.scheduled'), show: true, total: 0, key: 'operational_previous_review' },
        { status: 'ready_to_execute', title: this.t('simulations.status/ready_to_execute'), show: true, total: 0, key: 'ready_to_execute' },
        { status: 'executed', title: this.t('pipedrive.stage.contract_executed'), show: false, total: 0, key: 'executed' },
      ]
      localStorage.setItem('cardColumns', JSON.stringify(columns))
    }

    this.loadMore()
    const listElm = document.querySelector('#infinite-list');
    listElm.addEventListener('scroll', e => {
      if(listElm.scrollTop + listElm.clientHeight >= listElm.scrollHeight && !this.loading) {
        this.loadMore();
      }
    });
  }
}
</script>
<style>
  .column-width {
    min-width: 220px;
    width: 220px;
  }
</style>
