<template>
  <div class="col-span-8 sm:grid sm:grid-cols-8">
    <div class="col-span-8">
      <div class="flex">
        <h3 class="text-h3">{{ element.product_type.name }} - {{products_description}} </h3>
        <span class="badge badge-info badge-pill ml-2" v-if="cashProductType">
          <em class="fas fa-money-bill mr-2" />
          {{ t(`product_type.cash`) }}
        </span>
      </div>
      <div class="py-2 sm:grid sm:grid-cols-8">
        <dt class="text-base font-bold text-black col-span-2">
          Valor producto:
        </dt>
        <dd class="text-lg text-gray-900 col-span-2">
          {{ productValue }}
        </dd>
        <dt class="text-base font-bold text-black col-span-2">
          {{ cashProductType ? t('simulations.total_amount') : t('simulations.downpayment_amount') }}
        </dt>
        <dd class="text-lg text-gray-900 sm:mt-0 sm:col-span col-span-2">
          <span>
            {{ element.down_payment | currency('$', 0, { thousandsSeparator: '.', decimalSeparator: ',' }) }}
          </span>
        </dd>
        <dt v-if="!cashProductType" class="text-base font-bold text-black col-span-2">
          Monto líquido:
        </dt>
        <dt v-else class="text-base font-bold text-black col-span-2">
          {{ t('simulations.total_extra_cost_amount') }}:
        </dt>
        <dd v-if="!cashProductType" class="text-lg text-gray-900 sm:mt-0 sm:col-span col-span-2">
          {{ element.net_amount | currency('$', 0, { thousandsSeparator: '.', decimalSeparator: ',' }) }}
        </dd>
        <dd v-else class="text-lg text-gray-900 sm:mt-0 sm:col-span col-span-2">
          {{ amountExtraCost | currency('$', 0, { thousandsSeparator: '.', decimalSeparator: ',' }) }}
        </dd>
        <dt v-if="!cashProductType" class="text-base font-bold text-black col-span-2">
          Porcentaje pie:
        </dt>
        <dd v-if="!cashProductType" class="text-lg text-gray-900 sm:mt-0 sm:col-span col-span-2">
          {{ (element.down_payment_percentage || 0) | percent(3) }}
        </dd>
        <dt v-if="!cashProductType" class="text-base font-bold text-black col-span-2">
          Monto bruto:
        </dt>
        <dd v-if="!cashProductType" class="text-lg text-gray-900 sm:mt-0 sm:col-span col-span-2">
          {{ element.gross_amount | currency('$', 0, { thousandsSeparator: '.', decimalSeparator: ',' }) }}
        </dd>
        <span style="display:contents" v-if="showDownPaymentStatus()">
          <dt class="text-base font-bold text-black col-span-2">
            Estado del pago de pie:
          </dt>
          <dd class="text-lg text-gray-900 sm:mt-0 sm:col-span col-span-2">
            <badge-status :color="badgeColor">
              {{ t(`activerecord.attributes.payment_status.${ downPaymentStatus }`) }}
            </badge-status>
            <b-button variant="link" id="show-btn" size="sm" @click="$bvModal.show('down-payment-status-details')">
              {{ t(`modal_receive_payment.view_details`) }}
            </b-button>
          </dd>
        </span>
        <dt v-if="!cashProductType" class="text-base font-bold text-black col-span-2">
          Primer pago:
        </dt>
        <dd v-if="!cashProductType" class="text-lg text-gray-900 sm:mt-0 sm:col-span col-span-2">
          {{ dateParse(element.first_payment_date) }}
        </dd>
        <span v-if="showDownPaymentStatus() && !cashProductType" style="display:contents" >
          <dt class="text-base font-bold text-black col-span-2">
            Monto a pagar de Pie:
          </dt>
          <dd class="text-lg text-gray-900 sm:mt-0 sm:col-span col-span-2" v-b-tooltip.hover  v-b-tooltip.right :title="tooltipTitle">
            {{ downPaymentAmount | currency('$', 0, { thousandsSeparator: '.', decimalSeparator: ',' }) }}
          </dd>
        </span>
        <dt v-if="!cashProductType" class="text-base font-bold text-black col-span-2">
          Cuotas:
        </dt>
        <dd v-if="!cashProductType" class="text-lg text-gray-900 sm:mt-0 sm:col-span col-span-2">
          {{ Math.round(element.installments_amount) }}
        </dd>
        <dt v-if="!cashProductType" class="text-base font-bold text-black col-span-2">
          Monto de la cuota:
        </dt>
        <dd v-if="!cashProductType" class="text-lg text-gray-900 sm:mt-0 sm:col-span col-span-2">
          {{ installmentValue | currency('$', 0, { thousandsSeparator: '.', decimalSeparator: ',' }) }}
        </dd>
        <dt class="text-base font-bold text-black col-span-2">
          {{ cashProductType ? t('simulations.pay_date') : t('simulations.sign_date') }}:
        </dt>
        <dd class="text-lg text-gray-900 sm:mt-0 sm:col-span col-span-2">
          {{ dateParse(element.sign_date) }}
        </dd>
        <dt v-if="!cashProductType" class="text-base font-bold text-black col-span-2">
          Interés mensual:
        </dt>
        <dd v-if="!cashProductType" class="text-lg text-gray-900 sm:mt-0 sm:col-span col-span-2">
          {{ element.interest_rate_per_month | percent(3) }}
        </dd>
        <dt v-if="!cashProductType" class="text-base font-bold text-black col-span-2">
          Ingreso promedio:
        </dt>
        <dd v-if="!cashProductType" class="text-base text-gray-900 sm:mt-0 sm:col-span col-span-2">
          {{ clientIncomesAverage | currency('$', 0, { thousandsSeparator: '.', decimalSeparator: ',' }) }}
        </dd>
        <dt v-if="element.cae && !cashProductType" class="text-base font-bold text-black col-span-2">
          CAE:
        </dt>
        <dd v-if="element.cae && !cashProductType" class="text-base text-gray-900 sm:mt-0 sm:col-span col-span-2">
          {{ element.cae | percent(3) }}
        </dd>
        <dt v-if="!cashProductType" class="text-base font-bold text-black col-span-2">
          Ratio Cuota:
        </dt>
        <dd v-if="!cashProductType" class="text-base text-gray-900 sm:mt-0 sm:col-span col-span-2">
          <span v-if="clientIncomesAverage === 0">
            El ingreso promedio es cero, no se puede calcular el ratio.
          </span>
          <span v-else>
            {{ ratio | percent(3) }}
          </span>
        </dd>
        <dt v-if="!cashProductType" class="text-base font-bold text-black col-span-2">
          Ratio Financiamiento Bruto:
        </dt>
        <dd v-if="!cashProductType" class="text-base text-gray-900 sm:mt-0 sm:col-span col-span-2">
          <span v-if="clientIncomesAverage === 0">
            El ingreso promedio es cero, no se puede calcular el ratio.
          </span>
          <span v-else>
            {{ parseFloat(grossAmountIncomesRatio).toFixed(3) }}
          </span>
        </dd>
      </div>
      <p v-if="element.interested_in" class="mb-0 mt-3 text-muted"><small>{{t('views.interested_in')}}: {{ element.interested_in }}</small></p>
      <div v-if="element.products && element.products.length > 0" class="pb-2 ">
        <h4 class="mb-0 font-bold text-gray-900">
          {{ t('views.product_summary') }}:
        </h4>
        <p class="mb-4 text-muted"><small><strong>{{t('views.latest_update')}}: {{ element.latest_product_update }}</strong></small></p>
        <div>
          <table class="table responsive-table table-with-header">
            <thead>
              <tr>
                <th > {{ t('simulations.identifier')}} </th>
                <th > {{ t('simulations.brand_and_model')}} </th>
                <th > {{ t('simulations.status')}} </th>
                <th > {{ t('simulations.color')}} </th>
                <th > {{ t('simulations.year')}} </th>
                <th > {{ t('simulations.value')}} </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="product in element.products" :key="product.id" >
                <td> {{ product.item ? product.item.inventory_number : '-' }} </td>
                <td> {{ product.item ? `${product.item.brand_name} ${product.item.product_model_name}` : product.description }} </td>
                <td> {{ product ? (product.condition_use || '-') : '-' }} </td>
                <td> {{ product.item && product.item.color_name ? product.item.color_name : product.color_name }} </td>
                <td> {{ product.item ? (product.item.product_model_year || '-') : product.product_model_year }} </td>
                <td> {{ product.item ? (product.price || '-') : '-' }} </td>
              </tr>
            </tbody>
          </table>
          <ul>
            <li v-for="product in element.products" :key="product.id" class="text-muted">
              <p class="mb-0">
                <small>
                  <strong>{{t('simulations.first_purchase_intention')}} - {{product.inventory_type_name}}</strong>: {{product.model_first_intention_name || '-'}}
                  <strong>{{t('simulations.color')}}</strong>: {{product.color_first_intention_name || '-'}}
                  <strong>{{t('simulations.status')}}</strong>: {{product.condition_use || '-'}}
                  <strong>{{t('simulations.value')}}</strong>: ${{product.model_first_intention_list_price || '-'}}
                  <strong>{{t('simulations.updated_at')}}</strong>: {{product.updated_at || '-'}}
                </small>
              </p>
            </li>
          </ul>
        </div>
      </div>
      <div>
        <preorder-detail
          :simulationId="this.element.id"
          :simulationStatus="this.element.status"
          :hasInventoryItem="this.element.product_type.has_inventory"
          :userRole="userRole"
          :type="this.element.simulation_type"
          :user-rolify-roles="userRolifyRoles"
        />
      </div>
      <div>

      <b-modal
        id="down-payment-status-details"
        size="xl"
        :title="t(`modal_down_payment_details.title`)"
        hide-footer
      >
        <down-payment-detail
          :credit-id="element.credit ? element.credit.id : null"
          :user-role="userRole"
          :down-payment-details="downPaymentDetails"
          :general-status="downPaymentStatus"
          :user-rolify-roles="userRolifyRoles"
          @onDownPaymentUpdateStatus="downPaymentUpdateStatus"
        />
      </b-modal>

      <m-modal ref="m-modal" :title="mmodal.titleModal" :status="mmodal.statusModal" :ok-only="true" size="md">
        <template slot="body">
          <p v-html="mmodal.bodyModal"></p>
        </template>
      </m-modal>
      </div>
    </div>
  </div>
</template>
<script>
  import i18n from "../mixins/i18n"
  import _ from 'lodash';
  import moment from 'moment'
  import PreorderDetail from './PreorderDetail'
  import BadgeStatus from './BadgeStatus'
  import clientsApi from '../../api/clients';
  import downPaymentDetailsApi from '../../api/down_payment_details';
  import { badgeColorPaymentStatuses } from '../../constants/statuses'
  import MModal from '../dynamics_form/MModal.vue';

  export default {
    mixins: [i18n],
    name: 'card-detail',
    components: {
      PreorderDetail,
      BadgeStatus,
      MModal,
    },
    props: [
      'element',
      'userRole',
      'userRolifyRoles'
    ],
    data: function () {
      return {
        clientIncomesAverage: null,
        installmentValue: null,
        downPaymentStatus: null,
        tooltipTitle: this.t('general.down_payment_tooltip_message'),
        downPaymentDetails: null,
        products_description: '',
        preordersGeneralStatus: null,
        preorders: null,
        mmodal: {
          statusModal: null,
          titleModal: null,
          bodyModal: null,
        },
      }
    },
    methods: {
      setClientIncomesAverage: async function() {
        if (!this.element.client.id) { return }

        const response = await clientsApi.getIncomesAverage(this.element.client.id)
        this.clientIncomesAverage = response
      },
      setInstallmentValue: function() {
        const firstPeriodInstallment = _.find(this.element.installments, { period: 1 })
        this.installmentValue = firstPeriodInstallment.installment_value
      },
      setDownPaymentStatus: async function() {
        if (!this.element.credit) { return }

        const response = await downPaymentDetailsApi.getDownPaymentStatus(this.element.credit.id)
        this.downPaymentStatus = response.general_status
        this.downPaymentDetails = response.details

      },
      showVehicleModel: function(){
        return !_.isNil(this.element.vehicle_model)
      },
      showVehicleColor: function(){
        return !_.isNil(this.element.vehicle_color)
      },
      showVehicle: function(){
        return !_.isNil(this.element.vehicle)
      },
      showVehicleBrand: function(){
        return !_.isNil(this.element.vehicle.vehicle_model.vehicle_brand)
      },
      showDownPaymentStatus: function(){
        return this.element.credit && this.downPaymentStatus && this.element.down_payment > 0
      },
      getItemFields: function(item) {
        return item?.details?.fields || []
      },
      dateParse: function(value) {
        return moment(value).format('DD-MM-YYYY')
      },
      downPaymentUpdateStatus: async function(downPaymentIds, transition) {
        let response = await downPaymentDetailsApi.updateStatus(downPaymentIds, transition)
        this.mmodal = {
          titleModal: response.status === 200 ? this.t(`mmodal.preorder.title.success`) : this.t(`mmodal.preorder.title.error`),
          bodyModal: response.status === 200 ? '' : this.t(`mmodal.preorder.body_text.error.generic`),
          statusModal: response.status === 200 ? 'success' : 'error'
        }
        this.$refs['m-modal'].toggleModal(true)
        if(response.status === 200) { this.setDownPaymentStatus() }
      },
    },
    mounted() {
      this.setClientIncomesAverage()
      this.setInstallmentValue()
      this.setDownPaymentStatus()
      this.products_description = this.element.products?.map(val => val.description)?.join(', ')
    },
    computed: {
      ratio() {
        return this.installmentValue / this.clientIncomesAverage
      },
      grossAmountIncomesRatio() {
        return this.element.gross_amount / this.clientIncomesAverage
      },
      extraCostsForNetAmount(){
        return Number(
          this.element.extra_costs
            .filter(({category}) => category === 'net')
            .reduce((sum, val) => Number(sum) + Number(val.amount), 0)
        )
      },
      badgeColor: function() {
        return badgeColorPaymentStatuses[this.downPaymentStatus] || badgeColorPaymentStatuses.default
      },
      cashProductType: function() {
        return this.element.product_type?.type == 'cash'
      },
      amountExtraCost() {
        return this.element.extra_costs.reduce((sum, val) => Number(sum) + Number(val.amount), 0)
      },
      productValue() {
        let productValue = 0

        if(this.cashProductType)
          productValue = Number(this.element.net_amount) - this.amountExtraCost
        else
          productValue = Number(this.element.net_amount) + Number(this.element.down_payment) - this.extraCostsForNetAmount

        return this.$options.filters.currency(productValue, '$', 0, { thousandsSeparator: '.', decimalSeparator: ',' })
      },
      downPaymentAmount() {
        return this.downPaymentDetails.reduce( (acc, dpd ) => acc + parseInt(dpd.amount), 0)
      }
    }
  }
</script>
