export const TRANSVERSAL_FIELDS = [
  {
    name: 'id',
    description: 'Número de inventario',
    required: 'No',
    type: {
      text: 'Numérico',
      code: 'number'
    }
  },
  {
    name: 'brand',
    description: 'S/N',
    required: 'Si',
    type: {
      text: 'Lista',
      code: 'select'
    }
  },
  {
    name: 'product_model',
    description: 'S/N',
    required: 'Si',
    type: {
      text: 'Lista',
      code: 'select'
    }
  },
  {
    name: 'year',
    description: 'S/N',
    required: 'No',
    type: {
      text: 'Numérico',
      code: 'number'
    }
  },
  {
    name: 'color',
    description: 'S/N',
    required: 'No',
    type: {
      text: 'Lista',
      code: 'select'
    }
  },
  {
    name: 'operation_intent',
    description: 'S/N',
    required: 'Si',
    type: {
      text: 'Lista',
      code: 'select'
    }
  },
  {
    name: 'warehouse_status',
    description: 'S/N',
    required: 'Si',
    type: {
      text: 'Lista',
      code: 'select'
    }
  },
  {
    name: 'commercial_status',
    description: 'S/N',
    required: 'Si',
    type: {
      text: 'Lista',
      code: 'select'
    }
  },
  {
    name: 'reception_date',
    description: 'S/N',
    required: 'No',
    type: {
      text: 'Fecha',
      code: 'date'
    }
  },
  {
    name: 'availability_date',
    description: 'S/N',
    required: 'No',
    type: {
      text: 'Fecha',
      code: 'date'
    }
  },
  {
    name: 'approx_delivery_date',
    description: 'S/N',
    required: 'No',
    type: {
      text: 'Fecha',
      code: 'date'
    }
  },
  {
    name: 'delivered_at',
    description: 'S/N',
    required: 'No',
    type: {
      text: 'Fecha',
      code: 'date'
    }
  },
  {
    name: 'reserved_at',
    description: 'S/N',
    required: 'No',
    type: {
      text: 'Lista',
      code: 'select'
    }
  },
  {
    name: 'invoice_id',
    description: 'S/N',
    required: 'No',
    type: {
      text: 'Numérico',
      code: 'number'
    }
  },
  {
    name: 'purchased_at',
    description: 'S/N',
    required: 'No',
    type: {
      text: 'Fecha',
      code: 'date'
    }
  },
  {
    name: 'net_amount',
    description: 'S/N',
    required: 'No',
    type: {
      text: 'Numérico',
      code: 'number'
    }
  },
  {
    name: 'vat',
    description: 'S/N',
    required: 'No',
    type: {
      text: 'Numérico',
      code: 'number'
    }
  },
  {
    name: 'sales_invoice_id',
    description: 'S/N',
    required: 'No',
    type: {
      text: 'Numérico',
      code: 'number'
    }
  },
  {
    name: 'sales_invoice_date',
    description: 'S/N',
    required: 'No',
    type: {
      text: 'Fecha',
      code: 'date'
    }
  },
  {
    name: 'sales_invoice_net_amount',
    description: 'S/N',
    required: 'No',
    type: {
      text: 'Fecha',
      code: 'date'
    }
  },
  {
    name: 'sales_invoice_vat',
    description: 'S/N',
    required: 'No',
    type: {
      text: 'Numérico',
      code: 'number'
    }
  },

  {
    name: 'inventory_type_id',
    description: 'S/N',
    required: 'No',
    type: {
      text: 'Numérico',
      code: 'number'
    }
  },
  {
    name: 'dealer',
    description: 'S/N',
    required: 'No',
    type: {
      text: 'Lista',
      code: 'select'
    }
  },
]
