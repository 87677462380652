import { render, staticRenderFns } from "./ModalAddErrand.vue?vue&type=template&id=73a39f41&"
import script from "./ModalAddErrand.vue?vue&type=script&lang=js&"
export * from "./ModalAddErrand.vue?vue&type=script&lang=js&"
import style0 from "./ModalAddErrand.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports