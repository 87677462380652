<template>
  <div class="pl-3 pr-3">
    <b-card v-for="(rule, counter) in ruleSet" v-bind:key="counter" class="mt-2 mb-2 frame-3">
      <h2 v-if="rule.client !== ''" class="title t6 mbp-12">{{ rule.client }}</h2>
      <div class="flex justify-content-between">
        <b>{{ t("admission.show_requirements.to_evaluate") }}:</b><span>{{ t(`admission.show_requirements.evaluators.${rule.evaluator}`) }}</span>
      </div>
      <div class="flex justify-content-between mtp-12">
        <b>{{ t(`admission.show_requirements.rule`) }}:</b><span>{{ translateCriteria(rule) }}</span>
      </div>
      <collapse-with-header v-for="(exception, index) in rule.exceptions" v-bind:key="exception.id" class="mtp-12"   
        :title="t(`admission.show_requirements.singular_exception`) + ` ${index + 1}`"
        collapse-group="exception-group">
        <template slot="body">
          <div v-bind:style="exceptionDeleteStyle" class="btn-delete-exception color-error cursor-pointer"
            @click="onDeleteException(counter, index)">
            <i class="fas fa-trash-alt mlp-6"></i>
          </div>
          <div v-for="(exception_rules) in exception.exception_rules" v-bind:key="exception_rules.id" class="font-14">
            <hr class="horizontal-line mt-0">
            <h2 class="title t6 mbp-12">{{ t(`admission.show_requirements.alternative_rule`) }}</h2>
            <div v-bind:style="exceptionPadding">
              <div class="flex justify-content-between">
                <b>{{ t(`admission.show_requirements.apply_to`) }}:</b><span>{{ exception_rules.client }}</span>
              </div>
              <div class="flex justify-content-between mtp-6">
                <b>{{ t(`admission.show_requirements.to_evaluate`) }}:</b><span>{{ t(`admission.show_requirements.evaluators.${exception_rules.evaluator}`) }}</span>
              </div>
              <div class="flex justify-content-between mtp-6 mbp-16">
                <b>{{ t(`admission.show_requirements.rule`) }}:</b><span>{{ translateCriteria(exception_rules) }}</span>
              </div>
            </div>
          </div>
          <div v-for="(exception_conditions) in exception.exception_conditions" v-bind:key="exception_conditions.id" class="font-14">
            <hr class="horizontal-line mt-0">
            <h2 class="title t6 mbp-12">{{ t(`admission.show_requirements.condition`) }}</h2>
            <div v-bind:style="exceptionPadding">
              <div class="flex justify-content-between">
                <b>{{ t(`admission.show_requirements.apply_to`) }}:</b><span>{{ exception_conditions.client }}</span>
              </div>
              <div class="flex justify-content-between mtp-6">
                <b>{{ t(`admission.show_requirements.to_evaluate`) }}:</b><span>{{ t(`admission.show_requirements.evaluators.${exception_conditions.evaluator}`) }}</span>
              </div>
              <div class="flex justify-content-between mtp-6 mbp-16">
                <b>{{ t(`admission.show_requirements.rule`) }}:</b><span>{{ translateCriteria(exception_conditions) }}</span>
              </div>
            </div>
          </div>
        </template>
      </collapse-with-header>

      <hr class="horizontal-line mtp-22 mbp-16" />
      <div class="flex justify-content-between frame-3-footer">
        <button class="flex align-items-center color-error cursor-pointer" @click="onDeleteRule(ruleSet, counter)">
          {{ t(`admission.rule_card.delete`) }} <i class="fas fa-trash-alt mlp-6"></i>
        </button>
        <button v-if="isCreateException" class="flex align-items-center color-main-blue cursor-pointer" @click="onCreateException(ruleSet, counter)">
          {{ t(`admission.rule_card.create_exception`) }} <i class="fas fa-exclamation-circle mlp-6"></i>
        </button>
      </div>
    </b-card>
  </div>
</template>
<script>
import i18n from "../mixins/i18n"
import capitalize from "../mixins/capitalize"
import admissionRequirementsMixins from "../mixins/admission_requirements"
import CollapseWithHeader from '../template/CollapseWithHeader.vue'

export default {
  components: { CollapseWithHeader },
  name: 'rule-card',
  mixins: [i18n, capitalize, admissionRequirementsMixins],
  props: {
    ruleSet: Array,
    deleteRule: Function,
    deleteException: Function,
    isCreateException: {
      type: Boolean,
      default: true
    },
    createException: {
      type: Function,
      default: () => {}
    },
  },
  data() {
    return {
      exceptionPadding: {paddingInline: '6px'},
      exceptionDeleteStyle: {position: 'absolute', right: '16px',top: '1rem'}
    }
  },
  methods: {
    onDeleteRule(ruleSet, counter) {
      this.$emit('deleteRule', ruleSet, counter)
    },
    onDeleteException(ruleId, exceptionId) {
      this.$emit('deleteException', this.ruleSet, ruleId, exceptionId)
    },
    onCreateException(ruleSet, counter) {
      this.$emit('createException', ruleSet, counter)
    },
  },
}
</script>
<style>
.frame-3-footer button:hover{
  text-decoration: underline;
}

.btn-delete-exception:hover {
  color: #861515;
}
</style>
