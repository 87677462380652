<template>
  <b-modal id="total-check" size="lg" @shown="getDocuments">
    <template #modal-title>
      <h4 class="mb-12">{{ t(`total_check.manage_process`) }}</h4>
    </template>
    <div class="mb-12 static-info">
      <small class="error-100" v-if="errors.clientInformation">{{ t(`total_check.errors.client_information`) }}</small>
      <div class="flex">
        <span class="bold">{{ t(`total_check.client_email`) }}:</span>
        <span class="regular ml-2">{{ client.email }}</span>
      </div>
      <div class="flex">
        <span class="bold">{{ t(`total_check.client_phone`) }}:</span>
        <span class="regular ml-2">{{ client.phone }}</span>
      </div>
    </div>

    <div class="mb-12">
      <h6>{{ t(`total_check.priority_title`) }}</h6>
      <div class="flex mt-4">
        <label class="form-radio">
          <span class="regular ml-2">{{ t(`total_check.priority.normal`) }}</span>
          <input type="radio" value="NORMAL" v-model="priority" name="priority" required/>
          <span class="checkmark"></span>
        </label>
        <label class="form-radio ml-6">
          <span class="regular ml-2">{{ t(`total_check.priority.high`) }}</span>
          <input type="radio" value="HIGH" v-model="priority" name="priority" required/>
          <span class="checkmark"></span>
        </label>
      </div>
    </div>

    <div>
      <h6 class="mb-4">{{ t(`total_check.select_document`) }}</h6>
      <small class="error-100" v-if="errors.file">{{ t(`total_check.errors.file`) }}</small>
      <div v-for="(file, index) in documents" :key="file.id" class="errand-document mb-3">
        <label class="form-radio ml-6">
          <span class="regular ml-2">{{ file.name }}</span>
          <input type="radio" :value="index" v-model="selectedDocumentIndex" name="file" required/>
          <span class="checkmark"></span>
        </label>
        <a :href="file.file_url" target="_blank" class="button button-negative button-sm">{{ t(`views.visualize`).toUpperCase() }}</a>
      </div>
    </div>

    <template #modal-footer>
      <button class="button button-secondary" @click="$bvModal.hide('total-check')">{{ t(`views.cancel`) }}</button>
      <button class="button button-primary" @click="startProcess">{{ t(`total_check.confirm`) }}</button>
    </template>
  </b-modal>
</template>

<script>
import i18n from "../mixins/i18n"
import errandsApi from '../../api/errands'

export default {
  name: 'new-total-check-process',
  mixins: [i18n],
  props: {
    errandId: {
      type: Number,
      default: null
    },
    client: {
      type: Object,
      default: {}
    }
  },
  data() {
    return {
      documents: {},
      priority: 'NORMAL',
      selectedDocumentIndex: null,
      errors: {
        file: false,
        clientInformation: false
      }
    }
  },
  mounted: async function() {
    if (!this.client.email || !this.client.phone) { this.errors['clientInformation'] = true }
  },
  methods: {
    getDocuments: async function() {
      this.documents = await errandsApi.getErrandTriggerDocuments(this.errandId)
    },
    startProcess() {
      if (this.selectedDocumentIndex == null) {
        this.errors['file'] = true
        return
      }
      this.$bvModal.hide('total-check')
      errandsApi.newTotalCheckProcess(this.errandId, {
        client_id: this.client.id,
        errand_document_id: this.documents[this.selectedDocumentIndex].id,
        priority: this.priority
      })
      this.$emit('processRequested')
    }
  }
}
</script>
<style >
  .static-info {
    border: 1px solid #D9E2F4;
    border-radius: 22px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 6px;
  }
  .errand-document {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
    gap: 100px;
    background: #FFFFFF;
    border: 1px solid #AB8CF1;
    border-radius: 12px;
  }
</style>
