<template>
  <div>
    <h2 class="col-span-12 title-module">{{ t("admission.show_requirements.approval_credit_requirements") }}</h2>
    <div v-for="creditType in groupedApprovalOptions['hash_keys']" :key="creditType">
      <b-container fluid>
        <b-row v-b-toggle="`collapse-${creditType}`" class="title-collapse-blue">
          <span>{{ creditType }}</span>
          <span><i class="fas fa-angle-down when-closed"></i><i class="fas fa-angle-up when-open"></i></span>
        </b-row>
      </b-container>
      <b-collapse v-bind:id="`collapse-${creditType}`" class="col-xl-6 col-lg-12">
        <collapse-with-header v-for="(rulesGroup, index) in groupedApprovalOptions['hash_data'][creditType]" v-bind:key="index"
          :title="rulesGroup.rulesGroupDescriptor.title" class="collapse-list">
          <template slot="sub_header">
            <div v-if="rulesGroup.rulesGroupDescriptor.subtitle.length > 0" class="sm:grid sm:grid-cols-2 mtp-6">
              <span v-for="(identifier, index) in rulesGroup.rulesGroupDescriptor.subtitle" :key="index">
                {{ `${identifier["actor"]}-${identifier["evaluator"] }: ${identifier["translatedCriteria"]}` }}
              </span>
            </div>
          </template>
          <template slot="body">
            <hr class="horizontal-line mt-0">
            <b-overlay v-bin:id="`overlay-${creditType}-${collapseIdMaker(rulesGroup.identifiers)}`" :show="busy" no-wrap>
              <template #overlay>
                <div
                  tabindex="-1"
                  role="dialog"
                  aria-modal="false"
                  aria-labelledby="form-confirm-label"
                  class="text-center p-3">
                  <p><strong id="form-confirm-label">{{ t("admission.show_requirements.delete_rule_group.confirmation") }}</strong></p>
                  <div class="d-flex">
                    <b-button variant="outline-danger" class="mr-3" @click="onCancel">
                      {{ t("admission.show_requirements.delete_rule_group.no_answer") }}
                    </b-button>
                    <b-button variant="outline-success" @click="deleteRule(rulesGroup)">{{ t("admission.show_requirements.delete_rule_group.yes_answer") }}</b-button>
                  </div>
                </div>
              </template>
            </b-overlay>
            <b-card-title class="font-bold">{{ t("admission.show_requirements.requirements_subtitle") }}</b-card-title>
            <div v-if="rulesGroup">

              <div v-for="rule in rulesGroup.rule_set" v-bind:key="rule.id" class="sm:grid sm:grid-cols-2">
                <dd>{{ t(`admission.show_requirements.evaluators.${rule.evaluator}`) }}</dd>
                <dd v-bind:class="successColor(rule)">{{ translateCriteria(rule) }}</dd>
                <collapse-with-header v-for="(exception, index) in rule.exceptions" v-bind:key="exception.id" class="mtp-12"
                  :title="t(`admission.show_requirements.singular_exception`) + ` ${index + 1}`"
                  collapse-group="exception-group">

                  <template slot="body">
                    <div v-for="(exception_rules) in exception.exception_rules" v-bind:key="exception_rules.id" class="font-14">
                      <hr class="horizontal-line mt-0">
                      <h2 class="title t6 mbp-12">{{ t('admission.show_requirements.alternative_rule') }}</h2>
                      <div v-bind:style="exceptionPadding">
                        <div class="flex justify-content-between">
                          <b>{{ t('admission.show_requirements.apply_to') }}:</b><span>{{ exception_rules.client }}</span>
                        </div>
                        <div class="flex justify-content-between mtp-6">
                          <b>{{ t('admission.show_requirements.to_evaluate') }}:</b><span>{{ t(`admission.show_requirements.evaluators.${exception_rules.evaluator}`) }}</span>
                        </div>
                        <div class="flex justify-content-between mtp-6 mbp-16">
                          <b>{{ t('admission.show_requirements.rule') }}:</b><span>{{ translateCriteria(exception_rules) }}</span>
                        </div>
                      </div>
                    </div>
                    <div v-for="(exception_conditions) in exception.exception_conditions" v-bind:key="exception_conditions.id" class="font-14">
                      <hr class="horizontal-line mt-0">
                      <h2 class="title t6 mbp-12">{{ t('admission.show_requirements.condition') }}</h2>
                      <div v-bind:style="exceptionPadding">
                        <div class="flex justify-content-between">
                          <b>Aplicar a:</b><span>{{ exception_conditions.client }}</span>
                        </div>
                        <div class="flex justify-content-between mtp-6">
                          <b>A evaluar:</b><span>{{ t(`admission.show_requirements.evaluators.${exception_conditions.evaluator}`) }}</span>
                        </div>
                        <div class="flex justify-content-between mtp-6 mbp-16">
                          <b>Regla:</b><span>{{ translateCriteria(exception_conditions) }}</span>
                        </div>
                      </div>
                    </div>
                  </template>
                </collapse-with-header>
              </div>

            </div>
            <b-button class="mbp-14" :disabled="busy" variant="danger" @click="onSubmit">
              {{ t("admission.show_requirements.delete_rule_group.button") }}
              <i class="fas fa-trash-alt"></i>
            </b-button>
          </template>
        </collapse-with-header>
      </b-collapse>
    </div>
  </div>
</template>

<script>
import apiAdmissionRequirements from '../../api/admission_requirements'
import includes from 'lodash/includes'
import cloneDeep from 'lodash/cloneDeep'
import i18n from "../mixins/i18n"
import rulesGroupDescriptor from "../mixins/rulesGroupDescriptor"
import capitalize from "../mixins/capitalize"
import CollapseWithHeader from '../template/CollapseWithHeader.vue'

export default {
  components: { CollapseWithHeader },
  mixins: [i18n, capitalize, rulesGroupDescriptor],
  name: 'show-requirements',
  data: function() {
    return {
      admissionRequirements: {},
      busy: false,
    }
  },
  methods: {
    getAdmissionRequirements: async function() {
      this.admissionRequirements = await apiAdmissionRequirements.getRequirements()
    },
    onSubmit() {
      this.busy = true
    },
    onCancel() {
      this.busy = false
    },
    deleteRule: function(rulesGroup) {
      const pos = rulesGroup.originalPosition
      this.approvalOptions.splice(pos, 1)
      // Might be a problem:
      // The rule that "takes" its place stays expanded
      this.callToReplaceApprovalOptions()
      this.busy = false
    },

    successColor: function(rule) {
      let res = this.t(`admission.show_requirements.by_evaluator.${rule.evaluator}.operators.${rule.criteria.operator}`) === ""
      return {'text-success': res, 'text-primary': !res}
    },

    rulesGroupCreditType: function(rulesGroup) {
      if (rulesGroup.identifiers === null) { return "" }
      for (var i = 0; i < rulesGroup.identifiers.length; i++) {
        if (rulesGroup.identifiers[i].evaluator === "credit_type") {
          return rulesGroup.identifiers[i].criteria.value
        }
      }
      return ""
    },

    buildSubtitle: function(subtitleData) {
      return subtitleData.map(i=>`${i["actor"]}: ${i["translatedCriteria"]}`)
    },

    translateValue: function(rule) {
      const months_evaluators = ['job_continuity', 'job_seniority', 'continuous_contributions']
      const money_evaluators = ['client_incomes_average', 'gross_amount']
      if (money_evaluators.includes(rule.evaluator)) {
        return this.toCurrency(rule.criteria.value, { precision: 0 })
      }
      if (rule.evaluator == 'installments_quantity') {
        if (Array.isArray(rule.criteria.value)) {
          let values = [...rule.criteria.value]
          let result_last_element = values.pop()
          let result = [values.join(', '), this.t("activerecord.attributes.installment.x_installments", { count: result_last_element })].join(' o ')
          return result
        }
        else {
          return this.t("activerecord.attributes.installment.x_installments", { count: rule.criteria.value })
        }
      }
      if (rule.evaluator == 'down_payment') {
        return this.toPercentage(rule.criteria.value * 100, { precision: 0 })
      }
      if (months_evaluators.includes(rule.evaluator)) {
        return this.t("datetime.distance_in_words.x_months", { count: rule.criteria.value })
      }
      return this.t(`admission.show_requirements.by_evaluator.${rule.evaluator}.value.${rule.criteria.value}`, { defaultValue: rule.criteria.value })
    },

    translateCriteria: function(rule) {
      return this.capitalize([this.t(`admission.show_requirements.by_evaluator.${rule.evaluator}.operators.${rule.criteria.operator}`), this.translateValue(rule)].join(" ").trim())
    },

    groupByCreditType: function(approvalOptions) {
      if (!approvalOptions) {return null}
      let groupedByCreditTypeResult = {}
      let lastCreditType = null

      for (var i = 0; i < approvalOptions.length; i++) {
        let rulesGroup = approvalOptions[i]
        rulesGroup["originalPosition"] = i
        let creditType = this.rulesGroupCreditType(rulesGroup)
        if (!this.rulesGroupHasIdentifiers(rulesGroup)) {
          lastCreditType = this.t("admission.show_requirements.no_identifiers_title")
        }
        else if (!creditType) {
          lastCreditType = this.t("admission.show_requirements.no_credit_type_requirements_title")
        }
        else if (creditType != lastCreditType) {
          lastCreditType = creditType
        }
        if (!groupedByCreditTypeResult[lastCreditType]) {
          groupedByCreditTypeResult[lastCreditType] = []
        }

        rulesGroup["rulesGroupDescriptor"] = this.rulesGroupDescriptor(rulesGroup)

        groupedByCreditTypeResult[lastCreditType].push(rulesGroup)
      }
      let groupedByCreditTypeResultKeys = Object.keys(groupedByCreditTypeResult)

      let indexOfNoCreditType = groupedByCreditTypeResultKeys.indexOf(this.t("admission.show_requirements.no_credit_type_requirements_title"))
      if (indexOfNoCreditType != -1) {
        groupedByCreditTypeResultKeys.push(groupedByCreditTypeResultKeys.splice(indexOfNoCreditType, 1)[0])
      }

      let indexOfNoIdentifiers = groupedByCreditTypeResultKeys.indexOf(this.t("admission.show_requirements.no_identifiers_title"))
      if (indexOfNoIdentifiers != -1) {
        groupedByCreditTypeResultKeys.push(groupedByCreditTypeResultKeys.splice(indexOfNoIdentifiers, 1)[0])
      }

      return { 'hash_keys': groupedByCreditTypeResultKeys, 'hash_data': groupedByCreditTypeResult }
    },
    callToReplaceApprovalOptions: async function() {
      const response = await apiAdmissionRequirements.deleteApprovalOption({ "approval_options" : this.approvalOptions })

      if (response.content) {
        // succesful response
      } else {
        // TODO: show error
      }
    },
    collapseIdMaker: function(identifiers) {
      let collapseId = ''
      if (!identifiers) {
        return collapseId
      }
      for (var i = 0; i < identifiers.length; i++) {
        collapseId += identifiers[i]['evaluator'] + identifiers[i]['criteria']['operator'] + identifiers[i]['criteria']['value']
      }
      return collapseId
    }
  },
  computed: {
    approvalOptions: function() {
      const currentVersion = this.admissionRequirements.current_version
      if (!currentVersion) { return null }
      return this.admissionRequirements.versions[currentVersion].approval_options
    },
    groupedApprovalOptions: function() {
      const approvalOptions = cloneDeep(this.approvalOptions)
      return this.groupByCreditType(approvalOptions) ?? []
    }
  },
  mounted: async function() {
    this.getAdmissionRequirements()
  }
}
</script>

<style>
  .collapsed .when-open,
  *[aria-expanded="true"] .when-closed {
    display: none;
  }
</style>
