<template>
  <div>
    <dropdown-filter
      ref="dropdown_exceptions_types"
      :items="items"
      :list-check="selectedItems"
      :text="t(`activerecord.attributes.operation.exception_types`)"
      v-on:onFilter="getSelected"
      :edit-permissions="canEdit"
    />

    <alert ref="notification" :text="alertMessage" />

    <b-modal id="modal_error" centered>
      <template #modal-header><div class="none"></div></template>
      <div v-bind:style="styleBodyModal">
        <i class="far fa-times-circle w-100 mbp-42" v-bind:style="styleSymbolError"></i>
        <span class="title t1">{{ alertMessage }}</span>
      </div>
      <template #modal-footer>
        <button class="footer-button base-button" @click="$bvModal.hide('modal_error')">{{ t(`views.close`) }}</button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import i18n from "../mixins/i18n"
import capitalize from "../mixins/capitalize"
import DropdownFilter from "../dynamics_form/DropdownFilter.vue";
import operations from "../../api/operations";
import Alert from "../template/Alert.vue";
import includes from 'lodash/includes'

export default {
  components: { DropdownFilter, Alert },
  name: "select-exceptions-types",
  mixins: [i18n, capitalize],
  props: {
    items: Array,
    operationId: Number,
    selectedItems: Array,
    userRole: String
  },
  data() {
    return {
      alertMessage: '',
      styleBodyModal: {
        marginBottom: '42px',
        marginTop: '42px',
        textAlign: 'center',
      },
      styleSymbolError: {
        fontSize: '34px',
        color: 'rgb(221, 33, 34)',
      },
    }
  },
  methods: {
    getSelected: async function (items) {
      let response = await operations.setExceptionType(items, this.operationId);
      this.alertMessage = response.data.message
      if (response.status !== 200)
        return this.$bvModal.show('modal_error')
      return this.$refs.notification.show();
    },
    isAuthorized: function(authorizedRoles) {
      return includes(authorizedRoles, this.userRole)
    }
  },
  computed: {
    canEdit: function() {
      return this.isAuthorized([
        'admin', 'operations', 'operations_boss'
      ])
    }
  }
};
</script>
<style>
  #modal_error .modal-content {
    align-items: center;
  }

  #modal_error .modal-dialog{
    max-width: 424px !important;
  }
</style>
