<template>
  <div
    class="px-3 py-1 rounded-full text-xs font-semibold flex items-center"
    :class="`bg-${color}-100 text-${color}-700`"
  >
    <span>
      <slot></slot>
    </span>
  </div>
</template>

<script>
export default {
  name: 'badge',
  props: [ 'color' ]
}
</script>
