<template>
  <section>
    <div v-if="!isChange">
      <b-button
        id="edit-btn"
        variant="primary"
        size="sm"
        class="ml-3"
        @click="toggleShowEditForm"
        v-show="canEdit"
        v-b-tooltip.hover.bottom :title="`${t(`views.edit`)}`"
      >
        <em class="fas fa-edit"></em>
      </b-button>
      <b-button
        id="cancel-btn"
        variant="primary"
        size="sm"
        class="ml-3"
        @click="changeStatus(data.item.id, 'cancel')"
        v-show="canCancel"
        v-b-tooltip.hover.bottom :title="`${t(`activerecord.attributes.credit.options.cancel`)}`"
      >
        <em class="fas fa-minus-circle"></em>
      </b-button>
      <b-button
        id="reverse-btn"
        variant="primary"
        size="sm"
        class="ml-3"
        @click="changeStatus(data.item.id, 'reverse')"
        v-show="canReverse"
        v-b-tooltip.hover.bottom :title="`${t(`views.reverse`)}`"
      >
        <em class="fas fa-redo"></em>
      </b-button>
      <b-button
        id="move-btn"
        variant="primary"
        size="sm"
        class="ml-3"
        @click="changeStatus(data.item.id, 'move')"
        v-show="canMove"
        v-b-tooltip.hover.bottom :title="`${t(`views.move`)}`"
      >
        <em class="fas fa-exchange-alt"></em>
      </b-button>
      <b-button
        id="pending-btn"
        variant="primary"
        size="sm"
        class="ml-3"
        @click="changeStatus(data.item.id, 'make_available')"
        v-show="canPending"
        v-b-tooltip.hover.bottom :title="`${t(`activerecord.attributes.payment_status.pending`)}`"
      >
        <em class="fas fa-pause"></em>
      </b-button>
    </div>
    <div v-else class="flex align-items-center">
      <preorder-form
        :preorder_id="data.item.id"
        :amount="data.item.amount"
        @toggleShowEditForm="toggleShowEditForm"
        @element="setElement"
        @amount="setAmount"
      />
    </div>
     <m-modal ref="m-modal" :title="titleModal" status="warning" :confirm-only="true" size="md" @onResponse="actionResponse">
      <template slot="body">
        <p v-html="bodyModal"></p>
      </template>
    </m-modal>
  </section>
</template>
<script>
  import i18n from "../mixins/i18n";
  import PreorderForm from './PreorderForm.vue';
  import MModal from '../dynamics_form/MModal.vue';

export default {
  components: {
    PreorderForm,
    MModal
  },
  name: 'preorder-edit',
  mixins: [i18n],
  props: {
    data: Object,
    canUpdateAmount: {
      required: true,
      type: Boolean,
    },
    onSetAmount: Function,
    onSetElement: Function,
    preorderStatus: String,
    simulationStatus: String,
    canUpdateStatus: Boolean,
  },
  data() {
    return {
      canEdit: false,
      canCancel: false,
      canReverse: false,
      canMove: false,
      canPending: false,
      isChange:false,
      preorderSelected: null,
      actionSelected: null,
      titleModal: null,
      bodyModal: null,
      actionName: null
    }
  },
  methods: {
    toggleShowEditForm() {
      this.isChange = !this.isChange
    },
    setAmount: function(amount, id) {
      this.$emit('onSetAmount', amount, id)
    },
    setElement: function(value) {
      this.$emit('onSetElement', value)
    },
    changeStatus: function(preorder_id, action){
      this.preorderSelected = preorder_id
      this.actionSelected = action

      this.setActionNameAndBodyModal(action)
      this.titleModal= this.t(`mmodal.preorder.title.warning`)
      this.$refs['m-modal'].toggleModal(true);
    },
    actionResponse: function(selection){
      if(selection){
        this.$emit('onChangeStatus', this.preorderSelected, this.actionSelected)
      }
      this.preorderSelected = null
      this.actionSelected = null
    },
    setActionNameAndBodyModal: function(action){
      this.actionName = this.t(`mmodal.preorder.action.${action}`)
      this.bodyModal = this.t(`mmodal.preorder.body_text.warning`, { action: this.actionName, internal_code: this.data.item.internal_code })
    },
    validateExecutedStatusSimulation(status){
      return status === 'executed'
    },
    setButtonsState: function(status) {
      if(!(this.canUpdateStatus || this.canUpdateAmount)) { return }

      switch (status) {
        case "pending":
          this.canEdit = !this.validateExecutedStatusSimulation(this.simulationStatus)
          this.canCancel = this.canUpdateStatus && !this.validateExecutedStatusSimulation(this.simulationStatus)
          this.canReverse = false
          this.canMove = false
          this.canPending = false
          break;
        case "paid":
          this.canEdit = false
          this.canCancel = false
          this.canReverse = this.canUpdateStatus && !this.validateExecutedStatusSimulation(this.simulationStatus)
          this.canMove = this.canUpdateStatus
          this.canPending = false
          break;
        case "annulled":
          this.canEdit = false
          this.canCancel = false
          this.canReverse = false
          this.canMove = false
          this.canPending = this.canUpdateStatus && !this.validateExecutedStatusSimulation(this.simulationStatus)
          break;
        default:
          this.canEdit = false
          this.canCancel = false
          this.canReverse = false
          this.canMove = false
          this.canPending = false
      }
    },
  },
  mounted(){
    this.setButtonsState(this.preorderStatus)
  },
  watch: {
    preorderStatus(new_value){
      this.setButtonsState(new_value)
    }
  }

}
</script>
