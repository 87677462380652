<template>
  <b-container fluid>
    <b-alert
      variant="warning"
      :show="hasWarningMessage"
      dismissible
    >
      No puede ejecutar operación hasta seleccionar un vehículo/moto
    </b-alert>
    <b-alert
      variant="danger"
      :show="hasErrors"
      dismissible
      @dismissed="hasErrors = false"
    >
      {{ errorMessage }}
    </b-alert>
    <b-alert
      variant="success"
      :show="hasSuccessMessage"
      dismissible
      @dismissed="hasSuccessMessage = false"
    >
      {{ successMessage }}
    </b-alert>
    <b-card class="shadow" no-body>
      <template #header>
        <div class="d-flex justify-content-between align-items-center w-100">
          <div class="d-flex flex-column">
            <div class="d-flex align-items-center">
              <div>
                <h3>
                  <a class="text-decoration-none" :href="`/clients/${simulationData.client.slug}`" target="_blank">
                    {{ simulationData.client.name }}
                  </a>
                </h3>
              </div>
              <div class="ml-2">
                <a
                  class="text-decoration-none"
                  :href="`https://migrantesf.pipedrive.com/deal/${simulationData.pipedrive_id}`" target="_blank" >
                  <badge-status :color="badgeColor">
                    P:
                    <span v-if="simulationData.pipedrive_id">
                      {{ badgePipedrive }}
                    </span>
                    <span v-else>
                      -
                    </span>
                  </badge-status>
                </a>
              </div>
            </div>
            <p class="small text-muted">{{ simulationInfo }}</p>
            <span
              class="small text-muted"
              v-show="has_quick_code"
            >
              Quick code: #{{ simulationData.quick_code }}
            </span>
            <p v-if="simulationData.fogape" class="mt-1">
              <span class="badge badge-pill bg-warning">
                {{ t("activerecord.attributes.simulation.fogape_postulation") }}
              </span>
            </p>
          </div>
          <div class="d-flex justify-content-around align-items-center">
            <div class="spinner-squared">
              <b-spinner v-if="loading" label="Spinning" />
            </div>
            <dropdown-status :status="simulationData.status" class="inline-block" @updateStatus="updateStatus" />
          </div>
          <div class="operation-buttons">
            <b-button
              v-b-modal.modal_confirm_commitment_date
              v-if="canExecuteOperation"
              :disabled="hasWarnings"
              variant="primary"
            >
              Ejecutar operación
            </b-button>
            <b-button
              v-if="simulationData.operation"
              :href="`/operations/${simulationData.operation.id}`"
              variant="success"
              target="_blank"
            >
              Ver operación {{ simulationData.credit.code }}
            </b-button>
            <b-button
              v-if="canEditSimulation"
              :href="`/simulations/${simulationId}/edit`"
              class="text-white font-weight-bold"
              target="_blank"
              variant="warning"
            >
              Editar simulación
            </b-button>
            <b-button
              v-if="canEvaluate"
              :href="`/simulations/${simulationId}/evaluation`"
              class="btn-purple"
              target="_blank"
            >
              Evaluación
            </b-button>
            <b-button
              v-if="canSelectItem"
              :href="`/simulations/${simulationId}/select_item`"
              target="_blank"
            >
              Seleccionar producto
            </b-button>
          </div>
        </div>
      </template>
      <b-card no-body>
        <b-tabs justified card pills>
          <b-tab
            active
            title="Información de operación"
            title-item-class="tab"
          >
            <card-detail :element="simulationData" :user-role="userRole" :user-rolify-roles="userRolifyRoles"/>
          </b-tab>
          <b-tab
            title="Información de cliente"
            title-item-class="tab"
          >
            <card-person :element="simulationData" />
          </b-tab>
          <b-tab
            title="PAC"
            title-item-class="tab"
            v-if="!cashProductType"
          >
            <card-payment-system :element="simulationData" :userRole="userRole" />
          </b-tab>
          <b-tab
            title="Transferencia Bancaria"
            title-item-class="tab"
            v-if="!cashProductType"
          >
            <card-transfer-bank-account :element="simulationData" />
          </b-tab>
          <b-tab
            title="Avales"
            title-item-class="tab"
            v-if="!cashProductType"
          >
            <guarantor-card
              :element="simulationData"
              @updateSimulation="updateSimulation"
            />
          </b-tab>
          <b-tab
            title="Información faltante"
            title-item-class="tab"
            :title-link-class="{'bg-danger text-white': hasWarnings}"
          >
            <card-document-templates
              :element="simulationData"
              @updatedWarning="updatedWarning"
            />
          </b-tab>
          <b-tab
            title="Cuadro de pago"
            title-item-class="tab"
            v-if="!cashProductType"
          >
            <card-payment-table :element="simulationData" />
          </b-tab>
          <b-tab
            :title="t('activerecord.attributes.simulation.kayak_information')"
            title-item-class="tab"
            v-if="!cashProductType"
          >
            <kayak-information
                :debts-information="JSON.stringify(simulationData.debts_information)"
                :personal-information="JSON.stringify(simulationData.personal_information)"
                :labor-information="JSON.stringify(simulationData.labor_information)"
                :client-incomes="JSON.stringify(simulationData.client_incomes)"
                :client-incomes-average="simulationData.client_incomes_average"
                :kayak-payload="JSON.stringify(simulationData.kayak_payload)"
                :affiliate-records="JSON.stringify(simulationData.affiliate_records)"
                :client-medical-leave="JSON.stringify(simulationData.client_medical_leave)" />
          </b-tab>
          <b-tab
            title="Evaluación Automática"
            title-item-class="tab"
          >
            <automatic-evaluation :simulationId="simulationData.id" :productAmount="simulationData.product_amount" />
          </b-tab>
          <b-tab
            :title="t('activerecord.attributes.simulation.comments')"
            title-item-class="tab"
          >
            <simulation-comments-card :simulation="simulationData" />
          </b-tab>
        </b-tabs>
      </b-card>
    </b-card>

    <b-modal
      id="modal_confirm_commitment_date"
      v-if="canExecuteOperation"
      :title="t('simulations.commitment_date.title')"
    >
      <p class="color-default mtp-20">
        {{t('simulations.commitment_date.message')}}: <strong class="text-primary">{{getProductDate()}}</strong>
      </p>

      <div>
        <b-form-group :label="t('activerecord.attributes.simulation.sign_type')" id="firstForm">
          <b-form-radio-group
            name="firstFormRadio"
            v-model="signType"
            :options="signTypeOptions"
            aria-describedby="signtype"
            stacked
          ></b-form-radio-group>
        </b-form-group>
      </div>

      <div>
        <p class="color-default mtp-20">
          <strong class="">{{ t('digital_sign.extra_cost_title') }}</strong>
        </p>

        <table>
          <tbody>
            <tr v-for="extraCost in simulationData.extra_costs" :key="extraCost.name">
              <td><span class="badge badge-pill badge-light"> {{ t(`activerecord.attributes.extra_cost.category_name.${extraCost.category}`) }} </span></td>
              <td>{{ extraCost.human_name }}:</td>
              <td>${{ extraCost.amount }}</td>
            </tr>
          </tbody>
        </table>

        <div class="alert alert-warning flex align-items-center" >
          <span class="mr-2">
            <i class="fas fa-exclamation-circle"></i>
          </span>
          <p>
            {{ t('digital_sign.extra_cost_message') }}.
          </p>
        </div>
      </div>

      <template #modal-footer>
        <button class="footer-button base-button" @click="$bvModal.hide('modal_confirm_commitment_date')" >{{ t(`views.cancel`) }}</button>
        <button class="footer-button base-button outline" :disabled="hasWarnings" @click="createCredit()">{{ t(`simulations.commitment_date.confirm`) }}</button>
      </template>
    </b-modal>
  </b-container>
</template>

<script>
import i18n from "../mixins/i18n"
import BadgeStatus from './BadgeStatus'
import CardDetail from './CardDetail'
import CardDocumentTemplates from './CardDocumentTemplates'
import CardPerson from './CardPerson'
import CardTransferBankAccount from './CardTransferBankAccount'
import CommentsCard from './CommentsCard'
import DropdownStatus from './DropdownStatus'
import KayakInformation from './KayakInformation.vue'
import AutomaticEvaluation from './AutomaticEvaluation'

import { simulationStatuses, pipedriveStages, badgeColorStatuses, dropdownStatuses } from '../../constants/statuses'

import apiSimulation from '../../api/simulations'
import apiCredit from '../../api/credits'

import includes from 'lodash/includes'
import isNil from 'lodash/isNil'
import DropdownStatusVue from './DropdownStatus.vue'

export default {
  name: 'simulation-show',
  mixins: [i18n],
  components: {
    BadgeStatus,
    CardDetail,
    CardDocumentTemplates,
    CardPerson,
    CardTransferBankAccount,
    CommentsCard,
    DropdownStatus,
    KayakInformation,
    AutomaticEvaluation,
    extraCostFields: [
      {
        key: 'category  ',
        label: '',
        sortable: true
      },
      {
        key: 'human_name',
        label: '',
        sortable: true
      },
      {
        key: 'amount',
        label: '',
        sortable: true
      }
    ]
    //EvaluationRequisites
  },
  props: [
    'simulationId',
    'userRole',
    'userRolifyRoles',
    'readOnly'
  ],
  data: function() {
    return {
      errorMessage: '',
      hasErrors: false,
      hasWarnings: false,
      loading: false,
      simulationData: {},
      successMessage: 'Ya se puede generar el contrato, recuerda validar todos los datos del cliente y sus avales.',
      signTypeOptions: [],
      signType: ''
    }
  },
  methods: {
    createCredit: async function() {
      this.$bvModal.hide('modal_confirm_commitment_date');
      if(this.loading) { return }

      this.loading = true
      this.hasErrors = false
      let confirmCreate = true

      if (this.$moment().format('YYYY-MM-DD') !== this.$moment(this.simulationData.sign_date).format('YYYY-MM-DD')){
        confirmCreate = confirm("¿Quieres ejecutar con una fecha de firma distinta a la de hoy?")
      }
      if (confirmCreate) {
        const response = await apiCredit.createFromSimulation(this.simulationId, this.signType);

        if (response.simulation) {
          this.updateSimulation(response.simulation)
          location.reload()
        } else if (includes([422, 403], response.status)) {
          this.hasErrors = true
          this.errorMessage = response.data.errors
        }
      }
      this.loading = false
    },
    updatedWarning: function(value) {
      this.hasWarnings = value
    },
    updateStatus: async function(value) {
      this.loading = true
      const response = await apiSimulation.update(this.simulationData.id, { status: value });
      this.loading = false
      if (response.status === 'error'){
        this.errorMessage = response.message
        this.hasErrors = true
      } else {
        this.updateSimulation(response)
        location.reload()
      }
    },
    updateSimulation: function(simulation) {
      this.$emit('updateSimulation', simulation)
    },
    isAuthorized: function(authorizedRoles) {
      return includes(authorizedRoles, this.userRole)
    },
    getProductDate: function() {
      let dates = (this.simulationData.products || []).reduce((acc, date) => {
        if(date.approx_delivery_date)
          acc.push(this.$moment(date.approx_delivery_date, 'DD/MM/YYYY'))

        return acc
      }, []);

      if(!dates || dates.length === 0)
        return this.t('simulations.commitment_date.unavailable')

      return this.$moment.max(dates).format('DD/MM/YYYY')
    }
  },
  computed: {
    simulationInfo: function() {
      return `Simulación #${this.simulationId} | Ejecutivo: ${this.simulationData?.owner.first_name} ${this.simulationData?.owner.last_name} <${this.simulationData?.owner.email}>`
    },
    has_quick_code: function () {
      return !isNil(this.simulationData.quick_code)
    },
    canExecuteOperation: function() {
      if (this.simulationData?.product_type?.has_inventory) {
        return !this.simulationData.credit
          && this.isAuthorized(['operations','operations_boss','admin','manager'])
          && this.simulationData.status === 'ready_to_execute'
          && this.hasItemModelSelected
      } else {
        return !this.simulationData.credit
          && this.isAuthorized(['operations','operations_boss','admin','manager'])
          && this.simulationData.status === 'ready_to_execute'
      }
    },
    hasItemModelSelected: function () {
      return this.simulationData.sim_model_color_preferences.reduce(
        (acc, curr) => acc && !isNil(curr.inventory_item_id), true
      )
    },
    hasWarningMessage: function() {
      return this.simulationData.status === 'ready_to_execute'
        && this.simulationData?.product_type?.has_inventory
        && !this.hasItemModelSelected
    },
    hasSuccessMessage: {
      get: function () {
        return this.canExecuteOperation
          && !this.hasWarnings
      },
      set: function (newValue) {
        return newValue
      }
    },
    badgeColor: function() {
      return badgeColorStatuses[this.simulationData.status] || badgeColorStatuses.default
    },
    badgePipedrive: function() {
      return pipedriveStages[this.simulationData.pipedrive_stage] || pipedriveStages.default
    },
    canEditSimulation: function() {
      if (!this.simulationData) { return false }

      const status = this.simulationData.status
      if (includes(['executed'], status) || !isNil(this.simulationData.credit)){
        return false
      } else {
        if(includes(['ready_to_execute', 'approved'], status)) {
          if (this.isAuthorized(['sales_exec', 'sales_team_leader'])) {
            return false
          } else {
            return true
          }
        } else {
          return true
        }
      }
    },
    canEvaluate: function() {
      return this.isAuthorized(['admin', 'admissions', 'collections_boss'])
    },
    canSelectItem: function() {
      return this.isAuthorized([
        'admin', 'sales_team_leader', 'manager', 'tech_support',
        'operations_exec', 'operations', 'operations_boss', 'collections_boss',
        'logistics_deputy_manager', 'logistics_boss'
      ])
        && this.simulationData.product_type.has_inventory
    },
    cashProductType: function() {
      return this.simulationData.product_type?.type == 'cash'
    },
  },
  mounted: async function() {
    this.signTypeOptions = [
      { text: this.t('digital_sign.sign_type.digital') , value: 'digital' },
      { text: this.t('digital_sign.sign_type.manually'), value: 'manually' },
      { text: this.t('digital_sign.sign_type.both'), value: 'both' }
    ]

    const response = await apiSimulation.getSimulation(this.simulationId)
    if (includes([422, 500], response.status)) {
      console.log(`Error: ${response.statusText}`)
    } else {
      this.simulationData = response
    }
  }
}
</script>
<style scoped>
  .btn {
    margin: .1rem;
  }
  .btn-purple, .btn-success {
    font-weight: bold !important;
  }
  .btn-purple {
    background: #8b5cf6 !important;
    color: white !important;
  }
  .btn-purple:hover {
    background: #6f42c1 !important;
  }
  .tab > a {
    max-width: 125px !important;
    min-height: 55px !important;
    padding: .4rem !important;
    text-align: center !important;
  }
  .spinner-squared {
    width: 32px;
    height: 32px;
    margin-right: .5rem;
  }
  .operation-buttons {
    max-width: 350px;
  }
</style>
