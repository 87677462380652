import axios from 'axios'
import moment from 'moment'

export default {
  async get(id) {
    const path = `/leasings/${id}.json`

    const headers = {
      'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
    }

    return await axios.get(path, headers).then((res) => res.data)
  }
}
