<template>
  <div class="d-inline">
    <span v-b-modal.exampleModal class="btn text-primary" >
      Editar varios items
      <span> <i class="fas fa-pen ml-1"></i></span>
    </span>

    <b-modal
      id="exampleModal"
      hide-footer tabindex="-1"
      title="Editar varios items"
    >

      <div class="bg-dark-blue rounded p-4">
        <div class="d-flex">
          <b-form-group
            label="Editar todos a"
            label-class=""
            class="text-white m-0"
          >
            <b-form-input type="text" v-model="name" required></b-form-input>
          </b-form-group>
          <div class="d-flex align-items-end ml-2">
            <b-button v-b-modal.modal-confirm variant="primary" :disabled="!canMerge">
              Cambiar y fusionar
            </b-button>
          </div>
        </div>
      </div>

      <hr>

      <li
        class="list-style-type-none table-header p-2"
      >
        {{modelNameTitle}}
      </li>

      <li
        v-for="item in items" :key="item.id"
        class="list-style-type-none p-2"
      >
        <b-form-checkbox
          :id="`checkbox-${item.id}`"
          v-model="selected"
          name="selecteds"
          :value="item"
          :disabled="isDisabled(item)"
        >
          {{item.name}}
        </b-form-checkbox>

      </li>

      <b-modal id="modal-confirm" tabindex="-1" hide-footer :hide-header="true" hide-header-close title="BootstrapVue">
        <div class="container">
          <div class="text-primary text-center">
            <i class="fas fa-exclamation-triangle fa-3x"></i>
          </div>
          <p class="my-4 text-center">
            <strong>
              ¿Estás seguro de fusionar el ítem "{{fromName}}" en "{{toName}}" con el nombre "{{name}}"?
            </strong>
            <br>
            Las asociociones de inventario o simulaciones asociodas a este registro seran modificadas.
          </p>

          <div class="d-flex">
            <b-button class="w-100 " @click="$bvModal.hide('modal-confirm')" variant="outline-primary" :disabled="!canMerge">
              No, Cancelar
            </b-button>

            <b-button class="w-100 ml-1" @click="merge" variant="primary" :disabled="!canMerge">
              Si, cambiar y fusionar
            </b-button>
          </div>
        </div>

      </b-modal>
    </b-modal>
  </div>
</template>

<script>
  import inventoryTypes from '../../api/inventory_types';

  export default {
    name: 'items-merger',
    components: { },
    props: [
      'items',
      'modelName',
      'modelNameTitle'
    ],
    data() {
      return {
        selected: [],
        name: ''
      }
    },
    computed: {
      canMerge() {
        return this.selected.length === 2 && this.name
      },
      fromName() {
        return this.selected[0]?.name || ''
      },
      toName() {
        return this.selected[1]?.name || ''
      }
    },
    mounted: async function() { },
    methods: {
      isDisabled(item) {
        return this.selected.length > 1 && this.selected.indexOf(item) === -1
      },
      merge: async function() {
        if( !this.canMerge) { return }

        let response = await inventoryTypes.merge_items( this.modelName, this.selected[0].id, this.selected[1].id, this.name )
        if(response.ok) { location.reload() }
      }
    }
  }
</script>
