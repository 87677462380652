export default {
  props: [
    'fieldName',
    'fieldRequired',
    'fieldType',
    'fieldError',
    'fieldDescription',
    'fieldValue',
    'fieldType',
    'fieldId',
    'fieldOptions',
    'readonly'
  ],
  data() {
    return {
      id: this.fieldId || '',
      name: this.fieldName || '',
      required: this.fieldRequired || false,
      type: this.fieldType || 'text',
      error: this.fieldError || '',
      description: this.fieldDescription,
      value: this.fieldValue || '',
      options: this.fieldOptions || [],
      helpId: (this.fieldName || '') + 'input-live-help',
      feedbackId: (this.fieldName || '' ) + 'input-live-feedback'
    }
  },
  mounted() {
  },
  computed: {
    state() {
      const isvalid = this.required ? !(this.value.toString().length === 0) : null
      this.$emit('fieldsValid', { value: isvalid, id: this.id })
      return isvalid
    },
  },
  watch: {
    value: function(newValue, oldValue) {
      this.$emit('changeValue', {value: newValue, id: this.id} )
    }
  },
  methods: {
  }
}
