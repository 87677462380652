<template>
  <div>
    <div class="card">
      <div class="card-body">
        <b-form @submit.prevent="saveBankAccount">
          <b-container>
            <b-row>
              <b-col cols="6">
                <b-form-group id="input-group-1" label="Nombre" label-for="input-1" description="Especifíca el nombre del titular de la cuenta">
                  <b-form-input id="input-1" v-model="bank_account.name" placeholder="Nombre titular" required></b-form-input>
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group id="input-group-4" label="Número de cuenta" label-for="input-4">
                  <b-form-input id="input-4" v-model="bank_account.number" placeholder="Número cuenta titular" required></b-form-input>
                </b-form-group>
              </b-col>
              <b-col cols="3">
                <b-form-group id="input-group-2" label="RUT" label-for="input-2">
                  <b-form-input id="input-2" v-model="bank_account.rut" placeholder="Rut titular" required></b-form-input>
                </b-form-group>
              </b-col>
              <b-col cols="1">
                <b-form-group id="input-group-2" label="Dv" label-for="input-2">
                  <b-form-input id="input-2" v-model="bank_account.dv" placeholder="dv" required></b-form-input>
                </b-form-group>
              </b-col>
              <b-col cols="4">
                <b-form-group id="input-group-3" label="Tipo de cuenta" label-for="input-3">
                  <b-form-select id="input-3" v-model="bank_account.account_type" :options="account_types" required></b-form-select>
                </b-form-group>
              </b-col>
              <b-col cols="4">
                <b-form-group id="input-group-5" label="Banco" label-for="input-5">
                  <b-form-select id="input-5" v-model="bank_account.bank_id" :options="banks" required></b-form-select>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-button type="submit" variant="primary">Guardar</b-button>
              </b-col>
            </b-row>
          </b-container>
        </b-form>
      </div>
    </div>
  </div>
</template>
<script>
  import _ from 'lodash';
  import bankAccountApi from '../../api/bank_accounts';
  import bankApi from '../../api/banks';

  export default {
    components: {},
    props: {
      client: {
        default: () => ({}),
      }
    },
    data() {
      return {
        currentForm: null,
        bank_account: {},
        account_types: ['Cuenta Corriente', 'Cuenta Rut/Vista', 'Cuenta Ahorro'],
        banks: [],
      }
    },
    methods: {
      loadBankAccount() {
        bankAccountApi.getBankAccount(this.client)
          .then(data => {
            this.bank_account = data
          })
      },
      loadBanks() {
        bankApi.getBanks()
          .then(data => {
            this.banks = data
          })
      },
      saveBankAccount(){
        bankAccountApi.updateBankAccount(this.bank_account)
          .then(data => {
            if(data) {
              this.showMessage()
            }
          })
      },
      showMessage() {
        this.$bvToast.toast('Se han actualizado los datos correctamente', {
          title: 'Actualización Cuenta Bancaria',
          variant: 'success',
          solid: true
        })
      },
    },
    mounted() {
      this.loadBankAccount();
      this.loadBanks();
    },
  }
</script>
