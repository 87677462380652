<template>
  <div>
    <conditional-table
      :title="t('activerecord.attributes.simulation.evaluation.direct_debt')"
      :items="formattedDirectDebt"
      :fields="fieldsDirectAndIndirectDebt"
      :condition="hasDirectDebt"
    />
    <conditional-table
      :title="t('activerecord.attributes.simulation.evaluation.indirect_debt')"
      :items="formattedIndirectDebt"
      :fields="fieldsDirectAndIndirectDebt"
      :condition="hasIndirectDebt"
    />
    <br>
    <div>
      <h4>{{t('activerecord.attributes.simulation.evaluation.available_credits')}}</h4>
      <conditional-table
        :title="t('activerecord.attributes.simulation.evaluation.credit_lines')"
        :items="formattedCreditLines"
        :fields="fieldsCreditLinesAndOtherCredits"
        :condition="hasCreditLines"
      />
      <conditional-table
        :title="t('activerecord.attributes.simulation.evaluation.other_credits')"
        :items="formattedOtherCredits"
        :fields="fieldsCreditLinesAndOtherCredits"
        :condition="hasOtherCredits"
      />
    </div>
  </div>
</template>

<script>
import ConditionalTable from "./ConditionalTable.vue"
import i18n from "../mixins/i18n"

export default {
  name: "cmf-data-table",
  mixins: [i18n],
  components: {
    ConditionalTable
  },
  props: [
    'directDebt',
    'indirectDebt',
    'creditLines',
    'otherCredits'
  ],
  data() {
      const formattedDirectDebt = this.directDebt.map((obj) => this.changeNumbersToCurrency(obj))
      const formattedIndirectDebt = this.indirectDebt.map((obj) => this.changeNumbersToCurrency(obj))
      const formattedCreditLines = this.creditLines.map((obj) => this.changeNumbersToCurrency(obj))
      const formattedOtherCredits = this.otherCredits.map((obj) => this.changeNumbersToCurrency(obj))
      return {
        hasDirectDebt: !this.directDebt.length == 0,
        hasIndirectDebt: !this.indirectDebt.length == 0,
        hasCreditLines: !this.creditLines.length == 0,
        hasOtherCredits: !this.otherCredits.length == 0,
        formattedDirectDebt,
        formattedIndirectDebt,
        formattedCreditLines,
        formattedOtherCredits,
        fieldsDirectAndIndirectDebt: this.translateFields(['institution', 'current_debt', 'between_30_to_59_days', 'between_60_to_89_days', "between_30_to_89_days", 'over_90_days', 'total']),
        fieldsCreditLinesAndOtherCredits: this.translateFields(['institution', 'direct', 'indirect'])
      }
  },
  methods: {
    translateFields(fields) {
      return fields.map((field) => ({
        key: field,
        label: this.t(`activerecord.attributes.simulation.evaluation.${field}`)
      }))
    },
    objectMap(obj, fn) {
      return Object.fromEntries(
        Object.entries(obj).map(
          ([k, v], i) => [k, fn(v, k, i)]
        )
      );
    },
    changeNumbersToCurrency(obj) {
      return this.objectMap(obj, v => {
        if(!isNaN(v)) {
          return this.$options.filters.currency(parseInt(v), '$', 0, { thousandsSeparator: '.', decimalSeparator: ',' })
        } else {
          return v
        }
      });
    }
  }
}
</script>
