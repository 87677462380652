<template>
  <b-modal id="generate-invoice" size="lg">
    <template #modal-title>
      <h4 class="mb-12">{{ t(`errand_invoice_template.preview`)}}</h4>
    </template>
    <div class="mb-4 flex">
        <span class="bold mr-2 col-3">{{ t(`errand_invoice_template.preview_data.filename`)}}:</span>
        <b-form-input
          type="text"
          v-model="invoiceName"
          :disabled="!editName"
        ></b-form-input>
      <button class="primary-100" @click="toggleEditName">
        <em class="far fa-edit ml-4"></em>
      </button>
    </div>

    <div class="preview mb-12">
      <div class="info-2">
        <div class="info-3">
          <div class="template-fields">
            <span class="bold">{{ t(`errand_invoice_template.name`) }}:</span>
            <p>{{ client.name }}</p>
          </div>
          <div class="template-fields">
            <span class="bold">{{ t(`errand_invoice_template.rut`) }}:</span>
            <p>{{ client.rut }}</p>
          </div>
          <div class="template-fields">
            <span class="bold">{{ t(`errand_invoice_template.order`) }}:</span>
            <p>{{ t(`errand_invoice_template.preview_data.no_order`) }}</p>
          </div>
          <div class="template-fields">
            <span class="bold">{{ t(`errand_invoice_template.preview_data.street_address`) }}:</span>
            <p>{{ address.street_address }}</p>
          </div>
          <div class="template-fields">
            <span class="bold">{{ t(`errand_invoice_template.preview_data.commune`) }}:</span>
            <p>{{ address.commune }}</p>
          </div>
          <div class="template-fields">
            <span class="bold">{{ t(`errand_invoice_template.preview_data.city`) }}:</span>
            <p>{{ address.city }}</p>
          </div>
        </div>
        <div class="info-3">
          <div class="template-fields">
            <span class="bold">{{ t(`errand_invoice_template.preview_data.emission_date`) }}:</span>

            <b-form-datepicker id="emissionDate" v-model="emissionDate" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" locale="es" class="mb-2"></b-form-datepicker>
          </div>
          <div class="template-fields">
            <span class="bold">{{ t(`errand_invoice_template.preview_data.expiration_date`) }}:</span>
            <p>{{ expirationDate }}</p>
          </div>
          <div class="template-fields">
            <span class="bold">{{ t(`errand_invoice_template.method_payment`) }}:</span>
            <p>{{ isCash ? t(`errand_invoice_template.preview_data.cash_payment_method`) : t(`errand_invoice_template.preview_data.default_payment_method`) }}</p>
          </div>
        </div>
      </div>
      <div class="info-2">
        <div class="info-3">
          <span class="bold">{{ t(`errand_invoice_template.value`) }}</span>
          <p>{{ toCurrency(inventoryItem.price) }}</p>
        </div>
        <div class="info-3">
          <span class="bold">{{ t(`errand_invoice_template.quantity`) }}</span>
          <p>1</p>
        </div>
        <div class="info-3">
          <span class="bold">{{ t(`errand_invoice_template.subtotal`) }}</span>
          <p>{{ toCurrency(inventoryItem.price) }}</p>
        </div>
      </div>
    </div>
    <div>
      <div class="flex-space-between">
        <h4>{{ t(`errand_invoice_template.preview_data.comment`) }}</h4>
        <button class="button button-secondary" @click="toggleEditContent">
          {{ t(`views.edit`) }}
          <em class="far fa-edit ml-2"></em>
        </button>
      </div>
      <b-form-group>
        <b-form-textarea
          id="textarea"
          placeholder=""
          :disabled="!editContent"
          v-model="invoiceContent"
        >
        </b-form-textarea>
      </b-form-group>
    </div>
    <template #modal-footer>
      <button class="button button-secondary" @click="$bvModal.hide('generate-invoice')">{{ t(`views.cancel`) }}</button>
      <button class="button button-primary" @click="generateInvoice">{{ t(`errand_invoice_template.preview_data.generate_invoice`) }}</button>
    </template>
  </b-modal>
</template>

<script>
import i18n from "../mixins/i18n"
import errandInvoiceTemplateApi from '../../api/errand_invoice_template'
import errandsApi from '../../api/errands'

export default {
  name: "generate-invoice",
  mixins: [i18n],
  props: {
    client: {
      type: Object,
      default: {}
    },
    address: {
      type: Object,
      default: {}
    },
    errandId: {
      type: Number,
      default: null
    },
    errandTypeId: {
      type: Number,
      default: null
    },
    inventoryItem: {
      type: Object,
      default: {}
    },
    creditId: {
      type: Number,
      default: null
    },
    operationalCheck: {
      type: String,
      default: null,
    },
    typeCredit: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      emissionDate: new Date(),
      editContent: false,
      editName: false,
      invoiceName: '',
      invoiceContent: '',
      isCash: false,
    }
  },
  mounted: async function() {
    console.log(this.typeCredit)
    this.isCash = this.typeCredit === 'Cash';
  },
  watch: {
    inventoryItem: function() {
      this.buildInvoice()
    }
  },
  computed: {
    expirationDate: function() {
      return this.$moment(this.emissionDate).format('DD/MM/YYYY')
    }
  },
  methods: {
    toggleEditContent() {
      this.editContent = !this.editContent
    },
    toggleEditName() {
      this.editName = !this.editName
    },
    generateInvoice: async function() {
      this.$emit('generatingInvoice', this.invoiceName)
      this.$bvModal.hide('generate-invoice')
      const response = await errandsApi.generateInvoice(
        this.errandId,
        {
          client_id: this.client.id,
          comment: this.invoiceContent,
          filename: this.invoiceName,
          total: this.inventoryItem.price,
          inventory_item_id: this.inventoryItem.id,
          inventory_type_id: this.inventoryItem.inventory_type_id,
          errand_type_id: this.errandTypeId,
          emission_date: this.emissionDate
        }
      )
      this.$emit(
        'invoiceGenerated',
        {
          status: response.status,
          file_url: response.file_url,
          data: response.data
        }
      )
    },
    buildInvoice: async function() {
      const response = await errandInvoiceTemplateApi.buildInvoice(
      this.errandTypeId,
      this.inventoryItem.inventory_type_id,
      this.creditId
      )
      this.invoiceContent = response.content
      this.invoiceName = response.name
    }
  }
}
</script>
<style >
  .preview {
    border: 1px solid #D9E2F4;
    border-radius: 22px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
  }
  .info-2 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 96px;
  }
  .info-3 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 6px;
  }
  .template-fields {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 6px;
  }
</style>
