import axios from 'axios'
import { config } from '../constants/api'

export default {
  async create(params) {
    const path = '/preorders.json'

    return await axios.post(path, params, config)
      .then((res) => res)
      .catch((error) => error.response)
  },
  async getPreorderFromSimulation(simulationId) {
    const path = `/preorders/from_simulation/${simulationId}.json`

    return await axios.get(path, config).then(res => res.data).catch((error) => (error.response))
  },

  async update(id, params) {
    const path = `/preorders/${id}.json`
    return await axios.put(path, params, config)
      .then((res) => res)
      .catch((error) => error.response)
  },

  async getGeneralStatus(simulation_id) {

    const path = `/preorders/general_status/${simulation_id}.json`
    return axios.get(path, config)
      .then(res => res.data)
      .catch((error) => (error.response))
  },

  async registerPayment(paidAmount, simulationId, paymentSystem, date) {

    let params = {
      paid_amount: paidAmount,
      simulation_id: simulationId,
      payment_system: paymentSystem,
      date: date
    }

    const path = `/preorders/receive_payment.json`
    return axios.post(path, params, config)
      .then(res => res.data)
      .catch((error) => (error.response))
  },

  async updateStatus(id, transition) {
    const path = `/preorders/${id}/${transition}.json`

    return await axios.put(path, {}, config)
      .then((res) => res)
      .catch((error) => error.response)
  },

}
