<template>
  <div>
    <b-alert
      variant="danger"
      :show="isError"
      dismissible
      @dismissed="isError=false"
    >
      {{ errorMessage }}
    </b-alert>
    <b-alert
      variant="success"
      :show="isSuccess"
      dismissible
      @dismissed="isSuccess=false"
    >
      {{ successMessage }}
    </b-alert>
    <div class="d-flex justify-content-end mb-4">
      <b-button v-if="hasBankAccountData" @click="usePac" variant="primary">
        Usar datos de PAC
      </b-button>
    </div>
    <b-card-group deck>
      <b-card header="Selecciona una cuenta para transferencia">
        <TransferenceSelector
          :clientId="element.client.id"
          :selected="selectedBankAccount.id"
          :bankAccounts="bankAccounts"
          @onSelected="handleSelected"
          @onSaveButton="handleSaveBankAccount"
        ></TransferenceSelector>
      </b-card>
      <b-card v-if="showNewTransferenceForm">
        <div class="font-weight-bold">
          <b-row>
            <b-col>
              <b-form-group
                label="Nombre Completo Titular Cuenta"
                label-for="input-1"
              >
                <b-form-input v-model="manualTransferBankAccount.name" />
              </b-form-group>

              <b-form-group
                label="RUT"
                label-for="input-2"
                description="Número de RUT - Digito verificador"
              >
                <b-input-group>
                  <b-form-input v-model="manualTransferBankAccount.rut"/>
                  <b-input-group-prepend is-text><strong>-</strong></b-input-group-prepend>
                  <b-form-input v-model="manualTransferBankAccount.dv" class="dv"/>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group label="Tipo de Cuenta" label-for="input-3" description="Tipo de cuenta">
                <b-form-select v-model="manualTransferBankAccount.account_type" :options="bankAccountTypes" />
              </b-form-group>
              <b-form-group
                label="Número de Cuenta"
                label-for="input-4"
              >
                <b-form-input v-model="manualTransferBankAccount.number" />
              </b-form-group>
              <b-form-group label="Banco" label-for="input-1">
                <b-form-select v-model="manualTransferBankAccount.bank_id" :options="bankNames" />
              </b-form-group>
            </b-col>
          </b-row>
        </div>
        <b-button
          @click="handleSaveNewTransference"
          variant="primary"
        >
          Guardar Datos
        </b-button>
      </b-card>
    </b-card-group>
    <div class="d-flex align-items-center">
      <CheckboxField :show="showNewTransferenceForm" @onChangeValue="handleNewTransferenceForm"/>
      <span class="p-medium mt-2">Agregar nueva cuenta</span>
    </div>
  </div>

</template>

<script>
  import apiSimulation from '../../api/simulations'
  import includes from 'lodash/includes'
  import isNil from 'lodash/isNil'
  import values from 'lodash/values'
  import CheckboxField from '../dynamics_form/fields/CheckboxField.vue';
  import TransferenceSelector from '../bank_account/TransferenceSelector.vue'
  import bankAccountApi from '../../api/bank_accounts'

  import { BANK_ACCOUNT_TYPES_LIST, BANKS_LIST } from '../../constants/payments'

  export default {
    name: 'card-transfer-account',
    props: [
      'element',
    ],
    data: function() {
      return {
        isSuccess: false,
        isError: false,
        errorMessage: '',
        successMessage: 'La información se guardó satisfactoriamente.',
        isUsingPAC: false,
        bankAccounts: [],
        manualTransferBankAccount: {
          name: '',
          rut: '',
          dv: '',
          account_type: '',
          number: '',
          bank_id: '',
          client_id: this.element.client.id
        },
        selectedBankAccount: this.element.transference_bank_account || {},
        showNewTransferenceForm: false
      }
    },
    async mounted() {
      this.bankAccounts = await bankAccountApi.getFromClient(this.element.client.id)
    },
    computed: {
      bankAccountTypes: function () {
        return BANK_ACCOUNT_TYPES_LIST
      },
      bankNames: function () {
        return BANKS_LIST
      },
      hasBankAccountData: function () {
        return !values(this.element.bank_account).every(isNil)
      },
    },
    methods: {
      usePac: function(newValue, oldValue) {
        if (this.hasBankAccountData) {
          this.selectedBankAccount = this.element.pac_subscription
          this.handleSaveBankAccount();
        }
      },
      savePaymentSystem: async function() {
        const simulationId = this.element.id
        const response = await apiSimulation.update(simulationId, {
          transfer_bank_account_attributes: this.manualTransferBankAccount
        })
        if(includes([422, 500], response.status)) {
          this.errorMessage = `Error: ${response.data}`
          this.isError = true
        } else {
          this.simulation = response
          this.isSuccess = true
          // need to reload the page in order to query the new state
          setTImeout(() => location.reload(), 3000)
        }
      },
      resetBankAccountData: function () {
        Object.keys(this.manualTransferBankAccount).forEach(key =>
          this.manualTransferBankAccount[key] = ''
        )
      },
      handleSelected(selected) {
        this.selectedBankAccount = selected;
      },
      handleSaveBankAccount: async function() {
        const body = {
          account_type: 'transference',
          object_id: this.element.id,
          client_id: this.element.client.id,
          bank_account_id: this.selectedBankAccount.bank_account_id
        }
        const response = await bankAccountApi.updateByBankAccountType('simulation', body)

        if (response.status != 200) {
          this.errorMessage = `Error: ${response.data}`;
          this.isError = true;
          return
        }

        this.isSuccess = true;
        setTimeout(() => location.reload(), 3000);
      },
      handleNewTransferenceForm(value) {
        this.showNewTransferenceForm = value
      },
      handleSaveNewTransference: async function() {
        const response = await bankAccountApi.createBankAccount(
          'transference',
          this.manualTransferBankAccount,
          this.element.id,
          'simulation'
        )

        if (response.status != 201) {
          this.errorMessage = `Error: ${response.data}`;
          this.isError = true;
          return
        }

        this.isSuccess = true;
        setTimeout(() => location.reload(), 3000);
      }
    },
    components: { TransferenceSelector, CheckboxField }
  }
</script>

<style>
.dv {
  max-width: 10%;
}
</style>
