<template>
  <div>
    <b-container fluid>
      <b-row align-h="start">
        <b-col col xl="4">
          <h2 class="col-span-12 title-module margin-unset">{{ t("admission.new_requirements.create_rule_set") }}</h2>

          <h4 class="col-span-12 mb-3 mt-4 title-module sub-title">{{ t("admission.new_requirements.set_identifiers") }}</h4>
          <rule-card
            :rule-set="identifiers"
            v-on:deleteRule="deleteRule"
            :is-create-exception="false"/>
          <div class="pl-3 pr-3">
            <b-button class="align-items-center" variant="outline-primary" @click="showFormAction('identifier')" block>
              <i class="fas fa-plus"></i> {{ t("admission.new_requirements.add_identifier") }}
            </b-button>
          </div>

          <h4 class="col-span-12 mb-3 mt-4 title-module sub-title">{{ t("admission.new_requirements.rules_to_apply") }}</h4>
          <b-form-group id="secondForm">
            <b-container fluid>
              <div class="flex align-items-center">
                <span class="text-muted w-100"><i class="far fa-copy"></i> {{ t("admission.new_requirements.copy_rules_from") }}:</span>
                <div class="content-select">
                  <b-form-select class="default-select" v-model="rulesGroupToCopy" :options="copyRulesGroupOptions"></b-form-select>
                  <i></i>
                </div>
              </div>
            </b-container>
          </b-form-group>
          <div class="flex justify-content-end mb-4" v-if="Object.keys(rulesGroupToCopy).length != 0">
            <button class="base-button" @click="copyRulesGroup">{{ t("admission.new_requirements.do_it") }}</button>
          </div>
          <rule-card
            :rule-set="ruleSet"
            v-on:deleteRule="deleteRule"
            v-on:deleteException="deleteException"
            v-on:createException="createException"/>
          <div class="pl-3 pr-3">
            <b-button class="align-items-center" variant="outline-primary" @click="showFormAction('rule_set')" block>
              <i class="fas fa-plus"></i> {{ t("admission.new_requirements.add_rule") }}
            </b-button>
          </div>

          <div class="flex justify-content-end mt-3">
            <button class="base-button" @click="saveApprovalOption" :disabled="notEnoughData()">{{ t("admission.new_requirements.save_and_apply_rules") }}</button>
          </div>
        </b-col>

        <b-col cols="1"></b-col>

        <b-col cols xl="6" v-if="showForm">
          <b-card border-variant="info">
            <h2 class="col-span-12 title-module margin-unset">{{ ruleCreationTitle }}</h2>

            <b-form-group :label="'1 ' + t(`admission.new_requirements.apply_conditional_to`)" v-slot="{ ariaDescribedby }" id="redirectForm">
              <b-form-checkbox-group
                id="checkbox-group-1"
                v-model="selectedRedirection"
                :options="redirectOptions"
                :aria-describedby="ariaDescribedby"
                name="flavour-1"
                stacked
              ></b-form-checkbox-group>
            </b-form-group>

            <b-form-group :label="'2 ' + t(`admission.new_requirements.what_type`)" v-slot="{ ariaDescribedby1 }" id="firstForm">
              <b-form-radio-group
                name="firstFormRadio"
                v-model="selectedTypeOfRule"
                :options="typeOfRuleOptions"
                :aria-describedby="ariaDescribedby1"
                v-on:change="secondCleaning"
                stacked
              ></b-form-radio-group>
            </b-form-group>

            <div class="disabled-container">
              <b-form-group :label="'3 ' + t(`admission.new_requirements.criteria_to_evaluate`)" id="secondForm">
                <b-col cols="6" class="content-select p-0">
                  <b-form-select class="default-select" v-model="buildRule.evaluator" :options="selectedTypeOfRuleOptions" v-on:change="firstCleaning"></b-form-select>
                  <i></i>
                </b-col>
              </b-form-group>
              <div v-if="show" class="opacity-div"></div>
            </div>

            <!-- DOCUMENT OPTIONS FORM -->

            <b-form-group v-if="showDocumentOptionsForm" :label="t(`admission.new_requirements.choose_requirements`)" v-slot="{ ariaDescribedby3 }" id="thirdForm">
              <b-form-radio-group
                name="thirdFormRadio"
                v-model="selectedDocumentRequirement"
                :options="documentRequirementOptions"
                :aria-describedby="ariaDescribedby3"
                stacked
              ></b-form-radio-group>
            </b-form-group>

            <!-- OPTIONS FORM -->
            <div v-if="showOptionsForm">
              <options-form-component :evaluator="buildRule.evaluator" :evaluatorOptions="optionsFromArc[buildRule.evaluator]" @selectedOption="buildRule.criteria = $event"></options-form-component>
            </div>

            <!-- DUE DATE FORM -->
            <div v-if="selectedTypeOfRule == 'politic' && buildRule.evaluator !== null && typeOfRequirementByPolitic[buildRule.evaluator].typeOfData === 'due_date'">
              <rut-due-date-component @validateDueDate="validateDueDateData = $event"></rut-due-date-component>
            </div>

            <!-- MONEY FORM -->
            <div v-if="selectedTypeOfRule == 'politic' && buildRule.evaluator !== null && typeOfRequirementByPolitic[buildRule.evaluator].typeOfData === 'money'">
              <b-form-group :label="t(`admission.new_requirements.type_of_restriction`)" v-slot="{ ariaDescribedby }">
                <b-form-radio-group
                  id="radio-group-1"
                  v-model="buildRule.criteria.operator"
                  :options="options"
                  :aria-describedby="ariaDescribedby"
                  name="radio-options"
                  v-on:change="thirdCleaning"
                ></b-form-radio-group>
              </b-form-group>

              <b-form-group
                v-if="buildRule.criteria.operator === 'range'"
                :label="t(`admission.new_requirements.choose_requirements`)">
                <b-container fluid class="mbp-5">
                  <b-row align-h="start" align-v="center">
                    <b-col cols="4">
                      <b-form-checkbox v-model="greaterThan" :value="true" :unchecked-value="false">{{ t("admission.new_requirements.greater_than") }}:</b-form-checkbox>
                    </b-col>
                    <b-col cols="4">
                      <b-form-input
                        id="type-number1"
                        type="text"
                        v-model="greaterThanValue"
                        placeholder="$ 0"
                        :formatter="currencyFormatter">
                      </b-form-input>
                    </b-col>
                    <b-col cols="4">
                      <span>{{ typeOfRequirementByPolitic[buildRule.evaluator].explanation }}</span>
                    </b-col>
                  </b-row>
                </b-container>

                <b-container fluid class="mbp-5">
                  <b-row align-h="start" align-v="center">
                    <b-col cols="4">
                      <b-form-checkbox v-model="lessThan" :value="true" :unchecked-value="false" >{{ t("admission.new_requirements.less_than") }}:</b-form-checkbox>
                    </b-col>
                    <b-col cols="4">
                      <b-form-input
                        id="type-number2"
                        type="text"
                        v-model="lessThanValue"
                        placeholder="$ 0"
                        :formatter="currencyFormatter">
                      </b-form-input>
                    </b-col>
                    <b-col cols="4">
                      <span>{{ typeOfRequirementByPolitic[buildRule.evaluator].explanation }}</span>
                    </b-col>
                  </b-row>
                </b-container>

                <b-container fluid class="mbp-5">
                  <b-row align-h="start" align-v="center">
                    <b-col cols="4">
                      <b-form-checkbox
                        v-model="greaterOrEqualTo"
                        :value="true"
                        :unchecked-value="false"
                      >{{ t("admission.new_requirements.greater_or_equal") }}:</b-form-checkbox>
                    </b-col>
                    <b-col cols="4">
                      <b-form-input
                        id="type-number1"
                        type="text"
                        v-model="greaterOrEqualToValue"
                        placeholder="$ 0"
                        :formatter="currencyFormatter"
                      ></b-form-input>
                    </b-col>
                    <b-col cols="4">
                      <span>{{ typeOfRequirementByPolitic[buildRule.evaluator].explanation }}</span>
                    </b-col>
                  </b-row>
                </b-container>

                <b-container fluid class="mbp-5">
                  <b-row align-h="start" align-v="center">
                    <b-col cols="4">
                      <b-form-checkbox
                        v-model="lessOrEqualTo"
                        :value="true"
                        :unchecked-value="false"
                      >{{ t("admission.new_requirements.less_or_equal") }}:</b-form-checkbox>
                    </b-col>
                    <b-col cols="4">
                      <b-form-input
                        id="type-number2"
                        type="text"
                        v-model="lessOrEqualToValue"
                        placeholder="$ 0"
                        :formatter="currencyFormatter"
                      ></b-form-input>
                    </b-col>
                    <b-col cols="4">
                      <span>{{ typeOfRequirementByPolitic[buildRule.evaluator].explanation }}</span>
                    </b-col>
                  </b-row>
                </b-container>

                <!-- Not in money form -->
                <p class="text-muted">{{ t("admission.new_requirements.decimals_with_comma") }}</p>
              </b-form-group>

              <b-form-group v-if="buildRule.criteria.operator === '=='">
                <b-container fluid class="mbp-5">
                  <b-row align-h="start" align-v="center">
                    <b-col cols="3">
                      <label :for="`type-number3`">{{ t("admission.new_requirements.equal_to") }}:</label>
                    </b-col>
                    <b-col cols="2">
                      <b-form-input
                        id="type-number3"
                        type="text"
                        v-model="buildRule.criteria.value"
                        placeholder="$ 0"
                        :formatter="currencyFormatter"
                      ></b-form-input>
                    </b-col>
                    <b-col cols="3">
                      <span>{{ typeOfRequirementByPolitic[buildRule.evaluator].explanation }}</span>
                    </b-col>
                  </b-row>
                </b-container>
              </b-form-group>
              <b-form-group v-if="buildRule.criteria.operator === '!='">
                <b-container fluid class="mbp-5">
                  <b-row align-h="start" align-v="center">
                    <b-col cols="3">
                      <label :for="`type-number4`">{{ t("admission.new_requirements.different_to") }}:</label>
                    </b-col>
                    <b-col cols="2">
                      <b-form-input
                        id="type-number4"
                        type="text"
                        v-model="buildRule.criteria.value"
                        placeholder="$ 0"
                        :formatter="currencyFormatter"
                      ></b-form-input>
                    </b-col>
                    <b-col cols="3">
                      <span>{{ typeOfRequirementByPolitic[buildRule.evaluator].explanation }}</span>
                    </b-col>
                  </b-row>
                </b-container>
              </b-form-group>
            </div>

            <!-- NUMBER FORM -->
            <div v-if="selectedTypeOfRule == 'politic' && buildRule.evaluator !== null && typeOfRequirementByPolitic[buildRule.evaluator].typeOfData === 'number'">

              <b-form-group :label="t(`admission.new_requirements.type_of_restriction`)" v-slot="{ ariaDescribedby }">

                <b-form-radio-group
                  id="radio-group-1"
                  v-model="buildRule.criteria.operator"
                  :options="options"
                  :aria-describedby="ariaDescribedby"
                  name="radio-options"
                  v-on:change="thirdCleaning"
                ></b-form-radio-group>
              </b-form-group>

              <b-form-group
                v-if="buildRule.criteria.operator === 'range'"
                :label="t(`admission.new_requirements.choose_requirements`)">
                <b-container fluid class="mbp-5">
                  <b-row align-h="start" align-v="center">
                    <b-col cols="4">

                      <b-form-checkbox v-model="greaterThan" :value="true" :unchecked-value="false">{{ t("admission.new_requirements.greater_than") }}:</b-form-checkbox>
                    </b-col>
                    <b-col cols="4">
                      <b-form-input
                        :id="`type-number1`"
                        type="number"
                        placeholder="0"
                        v-model="greaterThanValue"
                        :min="typeOfRequirementByPolitic[buildRule.evaluator].lowerLimit"
                        :max="typeOfRequirementByPolitic[buildRule.evaluator].upperLimit"
                      ></b-form-input>
                    </b-col>
                    <b-col cols="3">
                      <span>{{ typeOfRequirementByPolitic[buildRule.evaluator].explanation }}</span>
                    </b-col>
                  </b-row>
                </b-container>

                <b-container fluid class="mbp-5">
                  <b-row align-h="start" align-v="center">
                    <b-col cols="4">
                      <b-form-checkbox v-model="lessThan" :value="true" :unchecked-value="false">{{ t("admission.new_requirements.less_than") }}:</b-form-checkbox>
                    </b-col>
                    <b-col cols="4">
                      <b-form-input
                        :id="`type-number2`"
                        type="number"
                        v-model="lessThanValue"
                        placeholder="0"
                        :min="typeOfRequirementByPolitic[buildRule.evaluator].lowerLimit"
                        :max="typeOfRequirementByPolitic[buildRule.evaluator].upperLimit"
                      ></b-form-input>
                    </b-col>
                    <b-col cols="3">
                      <span>{{ typeOfRequirementByPolitic[buildRule.evaluator].explanation }}</span>
                    </b-col>
                  </b-row>
                </b-container>
                <b-container fluid class="mbp-5">
                  <b-row align-h="start" align-v="center">
                    <b-col cols="4">
                      <b-form-checkbox v-model="greaterOrEqualTo" :value="true" :unchecked-value="false">{{ t("admission.new_requirements.greater_or_equal") }}:</b-form-checkbox>
                    </b-col>
                    <b-col cols="4">
                      <b-form-input
                        :id="`type-number2`"
                        type="number"
                        v-model="greaterOrEqualToValue"
                        placeholder="0"
                        :min="typeOfRequirementByPolitic[buildRule.evaluator].lowerLimit"
                        :max="typeOfRequirementByPolitic[buildRule.evaluator].upperLimit"
                      ></b-form-input>
                    </b-col>
                    <b-col cols="3">
                      <span>{{ typeOfRequirementByPolitic[buildRule.evaluator].explanation }}</span>
                    </b-col>
                  </b-row>
                </b-container>

                <b-container fluid class="mbp-5">
                  <b-row align-h="start" align-v="center">
                    <b-col cols="4">

                      <b-form-checkbox v-model="lessOrEqualTo" :value="true" :unchecked-value="false">{{ t("admission.new_requirements.less_or_equal") }}:</b-form-checkbox>
                    </b-col>
                    <b-col cols="4">
                      <b-form-input
                        :id="`type-number2`"
                        type="number"
                        v-model="lessOrEqualToValue"
                        placeholder="0"
                        :min="typeOfRequirementByPolitic[buildRule.evaluator].lowerLimit"
                        :max="typeOfRequirementByPolitic[buildRule.evaluator].upperLimit"
                      ></b-form-input>
                    </b-col>
                    <b-col cols="3">
                      <span>{{ typeOfRequirementByPolitic[buildRule.evaluator].explanation }}</span>
                    </b-col>
                  </b-row>
                </b-container>

              </b-form-group>

              <b-form-group v-if="buildRule.criteria.operator === '=='">
                <b-container fluid class="mbp-5">
                  <b-row align-h="start" align-v="center">
                    <b-col cols="3">

                      <label :for="`type-number3`">{{ t("admission.new_requirements.equal_to") }}:</label>
                    </b-col>
                    <b-col cols="2">
                      <b-form-input
                        :id="`type-number3`"
                        type="number"
                        v-model="buildRule.criteria.value"
                        placeholder="0"
                        :min="typeOfRequirementByPolitic[buildRule.evaluator].lowerLimit"
                        :max="typeOfRequirementByPolitic[buildRule.evaluator].upperLimit"
                      ></b-form-input>
                    </b-col>
                    <b-col cols="3">
                      <span>{{ typeOfRequirementByPolitic[buildRule.evaluator].explanation }}</span>
                    </b-col>
                  </b-row>
                </b-container>
              </b-form-group>
              <b-form-group v-if="buildRule.criteria.operator === '!='">
                <b-container fluid class="mbp-5">
                  <b-row align-h="start" align-v="center">
                    <b-col cols="3">

                      <label :for="`type-number4`">{{ t("admission.new_requirements.different_to") }}:</label>
                    </b-col>
                    <b-col cols="2">
                      <b-form-input
                        :id="`type-number4`"
                        type="number"
                        v-model="buildRule.criteria.value"
                        placeholder="0"
                        :min="typeOfRequirementByPolitic[buildRule.evaluator].lowerLimit"
                        :max="typeOfRequirementByPolitic[buildRule.evaluator].upperLimit"
                      ></b-form-input>
                    </b-col>
                    <b-col cols="3">
                      <span>{{typeOfRequirementByPolitic[buildRule.evaluator].explanation}}</span>
                    </b-col>
                  </b-row>
                </b-container>
              </b-form-group>
              <p v-if="buildRule.criteria.operator !== null" class="text-muted">{{ t("admission.new_requirements.decimals_with_dot") }}</p>
            </div>

            <button class="base-button" :disabled="notFullRule()" @click="buildRules()">{{ ruleCreationButton }}</button>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
  import { v4 as uuidv4 } from 'uuid';
  import apiAdmissionRequirements from '../../api/admission_requirements'
  import includes from 'lodash/includes'
  import cloneDeep from 'lodash/cloneDeep'
  import i18n from "../mixins/i18n"
  import admissionRequirementsMixins from "../mixins/admission_requirements"
  import capitalize from "../mixins/capitalize"
  import RutDueDateForm from "./RutDueDateForm.vue"
  import OptionsForm from "./OptionsForm.vue"
  import rulesGroupDescriptor from "../mixins/rulesGroupDescriptor"
  import RuleCard from './RuleCard.vue'

  export default {
    mixins: [i18n, capitalize, rulesGroupDescriptor, admissionRequirementsMixins],
    name: 'new-requirement',
    components: {
      RutDueDateForm,
      OptionsForm,
      RuleCard
    },
    data: function() {
      return {
        hasExceptions: false,
        ruleCreationTitle: '',
        ruleCreationButton: '',
        ruleSelected: null,
        formStep: '',
        ruleCreationTitle: '',
        rulesGroupToCopy: { },
        admissionRequirements: { },
        clientSimulationRelations: [],
        jobSalaryCompositions: [],
        jobContracts: [],
        typeOfClientLoyalty: [],
        employmentStatuses: [],
        productTypes: [],
        creditTypes: [],
        dueDateAuxiliarVar: false,
        showIdentifierOptions: false,
        showForm: false,
        identifiers: [],
        ruleSet: [],
        greaterThan: false,
        greaterThanValue: null,
        lessThan: false,
        lessThanValue: null,
        greaterOrEqualTo: false,
        greaterOrEqualToValue: null,
        lessThanOrEqualTo: false,
        lessThanOrEqualToValue: null,
        dueDateOption: null,
        options: [
          { text: 'Rango de valores', value: 'range' },
          { text: 'Igualdad a un valor', value: '==' },
          { text: 'Diferente a un valor', value: '!=' },
        ],
        operator: [],
        opacity: 0.67,
        selectedTypeOfRule: null,
        selectedRedirection: [],
        typeOfRuleOptions: [],
        redirectOptions: [
          { text: 'Titular', value: 'holder' },
          { text: 'Aval', value: 'guarantor' },
          // { text: 'Simulación', value: 'simulation' }
        ],
        specialTypeOfRuleOptions: [
          { text: 'Documento', value: 'document' },
          { text: 'Política', value: 'politic' },
          { text: 'Identificador', value: 'identifier' }
        ],
        defaultTypeOfRuleOptions: [
          { text: 'Documento', value: 'document' },
          { text: 'Política', value: 'politic' }
        ],
        identifierOptions: [
          { text: 'Selecciona un criterio', value: null, disabled: true },
          { text: 'Tipo de financiamiento', value: 'product_type' },
          { text: 'Tipo de crédito', value: 'credit_type' },
          { text: 'Tipo de cliente', value: 'client_simulation_relation' },
          { text: 'Fidelidad del cliente', value: 'client_loyalty' },
        ],
        politicOptions: [
          { text: 'Selecciona un criterio', value: null, disabled: true },
          { text: 'Cantidad de cuotas', value: 'installments_quantity' },
          { text: 'Puntaje DICOM', value: 'client_dicom' },
          { text: 'Promedio de ingresos', value: 'client_incomes_average' },
          { text: 'Pago regular de cotizaciones', value: 'continuous_contributions' },
          { text: 'Porcentaje de Pie', value: 'down_payment' },
          { text: 'Situación laboral', value: 'employment_status' },
          { text: 'Ratio Monto Bruto / Sueldo', value: 'gross_amount_ratio' },
          { text: 'Ratio Cuota/Sueldo', value: 'installment_amount_ratio' },
          { text: 'Continuidad laboral', value: 'job_continuity' },
          { text: 'Tipo de contrato', value: 'job_contract' },
          { text: 'Antigüedad laboral', value: 'job_seniority' },
          // This option could be requested in the future and it is already implemented, only this line is commented so as not to be used in production
          // { text: 'Monto Bruto', value: 'gross_amount' },
          { text: 'Cantidad de liquidaciones de sueldo', value: 'personal_earnings_quantity' },
          { text: 'Vencimiento rut', value: 'rut_due_date' },
          { text: 'Tipo de sueldo', value: 'salary_composition' },
        ],
        documentOptions: [
          { text: 'Selecciona un criterio', value: null, disabled: true },
          { text: 'Título académico', value: 'college_degree' },
          { text: 'Pasaporte', value: 'passport' },
          { text: 'Boletas de honorarios', value: 'verified_personal_invoices' },
          { text: 'Resumen boletas de honorarios', value: 'verified_personal_invoice_summary' },
          { text: 'Liquidaciones de sueldo', value: 'verified_personal_earnings' },
          { text: 'Certificado de AFP', value: 'retirement_fund_certificate' },
          { text: 'Carnet', value: 'base_rut' },
          { text: 'Trámite de visa', value: 'visa_document' },
        ],
        selectedDocumentRequirement: null,
        documentRequirementOptions: [
          { text: 'Validación', value: 'validation' },
          { text: 'Solo presencia del documento', value: 'presence', disabled: true },
        ],
        typeOfRequirementByPolitic: {
          client_loyalty: {
            typeOfData: 'options'
          },
          client_simulation_relation: {
            typeOfData: 'options'
          },
          product_type: {
            typeOfData: 'options'
          },
          credit_type: {
            typeOfData: 'options'
          },
          client_dicom: { typeOfData: 'number', upperLimit: 999, lowerLimit: 0, explanation: null },
          installments_quantity: { typeOfData: 'number', upperLimit: null, lowerLimit: 0, explanation: null },
          client_incomes_average: { typeOfData: 'money' },
          continuous_contributions: { typeOfData: 'number', upperLimit: null, lowerLimit: 0, explanation: 'meses' },
          down_payment: { typeOfData: 'number', upperLimit: 1, lowerLimit: 0, explanation: 'porcentaje en notación: 0.2 para 20%' },
          employment_status: {
            typeOfData: 'options'
          },
          gross_amount_ratio: { typeOfData: 'number', upperLimit: null, lowerLimit: 0, explanation: null },
          // This option could be requested in the future and it is already implemented, only this line is commented so as not to be used in production
          // gross_amount: { typeOfData: 'money' },
          installment_amount_ratio: { typeOfData: 'number', upperLimit: 100, lowerLimit: 0, explanation: '%'  },
          job_continuity: { typeOfData: 'number', upperLimit: null, lowerLimit: 0, explanation: 'meses' },
          job_contract: {
            typeOfData: 'options'
          },
          job_seniority: { typeOfData: 'number', upperLimit: null, lowerLimit: 0, explanation: 'meses' },
          personal_earnings_quantity: { typeOfData: 'number', upperLimit: null, lowerLimit: 0, explanation: null },
          rut_due_date: { typeOfData: 'due_date'},
          salary_composition: {
            typeOfData: 'options'
          },
        },
        buildRule: {
          evaluator: null,
          criteria: {
            operator: null,
            value: null
          },
          exceptions: []
        },
        buildExtraRule: {
          evaluator: null,
          criteria: {
            operator: null,
            value: null
          },
          exceptions: []
        },
        applicants:[
          {previous: '', expiration:''}
        ],
        requirementTypeForm: ''
      }
    },
    methods: {
      copyRulesGroup: function() {
        this.rulesGroupToCopy.map(item => {
          item.id = uuidv4()
          if(item.exceptions == null)
            item.exceptions = []
          this.ruleSet.push(item)
        })
      },
      rulesGroupDescriptorFormatted: function(rulesGroup) {
        let phrase = ""
        let original = this.rulesGroupDescriptor(rulesGroup)
        if (original["creditTypeValue"] != "") {
          phrase += original["creditTypeValue"] + "; "
        }
        phrase += original["title"]
        for (var i = 0; i < original["subtitle"].length; i++) {
          let parsedIdentifier = original["subtitle"][i]
          phrase += "; "
          phrase += parsedIdentifier["actor"] + "-"
          phrase += parsedIdentifier["evaluator"] + ": "
          phrase += parsedIdentifier["translatedCriteria"]
        }
        return phrase
      },
      translateOptionValue: function(evaluator, value) {
        return this.t(`admission.show_requirements.by_evaluator.${evaluator}.value.${value}`, { defaultValue: value })
      },
      showFormAction(ruleAs) {
        this.formStep = ruleAs
        let possibleTitles = { 'identifier': 'Crear regla para identificar', 'rule_set': 'Crear regla a aplicar', 'exception_conditions': 'Crear condición para aplicar regla alternativa', 'exception_rules': 'Crear regla alternativa bajo condición' }
        let possibleButtons = { 'identifier': 'Crear regla para identificar', 'rule_set': 'Crear nueva regla', 'exception_conditions': 'Crear condición para regla alternativa', 'exception_rules': 'Siguiente' }
        if (ruleAs === 'identifier') {
          this.showIdentifierOptions = true
          this.typeOfRuleOptions = this.specialTypeOfRuleOptions
        }
        else if (ruleAs != 'identifier' && ruleAs in possibleTitles) {
          this.showIdentifierOptions = false
          this.typeOfRuleOptions = this.defaultTypeOfRuleOptions
        }
        this.ruleCreationTitle = possibleTitles[ruleAs]
        this.ruleCreationButton = possibleButtons[ruleAs]
        this.showForm = true
      },

      currencyFormatter(value) {
        value = value.replace(/\D/g, '')
        return "$ " + value.replace(/(\d)(?=(\d{3})+(?:\,\d+)?$)/g, "$1.")
      },

      isDocumentValidationRule() {
        return this.selectedDocumentRequirement === 'validation'
      },
      firstCleaning() {
        this.selectedDocumentRequirement = null
        this.buildRule.criteria.operator = null
        this.buildRule.criteria.value = null
      },
      secondCleaning() {
        this.buildRule.evaluator = null
      },
      thirdCleaning() {
        this.buildRule.criteria.value = null
        this.greaterThan = false
        this.greaterThanValue = null
        this.lessThan = false
        this.lessThanValue = null
        this.greaterOrEqualTo = false
        this.greaterOrEqualToValue = null
        this.lessOrEqualTo = false
        this.lessOrEqualToValue = null
      },
      cleanAll() {
        this.selectedTypeOfRule = null
        this.greaterThan = false
        this.greaterThanValue = null
        this.lessThan = false
        this.lessThanValue = null
        this.greaterOrEqualTo = false
        this.greaterOrEqualToValue = null
        this.lessOrEqualTo = false
        this.lessOrEqualToValue = null
        this.buildRule.evaluator = null
        this.buildRule.criteria.operator = null
        this.buildRule.criteria.value = null
        this.buildExtraRule.evaluator = null
        this.buildExtraRule.criteria.operator = null
        this.buildExtraRule.criteria.value = null
        this.showForm = false
        this.typeOfRuleOptions = this.defaultTypeOfRuleOptions
        this.showIdentifierOptions = false
        this.selectedRedirection = []
      },
      isPoliticRangeRule() {
        return this.buildRule.criteria.operator === 'range'
      },
      notEnoughData() {
        if (this.identifiers.length === 0) {
          return true
        }
        if (this.ruleSet.length === 0) {
          return true
        }
        return false
      },
      notFullRule() {
        if(this.buildRule.evaluator === null) {
          return true
        }
        if(!this.isDocumentValidationRule() && this.buildRule.criteria.operator === null) {
          return true
        }
        if(!this.isDocumentValidationRule() && !this.isPoliticRangeRule() && this.buildRule.criteria.value === null) {
          return true
        }
        if (this.isPoliticRangeRule()) {
          if ((this.greaterThan === false && this.lessThan === false && this.greaterOrEqualTo === false && this.lessOrEqualTo === false)) {
            return true
          }
          if ((this.greaterThan === true && this.greaterThanValue === null) || (this.lessThan === true && this.lessThanValue === null) || (this.greaterOrEqualTo === true && this.greaterOrEqualToValue === null) || (this.lessOrEqualTo === true && this.lessOrEqualToValue === null)) {
            return true
          }
        }
        return false
      },

      currencyToNumber(currencyString) {
        return parseFloat(currencyString.replace(/[\s\.\$]/g, '').replace(/,/g, '.'))
      },

      toNumber(input) {
        if(Number(input)) {
          return parseFloat(input)
        }
        return this.currencyToNumber(input)
      },
      deleteRule(groupedRules, counter) {
        groupedRules.splice(counter, 1)
      },
      deleteException(groupedRules, ruleId, exceptionId) {
        groupedRules[ruleId].exceptions.splice(exceptionId, 1)
      },
      createException(groupedRules, counter) {
        this.showFormAction('exception_rules')
        this.ruleSelected = counter
      },
      buildRules() {
        let groupedRules = []
        let addOriginal = true
        let addExtra = false

        const assignedTranslated = this.selectedRedirection.map(item =>
          this.t(`admission.show_requirements.by_evaluator.client_simulation_relation.value.${item}`)
        )
        this.buildRule.client = this.buildExtraRule.client = assignedTranslated.join(' - ')

        this.buildRule.id = this.buildExtraRule.id = uuidv4()

        if (this.isDocumentValidationRule()) {
          this.buildRule.criteria.operator = '=='
          this.buildRule.criteria.value = 'accepted'
        }
        if (this.isPoliticRangeRule()) {
          if (this.greaterThan && this.greaterThanValue) {
            this.buildRule.criteria.operator = '>'
            this.buildRule.criteria.value = this.toNumber(this.greaterThanValue)
          }
          else if (this.greaterOrEqualTo && this.greaterOrEqualToValue) {
            this.buildRule.criteria.operator = '>='
            this.buildRule.criteria.value = this.toNumber(this.greaterOrEqualToValue)
          }
          if (this.lessThan && this.lessThanValue) {
            this.buildExtraRule.evaluator = this.buildRule.evaluator
            this.buildExtraRule.criteria.operator = '<'
            this.buildExtraRule.criteria.value = this.toNumber(this.lessThanValue)
            addExtra = true
          }
          else if (this.lessOrEqualTo && this.lessOrEqualToValue) {
            this.buildExtraRule.evaluator = this.buildRule.evaluator
            this.buildExtraRule.criteria.operator = '<='
            this.buildExtraRule.criteria.value = this.toNumber(this.lessOrEqualToValue)
            addExtra = true
          }
          if (this.buildRule.criteria.operator === 'range') {
            addOriginal = false
          }
        }
        if (this.money_evaluators.includes(this.buildRule.evaluator)) {
          this.buildRule.criteria.value = this.toNumber(this.buildRule.criteria.value)
        }

        if(this.formStep == 'identifier') {
          groupedRules = this.identifiers
        }
        else if (this.formStep == 'rule_set') {
          groupedRules = this.ruleSet
        }
        else if (this.formStep == 'exception_rules') {
          this.ruleSet[this.ruleSelected].exceptions.push({ exception_conditions: [], exception_rules: [] })
          groupedRules = this.ruleSet[this.ruleSelected].exceptions[this.ruleSet[this.ruleSelected].exceptions.length - 1].exception_rules
        }
        else if (this.formStep == 'exception_conditions') {
          groupedRules = this.ruleSet[this.ruleSelected].exceptions[this.ruleSet[this.ruleSelected].exceptions.length - 1].exception_conditions
          this.hasExceptions = true
        }
        if (addOriginal === true) {
          groupedRules.push(cloneDeep(this.buildRule))
        }
        if (addExtra === true) {
          groupedRules.push(cloneDeep(this.buildExtraRule))
        }
        console.log(this.ruleSet);
        this.cleanAll()
        if (this.formStep === 'exception_rules') {
          this.showFormAction('exception_conditions')
        }
        else {
          this.ruleSelected = null
        }
      },

      saveApprovalOption: async function() {
        const response = await apiAdmissionRequirements.addApprovalOption(this.identifiers, this.ruleSet, this.hasExceptions)
        window.location.href = `${window.location.origin}/admission/requirements/show`
        if (response.content) {
          // succesful response
        } else {
          // TODO: show error
        }
      }
    },
    computed: {
      approvalOptions: function() {
        let currentVersion = this.admissionRequirements.current_version
        if (!currentVersion) { return null }
        return this.admissionRequirements.versions[currentVersion].approval_options
      },
      copyRulesGroupOptions() {
        if (!this.approvalOptions) {
          return null
        }
        let res = [{ text: 'Selecciona un grupo de reglas', value: {}, disabled: true }]
        let options = this.approvalOptions.map((x) => {
          return {
            text: this.rulesGroupDescriptorFormatted(x),
            value: x["rule_set"]
          }
        })
        return res.concat(options)
      },
      showOptionsForm() {
        return (this.selectedTypeOfRule === 'politic' && this.buildRule.evaluator !== null && this.typeOfRequirementByPolitic[this.buildRule.evaluator].typeOfData === 'options') || (this.selectedTypeOfRule === 'identifier' && this.buildRule.evaluator !== null && this.typeOfRequirementByPolitic[this.buildRule.evaluator].typeOfData === 'options')
      },
      showDocumentOptionsForm() {
        return this.selectedTypeOfRule === 'document' && this.buildRule.evaluator !== null
      },
      validateDueDateData: {
        get() {
          return this.dueDateAuxiliarVar
        },
        set(newVal) {
          this.dueDateAuxiliarVar = newVal
          this.buildRule.criteria = newVal
        }
      },
      show() {
        return this.selectedTypeOfRule === null
      },
      selectedTypeOfRuleOptions() {
        if (this.selectedTypeOfRule === 'document') {
          return this.documentOptions
        }
        if (this.selectedTypeOfRule === 'politic') {
          return this.politicOptions
        }
        if (this.selectedTypeOfRule === 'identifier') {
          return this.identifierOptions
        }
      },
      optionsFromArc() {
        return {
          'product_type': this.productTypeOptions,
          'credit_type': this.creditTypeOptions,
          'employment_status': this.employmentStatusOptions,
          'client_loyalty': this.clientLoyaltyOptions,
          'job_contract': this.jobContractOptions,
          'salary_composition': this.jobSalaryCompositionOptions,
          'client_simulation_relation': this.clientSimulationRelationOptions
        }
      },
      productTypeOptions() {
        return this.productTypes.map((x) => {
          return {
            value: { 'operator': '==', 'value': x.name },
            text: this.translateOptionValue('product_type', x.name)
          }
        })
      },
      creditTypeOptions() {
        return this.creditTypes.map((x) => {
          return {
            value: { 'operator': '==', 'value': x.code },
            text: this.translateOptionValue('credit_type', x.code)
          }
        })
      },
      employmentStatusOptions() {
        return this.employmentStatuses.map((x) => {
          return {
            value: { 'operator': '==', 'value': x },
            text: this.translateOptionValue('employment_status', x)
          }
        })
      },
      clientLoyaltyOptions() {
        return this.typeOfClientLoyalty.map((x) => {
          return {
            value: { 'operator': '==', 'value': x },
            text: this.translateOptionValue('client_loyalty', x)
          }
        })
      },
      jobContractOptions() {
        return this.jobContracts.map((x) => {
          return {
            value: { 'operator': '==', 'value': x },
            text: this.translateOptionValue('job_contract', x)
          }
        })
      },
      jobSalaryCompositionOptions() {
        return this.jobSalaryCompositions.map((x) => {
          return {
            value: { 'operator': '==', 'value': x },
            text: this.translateOptionValue('salary_composition', x)
          }
        })
      },
      clientSimulationRelationOptions() {
        return this.clientSimulationRelations.map((x) => {
          return {
            value: { 'operator': '==', 'value': x },
            text: this.translateOptionValue('client_simulation_relation', x)
          }
        })
      },
    },
    mounted: async function() {
      this.productTypes = await apiAdmissionRequirements.getProductTypes()

      this.creditTypes = await apiAdmissionRequirements.getCreditTypes()

      this.employmentStatuses = await apiAdmissionRequirements.getEmploymentStatuses()

      this.typeOfClientLoyalty = await apiAdmissionRequirements.getClientLoyalty()

      this.jobContracts = await apiAdmissionRequirements.getJobContracts()

      this.jobSalaryCompositions = await apiAdmissionRequirements.getJobSalaryCompositions()

      this.clientSimulationRelations = await apiAdmissionRequirements.getClientSimulationRelations()

      this.admissionRequirements = await apiAdmissionRequirements.getRequirements()
    }
  }
</script>

<style>
  .wobble-hor-bottom:hover {
    -webkit-animation: wobble-hor-bottom 0.8s both;
            animation: wobble-hor-bottom 0.8s both;
  }
  @-webkit-keyframes wobble-hor-bottom {
    0%,
    100% {
      -webkit-transform: translateX(0%);
              transform: translateX(0%);
      -webkit-transform-origin: 50% 50%;
              transform-origin: 50% 50%;
    }
    15% {
      -webkit-transform: translateX(-10px) rotate(-6deg);
              transform: translateX(-10px) rotate(-6deg);
    }
    30% {
      -webkit-transform: translateX(5px) rotate(6deg);
              transform: translateX(5px) rotate(6deg);
    }
    45% {
      -webkit-transform: translateX(-5px) rotate(-3.6deg);
              transform: translateX(-5px) rotate(-3.6deg);
    }
    60% {
      -webkit-transform: translateX(3px) rotate(2.4deg);
              transform: translateX(3px) rotate(2.4deg);
    }
    75% {
      -webkit-transform: translateX(-2px) rotate(-1.2deg);
              transform: translateX(-2px) rotate(-1.2deg);
    }
  }
  @keyframes wobble-hor-bottom {
    0%,
    100% {
      -webkit-transform: translateX(0%);
              transform: translateX(0%);
      -webkit-transform-origin: 50% 50%;
              transform-origin: 50% 50%;
    }
    15% {
      -webkit-transform: translateX(-10px) rotate(-6deg);
              transform: translateX(-10px) rotate(-6deg);
    }
    30% {
      -webkit-transform: translateX(5px) rotate(6deg);
              transform: translateX(5px) rotate(6deg);
    }
    45% {
      -webkit-transform: translateX(-5px) rotate(-3.6deg);
              transform: translateX(-5px) rotate(-3.6deg);
    }
    60% {
      -webkit-transform: translateX(3px) rotate(2.4deg);
              transform: translateX(3px) rotate(2.4deg);
    }
    75% {
      -webkit-transform: translateX(-2px) rotate(-1.2deg);
              transform: translateX(-2px) rotate(-1.2deg);
    }
  }
</style>
