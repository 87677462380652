<template>
  <div>
    <h2 class="pb-2">{{ t("admission.admission_requirements_menu.title") }}</h2>
    <b-container fluid>
      <b-row class="pb-2 pt-2">
        <b-col>
          <b-button variant="primary" @click="createRules()">{{ t("admission.admission_requirements_menu.create_rule_set") }}</b-button>
        </b-col>
      </b-row>
      <b-row class="pb-2 pt-2">
        <b-col>
          <b-button variant="primary" @click="indexCreatedRules()">{{ t("admission.admission_requirements_menu.applied_rules") }}</b-button>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
  // Imports
  import i18n from "../mixins/i18n"
  import capitalize from "../mixins/capitalize"

  export default {
    mixins: [i18n, capitalize],
    name: 'admission-requirements-component',
    components: {
      // Add components as: CoolComponent,
    },
    props: [
      // Properties
    ],
    emits: [
      // Events that emit
    ],
    data: function() {
      return {
        // Internal variables
      }
    },
    methods: {
      // Actions
      createRules() {
        window.location.href = `${window.location.origin}/admission/requirements/new_requirements`
      },
      indexCreatedRules() {
        window.location.href = `${window.location.origin}/admission/requirements/show`
      }
    },
    computed: {
      // To parse received data
    },
    mounted: async function() {
    }
  }
</script>

<style>

</style>
