import axios from 'axios';
import * as humps from 'humps';

export default {
  getBanks() {
    const path = '/banks.json';
    const headers = {
      'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
    }
    return axios.get(
      path,
      {
        headers
      }
    ).then(res => res.data);
  },
  updateBank(bank) {
    const path = `/banks/${bank.id}`;
    const body = {
      bank: {
        code: bank.code,
        name: bank.name
      }
    }
  
    const headers = {
      'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
    }

    return axios.patch(
      path,
      humps.decamelizeKeys(body), {
        headers: headers
      }
    ).then(res => res.data);
  },
  newBank(bank) {
    const path = '/banks';
    const body = {
      bank: {
        code: bank.code,
        name: bank.name
      }
    }
    const headers = {
      'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
    }
    
    return axios.post(
      path,
      humps.decamelizeKeys(body),{
        headers
      }
    ).then(res => res.data);
  }
}
