<template>
  <div>
    <b-collapse :visible="false" :id="collapseId">
      <slot name="collapse-contain"></slot>
    </b-collapse>
  <button ref="btn-collapse" class="button button-negative button-sm button-collapse" v-b-toggle="collapseId">
      {{ textButton }}
    </button>
  </div>
</template>
<script>
import i18n from "../mixins/i18n"
import { v4 as uuidv4 } from 'uuid';

export default {
  name: "content-collapse",
  mixins: [i18n],
  data() {
    return {
      textButton: this.t('views.see_full'),
      collapseId: `collapse-${uuidv4()}`
    }
  },
  mounted() {
    this.$root.$on('bv::collapse::state', (collapseId, isJustShown) => {
      if (collapseId === this.collapseId)
        if (isJustShown) {
          this.textButton = this.t('views.hide')
          this.$refs['btn-collapse'].classList.add('active')
        } else {
          this.textButton = this.t('views.see_full')
          this.$refs['btn-collapse'].classList.remove('active')
        }
    })
  }
}
</script>
<style scoped>
.button-collapse:after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.button-collapse.active:after {
  border-top: 0;
  border-bottom: 0.3em solid;
}

.button-collapse.active {
  border: 1px solid rgba(255, 255, 255, 0) !important;
}
</style>
