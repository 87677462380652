<template>
  <m-dropdown
    :text="t('calendar.appointment_date')"
    @onSubmitSelected="submitDateFilter">
    <template slot="content">
      <b-form-datepicker class="w-100"
        v-model="date"
        placeholder="dd/mm/aaaa"
        :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
        locale="en-US">
      </b-form-datepicker>
    </template>
  </m-dropdown>
</template>
<script>
import MDropdown from '../../../../../dynamics_form/MDropdown.vue'
import i18n from "../../../../../mixins/i18n"

export default {
  components: { MDropdown },
  name: 'date-filter',
  mixins: [i18n],
  props: {
    onSubmitDateFilter: Function
  },
  data() {
    return {
      date: '',
    }
  },
  methods: {
    submitDateFilter() {
      this.$emit('onSubmitDateFilter', this.date)
    },
    cleanFilter() {
      this.date = ''
    },
  }
}
</script>
