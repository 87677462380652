<template>
  <div>
    <div v-if="showModal" class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex w-full text-xs">
      <div class="relative my-6 mx-auto w-screen mx-20">
        <!--content-->
        <div class="border-0 mx-40 rounded-lg shadow-lg relative flex flex-col space-x-1 max-w-6xl bg-white outline-none focus:outline-none">
          <!--header-->
          <div class="px-3 pt-3 border-b border-gray-200">
            <h3 class="text-lg leading-6 font-medium text-gray-900">
              Búsqueda de vehículos
              <button class="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-lg leading-none font-semibold outline-none focus:outline-none" v-on:click="toggleModal()">
                <span class="bg-transparent text-black opacity-5 h-3 w-3 text-lg block outline-none focus:outline-none">
                  ×
                </span>
              </button>
            </h3>
          </div>
          <!--body-->
          <div class="relative p-3 pt-0 flex-auto sm:grid sm:grid-cols-8 gap-4">
            <div class="text-gray-600 col-start-1 col-span-2 " >
              N. de Inventario
            </div>
            <div class="text-gray-600 col-start-3 col-span-2 " >
              Marca
            </div>
            <div class="text-gray-600 col-start-5 col-span-2 " >
              Modelo
            </div>
            <div class="text-gray-600 col-start-7 col-span-2 " >
              Color
            </div>
            <div class="text-gray-600 col-start-9 col-span-2 " >
            </div>
            <div class="text-gray-600 col-start-1 col-span-2 ">
              <b-input v-model="inventoryNumber"></b-input>
            </div>
            <div class="text-gray-600 col-start-3 col-span-2 ">
              <b-form-select
                      v-model="vehicleBrandId"
                      :options="this.vehicleBrandList"
                      value-field="id"
                      text-field="name"
              >
                <template #first>
                  <b-form-select-option value="">Marca</b-form-select-option>
                </template>
              </b-form-select>
            </div>
            <div class="text-gray-600 col-start-5 col-span-2 ">
              <b-form-select
                      v-model="vehicleModelId"
                      :options="this.vehicleModelList"
                      value-field="id"
                      text-field="name"
              >
                <template #first>
                  <b-form-select-option value="">Modelo</b-form-select-option>
                </template>
              </b-form-select>
            </div>
            <div class="text-gray-600 col-start-7 col-span-2 ">
              <b-form-select
                      v-model="colorId"
                      :options="this.colorsList"
                      value-field="id"
                      text-field="name"
              >
                <template #first>
                  <b-form-select-option value="">Color</b-form-select-option>
                </template>
              </b-form-select>
            </div>
            <div class="text-gray-600 col-start-9 col-span-2 ">
              <b-button block @click="filterItems()" variant="primary">Filtrar</b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showModal" class="opacity-25 fixed inset-0 z-40 bg-black"></div>
  </div>
</template>

<script>
import 'tailwindcss/dist/base.min.css'
import 'tailwindcss/dist/components.min.css'
import 'tailwindcss/dist/utilities.min.css'
import 'tailwindcss/dist/tailwind.min.css'
import inventoryApi from '../../api/inventory';

export default {
  name: "vehicle-search-modal",
  props: [
    'showEditModal',
  ],
  data: function() {
    return {
      showModal: this.showEditModal,
      loading: false,
      errorMessage: '',
      colorsList: '',
      vehicleModelList: '',
      vehicleBrandList: '',
      colorId: '',
      vehicleBrandId: '',
      vehicleModelId: '',
      inventoryNumber: ''
    }
  },
  methods: {
    filterItems: function(){

      this.$emit('filterVehicles', {inventoryNumber: this.inventoryNumber, brand: this.vehicleBrandId, model: this.vehicleModelId, color: this.colorId});
   },
    toggleModal: function() {
      this.showModal = !this.showModal
    },
    getVehicleBrandList: async function() {
      const response = await inventoryApi.vehicleBrandList()
      this.vehicleBrandList = response.data
    },
    getVehicleModelList: async function() {
      const response = await inventoryApi.vehicleModelList()
      this.vehicleModelList = response.data
    },
    getColorsList: async function() {
      const response = await inventoryApi.colorsList()
      this.colorsList = response.data
    },
  },
  mounted: async function() {
    this.getVehicleBrandList()
    this.getVehicleModelList()
    this.getColorsList()
  },
  watch: {
    showEditModal: function(newVal, oldVal) {
      this.showModal = this.showEditModal;
    }
  }
}
</script>
