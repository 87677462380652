export const simulationStatuses = {
  unconfirmed: 'No confirmado',
  waiting_documentation: 'Esperando documentación',
  waiting_evaluation: 'Esperando evaluación',
  evaluating: 'Evaluando',
  exception: 'Apelación',
  committee: 'Comité evaluación',
  approved: 'Aprobado',
  does_not_accept_evaluation: 'No acepta simulación',
  rejected: 'Rechazado',
  reserved: 'Reservado',
  repair: 'Reparo',
  executed: 'Ejecutado',
  ready_to_execute: 'Listo para ser ejecutado',
  default: 'Sin Estado',
}

export const pipedriveStages = {
  new_prospect: 'Nuevo prospecto',
  contact: 'Contactado',
  form_2x1: 'Form2x1',
  form_2x2: 'Form2x2',
  pre_approval: 'Evaluación comercial',
  waiting_for_documentation: 'Esperando documentación',
  evaluation: 'Evaluación',
  appeal: 'Apelación',
  evaluation_committee: 'Comité evaluación',
  approval: 'Aprobado',
  approval_reserved: 'Reservado',
  executive_repair: 'Reparo',
  scheduled: 'En revisión de operaciones',
  contract: 'Contrato Migrante',
  product_delivered: 'Entregado',
  waiting_for_product: 'Esperando producto',
  contract_executed: 'Ejecutado',
  default: 'Nuevo prospecto'
}

export const badgeColorStatuses = {
  unconfirmed: 'bg-gray-900',
  waiting_documentation: 'bg-gray-900',
  waiting_evaluation: 'bg-gray-900',
  evaluating: 'bg-gray-900',
  exception: 'bg-gray-900',
  approved: 'bg-gray-900',
  does_not_accept_evaluation: 'bg-gray-900',
  rejected: 'bg-gray-900',
  executed: 'bg-gray-900',
  default: 'bg-gray-900',
  new_prospect: 'bg-gray-900',
  contact: 'bg-gray-900',
  form_2x1: 'bg-gray-900',
  form_2x2: 'bg-gray-900',
  pre_approval: 'bg-gray-900',
  waiting_for_documentation: 'bg-gray-900',
  evaluation: 'bg-gray-900',
  committee: 'bg-gray-900',
  appeal: 'bg-gray-900',
  approval: 'bg-gray-900',
  reserved: 'bg-gray-900',
  repair: 'bg-gray-900',
  ready_to_execute: 'bg-gray-900',
  contract: 'bg-gray-900',
}

export const dropdownStatuses = [
  { value: 'unconfirmed', text: 'No confirmado' },
  { value: 'waiting_documentation' , text: 'Esperando documentación' },
  { value: 'waiting_evaluation' , text: 'Esperando evaluación' },
  { value: 'evaluating' , text: 'Evaluando' },
  { value: 'exception' , text: 'Apelación' },
  { value: 'committee' , text: 'Comité evaluación' },
  { value: 'approved' , text: 'Aprobado' },
  { value: 'reserved' , text: 'Reservado' },
  { value: 'repair' , text: 'Reparo' },
  { value: 'operational_previous_review' , text: 'En revisión de operaciones' },
  { value: 'ready_to_execute' , text: 'Listo para ser ejecutado' },
  { value: 'does_not_accept_evaluation' , text: 'No acepta simulación' },
  { value: 'rejected' , text: 'Rechazado' },
]

export const paymentStatuses = [
  { value: 'make_available', text: 'Pendiente'},
  { value: 'cancel', text: 'Anulado' },
  { value: 'refund' , text: 'Devuelto' }
]

export const badgeColorPaymentStatuses = {
  pending: 'bg-red-600',
  annulled: 'bg-red-900',
  moved: 'bg-blue-900',
  paid: 'bg-green-600',
  refunded: 'bg-gray-900',
  default: 'bg-gray-900',
}

export const simulationStatusesForPreorder = ['approved','reserved','ready_to_execute','operational_previous_review']


export const pacStatusColor = {
  active: 'badge-success',
  pending: 'badge-secondary',
  expired: 'badge-success',
  cancelled: 'badge-danger'
}
