import axios from 'axios'
import { config } from '../constants/api'

export default {
  async annul(invoiceId, errandDocumentId) {
    const path = `/invoices/${invoiceId}/annul.json`
    const params = {
      errand_document_id: errandDocumentId
    }
    return axios.put(path, params, config)
      .then((res) => res.data)
      .catch((error) => error.response)
  }
}
