<template>
  <section>
    <strong>{{ t('activerecord.attributes.inventory_item.search_by') }}</strong>
    <p class="mb-2">{{ t('calendar.search_instruction') }}</p>
    <searcher
      @onSearch="onSearch"
      input-width="480px"
      :input-placeholder="t(`calendar.text_placeholder`)"
    />
  </section>
</template>
<script>
import Searcher from '../../../dynamics_form/Searcher.vue'
import i18n from "../../../mixins/i18n"

export default {
  components: { Searcher },
  name: 'searcher-calendar',
  mixins: [i18n],
  props: {
    searchCalendar: {
      type: Function,
      default: () => {}
    }
  },
  methods: {
    onSearch(text) {
      this.$emit('searchCalendar', text)
    }
  }
}
</script>
