<template>
    <b-form-group v-slot="{ ariaDescribedby }">
      <strong>{{ `${t(`activerecord.attributes.inventory_item.search_by`)}` }}</strong>
      <p class="mb-2">{{ searchInstruction}}</p>
      <b-form-radio-group
        v-model="selected"
        :options="options"
        :aria-describedby="ariaDescribedby"
        name="radio-inline"
      ></b-form-radio-group>
    </b-form-group>
</template>

<script>
import i18n from "../mixins/i18n"

export default {
  name: "filter-scope",
  mixins: [i18n],
  props: {
    searchInstruction: {
      type: String,
      default: ''
    },
    isInventory: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      selected: '',
      options: this.isInventory ? [
          { text: this.t('activerecord.attributes.inventory_item.by_operation'), value: 'operation_code' },
          { text: this.t('activerecord.attributes.inventory_item.by_inventary'), value: 'inventory_number' },
          { text: this.t('activerecord.attributes.inventory_item.by_simulation'), value: 'simulation_id' }
        ] : [
          { text: this.t('activerecord.attributes.inventory_item.by_operation'), value: 'operation_code' },
          { text: this.t('activerecord.attributes.inventory_item.by_simulation'), value: 'simulation_id' }
      ]
    }
  },
  methods: {
    defaultSelect(){
      this.selected = ''
    }
  },
  watch: {
    selected(_newValue, _oldValue) {
      this.$emit('onFilterScope', _newValue)
    }
  }
}
</script>

