<template>
  <section>
    <b-table
      id="calendar_table"
      responsive
      :items="meetingList"
      :fields="fields"
      :per-page="perPage"
      :current-page="currentPage"
      ref="selectableTable"
      :select-mode="selectCalendarMode"
      :selectable="fields[0] === 'selected'"
      @row-selected="onRowCalendarSelected"
    >
      <template #head(selected)>
        <b-form-checkbox
          class="select_column"
          ref="select_column"
          v-model="allSelected"
          :indeterminate="indeterminateCheck"
          @change="toggleAll" />
      </template>
      <template #head(operation)>{{ t('views.number_of_operation') }}</template>
      <template #head(client)>{{ t('views.client') }}</template>
      <template #head(product)>{{ t('views.product') }}</template>
      <template #head(pipedriveStage)>
        <div class="inline-block">
          <span class="flex align-items-center">
            {{ t('views.stage') }}
            <img src="/assets/pipedrive_logo.png" alt="pipedrive" width="16" height="16" class="ml-2">
          </span>
        </div>
      </template>
      <template #head(createdAt)>
        <div class="inline-block">
          <span class="flex align-items-center">
            {{ t('views.creation') }}
            <img src="/assets/calendly.png" alt="calendly" width="16" height="16" class="ml-2">
          </span>
        </div>
      </template>
      <template #head(startTime)>
        <div class="inline-block">
          <span class="flex align-items-center">
            {{ t('views.meeting') }}
            <img src="/assets/calendly.png" alt="calendly" width="16" height="16" class="ml-2">
          </span>
        </div>
      </template>
      <template #head(responsible)>{{ t('views.responsible') }}</template>
      <template #head(details)>{{ t('views.details') }}</template>
      <template #head(actions)>{{ t('views.actions') }}</template>

      <template #cell(selected)="{ rowSelected }">
        <template v-if="rowSelected">
          <b-form-checkbox class="select_column" ref="select_column" checked="true" disabled/>
        </template>
        <template v-else>
          <div ref="select_column"></div>
          <b-form-checkbox class="select_column" ref="select_column" disabled/>
        </template>
      </template>
      <template #cell(operation)="data">
        <a v-if="data.value" :href="`./operations/${data.value.id}`" class="text-left">
          <span class="highlight_title">{{ data.value.id }}</span>
          <span class="highlight_sub_title font-weight-normal">
            {{ `${t('views.signature')} ${data.value.signatureType}` }}
          </span>
        </a>
        <a v-else-if="data.item.simulationId"
          :href="`./simulations/${data.item.simulationId}`"
          class="text-left">
          <span class="highlight_title">{{ data.item.simulationId }}</span>
        </a>
      </template>
      <template #cell(client)="data">
        <span class="two_line_info">{{ data.value.rut }}<br> {{ data.value.name }}</span>
      </template>
      <template #cell(pipedriveStage)="data">
        <a v-if="data.value" :href="data.item.pipedriveUrl">
          {{ t(`pipedrive.stage.${data.item.pipedriveStage}`) }}
        </a>
      </template>
      <template #cell(createdAt)="data">
        {{ t('views.days_ago', {days: daysAgo(new Date(data.value))}) }}
      </template>
      <template #cell(startTime)="data">
        {{ setStartTime(data.value) }}
      </template>
      <template #cell(responsible)="data">{{ data.value.name }}</template>
      <template #cell(details)="data">
        <div class="flex-center">
          <b-button variant="link" @click="onOpenModal(data.item)">{{ t('views.show') }}</b-button>
        </div>
      </template>
      <template #cell(actions)="data">
        <div class="flex-center">
          <button class="button button-negative button-sm" @click="onOpenModal(data.item, true)">
            {{ t('views.edit') }}
            <em class="fas fa-edit ml-1"></em>
          </button>
        </div>
      </template>
    </b-table>

    <div class="flex-vertical-center row-sm-block mtp-20">
      <per-page :per-page.sync="perPage" />
      <b-pagination
        class="sm-center"
        style="margin-bottom: unset"
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        aria-controls="calendar_table"
        prev-text="‹ Anterior"
        next-text="Siguiente ›"
        hide-goto-end-buttons
        hide-ellipsis>
      </b-pagination>
    </div>
  </section>
</template>
<script>
import PerPage from '../../../dynamics_form/PerPage.vue'
import i18n from "../../../mixins/i18n"
import dateFormat from "../../../mixins/dateFormat"
import { TYPES_DATE_FORMATS } from '../../../../constants/date'

export default {
  name: 'calendar-table',
  components: { PerPage },
  mixins: [i18n, dateFormat],
  props: {
    meetingList: Array,
    rows: 0,
    openModal: Function,
    canEdit: Boolean
  },
  data() {
    return {
      fields: [
        'selected', 'operation', 'client',
        { key: 'product', sortable: true, class: 'text-center' },
        { key: 'pipedriveStage', sortable: true, class: 'text-center' },
        { key: 'createdAt', class: 'text-center' },
        { key: 'startTime', class: 'text-center' },
        { key: 'responsible', sortable: true },
        { key: 'details', class: 'text-center' },
      ],
      currentPage: 1,
      perPage: 10,
      selectCalendarMode: 'multi',
      allSelected: false,
      indeterminateCheck: false,
      calendarsSelected: [],
    }
  },
  methods: {
    onRowCalendarSelected(items) {
      this.calendarsSelected = items
      if (this.calendarsSelected.length === 0) {
        this.indeterminateCheck = false
        this.allSelected = false
      } else if (this.calendarsSelected.length === this.perPage || this.calendarsSelected.length === this.meetingList.length ) {
        this.indeterminateCheck = false
        this.allSelected = true
      } else {
        this.indeterminateCheck = true
        this.allSelected = false
      }
    },
    getItemsSelected() {
      return this.calendarsSelected
    },
    toggleAll(checked) {
      checked ? this.$refs.selectableTable.selectAllRows() : this.$refs.selectableTable.clearSelected()
    },
    onOpenModal(item, edit = false) {
      this.$emit('openModal', item, edit)
    },
    setStartTime(date) {
      return this.formatDate(new Date(date), TYPES_DATE_FORMATS.DD_MM_HH_MIN)
    },
    deselectAll() {
      this.$refs.selectableTable.clearSelected()
    }
  },
  mounted() {
    if (this.canEdit) this.fields.push({ key: 'actions', class: 'text-center' })
  }
}
</script>
