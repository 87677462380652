<template>
  <div class="mb-4 mt-4">
    <h4 class="title t2">{{ t(`modal_mass_edit_base_state.history_changes_errands`) }}</h4>

    <div class="text-center">
      <b-spinner class="color-main-blue" v-if="isLoading"  label="Large Spinner"></b-spinner>
    </div>

    <div v-if="(history || []).length > 0 && !isLoading">
      <div class="table-responsive mtp-12 mb-4">
        <table class="table b-table" aria-describedby="audits table">
          <thead>
            <tr>
              <th>{{ t('change_history.date') }}</th>
              <th>{{ t('change_history.responsible') }}</th>
              <th>{{ t('change_history.change') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(audit, index) in history" :key="audit.id">
              <td>
                {{ audit.created_at }}
              </td>
              <td>
                {{ audit.user_full_name }}
              </td>
              <td>
                <div v-if="isErrandStatus(audit.auditable_type)" class="flex align-items-center" v-html="formatPreviewsSubState(index, audit)"></div>
                <div v-if="isErrandDocument(audit.auditable_type) && audit.action !== 'destroy'">
                  <p v-for="change, nameF in audit.audited_changes" :key="nameF">
                    {{ t(`activerecord.attributes.errand.errand_document.${nameF}`) }} {{ formatAuditedChanged(change, audit.action) }}
                  </p>
                </div>
                <div v-if="!isErrandStatus(audit.auditable_type) && !isErrandDocument(audit.auditable_type) && audit.action !== 'destroy'">
                  <p v-for="change, nameF in audit.audited_changes" :key="nameF">
                    {{ t(`activerecord.attributes.errand.${nameF}`) }} {{ formatAuditedChanged(change, audit.action) }}
                  </p>
                </div>
                <div v-if="audit.action === 'destroy'">
                  <p>
                    {{ t('views.destroy_element', { model_name: t(`activerecord.models.${audit.auditable_type}`), name: audit.audited_changes.name }) }}
                  </p>
                </div>

                <p v-if="hasComment(audit.comment)"><strong>Comentario:</strong> {{ audit.comment }}</p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="col-md-5 p-0 m-0 text-center" v-if="(history || []).length === 0 && !isLoading">
      <p class="associated-link-container">{{ t('change_history.not_history') }}</p>
    </div>
  </div>
</template>

<script>
import i18n from "../mixins/i18n"
import SelectInput from '../dynamics_form/SelectInput.vue';

export default {
  components: { SelectInput },
  name: "change_history",
  mixins: [i18n],
  props: {
    getHistoryFunction: {
      type: Function,
      default: (_id) => { console.log('function not implemented yet')}
    },
    modelId: 0,
  },
  data() {
    return {
      history: [],
      isLoading: true
    }
  },
  methods: {
    isErrandStatus(errandableType) {
      return errandableType == 'ErrandStatus'
    },
    isErrandDocument(errandableType) {
      return errandableType == 'ErrandDocument'
    },
    formatAuditedChanged(change, action) {
      if(Array.isArray(change) && action !== 'create')
        return `${this.t('views.changed_from')}: ${change[0] || this.t('views.empty')} ${this.t('views.to')}: ${change[1] || this.t('views.empty')}`
      else if(typeof change == 'number')
        return `${this.t('views.nro')} ${change}`
      else
        return `${this.t('views.is')} ${change || this.t('views.empty')}`
    },
    formatPreviewsSubState(index, currentAudit) {
      let previesSubStatus = ''

      if(index+1 !== this.history.length)
        for (let i = index+1; i < this.history.length; i++) {
          let audit = this.history[i];

          if(audit.auditable_type !== 'ErrandStatus') continue;

          previesSubStatus = this.subStatusChangeFormatting(audit)

          break;
        }

      return ` <div> ${previesSubStatus} </div>

               ${previesSubStatus ? '<i class="ml-3 mr-3 fas fa-arrow-right"></i>' : ''}

               <div> ${this.subStatusChangeFormatting(currentAudit)} </div>
             `
    },
    subStatusChangeFormatting(audit){
      return `
              <strong> ${this.t('activerecord.models.sub_status_type')}: </strong> ${ audit.auditable.sub_status_name }
                <br/>
              <strong> ${this.t('activerecord.models.base_status')}: </strong> ${ audit.auditable.general_status_name }
             `
    },
    getHistory: async function() {
      this.isLoading = true
      this.history = await this.getHistoryFunction(this.modelId)
      this.isLoading = false
    },
    hasComment(comment) {
      return !!comment
    }
  },
  mounted: async function() {
    await this.getHistory()
  },
};
</script>
