<template>
  <section>
    <select-list
      ref="now_date"
      :item-text="t(`filter.until_today`)"
      :item-value="defaultDate"
      :is-deselectable="false"
    />
    <select-list
      ref="options_date"
      :item-text="`${t('filter.until')} ${textSelectedDate}`"
      :item-value="selectedDateList"
      @deselectItem="onRemoveDateFilter"
    />
  </section>
</template>
<script>
import SelectList from '../../../dynamics_form/SelectList.vue';
import SelectOptions from '../../../dynamics_form/SelectOptions.vue'
import dateFormat from "../../../mixins/dateFormat"
import i18n from "../../../mixins/i18n"

export default {
  name: 'selected-filters',
  mixins: [i18n, dateFormat],
  components: { SelectOptions, SelectList },
  props: {
    selectedDate: String,
    removeDateFilter: Function
  },
  data() {
    return {
      selectedDateList: this.selectedDate,
      defaultDate: new Date().toLocaleDateString('en-CA')
    }
  },
  methods: {
    onRemoveDateFilter() {
      this.$emit('removeDateFilter')
      this.$refs.now_date.selectItem()
    },
  },
  computed: {
    textSelectedDate() {
      return !this.selectedDateList || this.DDMonth(new Date(this.selectedDateList))
    }
  },
  watch: {
    selectedDate(newValue) {
      this.selectedDateList = newValue
      this.$refs.options_date.selectItem()
      this.$refs.now_date.deselectItem()
    }
  },
  mounted() {
    this.$refs.now_date.selectItem()
  }
}
</script>
