<template>
  <div>
    <div v-if="canCreate" class="text-right mb-4">
      <button v-b-modal.select-types class="btn btn-sm text-primary" type="button" >
        {{t('errand_type.select_types')}}
        <span class="fa fa-file-alt mr-1" aria-hidden="true"> </span>
      </button>

      <button class=" btn btn-sm text-primary" type="button" @click="setErrandType(null)" >
        {{t('errand_type.adds_type')}}
        <span class="fa fa-plus-circle mr-1" aria-hidden="true"> </span>
      </button>
    </div>

    <div v-if="!loading">
      <b-alert v-if="hasErrors" show variant="danger">
        <ul>
          <li v-for="message in errorMessages" :key="message">{{ message }}</li>
        </ul>
      </b-alert>
      <table v-if="errandTypes.length > 0" class="table b-table" aria-describedby="errand types table">
        <thead>
          <tr>
            <th> {{t('activerecord.attributes.errand_type.name')}} </th>
            <th> {{t('activerecord.attributes.errand_type.errand_triggers')}} </th>
            <th> {{t('activerecord.attributes.errand_type.limit_days')}} </th>
            <th> {{t('activerecord.attributes.errand_type.sub_statuses')}} </th>
            <th></th>
          </tr>
        </thead>
        <tbody >
          <tr v-for="errandType in errandTypes" :key="errandType.id" >
            <td>
              <span>{{errandType.name}}</span>
              <p class="small primary-100" v-if="errandType.has_template">{{ t(`errand_type.errand_template_tag`) }}</p>
            </td>
            <td>
              <ol>
                <li v-for="errand_trigger in errandType.errand_triggers_attributes" :key="errand_trigger.id">
                  {{ getTriggerName(errand_trigger.triggerable_type, errand_trigger.state) }} - {{ errand_trigger.sub_status_name }} {{ errand_trigger.associated_document }}
                </li>
              </ol>
            </td>
            <td> {{errandType.limit_days}} </td>
            <td>
              <ol>
                <li v-for="(subStatus) in errandType.sub_statuses_attributes" :key="subStatus.id">
                  {{subStatus.order}}. {{subStatus.name}} - {{ subStatus.general_status.name }} - {{ subStatus.limit_days || 0 }} {{t('views.day')}}
                </li>
              </ol>
            </td>
            <td>
              <button @click="setErrandType(errandType)" type="button" class="btn btn-sm btn-outline-primary">
                <span class="fa fa-pen" aria-hidden="true"> </span>
              </button>
              <button @click="deleteErrandType(errandType.id)" type="button" class="btn btn-sm btn-outline-danger">
                <span class="fa fa-trash" aria-hidden="true"> </span>
              </button>
            </td>

          </tr>
        </tbody>
      </table>
      <div v-else class="pb-4">
        <button v-if="canCreate" class=" btn btn-sm btn-outline-primary w-100" type="button" @click="setErrandType(null)" >
        {{t('errand_type.adds_type')}}
          <span class=" fa fa-plus-circle mr-1" aria-hidden="true"> </span>
        </button>
        <p v-else>
          {{t('errand_type.empty')}}
        </p>
      </div>
    </div>
    <div class="text-center" v-else>
      <b-spinner variant="primary" />
    </div>

    <b-modal size="xl" :hide-footer="true" content-class="w-100" id="new-errand" :title="t('errand_type.create_message', {model_name: t('activerecord.models.operation').toLowerCase()}) ">
      <errand-type-form
        :title="t('activerecord.models.operation')"
        :errand-type="errandType"
        :model-id="modelId"
        :model-name="model"
        @update="update"
      ></errand-type-form>
    </b-modal>

    <b-modal size="xl"  content-class="w-100" id="select-types" :title="t('errand_type.select_types')">
      <div class="row">
        <div class="col-12">
          <b-form-checkbox-group
            id="checkbox-group-1"
            v-model="errandTypeIds"
            aria-describedby="ariaDescribedby"
            name="errand_type_ids"
            stacked
          >
            <b-form-checkbox v-for="errandType in allErrandTypes" :key="errandType.id" :value="errandType.id">{{errandType.name}}</b-form-checkbox>
          </b-form-checkbox-group>
        </div>
      </div>
      <template #modal-footer="{ cancel }">
        <b-button size="sm" variant="secondary" @click="cancel()">
          {{t('views.cancel')}}
        </b-button>

        <b-button size="sm" variant="success" @click="submitErrandTypes">
          {{t('views.save')}}
        </b-button>
      </template>
    </b-modal>

  </div>
</template>


<script>
  import i18n from "../mixins/i18n"
  import errandsAPI from '../../api/errands'
  import productTypesAPI from '../../api/product_types'
  import { TARGETSTATUSES } from '../../constants/errands'

  export default {
    name: 'errand-type-list',
    mixins: [i18n],
    components: {
    },
    props: [
      'canCreate',
      'model',
      'modelId'
    ],
    data() {
      return {
        errandTypes: [],
        loading: false,
        errandType: null,
        statuses: TARGETSTATUSES,
        allErrandTypes: [],
        errandTypeIds: [],
        hasErrors: false,
        errorMessages: []
      }
    },
    mounted: async function() {
      this.loading = true
      this.errandTypes = await errandsAPI.list(this.model, this.modelId)
      this.allErrandTypes = await errandsAPI.list(null, null)
      this.errandTypeIds = this.errandTypes.map(val => val.id)
      this.loading = false
    },
    methods: {
      setErrandType(errand) {
        this.errandType = errand
        this.$bvModal.show('new-errand')
      },
      update: async function(){
        this.errandTypes = await errandsAPI.list(this.model, this.modelId)
      },
      getTriggerName(triggerableType, state) {
        if(!triggerableType) return ''
        if(triggerableType === 'errand_type') return this.errandTypes.find(val => val.id == state)?.name || ''

        const name = this.statuses[this.model][triggerableType].find(val => val.value === state)?.text || ''
        return name
      },
      submitErrandTypes: async function(){
        this.loading = true
        this.hasErrors = false
        this.errorMessages = []

        let resp = await productTypesAPI.update(this.modelId, {
          product_type: { errand_type_ids: this.errandTypeIds }
        })

        if(resp.status === 422){
          this.hasErrors = true
          this.errorMessages = Object.values(resp.data).reduce((acc, curr) => {
            let errors = acc.concat(curr)
            return errors
          }, [])
        }

        this.$bvModal.hide('select-types')
        this.errandTypes = await errandsAPI.list(this.model, this.modelId)
        this.loading = false
      },
      deleteErrandType: async function(id){
        this.errandTypeIds = this.errandTypeIds.filter(val => val !== id)
        await this.submitErrandTypes()
      }
    },

  }
</script>
