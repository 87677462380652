<template>
  <div>
    <div v-if="bankAccounts.length">
      <BankAccountSelector
        class="my-2"
        :bankAccounts="bankAccounts"
        :bankAccountSelected="selected"
        @onSelectedBankAccount="handleBankAccount"
      />
      <b-button v-if="hasOnSaveButtonListener" @click="handleSaveButton" variant="primary">
        Confirmar
      </b-button>
      <div v-if="showNewSubscription" class="d-flex justify-content-between align-items-center">
        <span>Para agregar una nueva suscripción copia y envía al cliente el siguiente link</span>
        <label
          @click="copyPacTugboatLink"
          class="button button-secondary button-sm copy-tugboat-link__button mt-2"
          v-bind:class="{'bg-success': copiedLink}"
        >
          {{ copiedLink ? '¡Copiado!' : 'Copiar link' }}
        </label>
      </div>
    </div>
    <div v-if="!bankAccounts.length" class="d-flex flex-column align-items-center">
      <em class="fas fa-thumbs-down fa-6x mb-4"></em>
      <h5>Este cliente no tiene cuentas registradas</h5>
      <h5>Pueba la suscripción con Fintoc, copiando y enviando al cliente el siguiente link</h5>
      <label
        @click="copyPacTugboatLink"
        class="button button-secondary button-sm copy-tugboat-link__button mt-4"
        v-bind:class="{'bg-success': copiedLink}"
      >
        {{ copiedLink ? '¡Copiado!' : 'Copiar link' }}
      </label>
    </div>
  </div>
</template>
<style>
  .copy-tugboat-link__button {
    min-width: 130px;
  }

  .copy-tugboat-link__button:focus {
    outline: none;
    box-shadow: none;
  }
</style>
<script>
import BankAccountSelector from './BankAccountSelector.vue';
import { copy } from '../../plugins/clipboard'

export default {
  name: "pac-selector",
  components: { BankAccountSelector },
  props: {
    clientId: Number,
    selected: {
      type: Object
    },
    tugboatUrl: String,
    bankAccounts: {
      type: Array,
      required: true
    },
    showNewSubscription: {
      type: Boolean,
      default: false
    }
  },
  data: () => {
    return {
      copiedLink: false,
    };
  },
  computed:{
    hasOnSaveButtonListener(){
      return Boolean(this.$listeners && this.$listeners.onSaveButton)
    }
  },
  methods: {
    copy,
    handleBankAccount(item) {
      this.$emit('onSelectedPac', item);
    },
    handleSaveButton() {
      this.$emit('onSaveButton');
    },
    copyPacTugboatLink() {
      this.copy(this.tugboatUrl);

      this.copiedLink = true;
      setTimeout(() => this.copiedLink = false, 500);
    },
  }
};
</script>
