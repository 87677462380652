<template>
  <b-modal id="total-check-approved" size="lg">
    <template #modal-title>
      <h4 class="mb-12">{{ t(`total_check.manage_approved`) }}</h4>
    </template>
    <div class="mb-12">
      <small class="error-100" v-if="errors.clientInformation">{{ t(`total_check.errors.client_information`) }}</small>
      <div class="flex">

        <b-form-file
          :disabled="errandFileInputDisabled"
          v-model="errandFile"
          :placeholder="t('errand.doc_errand_placeholder')"
          drop-placeholder="Drop file here..."
          accept="image/jpeg, image/jpg, image/png, .pdf"
          :browse-text="t('views.search').toUpperCase()"
          class="button button-dashed button-sm file rounded"
          ref="errand-input"
        />
      </div>
    </div>
    <template #modal-footer>
      <button class="button button-secondary" @click="$bvModal.hide('total-check-approved')">{{ t(`views.cancel`) }}</button>
      <button class="button button-primary" @click="upload">{{ t(`total_check.confirm`) }}</button>
    </template>
  </b-modal>
</template>

<script>
import i18n from "../mixins/i18n"
import errandsApi from '../../api/errands'

export default {
  name: 'updaload-approved-total-check',
  mixins: [i18n],
  props: {
    errandId: {
      type: Number,
      default: null
    },
  },
  data() {
    return {
      documents: {},
      errandFile: null,
      selectedDocumentIndex: null,
      errors: {
        file: false,
        clientInformation: false
      }
    }
  },
  mounted: async function() {
  },
  methods: {
    async upload() {
      if(!this.errandFile) return

      this.errandFileInputDisabled = true
      let res = await errandsApi.uploadApproved(this.errandFile, this.errandId)

      if(res.id){
        this.errandFiles.push(res)
        this.$emit('processRequested')
      }

      this.errandFileInputDisabled = false
      this.$refs['errand-input'].reset()
      this.$bvModal.hide('total-check')
    }
  }
}
</script>
