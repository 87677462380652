<template>
  <div v-if="hasWarnings" class="-mt-4 -mx-4 mb-1 text-center text-xs text-lt rounded-t border-yellow-400 bg-yellow-400">
    Falta información
  </div>
</template>
<script>
import api from '../../api/product_types'
import 'tailwindcss/dist/base.min.css'
import 'tailwindcss/dist/components.min.css'
import 'tailwindcss/dist/utilities.min.css'
import 'tailwindcss/dist/tailwind.min.css'

export default {
  name: 'missing-info',
  props: [
    'element'
    ],
    data: function() {
      return {
        warnings: [],
        templateError: false,
        hasWarnings: false,
      }
    },
  methods: {
    getProductType: async function() {
      const params = {
        simulation_id: this.element.id,
        product_type_id: this.element.product_type.id,
        simulation_type: this.element.simulation_type
      }

      return await api.documentTemplateWarnings(params)
    },
    productTypeWarnings: async function() {
      const document_type = await this.getProductType()
      if(_.isNil(document_type.document_templates_warning)) {
        this.templateError = true
        this.hasWarnings = true
      } else {
        this.warnings = document_type.document_templates_warning
        this.hasWarnings = this.warnings.length > 0
      }
      this.$emit('updatedWarning', this.hasWarnings)
    }
  },
  computed: {
    isEmpty: function() {
      return this.warnings.length === 0
    }
  },
  mounted: function() {
    // removing this tag in order to improve performance
    // until we have time to make this request light or cached.
    // this.productTypeWarnings()
  }
}
</script>
