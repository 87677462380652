import I18n from 'i18n-js'

I18n.defaultLocale = 'es'
I18n.locale = 'es'

export default {
  methods: {
    t: (...args) => I18n.t(...args),
    toNumber: (...args) => I18n.toNumber(...args),
    toCurrency: (...args) => I18n.toCurrency(...args),
    toPercentage: (...args) => I18n.toPercentage(...args),
    currentLocale: () => I18n.currentLocale()
  }
}
