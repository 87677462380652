<template>
  <div class="d-inline">
    <span v-b-modal.mergeLocation class="btn text-primary" >
      {{t('inventory_location.merge_items')}}
      <span> <em class="far fa-edit ml-1"></em></span>
    </span>

    <b-modal id="mergeLocation" size="lg">
      <template #modal-title>
        <span class="title t1">{{ t('inventory_location.merge_items') }}</span>
      </template>
      <br>
      <p class="text-muted">{{ t('inventory_location.merge_info') }}</p>
      <br>
      <div>
        <div class="table-responsive mtp-12">
          <table class="table b-table" aria-describedby="merge-inventory-items-location">
            <thead>
              <tr>
                <th>{{ t('inventory_location.current_location') }}</th>
                <th></th>
                <th>{{ t('inventory_location.change_to') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <select-input
                    :options="locationsList"
                    :placeholder="`${t('inventory_location.select_location')}`"
                    value-field="id"
                    text-field="name"
                    input-width="auto"
                    @onChange="selectCurrentLocation"
                  />
                </td>
                <td><em class="fas fa-arrow-right" v-bind:style="styleArrow"></em></td>
                <td>
                  <select-input
                    :options="locationsList"
                    :placeholder="`${t('inventory_location.select_location')}`"
                    value-field="id"
                    text-field="name"
                    input-width="auto"
                    @onChange="selectNewLocation"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <b-form-checkbox
          v-model="deleteCurrentLocation"
          id="delete_current_location_checkbox"
        >
          <span class=text-muted>{{ t('inventory_location.delete_current')}}</span>
        </b-form-checkbox>
      </div>
      <template #modal-footer>
        <button class="footer-button button button-secondary" @click="$bvModal.hide('mergeLocation')">{{ t(`views.cancel`) }}</button>
        <button class="footer-button button button-primary" @click="merge" :disabled="!canMerge">{{ t(`inventory_location.merge`) }}</button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import i18n from "../mixins/i18n"
import SelectInput from '../dynamics_form/SelectInput.vue';
import inventoryApi from '../../api/inventory';

export default {
  components: { SelectInput },
  name: 'inventory-location-merge',
  mixins: [i18n],
  props: {
    locationsList: Array
  },
  data() {
    return {
      styleArrow: {
        color: '#484848',
        fontSize: '16.5px'
      },
      currentLocationId: null,
      newLocationId: null,
      deleteCurrentLocation: false
    }
  },
  methods: {
    selectCurrentLocation(value) {
      this.currentLocationId = value
    },
    selectNewLocation(value) {
      this.newLocationId = value
    },
    merge: async function() {
      if( !this.canMerge) { return }

      let response = await inventoryApi.mergeLocations(this.currentLocationId, this.newLocationId)

      if (this.deleteCurrentLocation) { await inventoryApi.deleteInventoryLocation(this.currentLocationId) }
      if(response.ok) { location.reload() }
    }
  },
  computed: {
    canMerge() {
      return this.currentLocationId != null && this.newLocationId != null
    }
  }
}
</script>
