<template>
  <section>
    <strong>{{ t('filter.filter_by') }}</strong>
    <div class="flex align-items-center mt-2">
      <date-filter
        ref="data_filter"
        @onSubmitDateFilter="onSubmitDateFilter"/>
      <div class="vertical-line"></div>
      <dropdown-filter
        :items="stageList"
        :text="t('activerecord.attributes.simulation.stage')"
        @onFilter="onStageFilter">
        <template slot="content-button">
          <img src="/assets/pipedrive_logo.png" alt="pipedrive" width="16" height="16" class="ml-2">
        </template>
      </dropdown-filter>
      <div class="vertical-line"></div>
      <select-options
        ref="options_my_calendars"
        :list="[t('calendar.assigned_to_me')]"
        :items-selected.sync="selectedMyCalendars"
        @onSelect="onSelectMyCalendars"/>
    </div>
  </section>
</template>
<script>
import DropdownFilter from '../../../../dynamics_form/DropdownFilter.vue'
import SelectOptions from '../../../../dynamics_form/SelectOptions.vue'
import i18n from '../../../../mixins/i18n'
import DateFilter from './compnents/DateFilter.vue'

export default {
  components: { DateFilter, DropdownFilter, SelectOptions },
  name: 'filters-calendar',
  mixins: [i18n],
  props: {
    stageList: Array,
    stageFilter: Function,
    selectMyCalendars: Function,
    submitDateFilter: Function
  },
  data() {
    return {
      selectedMyCalendars: [],
    }
  },
  methods: {
    onStageFilter(selectedItems) {
      this.$emit('stageFilter', selectedItems)
    },
    onSelectMyCalendars() {
      this.$emit('selectMyCalendars', this.selectedMyCalendars)
    },
    onSubmitDateFilter(selected) {
      this.$emit('submitDateFilter', selected)
    },
    cleanDateFilter() {
      this.$refs.data_filter.cleanFilter()
    },
  }
}
</script>
<style scoped>
.filter-container {
  margin-top: 20px;
}
</style>
