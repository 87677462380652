<template>
  <div>
    <div class="col-12">
      <div class="card shadow mb-4">
        <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
          <h6 class="m-0 font-weight-bold text-primary">Pacs</h6>
          <b-button variant="primary" v-b-modal.pac-modal>Agregar PAC</b-button>
        </div>
        <div class="card-body">
          <b-table striped hover :items="pacs" :fields="fields"></b-table>
        </div>
      </div>
    </div>

    <b-modal ref="pac-modal" id="pac-modal" no-close-on-backdrop title="Agregar PAC" on-only ok-title="Aceptar">
      <template v-for="(field, index) in fields" v-slot:[`cell(${field.key})`]="data">
        <div v-if="field.key === 'content_type'">
          <b-button variant='primary' @click="editPac(data.item)" v-b-modal.pac-modal-edit>Editar</b-button>
        </div>
        <div v-else-if="field.key === 'account_number'">{{data.item.account_number}}</div>
        <div v-else-if="field.key === 'amount'">{{data.item.amount}}</div>
        <div v-else-if="field.key === 'payday_at'">{{data.item.payday_at}}</div>
        <div v-else-if="field.key === 'state'">{{data.item.state}}</div>
      </template>
    </b-modal>
  </div>
</template>
<script>
  import _ from 'lodash';
  import pacApi from '../../api/pacs';

  export default {
    components: {},
    props: {
    },
    data() {
      return {
        fields: [ 
          {
            key: 'client_id',
            label: 'Cliente',
            sortable: true
          },
          {
            key: 'identifier',
            label: 'Identificador',
            sortable: true
          }, 
          {
            key: 'amount',
            label: 'Monto',
            sortable: true
          },
          {
            key: 'payday_at',
            label: 'Fecha cargo',
            sortable: true
          },
          {
            key: 'account_number',
            label: 'Cuenta',
            sortable: true
          },
          {
            key: 'state',
            label: 'Estado',
            sortable: true
          }
        ],
        pacs: [],
      }
    },
    methods: {
      getPacs() {
        pacApi.getPacs()
          .then(data => {
            this.pacs = data;
          })
      }
    },
    mounted() {
      this.getPacs();
    }
  }
</script>
