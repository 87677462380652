<template>
  <b-card header-class="subtitle neutral-70 flex justify-content-between" header-tag="header">
    <template #header>
      <div>
        {{ t("activerecord.attributes.credit_document.credit_documents") }}
      </div>

      <div>
        <b-button v-b-modal.regenerate_modal class="button button-negative button-sm flex-center" >
          {{ t('digital_sign.regenerate') }}
        </b-button>

        <b-button @click="updateDocsStatus" :disabled="checkingStatus" class="button button-negative button-sm flex-center" >
          {{ t('digital_sign.check_status_button') }}
          <b-spinner v-if="checkingStatus" />
        </b-button>
      </div>

      <div>
        <select-input
          ref="signTypeInput"
          :options="SignTypeList"
          :defaultValue="selectedSignType"
          placeholder="Tipo de firma"
          value-field="value"
          text-field="text"
          input-width="auto"
          @onChange="setSignType"
        />
      </div>
    </template>


    <div class="position-relative">
      <div v-if="isLoadingSignType" class="spinner-override flex justify-content-center align-items-center">
        <b-spinner variant="primary" size="lg" />
      </div>

      <div v-if="(documentsWithoutSign.length > 0 && showDocumentWithoutSign === 'true')">
        <table class="table"> <!-- Noncompliant -->
          <thead>
            <tr>
              <th>
                {{ t("digital_sign.sign_type.no_signature") }}
              </th>
              <th>
                {{ t('digital_sign.original') }}
              </th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="(document) in documentsWithoutSign" :key="document.id">
              <td class="two_line_info">
                {{ document.name }}
              </td>
              <td>
                <a :href="document.document_url" class="blue-color" target="_blank" download :disabled="!document.document_url">
                  {{ t('digital_sign.download') }} <span class="fa fa-download mr-1" aria-hidden="true"></span>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div v-if="manualHolderDocuments.length > 0">
        <table class="table"> <!-- Noncompliant -->
          <thead>
            <tr>
              <th>
                {{ t("digital_sign.table_doc_title", { signer_type: 'Titular' }) }}
                <br/>
                <span class="fs-10pt font-weight-lighter">{{ t('digital_sign.manual_sign') }}</span>
              </th>
              <th>
                {{ t("activerecord.attributes.credit_document.status") }}
              </th>
              <th>
                {{ t('digital_sign.original') }}
              </th>
              <th>
                {{ t('digital_sign.mark_as_signed') }}
              </th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="(document) in manualHolderDocuments" :key="document.id">
              <td class="two_line_info">
                {{ document.name }}
              </td>
              <td>
                <span class="whitespace-no-wrap">
                  <span class="fa fa-minus-circle mr-1 neutral-50" aria-hidden="true"></span>
                  {{ document.status }}
                </span>
              </td>
              <td>
                <a :href="document.document_url" class="blue-color" target="_blank" download :disabled="!document.document_url">
                  {{ t('digital_sign.download') }} <span class="fa fa-download mr-1" aria-hidden="true"></span>
                </a>
              </td>
              <td>
                <b-button class="button button-primary w-max" @click="signManualDoc(document.id, document.holderId)" :disabled="document.documentStatus === 'signed'">
                  <span v-if="!document.loading">
                    {{ document.originalStatus === 'signed' ? 'Firmado' : t("activerecord.attributes.credit_document.sign") }}
                    <em class="fas fa-file-signature ml-2"></em>
                  </span>
                  <b-spinner size="sm" v-else />
                </b-button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div v-if="digitalHolderDocuments.length > 0">
        <table class="table"> <!-- Noncompliant -->
          <thead>
            <tr>
              <th>
                {{ t("digital_sign.table_doc_title", { signer_type: 'Titular' }) }}
                <br/>
                <span class="fs-10pt font-weight-lighter">{{ t('digital_sign.digital_sign') }}</span>
              </th>
              <th>
                {{ t("activerecord.attributes.credit_document.status") }}
              </th>
              <th>
                {{ t('digital_sign.signed') }}
              </th>
              <th>
                {{ t('digital_sign.original') }}
              </th>
              <th>
                {{ t('digital_sign.mark_as_signed') }}
              </th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="(document) in digitalHolderDocuments" :key="document.id">
              <td class="two_line_info">
                {{ document.name }}
              </td>
              <td>
                <span class="whitespace-no-wrap">
                  <span class="fa fa-minus-circle mr-1 neutral-50" aria-hidden="true"></span>
                  {{ document.status }} {{ document.signed_digitally ? 'digital' : ''}}
                </span>
              </td>
              <td>
                <a :href="document.sign_document_url" class="blue-color" target="_blank" download :disabled="!document.sign_document_url">
                  {{ t('digital_sign.download') }} <span class="fa fa-download mr-1" aria-hidden="true"></span>
                </a>
              </td>
              <td>
                <a :href="document.document_url" class="blue-color" target="_blank" download :disabled="!document.document_url">
                  {{ t('digital_sign.download') }} <span class="fa fa-download mr-1" aria-hidden="true"></span>
                </a>
              </td>
              <td>
                <b-button class="button button-primary w-max" @click="signManualDoc(document.id, document.holderId)" :disabled="document.documentStatus === 'signed'">
                  <span v-if="!document.loading">
                    {{ document.originalStatus === 'signed' ? 'Firmado' : t("activerecord.attributes.credit_document.sign") }}
                    <em class="fas fa-file-signature ml-2"></em>
                  </span>
                  <b-spinner size="sm" v-else />
                </b-button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div v-if="digitalGuarantorDocuments.length > 0">
        <table class="table"> <!-- Noncompliant -->
          <thead>
            <tr>
              <th>
                {{ t("digital_sign.table_doc_title", { signer_type: 'Aval' }) }}
                <br/>
                <span class="fs-10pt font-weight-lighter">{{ t('digital_sign.digital_sign') }}</span>
              </th>
              <th>
                {{ t("activerecord.attributes.credit_document.status") }}
              </th>
              <th>
                {{ t('digital_sign.signed') }}
              </th>
              <th>
                {{ t('digital_sign.original') }}
              </th>
              <th>
                {{ t('digital_sign.mark_as_signed') }}
              </th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="(document) in digitalGuarantorDocuments" :key="document.id">
              <td class="two_line_info">
                {{ document.name }}
              </td>
              <td>
                <span class="whitespace-no-wrap">
                  <span class="fa fa-minus-circle mr-1 neutral-50" aria-hidden="true"></span>
                  {{ document.status }}
                </span>
              </td>
              <td>
                <a :href="document.sign_document_url" class="blue-color" target="_blank" download :disabled="!document.sign_document_url">
                  {{ t('digital_sign.download') }} <span class="fa fa-download mr-1" aria-hidden="true"></span>
                </a>
              </td>
              <td>
                <a :href="document.document_url" class="blue-color" target="_blank" download :disabled="!document.document_url">
                  {{ t('digital_sign.download') }} <span class="fa fa-download mr-1" aria-hidden="true"></span>
                </a>
              </td>
              <td>
                <b-button class="button button-primary w-max" @click="signManualDoc(document.id, document.holderId)" :disabled="document.documentStatus === 'signed'">
                  <span v-if="!document.loading">
                    {{ document.originalStatus === 'signed' ? 'Firmado' : t("activerecord.attributes.credit_document.sign") }}
                    <em class="fas fa-file-signature ml-2"></em>
                  </span>
                  <b-spinner size="sm" v-else />
                </b-button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="flex flex-column align-items-center mt-3">
        <div class="flex flex-column align-items-center" v-if="signService === 'gofirmex' && selectedSignType !== 'manually'">
          <span v-if="portfolioSignProccessSuccessMessage" class="bg-success text-light mb-2 p-1 rounded" >{{ portfolioSignProccessSuccessMessage }}</span>
          <span v-if="portfolioSignProccessErrorMessage" class="bg-danger text-light mb-2 p-1 rounded" >{{ portfolioSignProccessErrorMessage }}</span>

          <b-button v-if="canStartSignProccess" class="button button-primary flex-center" v-b-modal.start_sign_procces_modal>
            {{ t('activerecord.attributes.credit_document.send_to_sign') }}
          </b-button>
        </div>
        <div class="flex flex-column align-items-center" v-if="!hasRejectedDocuments && selectedSignType !== 'manually'">
          <span v-if="!canDownloadPortfolio && !canStartSignProccess" class="font-weight-bold text-center mb-2" >
            {{ t('digital_sign.download_message') }}
          </span>
          <b-button v-if="!canStartSignProccess" :href="`/credits/${creditId}/combined_documents_download.pdf`" :disabled="!canDownloadPortfolio" class="button button-primary flex-center" >
            {{ t('digital_sign.bulk_download') }}
          </b-button>

        </div>

        <div v-if="hasRejectedDocuments || signType === 'manually'" class="flex flex-column align-items-center">
          <div class="flex align-items-center text-danger" v-if="hasRejectedDocuments">
            <em class="fas fa-exclamation-triangle mr-3"></em>
            <p class="text-center">
              {{ t('digital_sign.has_rejected_docs') }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <b-modal ref="start_sign_procces_modal" id="start_sign_procces_modal">

      <div class="text-center">
        <h5 class="secondary-color font-weight-bold">
          {{ t('digital_sign.confirm_message') }}
        </h5>

        <p>{{ t('digital_sign.confirm_message_second') }} </p>

        <p class="primary-color fw-bolder"> {{ t('digital_sign.confirm_message_third') }} </p>
      </div>

      <template #modal-footer>
        <div class="flex justify-content-between w-100">
          <button class="button button-secondary mrp-24" @click="$bvModal.hide('start_sign_procces_modal')"> {{ t('digital_sign.cancel') }} </button>
          <button class="button button-primary" @click="signPortfolio">
            <b-spinner v-if="isStartingSignProccess" />
            <span v-else> {{ t('digital_sign.send') }} </span>
          </button>
        </div>
      </template>

    </b-modal>

    <b-modal ref="regenerate_modal" id="regenerate_modal">

      <div class="">
        <h5 class="text-center secondary-color font-weight-bold">
          {{ t('digital_sign.regenerate_title') }}
        </h5>

        <p class="primary-color fw-bolder mt-4">
          {{ t('digital_sign.regenerate_subtitle_message') }}
        </p>

        <div class="mb-4">
          <select-input
            ref="signTypeInput"
            :options="regerateSignTypeList"
            :defaultValue="selectedSignTypeRegenerate"
            placeholder="Tipo de firma"
            value-field="value"
            text-field="text"
            input-width="auto"
            @onChange="setSignTypeRegenerate"
          />
        </div>

        <p class="primary-color fw-bolder ">
          {{ t('digital_sign.regenerate_message') }}
        </p>
      </div>

      <template #modal-footer>
        <div class="flex justify-content-between w-100">
          <button class="button button-secondary mrp-24" @click="$bvModal.hide('regenerate_modal')"> {{ t('digital_sign.cancel') }} </button>
          <button class="button button-primary" @click="regenerateDocs">
            <b-spinner v-if="isRegeratingDocs" />
            <span v-else> {{ t('digital_sign.regenerate_confirm') }} </span>
          </button>
        </div>
      </template>

    </b-modal>

  </b-card>
</template>
<script>
import i18n from "../mixins/i18n"
import capitalize from "../mixins/capitalize"
import SelectInput from '../dynamics_form/SelectInput.vue';
import signAPI from '../../api/digital_sign'
import creditAPI from '../../api/credits'

export default {
  components: { SelectInput },
  name: "digital-sign-card",
  mixins: [i18n, capitalize],
  props: [
    'creditId',
    'documents',
    'signType',
    'canShowDocumentWithoutSign',
  ],
  data() {
    return {
      SignTypeList: [
        {value: 'manually', text: 'Fisica'},
        {value: 'both', text: 'Hibrida'},
        {value: 'digital', text: 'Digital'}
      ],
      regerateSignTypeList: [
        {value: 'manual', text: 'Solo docs fisicos'},
        {value: 'holder', text: 'Docs titular'},
        {value: 'guarantor', text: 'Docs aval'},
        {value: 'all', text: 'Todos'}
      ],
      isLoadingSignType: false,
      selectedSignTypeRegenerate: 'manual',
      selectedSignType: this.signType || 'both',
      signService: 'gofirmex',
      default_sign_methods: [],
      documentItems: this.documents.map((doc) => ({...doc, signType: doc.document_template.sign_type,form: {sucessMessage: null, errorMessage: null }})) || [],
      portfolioSignProccessSuccessMessage: null,
      portfolioSignProccessErrorMessage: null,
      isStartingSignProccess: false,
      isRegeratingDocs: false,
      checkingStatus: false,
      showDocumentWithoutSign: this.canShowDocumentWithoutSign,
    }
  },
  methods: {
    async setSignType(value){
      this.isLoadingSignType = true
      const response = await creditAPI.change_sign_type(this.creditId, value)

      if(response.status === 200){
        this.selectedSignType = value
      } else
        this.$refs['signTypeInput'].setValue(this.selectedSignType)

      this.isLoadingSignType = false

    },
    setSignTypeRegenerate(value) {
      this.selectedSignTypeRegenerate = value
    },
    async regenerateDocs() {
      this.isRegeratingDocs = true

      const response = await creditAPI.regenerate_documents(this.creditId, this.selectedSignTypeRegenerate)
      if(response.status === 200){
        this.portfolioSignProccessSuccessMessage = response.data.message
        const docs = JSON.parse(response.data.docs)
        this.documentItems = docs.map((doc) => ({...doc, signType: doc.document_template.sign_type,form: {sucessMessage: null, errorMessage: null }}))
        this.$bvModal.hide('regenerate_modal')
      } //else
        // show error

      this.isRegeratingDocs = false
    },
    async signManualDoc(docId, holderId){
      let response = await signAPI.signPresentialDoc(docId, holderId, this.creditId)
      if(response.status === 200){
        const docs = JSON.parse(response.data.docs)
        this.documentItems = docs.map((doc) => ({...doc, signType: doc.document_template.sign_type,form: {sucessMessage: null, errorMessage: null }}))
      }
    },
    async signPortfolio(){
      this.isStartingSignProccess = true
      this.portfolioSignProccessSuccessMessage = null
      this.portfolioSignProccessErrorMessage = null

      let response = await signAPI.create_sign_process(this.creditId)

      if(response.status === 200){
        this.portfolioSignProccessSuccessMessage = response.data.message
        const docs = JSON.parse(response.data.docs)
        this.documentItems = docs.map((doc) => ({...doc, signType: doc.document_template.sign_type,form: {sucessMessage: null, errorMessage: null }}))
      }else
        this.portfolioSignProccessErrorMessage = response.data.message

      this.isStartingSignProccess = false
      this.$bvModal.hide('start_sign_procces_modal')

    },
    buildDocumentObject: function(document, acc, holder){
        acc = [...acc, {
          id: document.id,
          holderId: holder?.id,
          name: document.document_template.name,
          status: this.t(`activerecord.attributes.credit_document.status_list.${holder?.status}`),
          documentStatus: document.status,
          originalStatus: holder?.status,
          statusKey: holder?.status,
          signType: document.signType,
          sign_document_url: document.signed_document_url,
          document_url: document.document_url,
          signed_digitally: document?.signed_digitally,
          successMessage: '',
          errorMessage: ''
        }]

        return acc
    },
    async updateDocsStatus(){
      this.checkingStatus = true
      let response = await signAPI.updateDocsStatus(this.creditId)

      if(response.status === 200){
        this.portfolioSignProccessSuccessMessage = response.data.message
        const docs = JSON.parse(response.data.docs)
        this.documentItems = docs.map((doc) => ({...doc, signType: doc.document_template.sign_type,form: {sucessMessage: null, errorMessage: null }}))
      }else
        this.portfolioSignProccessErrorMessage = response.data.message

      this.checkingStatus = false
    }
  },
  mounted: async function() {
    console.log(this.creditId)
    console.log(this.documentItems)

    this.default_sign_methods = await signAPI.default_sign_methods()

    console.log(this.manualHolderDocuments)
    console.log(this.digitalHolderDocuments)
    console.log(this.digitalGuarantorDocuments)
    console.log(this.selectedSignType)
    console.log('showDocumentWithoutSign', this.showDocumentWithoutSign)
  },
  computed: {
    documentsWithoutSign: function() {
      let docs = this.documentItems.reduce((acc, document) => {
        if(document.signType !== 'no_signature' ) return acc

        return this.buildDocumentObject(document, acc, null)
      }, [])
      return docs
    },
    manualHolderDocuments: function() {
      const selectedSignType = this.selectedSignType
      let docs = this.documentItems.reduce((acc, document) => {
      let holder = document.signatures[0]

        if(
            ( selectedSignType === 'manually' && ( document.signType === 'manually' || document.signType === 'both' )) ||
            ( selectedSignType === 'both' && document.signType === 'manually' )
        ) return this.buildDocumentObject(document, acc, holder)

        return acc
      }, [])
      return docs
    },
    digitalHolderDocuments: function() {
      const selectedSignType = this.selectedSignType
      let docs = this.documentItems.reduce((acc, document) => {
        let holder = document.signatures.find((value) => !value.is_aval)

        if( holder &&
            (
              ( (['digital', 'both']).includes(selectedSignType) && ( document.signType === 'digital' || document.signType === 'both' ))
            )
        ) return this.buildDocumentObject(document, acc, holder)

        return acc
      }, [])
      return docs
    },
    digitalGuarantorDocuments: function() {
      const selectedSignType = this.selectedSignType

      let docs = this.documentItems.reduce((acc, document) => {
        let holder = document.signatures.find((value) => value.is_aval)

        if( holder &&
            (
              ( (['digital', 'both']).includes(selectedSignType) && ( document.signType === 'digital' || document.signType === 'both' ))
            )
        ) return this.buildDocumentObject(document, acc, holder)

        return acc
      }, [])
      return docs
    },
    canDownloadPortfolio: function() {
      let value = this.documentItems.reduce((acc, document) => {
        if(document.signType === 'no_signature') return acc
        return acc && document.status === 'signed'
      }, true)

      return value
    },
    canStartSignProccess: function() {
      let value = this.documentItems.reduce((acc, document) => {

        if(document.document_template.sign_type === 'manually' || document.document_template.sign_type === 'no_signature') return acc

        return acc && document.status === 'unsigned'

      }, true)

      return value
    },
    hasRejectedDocuments: function() {
      let value = this.documentItems.reduce((acc, document) => {
        return acc && document.status !== 'rejected'

      }, true)

      return !value
    },
  }
};
</script>
