<template #modal-title>
  <b-container>
    <b-alert
      variant="danger"
      :show="isError"
      dismissible
      @dismissed="isError=false"
    >
      {{ errorMessage }}
    </b-alert>
    <b-alert
      variant="success"
      :show="isSuccess"
      dismissible
      @dismissed="isSuccess=false"
    >
      {{ successMessage }}
    </b-alert>
    <b-row>
      <b-col cols="12">
        <b-dropdown
          v-if="canBeUpdated()"
          split-variant="outline-primary"
          variant="outline-primary"
          :text="`${t(`views.select_all_by_state`)}`"
          class="my-3"
        >
          <b-dropdown-item v-show="selectionButtons.paid" @click="selectDownPaymentsBy('paid')">{{`${t(`views.paid`)}`}}</b-dropdown-item>
          <b-dropdown-item v-show="selectionButtons.annulled" @click="selectDownPaymentsBy('annulled')">{{`${t(`views.canceled`)}`}}</b-dropdown-item>
          <b-dropdown-item v-show="selectionButtons.pending" @click="selectDownPaymentsBy('pending')">{{`${t(`views.pending`)}`}}</b-dropdown-item>
          <b-dropdown-item v-show="selectionButtons.moved" @click="selectDownPaymentsBy('moved')">{{`${t(`views.moved`)}`}}</b-dropdown-item>
          <b-dropdown-item @click="selectDownPaymentsBy(null)">{{`${t(`views.none`)}`}}</b-dropdown-item>
        </b-dropdown>
        <span v-if="canBeUpdated()">{{countDownPaymentsSelected}}/{{totalDownPayment}}</span>
        <section style="height: 28vh; overflow-y:scroll;">
          <b-table :items="setDownPaymentData" :fields="fields">
            <template #cell(checked)="data">
              <b-form-checkbox
                v-if="canBeUpdated()"
                class="ml-2"
                v-model="data.item.checked"
                @change="selectDownPaymentOneOnOne(data.item.id, data.item.status)"
              />
            </template>
            <template #cell(status)="data">
              <badge-status class="rounded-pill" :color="badgeColor(data.value)">
                {{ t(`activerecord.attributes.payment_status.${ data.value }`) }}
              </badge-status>
            </template>
            <template #cell(amount)="data">
              {{ data.value | currency('$', 0, { thousandsSeparator: '.', decimalSeparator: ',' })}}
            </template>
            <template #cell(paid_at)="data">
                  {{ data.value ? dateParse(data.value) : '-' }}
            </template>
          </b-table>
        </section>
        <section class="my-4" v-if="canBeUpdated()">
          <b-button
            variant="primary"
            v-show="canPay"
            @click="updateStatus('pay')"
          >{{`${t(`views.enter_pay`)}`}}</b-button>
          <b-button
            variant="primary"
            v-show="canPending"
            @click="updateStatus('make_available')"
          >{{`${t(`activerecord.attributes.payment_status.pending`)}`}}</b-button>
          <b-button
            variant="primary"
            v-show="canCancel"
            @click="updateStatus('cancel')"
          >{{`${t(`activerecord.attributes.credit.options.cancel`)}`}}</b-button>
          <b-button
            variant="primary"
            v-show="canMove"
            @click="updateStatus('move')"
          >{{`${t(`views.move`)}`}}</b-button>
          <b-button
            variant="primary"
            v-show="canReverse"
            @click="updateStatus('reverse')"
          >{{`${t(`views.reverse`)}`}}</b-button>
        </section>
        <b-form @submit="onSubmit" v-if="enterPayment">
          <b-form-group :label="t(`modal_receive_payment.amount_to_pay`)">
            <b-form-input
              id="type-amount"
              v-model="paidAmount"
              type="number"
              placeholder="ej: 100000"
              :label="t(`modal_receive_payment.amount`)"
              required>
            </b-form-input>
          </b-form-group>
          <b-form-group :label="t(`modal_down_payment_details.payment_method`)">
            <b-form-select
              v-model="selectedPaymentSystem"
              :options="paymentSystemsList"
              required>
              <template #first>
                <b-form-select-option
                  :value="null"
                  disabled>
                  {{ t('modal_receive_payment.select_payment_method') }}
                  </b-form-select-option>
              </template>
            </b-form-select>
          </b-form-group>
          <b-form-group
            :label="t('modal_receive_payment.payment_date')"
            label-for="datepicker">
            <b-form-datepicker
              id="datepicker"
              v-model="paymentDate"
              :max="maxSelectDate"
              locale="es"
              placeholder="dd/mm/aaaa"
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
              :disabled="paidAmount === null"
              required>
            </b-form-datepicker>
          </b-form-group>
          <b-button
            type="submit"
            variant="primary"
            :disabled="!formValidation">
            {{ t('modal_receive_payment.register_payment') }}
          </b-button>
        </b-form>
      </b-col>
    </b-row>
    <m-modal ref="m-modal" :title="mmodal.titleModal" modal-id="mmodal" :status="mmodal.statusModal" :ok-only="mmodal.modalOkOnly" :confirm-only="mmodal.modalConfirmOnly" size="md" @onResponse="modalResponseAction">
      <template slot="body">
        <p v-html="mmodal.bodyModal"></p>
      </template>
    </m-modal>
  </b-container>
</template>

<script>
import downPaymentDetailsApi from '../../api/down_payment_details';
import i18n from '../mixins/i18n';
import moment from 'moment';
import BadgeStatus from './BadgeStatus';
import { badgeColorPaymentStatuses } from '../../constants/statuses';
import { CHARGE_ORDER_PAYMENT_SYSTEMS_LIST } from '../../constants/payments'
import includes from 'lodash/includes';
import MModal from '../dynamics_form/MModal.vue';

export default {
  components: {
    BadgeStatus,
    MModal
  },
  mixins: [i18n],
  name: 'down-payment-detail',
  props: [
    'creditId',
    'userRole',
    'downPaymentDetails',
    'generalStatus',
    'userRolifyRoles',
    ],
  data: function () {
    const maxDate = new Date();
    return {
      indeterminate: false,
      paymentDate: '',
      paidAmount: null,
      maxSelectDate: maxDate,
      selectedPaymentSystem: null,
      paymentSystemsList: CHARGE_ORDER_PAYMENT_SYSTEMS_LIST,
      showForm: false,
      showEditForm: false,
      showElement: false,
      fields: [
        {
          key: 'checked',
          label: '',
        },
        {
          key: 'internal_code',
          label: this.t(`modal_down_payment_details.code`),
        },
        {
          key: 'status',
          label: this.t(`modal_down_payment_details.status`),
        },
        {
          key: 'payment_system',
          label: this.t(`modal_down_payment_details.payment_system`),
        },
        {
          key: 'amount',
          label: this.t(`modal_down_payment_details.amount`),
        },
        {
          key: 'paid_at',
          label: this.t(`modal_down_payment_details.paid_at`),
        },
      ],
      isError: false,
      errorMessage: '',
      isSuccess: false,
      successMessage: '',
      downPaymentData: [],
      listDownPaymentsSelected: [],
      statusFirstDownPaymentSelected: null,
      totalDownPayment: null,
      mmodal: {
        modalOkOnly: false,
        modalConfirmOnly: false,
        statusModal: null,
        titleModal: null,
        bodyModal: null,
      },
      selectionButtons: {
        pending: false,
        annulled: false,
        paid: false,
        moved: false,
      },
      enterPayment: false,
      actionsButton: false,
      actionSelected: null,
      canPay: true,
      canCancel: false,
      canReverse: false,
      canMove: false,
      canPending: false,
      roles: [
        'operations_manager',
        'operations_coordinator',
        'operations_visator',
        'operations_executive',
        'operations_boss',
        'operations',
        'manager'
      ]
    };
  },
  methods: {
    isAdmin: function() {
      return this.userRole === 'admin'
    },
    isTechSupport() {
      return this.userRole === 'tech_support'
    },
    isAuthorized: function(authorizedRoles) {
        return includes(authorizedRoles, this.userRole)
          && includes(this.userRolifyRoles, 'can_register_payment')
    },
    canBeUpdated: function() {
      return (this.isAuthorized(this.roles)
        || this.isAdmin() || this.isTechSupport())
    },
    badgeColor(status) {
      return badgeColorPaymentStatuses[status];
    },
    dateParse: function(value) {
      return moment(value).format('DD-MM-YYYY');
    },
    onSubmit: async function(event) {
      event.preventDefault()

      const confirm_message = this.t(`modal_receive_payment.confirm_message`)
      if (confirm(confirm_message)) {
        const response = await downPaymentDetailsApi.receive({
          payment_system: this.selectedPaymentSystem,
          payment_date: this.paymentDate,
          paid_amount: this.paidAmount,
          credit_id: this.creditId
        })

        if (response.status !== 200) {
          const errors = response.data.map((error, index) => {
            const errorAttribute = `${error.attribute}.${error.type}`
            const errorMessage = this.t(`modal_down_payment_details.errors.${errorAttribute}`)
            return ((index === response.data.length -1) && response.data.length > 1)
              ? ` y ${errorMessage}` : errorMessage
          });
          this.errorMessage = `Error: ${errors}`
          this.isError = true
          return
        }

        this.paidAmount = null;
        this.successMessage = this.t(`modal_receive_payment.message.down_payment.success`)
        this.isSuccess = true

        setTimeout(() => location.reload(), 2000);
      }
    },
    selectDownPaymentsBy: function(status) {
      this.downPaymentData.forEach(function(item) { item.checked = false })
      this.enterPayment = false
      this.statusFirstDownPaymentSelected = null
      this.listDownPaymentsSelected = []

      if(status){
        this.downPaymentData.map((item) => {
          if(item.status == status) {
            item.checked = !item.checked
            this.groupDownPayment(item.id)
          }
        })
        this.statusFirstDownPaymentSelected = status
        if(this.listDownPaymentsSelected.length > 0){ this.actionsButton = true }
      }
      else{
        this.listDownPaymentsSelected = []
        this.actionsButton = false
      }
    },
    selectDownPaymentOneOnOne: function(id, status) {
      this.validateAndGroupDownPaymentSelected(id, status)
      this.setButtonsState(this.statusFirstDownPaymentSelected)
      this.actionsButton = true
    },
    validateAndGroupDownPaymentSelected: function(id, status){
      if(this.listDownPaymentsSelected.length == 0) {
        this.groupDownPayment(id)
        this.statusFirstDownPaymentSelected = status
      }
      else
      {
        this.validateListDownPaymentSelected(id, status)
      }
    },
    validateListDownPaymentSelected: function(id, status) {
      if (includes(this.listDownPaymentsSelected, id)) { return this.unGroupDownPayment(id) }
      if (status == this.statusFirstDownPaymentSelected) { return this.groupDownPayment(id) }

      this.notifyAndUncheckDownPaymentSelected(id, status)
    },
    notifyAndUncheckDownPaymentSelected: function(id, status) {
      this.mmodal = {
        modalOkOnly: true,
        modalConfirmOnly: false,
        titleModal: this.t(`mmodal.down_payment_detail.title.warning`),
        statusModal: 'warning',
        bodyModal: this.t(`mmodal.down_payment_detail.body_text.warning_selection_one_by_one`),
      }
      this.$refs['m-modal'].toggleModal(true);
      const index = this.downPaymentData.findIndex(item => item.id === id)
      setTimeout(() => this.downPaymentData[index].checked = false, 0)
    },
    groupDownPayment: function(id){
      this.listDownPaymentsSelected.push(id)
    },
    unGroupDownPayment: function(id){
      let index = this.listDownPaymentsSelected.indexOf(id)
      this.listDownPaymentsSelected.splice(index, 1)
      if (this.listDownPaymentsSelected.length == 0) {
        this.statusFirstDownPaymentSelected = null
        setTimeout(() => this.actionsButton = false, 0)
        this.enterPayment = false
      }
    },
    updateStatus: function(action){
      this.actionSelected = action
      if(action === 'pay'){
        this.enterPayment = !this.enterPayment
        return
      }

      this.mmodal = {
        modalOkOnly: false,
        modalConfirmOnly: true,
        statusModal: 'warning',
        titleModal: this.t(`mmodal.preorder.title.warning`),
        bodyModal: this.setActionNameAndBodyModal(action),
      }
      this.$refs['m-modal'].toggleModal(true);
    },
    setActionNameAndBodyModal: function(action){
      let translateAction = this.t(`mmodal.preorder.action.${action}`)
      return this.t(`mmodal.down_payment_detail.body_text.warning`, { action: translateAction })
    },
    modalResponseAction: function(selection) {
      if(selection){
        this.$emit('onDownPaymentUpdateStatus', this.listDownPaymentsSelected, this.actionSelected)
      }
      this.statusFirstDownPaymentSelected = null
      this.listDownPaymentsSelected = []
      this.downPaymentData = []
      this.actionsButton = false
      this.actionSelected = null
    },
    setButtonsState: function(status) {
      switch (status) {
        case "pending":
          this.canCancel = true
          this.canReverse = false
          this.canMove = false
          this.canPending = false
          break;
        case "paid":
          this.canCancel = false
          this.canReverse = true
          this.canMove = true
          this.canPending = false
          break;
        case "annulled":
          this.canCancel = false
          this.canReverse = false
          this.canMove = false
          this.canPending = true
          break;
        default:
          this.canCancel = false
          this.canReverse = false
          this.canMove = false
          this.canPending = false
      }
    },
    sortDownPaymentData: function(downPaymentData){
      return downPaymentData.sort(function (a, b) {
        if (a.status > b.status) { return -1 }
        if (a.status < b.status) { return 1 }
        return 0
      })
    },
    setSelectionButtons: function(presentStatus){
      this.selectionButtons = {
        pending: includes(presentStatus, 'pending'),
        annulled: includes(presentStatus, 'annulled'),
        paid: includes(presentStatus, 'paid'),
        moved: includes(presentStatus, 'moved'),
      }
    },
    extractStatusesFromDownPaymentData: function(downPaymentData){
      let status = []
      downPaymentData.map((dpd) => status.push(dpd.status))
      return status.filter((item,index)=>{ return status.indexOf(item) === index })
    },
    setPaymentButton: function(presentStatus){
      this.canPay = includes(presentStatus, 'pending')
    },
  },
  computed: {
    formValidation() {
      return this.selectedPaymentSystem && this.paymentDate && this.paidAmount;
    },
    countDownPaymentsSelected() {
      let total = 0
      this.downPaymentData.forEach(function(item) {
        if(item.checked) { total += 1 }
      })
      return total
    },
    setDownPaymentData(){
       this.downPaymentData = []
       this.downPaymentDetails.forEach(element => {
        this.downPaymentData.push(
          {
            id: element.id,
            internal_code: element.internal_code,
            status: element.status,
            payment_system: element.payment_system,
            amount: element.amount,
            paid_at: element.paid_at,
            checked: false,
          }
        )
      })
      let presentStatus = this.extractStatusesFromDownPaymentData(this.downPaymentData)
      this.setSelectionButtons(presentStatus)
      this.setPaymentButton(presentStatus)
      this.totalDownPayment = this.downPaymentDetails.length
      this.downPaymentData = this.sortDownPaymentData(this.downPaymentData)
      return this.downPaymentData
    }
  },
  watch: {
    statusFirstDownPaymentSelected(new_value){
      this.setButtonsState(new_value)
    }
  }
};
</script>
