<template>
  <div class="card shadow-light-20 mb-4">
    <div class=" card-header d-flex flex-row align-items-center justify-content-between">
      <span class="subtitle neutral-70">{{ t('credit.extraordinary_payment') }}</span>
    </div>
    <div class=" card-body border-top">
      <b-alert
        variant="danger"
        :show="isError"
        dismissible
        @dismissed="isError=false"
      >
        {{ errorMessage }}
      </b-alert>
      <b-alert
        variant="success"
        :show="successMessage.length"
        dismissible
      >
        {{ successMessage }}
      </b-alert>
      <b-form>
        <b-form-group
          label-cols="4"
          label-cols-lg="2"
          :label="t('activerecord.attributes.extraordinary_payment.percentage')"
          label-for="input-default"
        >
          <b-input-group append="%" class="mb-2 mr-sm-2 mb-sm-0">
            <b-form-input
              v-model="percentage"
              type="number"
              id="percentage"
              placeholder="0"
              min="1"
              max="100"
            />
          </b-input-group>
        </b-form-group>
        <b-form-group>
          <span>{{ t('activerecord.attributes.extraordinary_payment.installment_amount') }}: {{ this.fee | currency('$', 0, { thousandsSeparator: '.' }) }}</span><br>
          <span>{{ t('activerecord.attributes.extraordinary_payment.digital_signature_reprogramming_expense') }}: {{ this.digitalSignatureExpense | currency('$', 0, { thousandsSeparator: '.' }) }}</span><br>
          <span>{{ t('activerecord.attributes.extraordinary_payment.promissory_note_legalization_reprogramming_expense') }}: {{ this.promissoryNoteLegalizationExpense | currency('$', 0, { thousandsSeparator: '.' }) }}</span><br>
          <span v-if="this.fogape">{{ t('activerecord.attributes.extraordinary_payment.fogape_reprogramming_expense') }}: {{ this.fogapeExpense | currency('$', 0, { thousandsSeparator: '.' }) }}</span><br v-if="this.fogape"><hr>
          <span><strong>{{ t('activerecord.attributes.extraordinary_payment.total_to_pay') }}: {{ this.totalInstallment | currency('$', 0, { thousandsSeparator: '.' }) }}</strong></span>
        </b-form-group>
        <b-button variant="danger" v-if="hasPendings" @click="cancel">{{ t('views.annul') }}</b-button>
        <b-button
          variant="primary"
          v-if="canSave"
          :disabled="this.percentage <= 0 || loadingSave"
          @click="save"
        >
          <b-spinner small v-if="loadingSave"></b-spinner>
          <span v-if="!loadingSave">{{ t('views.save') }}</span>
          <span v-if="loadingSave">{{ t('views.saving') }}</span>
        </b-button>
        <b-button
          variant="primary"
          v-if="canCreate"
          @click="create"
          :disabled="this.percentage <= 0 || loadingCreate"
        >
          <b-spinner small v-if="loadingCreate"></b-spinner>
          <span v-if="!loadingCreate">{{ t('views.create') }}</span>
          <span v-if="loadingCreate">{{ t('views.creating') }}</span>
        </b-button>
      </b-form>
    </div>
  </div>
</template>
<script>
  import includes from 'lodash/includes'
  import i18n from "../mixins/i18n"
  import extraordinaryPaymentsApi from '../../api/extraordinary_payments'

  export default {
    name: "extraordinary-payments",
    mixins: [i18n],
    props: {
      extraordinaryPayments: {
        type: Array
      },
      credit: {
        type: Object
      },
      installmentAmount: {
        type: Number
      },
      reprogrammingExpenses:{
        type: Array
      },
    },
    data() {
      return {
        extraordinaryPaymentsArray: [],
        percentage: 0,
        fee: 0,
        loadingSave: false,
        loadingCreate: false,
        isError: false,
        errorMessage: '',
        successMessage: '',
        digitalSignatureExpense: 0,
        promissoryNoteLegalizationExpense: 0,
        fogapeExpense: 0,
        totalInstallment: 0,
        fogape: false
      }
    },
    methods: {
      async cancel() {
        const pending = this.getPending()
        const response = await extraordinaryPaymentsApi.cancel(pending.id)

        if (includes([422, 500], response.status)) {
          this.errorMessage = `Error: ${this.getErrors(response)}`
          this.isError = true
          return
        }

        this.successMessage = this.t('activerecord.attributes.extraordinary_payment.success.cancel')
        this.extraordinaryPaymentsArray = this.extraordinaryPaymentsArray.filter((el) => { return el.id !== pending.id })
        this.percentage = 0
      },
      async create() {
        this.loadingCreate = true

        const response = await extraordinaryPaymentsApi.create(this.percentage, this.credit.code)

        this.loadingCreate = false

        if (includes([422, 500], response.status)) {
          this.errorMessage = `Error: ${this.getErrors(response)}`
          this.isError = true
          return
        }

        this.successMessage = this.t('activerecord.attributes.extraordinary_payment.success.create')
        this.extraordinaryPaymentsArray.push(response.data)
      },
      async save() {
        this.loadingSave = true

        const pending = this.getPending()
        const response = await extraordinaryPaymentsApi.update(pending.id, this.percentage)

        this.loadingSave = false

        if (includes([422, 500], response.status)) {
          this.errorMessage = `Error: ${this.getErrors(response)}`
          this.isError = true
          return
        }

        this.successMessage = this.t('activerecord.attributes.extraordinary_payment.success.update')
        pending.percentage = this.percentage
      },
      getPending() {
        return this.extraordinaryPaymentsArray.find(element => element.status === 'pending')
      },
      getErrors(response) {
        return response.data.map((error, index) => {
          const errorAttribute = `${error.attribute}.${error.type}`
          const errorMessage = this.t(`activerecord.attributes.extraordinary_payment.errors.${errorAttribute}`)
          return ((index === response.data.length -1) && response.data.length > 1)
            ? ` y ${errorMessage}` : errorMessage
        });
      },
      getExpenseAmount(name) {
        return this.reprogrammingExpenses.filter( expense => expense.name === name )
      }
    },
    mounted() {
      this.extraordinaryPaymentsArray = this.extraordinaryPayments.slice()

      const pending = this.getPending()

      this.fee = pending?.amount || 0
      this.percentage = pending?.percentage || 0
      this.digitalSignatureExpense = this.getExpenseAmount('digital_signature')[0]?.amount || 0
      this.promissoryNoteLegalizationExpense = this.getExpenseAmount('promissory_note_legalization')[0]?.amount || 0
      this.fogapeExpense = this.getExpenseAmount('fogape')[0]?.amount || 0
      this.fogape = this.credit.fogape
    },
    computed: {
      canCreate() {
        return (this.extraordinaryPaymentsArray.length === 0) && ['paying', 'delayed'].includes(this.credit.status)
      },
      canSave() {
        return Boolean(this.getPending()) && includes(['paying', 'delayed'], this.credit.status)
      },
      hasPendings() {
        return Boolean(this.getPending())
      }
    },
    watch: {
      percentage(percentage) {
        this.fee = Math.trunc((this.installmentAmount * percentage) / 100)

        let total = this.digitalSignatureExpense + this.promissoryNoteLegalizationExpense + this.fee
        this.totalInstallment = this.fogape ? (total + this.fogapeExpense) : total
      }
    }
  }
</script>
