<template>
  <div>
    <b-alert
      variant="danger"
      :show="isError"
      dismissible
      @dismissed="isError=false"
    >
      {{ errorMessage }}
    </b-alert>
    <b-alert
      variant="success"
      :show="isSuccess"
      dismissible
      @dismissed="isSuccess=false"
    >
      La información se guardó satisfactoriamente.
    </b-alert>
    <small class="text-muted">LISTADO DE AVALES</small>
    <guarantor-list :element="simulation" @simulationUpdated="updateSimulation"/>
    <div v-if="element.status !== 'executed' && element.product_type.guarantors_count > 0">
      <small class="text-muted">AGREGAR AVAL</small>
      <guarantor-options :default-option="checkboxValue" @checkboxValueUpdated="checkboxValueUpdated" />
      <guarantor-dropdown :default-guarantor="guarantor" v-if="guarantorFormIsDisabled" @selectedGuarantor="updateFormGuarantor"/>
      <guarantor-form :simulationId="element.id" :guarantor="guarantor" :isDisabled="guarantorFormIsDisabled" @simulationUpdated="updateSimulation"/>
    </div>
    <div v-if="element.product_type.guarantors_count == 0" class="back-grey">
      {{ t('activerecord.attributes.simulation.product_type_not_require_guarantees') }}
    </div>
  </div>
</template>
<script>
  import i18n from "../mixins/i18n"
  import "vue-select/dist/vue-select.css";
  import BoardMixin from '../mixins/board'
  import GuarantorForm from './GuarantorForm'
  import GuarantorDropdown from './GuarantorDropdown'
  import GuarantorOptions from './GuarantorOptions'
  import GuarantorList from './GuarantorList'

  export default {
    name: 'guarantor-card',
    props: [
      'element'
    ],
    mixins: [BoardMixin, i18n],
    components: {
      GuarantorForm,
      GuarantorDropdown,
      GuarantorOptions,
      GuarantorList
    },
    data: function() {
      return {
        checkboxValue: null,
        guarantor: null,
        isError: false,
        errorMessage: '',
        isSuccess: false,
        simulation: this.element
      }
    },
    methods: {
      checkboxValueUpdated: function(value) {
        this.checkboxValue = value
      },
      updateSimulation: function(simulationValue) {
        if (simulationValue.error) {
          this.errorMessage = `Error: ${simulationValue.error.message}`
          this.isError = true
        } else {
          this.simulation = simulationValue
          this.isSuccess = true
        }

        // clean guarantor on the form
        this.guarantor = null
        this.checkboxValue = null
      },
      updateFormGuarantor: function(guarantor) {
        this.guarantor = { ...guarantor }
      },
    },
    watch: {
      checkboxValue: function() {
        this.guarantor = {
          first_name: '',
          last_name: '',
          rut: '',
          dv: '',
          passport: '',
          phone: '',
          email: ''
        }
      }
    },
    computed: {
      guarantorFormIsDisabled: function() {
        return this.checkboxValue === 'not_new'
      }
    }
  }
</script>

<style lang="scss" scoped>
  .v-select .dropdown-toggle, .vs__dropdown-toggle {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: flex;
    background: none;
    border-radius: 4px;
    white-space: normal;
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
    border: 1px solid #dee2e6 !important;
  }
</style>

