<template>
  <box :title="t(`simulator.credit_simulator.title`)" md="12">
    <template slot="content">
      <div class="row mb-3">
        <div class="col-12">
          <div class="card border-0" >
            <div>
              <div no-body md="12" >
                <div header-tag="header" class="padding-accordion-header" role="tab">
                  <div block>
                    <label class="flex-vertical-center pointer group-header"  @click="expandPanel(1)">
                      <div class="inline-header">
                        <div v-show="selectedProductTypeId" class="circle-status" v-bind:class="`${selectedProductTypeId ? 'd-inline-block' : ''} text-success mr-2`" >
                          <i class="fas fa-check-circle" aria-hidden="true" />
                        </div>
                        <div v-show="!selectedProductTypeId" :class="`${selectedProductTypeId ? 'dot-active' : 'dot-inactive-blue'}`">1</div>
                        <span class="accordion-title">{{t(`simulator.credit_simulator.credit_type.title`)}}</span>
                      </div>
                      <span v-show="!isExpandedFirstPanel"><i class="fas fa-angle-down angle-icon" aria-hidden="true" /></span>
                      <span v-show="isExpandedFirstPanel"><i class="fas fa-angle-up angle-icon" aria-hidden="true" /></span>
                    </label>
                  </div>
                </div>
                <b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel" class="padding-accordion-body"  v-model="isExpandedFirstPanel">
                    <div class="row">
                      <div class="col-3">
                        <label class="flex-vertical-center" for="creditTypeID">
                          <div v-show="selectedCreditTypeId" class="circle-status" v-bind:class="`${selectedCreditTypeId ? 'd-inline-block' : ''} text-success mr-2`" >
                            <i class="fas fa-check-circle" aria-hidden="true" />
                          </div>
                          <div v-show="!selectedCreditTypeId" :class="`${selectedCreditTypeId ? 'dot-active' : 'dot-inactive-blue'}`">1</div>
                          {{t(`simulator.credit_simulator.credit_type.credit_product`)}}
                        </label>
                        <b-form-select
                          id="creditTypeID"
                          v-model="selectedCreditTypeId"
                          @change="filterProductTypeList"
                          :options="creditTypesList"
                          value-field="id"
                          text-field="name"
                        />
                      </div>

                      <div class="col-3" v-if="selectedCreditTypeId">
                        <label class="flex circle-status" for="guarantorsCount">
                          <div v-show="selectedGuarantorsCount > 0" :class="`${selectedGuarantorsCount !=null ? 'd-inline-block' : ''} text-success mr-2`" >
                            <i class="fas fa-check-circle fa-lg"></i>
                          </div>
                          <div v-show="!(selectedGuarantorsCount > 0)" :class="`${selectedGuarantorsCount === null ? 'dot-inactive-blue' : 'd-none' }`">2</div>
                          {{t(`simulator.credit_simulator.credit_type.guarantors`)}}
                        </label>
                        <b-form-select
                          id="guarantorsCount"
                          v-model="selectedGuarantorsCount"
                          @change="filterProductTypeList"
                          :options="guarantorsCountList"
                          value-field="id"
                          text-field="name"
                        />
                      </div>

                      <div class="col-3" v-if="selectedCreditTypeId !== 2 && selectedGuarantorsCount !== null">
                        <label class="flex circle-status" for="hasInventory">
                          <div v-show="selectedHasInventory!=null" :class="`${selectedHasInventory!=null?'d-inline-block text-success mr-2' : ''}`" >
                            <i class="fas fa-check-circle fa-lg" aria-hidden="true" />
                          </div>
                          <div v-show="selectedHasInventory===null" :class="`${selectedHasInventory===null? 'dot-inactive-blue' : 'd-none'}`">3</div>
                            {{t(`simulator.credit_simulator.credit_type.inventory`)}}
                        </label>
                        <b-form-select
                          id="hasInventory"
                          v-model="selectedHasInventory"
                          @change="filterProductTypeList"
                          :options="hasInventoryOptions"
                          value-field="value"
                          text-field="name"
                        />
                      </div>

                      <div class="col-3" v-if="showProductTypesList">
                        <div>
                          <label class="flex circle-status" for="productTypeID">
                              <div v-show="selectedProductTypeId" :class="`${selectedProductTypeId ? 'd-inline-block' : ''} text-success mr-2`" > <i class="fas fa-check-circle fa-lg"></i> </div>
                              <div v-show="!selectedProductTypeId" :class="`${selectedProductTypeId ? 'dot-active' : 'dot-inactive-blue'}`">
                                {{selectedCreditTypeId !== 2 && selectedGuarantorsCount !== null ? 4 : 3}}
                              </div>

                              {{t(`simulator.credit_simulator.product_type.title_select`)}}
                          </label>
                          <b-form-select
                            id="productTypeID"
                            v-model="selectedProductTypeId"
                            @change="addProductTypeExtraCosts"
                            :options="productTypesListFiltered"
                            value-field="id"
                            text-field="name"
                          />

                        </div>
                      </div>
                    </div>
                    <div class="message-card warning mb-4" v-if="cashProductType">
                      <em class="fas fa-exclamation-triangle mr-3" />
                      <p>{{ t(`simulator.credit_simulator.product_type.cash`) }}</p>
                    </div>
                </b-collapse>
              </div>

              <div no-body md="12">
                <div header-tag="header" class="padding-accordion-header" role="tab">
                  <div block>
                    <label class="flex-vertical-center  pointer group-header"  @click="expandPanel(2)">
                      <div class="inline-header">
                        <div v-show="isFinishedTwoStep" class="circle-status" v-bind:class="`${isFinishedTwoStep ? 'd-inline-block' : ''} text-success mr-2`" >
                          <i class="fas fa-check-circle" aria-hidden="true" />
                        </div>
                        <div v-show="!isFinishedTwoStep" :class="`${isFinishedTwoStep ? 'dot-active' : 'dot-inactive-blue'}`">2</div>
                        <span class="accordion-title">{{t(`simulator.credit_simulator.products_list.title`)}}</span>
                      </div>
                      <span v-show="!isExpandedSecondPanel"><i class="fas fa-angle-down angle-icon" aria-hidden="true" /></span>
                      <span v-show="isExpandedSecondPanel"><i class="fas fa-angle-up angle-icon" aria-hidden="true" /></span>
                    </label>
                  </div>
                </div>
                <b-collapse id="accordion-2" accordion="my-accordion-2" role="tabpanel" class="padding-accordion-body" v-model="isExpandedSecondPanel">
                  <div class="row" v-if="showProductTypesList">
                    <div v-if="showAvailableModelsList" class="col-12">
                      <div v-if="!isLoadingInventoryTypes">
                        <div class="mb-3" v-for="invType in inventoryTypes" :key="invType.id">
                          <label class="font-bold" >{{t(`simulator.credit_simulator.inventory_types.product`)}} {{invType.name}}</label>
                            <vehicle-models-list
                              v-for="product in productsSplitted[invType.id]"
                              :key="`${product._uid || product.id}`"
                              :product-reference-id="product._uid || product.id"
                              :inventory-item-id="product.inventory_item_id"
                              :inventory-type-id="invType.id"
                              :vehicle-model-id="product.product_model_id_formatted"
                              :model-id-number="product.product_model_id"
                              :type-price="product.has_discount"
                              :color-id="product.color_id"
                              :item-selected-step.sync="itemSelectedStep"
                              :product-value ="productValue"
                              @removeModel="removeModel"
                              @vehicleModelIdSelected="setSelectedModel"
                              @colorIdSelected="setSelectedColor"
                            />
                            <button class="btn btn-outline-primary mr-1 dashed-button flex-vertical-center justify-content-center mtp-12 text-capitalize"
                              @click="addProductReference(invType.id)">
                              <i class="fas fa-plus-circle mrp-12"></i> <span>{{t(`simulator.credit_simulator.inventory_types.add_reference`)}} {{invType.name}}</span>
                            </button>
                        </div>
                      </div >
                      <div class="text-center" v-else>
                        <b-spinner variant="primary" />
                      </div>
                    </div>
                  </div>
                </b-collapse>
              </div>

            </div>
          </div>
        </div>

        <simulator-form
          v-if="calculator_name === 'arc'"
          ref="form"
          :user-role="userRole"
          :new-product-type="selectedProductType"
          :sim-model-color-preference="productsPreference"
          @updateCalculator="updateCalculator"
          @clearProducts="clearProducts"
          @updateProducts="updateProducts"
          :client-id="clientId"
          :pipedrive-person-id="pipedrivePersonId"
          :pipedrive-deal-id="pipedriveDealId"
          :simulationId="simulationId"
          :vehicle-model-id="selectedVehicleModelId"
          :color-id="selectedColorId"
        />

        <simulator-form-v2
          v-if="calculator_name === 'ferry'"
          ref="form2"
          :user-role="userRole"
          :new-product-type="selectedProductType"
          :sim-model-color-preference="productsPreference"
          @updateCalculator="updateCalculator"
          @clearProducts="clearProducts"
          @updateProducts="updateProducts"
          @updateProductValue="updateProductValue"
          :client-id="clientId"
          :pipedrive-person-id="pipedrivePersonId"
          :pipedrive-deal-id="pipedriveDealId"
          :simulationId="simulationId"
          :vehicle-model-id="selectedVehicleModelId"
          :color-id="selectedColorId"
          :firstEditModeLoaded="firstEditModeLoaded"
          :setFirstViewAsLoaded="setFirstViewAsLoaded"
        />
      </div>
    </template>
  </box>
</template>

<script>

import { v4 as uuidv4 } from 'uuid';
import VehicleList from '../simulator/VehicleSearchList.vue'
import VehicleModelsList from '../simulator/VehicleModelsList.vue'
import SimulatorFormV2 from './FormV2.vue'
import SimulatorForm from './Form.vue'
import calculatorMixin from '../mixins/calculator'
import i18n from "../mixins/i18n"

export default {
  name: 'ProductTypeForm',
  mixins: [calculatorMixin, i18n],
  components: {
    VehicleList,
    VehicleModelsList,
    SimulatorFormV2,
    SimulatorForm,
  },
  props: [
    'userRole',
  ],
  data: function() {
    return {
      selectedProductType: null,
      showModal: false,
      colorId: '',
      vehicleBrandId: '',
      vehicleModelId: '',
      calculator_name: '',
      isLoadingInventoryTypes: false,
      inventoryTypes: [],
      productsSplitted: {},
      itemSelectedStep: false,
      productValue: null,
      expandedFirstPanel: true,
      expandedSecondPanel: false,
      firstEditModeLoaded: true
    }
  },
  mounted: async function() {
    await this.getCreditTypesList()
    await this.getProductTypesList()
    await this.setCurrentSimulation()

    if(this.simulationId && this.currentSimulation?.product_type?.active) this.firstEditModeLoaded = false

    this.productTypesList.unshift({ id: null, name: 'Selecciona el tipo de financiamiento', disabled: true})
  },
  watch: {
    firstEditViewLoaded: function(newValue, oldValue) {
      this.firstEditViewLoaded =  newValue
    },
    selectedProductTypeId: async function(newValue, oldValue) {
      this.setSelectedProductType(newValue)
      if (!this.showAvailableModelsList) {
        this.selectedVehicleModelId = null
        this.selectedVehicleModel = null
      }

      if(newValue){
        this.isLoadingInventoryTypes = true
        if(oldValue)
          this.productsPreference.forEach( element =>  {
            this.removeModel(element.id || element._uid)
          });

        this.inventoryTypes = await this.getInventoryTypesByProductType(newValue)
        this.splitProducts()
        this.isLoadingInventoryTypes = false
      }
    },
    productsPreference: function(newValue) {
      let element = this.calculator_name == 'ferry' ? this.$refs.form2 : this.$refs.form
      if(element)
        element.simModelColorPreferences = this.productsPreference
    }
  },
  computed: {
    colorIdSelected: function () {
      return this.currentSimulation?.color?.id
    },
    canManageExtraCosts: function() {
      return _.includes(this.manageExtraCostsRoles, this.userRole)
    },
    isEmptyCurrentSimulation: function() {
      return _.isNil(this.currentSimulation)
    },
    isClientPresent: function() {
      return !_.isNil(this.client.id)
    },
    hasMinimalConditions: function(){
      return this.isClientPresent && this.simulationId && this.completeMandatoryExtraCosts
    },
    hasSelectedProductType: function() {
      return !_.isNil(this.selectedProductType)
    },
    showAvailableModelsList: function() {
      return this.selectedProductType?.has_inventory
    },
    isFinishedTwoStep: function() {
      const fnIsValidSplittedProduct = (inventoryTypeId) => {
        const splittedProductsByInventoryTypeId = (this.productsSplitted && this.productsSplitted[inventoryTypeId]) || []
        if(splittedProductsByInventoryTypeId.length === 0) return false

        const hasInvalidProductModels = splittedProductsByInventoryTypeId.find(splittedProduct => {
          const { product_model_id, price} = splittedProduct
          return (price || 0) <= 0 || !product_model_id
        }) || false
        return !hasInvalidProductModels
      }
      return this.showAvailableModelsList &&
              this.inventoryTypes.reduce((acc, curr) => {
                return acc && fnIsValidSplittedProduct(curr.id)
              }, true)
    },
    showProductTypesList: function(){
      return this.selectedCreditTypeId && !_.isNil(this.selectedGuarantorsCount) && (this.productTypesListFiltered.length > 1)
    },
    cashProductType: function() {
      return this.selectedProductType?.type == 'cash'
    },
    isExpandedFirstPanel:{
      get(){
        return this.expandedFirstPanel
      },
      set(expandedFirstPanel){
        return expandedFirstPanel
      }
    },
    isExpandedSecondPanel:{
      get(){
        return this.expandedSecondPanel
      },
      set(expandedSecondPanel){
        return expandedSecondPanel
      }
    }
  },
  methods: {
    setFirstViewAsLoaded: function () {
      this.firstEditModeLoaded = true
    },
    setSelectedModel: function(value) {
      this.setSelectedValues(value.id, {
        product_model_id: value.modelId,
        productModel: value.model,
        price: value.price,
        has_discount: value.hasDiscount,
        discount_amount: value.discount_amount
      })
    },
    setSelectedColor: function(value) {
      this.setSelectedValues(value.id, { color_id: value.colorId })
    },
    setSelectedProductType: function(value) {
      this.selectedProductType = this.productTypesListFiltered.filter(productType => productType.id === value)[0]
      this.calculator_name = this.selectedProductType?.calculator_name
      if(this.selectedProductType?.has_inventory) this.expandPanel(2)
    },
    updateCalculator: function() {
      if(this.calculator_name === 'ferry'){
        this.$refs.form2.productTypesListFiltered = this.productTypesListFiltered || []
      } else {
        this.$refs.form.productTypesListFiltered = this.productTypesListFiltered || []
      }
    },
    splitProducts: function() {
      this.productsSplitted = this.inventoryTypes.reduce((acc, curr) => {
        acc[curr.id] = this.productsPreference.filter((pf) => pf.inventory_type_id === curr.id && !pf._destroy)
        return acc
      }, {})
    },
    setSelectedValues(id, value) {
      let productReferenceIndex = this.productsPreference.findIndex((element) => element.id === id || element._uid === id)
      this.productsPreference[productReferenceIndex] = {
        ...this.productsPreference[productReferenceIndex],
        ...value
      }
      this.productsPreference = [...this.productsPreference]
      this.splitProducts()
    },
    addProductReference: function(inventoryTypeId) {
      this.itemSelectedStep = false
      this.productsPreference.push({
        _uid: uuidv4(),
        inventory_type_id: inventoryTypeId,
        color_id: null,
        product_model_id: null,
        productModel: null,
        discount_amount: 0
      })
      this.splitProducts()
    },
    removeModel: function(id) {
      this.productsPreference = [...this.productsPreference.reduce((acc, element) => {
        if(element.id === id) {
          acc.push({ id: element.id, _destroy: 1 })
        } else if(element.id || element._uid !== id) {
          acc.push({...element})
        }

        return acc
      }, [])]
      this.splitProducts()
    },
    clearProducts: function() {
      this.productsPreference = [...this.productsPreference.reduce((acc, element) => {
        if(!element._destroy) {
          acc.push({...element})
        }

        return acc
      }, [])]
      this.splitProducts()
    },
    updateProducts: function(products) {
      this.productsPreference = products.filter(prod => !prod._destroy)
      this.splitProducts()
    },
    updateProductValue: function(value) {
      this.productValue = value
    },
    expandPanel(panelIndex){
      if(panelIndex == 1)  this.expandedFirstPanel = !this.expandedFirstPanel
      if(panelIndex == 2)  this.expandedSecondPanel = !this.expandedSecondPanel
    }
  }
}

</script>
