<template>
  <b-modal v-model="modalShow" :id="modalId" :size="size" :centered="isCentered" :hide-footer="true">
    <div v-if="textCenterType()" class="text-center">
      <em :class="`${icons[status]} text-5xl mb-8`"></em>
      <h5>{{ title }}</h5>
      <slot name="body"></slot>
      <div class="modal-footer">
        <button v-if="okOnly" class="button button-primary w-100" @click="toggleModal(false)">{{ t('views.accept') }}</button>
        <button v-if="okOnlyReloadPage" class="button button-primary w-100" @click="toggleModalReloadPage(false, 1000)">{{ t('views.accept') }}</button>
        <button v-if="confirmOnly" class="button button-primary w-100" @click="response(true)">{{ t('views.accept') }}</button>
        <button v-if="confirmOnly" class="button button-primary w-100" @click="response(false)">{{ t('views.cancel') }}</button>
        <slot name="footer">
        </slot>
      </div>
    </div>
  </b-modal>
</template>

<script>
import i18n from "../mixins/i18n"

export default {
  name: 'm-modal',
  mixins: [i18n],
  props: {
    modalId: {
      type: String,
      default: undefined
    },
    title: {
      type: String,
      default: undefined
    },
    status: {
      type: String,
      default: 'info'
    },
    size: {
      type: String,
      default: 'md',
      validator: function (value) {
        return [
          'xl',
          'lg',
          'md',
          'sm'
        ].indexOf(value) !== -1
      }
    },
    okOnly: {
      type: Boolean,
      default: false
    },
    okOnlyReloadPage: {
      type: Boolean,
      default: false
    },
    confirmOnly: {
      type: Boolean,
      default: false
    },
    isCentered: false
  },
  data() {
    return {
      modalShow: false,
      modalStatus: [
        'warning',
        'error',
        'success',
        'info'
      ],
      icons: {
        warning: 'fas fa-exclamation-triangle warning-100',
        error: 'fas fa-times-circle error-80',
        success: 'fas fa-check-circle success-100',
        info: 'fas fa-info-circle info-80'
      }
    }
  },
  methods: {
    toggleModal(action) {
      this.modalShow = action
    },
    toggleModalReloadPage(action, miliSeconds) {
      this.modalShow = action
      if (!action) { setTimeout(() => location.reload(), miliSeconds) }
    },
    textCenterType() {
      return this.modalStatus.includes(this.status)
    },
    response(selection) {
      this.$emit('onResponse', selection)
      this.toggleModal(false)
    }
  }
}
</script>

