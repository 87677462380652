<template>
  <div v-if="hasClientData || affiliateRecords">
    <h1 class="mb-3">{{t('activerecord.attributes.simulation.evaluation.labor_information')}}</h1>

    <b-row>
      <b-col cols lg="6" sm="12" v-if="hasClientData">
        <section class="card shadow-light-20 mb-4">
          <div class="card-header flex align-items-center justify-content-between">
            <span class="subtitle neutral-70">
              {{ t('activerecord.attributes.simulation.evaluation.client') }}
            </span>
          </div>
          <div class="card-body p-0">
            <b-table
              class="m-0"
              :items="clientData"
              :fields="fields"/>
          </div>
        </section>
      </b-col>
      <b-col cols lg="6" sm="12" v-if="hasGuarantorData">
        <section class="card shadow-light-20 mb-4">
          <div class="card-header flex align-items-center justify-content-between">
            <span class="subtitle neutral-70">
              {{ t('activerecord.attributes.simulation.evaluation.guarantor') }}
            </span>
          </div>
          <div class="card-body p-0">
            <b-table
              class="m-0"
              :items="guarantorData"
              :fields="fields"/>
          </div>
        </section>
      </b-col>
    </b-row>

    <b-row v-if="clientHasValidContracts">
      <b-col cols lg="12" sm="12">
        <h1 class="mb-3">{{t('activerecord.attributes.simulation.evaluation.labor_information_detail')}}</h1>
        <section class="card shadow-light-20 mb-4">
          <div class="card-header flex align-items-center justify-content-between">
            <span class="subtitle neutral-70">
              {{ t('activerecord.attributes.simulation.evaluation.client') }}
            </span>
          </div>
          <div class="card-body p-0">
            <b-table
              class="m-0"
              :items="clientValidContracts"
              :fields="validContractsFields"/>
          </div>
        </section>
      </b-col>
    </b-row>

    <section v-if="affiliateRecords" class="card shadow-light-20 mb-4">
      <div class="card-header flex align-items-center justify-content-between">
        <span class="subtitle neutral-70">
          {{ t('activerecord.attributes.simulation.evaluation.employee') }}
        </span>
      </div>
      <div class="card-body">
        <b-table
          class="text-center"
          hover
          :fields="fieldsAffiliateRecords"
          :items="affiliateRecords"
          :current-page="currentPage"
          :per-page="perPage">
        </b-table>
        <div class="flex-vertical-center justify-content-end row-sm-block mtp-20">
          <per-page :per-page.sync="perPage" />
          <b-pagination
            v-model="currentPage"
            class="sm-center"
            style="margin-bottom: unset"
            :total-rows="rows"
            :per-page="perPage"
            :prev-text="`‹ ${t('views.previous')}`"
            :next-text="`${t('views.next')} ›`"
            hide-goto-end-buttons
            hide-ellipsis>
          </b-pagination>
        </div>
      </div>
    </section>
  </div>
  <div v-else>
    <strong>{{t('activerecord.attributes.simulation.evaluation.no_kayak_labor_information')}}</strong>
  </div>
</template>
<script>
import PerPage from '../dynamics_form/PerPage.vue'
import i18n from "../mixins/i18n"
import _ from 'lodash'

export default {
  components: { PerPage },
  name: "kayak-labor-information",
  mixins: [i18n],
  props: [
    'laborInformation',
    'affiliateRecords'
  ],
  data() {
    return {
      hasGuarantorData: Object.values(this.laborInformation.guarantor).some(x => x != null),
      hasClientData: Object.values(this.laborInformation.client).some(x => x != null),
      clientData: [this.laborInformation.client] ?? null,
      clientValidContracts: this.mapValidContracts(this.laborInformation.client_valid_contracts),
      clientHasValidContracts: this.laborInformation?.client_valid_contracts?.length,
      guarantorData: [this.laborInformation.guarantor] ?? null,
      fields: this.translateFields(['seniority', 'continuity', 'contract_type', 'labor_situation', 'status']),
      validContractsFields: this.translateFields(['seniority', 'employer_rut', 'contract_type', 'labor_situation', 'status'], true),
      fieldsAffiliateRecords: this.translateFields(
        ['subscription_date', 'employer_rut', 'company_name', 'contract_type', 'starting_contract_date', 'ending_contract_date']),
      currentPage: 1,
      rows: this.affiliateRecords?.length ?? 0,
      perPage: 5,
    }
  },
  methods: {
    translateFields(fields, fieldsToCamelcase = false) {
      return fields.map((field) => ({
        key: fieldsToCamelcase ? _.camelCase(field) : field,
        label: this.t(`activerecord.attributes.simulation.evaluation.${field}`)
      }))
    },
    mapValidContracts(validContracts) {
      return validContracts?.map(contract => {
        if (contract?.employer_rut?.length) {
          return {
            seniority: contract.seniority,
            employerRut: contract.employer_rut[0],
            contractType: contract.contract_type,
            laborSituation: contract.labor_situation,
            status: contract.status
          }
        }
      })
    }
  }
}
</script>
