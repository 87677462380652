<template>
  <b-form key="field">
    <b-row class="neutral-10" >
      <div :class="readOnlyClass">
        <b-form-group v-if="!isReadonly" :label="t('views.type')" invalid-feedback="tipo es requerido">
          <div class="d-flex">
            <b-form-select v-model="triggerableType" :options="triggerableTypeOptions" required></b-form-select>
          </div>
          <span class="text-danger" v-if="errors.triggerableType" >{{ errors.triggerableType }}</span>
        </b-form-group>
        <div v-else>
          {{ getTriggerableTypeName(triggerableType) }}
        </div>
      </div>

      <div :class="readOnlyClass">
        <b-form-group v-if="!isReadonly" :label="t('errand_type.status')" invalid-feedback="Estado base es requerido">
          <div class="d-flex">
            <dropdown-filter
              ref="dropdown_state"
              :list-check="[state]"
              :items="stateOptions"
              :radio-inputs="true"
              v-on:onFilter="stateSelected"
              :text="t('errand_type.status')"
              input-width="100%"
            />
          </div>
          <span class="text-danger" v-if="errors.state" >{{ errors.state }}</span>
        </b-form-group>
        <div v-else>
          {{ getStateName(state) }}
        </div>
      </div>

      <div v-if="triggerableType === 'errand_type'" :class="readOnlyClass">
        <b-form-group v-if="!isReadonly" :label="t('errand_type.initial_state')" invalid-feedback="Estado base es requerido">
          <div class="d-flex">
            <b-form-select v-model="subStatusId" value-field="id" text-field="name" :options="subStatusOptions" required></b-form-select>
          </div>
          <span class="text-danger" v-if="errors.subStatusId" >{{ errors.subStatusId }}</span>
        </b-form-group>
        <div v-else>
          {{ getSubStatusName(subStatusId) }}
        </div>
      </div>

      <div v-if="triggerableType === 'DocumentTemplatesProductType'" :class="readOnlyClass">
        <b-form-group v-if="!isReadonly" :label="t('errand_type.initial_state')">
          <div class="d-flex">
            <b-form-select v-model="documentTemplateId" value-field="id" text-field="name" :options="allDocumentTemplates" required></b-form-select>
          </div>
          <span class="text-danger" v-if="errors.documentTemplateId" >{{ errors.documentTemplateId }}</span>
        </b-form-group>
        <div v-else>
          {{ getDocumentTemplateName(documentTemplateId) }}
        </div>
      </div>

      <div v-if="!isReadonly && this.id && this.state && this.triggerableType" :class="readOnlyClass" class="btn-action-container">
        <b-form-group :label="t('views.actions')" invalid-feedback="tipo es requerido">
          <b-button variant="outline-danger" type="button" @click="onDelete"> <i class="fas fa-trash"></i> </b-button>
        </b-form-group>
      </div>

    </b-row>
  </b-form>

</template>

<script>
  import i18n from "../mixins/i18n"
  import _ from 'lodash';
  import { TARGETMODELS, TARGETSTATUSES } from '../../constants/errands'
  import errandsAPI from '../../api/errands'
  const NAME_REQUIRED_FIELDS = ['state', 'triggerableType', 'subStatusId']
  import DropdownFilter from '../dynamics_form/DropdownFilter.vue'

  export default {
    name: 'trigger-form',
    components: { DropdownFilter },
    mixins: [i18n],
    props: [
      'initField',
      'readonly',
      'targetModel',
      'subStatuses',
      'modelId',
      'modelName',
      'errandTypeId'
    ],
    data() {
      return {
        isNew: _.isNil(this.initField?.id),
        _uid: this.initField?._id,
        id: this.initField?.id,
        state: this.initField?.state || '',
        stateOptions: [],
        triggerableType: this.initField?.triggerable_type || '',
        triggerableTypeOptions: [],
        targetModels: TARGETMODELS,
        targetStatuses: TARGETSTATUSES,
        subStatusId: this.initField?.sub_status_id || '',
        subStatusOptions: this.subStatuses || [],
        isEditing: false,
        isReadonly: this.readonly || false,
        showAddItem: false,
        disabledAddItem: false,
        documentTemplateId: this.initField?.model_triggerable_id || '',
        allDocumentTemplates: [],
        errors: {
          state: '',
          triggerableType: null,
          subStatusId: '',
          documentTemplateId:''
        },
      }
    },
    mounted: async function() {
      this.triggerableTypeOptions = this.targetModels[this.targetModel]
      if(this.triggerableType && this.triggerableType !== 'errand_type' ){
        this.allDocumentTemplates = await errandsAPI.getDocumentTemplate(this.modelId)
        this.stateOptions = this.targetStatuses[this.targetModel][this.triggerableType].map(val => {
          return {id: val.value, name: val.text}
        })
      }else if(this.triggerableType === 'errand_type') {
          this.stateOptions = await this.getStateOptionErrand()
          this.subStatusOptions = [...(await this.getSubStateOptionErrand())]
      }
    },
    watch: {
      triggerableType:async function(newValue, oldValue){
        if(!newValue) return this.stateOptions = []

        this.state = ''
        this.subStatusId = null

        if(this.triggerableType === 'errand_type'){
          this.stateOptions = await this.getStateOptionErrand()
        }else if(this.triggerableType === 'DocumentTemplatesProductType'){
          this.allDocumentTemplates = await errandsAPI.getDocumentTemplate(this.modelId)
          this.stateOptions = this.targetStatuses[this.targetModel][newValue].map(val => {
            return {id: val.value, name: val.text}
          })
        }else{
          this.stateOptions = this.targetStatuses[this.targetModel][newValue].map(val => {
            return {id: val.value, name: val.text}
          })
        }

        this.onSubmitEdit()
      },
      state: async function(newValue, oldValue){
        if(this.triggerableType === 'errand_type'){
          this.subStatusOptions = [...(await this.getSubStateOptionErrand())]
        }else{
          this.subStatusOptions = this.subStatuses || []
        }

        if(newValue) this.onSubmitEdit()
      },
      subStatusId:async function(newValue, oldValue){
        if(newValue) this.onSubmitEdit()
      },
      initField(newValue) {
        this.isNew = _.isNil(this.initField?.id)
        this._uid = this.initField?._id
        this.id = this.initField?.id
        this.state = this.initField?.state || ''
        this.triggerableType = this.initField?.triggerable_type || ''
        this.subStatusId = this.initField?.sub_status_id || ''
        this.documentTemplateId = this.initField?.model_triggerable_id || ''
      },
      documentTemplateId: async function(newValue, oldValue){
        if(newValue) this.onSubmitEdit()
      }
    },
    computed: {
      isDisabled() {
        return !this.isNew && !this.isEditing  ? true : false
      },
      readOnlyClass() {
        return {
          'd-flex align-items-center': this.isReadonly,
          '': !this.isReadonly
        }
      }
    },
    methods: {
      stateSelected(item){
        this.state = item[0]
      },
      getStateOptionErrand: async function(){
        let options = (await errandsAPI.list(this.modelName, this.modelId))
        options = options.filter(val => val.id != this.errandTypeId)
        return options
      },
      getSubStateOptionErrand: async function(){
        let options = await errandsAPI.getSubStatusList(this.state)
        options = options.reduce((acc, curr) => {
          let list = [...curr.sub_status_list]
          for (var i=0; i < list.length; i++) acc.push(list[i]);
          return acc
        },[])
        options = [{
            id: null,
            name: 'Seleccione'
          },
          ...options
        ]
        return options
      },
      onSubmit(event) {
        event.preventDefault()
        if(!this.valid()) return;

        this.$emit('addTrigger', {
          triggerable_type: this.triggerableType,
          state: this.state,
          sub_status_id: this.subStatusId,
          model_triggerable_id: this.documentTemplateId,
          model_triggerable_type: 'DocumentTemplatesProductType'
        });

        this.id = null
        this._uid = null
        this.triggerable_type = ''
        this.state = ''
        this.sub_status_id = ''
        this.model_triggerable_id = ''
        this.model_triggerable_type = ''
      },
      onSubmitEdit(){
        this.$emit('updateTrigger', {
          _uid: this._uid,
          id: this.id,
          triggerable_type: this.triggerableType,
          state: this.state,
          sub_status_id: this.subStatusId,
          model_triggerable_id: this.documentTemplateId,
          model_triggerable_type: 'DocumentTemplatesProductType'
        });

      },
      toggleEditing(event) {
        this.isEditing = !this.isEditing
        this.isReadonly = !this.isReadonly
      },
      onDelete() {
        this.$emit('deleteTrigger', this.id || this._uid)
      },
      valid() {
        let valid = true
        for (let index = 0; index < NAME_REQUIRED_FIELDS.length; index++) {
          const element = NAME_REQUIRED_FIELDS[index];
          if(!this[element]){
            this.errors[element] = 'Es requerido'
            valid = false
          } else {
            this.errors[element] = ''
          }
        }

        return valid
      },
      getSubStatusName(subStatusID) {
        return this.subStatuses.find(val => val.id == subStatusID)?.name || ''
      },
      getTriggerableTypeName(type) {
        return this.triggerableTypeOptions.find(val => val.value == type)?.text || ''
      },
      getStateName(state) {
        return this.stateOptions.find(val => val.value == state)?.text || ''
      },
      getDocumentTemplateName(documentTemplateId){
        return this.allDocumentTemplates.find(val => val.id == documentTemplateId)?.name || ''
      }
    }

  }
</script>

