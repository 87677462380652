<template>
  <div class="mb-4">
    <ul v-if="!loading">
      <div
        class="pb-4 d-flex align-items-center"
        v-for="line in inventory_types"
        v-bind:key="line.name"
      >
        <span class="flex-vertical-center">
          <span v-if="pruena(line.color, line.icon)" class="flex-vertical-center justify-content-center relative" v-bind:style="{ width: '44px', height: '44px' }">
            <span id="icon_selected" v-bind:style="{ color: line.color }">
              <i :class="line.icon"></i>
            </span>
            <div class="absolute back-icon" v-bind:style="{ background: line.color, opacity: '20%' }"></div>
          </span>
          <span v-else class="flex-vertical-center justify-content-center relative" v-bind:style="{ width: '44px', height: '44px' }">
            <span id="icon_selected" v-bind:style="{ color: '#000' }">
              <strong>{{ line.name.charAt(0) }}</strong>
            </span>
            <div class="absolute back-icon" v-bind:style="{ background: '#000', opacity: '20%' }"></div>
          </span>
        </span>
        <span class="title-icon-model"><strong class="mr-4 text-capitalize"> {{line.name}} </strong></span>
        <span class="title-icon-model justify-content-center"><b-badge class="text-muted rounded-pill badge-count pt-1" variant="light">{{line.items_count}} {{ t(`inventory_consolidate.available`) }}</b-badge></span>
        <span class="title-icon-model justify-content-center"><b-badge class="text-muted rounded-pill badge-count pt-1" variant="light">{{line.brands_count}} {{ t(`inventory_consolidate.brands`) }}</b-badge></span>
        <span class="title-icon-model justify-content-center"><b-badge class="text-muted rounded-pill badge-count pt-1" variant="light">{{line.models_count}} {{ t(`inventory_consolidate.models`) }}</b-badge></span>
        <span class="button-icon-model justify-content-center" >
          <button class="second-button flex" v-b-toggle.sidebar-brands-list @click="showBrandList(line)">{{ t(`views.see_available`) }}</button>
        </span>
      </div>
    </ul>

    <div v-else class="text-center">
      <b-spinner variant="primary" label="Spinning"></b-spinner>
    </div>

    <brands-list :inventory-type="selectedType"/>
  </div>
</template>

<script>
  import i18n from "../mixins/i18n"
  import capitalize from "../mixins/capitalize"
  import inventory from '../../api/inventory';
  import BrandsList from './BrandsList.vue'
  import CardItem from '../template/CardItem.vue'

  export default {
    name: 'inventory-consolidated',
    components: {
      BrandsList,
      CardItem
    },
    mixins: [i18n, capitalize],
    props: [
      'disabled'
    ],
    data() {
      return {
        value: '',
        loading: false,
        inventory_types: [],
        selectedType: null
      }
    },
    mounted: async function() {
      this.loading = true
      this.inventory_types = await inventory.consolidated()
      this.loading = false

    },
    methods: {
      showBrandList(type) {
        this.selectedType = type
      },
      pruena(color, icon) {
        return color != null && icon != null
      }
    }
  }
</script>
