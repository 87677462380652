<template>
  <b-dropdown class="dropdown-form" ref="dropdown" variant="link" :style="`width: ${ inputWidth }`"
    toggle-class="button button-secondary button-sm dropdown-custom text-decoration-none" :disabled="isDisabled"
    @hide="handleHide($event)" @hidden="onHidden">
    <template #button-content>
      <div class="flex">{{ text }}</div>
    </template>
    <b-dropdown-form @submit.stop.prevent class="w-100">
      <slot name="content"></slot>
      <div v-if="isSubmit" class="flex justify-end w-100 mt-3">
        <button v-if="ifCancel" class="button button-secondary" style="margin-right: 14px;" @click="closeDropdown">{{ t(`views.cancel`) }}</button>
        <button class="button button-primary" @click="submitSelected">{{ t(`views.accept`) }}</button>
      </div>
    </b-dropdown-form>
  </b-dropdown>
</template>
<script>
import i18n from "../mixins/i18n"

export default {
  name: 'm-dropdown',
  mixins: [i18n],
  props: {
    text: String,
    inputWidth: {
      type: String,
      default: 'auto'
    },
    ifCancel: {
      type: Boolean,
      default: true
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    isSubmit: {
      type: Boolean,
      default: true
    },
    closeable: {
      type: Boolean,
      default: false
    },
    onSubmitSelected: {
      type: Function,
      default: () => {}
    },
  },
  data() {
    return {
      isCloseable: this.closeable
    }
  },
  methods: {
    handleHide(bvEvent) {
      if (!this.isCloseable) {
        bvEvent.preventDefault()
      } else {
        this.$refs.dropdown.hide()
      }
    },
    submitSelected() {
      this.$emit('onSubmitSelected')
      this.closeDropdown()
    },
    closeDropdown() {
      this.isCloseable = true
      this.$refs.dropdown.hide()
    },
    onHidden() {
      if (!this.closeable)
        this.isCloseable = false
    }
  },
  mounted() {
    this.$root.$on('bv::dropdown::show', bvEvent => {
      bvEvent.vueTarget.$refs.toggle.classList.add('active')
    })
    this.$root.$on('bv::dropdown::hide', bvEvent => {
      bvEvent.vueTarget.$refs.toggle.classList.remove('active')
    })
  },
}
</script>
