import axios from 'axios'
import { config } from '../constants/api'

const buildConfig = (extraconfig) => {
  return { ...config, ...extraconfig }
}

export default {
  async post(data) {
    const path = '/inventory_types.json'

    return await axios.post(path, data, config).then(res => res.data).catch(err => {
      return err.response
    })
  },
  async update(inventoryTypeId, data) {
    const path = `/inventory_types/${inventoryTypeId}.json`

    return await axios.patch(path, data, config)
      .then((res) => res.data)
      .catch((error) => error.response)
  },
  async get(inventoryTypeId) {
    const path = `/inventory_types/${inventoryTypeId}.json`

    return await axios.get(path, config)
      .then((res) => res.data)
      .catch((error) => error.response)
  },
  async merge_items(modelName, fromId, toId, name) {
    const path = `/inventory_types/merge_items.json`

    let data = {
      model_name: modelName,
      from_id: fromId,
      to_id: toId,
      name: name,
    }

    return await axios.post(path, data, config)
      .then((res) => res.data)
      .catch((error) => error.response)
  },
  async inventoryTypeList() {
    const path = `/inventory_types/list.json`;

    return await axios.get(path, config)
      .then((res) => res.data)
      .catch((error) => error.message)
  },
  async dynamicFieldsByInventoryType(inventory_type_id) {
    const path = `/inventory_types/dynamic_fields.json`

    let data = { inventory_type_id: inventory_type_id }

    return await axios.post(path, data, config)
      .then((res) => res.data)
      .catch((error) => error.response)
  },
}
