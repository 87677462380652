export const PAYMENT_SYSTEMS_LIST = [
  { value: 'pac', text: 'PAC' },
  { value: 'otrospagos', text: 'OtrosPagos' },
]

export const BANK_ACCOUNT_TYPES_LIST = [
  { value: 'checking_account', text: 'Cuenta Corriente' },
  { value: 'sight_account', text: 'Cuenta Rut/Vista' },
  { value: 'savings_account', text: 'Cuenta Ahorro' }
]

export const BANKS_LIST = [
  { value: 1, text: 'BANCO DE CHILE, Banco Edwards, Citi, Atlas y CrediChile' },
  { value: 2, text: 'BANCO INTERNACIONAL' },
  { value: 3, text: 'BANCO ESTADO', excluded: ['savings_account'] },
  { value: 4, text: 'SCOTIABANK CHILE' },
  { value: 5, text: 'BANCO DE CREDITO E INVERSIONES, TBanc y Banco Nova' },
  { value: 6, text: 'CORPBANCA, Banco Condell' },
  { value: 7, text: 'BANCO BICE' },
  { value: 8, text: 'HSBC BANK (CHILE)' },
  { value: 9, text: 'BANCO SANTANDER-CHILE, Banefe', excluded: ['sight_account'] },
  { value: 10, text: 'BANCO ITAÚ CHILE' },
  { value: 11, text: 'THE BANK OF TOKYO - MITSUBISHI LTD.' },
  { value: 12, text: 'BANCO SECURITY' },
  { value: 13, text: 'BANCO FALABELLA' },
  { value: 14, text: 'DEUTSCHE BANK (CHILE)' },
  { value: 16, text: 'RABOBANK CHILE (ex HNS BANCO)' },
  { value: 17, text: 'BANCO CONSORCIO (ex BANCO MONEX)' },
  { value: 18, text: 'BANCO PENTA' },
  { value: 19, text: 'BANCO PARIS' },
  { value: 20, text: 'BANCO BILBAO VIZCAYA ARGENTARIA, CHILE (BBVA), BBVA Express' },
]

export const CHARGE_ORDER_PAYMENT_SYSTEMS_LIST = [
  { value: 'direct', text: 'Transferencia Directa' },
  { value: 'otrospagos', text: 'OtrosPagos' },
  { value: 'etpay', text: 'ETPay'},
  { value: 'alliance', text: 'Alianza'}
]
