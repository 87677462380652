<template>
  <i v-bind:class="iconClass.class" v-bind:style="iconClass.style" v-bind:title="status"/>
</template>
<script>
export default {
  name: 'evaluation-status-sign',
  props: [
    'status'
  ],
  computed: {
    iconClass: function() {
      const status = this.status
      const statusObject = {
        approved: {style: "color: #28a745", class: "fas fa-check"},
        pending: {style: "color: #ffc107", class: "fas fa-exclamation"},
        rejected: {style: "color: red", class: "fas fa-times"}
      }
      return statusObject[status]
    }
  }

}
</script>
