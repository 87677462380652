<template>
  <b-row>
    <b-col cols="12">
      <b-alert
        variant="danger"
        :show="isError"
        dismissible
        @dismissed="isError=false"
      >
        {{ errorMessage }}
      </b-alert>
      <b-alert
        variant="success"
        :show="isSuccess"
        dismissible
        @dismissed="isSuccess=false"
      >
        {{ successMessage }}
      </b-alert>
      <b-alert variant="info" class="d-flex justify-content-between ont-weight-bold" show>
        <div class="p-medium mt-2">
          <em class="fas fa-lightbulb"></em>
          <span>Para suscribir un nuevo PAC envía este link al cliente:</span>
          <a :href="pacTugboatURL">{{ pacTugboatURL }}</a>
        </div>
        <label
          @click="copyPacTugboatLink"
          class="button button-secondary button-sm copy-tugboat-link__button"
          v-bind:class="{'bg-success': copiedLink}"
        >
          {{ copiedLink ? '¡Copiado!' : 'Copiar link' }}
        </label>
      </b-alert>
    </b-col>
    <b-col>
      <b-row>
        <b-col>
          <b-card header="Selecciona una cuenta PAC" class="h-100">
            <PacSelector
              :clientId="element.client.id"
              :bankAccounts="bankAccounts"
              :selected="selecteBankAccount"
              :tugboatUrl="pacTugboatURL"
              @onSelectedPac="handleSelectedPac"
              @onSaveButton="handleSavePacBankAccount"
            />
          </b-card>
        </b-col>
        <b-col  v-if="showManualSubscriptionForm" cols="12" md="6" lg="4" class="mt-xs-3">
          <b-card header="Datos cuenta bancaria">
            <NewBankAccount @onSaveButton="handleSaveManualPac" :selectedBankAccountToEdit="selecteBankAccount"/>
          </b-card>
        </b-col>
      </b-row>
      <div class="manual-subscription__checkbox" v-if="showContingencyForm">
        <CheckboxField :show="showManualSubscriptionForm" @onChangeValue="handleManualSubscriptionForm"/>
        <span class="p-medium mt-2">{{ t("simulations.contingency_pac.title_button") }}</span>
      </div>
    </b-col>
  </b-row>
</template>

<style scoped>
  .manual-subscription__checkbox {
    display: flex;
    align-items: center;
  }

  .copy-tugboat-link__button {
    min-width: 130px;
  }

  .copy-tugboat-link__button:focus {
    outline: none;
    box-shadow: none;
  }
</style>

<script>
import i18n from "../mixins/i18n"
import apiSimulation from '../../api/simulations';
import includes from 'lodash/includes'
import { PAYMENT_SYSTEMS_LIST, BANK_ACCOUNT_TYPES_LIST, BANKS_LIST } from '../../constants/payments'
import CheckboxField from '../dynamics_form/fields/CheckboxField.vue';
import BankAccountForm from '../bank_account/bank-account-form.vue';
import NewBankAccount from '../bank_account/NewBankAccount.vue';
import PacSelector from '../bank_account/PacSelector.vue';
import bankAccountApi from '../../api/bank_accounts';
import { copy } from '../../plugins/clipboard'

const EMPTY_BANK_ACCOUNT= {
  name: "",
  rut: "",
  dv: "",
  account_type: "",
  number: "",
  bank_id: "",
}

const USER_ROLE_ALLOWED_FOR_CONTINGENCY_PAC = [
  'admin',
  'tech_support',
  'operations_boss',
]

export default {
    name: "card-payment-table",
    mixins: [i18n],
    props: [
      "element",
      "userRole",
    ],
    data: function () {
      return {
        pacTugboatURL: `https://pagos.migrante.com/pac/operations-to-subscribe?rut=${this.element.client.rut}${this.element.client.dv}`,
        showManualSubscriptionForm: false,
        paymentSystem: "pac",
        isSuccess: false,
        isError: false,
        successMessage: "La información se guardó satisfactoriamente.",
        errorMessage: "",
        selecteBankAccount: this.element.pac_subscription || {},
        bankAccounts: [],
        copiedLink: false,
        showContingencyForm: false,
      };
  },
    computed: {
      paymentSystems: function () {
        return PAYMENT_SYSTEMS_LIST;
      },
      bankAccountTypes: function () {
        return BANK_ACCOUNT_TYPES_LIST;
      },
      bankNames: function () {
        return BANKS_LIST;
      },
    },
    async mounted() {
        this.bankAccounts = await bankAccountApi.getFromClient(this.element.client.id)
        // set Assetplan as only default choice only if product type is "Credito GAF"
        if (this.element.product_type.id === 3) {
            const onlyAssetplanChoice = this.filterPaymentSystem("assetplan");
            this.paymentSystems = [onlyAssetplanChoice];
            this.paymentSystem = onlyAssetplanChoice.value;
        }
        else {
            this.paymentSystem = this.element?.product_type?.payment_system;
        };
        this.canShowContingencyForm();
    },
    methods: {
        copy,
        savePaymentSystem: async function () {
          const simulationId = this.element.id;
          let body = {
            payment_system: this.paymentSystem
          };
          if (this.paymentSystem === "pac") {
            body["bank_account_attributes"] = this.bankAccount;
          }
          const response = await apiSimulation.update(simulationId, body);
          if (includes([422, 500], response.status)) {
            this.errorMessage = `Error: ${response.data}`;
            this.isError = true;
            return
          }

          this.simulation = response;
          this.isSuccess = true;
          this.element.payment_system = this.paymentSystem;
          // need to reload the page in order to query the new state of PAC
          setTimeout(() => location.reload(), 3000);
        },
        filterPaymentSystem: function (criteria) {
            return this.paymentSystems.filter(payment => {
                return payment.value === criteria;
            })[0];
        },
        handleManualSubscriptionForm(value) {
          this.selecteBankAccount = EMPTY_BANK_ACCOUNT
          this.showManualSubscriptionForm = value
        },
        copyPacTugboatLink() {
          this.copy(this.pacTugboatURL);

          this.copiedLink = true;
          setTimeout(() => this.copiedLink = false, 500);
        },
        handleSelectedPac(item) {
          this.selecteBankAccount = item;
          this.showManualSubscriptionForm = false;
          if (item.status !== "active" && this.isManualSubscription(item.source)) {
            this.selecteBankAccount = item;
            this.showManualSubscriptionForm = true;
          }
        },
        async handleSavePacBankAccount() {
          const body = {
            account_type: 'pac',
            object_id: this.element.id,
            client_id: this.element.client.id,
            bank_account_id: this.selecteBankAccount.bank_account_id
          }
          const response = await bankAccountApi.updateByBankAccountType('simulation', body)

          if (response.status != 200) {
            this.errorMessage = `Error: ${response.data}`;
            this.isError = true;
            return
          }

          this.isSuccess = true;
          setTimeout(() => location.reload(), 3000);
        },
        async handleSaveManualPac(bankAccount) {
          const response = await bankAccountApi.createOrUpdate(
            {...bankAccount, client_id: this.element.client.id},
            'pac',
            this.element.id,
            'simulation'
          );

          if (!includes([200, 201], response.status)) {
            const errors = response.data.map((error, index) => {
              return ((index === response.data.length -1) && response.data.length > 1) ? ` y ${error}` : error
            });
            this.errorMessage = `Error: ${errors}`;
            this.isError = true;
            return
          }

          this.isSuccess = true;
          setTimeout(() => location.reload(), 3000);
        },
        isManualSubscription(item) {
          return includes(['arc', 'arc-legacy', 'manual-tugboat', 'contingency'], item)
        },
        canShowContingencyForm(){
          this.showContingencyForm = USER_ROLE_ALLOWED_FOR_CONTINGENCY_PAC.includes(this.userRole)
        }
    },
    components: { CheckboxField, BankAccountForm, PacSelector, NewBankAccount }
}
</script>

