import axios from 'axios'
import _ from 'lodash'
import { config } from '../constants/api'

const buildConfig = (extraconfig) => {
  return { ...config, ...extraconfig }
}

export default {
  async inventoryItemsList() {
    const path = '/inventory_items/commercial_stock.json';

    const response = await axios.get(path, config)
    return response

  },
  async availableByListPrice(vehicleModelId, itemId, campaign, simulation_product_amount, discount_amount) {
    const path = `/inventory_items/available_by_list_price.json`;

    const config = {
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      },
      params: {
        product_model_id: vehicleModelId,
        item_id: itemId,
        campaign,
        simulation_product_amount,
        discount_amount
      }
    }
    const response = await axios.get(path, config)
    return response
  },
  async availableModelsList(inventoryTypeId, brandId, productModelId, filterByListPrice = true) {
    const path = '/inventory_items/available_models.json';

    const config = buildConfig({
      params: {
        filter_by_list_price: filterByListPrice,
        inventory_type_id: inventoryTypeId,
        brand_id: brandId,
        product_model_id: productModelId
      }
    })

    const response = await axios.get(path, config)
    return response

  },
  async availableColorsByModelList(filterWithDiscount, vehicleModelId, campaign, warehouseStatusId = null) {
    const path = '/inventory_items/available_colors_by_model.json';

    const config = {
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      },
      params: {
        product_model_id: vehicleModelId,
        filter_with_discount: filterWithDiscount,
        campaign: campaign,
        warehouse_status_id: warehouseStatusId
      }
    }
    const response = await axios.get(path, config)
    return response
  },
  async vehicleBrandList() {
    const path = `/vehicle_brands.json`;

    const response = await axios.get(path, config)
    return response
  },
  async vehicleModelList() {
    const path = `/vehicle_models.json`;

    const response = await axios.get(path, config)
    return response
  },
  async colorsList() {
    const path = `/colors.json`;

    const response = await axios.get(path, config)
    return response
  },



  async modelListByBrand(brandId) {
    const path = `/product_models.json?brand_id=${brandId}`;

    const config = buildConfig({
      params: { all: 1 }
    })

    const response = await axios.get(path, config)
      .then((res) => res.data)
      .catch((error) => error.response)
    return response
  },
  async post(data) {
    const path = '/inventory_items.json'

    return await axios.post(path, data, config).then(res => res.data).catch(err => {
      return err.response
    })
  },
  async update(inventoryItemId, data) {
    const path = `/inventory_items/${inventoryItemId}.json`

    return await axios.patch(path, data, config)
      .then((res) => res.data)
      .catch((error) => error.response)
  },
  async get(inventoryItemId) {
    const path = `/inventory_items/${inventoryItemId}.json`;

    return await axios.get(path, config)
      .then((res) => res.data)
      .catch((error) => error.response)

  },


  async consolidated() {
    const path = `/inventory_types/consolidated`;

    return await axios.get(path, config)
      .then((res) => res.data)
      .catch((error) => error.response)

  },

  async brandsByInventoryType(inventoryTypeId) {
    const path = '/inventory_items/brands_by_inventory_type.json';

    const config = buildConfig({
      params: { inventory_type_id: inventoryTypeId }
    })

    return await axios.get(path, config)
      .then((res) => res.data)
      .catch((error) => error.response)
  },

  async bulkUpload(file, inventoryTypeId) {
    const path = '/inventory_items/bulk_upload.json';

    let formData = new FormData();
    formData.append('file', file);
    formData.append('inventory_type_id', inventoryTypeId);

    return await axios.post(path, formData, config)
      .then((res) => res.data)
      .catch((error) => error.response)
  },

  async searchInventoryItem(query, filter, page, limit) {
    const path = `/inventory_items/list.json`;
    const data = {
      filters: filter,
      query: query,
      page: page,
      limit: limit
    };

    return await axios.post(path, data, config)
      .then((res) => res.data)
      .catch((error) => error.message)
  },

  async brandsList(all = null, inventoryTypeId = null) {
    const path = `/brands.json`;

    const config = buildConfig({
      params: {
        all,
        inventory_type_id: inventoryTypeId
      }
    })

    return await axios.get(path, config)
      .then((res) => res.data)
      .catch((error) => error.message)
  },

  async logisticsStatus() {
    const path = `/warehouse_statuses.json`;

    return await axios.get(path, config)
      .then((res) => res.data)
      .catch((error) => error.message)
  },

  async inventoryItemFilters(filters) {
    const path = `/inventory_items/filter.json`;
    const data = { filters: filters };

    return await axios.post(path, data, config)
      .then((res) => res.data)
      .catch((error) => error.message)
  },

  async commercialStatus() {
    const path = `/inventory_items/commercial_status.json`;

    return await axios.get(path, config)
      .then((res) => res.data)
      .catch((error) => error.message)
  },

  async availableModelsByCampaign(filterWithDiscount, brandId, productModelName, productValue, inventoryItemId, fullPriceFilter = false) {
    const path = `/inventory_items/available_models_by_campaign.json`;
    let params = {
      filter_with_discount: filterWithDiscount,
      brand_id: brandId,
      product_model_name: productModelName,
      product_value: productValue,
      inventory_item_id: inventoryItemId
    }
    if(fullPriceFilter) params['full_price'] = true

    return await axios.get(path, buildConfig({ params }))
  },

  async deletePaymentsInventoryProvider(id) {
    const path = `/payments_inventory_providers/${id}.json`;

    return await axios.delete(path, config)
      .then((res) => res.data)
      .catch((error) => error.message)
  },
  async mergeLocations(fromId, toId) {
    const path = `/inventory_locations/merge.json`

    const data = {
      from_id: fromId,
      to_id: toId
    }

    return axios.post(path, data, config)
      .then((res) => res.data)
      .catch((error) => error.response)
  },
  async deleteInventoryLocation(id) {
    const path = `/inventory_locations/${id}.json`;

    return await axios.delete(path, config)
      .then((res) => res.data)
      .catch((error) => error.message)
  },
}
