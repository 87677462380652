<template>
  <div>
    <b-form-checkbox
      class="col"
      v-model="unemploymentInsurance"
      id="checkbox-7"
    >
      Seguro de cesantía
    </b-form-checkbox>

    <b-form-group v-if="unemploymentInsurance" v-slot="{ ariaDescribedby }">
      <b-form-radio v-model="planUf" :aria-describedby="ariaDescribedby" name="some-radios" value="planUf3Insurance">Plan 3xUF3</b-form-radio>
      <b-form-radio v-model="planUf" :aria-describedby="ariaDescribedby" name="some-radios" value="planUf6Insurance">Plan 3xUF6</b-form-radio>
    </b-form-group>

  </div>
</template>

<script>

export default {
  name: 'unemploymentInsuranceForm',
  mixins: [],
  components: {
  },
  props: [
    'unemploymentInsuranceP',
    // 'planUfP',
    'planUf3',
    'planUf6'
  ],
  data: function() {
    return {
      unemploymentInsurance: this.unemploymentInsuranceP,
      planUf: ''
      // planUf6P: this.planUf6P
    }
  },
  mounted: async function() {
    this.unemploymentInsurance = this.unemploymentInsuranceP
    if(this.planUf3){
        this.planUf = 'planUf3Insurance'
    }
    if(this.planUf6){
        this.planUf = 'planUf6Insurance'
    }
  },
  watch: {
    planUf: function(newValue, oldValue) {
      this.$emit('unemploymentInsuranceUpdated', {name: newValue, value: true})
    },
    unemploymentInsurance: function(newValue, oldValue) {
      if(!newValue ){
        this.planUf = ''
        this.$emit('unemploymentInsuranceUpdated', {name: 'unemploymentInsurance', value: false})
      }
    },
    planUf3: function(newValue, oldValue) {
      if(newValue){
        this.unemploymentInsurance = true
        this.planUf = 'planUf3Insurance'
      }

    },
    planUf6: function(newValue, oldValue) {
      if(newValue){
        this.unemploymentInsurance = true
        this.planUf = 'planUf6Insurance'
      }
    }
  },
  methods: {
  }
}

</script>
