<template>
    <div v-if="!edit && option" >
      {{option}}
    </div>
    <div v-else>
      <b-form  @submit="onSubmit">
        <b-row>
          <b-col cols="8">
            <b-form-group label="Nombre ítem" class="w-100" invalid-feedback="Valor es requerido">
              <b-form-input :disabled="disabled" v-model="value" placeholder="Valor de la opcion" required></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="4" class="d-flex flex-row align-items-end">
            <b-form-group class="w-100" >
              <b-button  :disabled="disabled" class="w-100" type="submit" variant="primary" >{{ edit ? 'Actualizar' : 'Agregar'}}</b-button>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </div>
</template>

<script>

  export default {
    name: 'options-form',
    components: {},
    props: [
      'disabled',
      'option',
      'index',
      'edit'
    ],
    data() {
      return {
        value: this.option || ''
      }
    },
    mounted() {
    },
    methods: {
      onSubmit(event) {
        event.preventDefault()
        if(!this.edit) { this.$emit('onOptionAdded', this.value) }
        else { this.$emit('onOptionEditted', this.value, this.index) }
        this.value = ''
      }
    }
  }
</script>
