<template>
  <div class="mb-2">
    <div :class="`${isOpen ? 'active': 'inactive'} collapsible-item`">
      <div block v-b-toggle="`collapse-${uuid}`" @click="toggleIsOpen" class="collapsible-button">
        <div class="w-100">
          <div class="d-flex">
            <div v-if="dotTittle" v-show="isOk" class="text-success mr-2"><i class="fas fa-check-circle"></i></div>
            <div v-if="dotTittle" v-show="!isOk" :class="`${isOpen ? 'dot-active' : 'dot-inactive'}`"> {{dotTitleText}} </div>
            <strong>{{title}}</strong>
            <slot name="header"></slot>
          </div>
          <slot name="sub_header"></slot>
        </div>
        <span v-show="isOpen">
          <i class="fas fa-chevron-up"></i>
        </span>
        <span v-show="!isOpen" >
          <i class="fas fa-chevron-down"></i>
        </span>
      </div>

      <b-collapse :id="`collapse-${uuid}`" class="collapse-body relative" :accordion="collapseGroup">
        <slot name=body></slot>

        <div v-if="footerButton" class="row col">
          <div class="col-4">
            <b-button variant="primary" class=""  type="button" :disabled="false" @click="validate">
              <b-spinner variant="light" type="grow" v-if="false"></b-spinner>
              {{ buttonFootterText }}
            </b-button>
          </div>
        </div>
      </b-collapse>
    </div>
  </div>
</template>

<script>
  import { v4 as uuidv4 } from 'uuid';

  export default {
    name: 'collapse-with-header',
    components: {},
    props: [
      'open',
      'title',
      'isOk',
      'buttonText',
      'dotTittle',
      'footerButton',
      'collapseGroup',
      'openFunction',
      'object'
    ],
    data() {
      return {
        buttonFootterText: this.buttonText || 'Siguiente',
        isOpen: this.open || false,
        dotTittleText: this.dotTittle || '',
        uuid: uuidv4()
      }
    },
    methods: {
      toggleIsOpen() {
        this.isOpen = !this.isOpen
        if(this.isOpen)
          this.$emit('openFunction', this.object)
      },
      validate(){
        return true
      },
    },
    mounted() {
      this.$root.$on('bv::collapse::state', (collapseId, isJustShown) => {
        if(`collapse-${this.uuid}` == collapseId)
          this.isOpen = isJustShown
      })
    }
  }
</script>
