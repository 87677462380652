<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-5 pr-0">
        <div class="card">
          <div class="card-body">
            <b-form v-model.lazy="beenModified">
              <b-alert variant="danger" show dismissible v-if="this.rateValueError && this.simulationType !== 'leasing'">La tasa del crédito no puede ser mayor a TMC</b-alert>
              <div class="row">
                <div class="col-sm-12">
                  <b-form-group label="Opciones" label-for="input-1" description="Selecciona el tipo de crédito que deseas simular">
                    <b-form-select v-model="is_reprogrammed" :options="options"></b-form-select>
                  </b-form-group>
                </div>
                <div class="col-sm-12">
                  <b-form-group label="Periodicidad de pago" label-for="input-1" description="Periodicidad de Pago">
                    <b-form-select :disabled="disabledOptions" v-model="simulation.payment_frequency" :options="payment_frequencies"></b-form-select>
                  </b-form-group>
                </div>
                <div :class="{'col-sm-12': isLeasing, 'col-sm-6': !isLeasing}">
                  <b-form-group label="Fecha de curse" label-for="input-1" description="Fecha de firma">
                    <b-form-datepicker id="sign_date" v-model="simulation.sign_date" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" locale="es" class="mb-2"></b-form-datepicker>
                  </b-form-group>
                </div>
                <div class="col-sm-6" v-show="this.simulation.payment_frequency == 'monthly'">
                  <b-form-group label="Día de pago" label-for="input-1" description="Día de pago de las cuotas">
                    <b-form-select id="payment_day" v-model="simulation.payment_day" :options="days" class="mb-2"></b-form-select>
                  </b-form-group>
                </div>
                <div class="col-sm-6" v-show="this.is_reprogrammed != 'reprogram'">
                  <b-form-group
                    :label="this.productValueLabel"
                    label-for="input-1"
                    description="Valor del Auto/Moto/Consumo"
                  >
                    <b-input-group>
                      <b-form-input
                        :disabled="hasInventoryItemSelectedOrPrice"
                        v-model.number="simulation.product_value"
                        type="number"
                        placeholder="100"
                        :no-wheel="true"
                        required></b-form-input>
                    </b-input-group>
                  </b-form-group>
                </div>
                <div class="col-sm-6" v-show="this.is_reprogrammed != 'reprogram'">
                  <b-form-group label="Valor Accesorios" label-for="input-1" description="Valor del casco u otros accesorios">
                    <b-input-group>
                      {{ extraCostsForProductValue }}
                    </b-input-group>
                  </b-form-group>
                </div>
                <div class="col-sm-6" v-show="this.is_reprogrammed != 'reprogram'">
                  <input type="radio" v-model="downPaymentType" value="0">
                  <b-form-group label="Monto pie" label-for="input-1"
                    :description="maxDownPaymentText">
                    <b-input-group>
                      <b-form-input :disabled="downPaymentType=='1'"
                        v-model.number="simulation.down_payment"
                        :lazy="true"
                        type="number"
                        placeholder="100"
                        min="0"
                        :no-wheel="true"></b-form-input>
                    </b-input-group>
                  </b-form-group>
                </div>
                <div class="col-sm-6" v-show="this.is_reprogrammed != 'reprogram'">
                  <input type="radio" v-model="downPaymentType" value="1">
                  <b-form-group label="(%) Porcentaje pie" label-for="input-1" description="Porcentaje pie">
                    <b-input-group>
                      <b-form-input :disabled="downPaymentType=='0'"
                        v-model.number="downPaymentPercentage" type="number"
                        placeholder="20%" max="100" min="0" :no-wheel="true" ></b-form-input>%
                    </b-input-group>
                  </b-form-group>
                </div>
                <div class="col-sm-6" v-show="this.is_reprogrammed != 'reprogram'">
                  <b-form-group label="Financiamiento" label-for="input-1" description="Producto + Accesorios + Gastos de inscripción - Pie">
                    <b-input-group>
                      <b-form-input :disabled="true" type="number" v-model.number="simulation.totalNetAmount" placeholder="100" required :no-wheel="true"></b-form-input>
                    </b-input-group>
                  </b-form-group>
                </div>
                <div class="col-sm-6">
                  <b-form-group label="Monto Bruto de crédito" label-for="input-1" description="Financiamiento + Gastos de crédito">
                    <b-input-group>
                      <b-form-input :disabled="disableGrossAmountInput()" v-model.number="simulation.gross_amount" placeholder="100" required :no-wheel="true"></b-form-input>
                    </b-input-group>
                  </b-form-group>
                </div>
                <div class="col-sm-6" v-show="this.is_reprogrammed != 'leasing'">
                  <b-form-group label="Tasa del crédito (Mensual)" label-for="input-1" description="Indica tasa del crédito">
                    <b-input-group>
                      <b-form-input
                        v-model="simulation.interest_rate_per_month"
                        v-bind:placeholder="installmentRateDefaultPlaceholder"
                        required
                        :disabled="!canEditSimulationRate && is_reprogrammed == 'reprogrammed'"
                      />
                    </b-input-group>
                  </b-form-group>
                </div>
                <div class="col-sm-6">
                  <b-form-group label="Cuotas" label-for="input-1" description="Especifica número de cuotas a pagar">
                    <b-form-input v-model="simulation.installments_amount" type="number" placeholder="10" required :no-wheel="true"></b-form-input>
                  </b-form-group>
                </div>
                <div class="col-sm-6" v-show="this.is_reprogrammed != 'leasing'">
                  <b-form-group label="Periodos de gracia" label-for="input-1" description="Especifíca número de períodos de gracia">
                    <b-form-input v-model="simulation.grace_period" placeholder="" required></b-form-input>
                  </b-form-group>
                </div>
                <div class="col-sm-6" v-if="is_reprogrammed !== 'leasing'">
                  <b-form-group label="Valor de cuota" label-for="input-1" description="Nota: Si no especifícas valor, el simulador calculará un valor de cuota.">
                    <b-form-input
                      v-model="simulation.installment_value"
                      placeholder="0"
                      required
                    />
                  </b-form-group>
                </div>
                <div class="col-sm-6" v-else>
                  <b-form-group label="Valor de cuota (con IVA)" label-for="input-1" description="Indica valor de cuota que pagará el cliente">
                    <b-form-input
                      v-model="simulation.installment_value"
                      :disabled="isReprogrammedOrHasLeasingValue"
                      placeholder="0"
                      required
                    />
                  </b-form-group>
                </div>
                <div :class="{'col-sm-12': isLeasing, 'col-sm-6': !isLeasing}">
                  <b-form-group label="Fecha primer pago" label-for="input-1" description="Fecha de la primera cuota a pagar">
                    <b-form-datepicker id="first_payment_date" :disabled="this.is_reprogrammed != 'leasing'" v-model="simulation.first_payment_date" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" locale="es" class="mb-2"></b-form-datepicker>
                  </b-form-group>
                </div>
                <div class="col-sm-6" v-show="this.is_reprogrammed != 'leasing'">
                  <b-form-group label="Fecha último pago" label-for="input-1" description="Fecha última cuota a pagar">
                    <b-form-datepicker id="last_date" :disabled="!this.isLeasing" v-model="simulation.last_date" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" locale="es" class="mb-2"></b-form-datepicker>
                  </b-form-group>
                </div>
                <div class="col-sm-12 mtp-12">
                  <b-button
                    block
                    variant="primary"
                    @click="calculate"
                    :disabled="!canCreateSimulation || loading"
                    size="lg"
                  >
                    <b-spinner v-if="loading" />
                    <div v-else>
                      Calcular <span v-if="isEditingSimulation()">y guardar</span>
                    </div>
                  </b-button>
                </div>
              </div>
            </b-form>
          </div>
        </div>
        <b-list-group class="mt-3" v-show="hasInstallments()" >
          <b-list-group-item v-if="firstInstallmentIsZero()" variant="warning">
            El valor de la cuota es cero
          </b-list-group-item>
          <b-list-group-item v-else>
            Cuota: {{ this.installmentValue | currency('$', 0, { thousandsSeparator: '.', decimalSeparator: ',' }) }}
          </b-list-group-item>
          <b-list-group-item v-if="!hasClientIncomesAverage()" variant="warning">
            No se ha registrado el ingreso promedio
          </b-list-group-item>
          <b-list-group-item v-else>
            Sueldo promedio: {{ this.clientIncomesAverage | currency('$', 0, { thousandsSeparator: '.', decimalSeparator: ',' }) }}
          </b-list-group-item>
          <b-list-group-item v-show="hasClientIncomesAverage()">
            Ratio de cuota: {{ this.installmentRatio | percent(3) }}
          </b-list-group-item>
          <b-list-group-item v-show="hasClientIncomesAverage()">
            Ratio financiamiento bruto / sueldo: {{ parseFloat(this.grossAmountIncomesRatio).toFixed(3) }}
          </b-list-group-item>
        </b-list-group>
      </div>
      <div class="col-sm-7 p-0">
        <b-jumbotron v-if="cdpError && !loading" header="" lead="">
          <div class="text-center">
            <h2>CDP Incorrecto</h2>
            <p>Hemos detectado que la última cuota de tu CDP es incorrecta</p>
            <p>No se encontró un valor de cuota adecuado para el número de cuotas indicadas</p>
            <p>Te recomendamos realizar el cálculo nuevamente revisando los datos ingresados</p>
          </div>
        </b-jumbotron>
        <b-table v-if="isCalculated && !cdpError" striped hover :items="simulation.formattedInstallments" :fields="fields">
        </b-table>
        <b-jumbotron v-if="!isCalculated" header="" lead="">
          <div  v-if="!loading" class="text-center">
            <h2>Simula tu crédito</h2>
            <p>Ingresa la información del crédito para calcular el cuadro de pago</p>
          </div>
          <div  v-if="loading" class="text-center">
            <h2>Calculando</h2>
            <p>Estamos generando su cuadro de pago</p>
            <b-spinner variant="primary" label="Calculando" style="width: 3rem; height: 3rem;"></b-spinner>
          </div>
        </b-jumbotron>
      </div>
    </div>
  </div>
</template>

<script>
import simulationApi from '../../api/simulations';
import clientsApi from '../../api/clients';

import _ from 'lodash'

import {
  tableFields,
  paymentDaysDropDownList,
  creditTypeDropDownList,
  paymentFrecuencyDropDownList,
  FIXED_LEASING_INSTALLMENTS_COUNT,
} from '../../constants/simulator'

export default {
  name: 'CreditSimulator',
  components: {},
  props: [
    'clientId',
    'colorId',
    'currentSimulation',
    'driversLicense',
    'extraCostsGrossAmount',
    'extraCostsNetAmount',
    'initialGrossAmount',
    'initialInstallments',
    'initialNetAmount',
    'initialTotalNetAmount',
    'installmentsCount',
    'lienInsuranceAmount',
    'originCreditId',
    'originCreditInstallmentAmount',
    'pipedriveId',
    'productType',
    'rateValueError',
    'selectedExtraCosts',
    'signDate',
    'simulationType',
    'stampDutyAmount',
    'tmc',
    'userRole',
    'simModelColorPreference',
    'reprogramType',
  ],
  data: function() {
    return {
      simulation: {
        client_id: this.clientId || null,
        down_payment: 0,
        down_payment_percentage: 0,
        extra_costs_attributes: this.selectedExtraCosts,
        first_payment_date: "",
        formattedInstallments: [],
        grace_period: 0,
        gross_amount: this.initialGrossAmount || 0,
        installment_value: this.originCreditInstallmentAmount,
        installments: [],
        installments_amount: this.installmentsCount,
        interest_rate_per_month: this.tmc || 0,
        last_date: "",
        lienInsuranceAmount: this.lienInsuranceAmount,
        net_amount: this.initialNetAmount || 0,
        origin_credit_id: this.originCreditId,
        payment_day: 1,
        payment_frequency: this.payment_frequency || 'monthly',
        pipedrive_id: this.pipedriveId || null,
        product_type_id: null,
        product_value: this.initialNetAmount || 0,
        sign_date: this.signDate,
        simulation_type: this.simulationType,
        stampDutyAmount: this.stampDutyAmount,
        totalNetAmount: this.initialTotalNetAmount|| 0,
      },
      beenModified: '',
      cdpError: false,
      clientIncomesAverage: null,
      days: paymentDaysDropDownList,
      disabledOptions: false,
      disabled_input: true,
      downPaymentPercentage: 0,
      downPaymentType: 1,
      fields: tableFields,
      installmentValue: null,
      isCalculated: false,
      isLeasing: false,
      is_reprogrammed: this.simulationType,
      latestOutstandingBalance: 0,
      loading: false,
      options: creditTypeDropDownList,
      payment_frequencies: paymentFrecuencyDropDownList,
      validateSimulation: false,
      reprogramType: this.reprogramType,
      installmentRateDefaultPlaceholder: this.setInstallmentRateDefaultPlaceholder(),
    }
  },
  methods: {
    firstInstallmentIsZero: function() {
      return this.installmentValue === 0
    },
    updateMonthsForLienInsurance: function() {
      const monthsForLienInsurance = Math.ceil(this.$moment(this.simulation.last_date).diff(this.$moment(this.simulation.sign_date), 'days') / 30)
      this.$emit('monthsForLienInsuranceUpdated', monthsForLienInsurance);
    },
    checkSimulationDate: function() {
      const simulation = _.mapKeys(this.simulation, (v, k) => _.camelCase(k))

      let { signDate, gracePeriod, installmentsAmount, paymentDay } = simulation //we need to overwrite with correct format
      const { isReprogrammed, simulationType, paymentFrequency } = simulation

      gracePeriod = parseInt(gracePeriod)
      signDate = this.$moment(signDate)
      installmentsAmount = parseInt(installmentsAmount)
      paymentDay = parseInt(paymentDay)

      let frecuencyType;
      let firstPayment;
      let lastPayment;
      let periodsInFirstPayment = 1 + gracePeriod;
      let periodsInLastPayment = periodsInFirstPayment + installmentsAmount;

      switch(paymentFrequency) {
        case 'weekly': {
          frecuencyType = 'weeks';
          break;
        }
        case 'monthly': {
          frecuencyType = 'months';
          break;
        }
        case 'byweekly': {
          frecuencyType = 'days';
          periodsInFirstPayment = (1 + gracePeriod) * 15;
          periodsInLastPayment = (1 + gracePeriod + installmentsAmount) * 15;
          break;
        }
        default: {
          console.log(`We don't support this simulation.paymentFrequency: ${paymentFrequency}`)
          break;
        }
      }

      firstPayment = signDate.clone().add(periodsInFirstPayment, frecuencyType)
      lastPayment = signDate.clone().add(periodsInLastPayment, frecuencyType)

      if (paymentFrequency == 'monthly') {
        firstPayment = firstPayment.date(paymentDay)
        lastPayment = lastPayment.date(paymentDay)
      }

      this.simulation.first_payment_date = firstPayment.format('YYYY-MM-DD')
      this.simulation.last_date = lastPayment.clone().subtract(1, frecuencyType).format('YYYY-MM-DD')
    },
    setClientIncomesAverage: async function() {
      const clientId = this.clientId || this.simulation.client_id
      const response = await clientsApi.getIncomesAverage(clientId)
      this.clientIncomesAverage = response
    },
    calculate: async function() {
      try {
        this.loading = true
        this.isCalculated = false
        this.simulation.drivers_license = this.driversLicense
        this.simulation.extra_costs_attributes = this.selectedExtraCosts
        this.simulation.lienInsuranceAmount = this.lienInsuranceAmount
        this.simulation.stampDutyAmount = this.stampDutyAmount
        this.simulation.sim_model_color_preferences_attributes = this.simModelColorPreference

        if (!_.isNil(this.productType)){
          this.simulation.product_type_id = this.productType.id
        }

        let response = {}
        const isEditingSimulation = this.isEditingSimulation()

        if (!isEditingSimulation){
          response = await simulationApi.create(this.simulation)
        } else {
          response = await simulationApi.recalculate(
            this.currentSimulation.id,
            {
              ...this.simulation,
              client_id: this.currentSimulation.client_id,
              status: this.currentSimulation.status
            }
          )
          this.currentSimulation = response.data
          this.$emit('clearProducts')
          this.$emit('updateProducts', response.data?.sim_model_color_preferences || [])
        }

        this.isCalculated = true
        this.cdpError = false
        if(this.is_reprogrammed == 'leasing') {
          this.fields = tableFields
          this.latestOutstandingBalance = response.data.installments[response.data.installments.length - 1].outstanding_balance
        } else {
          this.fields = _.filter(tableFields, field => field.key !== 'vat');
        }

        // these installments are shown on the installments table
        this.simulation.formattedInstallments = response.data.installments.map((installment) => {
          const formattedInstallment = {
            ...installment,
            due_date: this.$moment(installment.due_date).format("DD/MM/YYYY"),
            outstanding_balance: this.$options.filters.currency(installment.outstanding_balance, '$', 0, { thousandsSeparator: '.', decimalSeparator: ',' }),
            capital: this.$options.filters.currency(installment.capital, '$', 0, { thousandsSeparator: '.', decimalSeparator: ',' }),
            interest_paid: this.$options.filters.currency(installment.interest_paid, '$', 0, { thousandsSeparator: '.', decimalSeparator: ',' }),
            installment_value: this.$options.filters.currency(installment.installment_value, '$', 0, { thousandsSeparator: '.', decimalSeparator: ',' }),
          }

          if (formattedInstallment.installment_value === '$0' && formattedInstallment.period > 0) {
            formattedInstallment['_rowVariant'] = 'warning'
          }

          if(this.is_reprogrammed == 'leasing') {
            formattedInstallment.vat = this.$options.filters.currency(Number(installment.vat), '$', 0, { thousandsSeparator: '.', decimalSeparator: ',' })
          }

          return formattedInstallment
        })

        this.simulation.installments = response.data.installments.map((installment) => {
          const newInstallment = {
            ...installment,
            due_date: this.$moment(installment.due_date),
          }

          if(this.is_reprogrammed == 'leasing') {
            newInstallment.vat = Number(installment.vat)
          }

          return newInstallment
        })

        this.$emit('simulationCreated', response.data.id)
        this.loading = false
      } catch (e) {
        console.log(e)
        // TODO fix the response.data undefined
        // alert("No se pudo calcular, si el problema persiste contacta a soporte de tecnología para que te ayuden");
      }
    },
    setInstallmentValue: function() {
      const firstPeriodInstallment = _.find(this.simulation.installments, { period: 1 })
      this.installmentValue = firstPeriodInstallment.installment_value
    },
    disableGrossAmountInput: function() {
      return this.is_reprogrammed != 'reprogram'
    },
    calculateDefaultGracePeriod: function() {
      if(this.simulation.grace_period > 0) { return }

      const day = this.$moment(this.simulation.sign_date).format('D');
      if (this.simulation.payment_frequency == 'monthly' && day > 15) {
        this.simulation.grace_period = 1
      } else {
        this.simulation.grace_period = 0
      }
    },
    isEditingSimulation: function(){
      return !_.isNil(this.currentSimulation)
    },
    hasInstallments: function() {
      return !_.isEmpty(this.simulation.installments)
    },
    hasClientIncomesAverage: function() {
      return !(_.isNil(this.clientIncomesAverage) || this.clientIncomesAverage === 0)
    },
    setProductType: function(value, oldValue) {
      this.simulation.product_type_id = value.id
      // set simulationType as leasing if productTypeId is 'Moto Leasing'
      if(value && !_.isNil(oldValue)){
        this.isCalculated = false
      }

      if (_.includes(_.lowerCase(value.name), 'leasing')) {
        this.is_reprogrammed = 'leasing'
        this.simulation.payment_frequency = 'weekly'
      } else {
        if (this.is_reprogrammed != 'reprogram') {
          this.is_reprogrammed = 'normal'
        }

        this.simulation.payment_frequency = 'monthly'
      }
    },
    setInstallmentRateDefaultPlaceholder: function() {
      if (this.reprogramType === 'compromise')
        return 0
      else {
        return 0.02
      }
    }
  },
  computed: {
    canEditSimulationRate: function () {
      return this.userRole.includes('can_modify_simulation_rate')
    },
    hasInventoryItemSelectedOrPrice: function() {
      return (!_.isNil(this.simModelColorPreference) &&
              this.simModelColorPreference.length > 0 &&
              this.simModelColorPreference.reduce((acc, curr) => acc += Number(curr.productModel?.list_price || 0) , 0) > 0
             )
    },
    isReprogrammedOrHasLeasingValue: function() {
      return this.is_reprogrammed !== 'leasing'
        || (this.hasInventoryItemSelectedOrPrice &&
          this.simModelColorPreference.reduce((acc, curr) => acc += Number(curr.productModel?.leasing_installment_value || 0) , 0) > 0)
    },
    grace_period() {
      return this.simulation.grace_period || 0;
    },
    interest_rate_per_month() {
      return this.simulation.interest_rate_per_month || 0;
    },
    payment_day() {
      return this.simulation.payment_day || 0;
    },
    sign_date() {
      return this.simulation.sign_date;
    },
    canCreateSimulation() {
      if (_.isNil(this.simulation.product_type_id)) { return false }
      if (this.simulation.product_value === 0 && this.simulationType !== 'reprogram') { return false }
      if (this.rateValueError && this.simulationType !== 'leasing') { return false}
      if (this.interest_rate_per_month <= 0 && this.reprogramType !== 'compromise') { return false }
      if ((this.simulation.installments_amount * (this.simulation.installment_value || 0) < this.simulation.gross_amount) && this.simulation.simulation_type !== 'normal') {
        return false
      }
      return true
    },
    installmentRatio() {
      if(!this.hasInstallments() || !this.hasClientIncomesAverage()) { return 0 }

      return this.installmentValue / this.clientIncomesAverage
    },
    grossAmountIncomesRatio() {
      if(!this.hasClientIncomesAverage()) { return 0 }

      return this.simulation.gross_amount / this.clientIncomesAverage
    },
    extraCostsForProductValue: function() {
      return Number(
        this.selectedExtraCosts
          .filter(({category}) => category === 'accessory')
          .filter((ec) => ec._destroy !== 1)
          .reduce((sum, val) => Number(sum) + Number(val.amount), 0)
      )
    },
    totalProductValue: function() {
      return (this.simulation.product_value + (this.extraCostsForProductValue || 0))
    },
    maxDownPaymentText: function() {
      const maxProductValue =
        this.$options.filters.currency(Number(this.simulation.down_payment * 5), '$', 0, { thousandsSeparator: '.', decimalSeparator: ',' })

      return `Valor máximo de producto: ${maxProductValue}`
    },
    productValueLabel: function() {
      return `Valor del producto ${this.isLeasing ? '(Sin IVA)' : ''}`
    },
  },
  watch: {
    simModelColorPreference: function(newValue, oldValue) {
      if(!newValue) { return }
      if (this.isLeasing) {
        this.simulation.product_value = Number(newValue.reduce((acc, curr) => acc += Number(curr.productModel?.list_price || 0) , 0) / 1.19)
        this.simulation.installment_value = newValue.reduce((acc, curr) => acc += Number(curr.productModel?.leasing_installment_value || 0) , 0)
      } else {
        this.simulation.product_value = newValue.reduce((acc, curr) => acc += Number(curr.productModel?.list_price || 0) , 0)
      }
    },

    productType: function(newValue, oldValue) {
      // set product_type_id as the selected productType
      this.setProductType(newValue, oldValue)
    },
    'simulation.product_value': function(newValue, oldValue) {
      this.simulation.net_amount = newValue + (this.extraCostsForProductValue || 0) - (this.simulation.down_payment || 0)
      if (this.downPaymentType === "0"){
        if (this.totalProductValue > 0){
          this.downPaymentPercentage = this.simulation.down_payment / this.totalProductValue * 100;
        }
      }else{
        this.simulation.down_payment = Math.round((newValue + (this.extraCostsForProductValue || 0)) * this.simulation.down_payment_percentage);
      }
      this.$emit('productValueUpdated', newValue)
      this.$emit('netAmountUpdated', this.simulation.net_amount)
    },
    'extraCostsForProductValue': function(newValue, oldValue) {
      this.simulation.net_amount = this.simulation.product_value + (newValue || 0) - (this.simulation.down_payment || 0)
      if (this.downPaymentType === "0"){
        if (this.totalProductValue > 0){
          this.downPaymentPercentage = this.simulation.down_payment / this.totalProductValue * 100;
        }
      }else{
        this.simulation.down_payment = Math.round((this.simulation.product_value + (newValue || 0)) * this.simulation.down_payment_percentage);
      }
      this.$emit('netAmountUpdated', this.simulation.net_amount)
    },
    'simulation.down_payment': function(newValue, oldValue) {
      this.simulation.net_amount = this.simulation.product_value + (this.extraCostsForProductValue || 0) - newValue
      if (this.totalProductValue > 0){
        this.downPaymentPercentage = newValue / this.totalProductValue  * 100;
      }
      this.$emit('netAmountUpdated', this.simulation.net_amount)
    },
    'simulation.down_payment_percentage': function(newValue, oldValue) {
      this.simulation.down_payment = Math.round((this.simulation.product_value + (this.extraCostsForProductValue || 0)) * newValue);
    },
    'downPaymentPercentage': function(newValue, oldValue) {
      this.simulation.down_payment_percentage = newValue / 100;
    },

    'simulation.net_amount': function(newValue, oldValue) {
      const amount = Number(newValue)
      // for reprogram net amount extra costs are 0 at first
      const totalNetAmount = amount + Number(this.extraCostsNetAmount || 0)

      this.simulation.totalNetAmount = totalNetAmount
      this.simulation.gross_amount = totalNetAmount + Number(this.extraCostsGrossAmount);
      this.$emit('totalNetAmountUpdated', totalNetAmount)
    },
    'simulation.gross_amount': function(newValue, _oldValue) {
      this.$emit('grossAmountUpdated', newValue)
    },
    'simulation.installments_amount': function(newValue, oldValue) {
      this.checkSimulationDate()
      this.$emit('installmentsAmountUpdated', newValue);
    },
    'simulation.last_date': function(newValue, oldValue) {
      this.updateMonthsForLienInsurance()
    },
    'simulation.installments': function(newValue, oldValue) {
      this.setInstallmentValue()
    },
    clientId: function(newValue, oldValue) {
      this.simulation.client_id = newValue
      this.setClientIncomesAverage()
    },
    'simulation.client_id': function() {
      this.setClientIncomesAverage()
    },
    beenModified: function() {
      this.isCalculated = false
      this.loading = false
    },
    isCalculated: function(newValue, oldValue) {
      this.$emit('hideSaveButton', newValue)
    },
    initialInstallments: function(newValue, oldValue) {
      this.simulation.special_installments = newValue
    },
    is_reprogrammed: function(newValue, oldValue) {
      if(this.is_reprogrammed == 'reprogram') {
        this.isLeasing = false
        this.disabled_input = true
        this.disabledOptions = true
      } else if (this.is_reprogrammed == 'normal') {
        this.isLeasing = false
        this.disabled_input = false
        this.disabledOptions = false
        this.simulation.installment_value = 0
      } else if (this.is_reprogrammed == 'leasing') {
        this.isLeasing = true
        this.disabled_input = false
        this.disabledOptions = false
        this.simulation.installment_value = 0//Number(this.vehicleModel?.leasing_installment_value)
      }
      this.simulation.simulation_type = this.is_reprogrammed
      this.$emit('simulationTypeUpdated', this.is_reprogrammed)
    },
    grace_period() { this.checkSimulationDate() },
    interest_rate_per_month(newValue, oldValue) {
      this.$emit('rateUpdated', newValue)
    },
    installments_amount() {
      this.checkSimulationDate()
    },
    payment_day() {
      this.checkSimulationDate()
    },
    sign_date(newValue, oldValue) {
      this.$emit('signDateUpdated', newValue)
      this.calculateDefaultGracePeriod()
      this.simulation.sign_date = newValue
      this.checkSimulationDate()
      this.updateMonthsForLienInsurance()
    },
    tmc: function(newValue, oldValue) {
      this.simulation.interest_rate_per_month = Number(newValue)
    },
    initialGrossAmount(newValue, oldValue) {
      this.simulation.gross_amount = Number(newValue)
    },
    initialNetAmount(newValue, oldValue) {
      this.simulation.net_amount = Number(newValue)
    },
    initialTotalNetAmount(newValue, oldValue) {
      this.simulation.totalNetAmount = Number(newValue)
    },
    installmentsCount(newValue, oldValue) {
      this.simulation.installments_amount = Number(newValue)
    },
    latestOutstandingBalance(newValue, oldValue) {
      if(newValue < -1) {
        this.validateSimulation = true
        this.cdpError = true
        this.$emit('disabledButton', true)
      } else {
        this.validateSimulation = false
        this.cdpError = false
      }
    },
    currentSimulation(newValue, oldValue) {
      if(!oldValue) { return }

      this.options = this.options.filter((o) => o.value !== 'reprogram')
      this.simulation.client_id = newValue.client.id
      this.is_reprogrammed = newValue.simulation_type
      this.simulation.grace_period = newValue.grace_period
      this.simulation.payment_frequency = newValue.payment_frequency
      this.simulation.payment_day = this.$moment(newValue.first_payment_date).date()
      this.simulation.installment_value = Number(newValue.installment_value)
      this.simulation.sign_date = newValue.sign_date
      this.simulation.interest_rate_per_month = newValue.interest_rate_per_month
      this.simulation.first_payment_date = newValue.first_payment_date
      this.simulation.product_value = Number(newValue.net_amount) + Number(newValue.down_payment || 0) - (this.extraCostsNetAmount || 0) - (this.extraCostsForProductValue || 0)
      this.simulation.down_payment = Number(newValue.down_payment)
      // this.simulation.product_model_id = Number(newValue.product_model?.id)
      // this.simulation.color_id = Number(newValue.color?.id)
      this.downPaymentType = "0"
      this.simulation.sim_model_color_preferences_attributes = newValue.sim_model_color_preferences_attributes

      // this.simulation.down_payment_percentage = newValue.down_payment_percentage
      this.isCalculated = true
      // this.simulation.installments = newValue.installments

      // TODO: move this code to a method
      this.simulation.formattedInstallments = newValue.installments.map((installment) => {
        const formattedInstallment = {
          ...installment,
          due_date: this.$moment(installment.due_date).format("DD/MM/YYYY"),
          outstanding_balance: this.$options.filters.currency(installment.outstanding_balance, '$', 0, { thousandsSeparator: '.', decimalSeparator: ',' }),
          capital: this.$options.filters.currency(installment.capital, '$', 0, { thousandsSeparator: '.', decimalSeparator: ',' }),
          interest_paid: this.$options.filters.currency(installment.interest_paid, '$', 0, { thousandsSeparator: '.', decimalSeparator: ',' }),
          installment_value: this.$options.filters.currency(installment.installment_value, '$', 0, { thousandsSeparator: '.', decimalSeparator: ',' }),
        }

        if (formattedInstallment.installment_value === '$0' && formattedInstallment.period > 0) {
          formattedInstallment['_rowVariant'] = 'warning'
        }

        if (this.is_reprogrammed == 'leasing') {
          formattedInstallment.vat = this.$options.filters.currency(Number(installment.vat), '$', 0, { thousandsSeparator: '.', decimalSeparator: ',' })
        }

        return formattedInstallment
      })

      this.simulation.installments = newValue.installments.map((installment) => {
        const newInstallment = {
          ...installment,
          due_date: this.$moment(installment.due_date),
        }

        if(this.is_reprogrammed == 'leasing') {
          newInstallment.vat = Number(installment.vat)
        }

        return newInstallment
      })
      this.$emit('currentSimulationUpdated', newValue);
    },
    'simulation.payment_frequency': function() {
      this.checkSimulationDate()
    },
    'simulation.interest_rate_per_month': function() {
      if(parseFloat(this.simulation.interest_rate_per_month) > parseFloat(this.tmc))
        this.simulation.interest_rate_per_month = this.tmc
    },
  },
  mounted() {
    if(this.productType?.id) {
      this.setProductType(this.productType, null)
    }

    if(this.simulationType === 'reprogram') {
      this.options = this.options.filter((o) => o.value === 'reprogram')
    } else if(this.simulationType === 'leasing') {
      this.options = this.options.filter((o) => o.value === 'leasing')
    } else {
      this.options = this.options.filter((o) => o.value !== 'reprogram')
    }
    this.checkSimulationDate()
    if(this.clientId) {
      this.setClientIncomesAverage()
    }
  },
}
</script>
