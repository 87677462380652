<template>
  <div>
    <b-form-select v-if="!isExecuted && !isDelivered && !isWaiting" v-model="selected" :options="options" />
    <div v-else-if="isDelivered">Entregado</div>
    <div v-else-if="isWaiting">Esperando producto</div>
    <div v-else>Ejecutado</div>
  </div>
</template>
<script>
import { dropdownStatuses } from '../../constants/statuses'

export default {
  name: 'dropdown-status',
  props: ['status'],
  data: function() {
    return {
      options: dropdownStatuses,
      selected: dropdownStatuses.filter( x => x.value == this.status )[0].value
    }
  },
  computed: {
    isExecuted: function() {
      return this.status === 'executed'
    },
    isWaiting: function() {
      return this.status == 'waiting_delivery'
    },
    isDelivered: function() {
      return this.status === 'delivered'
    },
  },
  watch: {
    selected: function(newValue) {
      if (typeof newValue === 'undefined') { return }

      this.$emit('updateStatus', newValue)
    }
  },
}
</script>
