<template>
  <div>
    <strong>{{title}}</strong>
    <div v-if=condition>
      <b-table striped hover :items="items" :fields="fields"></b-table>
    </div>
    <div v-else>
      {{t('activerecord.attributes.simulation.evaluation.no_information')}}
    </div>
  </div>
</template>

<script>
import i18n from "../mixins/i18n"

export default {
  name: "conditional-table",
  mixins: [i18n],
  props: [
    'title',
    'items',
    'fields',
    'condition'
  ]
}
</script>
