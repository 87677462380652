import axios from 'axios';
import * as humps from 'humps';

export default {
  getPacs(){
    const path = '/pacs.json';
    const headers = {
      'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
    }

    return axios.get(
      path,
      {
        headers
      }
    ).then(res => res.data)
  },
  updatePac(pac){
    const path = `/pacs/${pac.id}`;
    const body = {
      pac: pac
    }

    const header = { 
      'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
    }

    return axios.patch(
      path,
      humps.decamelizeKeys(body), {
        headers
      }
    ).then(res => res.data);
  },
  destroyPac(){
    console.log("Not implemented yet");
  },
  createPac(pac){
    const path = '/pacs';
    const body = {
      pac: pac
    }

    const headers = {
      'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
    }

    return axios.post(
      path,
      humps.decamelizeKeys(body), {
        headers
      }
    ).then(res => res.data)
  },
}