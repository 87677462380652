<template>
  <div>
    <div class="card shadow mb-4">
      <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
        <h6 class="m-0 font-weight-bold text-primary">Bancos</h6>
        <b-button variant="primary" v-b-modal.bank-modal>Agregar banco</b-button>
      </div>
      <div class="card-body">
        <b-table striped hover :items="banks" :fields="fields">
          <template v-for="(field, index) in fields" v-slot:[`cell(${field.key})`]="data">
            <div v-if="field.key === 'content_type'">
              <b-button variant='primary' @click="editBank(data.item)" v-b-modal.bank-modal-edit>Editar</b-button>
            </div>
            <div v-else-if="field.key === 'code'">
              {{data.item.code}}
            </div>
            <div v-else-if="field.key === 'id'">
              {{data.item.id}}
            </div>
            <div v-else>
              {{data.item.name}}
            </div>
          </template>
        </b-table>
      </div>
    </div>

    <b-modal ref="bank-modal" id="bank-modal" no-close-on-backdrop title="Agregar Banco" ok-only ok-title="Aceptar" @ok="createBank">
      <form @submit.stop.prevent="handleSubmit">
        <b-form-group invalid-feedback="Nombre es requerido">
          <b-form-input v-model="bank.name" placeholder="Nombre del banco"></b-form-input>
        </b-form-group>
        <b-form-group invalid-feedback="Código es requerido">
          <b-form-input v-model="bank.code" placeholder="Código de banco"></b-form-input>
        </b-form-group>
      </form>
    </b-modal>

    <b-modal ref="bank-modal-edit" id="bank-modal-edit" title="Editar Banco" ok-only ok-title="Aceptar" no-close-on-backdrop @ok="updateBank">
      <form @submit.stop.prevent="handleSubmit">
        <b-form-group invalid-feedback="Nombre es requerido">
          <b-form-input v-model="bank.name" placeholder="Nombre del banco"></b-form-input>
        </b-form-group>
        <b-form-group invalid-feedback="Código es requerido">
          <b-form-input v-model="bank.code" placeholder="Código de banco"></b-form-input>
        </b-form-group>
      </form>
    </b-modal>
  </div>
</template>
<script>
  import _ from 'lodash';
  import bankApi from '../../api/banks';

  export default {
    components: {},
    props: {
    },
    data() {
      return {
        fields: [
          {
            key: 'id',
            label: "ID",
            sortable: true
          },
          {
            key: 'name',
            label: 'Nombre',
            sortable: true
          },
          {
            key: 'code',
            label: 'Código',
            sortable: true
          },
          {
            key: 'content_type',
            label: ''
          }
        ],
        banks: [],
        loading: false,
        bank: {},
      }
    },
    methods: {
      getBanks() {
        bankApi.getBanks()
          .then(data => {
            this.banks = data;
          })
      },
      loadBank() {
        this.bank = this.getBanks() || []
      },
      createBank(){
        if(this.bank) {
          bankApi.newBank(this.bank)
            .then(data => {
              this.addRow(data)
            })
        }
      },
      updateBank(){
        if(this.bank) {
          bankApi.updateBank(this.bank)
            .then(data => {
              this.bank = {}
              this.$bvToast.toast('Se han actualizado los datos correctamente', {
                title: 'Actualización Banco',
                variant: 'success',
                solid: true
              })
            })
        }
      },
      addRow(bank) {
        this.banks.push(bank)
        this.bank = {}
      },
      newBank() {
        this.bank = {}
        this.$refs['bank-modal'].show()
      },
      editBank(bank){
        this.bank = bank
        this.$refs['bank-modal-edit'].show()
      },
    },
    mounted(){
      this.loadBank();
    }
  }
</script>
