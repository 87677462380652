<template>
  <!-- DUE DATE FORM -->
  <div>
    <b-form-group :label="t(`admission.rut_due_date_form.choose_requirement`)">
      <b-container fluid>
        <b-row align-h="start">
          <b-col>
            <b-form-checkbox
              id="rut_due_date_checkbox"
              v-model="valueToSave"
              name="rut_due_date_checkbox"
              :value="{ 'operator': '>', 'value': 'today' }"
              :unchecked-value="{ 'operator': null, 'value': null }"
              @input="$emit('validateDueDate', valueToSave)"
            >
              {{ t(`admission.rut_due_date_form.valid`) }}
            </b-form-checkbox>
          </b-col>
        </b-row>
      </b-container>
    </b-form-group>
  </div>
</template>

<script>
  // Imports
  import i18n from "../mixins/i18n"
  import capitalize from "../mixins/capitalize"

  export default {
    mixins: [i18n, capitalize],
    name: 'rut-due-date-component',
    components: {
      // Add components as: CoolComponent,
    },
    props: [
      // Properties
    ],
    emits: [
      'validateDueDate'
    ],
    data: function() {
      return {
        savedValue: { 'operator': null, 'value': null }
      }
    },
    methods: {
      // Actions
    },
    computed: {
      // To parse received data
      valueToSave: {
        get() {
          return this.savedValue
        },
        set(newVal) {
          this.savedValue = newVal
        }
      }
    },
    mounted: async function() {
    }
  }
</script>

<style>

</style>
