import i18n from './i18n'

export default {
  mixins: [i18n],
  methods: {
    rulesGroupDescriptor: function(rulesGroup) {
      const noActorEvaluators = ['down_payment', 'gross_amount', 'installments_quantity', 'product_type']
      let rulesGroupActor = ""
      let subtitleMix = []
      let creditTypeValue = ""

      if (this.rulesGroupHasIdentifiers(rulesGroup)) {
        const client_simulation_relation_identifier = rulesGroup.identifiers.find(identifier => identifier.evaluator === 'client_simulation_relation')
        if (client_simulation_relation_identifier) {
          rulesGroupActor = this.t(`admission.show_requirements.by_evaluator.client_simulation_relation.value.${client_simulation_relation_identifier.criteria.value}`)
        }
        const credit_type_identifier = rulesGroup.identifiers.find(identifier => identifier.evaluator === 'credit_type')
        if (credit_type_identifier) {
          creditTypeValue = credit_type_identifier.criteria.value
        }
        for (var i = 0; i < rulesGroup.identifiers.length; i++) {
          let identifier = rulesGroup.identifiers[i]
          if (identifier.evaluator != "credit_type" && identifier.evaluator != "client_simulation_relation") {
            let parsedIdentifier = {}
            if (noActorEvaluators.includes(identifier.evaluator)) {
              parsedIdentifier["actor"] = ""
            }
            else if (identifier.redirect) {
              parsedIdentifier["actor"] = this.t(`admission.show_requirements.by_evaluator.client_simulation_relation.value.${identifier.redirect}`)
            }
            else {
              parsedIdentifier["actor"] = rulesGroupActor
            }
            parsedIdentifier["evaluator"] = this.t(`admission.show_requirements.evaluators.${identifier.evaluator}`)
            parsedIdentifier["translatedCriteria"] = this.translateCriteria(identifier)
            subtitleMix.push(parsedIdentifier)
          }
        }
      }
      else {
        rulesGroupActor = this.t("admission.show_requirements.default_rule_set")
      }
      return { "title": rulesGroupActor, "subtitle": subtitleMix, "creditTypeValue": creditTypeValue }
    },

    rulesGroupHasIdentifiers: function(rulesGroup) {
      if (rulesGroup.identifiers === null) { return false }
      return rulesGroup.identifiers.length > 0
    },
  }
}
