<template>
  <b-sidebar id="sidebar-brands-list" :title="line.name" v-model="viewSidebar" backdrop shadow>
    <loader ref="loader"/>
    <div class="sidebar-body" v-bind:style="{minWidth: '378px'}">
      <b-badge class="text-muted rounded-pill badge-count pt-1" variant="light">
        {{line.items_count}} {{ t(`inventory_consolidate.available`) }} -
        {{line.brands_count}} {{ t(`inventory_consolidate.brands`) }} -
        {{line.models_count}} {{ t(`inventory_consolidate.models`) }}
      </b-badge>
      <div class="horizontal-line mtp-34 mbp-34"></div>
      <collapse-with-header v-for="(brand, indexBrand) in records" :key="brand.id"
        class="collapse-list collapse-variant"
        v-bind:id="`collapse-${brand.id}`"
        :title="`${brand.name} (${brand.models_total} ${t(`views.units_available`)})`"
        :object="{indexBrand: indexBrand, brandId: brand.id}"
        v-on:openFunction="getModelsAvailables">
        <template slot="body">
          <ul>
            <li class="ml-3 text-capitalize" v-for="model in brand.product_models" :key="model.id">
              <a class="link-redirect" @click="sendFiltersToSearch(line.id, brand.id, model.id)">
                {{model.name}} ({{model.items_count}} {{ t(`views.units_available`) }})
              </a>
            </li>
          </ul>
        </template>
      </collapse-with-header>
    </div>
  </b-sidebar>
</template>

<script>
  import i18n from "../mixins/i18n"
  import inventory from '../../api/inventory';
  import Loader from '../template/Loader.vue';
  import CollapseWithHeader from '../template/CollapseWithHeader.vue';

  export default {
    name: 'brands-list',
    components: {Loader, CollapseWithHeader},
    mixins: [i18n],
    props: {
      inventoryType: Object
    },
    data() {
      return {
        records: [],
        line: {
          id: '',
          name: '',
          items_count: '',
          brands_count: '',
          models_count: '',
        },
        viewSidebar: false
      }
    },
    methods: {
      getModelsAvailables: async function(brand) {
        this.$refs.loader.isVisible()
        if(this.records[brand.indexBrand].product_models && this.records[brand.indexBrand].product_models.length > 0)
          return this.$refs.loader?.notVisible()

        const response = await inventory.availableModelsList(this.inventoryType.id, brand.brandId, null, false)
        const models = response.data
        this.records[brand.indexBrand].product_models = models
        this.records = [...this.records]
        this.$refs.loader?.notVisible()
      },
      sendFiltersToSearch(product_type_id, brand_id, product_model_id){
        window.location.href = `/inventory_items?pt_id=${product_type_id}&b_id=${brand_id}&pm_id=${product_model_id}&page=true`
      }
    },
    watch: {
      inventoryType: async function() {
        this.$refs.loader?.isVisible()
        if(this.inventoryType)
          this.line = this.inventoryType
        this.records = await inventory.brandsByInventoryType(this.inventoryType.id)
        this.$refs.loader?.notVisible()
      }
    }
  }
</script>
<style>
.collapse-variant strong,.link-redirect {
  font-size: 14px;
  line-height: 16px;
  color: #737373 !important;
}

.collapse-variant .collapsible-item {
  border: 0px !important;
}
</style>
