// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

import "@fortawesome/fontawesome-free/js/all";
// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.

// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
require("jquery")
require("custom/rut")
require("chartkick")
require("chart.js")
require("custom/phone")
require("custom/jquery_plugins")

import I18n from 'i18n-js/index.js.erb'
import moment from 'moment';
import Vue from 'vue';
import Shipyard from 'shipyard';
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
import TurbolinksAdapter from 'vue-turbolinks';
import { camelizeKeys } from 'humps';
import VueLogger from 'vuejs-logger';
import VueMoment from 'vue-moment';
import vSelect from 'vue-select';
import Vue2Filters from 'vue2-filters'
import Chartkick from 'vue-chartkick'
import Chart from 'chart.js'

import BankList from '../components/bank/bank-list.vue';
import PacList from '../components/pac/pac-list.vue';
import UnrecognizedChargeOrders from '../components/unrecognized_charge_orders/index.vue';
import BankAccountForm from '../components/bank_account/bank-account-form.vue';
import CreditSimulator from '../components/credit/simulator.vue';
import CreditSimulatorV2 from '../components/credit/simulatorV2.vue';
import CreditReprogramForm from '../components/credit/ReprogramForm.vue';
import LeasingForm from '../components/credit/LeasingForm.vue';
import ModalDeleteItem from '../components/credit/ModalDeleteItem.vue';
import SimulatorForm from '../components/simulator/Form.vue';
import SimulatorFormV2 from '../components/simulator/FormV2.vue';
import ProductTypeForm from '../components/simulator/ProductTypeForm.vue';
import VehicleSearchModal from '../components/simulator/VehicleSearchModal.vue';
import SimulationsBoard from '../components/simulations/Board.vue';
import SimulationShow from '../components/simulations/Show.vue';
import InstallmentsForm from '../components/simulator/InstallmentsForm.vue';
import CardDetail from '../components/simulations/CardDetail.vue';
import CardDocument from '../components/simulations/CardDocument.vue';
import GuarantorCard from '../components/simulations/GuarantorCard.vue';
import SimulationCommentsCard from '../components/simulations/CommentsCard.vue';
import CardPaymentTable from '../components/simulations/CardPaymentTable.vue';
import CardPaymentSystem from '../components/simulations/CardPaymentSystem.vue';
import SelectInventoryItemForm from '../components/simulator/SelectInventoryItemForm.vue';
import EvaluationRequisites from '../components/simulations/EvaluationRequisites.vue';
import EvaluationStatusSign from '../components/simulations/EvaluationStatusSign.vue';
import AdmissionRequirementsShow from '../components/admission_requirements/ShowRequirements.vue';
import InventoryTypeForm from '../components/dynamics_form/InventoryTypeForm.vue';
import InventoryItemForm from '../components/dynamics_form/InventoryItemForm.vue';
import CardItem from '../components/template/CardItem.vue';
import Box from '../components/template/Box.vue';
import ItemsMerger from '../components/inventory/ItemsMerger.vue';
import InventoryConsolidated from '../components/inventory/InventoryConsolidated.vue';
import BulkUploadInventory from '../components/inventory/BulkUpload.vue';
import NewRequirement from '../components/admission_requirements/New.vue';
import RutDueDateForm from '../components/admission_requirements/RutDueDateForm.vue';
import AdmissionRequirementsMenu from '../components/admission_requirements/AdmissionRequirementsMenu.vue';
import OptionsForm from '../components/admission_requirements/OptionsForm.vue';
import PreoderDetail from '../components/simulations/PreorderDetail.vue';
import PreoderForm from '../components/simulations/PreorderForm.vue';
import PaymentStatus from '../components/utils/PaymentStatus.vue';
import InventoryDetail from '../components/inventory/InventoryDetail.vue';
import ErrandsIndex from '../components/errands/ErrandsIndex.vue';
import InstantiateErrand from '../components/errands/InstantiateErrand.vue';
import ErrandTypeList from '../components/errands/ErrandTypeList.vue';
import ErrandTypeForm from '../components/errands/Form.vue';
import SelectExceptionTypes from '../components/operations/SelectExceptionTypes.vue';
import ContentCollapse from '../components/dynamics_form/ContentCollapse.vue';
import DownPaymentDetail from '../components/simulations/DownPaymentDetail.vue';
import KayakInformation from '../components/simulations/KayakInformation.vue'
import KayakInformationCmf from '../components/simulations/KayakInformationCmf.vue';
import KayakPersonalInformation from '../components/simulations/KayakPersonalInformation.vue'
import ErrandEdit from '../components/errands/ErrandEdit.vue';
import RateEvolutionChart from '../components/commercial_informations/RateEvolutionChart.vue';
import WarehouseStatusIndex from '../components/inventory/warehouse_status/WarehouseStatusIndex.vue';
import InventoryLocationMerge from '../components/inventory/InventoryLocationMerge.vue';
import CalendarIndex from '../components/calendar/CalendarIndex/CalendarIndex.vue';
import ModalReferralLink from '../components/user_options/ModalReferralLink.vue';
import GenerateInvoice from '../components/errands/GenerateInvoice.vue'
import BankAccountSelectorWrapper from '../components/credit/BankAccountSelectorWrapper.vue';
import NewTotalCheckProcess from '../components/errands/NewTotalCheckProcess.vue';
import AddressForm from '../components/address/Form.vue'
import InvoiceFiles from '../components/errands/InvoiceFiles.vue'
import DigitalSignCard from '../components/digital_sign_flow/DigitalSignCard.vue'
import ExtraordinaryPayments from '../components/credit/ExtraordinaryPayments.vue'

const isProduction = process.env.NODE_ENV == 'production';

const loggerOptions = {
  isEnabled: true,
  logLevel: isProduction ? 'fatal' : 'debug',
  stringifyArguments: false,
  showLogLevel: true,
  showMethodName: true,
  separator: '|',
  showConsoleColors: true,
};

Vue.use(TurbolinksAdapter)
Vue.use(VueLogger, loggerOptions);
Vue.use(VueMoment, { moment });
Vue.use(Vue2Filters)

Vue.component('v-select', vSelect);
Vue.component('pac-list', PacList);
Vue.component('bank-list', BankList);
Vue.component('bank-account-form', BankAccountForm);
Vue.component('credit-simulator', CreditSimulator);
Vue.component('credit-reprogram-form', CreditReprogramForm);
Vue.component('leasing-form', LeasingForm);
Vue.component('modal-delete-item', ModalDeleteItem);
Vue.component('unrecognized-charge-orders', UnrecognizedChargeOrders);
Vue.component('simulator-form', SimulatorForm);
Vue.component('vehicle-search-modal', VehicleSearchModal);
Vue.component('simulations-board', SimulationsBoard);
Vue.component('simulation-show', SimulationShow);
Vue.component('installments-form', InstallmentsForm);
Vue.component('card-detail', CardDetail);
Vue.component('guarantor-card', GuarantorCard);
Vue.component('simulation-comments-card', SimulationCommentsCard);
Vue.component('card-document', CardDocument);
Vue.component('card-payment-table', CardPaymentTable);
Vue.component('card-payment-system', CardPaymentSystem);
Vue.component('credit-simulator-v2', CreditSimulatorV2);
Vue.component('simulator-form-v2', SimulatorFormV2);
Vue.component('product-type-form', ProductTypeForm);
Vue.component('select-inventory-item-form', SelectInventoryItemForm);
Vue.component('evaluation-requisites', EvaluationRequisites);
Vue.component('evaluation-status-sign', EvaluationStatusSign);
Vue.component('show-requirements', AdmissionRequirementsShow);
Vue.component('inventory-type-form', InventoryTypeForm);
Vue.component('inventory-item-form', InventoryItemForm);
Vue.component('card-item', CardItem);
Vue.component('box', Box);
Vue.component('items-merger', ItemsMerger);
Vue.component('inventory-consolidated', InventoryConsolidated);
Vue.component('bulk-upload-inventory', BulkUploadInventory);
Vue.component('new-requirement', NewRequirement);
Vue.component('rut-due-date-component', RutDueDateForm);
Vue.component('options-form-component', OptionsForm);
Vue.component('admission-requirements-component', AdmissionRequirementsMenu);
Vue.component('preorder-detail', PreoderDetail);
Vue.component('preorder-form', PreoderForm);
Vue.component('down-payment-detail', DownPaymentDetail);
Vue.component('kayak-information-cmf', KayakInformationCmf);
Vue.component('kayak-information', KayakInformation);
Vue.component('kayak-personal-information', KayakPersonalInformation);
Vue.component('payment-status', PaymentStatus);
Vue.component('inventory-detail', InventoryDetail);
Vue.component('errands-index', ErrandsIndex);
Vue.component('select-exceptions-types', SelectExceptionTypes);
Vue.component('content-collapse', ContentCollapse);
Vue.component('instantiate-errand', InstantiateErrand);
Vue.component('errand-type-list', ErrandTypeList);
Vue.component('errand-type-form', ErrandTypeForm);
Vue.component('credit-compromise-form', CreditReprogramForm);
Vue.component('errand-edit', ErrandEdit);
Vue.component('rate-evolution-chart', RateEvolutionChart);
Vue.component('warehouse-status-component', WarehouseStatusIndex);
Vue.component('inventory-location-merge', InventoryLocationMerge);
Vue.component('calendar-index', CalendarIndex);
Vue.component('modal-referral-link', ModalReferralLink);
Vue.component('generate-invoie', GenerateInvoice);
Vue.component('bank-account-selector-wrapper', BankAccountSelectorWrapper);
Vue.component('address-form', AddressForm);
Vue.component('invioce-files', InvoiceFiles);
Vue.component('new-total-check-process', NewTotalCheckProcess);
Vue.component('digital-sign-card', DigitalSignCard);
Vue.component('extraordinary-payments', ExtraordinaryPayments)

Vue.filter('camelizeKeys', value => camelizeKeys(value));

import 'bootstrap-vue/dist/bootstrap-vue.css';

document.addEventListener('turbolinks:load', () => {
  const vueApps = [];
  if (document.getElementById('app') !== null) {
    const mainApp = new Vue({
      el: '#app',
      data() {
        return {}
      },
      mounted() {
      },
      methods: {
      }
    })
    vueApps.push(mainApp);
  };

  if (document.getElementById('header') !== null) {
    const headerApp = new Vue({
      el: '#header',
    })
    vueApps.push(headerApp);
  }

  return vueApps;
});

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(Chartkick.use(Chart));
Vue.use(Shipyard);
