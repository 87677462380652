import inventoryApi from '../../api/inventory'
import warehouseStatusApi from '../../api/warehouse_statuses'

export default {
  data: function () {
    return {
      availableModelsList: [],
      availableColorsByModelList: [],
      inventoryItem: null,
      inventoryItemsList: [],
      selectedInventoryItemId: null,
      selectedVehicleModelId: null,
      selectedVehicleModel: null,
      selectedColorId: null,
      listModelsCampaign: [],
      filterWithDiscount: null
    }
  },
  methods: {
    getAvailableModelsList: async function (inventoryTypeId, productModelId) {
      const response = await inventoryApi.availableModelsList(inventoryTypeId, null, productModelId)
      this.availableModelsList = response.data
    },
    getAvailableColorsByModelList: async function (itemCampaign) {
      const response = await inventoryApi.availableColorsByModelList(this.filterWithDiscount, itemCampaign?.id, itemCampaign?.discount_description,  itemCampaign?.warehouse_status_id)

      this.availableColorsByModelList = [{
        id: null,
        name: 'Color',
        disabled: true
      }, ...response.data]
    },
    getAvailableByListPrice: async function (campaign, simulationProductAmount, discountAmount) {
      const response = await inventoryApi.availableByListPrice(this.vehicleModelId, this.selectedInventoryItemId, campaign, simulationProductAmount, discountAmount)
      this.inventoryItemsList = response.data
    },
    getAvailableModelsByCampaign: async function (filterWithDiscount, productModelId, productValue, inventoryItemId, omitDiscount = false) {
      this.filterWithDiscount = omitDiscount ? null : filterWithDiscount
      const response = await inventoryApi.availableModelsByCampaign(this.filterWithDiscount, productModelId.brand_id, productModelId.name, productValue, inventoryItemId, omitDiscount)
      /**Get items details with header */
      const inventoryItemsList = response && response.data.map((r) => {
        return r.items.map((i) => {
          let details = { ...i, ...r }
          delete details.items
          return details
        });
      });
      if (inventoryItemsList.length === 0) return []

      const inventoryItemsMerged = [].concat.apply([], inventoryItemsList)
      /**Warehouse Status grouping */
      const warehouseStatutesList = await warehouseStatusApi.getWarehouseStatuses(true)
      const warehouseStatusesWithSummary = warehouseStatutesList.map((ws) => {
        const inventoryItems = inventoryItemsMerged.filter(iim => iim.warehouse_status_id === ws.id)
        if (inventoryItems.length === 0) return null
        /**Inventory Item grouping by list_price, year and discount_amount */
        const inventoryItemByPriceAndYear = []
        inventoryItems.forEach((item) => {
          const { list_price, discount_amount, year, id, discount_description } = item
          const groupHeader = { id, list_price, discount_amount, discount_description, year, warehouse_status_id: ws['id'] }
          const groupIndex = inventoryItemByPriceAndYear.findIndex(group => {
            return (group.list_price === list_price &&
              group.discount_amount === discount_amount &&
              group.year === year)
          });
          if (groupIndex !== -1)
            inventoryItemByPriceAndYear[groupIndex]['items_count'] = inventoryItemByPriceAndYear[groupIndex]['items_count'] + 1
          else
            inventoryItemByPriceAndYear.push({ ...groupHeader, items_count: 1 })
        })

        return { ...ws, inventoryItemByPriceAndYear }
      });
      const result = warehouseStatusesWithSummary.filter(ws => ws && ws.inventoryItemByPriceAndYear.length > 0)
      result.sort((a, b) => b.position - a.position)

      this.listModelsCampaign = result
    }
  }
}
