<template>
  <div class="row">
    <div class="col-12">
      <b-tabs class="table-responsive">
        <b-tab active>
          <template v-slot:title>
            {{ t(`views.index.to_consolidate`) }}
            <b-spinner variant="primary" :label="`${t(`views.index.labels.calculated`)}`" style="width: 1rem; height: 1rem;" v-if="unrecognizedLoading"></b-spinner>
            <b-badge variant="danger" v-if="!unrecognizedLoading">{{ unrecognizedChargeOrders.length }}</b-badge>
          </template>
          <div class="text-center p-3" v-if="unrecognizedLoading">
            <h2>{{ t(`views.index.loading`) }}</h2>
            <b-spinner variant="primary" :label="`${t(`views.index.labels.calculated`)}`" style="width: 3rem; height: 3rem;"></b-spinner>
          </div>
          <p class="p-3" v-if="!unrecognizedLoading">
            <b-table
              id="payment-table"
              striped
              hover
              :items="unrecognizedChargeOrders"
              :fields="fields"
              :per-page="perPage"
              :current-page="currentPage"
              class="mt-3"
            >
              <template v-slot:cell(actions)="row">
                <b-button size="sm" @click="row.toggleDetails">
                  {{ row.detailsShowing ? t(`views.index.hide`) : t(`views.index.show`) }} {{ t(`views.index.information`) }}
                </b-button>
              </template>

              <template v-slot:row-details="row">
                <b-card class="">
                  <div class="row">
                    <b-form @submit.prevent="receivePayment(installment.id, row.item)" class="col-5">
                      <b-form-group
                        id="input-group-1"
                        :label="`${t(`views.index.labels.credit`)}`"
                        label-for="input-1"
                      >
                        <vue-bootstrap-typeahead
                          v-model="query"
                          :data="credits"
                          :serializer="credit => credit.code"
                          :placeholder="`${t(`views.index.placeholders.credit_code`)}`"
                          @hit="selectCredit = $event"
                        />
                        <b-form-group v-if="selectCredit" id="input-group-1" :label="`${t(`views.index.labels.installment`)}`" label-for="input-1" :description="`${t(`views.index.descriptions.installment`)}`">
                          <b-form-select id="installment-id" v-model="installment.id" :options="installments" class="mb-2"></b-form-select>
                        </b-form-group>
                      </b-form-group>

                        <b-button variant="primary" v-if="submitted" disabled>
                          <b-spinner small></b-spinner>
                          <span class="sr-only">{{ t(`views.index.consolidating`) }}...</span>
                        </b-button>
                        <b-button type="submit" block variant="primary" v-if="!submitted">{{ t(`views.index.consolidate`) }}</b-button>
                        <p class="text-center">o</p>
                        <b-button block variant="link" @click="alreadyRecognized(row.item)">{{ t(`views.index.already_consolidated`) }}</b-button>
                    </b-form>
                    <div class="col-7" v-if="installment.client.name">
                      <b>{{ t(`views.index.client.name`) }}: </b>{{ installment.client.name }}<br />
                      <b>{{ t(`views.index.client.rut`) }}: </b>{{ installment.client.rut }}<br />
                      <b>{{ t(`views.index.client.passport`) }}: </b>{{ installment.client.passport }}<br />
                      <b>{{ t(`views.index.client.phone`) }}: </b>{{ installment.client.phone }}<br />
                    </div>
                  </div>
                </b-card>
              </template>
            </b-table>
            <b-pagination
              v-model="currentPage"
              :total-rows="unrecognized_rows"
              :per-page="perPage"
              aria-controls="payment-table"
            ></b-pagination>
          </p>
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>
<script>
import i18n from "../mixins/i18n"
import _ from "lodash"
import moment from  'moment'
import api from "../../api/unrecognized_charge_orders"
import apiCredit from "../../api/credits"
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'
export default {
  mixins: [i18n],
  name: "unrecognized-charge-orders",
  components: {
    VueBootstrapTypeahead
  },
  props: [ 'items' ],
  data() {
    return {
      query: '',
      perPage: 30,
      currentPage: 1,
      fields: [
        {
          key: "identifier",
          label: this.t("views.index.labels.identifier"),
        },
        {
          key: "amount",
          label: this.t("views.index.labels.amount")
        },
        {
          key: "transaction_date",
          label: this.t("views.index.labels.transaction_date")
        },
        {
          key: "payment_system",
          label: this.t("views.index.labels.payment_system")
        },
        {
          key: "status",
          label: this.t("views.index.labels.status")
        },
        {
          key: 'actions', label: ''
        }
      ],
      unrecognizedChargeOrders: [],
      credits: [],
      unrecognizedLoading: true,
      selectCredit: null,
      installment: {
        client: {
          name: '',
          rut: '',
          passport: '',
          passport: ''
        }
      },
      credit: {},
      submitted: false,
      filteredInstallments: []
    }
  },
  methods: {
    setUnrecognizedChargeOrders: async function(){
      const response = await api.getUnrecognizedChargeOrders()
      this.unrecognizedChargeOrders = response
      this.unrecognizedLoading = false
    },
    getCredits(query) {
      apiCredit.getCredits(query, 10
      ).then(data => {
        this.credits = data
      });
    },
    receivePayment(installment_id, unrecognizedChargeOrder) {
      this.submitted = true;
      api.updateUnrecognizedChargeOrder(installment_id, unrecognizedChargeOrder.id).then(data => {
        location.reload();
      })
    },
    alreadyRecognized(unrecognizedChargeOrder) {
      api.alreadyRecognized(unrecognizedChargeOrder.id).then(data => {
        location.reload();
      })
    }
  },
  filters: {
    date(date) {
      return moment.utc(date).format('DD-MM-YYYY');
    },
  },
  computed: {
    unrecognized_rows() {
      return this.unrecognizedChargeOrders.length
    },
    installments() {
      if (!this.selectCredit) return [];
      this.installment = this.selectCredit
      this.filteredInstallments = this.selectCredit.installments.filter((installment) => (installment.status == 'pending' || installment.status == 'delayed') && installment.period != '0.0' )
      return  this.filteredInstallments.map( ins => ({
        text: `${ins.period} / ${this.t(`activerecord.attributes.installment.status/${ins.status}`)} / ${moment.utc(ins.due_at).format('DD-MM-YYYY')}`,
        value: ins.id,
      }))
    }
  },
  watch: {
    query(q) {
      this.getCredits(q)
    },
  },
  mounted() {
    this.setUnrecognizedChargeOrders();
  }
}
</script>
