<template>
  <div>
    <loader ref="loader"/>
    <div class="container-fluid">
      <div class="row mb-3">
        <div class="col-12 justify-start">
            <b-alert v-if="isSaveSuccess"  variant="success" show dismissible class="mb-2 col-span-8 col-span-2">
              <p>{{ t(`select_inventory_item_form.item_saved.success`) }}</p>
            </b-alert>
            <b-alert v-if="isSaveSuccess === false"  variant="danger" show dismissible class="mb-2 col-span-8 col-span-2">
              <p>{{ t(`select_inventory_item_form.item_saved.fail`) }}</p>
            </b-alert>
            <br>
        </div>
        <div class="col-6 pr-0">
          <div class="card">
            <div class="card-header py-3 d-flex flex-row align-item-center justify-content-between">
              <h6 class="m-0 font-weight-bold text-primary">{{ t(`select_inventory_item_form.client_data`) }}</h6>
            </div>
            <div class="card-body">
              <b-alert variant="danger" show v-if="!isClientPresent">
                <p>{{ t(`select_inventory_item_form.no_client`) }}</p>
              </b-alert>
              <client-information
                :guarantors="guarantors"
                :client-id="client.id "
                :client-name="client.full_name"
                :pipedrive-person-id="pipedrivePersonId"
                :pipedrive-deal-id="pipedriveDealId"
                v-if="pipedrivePersonId > 0 || pipedriveDealId > 0" />

            </div>
          </div>
        </div>

        <div class="col-6">
          <div class="card">
            <div class="card-body">

              <div v-for="invType in inventoryTypes" :key="invType.id">
                <h5 class="font-bold" >{{ t(`activerecord.attributes.inventory_item.inventory_type_name`) }} {{invType.name}}</h5>

                <div v-if="productsSplitted[invType.id].length === 0">
                  <b-alert variant="danger" show dismissible class="mb-2 col-span-8 col-span-2">
                    <p>{{ t(`select_inventory_item_form.no_inventory_type`) }}</p>
                  </b-alert>
                </div>
                <div v-else v-for="(productPref) in productsSplitted[invType.id]" :key="productPref.id">

                  <dl class="row">
                    <dt class="col-md-4 col-xs-12">
                      {{ t(`activerecord.models.brand`) }}:
                    </dt>
                    <dt class="col-md-8 col-xs-12">
                      {{ productPref.product_model_brand_name || t(`select_inventory_item_form.not_specified`)}}
                    </dt>
                  </dl>

                  <dl class="row">
                    <dt class="col-md-4 col-xs-12">
                      {{ t(`activerecord.models.product_model`) }}:
                    </dt>
                    <dt class="col-md-8 col-xs-12">
                      {{ productPref.product_model_name || t(`select_inventory_item_form.not_specified`)}}
                    </dt>
                  </dl>

                  <dl class="row">
                    <dt class="col-md-4 col-xs-12">
                      {{ t(`activerecord.models.color`) }}:
                    </dt>
                    <dt class="col-md-8 col-xs-12">
                      {{ productPref.color_name || t(`select_inventory_item_form.not_specified`)}}
                    </dt>
                  </dl>

                  <vehicle-list
                    :product-pref-id="productPref.id"
                    :inventoryItemIdSelected="productPref.inventory_item_id"
                    :vehicleModelId="productPref.product_model_id"
                    :campaign="productPref.has_discount"
                    :permission-role="permissionRole"
                    :simulation-product-amount="simulationProductAmount"
                    :preferred-product-price="productPref.price"
                    :discount-amount="productPref.discount_amount"
                    @setSelectedInvItem="setSelectedInvItem"
                    @selectedInventory="setInventoryItem"
                  />
                  <hr>
                </div>
              </div>

            </div>

            <div class="card-footer">
              <div class="col-sm-12">
                <b-button
                  block
                  variant="primary"
                  @click="submitInventoryItem"
                  :disabled="!canSave()" size="lg">
                  {{ t(`views.save`) }}
                </b-button>
              </div>

            </div>
          </div>
        </div>

      </div>
    </div>

    <b-modal id="modal_delete_item" size="sm">
      <template #modal-title>
        <span class="title t1 width-max-content">{{ t(`simulations.confirm_unlink_item`) }}</span>
      </template>
        <div class="color-default mtp-20">{{ t(`simulations.text_unlink_item`) }}</div>
      <template #modal-footer>
        <button class="footer-button base-button outline" style="margin-right: 12px !important;" @click="$bvModal.hide('modal_delete_item')">
          {{ t(`simulations.cancel_unlink`) }}
        </button>
        <button class="footer-button base-button" @click="deleteInventoryItem">{{ t(`simulations.unlink`) }}</button>
      </template>
    </b-modal>
  </div>

</template>

<script>
import i18n from "../mixins/i18n"
import VehicleList from '../simulator/VehicleSearchList.vue'
import apiSimulation from '../../api/simulations'
import ClientInformation from '../simulator/ClientInformation.vue'
import clientMixin from '../mixins/client'
import Loader from '../template/Loader.vue'
import _ from 'lodash'

export default {
  name: 'SelectInventoryItemForm',
  components: {
    VehicleList,
    ClientInformation,
    Loader
  },
  mixins: [clientMixin, i18n],
  props: [
    'simulationId',
    'pipedrivePersonId',
    'pipedriveDealId',
    'preferenceModelColor',
    'modelsPreference',
    'inventoryTypes',
    'permissionRole',
    'simulationProductAmount',
  ],
  data: function() {
    return {
      isSuccess: null,
      productsSplitted: {},
      productsPreference: this.modelsPreference || [],
      productId: null,
    }
  },
  mounted: function() {
    this.splitProducts()
  },
  computed: {
    isClientPresent: function() {
      return !_.isNil(this.client.id)
    },
    isSaveSuccess: function() {
      return this.isSuccess
    },
  },
  methods: {
    setInventoryItem: function(value) {
      let modelIndex = this.productsPreference.findIndex((element) => element.id === value.id)
      this.productsPreference[modelIndex] = {
        ...this.productsPreference[modelIndex],
        inventory_item_id: value.itemId
      }
      this.productsPreference = [...this.productsPreference]
      this.splitProducts()
    },
    submitInventoryItem: async function(){
      this.isSuccess = null
      const response = await apiSimulation.update(this.simulationId, {
        sim_model_color_preferences_attributes: this.productsPreference
      })

      if(response.id)
        this.isSuccess = !!response.id
      else
        this.isSuccess = false
    },
    deleteInventoryItem: async function() {
      this.$refs.loader.isVisible()
      const response = await apiSimulation.deleteItem(this.simulationId, this.productId)
      window.location.href = `${window.location.origin}/simulations/${this.simulationId}`
    },
    splitProducts: function() {
      this.productsSplitted = this.inventoryTypes.reduce((acc, curr) => {
        acc[curr.id] = this.productsPreference.filter((pf) => pf.inventory_type_id === curr.id)
        return acc
      }, {})
    },
    canSave: function() {
      return this.productsPreference.reduce((acc, curr) => acc && !_.isNil(curr.inventory_item_id), true)
    },
    setSelectedInvItem(productId) {
      this.productId = productId
    },
  },
}
</script>
