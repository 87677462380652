<template>
  <b-modal id="modal-mass-edit-responsible" size="lg">
    <template #modal-title>
      <span class="title t1">{{t('errand.modal_change_responsible_title')}}</span>
    </template>
    <div class="container-modal">
      <p class="color-default mbp-12">{{t('errand.modal_message', {count: itemsSelected.length})}}:</p>
      <select-input
        :options="responsibleList"
        :placeholder="t('errand.change_responsible')"
        input-height="40px"
        valueField="id"
        textField="name"
        v-on:onChange="onChangeResponsible"/>
    </div>

    <div>
      <h4 class="title t2 mb-4">{{ t(`errand.add_comment`)}}</h4>
      <b-form-textarea
        id="textarea-default"
        :placeholder="`${t('errand.input_comment')}`"
        v-model="currentComment"
      ></b-form-textarea>
    </div>

    <template #modal-footer>
      <button class="footer-button base-button outline mrp-24" @click="$bvModal.hide('modal-mass-edit-responsible')">{{ t(`views.cancel`) }}</button>
      <button class="footer-button base-button" @click="confirmEvent" :disabled="isSelected">{{ t(`views.change`) }}</button>
    </template>
  </b-modal>
</template>
<script>
import i18n from "../mixins/i18n"
import capitalize from "../mixins/capitalize"
import SelectInput from '../dynamics_form/SelectInput.vue'

export default {
  components: { SelectInput },
  name: "modal-mass-edit-responsible",
  mixins: [i18n, capitalize],
  props: {
    itemsSelected: {
      type: Array,
      default: () => []
    },
    responsibleList: Array,
    confirmEventModal: Function,
  },
  data() {
    return {
      selectedResponsible: '',
      styleArrow: {
        color: '#484848',
        fontSize: '16.5px'
      },
      currentComment: null
    }
  },
  methods: {
    onChangeResponsible(value) {
      this.selectedResponsible = value
    },
    confirmEvent() {
      this.$emit('confirmEventModal', {
        items: this.itemsSelected.map(item => item.id),
        responsible: this.selectedResponsible,
        itemsObj: this.itemsSelected,
        comment: this.currentComment
        }
      )
      this.$bvModal.hide('modal-mass-edit-responsible')
    },
    clearSelectedResponsible() {
      this.selectedResponsible = ''
    },
  },
  mounted() {
    this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
      if(modalId === 'modal-mass-edit-responsible'){
        this.clearSelectedResponsible()
      }
    })
  },
  computed: {
    isSelected() {
      return this.selectedResponsible === ''
    }
  }
}
</script>
<style>
  #modal-mass-edit-responsible___BV_modal_content_ {
    width: max-content !important;
  }
</style>
