<template>

  <div>
    <b-form-group
      :label="name"
      label-class="text-capitalize"
      :aria-describedby="`${helpId} ${feedbackId}`"
      :state="state"
    >

      <b-form-checkbox
        :disabled="readonly"
        :id="_uid + name + id"
        :name="`checkbox-${id}`"
        @change="handleChange($event)"
        v-model="show"
        :state="state"
        :unchecked-value="false"
        :aria-describedby="`${helpId} ${feedbackId}`"
        class=""
        size="lg"
        switch
      ></b-form-checkbox>

      <b-form-invalid-feedback :id="feedbackId">
        Es requerido
      </b-form-invalid-feedback>

    <div class="d-block" >
      <span class="text-danger" v-if="error" >{{ error }}</span>
    </div>

    <div class="d-block" >
      <b-form-text :id="helpId">{{ description }}</b-form-text>
    </div>

    </b-form-group>
  </div>
</template>

<script>
  import fieldsMixin from '../../mixins/fields';

  export default {
    name: 'CheckboxField',
    mixins: [fieldsMixin],
    props: {
      show: Boolean
    },
    methods: {
      handleChange: function (value) {
        this.$emit('onChangeValue', value)
      }
    }
  }
</script>
