import axios from 'axios';
import * as humps from 'humps';
import { config } from '../constants/api'

export default {
  getBankAccount(client) {
    const path = `/clients/${client.id}/bank_accounts/edit.json`

    return axios.get(
      path,
      config
    ).then(res => res.data)
  },

  async getFromClient(clientId) {
    const path = `/bank_accounts/from_client/${clientId}.json`

    return axios.get(
      path,
      config
    ).then(res => res.data)
    .catch(error => error.response);
  },
  async getAllFromClient(clientId) {
    const path = `/bank_accounts/all_from_client/${clientId}.json`

    return axios.get(
      path,
      config
    ).then(res => res.data)
    .catch(error => error.response);
  },
  async updateByBankAccountType(objectType, body) {
    const path = `/bank_accounts/update_${objectType}_${body.account_type}.json`

    return axios.put(
      path,
      body,
      config
    ).then(res => res)
    .catch(error => error.response);
  },
  async createOrUpdate(bankAccount, accountType, objectId, objectType) {
    if (bankAccount.bank_account_id) {
      return this.updateBankAccount(bankAccount);
    }

    return this.createBankAccount(accountType, bankAccount, objectId, objectType);
  },
  async createBankAccount(accountType, bankAccount, objectId, objectType) {
    const path = `/bank_accounts/create_${accountType}_${objectType}.json`
    const params = {
      bank_account: bankAccount,
      object_id: objectId,
    }

    return axios.post(
      path,
      params,
      config
    ).then(res => res)
    .catch(error => error.response);
  },
  async updateBankAccount(bank_account) {
    const path = `/bank_accounts/${bank_account.bank_account_id}.json`;
    const body = {
      bank_account: {
        name: bank_account.name,
        rut: bank_account.rut,
        dv: bank_account.dv,
        account_type: bank_account.account_type,
        number: bank_account.number,
        bank_id: bank_account.bank_id
      }
    }

    return axios.patch(
      path,
      humps.decamelizeKeys(body),
      config
    ).then(res => res)
    .catch(error => error.response);
  },
}
