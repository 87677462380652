<template>
  <div class="col-span-8">
    <v-select
      class="mb-1"
      :filterable="false"
      :options="options"
      v-model="selectedGuarantor"
      @search="onSearch"
      @input="emitGuarantor" >
      <template slot="no-options">
        Escribe el nombre de un cliente...
      </template>
      <template slot="option" slot-scope="option">
        <div>
          {{ option.label }}
        </div>
      </template>
      <template slot="selected-option" slot-scope="option">
        <div class="selected">
          {{ option.label }}
        </div>
      </template>
    </v-select>
  </div>
</template>
<script>
  import BoardMixin from '../mixins/board'
  import apiGuarantor from '../../api/guarantors';

  import debounce from 'lodash/debounce';
  import isNil from 'lodash/isNil';

  export default {
    name: 'guarantor-dropdown',
    mixins: [BoardMixin],
    props: ['defaultGuarantor'],
    data: function () {
      return {
        selectedGuarantor: this.defaultGuarantor,
        options: []
      }
    },
    methods: {
      onSearch(search, loading) {
        loading(true);
        this.search(loading, search, this);
      },
      search: debounce(async (loading, search, vm) => {
        const guarantors = await apiGuarantor.get(search)
        vm.options = guarantors.map((guarantor) => ({
          label: `${guarantor.full_name} - ${guarantor.rut || '(sin RUT)'}`,
          value: guarantor,
        }))
        loading(false)
      }, 350),
      emitGuarantor: function (selectedOption) {
        let guarantor = {
          first_name: '',
          last_name: '',
          rut: '',
          dv: '',
          passport: '',
          phone: '',
          email: ''
        }

        if (!isNil(selectedOption)) {
          guarantor = selectedOption.value
        }

        this.$emit('selectedGuarantor', guarantor)
      }
    }
  }
</script>
