<template>
  <div>
    <b-alert
      variant="danger"
      :show="isError"
      dismissible
      @dismissed="isError=false"
    >
      {{ errorMessage }}
    </b-alert>
    <b-alert
      variant="success"
      :show="isSuccess"
      dismissible
      @dismissed="isSuccess=false"
    >
      {{ successMessage }}
    </b-alert>
    <PacSelector
      v-if="accountType === 'pac_subscription'"
      :clientId="client.id"
      :selected="selected.bank_account_id"
      :bankAccounts="pacBankAccounts"
      :tugboatUrl="pacTugboatURL"
      @onSelectedPac="handleSelectedPac"
      @onSaveButton="handleSavePacBankAccount"
      showNewSubscription
    />
    <div v-if="accountType === 'transference'">
      <TransferenceSelector
        :clientId="client.id"
        :selected="selected.bank_account_id"
        :bankAccounts="transferBankAccounts"
        @onSelected="handleSelectedTransference"
        @onSaveButton="handleSaveTransferenceBankAccount"
      ></TransferenceSelector>
      <div class="d-flex align-items-center">
        <CheckboxField :show="showNewBankAccounForm" @onChangeValue="handleShowNewBankAccountForm"/>
        <span class="p-medium mt-2">Nueva cuenta bancaria</span>
      </div>
      <NewBankAccount v-if="showNewBankAccounForm" @onSaveButton="handleSaveNewBankAccount" />
    </div>
  </div>
</template>
<script>
  import PacSelector from '../bank_account/PacSelector.vue';
  import TransferenceSelector from '../bank_account/TransferenceSelector.vue'
  import NewBankAccount from '../bank_account/NewBankAccount.vue';
  import CheckboxField from '../dynamics_form/fields/CheckboxField.vue';
  import bankAccountApi from '../../api/bank_accounts';
  import includes from 'lodash/includes'

  export default {
    name: "bank-account-selector-wrapper",
    components: { PacSelector, TransferenceSelector, NewBankAccount, CheckboxField },
    props: [
      "client",
      "accountType",
      "creditId",
      "simulationId",
      "account"
    ],
    data() {
      return {
        selected: this.account || {},
        pacBankAccounts: [],
        transferBankAccounts: [],
        showNewBankAccounForm: false,
        pacTugboatURL: `https://pagos.migrante.com/pac/operations-to-subscribe?rut=${this.client.rut}${this.client.dv}`,
        isSuccess: false,
        isError: false,
        successMessage: "La información se guardó satisfactoriamente.",
        errorMessage: ""
      }
    },
    async mounted() {
      this.pacBankAccounts = (this.accountType === 'pac_subscription') ? await bankAccountApi.getFromClient(this.client.id) : [];
      this.transferBankAccounts = (this.accountType === 'transference') ? await bankAccountApi.getAllFromClient(this.client.id): []
    },
    methods: {
      handleSelectedPac(pac) {
        this.selected = pac;
      },
      handleSavePacBankAccount: async function() {
        const body = {
          account_type: 'pac',
          object_id: this.creditId,
          client_id: this.client.id,
          bank_account_id: this.selected.bank_account_id
        }
        const response = await bankAccountApi.updateByBankAccountType('credit', body)

        if (response.status !== 200) {
          this.errorMessage = `Error: ${response.data}`;
          this.isError = true;
          return
        }

        this.isSuccess = true;
        setTimeout(() => location.reload(), 3000);
      },
      handleSelectedTransference(transference) {
        this.selected = transference
      },
      async handleSaveTransferenceBankAccount() {

        const body = {
            account_type: 'transference',
            object_id: this.simulationId,
            client_id: this.client.id,
            bank_account_id: this.selected.bank_account_id
        }
        const response = await bankAccountApi.updateByBankAccountType('simulation', body)

        if (response.status !== 200) {
          this.errorMessage = `Error: ${response.data}`;
          this.isError = true;
          return
        }

        this.isSuccess = true;
        setTimeout(() => location.reload(), 3000);
      },
      handleShowNewBankAccountForm(value) {
        this.showNewBankAccounForm = value
      },
      async handleSaveNewBankAccount(bankAccount) {
        const response = await bankAccountApi.createBankAccount(
          'transference',
          {...bankAccount, client_id: this.client.id},
          this.simulationId,
          'simulation'
        );

        if (!includes([200, 201], response.status)) {
          const errors = response.data.map((error, index) => {
            return ((index === response.data.length -1) && response.data.length > 1) ? ` y ${error}` : error
          });
          this.errorMessage = `Error: ${errors}`;
          this.isError = true;
          return
        }

        this.isSuccess = true;
        setTimeout(() => location.reload(), 3000);
      }
    }
  }
</script>

