<template>
  <div class="body-container">
    <loader ref="loader"/>
    <div style="padding-inline: 14px;">
      <FilterScope
        :is-inventory="false"
        @onFilterScope="getSelectedFilterScope"
        ref="childRef"
        :search-instruction="`${t(`activerecord.attributes.errand.search_instruction`)}`"
      />
    </div>
    <div class="flex justify-content-between align-items-center">
      <searcher style="padding-inline: 14px;"
        v-on:onSearch="prepareGetErrandsList"
        v-b-tooltip.bottomright.hover :title="`${t(`activerecord.attributes.errand.tooltip_input_search`)}`"
        input-width="480px"
        :input-placeholder="`${t(`activerecord.attributes.errand.text_placeholder`)}`"/>
      <div class="flex align-items-center">
        <span class="badge badge-light flex flex-center">
          {{ t(`views.find_number`, {'number': rows}) }}
          <div class="ml-2" v-b-tooltip.hover v-b-tooltip.html title="Solo se mostraran hasta <strong>1000</strong> items.<br>Puedes usar el buscador y los filtros para lograr un resultado específico.">
            <i class="fas fa-question-circle cursor-pointer color-default">
            </i>
          </div>
        </span>
      </div>
    </div>
    <div class="flex justify-content-between" style="padding-left: 15px; margin-top: 20px;">
      <div>
        <span class="title t4">{{ t(`filter.filter_by`) }}</span>
        <div class="mtp-14 flex-center">
          <button class="second-button flex" v-b-toggle.sidebar-filter @click="openSidebarFilter" :disabled="errorGetItems">
            {{ t(`filter.all_filters`) }} <span class='selected-number' v-bind:style="{ display: isSelected }">{{ itemsSelected }}</span>
          </button>
          <div class="vertical-line"></div>
          <dropdown-filter
            ref="general_status_filter"
            :items="baseStateListFilter"
            text="Estado base"
            title="Estados base"
            v-on:onFilter="getSelectedBaseState"
            :if-searcher="false"
            :isDisabled="errorGetItems"/>
          <div class="vertical-line"></div>
          <select-options
            ref="options_my_errands"
            :list="['Trámites asignados a mi']"
            :items-selected.sync="selectedMyErrands"
            v-on:onSelect="selectMyErrands"
            :isDisabled="errorGetItems"/>
        </div>
      </div>

      <div>
        <span class="title t4">{{ t(`extra_options.massive_actions`) }}</span>
        <div class="mtp-14 flex">
          <button class="button-outback" v-bind:class="{ select: fields[0] === 'selected' }" v-b-toggle.collapse-mass>{{ t(`extra_options.mass_editing_states`) }} <i class="fas fa-edit" style="margin-left: 9px"></i></button>
          <!-- <button class="button-outback" style="margin-left: 12px;">{{ t(`extra_options.export_excel`) }} <i class="fas fa-download" style="margin-left: 9px"></i></button> -->
        </div>
      </div>
    </div>
    <div class="horizontal-line mtp-20"></div>
    <b-collapse id="collapse-mass">
      <div class="container-massive-edit relative mtp-20">
        <span class="close-button" v-on:click="closeCollapseMassEdition"><i class="fas fa-times"></i></span>
        <span class="title t4 text-white">{{ t(`extra_options.mass_editing_states`) }}</span>
        <span class="normal-text text-white block">Selecciona el mismo nº de op para realizar cambios masivos o selecciona el mismo tipo de trámite para hacer cambio de sub estados</span>
        <div class="flex mtp-12">
          <button class="base-button" @click="openEditBaseStatus" :disabled="!isEditableBaseStatus">{{ t(`edit_states.change_base_state`) }}</button>
          <button class="base-button" style="margin-left: 12px;" @click="openEditSubStatus" :disabled="!isEditableSubStatus">{{ t(`edit_states.change_sub_state`) }}</button>
          <button class="base-button" style="margin-left: 12px;" @click="openEditResponsible" :disabled="!isEditableResponsible">{{ t(`errand.change_responsible`) }}</button>
        </div>
      </div>
    </b-collapse>
    <div class="mt-body" style="margin-top: 25px;">
      <div v-bind:style="{ display: searchNotFound ? 'none' : 'block' }">
        <table-errands
          ref="table_errands"
          :items="items"
          :item-selected.sync="itemErrandTypeTable"
          :fields="fields"
          :per-page="perPage"
          :current-page="currentPage"
          @deleteErrand="deleteErrand"
          v-on:onSelectCheck="actionsCollapseMassEdition"
          v-on:onClickErrandType="getSubStatuses"/>
        <div class="flex-vertical-center justify-content-end row-sm-block mtp-20">
          <per-page :per-page.sync="perPage" />
          <b-pagination
            class="sm-center"
            style="margin-bottom: unset"
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            aria-controls="errands_table"
            prev-text="‹ Anterior"
            next-text="Siguiente ›"
            hide-goto-end-buttons
            @change="onCurrentPageChange"
            hide-ellipsis>
          </b-pagination>
        </div>
      </div>
      <not-found :is-visible="searchNotFound" :text-not-found="searchedText"/>
    </div>

    <alert
      ref="notification"
      :text="textNotification"
      v-on:onUndoEvent="onUndoEventAlert"
      v-on:onEvent="onEventAlert"
      :progress-bar="alertProgressBar"
      :is-undo-button="alertUndoButton"/>

    <sidebar-filter-errands
      ref="sidebar"
      v-on:onApplyFilters="onApplyFilters"/>

    <modal-mass-edit-base-state
      :items-selected.sync="errandsCheckList"
      :base-statuses-list="baseStateList"
      v-on:confirmEventModal="confirmEditBaseState"
      v-on:removeItemModal="removeItemSelectedTable"/>
    <modal-mass-edit-sub-state
      :items-selected.sync="errandsCheckList"
      :sub-statuses-list="subStateList"
      v-on:confirmEventModal="confirmEditSubState"
      v-on:removeItemModal="removeItemSelectedTable"/>
    <modal-mass-edit-responsible
      :items-selected.sync="errandsCheckList"
      :responsible-list="responsibleList"
      v-on:confirmEventModal="confirmEditResponsible"
      />

    <b-modal id="modal-delete-errand" :title="t('views.delete', { model_name: t('activerecord.models.errand') })">
      <div v-if="errandToDelete">
        <p>{{t('activerecord.models.errand')}}: {{ errandToDelete.errand_type.name }}</p>
        <p>{{t('activerecord.models.general_status')}}: {{ errandToDelete.base_status }} - {{ errandToDelete.sub_status.name }}</p>
      </div>

      <template #modal-footer="{ cancel }">
        <button class="footer-button base-button outline mrp-24" @click="cancel">{{ t(`views.cancel`) }}</button>
        <button class="footer-button base-button btn-danger" @click="destroyErrand" >{{ t(`views.destroy`) }}</button>
      </template>
    </b-modal>

    <m-modal ref="m-modal" :title="titleModal" status="warning" :ok-only="true">
      <template slot="body">
        <p>
          {{t(`activerecord.attributes.inventory_item.informational_modal_message_part_one`)}}
          <strong>Ej: {{t(`activerecord.attributes.inventory_item.text_placeholder`)}}</strong>
          {{t(`activerecord.attributes.inventory_item.informational_modal_message_part_two`)}}
          <strong>{{t(`activerecord.attributes.inventory_item.by_operation`)}},
            {{t(`activerecord.attributes.inventory_item.by_simulation`)}}.
          </strong>
        </p>
      </template>
    </m-modal>
  </div>
</template>
<script>
import i18n from "../mixins/i18n"
import capitalize from "../mixins/capitalize"
import errands from "../../api/errands";
import generalStatuses from "../../api/general_statuses";
import Searcher from '../dynamics_form/Searcher.vue';
import Loader from '../template/Loader.vue';
import DropdownFilter from '../dynamics_form/DropdownFilter.vue';
import PerPage from '../dynamics_form/PerPage.vue';
import SidebarFilterErrands from './SidebarFilterErrands.vue';
import NotFound from '../template/NotFound.vue';
import SelectOptions from '../dynamics_form/SelectOptions.vue';
import ModalEditState from './ModalEditState.vue';
import TableErrands from './TableErrands.vue';
import ModalMassEditSubState from './ModalMassEditSubState.vue';
import ModalMassEditBaseState from './ModalMassEditBaseState.vue';
import ModalMassEditResponsible from './ModalMassEditResponsible.vue';
import Alert from '../template/Alert.vue';
import FilterScope from '../dynamics_form/FilterScope.vue';
import MModal from '../dynamics_form/MModal.vue';
import moment from 'moment';

export default {
  name: "errands-index",
  mixins: [i18n, capitalize],
  components: {
    Searcher, Loader, DropdownFilter, PerPage, SidebarFilterErrands, NotFound, SelectOptions, ModalEditState,
    TableErrands, ModalMassEditSubState, ModalMassEditBaseState, Alert, ModalMassEditResponsible, FilterScope,
    MModal,
  },
  props: {
    account: String
  },
  data: function() {
    return {
      currentPage: 1,
      perPage: 10,
      searchNotFound: false,
      isSelected: 'none',
      itemsSelected: 0,
      searchedText: '',
      isEditableBaseStatus: false,
      isEditableSubStatus: false,
      isEditableResponsible: false,
      errandsCheckList: [],
      undoEventAlert: () => {},
      eventAlert: () => {},
      itemErrandTypeTable: {
        signature_and_operation_number: {operation_number: ''},
        errand_type: {name: '', id: ''},
        rut_and_name: {name: '', rut: ''},
        history: [],
        financing_type: '',
        credit_type: '',
        sub_status: ''
      },
      textNotification: '',
      alertProgressBar: true,
      alertUndoButton: true,
      baseStateListFilter: [],
      baseStateList: [],
      subStateList: [],
      responsibleList: [],
      selectedMyErrands: [],
      fields: [
        'selected', 'signature_and_operation_number', 'rut_and_name', 'credit_type', 'base_status',
        { key: 'errand_type', tdClass: 'width-150', thClass: 'width-150' },
        'sub_status', 'operational_check', 'date', 'responsible', 'actions'
      ],
      items: [],
      waitingItems: [],
      waitingItemEdit: {},
      filterBase: {},
      filters: {},
      errorGetItems: false,
      errandToDelete: null,
      rows: 0,
      filterScope: null,
      queryScope: null,
      titleModal: null
    }
  },
  watch: {
    perPage: async function(newValue, oldValue){
      await this.getErrandsList()
    },
    filterScope(_newValue, _oldValue){
      if (_oldValue) { this.clearPropertiesFiltersObject(_oldValue) }
    }
  },
  methods: {
    onCurrentPageChange: async function(page) {
      await this.getErrandsList(this.searchedText, page)
    },
    getErrandsList: async function(query = '', page = 1) {
      this.$refs.loader.isVisible()
      try {
        let response = await errands.getList(query, this.filters, page, this.perPage);
        this.items = response.items
        this.rows = response.total
        if(this.items.length > 0) {
          this.items.map((item, i) => {
            item.id_prop = i
            item.errand_type.id_prop = item.id_prop
            item.actions = {}
            return item
          })
          this.searchNotFound = false
        } else {
          this.searchedText = query
          this.searchNotFound = true
        }
      } catch (error) {
        this.items = []
        this.errorGetItems = true
        this.searchNotFound = true
      }
      this.$refs.loader.notVisible()
    },
    getBaseStatuses: async function() {
      this.$refs.loader.isVisible()
      this.baseStateList = await generalStatuses.getList();
      this.baseStateListFilter = [... this.baseStateList]
      this.baseStateList = this.formatOptions(this.baseStateList)
      this.$refs.loader.notVisible()
    },
    getSubStatuses: async function(errandTypeId) {
      this.$refs.loader.isVisible()
      this.subStateList = await errands.getSubStatusList(errandTypeId);
      this.subStateList = this.formatOptionsLabel(this.subStateList)
      this.$refs.loader.notVisible()
    },
    openEditSubStatus: async function() {
      this.$refs.loader.isVisible()
      this.errandsCheckList = this.$refs.table_errands.getItemsSelected()
      this.getSubStatuses(this.errandsCheckList[0].errand_type.id)
      this.$bvModal.show('modal-mass-edit-sub-state')
      this.$refs.loader.notVisible()
    },
    openEditResponsible: async function() {
      this.$refs.loader.isVisible()
      this.errandsCheckList = this.$refs.table_errands.getItemsSelected()
      this.responsibleList = await errands.responsibleAvailable()
      this.$bvModal.show('modal-mass-edit-responsible')
      this.$refs.loader.notVisible()
    },
    confirmEditBaseState: async function(modalObject) {
      this.$refs.loader.isVisible()

      this.alertProgressBar = true
      this.alertUndoButton = true
      this.textNotification = `Has cambiado el estado base de ${modalObject.items.length} trámites`
      this.$refs.notification.show()

      this.waitingItemEdit = modalObject
      this.eventAlert = async () => {
        await errands.updateBaseStatus(this.waitingItemEdit.items, this.waitingItemEdit.status.id, this.waitingItemEdit.comment)
      }
      this.waitingItems = []
      modalObject.itemsObj.map(item => {
        this.waitingItems.push({... this.items[item.id_prop]})
        this.items[item.id_prop].base_status = modalObject.status.name
        this.manageEndDate(modalObject.status.name, item)
      })
      this.undoEventAlert = () => {
        this.waitingItems.map(item => {
          this.items[item.id_prop].base_status = item.base_status
          if (item.base_status == 'Finalizado') {
            this.items[item.id_prop].date.end_date = item.date.end_date
          } else {
            this.items[item.id_prop].date.end_date = 'Sin finalizar'
          }
        })
        this.$refs.notification.isUndo()
      }
      this.$refs.loader.notVisible()
    },
    confirmEditSubState: async function(modalObject) {
      this.$refs.loader.isVisible()

      this.textNotification = `Has cambiado el sub estado de ${modalObject.items.length} trámites.`
      this.$refs.notification.show()

      this.waitingItemEdit = modalObject
      this.eventAlert = async () => {
        await errands.updateSubStatus(this.waitingItemEdit.items, this.waitingItemEdit.status.id, this.waitingItemEdit.comment)
        if(this.waitingItemEdit?.hasCostsData)
          await errands.updateErrand(this.waitingItemEdit.itemsObj[0].id, this.waitingItemEdit.costsData)
      }

      this.waitingItems = []
      if(this.waitingItemEdit.status || this.waitingItemEdit?.hasCostsData){
        modalObject.itemsObj.map(item => {
          this.waitingItems.push({... this.items[item.id_prop]})

          if(this.waitingItemEdit.status){
            this.items[item.id_prop].sub_status = modalObject.status
            this.items[item.id_prop].base_status = this.subStateList.find(item => {
              if(item?.options){
                const s = item.options?.find(option => option.value.id === modalObject.status.id)
                if(s !== undefined)
                  return item
              }
            })?.label
            this.manageEndDate(this.items[item.id_prop].base_status, item)
          }

          if(this.waitingItemEdit?.hasCostsData){
            this.items[item.id_prop].errand_type_document_id = modalObject.costsData?.errand_type_document_id
            this.items[item.id_prop].document_number = modalObject.costsData?.document_number
            this.items[item.id_prop].amount = modalObject.costsData?.amount
            this.items[item.id_prop].errand_provider_id = modalObject.costsData?.errand_provider_id
          }
        })
      }

      this.undoEventAlert = () => {
        this.waitingItems.map(item => {
          if(this.waitingItemEdit.status) this.items[item.id_prop].sub_status = item.sub_status

          if(this.waitingItemEdit?.hasCostsData){
            this.items[item.id_prop].errand_type_document_id = item.errand_type_document_id
            this.items[item.id_prop].document_number = item.document_number
            this.items[item.id_prop].amount = item.amount
            this.items[item.id_prop].errand_provider_id = item.errand_provider_id
          }
        })

        this.$refs.notification.isUndo()
      }

      this.$refs.loader.notVisible()
    },
    confirmEditResponsible: async function(modalObject) {
      this.$refs.loader.isVisible()

      this.textNotification = this.t('errand.text_message_success', { count: modalObject.items.length })
      this.$refs.notification.show()

      this.waitingItemEdit = modalObject

      this.eventAlert = async () => {
        await errands.updateResponsible(this.waitingItemEdit.items, this.waitingItemEdit.responsible, this.waitingItemEdit.comment)
      }

      this.waitingItems = []
      if(this.waitingItemEdit.responsible){
        modalObject.itemsObj.map(item => {
          this.waitingItems.push({... this.items[item.id_prop]})

          this.items[item.id_prop].responsible = this.responsibleList.find(responsible => responsible.id === modalObject.responsible)
        })
      }

      this.undoEventAlert = () => {
        this.waitingItems.map(item => {
          this.items[item.id_prop].responsible = this.responsibleList.find(responsible => responsible.id === item.responsible.id)
        })

        this.$refs.notification.isUndo()
      }

      this.$refs.loader.notVisible()
    },
    onApplyFilters(sidebarObj) {
      if(sidebarObj.clean){
        this.itemsSelected = 0
        this.isSelected = 'none'
      } else {
        this.itemsSelected = 0
        Object.values(sidebarObj.filterSidebar).forEach(e => {
          if(e !== undefined)
            this.itemsSelected += e.length
        })
        this.isSelected = 'block'
      }
      this.filters = Object.assign(
        {},
        this.filterBase,
        sidebarObj.filterSidebar
      )
      this.getErrandsList()
    },
    openSidebarFilter() {
      this.$refs.sidebar.sideBarVisible()
    },
    getSelectedBaseState() {
      this.filterBase.general_status = this.$refs['general_status_filter'].getItemsSelected()
      this.filters = Object.assign(
        {},
        this.filters,
        this.filterBase,
      )
      this.getErrandsList()
    },
    selectMyErrands(items) {
      this.filterBase.responsible = items.length > 0 ? [JSON.parse(this.account).id] : []
      this.filters = Object.assign(
        {},
        this.filters,
        this.filterBase,
      )
      this.getErrandsList()
    },
    closeCollapseMassEdition() {
      this.$root.$emit('bv::toggle::collapse', 'collapse-mass')
    },
    actionsCollapseMassEdition(status) {
      this.isEditableBaseStatus = status.baseStatus
      this.isEditableSubStatus = status.subStatus
      this.isEditableResponsible = status.subStatus
    },
    openEditBaseStatus() {
      this.errandsCheckList = this.$refs.table_errands.getItemsSelected()
      this.$bvModal.show('modal-mass-edit-base-state')
    },
    removeItemSelectedTable(idItem) {
      this.$refs.table_errands.unselectItem(idItem)
    },
    onEventAlert() {
      this.eventAlert()
    },
    onUndoEventAlert() {
      this.undoEventAlert()
    },
    formatOptionsLabel(options) {
      return options.map(option => {
        return {
          label: option.name_base_status,
          options: this.formatOptions(option.sub_status_list)
        }
      })
    },
    formatOptions(options) {
      return options.map(option => {
        return {value: option, text: option.name}
      })
    },
    deleteErrand(errand) {
      this.errandToDelete = errand
      this.$bvModal.show('modal-delete-errand')
    },
    destroyErrand: async function() {
      await errands.destroy(this.errandToDelete.id)
      this.$bvModal.hide('modal-delete-errand')
      this.getErrandsList()
    },
    getSelectedFilterScope(scope){
      this.filterScope = scope
    },
    prepareGetErrandsList(query){
      this.queryScope = query
      if (!this.filterScope && this.formatQueryScope(query).length > 1) {
        this.titleModal = this.t(`activerecord.attributes.inventory_item.informational_modal_title`)
        this.$refs['m-modal'].toggleModal(true)
        return;
      }
      if (this.filterScope && this.queryScope) {
        this.queryScope = this.formatQueryScope(this.queryScope)
        this.setFilterScope(this.filterScope)
      }
      if(!query) {
        this.$refs.childRef.defaultSelect();
        if (this.filterScope) { this.clearPropertiesFiltersObject(this.filterScope) }
        this.filterScope = null;
        this.queryScope = null;
      }
      let query_needed= !this.filterScope ? query : ''
      this.getErrandsList(query_needed)
    },
    clearPropertiesFiltersObject(key){
      delete this.filters[`${key}`]
    },
    setFilterScope(key){
      this.filters[`${key}`] = this.queryScope
    },
    formatQueryScope(query){
      if (Array.isArray(query)) { return query }
      return query.replace(/[^a-zA-Z 0-9.]+/g,',').replace(/\./g, '').replace(/ /g, ',').toLowerCase().split(',').filter(Boolean)
    },
    manageEndDate(base_status, item) {
      if (base_status == 'Finalizado') {
        this.items[item.id_prop].date.end_date = moment(new Date()).format('DD-MM-YYYY')
      } else if (this.items[item.id_prop].date.end_date != 'Sin finalizar') {
        this.items[item.id_prop].date.end_date = 'Sin finalizar'
      }
    }
  },
  mounted: async function() {
    this.filterBase.responsible = [JSON.parse(this.account).id]
    this.filters = Object.assign(
      {},
      this.filters,
      this.filterBase,
    )
    await this.getErrandsList();
    this.getBaseStatuses();
    this.$root.$on('bv::collapse::state', (collapseId, isJustShown) => {
      if(collapseId === 'collapse-mass'){
        if(isJustShown) {
          this.fields.unshift('selected')
          this.$refs.table_errands.viewCheckInput()
          $('td').children('.select_column').children('.custom-control-label').attr('for', '')
        } else {
          this.$refs.table_errands.resetCheck()
          this.fields.shift()
        }
      }
    })
  },
}
</script>
<style>

</style>
