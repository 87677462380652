<template>
  <div class="container-retool">
    <iframe :src="iframeUrl"  title="Retool-View" frameborder="0" width="100%" height="1200px" style="position: relative;"></iframe>
    <div class="cement"/>
  </div>
</template>
<script>
  import EvaluationStatusSign from './EvaluationStatusSign.vue';
  export default {
    name: 'automatic-evaluation',
    component: {EvaluationStatusSign},
    props: {
      simulationId: {
        type: String,
        required: true
      },
      productAmount: {
        type: Number,
        required: true
      },
    },
    computed: {
      iframeUrl() {
        const riskRetoolUrl = document.querySelector('meta[name="risk-retool-url"]').getAttribute('content')
        return `${riskRetoolUrl}externalId=${this.simulationId}&productAmount=${this.productAmount}&_releaseVersion=latest`
      }
    }
  }

</script>

<style>
.cement {
  width: 100%;
  height: 100px;
  top: 1100px;
  left: 0px;
  background-color: #ffffff;
  position: absolute;
}
.container-retool{
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
</style>
