import axios from 'axios'
import * as humps from "humps"

export default {
  async get(id) {
    const path = `/clients/${id}.json`
    const headers = {
      'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
    }

    return await axios.get(path, { headers }).then(res => res.data)
  },
  async getByPipedrive(pipedrivePersonId, pipedriveDealId) {
    const path = `/clients/by_pipedrive.json?pipedrive_person_id=${pipedrivePersonId}&pipedrive_deal_id=${pipedriveDealId}`
    const headers = {
      'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
    }

    return await axios.get(path, { headers }).then(res => res.data).catch((error) => (error.response))
  },
  async getIncomesAverage(id) {
    const path = `/clients/${id}/client_incomes_average.json`
    const headers = {
      'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
    }

    return await axios.get(path, { headers }).then(res => res.data).catch((error) => (error.response))
  },

  async updateAddressClient(address, slug) {
    const path = `/clients/${slug}`

    let data = new FormData()

    data.append('client[addresses_attributes][0][id]', address.id)
    data.append('client[addresses_attributes][0][region_name]', address.region_name)
    data.append('client[addresses_attributes][0][country_name]', address.country_name)
    data.append('client[addresses_attributes][0][city_name]', address.city_name)
    data.append('client[addresses_attributes][0][commune_name]', address.commune_name)
    data.append('client[addresses_attributes][0][street_name]', address.street_name)
    data.append('client[addresses_attributes][0][street_number]', address.street_number)
    data.append('client[addresses_attributes][0][door_number]', address.door_number)
    data.append('client[addresses_attributes][0][other]', address.other)

    if(address.verification_file)
      data.append('client[addresses_attributes][0][verification_file]', address.verification_file)

    const headers = {
      'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
    }

    return axios.patch(path, data, { headers }).then(res => res.data).catch((error) => (error.response))
  }
}
