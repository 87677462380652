<template>
  <div>
    <div class="container-fluid">
      <div class="row mb-3">
        <div class="col-5 pr-0">
          <div class="card">
            <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
              <h6 class="m-0 font-weight-bold text-primary">Datos para la reprogramación</h6>
            </div>
            <div class="card-body">
              <dl class="row">
                <dt class="col-5">
                  Saldo deuda original
                </dt>
                <dd class="col-7">
                  <!-- {{ this.outstandingBalance | currency('$', 0, { thousandsSeparator: '.', decimalSeparator: ',' }) }} -->
                  <!-- ({{ this.outstandingBalanceInUf | number('0.00', { thousandsSeparator: '.', decimalSeparator: ',' } ) }} UF) -->
                  {{ this.portfolioValue | currency('$', 0, { thousandsSeparator: '.', decimalSeparator: ',' }) }}
                  ({{ this.portfolioValueInUf | number('0.00', { thousandsSeparator: '.', decimalSeparator: ',' } ) }} UF)
                </dd>
                <dt class="col-5">
                  Fecha de curse
                </dt>
                <dd class="col-7">
                  {{ this.$moment(this.signDate).format('DD/MM/YYYY') }}
                </dd>
                <dt class="col-5">
                  Valor UF al día de curse
                </dt>
                <dd class="col-7">
                  {{ this.ufAtSignDate | currency('$', 2, { thousandsSeparator: '.', decimalSeparator: ',' }) }}
                </dd>
                <dt class="col-5">
                  Valor de cuota actual
                </dt>
                <dd class="col-7">
                  {{ this.currentInstallmentAmount | currency('$', 0, { thousandsSeparator: '.', decimalSeparator: ',' }) }}
                </dd><dt class="col-5">
                  Cuotas restantes
                </dt>
                <dd class="col-7">
                  {{ this.leftInstallmentsCount }}
                </dd>
                <dt class="col-5">
                  Total de cuotas
                </dt>
                <dd class="col-7">
                  {{ this.totalInstallmentsCount }}
                </dd>
                <dt class="col-5">
                  Tasa original
                </dt>
                <dd class="col-7">
                  {{ this.originalCreditRate | percent(3) }}
                </dd>
                <dt class="col-5">
                  Tasa máxima convencional mensual
                </dt>
                <dd class="col-7">
                  {{ this.simpleTmc | percent(3) }}
                </dd>
                <dt class="col-5">
                  Tasa máxima convencional anual
                </dt>
                <dd class="col-7">
                  {{ this.annualTmc | percent(3) }}
                </dd>
              </dl>
            </div>
          </div>
        </div>
        <div class="col-7">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <dt class="col-4">
                  Razón de cierre
                </dt>
                <dd class="col-8" >
                  <b-form-select v-model="selectedCloseReason"
                    :options="this.closeReasonOptions"
                    :disabled="isDisabledcloseReson">
                  </b-form-select>
                </dd>
                <dt class="col-4">Tipo de Producto</dt>
                <dd class="col-8">
                  <div class="row">
                    <div class="col-8 pr-0">
                      <b-form-select
                      v-model="selectedProductTypeId"
                      :options="this.productTypesList"
                      value-field="id"
                      text-field="name"
                      ></b-form-select>
                    </div>
                    <div class="col-4 pl-1">
                      <b-button block @click="addProductTypeExtraCosts" variant="primary">Agregar costos predefinidos</b-button>
                    </div>
                  </div>
                </dd>
                <dt class="col-4">
                  Costos extra
                </dt>
                <dd class="col-8">
                  <div class="row">
                    <div class="col-5 pr-0">
                      <b-form-select
                      v-model="selectedExtraCostName"
                      :options="this.extraCostsList"
                      value-field="name"
                      text-field="human_name"
                      ></b-form-select>
                    </div>
                    <div class="col-3 pr-0 pl-1">
                      <b-input v-model="selectedExtraCostAmount"></b-input>
                    </div>
                    <div class="col-4 pl-1">
                      <b-button block @click="addExtraCost" variant="primary">Agregar</b-button>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 mt-3">
                      <ul class="extra-costs-list">
                        <li v-for="item in selectedExtraCosts" :key="item.name">
                          {{ item.human_name }}: {{ item.amount | currency('$', 0, { thousandsSeparator: '.', decimalSeparator: ',' }) }}
                          <b-button @click="removeExtraCost(item.name)" variant="link" size="sm" title="Eliminar">
                            <i class="delete fas fa-times"></i>
                          </b-button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </dd>
                <dt class="col-5">
                  Total costos extra
                </dt>
                <dd class="col-7">
                  {{ this.reprogramExtraCostsTotal() | currency('$', 0, { thousandsSeparator: '.', decimalSeparator: ',' }) }}
                </dd>
                <dt class="col-5">
                  Monto préstamo (con costos extras)
                </dt>
                <dd class="col-7">
                  {{ this.portfolioValuePlusExtraCosts | currency('$', 0, { thousandsSeparator: '.', decimalSeparator: ',' }) }}
                  ({{ this.portfolioValuePlusExtraCostsInUf | number('0.00', { thousandsSeparator: '.', decimalSeparator: ',' } ) }} UF)
                </dd>
              </div>
            </div>
          </div>
          <div>
            <br/>
            <installments-form @installmentsUpdated="installmentsUpdated" />
          </div>
        </div>
      </div>
      <div class="row mb-3 flex align-items-center justify-content-center">
        <credit-simulator
          v-if="isShowingCalculator"
          simulation-type='reprogram'
          :client-id="this.clientId"
          :initial-net-amount="this.portfolioValue"
          :initial-gross-amount="this.portfolioValuePlusExtraCosts"
          :origin-credit-id="this.creditId"
          :sign-date="this.signDate"
          :tmc="this.simpleTmc"
          :installments-count="this.leftInstallmentsCount"
          :origin-credit-installment-amount="this.currentInstallmentAmount"
          :extra-costs-gross-amount="this.reprogramExtraCostsTotal()"
          :extra-costs-net-amount="this.extraCostsForNetAmount"
          :selected-extra-costs="this.selectedExtraCosts"
          :product-type="selectedProductType"
          :user-role="userRole"
          @simulationCreated="updateSimulationId"
          @signDateUpdated="updateSignDate"
          @grossAmountUpdated="updateGrossAmount"
          @rateUpdated="updateRate"
          :initial-installments="this.installments"
          :reprogramType="this.reprogramType"
        ></credit-simulator>
        <b-spinner variant="primary" style="width: 5rem; height: 5rem;" v-else />
      </div>
      <div class="row">
        <div class="col-12">
          <form :action="this.reprogramAction" method="post" accept-charset="UTF-8" ref="reprogramForm">
            <input type="hidden" name="authenticity_token" :value="this.csrfToken" />
            <input type="hidden" name="credit[close_reason]" :value="this.selectedCloseReason" />
            <input type="hidden" name="credit[simulation_id]" :value="this.simulationId" />
            <div v-for="(obj, index) in this.selectedExtraCosts" :key="`extra-costs-${index}`">
              <input type="hidden" :name="`credit[extra_costs_attributes][${index}][name]`" :value="obj.name" />
              <input type="hidden" :name="`credit[extra_costs_attributes][${index}][amount]`" :value="obj.amount" />
            </div>
            <div v-show="this.reprogramType != 'leasing'">
              <div class="form-check mb-3">
                <input class="form-check-input" type="checkbox" value="1" id="sendEmailCheckbox" name="send_email" checked>
                <label class="form-check-label" for="sendEmailCheckbox">
                  Enviar correo con anexo de pagaré al cliente y notificaciones a Operaciones
                </label>
              </div>
            </div>
            <div v-show="this.reprogramType == 'leasing'">
              <div class="form-check mb-3">
                <label class="form-check-label">
                  Recuerda que debes generar el anexo de contrato correspondiente a esta reprogramación
                </label>
              </div>
            </div>
            <input v-show="this.reprogramType != 'compromise'"
              :disabled="this.disableReprogramSubmit"
              type="submit"
              class="btn btn-primary btn-block"
              value="Reprogramar"
              @click="submitReprogramForm"
            />
            <input v-show="this.reprogramType == 'compromise'"
              :disabled="this.disableReprogramSubmit"
              type="submit"
              class="btn btn-primary btn-block"
              value="Avenimiento"
              @click="submitReprogramForm"
            />
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
import InstallmentForm from '../simulator/InstallmentsForm.vue'
import CreditSimulator from './simulator'
import ufApi from '../../api/uf'
import tmcApi from '../../api/tmc'
import creditApi from '../../api/credits';
import calculatorMixin from '../mixins/calculator'
import i18n from "../mixins/i18n"

export default {
  mixins: [calculatorMixin, i18n],
  components: {
    CreditSimulator,
    InstallmentForm
  },
  props: [
    'creditId',
    'clientId',
    'creditCode',
    'originalCreditRate',
    'currentInstallmentAmount',
    'leftInstallmentsCount',
    'totalInstallmentsCount',
    'reprogramType',
    'userRole',
    'originalProductTypeId',
    'reprogramRequestType'
  ],
  data: function() {
    return {
      creditSlug: null,
      signDate: moment().format('YYYY-MM-DD'),
      ufAtSignDate: null,
      outstandingBalance: null,
      outstandingBalanceInUf: null,
      outstandingBalancePlusExtraCosts: null,
      outstandingBalancePlusExtraCostsInUf: null,
      portfolioValue: null,
      portfolioValueInUf: null,
      portfolioValuePlusExtraCosts: null,
      portfolioValuePlusExtraCostsInUf: null,
      rate: null,
      annualTmc: null,
      simpleTmc: null,
      simulationId: null,
      closeReasonOptions: [],
      selectedCloseReason: null,
      extraCostsList: [],
      selectedExtraCosts: [],
      selectedExtraCostName: null,
      selectedExtraCostHumanName: null,
      selectedExtraCostAmount: null,
      reprogramFormSubmitButtonDisabled: false,
      installments: [],
      selectedProductTypes: [],
      selectedProductType: {},
      isShowingCalculator: false,
      credit: {},
    }
  },
  methods: {
    setCreditSlug: function() {
      this.creditSlug = this.creditCode.replace(".", "-")
    },
    updateSimulationId: function(value) {
      this.simulationId = value
    },
    updateSignDate: function(value) {
      this.signDate = value
    },
    updateGrossAmount: function(value) {
      this.portfolioValuePlusExtraCosts = Number(value)
    },
    getCreditCloseReasons: async function() {
      const response = await creditApi.closeReasons()
      const closeReason = this.reprogramRequestType != 'delayed_payment' ? response.data.filter((entry)=> entry != 'delayed_payment') : response.data
      this.closeReasonOptions = [
        { value: null, text: this.t('activerecord.attributes.credit.close_reason_list_label') },
        ...closeReason.map((r) => ({ value: r, text: this.t(`activerecord.attributes.credit.close_reason_list.${ r }`) }))
      ]
    },
    getExtraCostsList: async function() {
      const response = await creditApi.extraCostsList()
      this.extraCostsList = response.data
    },
    setTmc: function(value) {
      this.annualTmc = Number(value)
      this.simpleTmc = _.round(value / 12, 6)
    },
    getUf: async function() {
      const response = await ufApi.atDate(this.signDate)
      this.ufAtSignDate = response.data ? response.data : 0
    },
    getTmc: async function(amount) {
      const response = await tmcApi.forAmount(
        amount,
        this.signDate
      )
      console.log('set tmc')
      this.setTmc(response.data)
    },
    getOutstandingBalance: async function() {
      const response = await creditApi.outstandingBalance(
        this.creditSlug,
        this.signDate
      )

      this.outstandingBalance = response.data.value
      this.outstandingBalanceInUf = response.data.value_in_uf ? response.data.value_in_uf : 0
      this.addExtraCostsTotalToOutstandingBalance()
    },
    updateOutstandingBalanceInUf: function() {
      this.outstandingBalanceInUf = this.outstandingBalance / this.ufAtSignDate
    },
    updateOutstandingBalancePlusExtraCostsInUf: function() {
      this.outstandingBalancePlusExtraCostsInUf = this.outstandingBalancePlusExtraCosts / this.ufAtSignDate
    },
    getPortfolioValue: async function() {
      const response = await creditApi.portfolioValue(
        this.creditSlug,
        this.signDate
      )

      this.portfolioValue = Math.round(response.data.value)
      this.portfolioValueInUf = response.data.value_in_uf ? response.data.value_in_uf : 0
      this.addExtraCostsTotalToPortfolioValue()
    },
    updatePortfolioValueInUf: function() {
      this.portfolioValueInUf = this.portfolioValue / this.ufAtSignDate
    },
    updatePortfolioValuePlusExtraCostsInUf: function() {
      this.portfolioValuePlusExtraCostsInUf = this.portfolioValuePlusExtraCosts / this.ufAtSignDate
    },
    updateRate: function(value) {
      this.rate = Number(value)
    },
    addExtraCost: function() {
      if (_.isNil(this.selectedExtraCostName)) { return }

      this.removeExtraCost(this.selectedExtraCostName)
      this.selectedExtraCosts = [
        ...this.selectedExtraCosts,
        {
          name: this.selectedExtraCostName,
          human_name: this.selectedExtraCostHumanName,
          amount: this.selectedExtraCostAmount
        }
      ]

      this.selectedExtraCostName = null
      this.selectedExtraCostHumanName = null
      this.selectedExtraCostAmount = null
    },
    removeExtraCost: function(name) {
      this.selectedExtraCosts = this.selectedExtraCosts.filter((ec) => ec.name !== name)
    },
    reprogramExtraCostsTotal: function() {
      return Number(this.selectedExtraCosts.reduce((sum, val) => (Number(sum) + Number(val.amount)), 0))
    },
    addExtraCostsTotalToOutstandingBalance: function() {
      this.outstandingBalancePlusExtraCosts = Number(this.outstandingBalance) + this.reprogramExtraCostsTotal()
      this.updateOutstandingBalancePlusExtraCostsInUf()
    },
    addExtraCostsTotalToPortfolioValue: function() {
      this.portfolioValuePlusExtraCosts = Number(this.portfolioValue) + this.reprogramExtraCostsTotal()
      this.updatePortfolioValuePlusExtraCostsInUf()
    },
    disableReprogramSubmitButton: function() {
      this.reprogramFormSubmitButtonDisabled = true
    },
    setDefaultCloseReason: function() {
      if (this.reprogramType == 'compromise') {
        this.selectedCloseReason = 'compromise'
      } else if (this.reprogramType == 'reprogram' && this.reprogramRequestType == 'delayed_payment') {
        this.selectedCloseReason = 'delayed_payment'
      } else {
        this.selectedCloseReason = 'other'
      }
    },
    submitReprogramForm: function() {
      this.disableReprogramSubmitButton()
      this.$refs.reprogramForm.submit()
    },
    installmentsUpdated: function(value) {
      this.installments = value
    },
    addProductTypeExtraCosts: async function () {
      const response = await creditApi.productTypeExtraCosts(this.selectedProductTypeId)
      this.addExtraCostsGroup(response.data)
    },
    getProductTypesList: async function() {
      const response = await creditApi.productTypesList()
      this.productTypesList = response.data
    },
    setSelectedProductType: function(value) {
      this.selectedProductType = this.productTypesList.filter(productType => productType.id === value)[0]
    },
    setDefaultProductType: function() {
      this.selectedProductTypeId = parseInt(this.originalProductTypeId)
    }
  },
  created: async function() {
    console.log('empezo')
    this.isShowingCalculator = false
    this.setCreditSlug()
    this.getCreditCloseReasons()

    this.credit = (await creditApi.getCredits(this.creditCode, 1))[0]

    this.setDefaultCloseReason()
    this.getExtraCostsList()
    this.getUf()
    await this.getOutstandingBalance()
    await this.getPortfolioValue()
    await this.getTmc(this.portfolioValuePlusExtraCosts)
    await this.getProductTypesList()
    this.setDefaultProductType()
    console.log('termino')
    this.isShowingCalculator = true
  },
  computed: {
    reprogramAction: function() {
      return `/credits/${this.creditSlug}/do_reprogram`
    },
    disableReprogramSubmit: function() {
      return this.reprogramFormSubmitButtonDisabled || !(this.simulationId && this.selectedCloseReason)
    },
    csrfToken: function() {
      return document.querySelector('meta[name="csrf-token"]').getAttribute('content')
    },
    extraCostsForNetAmount: function() {
      return Number(this.selectedExtraCosts.filter(({category}) => category == 'net')
        .reduce((sum, val) => Number(sum) + Number(val.amount), 0))
    },
    isDisabledcloseReson: function() {
      return this.reprogramType == 'compromise' || (this.reprogramType == 'reprogram' && this.reprogramRequestType == 'delayed_payment')
    }
  },
  watch: {
    selectedProductTypeId: function(newValue, oldValue) {
      this.setSelectedProductType(newValue)
    },
    signDate: function(newValue, oldValue) {
      this.getUf()
      this.getOutstandingBalance()
      this.getPortfolioValue()
      this.getTmc(this.portfolioValuePlusExtraCosts)
    },
    outstandingBalance: function(newValue, oldValue) {
      this.outstandingBalancePlusExtraCosts = newValue + this.reprogramExtraCostsTotal()
    },
    outstandingBalancePlusExtraCosts: function(newValue, oldValue) {
      this.updateOutstandingBalancePlusExtraCostsInUf()
      this.getTmc(this.outstandingBalancePlusExtraCosts)
    },
    portfolioValue: function(newValue, oldValue) {
      this.portfolioValuePlusExtraCosts = newValue + this.reprogramExtraCostsTotal()
    },
    portfolioValuePlusExtraCosts: function(newValue, oldValue) {
      this.updatePortfolioValuePlusExtraCostsInUf()
      this.getTmc(this.portfolioValuePlusExtraCosts)
    },
    selectedExtraCostName: function(newValue, oldValue) {
      if (_.isNil(newValue)) {
        this.selectedExtraCostHumanName = null
      } else {
        this.selectedExtraCostHumanName = this.extraCostsList.find((item) => item.name === newValue).human_name
      }
    },
    selectedExtraCosts: function(newValue, oldValue) {
      this.addExtraCostsTotalToOutstandingBalance()
      this.addExtraCostsTotalToPortfolioValue()
    }
  }
}
</script>

<style lang="scss" scoped>
ul.extra-costs-list {
  .delete {
    color: #dc3545;
  }
}
</style>
