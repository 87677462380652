<template>
<div class="horizontal-scroll">
  <table aria-label="Incomes Table" class="table table-responsive">
    <thead>
      <tr>
        <th></th>
        <th v-for="(month) in [0,1,2,3,4,5,6,7]" v-bind:key="month.id">
          {{formatDate(getRelativeMonthAndYear(month))}}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(attribute) in ['taxable_income', 'afc_contribution', 'fees', 'afp_contribution' ]" v-bind:key="attribute.id">
        <td>
          {{t(`activerecord.attributes.client_income.${attribute}`)}}
        </td>
        <td v-for="(month) in [0,1,2,3,4,5,6,7]" v-bind:key="month.id">
          {{getRelativeValueOfDate(month, attribute)}}
        </td>
      </tr>
    </tbody>
  </table>
</div>
</template>
<script>
import i18n from "../mixins/i18n"

export default {
  name: "incomes-table",
  mixins: [i18n],
  props: [
    'clientIncomes',
    'relationshipType'
  ],
  data() {
    return {
    }
  },
  methods: {
    formatDate(date) {
      return date.toLocaleDateString("en-US", {
        month: 'long',
        year: 'numeric'
      });
    },
    getRelativeMonthAndYear(month) {
      var date = new Date();
      date.setDate(1)
      date.setMonth(date.getMonth() - month);
      return date
    },
    getRelativeValueOfDate(month, attribute) {
      let date = this.getRelativeMonthAndYear(month);

      let clientIncome = this.clientIncomes.find((ci) => {
        return ci.month - 1 == date.getMonth() && ci.year == date.getFullYear() }
      )
      return clientIncome ? this.$options.filters.currency(clientIncome[attribute], '$', 0, { thousandsSeparator: '.', decimalSeparator: ',' }) : ''
    }
  }
}
</script>
<style>
.horizontal-scroll {
  overflow-y: hidden;
  overflow-x: auto;
}

</style>
