import axios from 'axios';
import * as humps from 'humps';
import { config } from '../constants/api'

function format_request_for_simulation(params) {
  const data = humps.decamelizeKeys(params)
  const { net_amount, total_net_amount, ...remaining } = data

  const body = {
    simulation: {
      ...remaining,
      net_amount: total_net_amount
    }
  }

  return body
}

export default {
  async create(params) {
    const path = '/simulations.json'
    const data = format_request_for_simulation(params)

    return await axios.post(path, data, config)
      .then((res) => res) // TODO: refactor simulation creation
      .catch((error) => error.response)
  },
  async createFromFerry(params) {
    const path = '/simulations/create_from_ferry.json'
    const data = params

    return await axios.post(path, data, config)
      .then((res) => res) // TODO: refactor simulation creation
      .catch((error) => error.response)
  },
  async recalculateFromFerry(id, params) {
    const path = `/simulations/${id}/recalculate_from_ferry.json`
    const data = params

    return await axios.post(path, data, config)
      .then((res) => res) // TODO: refactor simulation creation
      .catch((error) => error.response)
  },
  async update(simulationId, params) {
    const path = `/simulations/${simulationId}.json`

    const body = humps.decamelizeKeys({
      simulation: params
    })

    return await axios.patch(path, body, config)
      .then((res) => res.data)
      .catch((error) => error.response)
  },
  async deleteItem(id, productId) {
    const path = `/simulations/${id}/delete_item.json`
    const data = {
      product_id: productId
    }

    return await axios.post(path, data, config)
      .then((res) => res.data)
      .catch((error) => error.response)
  },
  async findSimulations(simulationStatuses, offset) {
    const path = `/simulations/find_simulations.json`
    const data = {
      simulation_statuses: simulationStatuses,
      offset: offset
    }

    return await axios.post(path, data, config)
      .then((res) => res.data)
      .catch((error) => error.message)
  },
  async getSimulation(simulationId) {
    const path = `/simulations/${simulationId}.json`

    return await axios.get(path, config)
      .then((res) => res.data)
      .catch((error) => error.response)
  },
  async recalculate(simulationId, params) {
    const path = `/simulations/${simulationId}/recalculate.json`
    const data = format_request_for_simulation(params)

    return await axios.patch(path, humps.decamelizeKeys(data), config)
      .then((res) => res)
      .catch((error) => error.response)
  },
  async addGuarantor(simulationId, client) {
    const path = `/simulations/${simulationId}.json`
    let data = {}

    if (client.id) {
      data = {
        guarantors_attributes: [
          {
            client_id: client.id
          }
        ]
      }
    } else {
      data = {
        guarantors_attributes: [
          {
            client_attributes: {
              ...client
            }
          }
        ]
      }
    }

    return await axios.patch(path, data, config)
      .then((res) => res.data)
      .catch((error) => error.response)
  },
  async removeGuarantor(simulationId, guarantorId) {
    const path = `/simulations/${simulationId}.json`
    const data = {
      guarantors_attributes: [
        {
          id: guarantorId,
          _destroy: true
        }
      ]
    }

    return await axios.patch(path, data, config)
      .then((res) => res.data)
      .catch((error) => error.response)
  },
  async addComment(simulationId, comment) {
    const path = `/comments.json`
    const data = {
      comment:
        {
          content: comment,
          commentable_id: simulationId,
          commentable_type: 'Simulation'
        }
    }

    return await axios.post(path, data, config)
      .then((res) => res.data)
      .catch((error) => error.response)
  },
  async getMinimumRequirements(params) {
    const path = `/simulations/${params.simulation_id}/missed_minimum_requirements.json`
    const body = {
      id: params.simulation_id
    }
    return await axios.get(path, humps.decamelizeKeys(body), config).then(res => res.data)
  },
  async getSpecialInstallments(simulationId) {
    const path = `/simulations/${simulationId}/special_installments.json`

    return await axios.get(path, config)
      .then((res) => res)
      .catch((error) => error.response)
  },
}
