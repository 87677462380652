<template>
  <b-form key="field">
    <b-row class="col" >
      <b-col md="3" sm="12" :class="`${ isReadonly ? 'd-flex align-items-center' : ''}`">
        <b-form-group v-if="!isReadonly" label="Nombre atributo" invalid-feedback="Nombre es requerido">
          <b-form-input v-model="name" placeholder="Nombre del campo" :disabled="isDisabled" required></b-form-input>
          <span class="text-danger" v-if="errors.name" >{{ errors.name }}</span>
        </b-form-group>
        <div v-else>
          {{ name }}
        </div>
      </b-col>

      <b-col md="3" sm="12" :class="`${ isReadonly ? 'd-flex align-items-center' : ''}`">
        <b-form-group v-if="!isReadonly" label="Descripción (Opcional)" >
          <b-form-textarea v-model="description" :disabled="isDisabled" placeholder="Descripcion breve" rows="1"></b-form-textarea >
        </b-form-group>
        <div v-else>
          {{ description }}
        </div>
      </b-col>

      <b-col md="2" sm="12" :class="`${ isReadonly ? 'd-flex align-items-center' : ''}`">
        <b-form-group v-if="!isReadonly" label="Tipo de atributo" invalid-feedback="Tipo es requerido">
          <div class="d-flex">
            <b-form-select v-model="field_type" :options="fieldTypes" :disabled="isDisabled" required></b-form-select>
            <b-button type="button" variant="outline-primary" v-if="isSelectField" @click="toggleShowListModal">
              <i class="fas fa-pen"></i>
            </b-button>
          </div>
          <span class="text-danger" v-if="errors.field_type" >{{ errors.field_type }}</span>
        </b-form-group>
        <div v-else>
          {{ field_type.text }}
          <div v-if="isSelectField">
            <ul class="list-disc ml-4">
              <li v-for="option in field_options" v-bind:key="option" >
                {{ option }}
              </li>
            </ul>
          </div>
        </div>
      </b-col>

      <list-modal
        v-if="showListModal && isSelectField && !isReadonly"
        :readonly="isReadonly"
        :name="name"
        :options="field_options"
        :isDisabled="isDisabled"
        @onOptionAdded="onOptionAdded"
        @onOptionEditted="onOptionEditted"
        @removeOption="removeOption"
        @toggleShowListModal="toggleShowListModal"
      />

      <b-col md="1" sm="12" :class="`${ isReadonly ? 'd-flex align-items-center' : ''}`">
        <b-form-group v-if="!isReadonly" label="Requerido">

          <b-form-radio
            id="checkbox-1"
            v-model="required"
            name="checkbox-1"
            :value="true"
          >
            Si
          </b-form-radio>
          <b-form-radio
            id="checkbox-2"
            v-model="required"
            name="checkbox-2"
            :value="false"
          >
            No
          </b-form-radio>
        </b-form-group>
        <div v-else>
          {{ required ? 'Si' : 'No' }}
        </div>
      </b-col>

      <b-col md="2" sm="12" :class="`${ isReadonly ? 'd-flex align-items-center' : ''}`">
        <b-form-group>
          <b-form-checkbox
            :id="name"
            :name="`checkbox-${name}`"
            v-model="notificationTrigger"
            :unchecked-value="null"
            :value="{name: name, type: getFieldTypeType}"
            size="lg"
            switch
          ></b-form-checkbox>
        </b-form-group>
      </b-col>

      <b-col md="1" sm="12" :class="`p-0 ${ isReadonly ? 'd-flex align-items-center' : ''}`">
        <div class="w-100" v-if="isNew">
          <b-button size="sm" variant="primary" class="rounded" block type="submit" @click="onSubmit"> Crear </b-button>
        </div>
        <div v-else>
          <b-form-group v-if="isEditing">
            <b-button size="sm" variant="primary" block type="button" @click="onSubmitEdit"> Cambiar </b-button>
          </b-form-group>

          <div v-if="isReadonly">
            <b-button size="sm" variant="outline-primary" type="button" @click="toggleEditing" > <i class="fas fa-pen"></i> </b-button>
            <b-button size="sm" variant="outline-danger" type="button" @click="onDelete"> <i class="fas fa-trash"></i> </b-button>
          </div>

        </div>
      </b-col>

    </b-row>
  </b-form>

</template>

<script>
  import { v4 as uuidv4 } from 'uuid';
  import _ from 'lodash';
  import OptionsForm from './OptionsForm.vue';
  import ListModal from './ListModal.vue';
  const NAME_REQUIRED_FIELDS = ['name', 'field_type']

  export default {
    name: 'FieldForm',
    components: {
      OptionsForm,
        ListModal
    },
    props: [
      'initField',
      'readonly',
      'notificationTriggerName'
    ],
    data() {
      return {
        isNew: _.isNil(this.initField?.id),
        id: this.initField?.id || uuidv4(),
        name: this.initField?.name || '',
        field_type: this.initField?.field_type || null,
        required: this.initField?.required || false,
        description: this.initField?.description || '',
        field_options: this.initField?.field_options || [],
        isEditing: false,
        isReadonly: this.readonly || false,
        showListModal: false,
        showAddItem: false,
        disabledAddItem: false,
        notificationTrigger: this.notificationTriggerName ? [this.notificationTriggerName] : [],//this.notificables,
        errors: {
          name: '',
          field_type: null,
          required: '',
          description: '',
          field_options: ''
        },
        fieldTypes: [
          { value: null, text: 'Seleccionar una Opción', selected: true },
          { value: {type: 'text', name: 'InputField', text: 'Texto corto'},     text: 'Texto corto' },
          { value: {type: 'textarea', name: 'TextAreaField', text: 'Texto Largo'}, text: 'Texto Largo' },
          { value: {type: 'number', name: 'InputField', text: 'Numérico'},   text: 'Numérico' },
          { value: {type: 'select', name: 'SelectField', text: 'Lista'},   text: 'Lista' },
          { value: {type: 'date', name: 'InputField', text: 'Fecha'}, text: 'Fecha' },
          { value: {type: 'checkbox', name: 'CheckboxField', text: 'Casilla de verificación'}, text: 'Casilla de verificación' },          { value: {type: 'checkbox', name: 'CheckboxField', text: 'Casilla de verificación'}, text: 'Casilla de verificación' },

        ]
      }
    },
    computed: {
      isDisabled() {
        return !this.isNew && !this.isEditing  ? true : false
      },
      getFieldTypeName() {
        return this.field_type?.name
      },
      getFieldTypeType() {
        return this.field_type?.type
      },
      isSelectField() {
        return this.getFieldTypeName === 'SelectField'
      }
    },
    watch: {
      notificationTrigger: function(newValue, oldValue){
        let result = {
          value: newValue ? newValue[0] : oldValue[0],
          action: newValue ? 'add' : 'remove'
        }

        this.$emit('updateNotificationTrigger', result)
      }
    },
    methods: {
      onSubmit(event) {
        event.preventDefault()
        if(!this.valid()) return;
        this.$emit('addField', {
          id: this.id,
          name: this.name,
          field_type: {...this.field_type},
          required: this.required,
          description: this.description,
          field_options: this.field_options,
        });

        this.id = null
        this.name = ''
        this.field_type = null
        this.required = false
        this.description = ''
        this.field_options = []
      },
      onSubmitEdit(){
        if(!this.valid()) return;
        this.$emit('updateField', {
          id: this.id,
          name: this.name,
          field_type: {...this.field_type},
          required: this.required,
          description: this.description,
          field_options: this.field_options,
        });
        this.toggleEditing()
      },
      onOptionAdded(value) {
        this.field_options.push(value)
      },
      onOptionEditted(value, index){
        this.field_options[index] = value
      },
      removeOption(value) {
        this.field_options = _.remove(this.field_options, (f, index) => { return index !== value })
      },
      toggleEditing(event) {
        this.isEditing = !this.isEditing
        this.isReadonly = !this.isReadonly
      },
      onDelete() {
        this.$emit('deleteField', this.id)
      },
      valid() {
        let valid = true
        for (let index = 0; index < NAME_REQUIRED_FIELDS.length; index++) {
          const element = NAME_REQUIRED_FIELDS[index];
          if(!this[element]){
            this.errors[element] = 'Es requerido'
            valid = false
          } else {
            this.errors[element] = ''
          }
        }

        return valid
      },
      toggleShowListModal() {
        this.showListModal = !this.showListModal
      }
    }

  }
</script>
