<template>
  <b-modal id="modal-add-errand" size="lg">
    <template #modal-title>
      <span class="font-bold">
        {{ t(
              `modal_add_errand.add_errand_to`,
              {'errandable_number': errandableType == 'Operation' ? `Nº Op ${errandableNumber}` : `Item Nº ${errandableNumber}`}
            )
        }}
      </span>
    </template>
    <div class="container-modal flex col-12" style="padding: 0;">
      <div class="grid col-6" style="padding-left: 0px;">
        <span class="normal-text mbp-12">{{ t(`modal_add_errand.select_procedure`) }}</span>
        <dropdown-filter
          ref="dropdown_errand_type"
          :items="errandTypeList"
          :radio-inputs="true"
          v-on:onFilter="errandSelected"
          :text="t(`modal_add_errand.select_procedure`)"/>
      </div>
      <div class="grid col-6" style="padding-rigth: 0px;">
        <span class="normal-text mbp-12">{{ t(`modal_add_errand.responsible_procedure`) }}</span>
        <dropdown-filter
          ref="dropdown_responsible"
          :items="responsiblesList"
          :radio-inputs="true"
          v-on:onFilter="responsibleSelected"
          :text="t(`modal_add_errand.responsible_procedure`)"/>
      </div>
    </div>
    <div v-if="errandSelectOn" class="container-modal mtp-12">
      <h4 class="title t2">{{ t(`modal_add_errand.errand_summary`) }}</h4>
      <div class="errand-summary">
        <tr>
          <td><span class="normal-text">{{ summaryErrand.errand_type_name }}</span></td>
          <td><span class="normal-text">{{ `${summaryErrand.limit_days} ${t(`datetime.prompts.days`)}` }}</span></td>
          <td>
            <div v-for="(sub_status, index) in summaryErrand.sub_statuses" v-bind:key="index" class="errands-list normal-text">
              <span>{{ sub_status.name }} - {{ sub_status.general_status }} - {{ `${sub_status.limit_days} ${t(`datetime.prompts.days`)}` }}</span>
            </div>
          </td>
        </tr>
      </div>
    </div>
    <template #modal-footer>
      <button class="footer-button base-button outline mrp-24" @click="$bvModal.hide('modal-add-errand')">{{ t(`views.cancel`) }}</button>
      <button class="footer-button base-button" @click="confirmEvent" :disabled="!isSelected">{{ t(`views.add`) }}</button>
    </template>
  </b-modal>
</template>
<script>
import i18n from "../mixins/i18n"
import capitalize from "../mixins/capitalize"
import errand from '../../api/errands'
import DropdownFilter from '../dynamics_form/DropdownFilter.vue'

export default {
  name: 'modal-add-errand',
  mixins: [i18n, capitalize],
  components: { DropdownFilter },
  props: {
    confirmEventModal: Function,
    errandTypeList: Array,
    responsiblesList: Array,
    errandableId: [Number, String],
    errandableType: String,
    errandableNumber: String,
  },
  data() {
    return {
      errandSelectOn: false,
      responsibleSelectOn: false,
      summaryErrand: [],
      errandId: null,
      responsibleId: null,
    }
  },
  methods: {
    confirmEvent() {
      const errandType = this.errandTypeList.find(errand => errand.id === this.errandId[0])
      const responsible = this.responsiblesList.find(responsible => responsible.id === this.responsibleId[0])
      this.$emit('confirmEventModal', errandType, responsible, this.summaryErrand)
      this.$bvModal.hide('modal-add-errand')
      this.errandSelectOn = false
      this.responsibleSelectOn = false
    },
    errandSelected: async function(item) {
      this.errandId = item
      this.summaryErrand = await errand.summaryErrand(item, this.errandableId, this.errandableType)
      this.errandSelectOn = true;
    },
    responsibleSelected(item) {
      this.responsibleId = item
      this.responsibleSelectOn = true;
    },
  },
  computed: {
    isSelected() {
      return this.errandSelectOn && this.responsibleSelectOn
    }
  },
  mounted() {
    this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
      if(modalId === 'modal-add-errand'){
        this.errandSelectOn = false
        this.responsibleSelectOn = false
      }
    })
  }
}
</script>
<style>
  .errand-summary {
    border-bottom: 1px solid #E6E6E6;
  }

  .errand-summary td {
    padding: 12px 27.5px;
  }

  .errand-summary td .errands-list {
    display: grid;
    align-items: center;
  }
</style>
