<template>
  <div class="per-page-component flex-vertical-center color-gray sm-center">
    <p>Filas por página:</p>
    <select v-model="perPage" name="select" @change="onChangePerPage($event)">
      <option value="5">5</option>
      <option value="10" selected>10</option>
      <option value="15">15</option>
      <option value="20">20</option>
      <option value="50">50</option>
      <option value="100">100</option>
    </select>
  </div>
</template>
<script>
export default {
  name: 'per-page',
  props: {
    perPage: {
      type: Number,
      require: true
    },
  },
  methods: {
    onChangePerPage(event) {
      this.$emit('update:perPage', parseInt(event.target.value))
    },
  }
}
</script>
<style scoped>
  @import '../../../assets/stylesheets/per_page.scss';
</style>
