import axios from 'axios'
import * as humps from 'humps'
import { config } from '../constants/api'

export default {
  async create(percentage, creditCode) {
    const path = `/extraordinary_payments.json`

    const params = {
      credit_code: creditCode,
      percentage
    }

    return axios.post(
      path,
      params,
      config
    ).then(res => res)
    .catch(error => error.response)
  },
  async update(id, percentage) {
    const path = `/extraordinary_payments/${id}.json`
    const body = {
      percentage
    }

    return axios.patch(
      path,
      humps.decamelizeKeys(body),
      config
    ).then(res => res)
    .catch(error => error.response)
  },
  async cancel(id) {
    const path = `/extraordinary_payments/${id}/cancel.json`

    return axios.put(
      path,
      config
    ).then(res => res)
    .catch(error => error.response)
  },
}
