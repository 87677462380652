<template>
  <b-dropdown class="dropdown-form" ref="dropdown" variant="link" :style="`width: ${ inputWidth }`"
    toggle-class="button button-secondary button-sm dropdown-custom text-decoration-none" :disabled="isDisabled">
    <template #button-content>
      <div class="flex-center">
        {{ radioInputs ? textSelectedMethod : text }}
        <slot name="content-button"></slot>
        <span class='selected-number' v-bind:style="{ display: isSelected }">{{ itemsSelected }}</span>
      </div>
    </template>
    <b-dropdown-form @submit.stop.prevent style="width: 100% !important;">
      <searcher
        v-if="ifSearcher"
        :button="false"
        v-on:inputSearch="searchFilter"
        inputWidth="100%"
        v-bind:style="{ marginBottom: radioInputs ? '0px' : '12px' }"
        :input-placeholder="`${t(`views.search`)}`"/>
      <div v-if="editPermissions" class="flex">
        <button v-if="!radioInputs" class="button-link" style="margin-right: 12px;" @click="selectAll">{{ t(`views.select_all`) }}</button>
        <button v-if="!radioInputs" class="button-link" @click="clearAll">{{ t(`views.clear`) }}</button>
      </div>
      <div v-if="updateItems.length == 0" class="flex justify-content-center" style="padding: 35px;">
        <span class="normal-text">Sin items</span>
      </div>
      <div v-if="updateItems.length > 0" class="container-check-list" v-bind:class="{ 'only-one': radioInputs }">
        <span v-if="title != ''" class="highlight_sub_title color-default">{{ title }}</span>
        <b-form-checkbox v-for="item in updateItems" :key="item.id"
          class="normal-text mtp-12"
          :disabled="!editPermissions"
          :ref='item.id'
          :value='item.id'
          :value-name='translate ? t(`views.${item.name}`) : item.name'
          v-model='itemsCheck'>
          {{ translate ? t(`views.${item.name}`) : item.name }}
        </b-form-checkbox>
      </div>
      <div v-if="editPermissions" class="flex footer-buttons">
        <button v-if="ifCancel" class="button button-secondary" style="margin-right: 14px;" @click="closeFilter">{{ t(`views.cancel`) }}</button>
        <button class="button button-primary" @click="getSelected">{{ t(`views.accept`) }}</button>
      </div>
    </b-dropdown-form>
  </b-dropdown>
</template>
<script>
import _ from 'lodash'
import i18n from "../mixins/i18n"
import capitalize from "../mixins/capitalize"
import Searcher from './Searcher.vue'

export default {
  components: { Searcher },
  name: "dropdown-filter",
  mixins: [i18n, capitalize],
  props: {
    items: Array,
    text: String,
    listCheck: {
      type: Array,
      default: () => []
    },
    radioInputs: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    ifCancel: {
      type: Boolean,
      default: true
    },
    ifSearcher: {
      type: Boolean,
      default: true
    },
    onFilter: {
      type: Function,
      default: () => {},
    },
    inputWidth: {
      type: String,
      default: 'auto'
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    translate: {
      type: Boolean,
      default: false
    },
    editPermissions: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      itemsCheck: [],
      isSelected: 'none',
      textSelected: null,
    }
  },
  methods: {
    closeFilter() {
      this.$refs.dropdown.hide(true)
    },
    selectAll() {
      this.itemsCheck = []
      for (var index in this.items) {
        this.itemsCheck.push(this.items[index].id)
      }
    },
    clearAll() {
      this.itemsCheck = []
    },
    searchFilter(value) {
      let elements = this.$el.getElementsByClassName('custom-checkbox')
      if(value === ''){
        for (let i = 0; i < elements.length; i++){
          elements[i].style.display = 'block'
        }
        return;
      }
      for (let i = 0; i < elements.length; i++){
        if(!elements[i].firstChild.getAttribute('value-name').toLowerCase().includes(value.toLowerCase())){
          elements[i].style.display = 'none'
        }
      }
    },
    getSelected: function(){
      if(this.radioInputs && !_.isNil(this.itemsCheck[0]))
        this.textSelected = this.items.find(item => this.itemsCheck[this.itemsCheck.length-1] == item.id)?.name
      this.$refs.dropdown.hide(true)
      this.$emit('onFilter', this.itemsCheck)
    },
    getItemsCheck() {
      return {
        items: this.items,
        itemsCheck: this.itemsCheck
      }
    },
    getItemsSelectedAll() {
      return this.itemsCheck.map(item => {
        if(typeof item === 'object')
          return Object.assign({}, item)
        return item
      })
    },
    getItemsSelected() {
      return this.itemsCheck
    },
    setItemsCheck(items) {
      this.itemsCheck = items
    },
  },
  mounted() {
    this.$root.$on('bv::dropdown::show', bvEvent => {
      bvEvent.vueTarget.$refs.toggle.classList.add('active')
    })
    this.$root.$on('bv::dropdown::hide', bvEvent => {
      bvEvent.vueTarget.$refs.toggle.classList.remove('active')
    })
    this.setItemsCheck(this.listCheck)
  },
  computed: {
    itemsSelected() {
      if(this.radioInputs){
        this.itemsCheck = [this.itemsCheck[this.itemsCheck.length-1]]
        this.textSelected = this.items.find(item => this.itemsCheck[this.itemsCheck.length-1] == item.id)?.name
        return 0;
      } else {
        let length = this.itemsCheck?.length
        this.isSelected = 'block'
        if(length == 0)
          this.isSelected = 'none'
        return length;
      }
    },
    updateItems() {
      if(this.items.length > 0) {
        if(typeof this.items[0] == 'string')
          this.items.forEach(function(item, index, object) {
            object[index] = {
              id: item,
              name: item
            }
          })
      } else {
        this.clearAll()
      }
      this.textSelected = null
      return this.items
    },
    textSelectedMethod() {
      return this.textSelected ?? this.text
    },
  },
}
</script>
<style lang="scss">
  @import '../../../assets/stylesheets/dropdown_filter.scss';
</style>
