<template>
  <b-modal id="modal-mass-edit-sub-state" size="lg">
    <template #modal-title>
      <span class="title t1">{{ t(`modal_mass_edit_sub_state.title`) }}</span>
    </template>
    <div class="container-modal">
      <p class="color-default mbp-12">{{ t(`errand_type.change_two_errands`) }}:</p>
      <select-input
        :options="subStatusesList"
        placeholder="Cambiar al sub estado"
        input-height="40px"
        v-on:onChange="onChangeState"/>
    </div>
    <div class="container-modal" style="margin-top: 0px;">
      <div class="table-responsive table-scroll">
        <table class="table b-table">
          <thead>
            <tr>
              <th>{{ t(`views.number_of_operation`) }}</th>
              <th>{{ t(`activerecord.models.errand_type`) }}</th>
              <th>{{ t(`modal_mass_edit_sub_state.current_sub_state`) }}</th>
              <th></th>
              <th>{{ t(`modal_mass_edit_sub_state.change_to_sub_status`) }}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="errand in itemsSelected" :key="errand.id_prop">
              <td>{{ errand.signature_and_operation_number.operation_number }}</td>
              <td>{{ errand.errand_type.name }}</td>
              <td>{{ errand.sub_status.name }}</td>
              <td><i class="fas fa-arrow-right" v-bind:style="styleArrow"></i></td>
              <td>{{ selectedState.name }}</td>
              <td><button class="button-outback a-link" @click="removeItem(errand)"> {{ t(`modal_mass_edit_sub_state.remove`) }} </button></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div>
      <h4 class="title t2 mb-4">{{ t(`errand.add_comment`)}}</h4>
      <b-form-textarea
        id="textarea-default"
        :placeholder="`${t('errand.input_comment')}`"
        v-model="currentComment"
      ></b-form-textarea>
    </div>

    <template #modal-footer>
      <button class="footer-button base-button outline mrp-24" @click="$bvModal.hide('modal-mass-edit-sub-state')">{{ t(`views.cancel`) }}</button>
      <button class="footer-button base-button" @click="confirmEvent" :disabled="isSelected">{{ t(`views.change`) }}</button>
    </template>
  </b-modal>
</template>
<script>
import i18n from "../mixins/i18n"
import capitalize from "../mixins/capitalize"
import SelectInput from '../dynamics_form/SelectInput.vue'

export default {
  components: { SelectInput },
  name: "modal-mass-edit-sub-state",
  mixins: [i18n, capitalize],
  props: {
    itemsSelected: {
      type: Array,
      default: () => []
    },
    subStatusesList: Array,
    confirmEventModal: Function,
    removeItemModal: Function,
  },
  data() {
    return {
      selectedState: '',
      styleArrow: {
        color: '#484848',
        fontSize: '16.5px'
      },
      currentComment: null
    }
  },
  methods: {
    onChangeState(value) {
      this.selectedState = value
    },
    clearSelectedState() {
      this.selectedState = ''
    },
    removeItem(errand) {
      let itemsSelectedTmp = this.itemsSelected.filter(item => {
        if(item.id_prop != errand.id_prop)
          return item
      })
      this.$emit('update:itemsSelected', itemsSelectedTmp)
      this.$emit('removeItemModal', errand.id_prop)
      if(itemsSelectedTmp.length === 0)
        this.$bvModal.hide('modal-mass-edit-sub-state')
    },
    confirmEvent() {
      this.$emit('confirmEventModal', {
        items: this.itemsSelected.map(item => item.id),
        status: this.selectedState,
        itemsObj: this.itemsSelected,
        comment: this.currentComment
        }
      )
      this.$bvModal.hide('modal-mass-edit-sub-state')
    },
  },
  mounted() {
    this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
      if(modalId === 'modal-mass-edit-sub-state'){
        this.clearSelectedState()
      }
    })
  },
  computed: {
    isSelected() {
      return this.selectedState === ''
    }
  }
}
</script>
<style>
  #modal-mass-edit-sub-state___BV_modal_content_ {
    width: max-content !important;
  }
</style>
