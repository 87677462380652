<template>
  <div class="card border-0 shadow-light-40 p-3">
    <loader ref="loader"/>
    <searcher-calendar @searchCalendar="searchCalendar"/>
    <section class="flex-space-between mt-3">
      <filters-calendar
        ref="filters"
        :stage-list="stageList"
        @stageFilter="stageFilter"
        @selectMyCalendars="selectMyCalendars"
        @submitDateFilter="submitDateFilter"
      />
      <div>
        <strong>{{ t('extra_options.massive_actions') }}</strong>
        <button class="button button-secondary  mt-2" @click="openMassEditModal">
          {{ t('calendar.mass_editing_responsible') }}
          <em class="fas fa-edit ml-2"></em>
        </button>
      </div>
    </section>
    <div class="horizontal-line my-3"></div>
    <section class="flex-space-between mb-3">
      <selected-filters
        :selected-date="selectedDate"
        @removeDateFilter="removeDateFilter"
      />
      <span class="badge badge-light badge-pill flex-center" v-b-tooltip.hover v-b-tooltip.html
        :title="t('calendar.table_information')">
        {{ t(`views.find_number`, {'number': rows}) }}
        <span class="ml-1">
          <em class="fas fa-exclamation-circle cursor-pointer neutral-70"></em>
        </span>
      </span>
    </section>
    <section>
      <not-found :is-visible="meetingList.length == 0" />
      <calendar-table v-if="meetingList.length > 0"
        ref="table_calendar"
        :meeting-list="meetingList"
        :rows="rows"
        @openModal="openModal"
        :can-edit="editPolicy === 'true'"
      />
    </section>

    <modal-info
      :calendar-items="calendarItemsSelected"
      :responsibles-list="responsiblesList"
      :massive-edit="massiveEdit"
      :if-edit="ifEditModal"
      @onSave="onSaveCalendar"
    />

    <m-modal ref="m-modal"
      :title="titleAlertModal"
      :status="statusAlertModal"
      size="sm"
      :is-centered="true"
      :ok-only="true"/>
  </div>
</template>
<script>
import Loader from '../../template/Loader.vue';
import i18n from "../../mixins/i18n"
import SearcherCalendar from './components/SearcherCalendar.vue';
import FiltersCalendar from './components/FiltersCalendar/FiltersCalendar.vue';
import SelectedFilters from './components/SelectedFilters.vue';
import CalendarTable from './components/CalendarTable.vue';
import NotFound from '../../template/NotFound.vue';
import calendar from '../../../api/calendars.js';
import ModalInfo from './components/ModalInfo.vue';
import MModal from '../../dynamics_form/MModal.vue';

export default {
  components: {
    Loader,
    SearcherCalendar,
    FiltersCalendar,
    SelectedFilters,
    CalendarTable,
    NotFound,
    ModalInfo,
    MModal
  },
  name: 'calendar-index',
  mixins: [i18n],
  props: {
    accountsList: String,
    pipedriveStatus: String,
    editPolicy: String,
    accountId: String
  },
  data() {
    return {
      meetingList: [],
      filters: {
        searchedText: null,
        date: null,
        stages: [],
        assignedId: this.accountId
      },
      selectedDate: null,
      rows: 0,
      calendarItemsSelected: [],
      responsiblesList: JSON.parse(this.accountsList),
      stageList: [],
      massiveEdit: false,
      ifEditModal: false,
      titleAlertModal: '',
      statusAlertModal: '',
    }
  },
  methods: {
    async getMeetingList() {
      this.$refs.loader.isVisible()

      this.meetingList = await calendar.scheduledEvents(this.filters)
      this.rows = this.meetingList?.length ?? 0

      this.$refs.loader.notVisible()
    },
    async onSaveCalendar(saveCalendar, calendarList, newResponsible) {
      this.$refs.loader.isVisible()

      const res = await calendar.saveScheduledEvents(saveCalendar)
      if (res) {
        this.titleAlertModal = this.t('calendar.updated_responsible')
        this.statusAlertModal = 'success'
        calendarList.forEach(uuid => {
          const position = this.meetingList.findIndex(meeting => meeting.uuidEvent === uuid)
          if (position >= 0)
            this.meetingList[position].responsible = newResponsible
        });
        this.$refs.table_calendar.deselectAll()
      } else {
        this.titleAlertModal = this.t('calendar.error_updating_responsible')
        this.statusAlertModal = 'error'
      }
      this.$refs['m-modal'].toggleModal(true)

      this.$refs.loader.notVisible()
    },
    searchCalendar(text) {
      this.filters.searchedText = text
      this.getMeetingList()
    },
    stageFilter(selectedItems) {
      this.filters.stages = selectedItems
      this.getMeetingList()
    },
    selectMyCalendars(selected) {
      this.filters.assignedId = selected.length > 0 ? this.accountId : null
      this.getMeetingList()
    },
    submitDateFilter(selected) {
      this.selectedDate = selected
      this.filters.date = `${selected}T09:00:00.000Z`
      this.getMeetingList()
    },
    removeDateFilter() {
      this.filters.date = null
      this.$refs.filters.cleanDateFilter()
      this.getMeetingList()
    },
    openMassEditModal() {
      this.$refs.loader.isVisible()
      this.calendarItemsSelected = this.$refs.table_calendar.getItemsSelected()
      if (this.calendarItemsSelected.length > 0) {
        this.ifEditModal = true
        this.massiveEdit = true
        this.$bvModal.show('modal-calendar-info')
      }
      this.$refs.loader.notVisible()
    },
    openModal(item, edit = false) {
      this.calendarItemsSelected = [item]
      if (this.calendarItemsSelected.length > 0) {
        this.ifEditModal = edit
        this.massiveEdit = false
        this.$bvModal.show('modal-calendar-info')
      }
    },
  },
  mounted: async function() {
    await this.getMeetingList()

    this.stageList = JSON.parse(this.pipedriveStatus).map(status => ({
      id: status,
      name: this.t(`pipedrive.stage.${status}`)
    }))
  }
}
</script>
