import axios from 'axios'
import moment from 'moment'
import * as humps from 'humps';
import { config } from '../constants/api'

export default {
  async getCredits(q, limit) {
    const path = `/credits/search.json?q=${q}&limit=${limit}`
    const headers = {
      'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
    }

    return await axios.get(path, headers).then((res) => res.data)
  },
  async closeReasons() {
    const path = `/credits/close_reasons.json`;

    const config = {
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      }
    }

    const response = await axios.get(path, config)
    return response
  },
  async productTypesList() {
    const path = `/product_types/list.json`;

    const config = {
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      }
    }

    const response = await axios.get(path, config)
    return response
  },
  async creditTypesList() {
    const path = `/accounts/credit_types.json`;

    const config = {
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      }
    }

    const response = await axios.get(path, config)
    return response
  },
  async productTypeExtraCosts(product_type_id) {
    const path = `/product_types/${product_type_id}/extra_costs_list.json`;

    const config = {
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      }
    }

    const response = await axios.get(path, config)
    return response
  },
  async extraCostsList() {
    const path = `/credits/extra_costs_list.json`;

    const config = {
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      }
    }

    const response = await axios.get(path, config)
    return response
  },
  async outstandingBalance(creditId, date) {
    const dateParam = moment(date).format('YYYY-MM-DD')
    const path = `/credits/${creditId}/outstanding_balance.json`;

    const config = {
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      },
      params: { date: dateParam }
    }

    const response = await axios.get(path, config)
    return response
  },
  async portfolioValue(creditId, date) {
    const dateParam = moment(date).format('YYYY-MM-DD')
    const path = `/credits/${creditId}/portfolio_value.json`;

    const config = {
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      },
      params: { date: dateParam }
    }

    const response = await axios.get(path, config)
    return response
  },
  async createFromSimulation(simulationId, signType) {
    const path = `/credits/create_from_simulation.json`
    const body = {
      credit: {
        simulation_id: simulationId,
        sign_type: signType
      }
    }
    const headers = {
      'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
    }

    return await axios.post(
      path,
      humps.decamelizeKeys(body), {
        headers
      }
    ).then(res => res.data).catch((error) => (error.response))
  },

  async inventoryTypesByProductType(productTypeId) {
    const path = `/inventory_types/by_product_type`;

    const config = {
      params: {
        product_type_id: productTypeId
      },
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      }
    }

    const response = await axios.get(path, config)
    return response
  },

  async deleteItem(itemId, creditCode, itemAvailable) {
    const path = `/credits/${creditCode}/delete_item.json`
    const data = {
      item_id: itemId,
      item_available: itemAvailable
    }

    return await axios.post(path, data, config)
      .then((res) => res)
      .catch((error) => error.response)
  },

  async change_sign_type(creditCode, signType) {
    const path = `/credits/${creditCode}/change_sign_type.json`;

    const data = {
      sign_type: signType
    }
    return await axios.post(path, data, config).then(res => res).catch((error) => (error.response))
  },

  async regenerate_documents(creditCode, signType) {
    const path = `/credits/${creditCode}/regenerate_documents.json`;

    const data = {
      option: signType
    }
    return await axios.post(path, data, config).then(res => res).catch((error) => (error.response))
  }
}
