<template>
  <div>
    <div class="container-fluid">
      <div class="row mb-3 mtp-20">
        <div class="col-5">
          <div class="card">
            <div class="card-header py-3 d-flex flex-row align-item-center justify-content-between">
              <h6 class="m-0 font-weight-bold text-primary">Datos para la simulación</h6>
            </div>
            <div class="card-body">
              <b-alert variant="danger" show v-if="!this.isClientPresent && this.isEmptyCurrentSimulation">
                <p>No se encontró un cliente para esta simulación, busca el enlace correcto en el trato de pipedrive</p>
              </b-alert>
              <client-information
                :guarantors="guarantors"
                :client-id="client.id || this.currentSimulation && this.currentSimulation.client.id"
                :client-name="client.full_name || this.currentSimulation && this.currentSimulation.client.name"
                :pipedrive-person-id="this.pipedrivePersonId || this.currentSimulation.client.pipedrive_id"
                :pipedrive-deal-id="this.pipedriveDealId || this.currentSimulation.pipedrive_id"
                v-if="pipedrivePersonId > 0 || pipedriveDealId > 0 || this.currentSimulation !== null" />

              <dl class="row">
                <dt class="col-5">
                  Valor del Producto
                </dt>
                <dd class="col-7">{{ productValue | currency('$', 0, { thousandsSeparator: '.', decimalSeparator: ',' })   }}</dd>
                <dt class="col-5">
                  Monto Líquido
                </dt>
                <dd class="col-7">{{ totalNetAmount | currency('$', 0, { thousandsSeparator: '.', decimalSeparator: ',' })   }}</dd>
                <dt class="col-5">
                  Monto Bruto
                </dt>
                <dd class="col-7">{{ grossAmount | currency('$', 0, { thousandsSeparator: '.', decimalSeparator: ',' })  }}</dd>
                <dt class="col-5">
                  Tasa máxima convencional simple
                </dt>
                <dd class="col-7">
                  {{ this.simpleTmc | percent(3) }}
                </dd>
                <dt class="col-5">
                  Tasa máxima convencional anual
                </dt>
                <dd class="col-7">
                  {{ this.annualTmc | percent(3) }}
                </dd>
              </dl>
            </div>
          </div>
        </div>
        <div class="col-7">
          <div class="card">
            <div class="card-body">

              <b-form-group
                id="input-group"
                label-class="font-bold"
                label-cols-sm="12"
                label-cols-md="4"
                content-cols-sm="12"
                content-cols-md="8"
                label="Costos asociados"
                label-for="input-1"
              >
                <b-input-group>
                  <b-form-select
                    v-model="selectedExtraCostName"
                    :options="this.extraCostsList"
                    value-field="name"
                    text-field="human_name"
                    placeholder="Tipo"
                  />
                  <b-input-group-append>
                    <b-input v-model="selectedExtraCostAmount" placeholder="Ingrese el costo"/>
                    <b-button block @click="addExtraCost" variant="primary">Agregar</b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
              <div class="row">
                <div class="col-12 mt-3">
                  <ul class="extra-costs-list">
                    <li v-for="item in selectedExtraCosts.filter((ec) => ec._destroy !== 1)" :key="item.name">
                      {{ item.human_name }}: {{ item.amount | currency('$', 0, { thousandsSeparator: '.', decimalSeparator: ',' }) }}
                      <b-button
                        @click="removeExtraCost(item.name)"
                        variant="link"
                        size="sm"
                        title="Eliminar"
                        :disabled="!canManageExtraCosts"
                        v-if="canManageExtraCosts"
                      >
                        <i class="delete fas fa-times"></i>
                      </b-button>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="row font-bold py-2 px-3">
                <b-form-checkbox
                  class="col"
                  v-model="lifeInsurance"
                  id="checkbox-1"
                >
                  Seguro de Vida
                </b-form-checkbox>
                <!-- <b-form-checkbox
                  class="col"
                  v-model="bikeInsurance"
                  id="checkbox-2"
                  v-show="hasSelectedBikeProductType"
                >
                  Seguro de Moto
                </b-form-checkbox> -->
                <b-form-checkbox
                  v-if="!isLeasing"
                  class="col"
                  v-model="lienInsurance"
                  :disabled="!canManageExtraCosts"
                  id="checkbox-3"
                >
                  Seguro de Desgravamen
                </b-form-checkbox>
                <b-form-checkbox
                  v-if="!isLeasing"
                  class="col"
                  v-model="stampDuty"
                  :disabled="!canManageExtraCosts"
                  id="checkbox-4"
                >
                  Impuesto de timbres y estampillas
                </b-form-checkbox>

                <!-- <unemployment-insurance-form
                  :unemployment-insurance-p="planUf6Insurance || planUf3Insurance"
                  :plan-uf3="planUf3Insurance"
                  :plan-uf6="planUf6Insurance"
                  @unemploymentInsuranceUpdated="unemploymentInsuranceUpdated"
                ></unemployment-insurance-form> -->

              </div>
              <b-form-group
                id="input-group"
                label-class="font-bold"
                label-cols-sm="12"
                label-cols-md="4"
                content-cols-sm="12"
                content-cols-md="8"
                label="Gastos del Crédito"
                label-for="input-1"
              >
                <div class="mt-2">
                  {{ extraCostsForGrossAmount | currency('$', 0, { thousandsSeparator: '.', decimalSeparator: ',' }) }}
                </div>
              </b-form-group>
              <b-form-group
                id="input-group"
                label-class="font-bold"
                label-cols-sm="12"
                label-cols-md="4"
                content-cols-sm="12"
                content-cols-md="8"
                label="Gastos Inscripción"
                label-for="input-1"
              >
                <div class="mt-2">
                  {{ extraCostsForNetAmount | currency('$', 0, { thousandsSeparator: '.', decimalSeparator: ',' }) }}
                </div>
              </b-form-group>
            </div>
          </div>
          <br />
          <installments-form
            @installmentsUpdated="installmentsUpdated"
            :simulation-id="this.simulationId"
          />
        </div>
      </div>
      <div class="row mb-3">
        <credit-simulator
          :client-id="client.id"
          :current-simulation="currentSimulation"
          :drivers-license="driversLicense"
          :extra-costs-gross-amount="extraCostsForGrossAmount"
          :extra-costs-net-amount="extraCostsForNetAmount"
          :initial-gross-amount="grossAmount"
          :initial-installments="installments"
          :initial-net-amount="netAmount"
          :initial-total-net-amount="totalNetAmount"
          :installments-count="installmentsAmount"
          :lien-insurance-amount="lienInsuranceAmount"
          :pipedrive-id="pipedriveDealId"
          :product-type="selectedProductType"
          :rate-value-error="rateValueError"
          :selected-extra-costs="selectedExtraCosts"
          :sign-date="signDate"
          :simulation-type="simulationType"
          :stamp-duty-amount="stampDutyAmount"
          :tmc="simpleTmc"
          :user-role="userRole"
          :sim-model-color-preference="simModelColorPreferences"
          @currentSimulationUpdated="updateCurrentSimulation"
          @grossAmountUpdated="grossAmountUpdated"
          @hideSaveButton="hideSaveButton"
          @installmentsAmountUpdated="installmentsAmountUpdated"
          @monthsForLienInsuranceUpdated="updateMonthsForLienInsurance"
          @netAmountUpdated="updateNetAmount"
          @productValueUpdated="updateProductValue"
          @rateUpdated="interestRatePerMonthUpdated"
          @signDateUpdated="signDateUpdated"
          @simulationCreated="updateSimulationId"
          @simulationTypeUpdated="simulationTypeUpdated"
          @totalNetAmountUpdated="updateTotalNetAmount"
          @clearProducts="clearProducts"
          @updateProducts="updateProducts"
        />
      </div>
      <b-alert variant="danger" show v-if="!completeMandatoryExtraCosts && isEmptyCurrentSimulation">
        <p>Se deben agregar los gastos extras requeridos para poder guardar:</p>
        <ul>
          <li v-for="item in mandatoryExtraCosts" :key="item.name">
            {{ item.human_name }}
          </li>
        </ul>
      </b-alert>

      <b-alert variant="danger" show v-if="!this.isClientPresent && this.isEmptyCurrentSimulation">
        <p>No se encontró un cliente para esta simulación, busca el enlace correcto en el trato de pipedrive</p>
      </b-alert>
      <b-alert variant="danger" show v-if="hasMinimalConditions && !this.isCalculated">
        <p>Debes volver a calcular la simulación, ya que cambiaste datos.</p>
      </b-alert>
      <commercial-executive-button
        v-if="hasMinimalConditions && this.isCalculated && this.isClientPresent"
        :simulation-id="this.simulationId"
        :client="this.client"
        :pipedrive-person-id="this.pipedrivePersonId"
        :pipedrive-deal-id="this.pipedriveDealId"
      />
    </div>
  </div>
</template>

<script>
import InstallmentsForm from '../simulator/InstallmentsForm.vue'
import CreditSimulator from '../credit/simulator'
import CommercialExecutiveButton from '../simulator/CommercialExecutiveButton.vue'
import ClientInformation from '../simulator/ClientInformation.vue'
import calculatorMixin from '../mixins/calculator'
import clientMixin from '../mixins/client'
import VehicleList from '../simulator/VehicleSearchList.vue'
import VehicleModelsList from '../simulator/VehicleModelsList.vue'
import unemploymentInsuranceForm from './unemploymentInsuranceForm.vue'

export default {
  name: 'SimulatorForm',
  mixins: [calculatorMixin, clientMixin],
  components: {
    CreditSimulator,
    CommercialExecutiveButton,
    ClientInformation,
    InstallmentsForm,
    VehicleList,
    VehicleModelsList,
    unemploymentInsuranceForm
  },
  props: [
    'simulationId',
    'userRole',
    'newProductType',
    'simModelColorPreference'
  ],
  data: function() {
    return {
      simulation: null,
      isCalculated: false,
      selectedProductType: this.newProductType,
      // there should be a better way to handle permissions,
      // like CASL and stuff. But this is enough at the moment.
      manageExtraCostsRoles: [
        'operations',
        'operations_boss',
        'admin'
      ],
      showModal: false,
      simModelColorPreferences: this.simModelColorPreference
    }
  },
  mounted: async function() {
    this.$emit('updateCalculator');
    await this.getExtraCostsList()
    await this.setCurrentSimulation()
    this.extraCostsList = this.extraCostsList.filter((ec) => (ec.name !== 'lien_insurance' && ec.name !== 'taxes'))

    if(this.selectedProductType && _.isNil(this.simulationId)) {
      await this.setProductType(this.selectedProductType)
    }

    this.getUF(this.signDate)
  },
  watch: {
    newProductType: async function(newValue, oldValue) {
      if(!_.isNil(oldValue) || this.isEmptyCurrentSimulation){
        await this.setProductType(newValue, _.isNil(oldValue) && !_.isNil(this.simulationId) )
      } else {
        this.selectedProductType = newValue
      }
    },
  },
  computed: {
    hasSelectedBikeProductType: function () {
      return this.selectedProductType?.credit_type === 'MOTO'
    },
    canManageExtraCosts: function() {
      return this.manageExtraCostsRoles.some(role => this.userRole.includes(role))
    },
    isEmptyCurrentSimulation: function() {
      return _.isNil(this.currentSimulation)
    },
    isClientPresent: function() {
      return !_.isNil(this.client.id) && this.client.id !== 0
    },
    hasMinimalConditions: function(){
      return this.isClientPresent && this.simulationId && this.completeMandatoryExtraCosts
    },
    hasSelectedProductType: function() {
      return !_.isNil(this.selectedProductType)
    },
    showAvailableModelsList: function() {
      return _.includes(['MOTO', 'AUTOMOTRIZ'], this.selectedProductType?.credit_type)
    }
  },
  methods: {
    setProductType: async function(producType, updateExtracostsFlag) {
      this.selectedProductTypeId = producType.id
      this.selectedProductType = producType
      await this.addProductTypeExtraCosts(updateExtracostsFlag)
    },
    hideSaveButton: function(value) {
      this.isCalculated = value
    },
    isLeasing: function() {
      return this.simulationType === 'leasing'
    },
    clearProducts: function() {
      this.$emit('clearProducts')
    },
    updateProducts: function(products) {
      this.$emit('updateProducts', products)
    }
  }
}

</script>
