import axios from 'axios'

export default {
  async forAmount(amount, date) {
    const path = `/tmc/for_amount.json`;

    const config = {
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      },
      params: { amount, date }
    }

    const response = await axios.get(path, config)
    return response
  },
  async get(params) {
    let tmc_params = ''
    if (params['month'] && params['year']) {
      tmc_params = `${params['year']}/${params['month']}`
    } else if (params['year']) {
      tmc_params = `${params['year']}`
    }
    const path = `/tmc/${tmc_params}.json`;

    const config = {
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      }
    }

    const response = await axios.get(path, config)
    return response
  }
}
