import axios from 'axios'
import { config } from '../constants/api'

export default {
  scheduledEvents(filters) {
    const path = '/calendars/events_list.json';

    return axios.post(path, { filters }, config)
      .then((res) => res.data)
      .catch((error) => error.response)
      .then((data) => data || [])
  },
  saveScheduledEvents(calendars) {
    const path = '/calendars/save_calendars.json';

    const data = { calendars }

    return axios.post(path, data, config)
      .then((res) => res.data)
      .catch(() => null)
  }
}
