
<template>
  <div>
    <b-form-group v-if="isTemplateListEmpty">
      <b-button variant="button button-dashed button-sm" class="w-100" type="submit" v-b-modal.modal-form-errand-template >
        <em class="fas fa-plus-circle"></em>
        {{ t('errand_invoice_template.create_template') }}
      </b-button>
    </b-form-group>
    <div v-else>
      <b-button variant="button button-negative button-sm float-right" @click="openCreateErrandInvoiceTemplate" >
        {{ t('errand_invoice_template.create_template') }}
        <em class="fas fa-plus-circle"></em>
      </b-button>

      <table class="table" aria-describedby="errand invoivce templates table">
        <thead>
          <tr>
            <th>{{ t('activerecord.attributes.errand_invoice_template.errand_type_id') }} </th>
            <th>{{ t('activerecord.attributes.errand_invoice_template.name') }} </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <b-spinner v-if="loading" />

          <tr v-for="errandInvoiceTemplate in errandTemplates" :key="errandInvoiceTemplate.id">
            <td>{{ errandInvoiceTemplate.errand_type_name }}</td>
            <td>{{ errandInvoiceTemplate.name }}</td>
            <td>
              <button
                @click="setErrandInvoiceTemplateSelected(errandInvoiceTemplate)"
              >
                <em class="far fa-edit"></em>
              </button>

            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <b-modal
      ref="modal-errand-invoice-template-form"
      id="modal-form-errand-template"
      :hide-footer="true"
      :title="t('errand_invoice_template.create_template')" size="xl"
    >
      <errand-invoice-template-form
        :inventory-type-id="inventoryTypeId"
        :errand-template="errandInvoiceTemplateSelected"
        @hideModal="hideModal"
      ></errand-invoice-template-form>
    </b-modal>
  </div>
</template>

<script>
  import { isEmpty } from 'lodash';
  import i18n from "../mixins/i18n"
  import ErrandInvoiceTemplateForm from './Form.vue'
  import errandInvoiceTemplateAPI from '../../api/errand_invoice_template'

  export default {
    name: 'errand-invoice-template-index',
    mixins: [i18n],
    components: {
      ErrandInvoiceTemplateForm
    },
    props: [
      'inventoryTypeId'
    ],
    data() {
      return {
        errandTemplates: [],
        errandInvoiceTemplateSelected: null,
        loading: true
      }
    },
    mounted: async function() {
      await this.getErrandInvoiceTemplates()
    },
    computed: {
      isTemplateListEmpty() {
        return isEmpty(this.errandTemplates)
      }
    },
    methods: {
      hideModal: async function(needUpdateList) {
        this.$refs['modal-errand-invoice-template-form'].hide()

        if(needUpdateList)
          await this.getErrandInvoiceTemplates()
      },
      getErrandInvoiceTemplates: async function(){
          this.loading = true
          this.errandTemplates = await errandInvoiceTemplateAPI.list(this.inventoryTypeId)
          this.loading = false
      },
      setErrandInvoiceTemplateSelected: async function(errandInvoiceTemplate) {
        this.errandInvoiceTemplateSelected = errandInvoiceTemplate
        this.$refs['modal-errand-invoice-template-form'].show()
      },
      openCreateErrandInvoiceTemplate: async function() {
        this.errandInvoiceTemplateSelected = null
        this.$refs['modal-errand-invoice-template-form'].show()
      }
    }

  }
</script>
