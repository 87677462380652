<template>
  <b-table
    id="errands_table"
    responsive
    :items="items"
    :fields="fields"
    :per-page="0"
    :current-page="currentPage"
    ref="selectableTable"
    :select-mode="selectErrandMode"
    :selectable="fields[0] === 'selected'"
    @row-selected="onRowErrandSelected">
    <template #head(selected)>
      <b-form-checkbox
        class="select_column"
        ref="select_column"
        v-model="allSelected"
        :indeterminate="indeterminateCheck"
        @change="toggleAll" />
    </template>
    <template #head(signature_and_operation_number)>
      Nº Op
    </template>
    <template #head(rut_and_name)>
      Cliente
    </template>
    <template #head(credit_type)>
      Tipo de crédito
    </template>
    <template #head(base_status)>
      Estado base
    </template>
    <template #head(errand_type)>
      Tipo de trámites
    </template>
    <template #head(sub_status)>
      Sub estado
    </template>
    <template #head(operational_check)>
      Visado
    </template>
    <template #head(date)>
      Fecha Inicio/Termino
    </template>
    <template #head(responsible)>
      Responsable
    </template>
    <template #head(actions)>
      {{ t(`views.actions`) }}
    </template>

    <template #cell(selected)="{ rowSelected }">
      <template v-if="rowSelected">
        <b-form-checkbox class="select_column" ref="select_column" checked="true" disabled/>
        <span class="sr-only">Selected</span>
      </template>
      <template v-else>
        <div ref="select_column"></div>
        <b-form-checkbox class="select_column" ref="select_column" disabled/>
        <span class="sr-only">Not selected</span>
      </template>
    </template>
    <template #cell(signature_and_operation_number)="data">
      <a :href="`./operations/${data.value.id}`">
        <span class="highlight_title">{{ data.value.operation_number }}</span>
        <span class="highlight_sub_title font-weight-normal">{{ defSignature(data.value.signature).toUpperCase() }}</span>
      </a>
    </template>
    <template #cell(rut_and_name)="data">
      <span class="two_line_info">{{ data.value.rut }}<br> {{ data.value.name }}</span>
    </template>
    <template #cell(credit_type)="data">
      {{ data.value.toUpperCase() }}
    </template>
    <template #cell(base_status)="data">
      <div class="flex align-items-center color-gray icon-table" v-html="iconCondition(data.value)"></div>
    </template>
    <template #cell(errand_type)="data">
      <button class="width-500 button-outback a-link flex flex-column width-moz-available" :item-id="data.value.id_prop" @click="openInfoModal">
        <p class="flex justify-content-between align-items-center">
          {{ data.value.name }}
          <i class="far fa-edit"></i>
        </p>
        <b-badge v-if="data.item.has_docs" pill variant="dark"> <em class="fas fa-solid fa-paperclip mlp-6" ></em> Docs</b-badge>
      </button>
    </template>
    <template #cell(sub_status)="data">
      {{ data.value.name }}
    </template>
    <template #cell(date)="data">
      {{ data.value.start_date }} - {{ data.value.end_date !== '' ? data.value.end_date : 'Sin finalizar' }}
    </template>
    <template #cell(responsible)="data">
      {{ data.value.name }}
    </template>
     <template #cell(actions)="data">
      <button class="button-outback btn btn-outline-danger btn-sm" @click="openDeleteModal(data.item)">
        <i class="fas fa-trash"></i>
      </button>
    </template>
  </b-table>
</template>
<script>

import i18n from "../mixins/i18n"
import errands from "../../api/errands";
import errandsMixin from '../mixins/errandStatusLabel'

export default {
  name: "table-errands",
  mixins: [errandsMixin, i18n],
  props: {
    items: Array,
    itemSelected: Object,
    fields: Array,
    perPage: Number,
    currentPage: Number,
    onSelectCheck: Function,
    onClickErrandType: Function,
  },
  data() {
    return {
      selectErrandMode: 'multi',
      errandsSelected: [],
      allSelected: false,
      indeterminateCheck: false,
    }
  },
  methods: {
    defSignature(signature) {
      if(signature)
        return 'Firma Digital'
      return 'Firma Física'
    },
    onRowErrandSelected(items) {
      this.errandsSelected = items
      if (this.errandsSelected.length === 0) {
        this.indeterminateCheck = false
        this.allSelected = false
      } else if (this.errandsSelected.length === this.perPage || this.errandsSelected.length === this.items.length ) {
        this.indeterminateCheck = false
        this.allSelected = true
      } else {
        this.indeterminateCheck = true
        this.allSelected = false
      }
      this.checkTableCondition()
    },
    toggleAll(checked) {
      checked ? this.$refs.selectableTable.selectAllRows() : this.$refs.selectableTable.clearSelected()
      this.checkTableCondition()
    },
    viewCheckInput() {
      $('td').children('.select_column').children('.custom-control-label').attr('for', '')
    },
    resetCheck() {
      this.errandsSelected = []
      this.indeterminateCheck = false
      this.allSelected = false
      this.$emit('onSelectCheck', {baseStatus: false, subStatus: false})
    },
    checkTableCondition() {
      let baseStatus = false
      let subStatus = false
      if(this.errandsSelected.length > 0) {
        if(this.errandsSelected.length === 1) {
          this.$emit('onSelectCheck', {baseStatus: true, subStatus: true})
          return
        }
        let noOpCondition = (errand) => errand.signature_and_operation_number.operation_number === this.errandsSelected[0].signature_and_operation_number.operation_number;
        let errandTypeCondition = (errand) => errand.errand_type.id === this.errandsSelected[0].errand_type.id;
        let listNoOp = this.errandsSelected.map(errand => errand.signature_and_operation_number.operation_number)
        let unique = listNoOp.filter((item, index, array) => array.indexOf(item) === index)
        baseStatus = this.errandsSelected.every(noOpCondition)
        subStatus = listNoOp.length === unique.length && this.errandsSelected.every(errandTypeCondition)
      }
      this.$emit('onSelectCheck', {baseStatus: baseStatus, subStatus: subStatus})
    },
    getItemsSelected() {
      return this.errandsSelected
    },
    unselectItem(idItem) {
      this.$refs.selectableTable.unselectRow(parseInt(idItem))
    },
    openInfoModal: async function(event) {
      let itemId = event.currentTarget.getAttribute('item-id')
      const url = `${window.location.href}/${this.items[itemId].id}/edit`
      window.open(url, '_blank').focus();
    },
    openDeleteModal: async function(errand) {
      this.$emit('deleteErrand', errand)
    }
  },
}
</script>
