import axios from 'axios'
import { config } from '../constants/api'

const buildConfig = (extraconfig) => {
  return { ...config, ...extraconfig }
}

export default {
  async getList() {
    const path = `/errand_types/list.json`;

    return await axios.get(path, config)
      .then((res) => res.data)
      .catch((error) => error.message)
  },

  async errandsAvailable(errandable_id, errandable_type, errandable_instance_id, errandable_instance_type) {
    const path = `/errand_types/errands_available.json`;
    const data = {
      errandable_id: errandable_id,
      errandable_type: errandable_type,
      errandable_instance_type: errandable_instance_type,
      errandable_instance_id: errandable_instance_id
    };

    return await axios.post(path, data, config)
      .then((res) => res.data)
      .catch((error) => error.message)
  },
}
