<template>

  <div>
    <b-form-group
      :label="name"
      label-class="text-capitalize"
      :aria-describedby="`${helpId} ${feedbackId}`"
      :state="state"
    >

      <b-form-select
        :disabled="readonly"
        :id="_uid + name + id"
        v-model="value"
        :state="state"
        :options="options"
        :aria-describedby="`${helpId} ${feedbackId}`"
      ></b-form-select>

      <b-form-invalid-feedback :id="feedbackId">
        Es requerido
      </b-form-invalid-feedback>

    <div class="d-block" >
      <span class="text-danger" v-if="error" >{{ error }}</span>
    </div>

    <div class="d-block" >
      <b-form-text :id="helpId">{{ description }}</b-form-text>
    </div>

    </b-form-group>
  </div>
</template>

<script>
  import fieldsMixin from '../../mixins/fields';

  export default {
    name: 'SelectField',
    mixins: [fieldsMixin],
    mounted() {}
  }
</script>
