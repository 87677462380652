<template>
  <b-dropdown class="dropdown-form select-icon" ref="dropdown" variant="link"
    toggle-class="text-decoration-none second-button dropdown-custom dropdown-icon-custom">
    <template #button-content>
      <div class="flex"><div id="icon-selected" :key="iconKey"></div><span class="mip-5">{{ iconName }}</span></div>
    </template>
    <b-dropdown-form @submit.stop.prevent style="width: 100% !important;">
      <searcher
        :button="false"
        v-on:inputSearch="searchFilter"
        inputWidth="100%"
        v-bind:style="{ marginBottom: '12px' }"
        :input-placeholder="`${t(`views.search`)}`"/>

      <div class="flex justify-content-center">
        <div class="icon-list-container">
          <button v-for="icon in filteredIcons" :key="icon.class"
            class="back-icon btn-icon icon-list"
            :name="icon.class"
            :value="icon.code"
            v-on:click="selectIcon($event)">
            <i v-bind:class="icon.code"></i>
          </button>
        </div>
      </div>

      <div class="flex-space-between mtp-6">
        <button class="undo-button m-0" @click="page--" :disabled="page === 1"><i class="fas fa-angle-left"></i></button>
        <button class="undo-button m-0" @click="page++" :disabled="page > (iconsList.length/10)"><i class="fas fa-angle-right"></i></button>
      </div>
    </b-dropdown-form>
  </b-dropdown>
</template>
<script>
import i18n from "../mixins/i18n"
import Searcher from './Searcher.vue'
import iconsList from '../mixins/icons'

export default {
  components: { Searcher },
  name: 'icons-list',
  mixins: [iconsList, i18n],
  props: {
    onChange: Function,
    iconSelected: {
      type: String,
      default: undefined
    }
  },
  data() {
    return {
      iconName: '',
      iconKey: 1,
      page: 1,
      iconsList: []
    }
  },
  methods: {
    selectIcon(event) {
      let iconClass;
      if(event.target.tagName == 'svg'){
        iconClass = event.target.parentElement.getAttribute('value')
        this.iconName = this.t(`icon.${event.target.parentElement.getAttribute('name')}`)
      } else if(event.target.tagName == 'path') {
        iconClass = event.target.parentElement.parentElement.getAttribute('value')
        this.iconName = this.t(`icon.${event.target.parentElement.parentElement.getAttribute('name')}`)
      } else if(event.target.tagName == 'BUTTON') {
        iconClass = event.target.getAttribute('value')
        this.iconName = this.t(`icon.${event.target.getAttribute('name')}`)
      }
      this.renderIcon(iconClass, this.iconKey, 'icon-selected')
      this.$emit('onChange', {icon: iconClass})
    },
    searchFilter(value) {
      this.page = 1
      if(value == '')
        return this.iconsList = this.icons

      this.iconsList = this.icons?.filter(icon =>
        icon.translate.toLowerCase().includes(value.toLowerCase()) || icon.class.toLowerCase().includes(value.toLowerCase())
      )
    },
  },
  mounted() {
    $('#icon-dropdown').children('.dropdown-toggle').removeClass('btn btn-link dropdown-toggle')
    this.iconsList = this.icons
  },
  computed: {
    filteredIcons: function () {
      return this.iconsList?.slice((this.page*10)-10, (this.page*10)-1)
    }
  },
  watch: {
    iconSelected() {
      if(this?.iconSelected) {
        this.renderIcon(this.iconSelected, this.iconKey, 'icon-selected')
        let icon = this.iconsList.find(icon => icon.code == this.iconSelected)
        this.iconName = this.t(`icon.${icon.class}`)
      }
    }
  }
}
</script>
<style>
.back-icon {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  width: 44px;
  height: 44px;
  padding: 12px;
  background-color: rgb(185, 185, 185);
  border-radius: 50px;
  font-size: 16px;
}

.back-icon.icon-list {
  margin: 2px;
}

.btn-icon:hover {
  opacity: 0.75;
}

.icon-list-container {
  display: flex;
  flex-wrap: wrap;
  width: 145px;
  justify-content: center;
}

.dropdown-icon-custom {
  border: 1px solid #D8D8D8 !important;
  box-sizing: border-box !important;
  border-radius: 6px !important;
  padding: 11px 14px 11px 16px !important;
  height: 40px;
  color: #484848 !important;
  font-size: 14px !important;
  min-width: 170px;
}

.select-icon .dropdown-menu {
  width: min-content !important;
}
</style>
