<template>
  <div>
    <kayak-incomes-information
        :kayak-payload="JSON.parse(kayakPayload)"
        :client-incomes="JSON.parse(clientIncomes)"
        :client-incomes-average-holder="clientIncomesAverage"
        :client-incomes-average-guarantor="0"
        :read-only="false"
        :has-guarantor="false"/>
    <br>
    <kayak-labor-information
      :labor-information="JSON.parse(laborInformation)"
      :affiliate-records="JSON.parse(affiliateRecords)"/>
    <br>
    <kayak-information-cmf :debts-information="debtsInformation"/>
    <br>
    <kayak-personal-information :personal-information="personalInformation" :client-medical-leave="JSON.parse(clientMedicalLeave)"/>
  </div>
</template>
<script>
import KayakLaborInformation from './KayakLaborInformation.vue'
import KayakInformationCmf from "./KayakInformationCmf.vue"
import KayakIncomesInformation from "./KayakIncomesInformation.vue"
import KayakPersonalInformation from "./KayakPersonalInformation.vue"

export default {
  name: "kayak-information",
  components: {
    KayakIncomesInformation,
    KayakInformationCmf,
    KayakPersonalInformation,
    KayakLaborInformation
  },
  props: [
    'debtsInformation',
    'personalInformation',
    'laborInformation',
    'clientIncomes',
    'clientIncomesAverage',
    'kayakPayload',
    'affiliateRecords',
    'clientMedicalLeave'
  ]
}
</script>
