<template>
  <b-sidebar id="sidebar-filter" title="Todos los filtros" v-model="view_sidebar" backdrop shadow>
    <loader ref="loader"/>
    <div class="sidebar-body">
      <p class="title t4 color-gray mbp-12 mtp-12">
        {{ t(`activerecord.models.product_type`) }}
      </p>
      <dropdown-filter
        ref="product_type_filter"
        :items="filter.product_type.list"
        text="Tipo de financiamiento"
        :if-cancel="false"
        v-on:onFilter="stepEvent('product_type', 'errand_type')"
        input-width="100%"
      />

      <p class="title t4 color-gray mtp-34">
        {{ t(`filter.sort_by_date_visa`) }}
      </p>
      <div class="form-group flex justify-content-between">
        <div class="width-moz-available">
          <span class="normal-text color-gray">{{ t(`views.date_start`) }}</span>
          <b-form-datepicker class="mtp-12"
            v-model="init_date"
            :min="dateLimit.startDateMin"
            :max="end_date !== '' ? end_date : dateLimit.endDateMax"
            placeholder="dd/mm/aaaa"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            locale="es">
          </b-form-datepicker>
        </div>
        <div style="margin: 11px"></div>
        <div class="width-moz-available">
          <span class="normal-text color-gray">{{ t(`views.date_end`) }}</span>
          <b-form-datepicker class="mtp-12"
            v-model="end_date"
            :min="init_date !== '' ? init_date : dateLimit.startDateMin"
            :max="dateLimit.endDateMax"
            placeholder="dd/mm/aaaa"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            locale="es">
          </b-form-datepicker>
        </div>
      </div>
      <button class="base-button search-button mbp-34" type="button" @click="changeDate">{{ t(`views.establish`) }}</button>

      <p class="title t4 color-gray mbp-12 mtp-34" v-bind:class="!filter.errand_type.step ? 'disabled' : ''">
        {{ t(`activerecord.models.errand_type`) }}
      </p>
      <dropdown-filter
        ref="errand_type_filter"
        :items="filter.errand_type.list"
        text="Tipo de trámite"
        :if-cancel="false"
        :is-disabled="!filter.errand_type.step"
        v-on:onFilter="stepEvent('errand_type', 'sub_status')"
        input-width="100%"/>

      <p class="title t4 color-gray mbp-12 mtp-34" v-bind:class="!filter.sub_status.step ? 'disabled' : ''">
        {{ t(`activerecord.models.sub_status_type`) }}
      </p>
      <dropdown-filter
        ref="sub_status_filter"
        :items="filter.sub_status.list"
        text="Sub estado"
        :if-cancel="false"
        :is-disabled="!filter.sub_status.step"
        v-on:onFilter="stepEvent('sub_status')"
        input-width="100%"/>

      <p class="title t4 color-gray mtp-34">{{ t(`filter.sort_by_time_range`) }}</p>
      <div class="form-group flex justify-content-between">
        <div class="width-moz-available">
          <span class="normal-text color-gray">{{ t(`views.date_start`)}}</span>
          <b-form-datepicker class="mtp-12"
            v-model="sort_by_time_init_date"
            :max="sort_by_time_end_date !== '' ? sort_by_time_end_date : dateLimit.endDateMax"
            placeholder="dd/mm/aaaa"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            chan
            locale="es">
          </b-form-datepicker>
        </div>
        <div style="margin: 11px"></div>
        <div class="width-moz-available">
          <span class="normal-text color-gray">{{ t(`views.date_end`) }}</span>
          <b-form-datepicker
            class="mtp-12"
            v-model="sort_by_time_end_date"
            :min="
              sort_by_time_init_date !== ''
                ? sort_by_time_init_date
                : dateLimit.startDateMin
            "
            :max="dateLimit.endDateMax"
            placeholder="dd/mm/aaaa"
            :date-format-options="{
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
            }"
            ref="sub_status_time_filter"
            locale="es"
          >
          </b-form-datepicker>
        </div>
      </div>
    </div>
    <template #footer="{}">
      <div class="flex justify-content-between" style="padding: 13px 22px">
        <button class="base-button outline mrp-24" @click="cleanFilter" :disabled="applicableFilter">{{ t(`views.clear_filter`) }}</button>
      <button class="base-button" :disabled="applicableFilter" @click="applyFilters">{{ t(`filter.apply_filter`) }}</button>
      </div>
    </template>
  </b-sidebar>
</template>
<script>
import i18n from '../mixins/i18n'
import capitalize from '../mixins/capitalize'
import errands from '../../api/errands'
import product_types from '../../api/product_types'
import Loader from '../template/Loader.vue'
import DropdownFilter from '../dynamics_form/DropdownFilter.vue'
import SelectOptions from '../dynamics_form/SelectOptions.vue'

export default {
  components: { Loader, DropdownFilter, SelectOptions },
  name: 'sidebar-filter-errands',
  mixins: [i18n, capitalize],
  props: {
    onApplyFilters: Function
  },
  data() {
    return {
      view_sidebar: false,
      init_date: null,
      end_date: null,
      sort_by_time_init_date: null,
      sort_by_time_end_date: null,
      is_applied: false,
      filter: {
        product_type: {
          list: [],
          selected: [],
          step: false
        },
        errand_type: {
          list: [],
          selected: []
        },
        sub_status: {
          list: [],
          selected: [],
          step: false
        }
      },
      dateLimit: {
        startDateMin: this.setInitDateMin(),
        endDateMax: this.setEndDateMax()
      },
      setDate: false,
      setProductType: false
    }
  },
  watch: {
    sort_by_time_init_date: function(newValue, oldValue) {
      this.status_time_changed()
    },
    sort_by_time_end_date: function(newValue, oldValue) {
      this.status_time_changed()
    }
  },
  methods: {
    status_time_changed() {
      if (this.sort_by_time_init_date && this.sort_by_time_end_date)
        this.stepEvent('sub_status_time')
    },
    getLists: async function() {
      this.filter.product_type.list = await product_types.getList()
    },
    applyFilters() {
      for (const property in this.filter) {
        this.filter[property].tmp_list = this.filter[property].list
        this.filter[property].tmp_selected = this.filter[property].selected.map(item => {
          if (typeof item === 'object')
            return Object.assign({}, item)
          return item
        })
        this.filter[property].tmp_selected_all = this.$refs[`${property}_filter`].getItemsSelectedAll()
        this.filter[property].tmp_step = this.filter[property].step
      }
      this.view_sidebar = false
      this.is_applied = true
      this.$emit('onApplyFilters', { filterSidebar: this.setListFilter() })
    },
    cleanFilter() {
      this.clearSteps('', true)
      this.is_applied = false
      this.init_date = null
      this.end_date = null
      this.setDate = false
      this.sort_by_time_init_date = null
      this.sort_by_time_end_date = null
      this.getLists()
      this.$emit('onApplyFilters', { filterSidebar: {}, clean: true })
    },
    stepEvent: async function(step, nextStep) {
      this.$refs.loader.isVisible()
      if (this.$refs[`${step}_filter`] && step !== 'sub_status_time')
        this.filter[step].selected = this.$refs[`${step}_filter`]?.getItemsSelected()

      if (nextStep != undefined) {
        this.filter[nextStep].selected = []
        const isValidStep = step === 'sub_status_time' || this.filter[step]?.selected?.length > 0
        if (isValidStep) {
          this.clearSteps(nextStep)
          this.filter[nextStep].list = await errands.errandsFilters(this.setListFilter())
          this.$refs[`${nextStep}_filter`].setItemsCheck([])
        }else{
          this.clearSteps(step)
        }
      }
      this.$refs.loader.notVisible()
    },
    sideBarVisible() {
      if (this.is_applied) {
        for (const property in this.filter) {
          this.filter[property].list = this.filter[property].tmp_list
          this.filter[property].selected = this.filter[property].tmp_selected
          this.filter[property].step = this.filter[property].tmp_step
          this.$refs[`${property}_filter`].setItemsCheck(
            this.filter[property].tmp_selected_all
          )
        }
      }
    },
    clearSteps(step, clear = false) {
      const propertiesNamesList = Object.keys(this.filter)
      for (const property of propertiesNamesList) {
        if (clear) {
          this.filter[property].step = false
          this.$refs[`${property}_filter`].setItemsCheck([])
          const propertyListMustBeCleaned = !['errand_type', 'product_type'].includes(property)
          if (propertyListMustBeCleaned) this.filter[property].list = []
        }
        if (property === step) {
          this.filter[property].step = true
          clear = true
        }
      }
    },
    setListFilter() {
      let newFilter = {}
      if(this.init_date || this.end_date)
        newFilter.operational_check_at = [this.init_date ?? this.dateLimit.startDateMin, this.end_date ?? this.dateLimit.endDateMax]
      if(this.sort_by_time_init_date || this.sort_by_time_end_date)
        newFilter.sub_status_time = [this.sort_by_time_init_date ?? this.dateLimit.startDateMin, this.sort_by_time_end_date ?? this.dateLimit.endDateMax]
      for (const property in this.filter) {
        const hasFilterValues = this.filter[property]?.selected?.length > 0
        const filterValues = hasFilterValues && this.filter[property].selected
        if (filterValues) newFilter[property] = this.filter[property].selected
        if(property === 'product_type') this.setProductType = hasFilterValues
      }
      return newFilter
    },
    setInitDateMin() {
      const now = new Date(2018, 0, 1)
      return now.toISOString().split('T')[0]
    },
    setEndDateMax() {
      const now = new Date()
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
      return today.toISOString().split('T')[0]
    },
    changeDate: async function() {
      if (this.init_date || this.end_date) {
        this.setDate = true
        this.filter['errand_type'].selected = []
        this.$refs['errand_type_filter'].setItemsCheck([])
        this.clearSteps('errand_type')
        this.filter['errand_type'].list = await errands.errandsFilters(
          this.setListFilter()
        )
      }
    }
  },
  mounted() {
    this.getLists()
  },
  computed: {
    applicableFilter() {
      return !(this.setDate || this.filter.sub_status.step || this.setProductType)
    }
  }
}
</script>
<style>
@import '../../../assets/stylesheets/sidebar.scss';
.form-group {
  margin-top: 18px !important;
}

p.disabled {
  opacity: 0.65;
}

#sidebar-filter {
  max-width: 400px !important;
  width: max-content !important;
}

.b-form-datepicker > .form-control {
  font-size: 14px !important;
}

.b-form-datepicker > button {
  font-size: 12px !important;
}
</style>
