import moment from 'moment'
import i18n from "./i18n"
import capitalize from "./capitalize"
import { TYPES_DATE_FORMATS } from '../../constants/date'

export default {
  mixins: [i18n, capitalize],
  methods: {
    daysAgo(date) {
      const previousDate = moment(this.formatDate(date, TYPES_DATE_FORMATS.YYYY_MM_DD));
      const today = moment(this.formatDate(new Date(), TYPES_DATE_FORMATS.YYYY_MM_DD));

      return today.diff(previousDate, 'days');
    },
    formatDate(date, format) {
      switch (format) {
        case TYPES_DATE_FORMATS.YYYY_MM_DD:
          return this.YYYYMMDD(date)
        case TYPES_DATE_FORMATS.DD_MM_HH_MIN:
          return this.DDMMHHminHr(date)
        default:
          break;
      }
    },
    // YYYY-MM-DD Format
    YYYYMMDD(date) {
      return date.toLocaleDateString('en-CA')
    },
    // DD MM, HH.minHr
    DDMMHHminHr(date) {
      const month = this.capitalize(this.t('date.month_names_js')[date.getMonth()])
      const hour = date.toLocaleString("en-US", {hour: '2-digit', minute: '2-digit', hour12: false})
      return `${date.getDate()} ${month}, ${hour}Hr`
    },
    // DD de Month
    DDMonth(date) {
      return `${date.getUTCDate()} de
        ${this.capitalize(this.t('date.month_names_js')[date.getMonth()])}`
    }
  }
}
