<template>
  <div>
    <h3>Comentarios</h3>
    <b-form-textarea
      id="textarea"
      v-model="currentComment"
      placeholder="Ingresa un comentario..."
      rows="3"
      max-rows="6"
    >
    </b-form-textarea>
    <b-button
      variant="primary"
      @click="saveComment"
    >
      Agregar comentario
    </b-button>
    <div class="col-span-8 mt-3">
      <ul class="comments" v-if="comments.length > 0">
        <li
          v-for="comment in orderedComments"
          :key="comment.id"
          class="comment my-3 pt-3 border-top"
        >
          <div
            class="content"
            v-html="$options.filters.formatComment(comment.content)" />
          <br>
          <span class="author text-xs text-lt text-gray-600">
            {{ `${comment.account.first_name} ${comment.account.last_name}` }}
          </span>
          <span class="timestamp text-xs text-lt text-gray-600">
            {{ comment.created_at | moment('DD-MM-YYYY HH:mm') }}
          </span>
        </li>
      </ul>
      <p v-else>Sin comentarios aún.</p>
    </div>
  </div>
</template>

<script>
  import sortBy from 'lodash/sortBy'
  import simulationsApi from '../../api/simulations'

  export default {
    name: 'simulation-comments-card',
    props: [
      'simulation'
    ],
    data: function() {
      return {
        comments: this.simulation.comments || [],
        currentComment: ''
      }
    },
    filters: {
      formatComment: function(comment) {
        return comment.replace(/(\r\n|\n|\r)/gm, '<br>')
      },
    },
    methods: {
      saveComment: async function() {
        const response = await simulationsApi.addComment(this.simulation.id, this.currentComment)
        if (response.content) {
          this.comments = [
            ...this.comments,
            response
          ]
          this.currentComment = ''
        } else {
          // TODO: show error
        }
      },
      isLongComment: function(comment) {
        return comment.length > Number(this.commentLength)
      },
    },
    computed: {
      orderedComments: function() {
        return sortBy(this.comments, ['created_at']).reverse()
      }
    },
  }
</script>

<style lang="scss" scoped>
.comments {
  word-wrap: break-word;
  overflow: auto;
  max-height: 300px;
}
.content {
  display: inline-block;
}
</style>
