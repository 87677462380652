import axios from 'axios'
import { config } from '../constants/api'

export default {
  async getLinks() {
    const path = `/accounts/fetch_referral_links.json`;

    return axios.get(path, config)
      .then((res) => res.data)
      .catch((error) => {
        return {
          error: {
            message: error.message,
            status: error.response.status
          }
        }
      })
  },
}
