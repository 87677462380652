<template>
  <b-modal id="modal-calendar-info" :hide-footer="!ifEdit" size="lg">
    <template #modal-title>
      <div class="title t1">
        <span v-if="massiveEdit">{{ t('calendar.mass_editing_responsible') }}</span>
        <span v-else-if="itemSelected.operation">'{{ t('views.contract') }}' - {{ t('views.number_of_operation') }} {{ itemSelected.operation.id }}</span>
        <span v-else-if="itemSelected.simulationId">
          '{{ t('views.contract') }}' - {{ t('activerecord.models.simulation') }} {{ itemSelected.simulationId }}
        </span>
      </div>
    </template>

    <section v-if="massiveEdit">
      <small class="neutral-50 block mb-2 mt-4">
        {{ t('calendar.select_responsible_meetings_text', {'meetings_number': calendarItems.length}) }}
      </small>
      <dropdown-filter
        ref="dropdown_responsible"
        :items="responsiblesList"
        :list-check="selectedResponsibleId"
        :radio-inputs="true"
        @onFilter="responsibleSelected"
        :text="t(`calendar.responsible_meeting`)"/>
      <b-table class="mt-4"
        id="calendar_edit_table"
        responsive
        :items="calendarItems"
        :fields="fields">
        <template #head(operation)>{{ t('views.number_of_operation') }}</template>
        <template #head(signature)>{{ t('views.signature') }}</template>
        <template #head(responsible)>{{ t('calendar.current_responsible') }}</template>
        <template #head(newResponsible)>{{ t('calendar.new_responsible') }}</template>
        <template #head(action)>
          <div class="flex-center">{{ t('views.actions') }}</div>
        </template>

        <template #cell(operation)="data">
          <span v-if="data.value">{{ data.value.id }}</span>
          <span v-else-if="data.item.simulationId">{{ data.item.simulationId }}</span>
        </template>
        <template #cell(signature)="data">
          <span v-if="data.item.operation">{{ data.item.operation.signatureType }}</span>
        </template>
        <template #cell(responsible)="data">{{ data.value.name }}</template>
        <template #cell(newResponsible)>{{ isResponsibleSelected }}</template>
      </b-table>
    </section>
    <section v-else>
      <section class="container-modal mb-4 m-inline info-15">
        <h6 class="title"> {{ t('activerecord.attributes.operation.operation_sumary') }}</h6>
        <div v-if="itemSelected" class="flex-space-between responsive mtp-12">
          <div class="mrp-12">
            <small class="neutral-50">{{ t('activerecord.attributes.client.client_name') }}</small>
            <p v-if="itemSelected.client">{{ itemSelected.client.name }}</p>
          </div>
          <div class="mrp-12">
            <small class="neutral-50">{{ t('activerecord.attributes.client.rut') }}</small>
            <p v-if="itemSelected.client">{{ itemSelected.client.rut }}</p>
          </div>
          <div class="mrp-12">
            <small class="neutral-50">{{ t('activerecord.attributes.product_type.type') }}</small>
            <p>{{ itemSelected.product }}</p>
          </div>
          <div class="mrp-12 last">
            <small class="neutral-50">{{ t('views.meeting') }}</small>
            <p>{{ itemSelected.appointmentMeeting }}</p>
          </div>
        </div>
        <div class="horizontal-line my-3"></div>
        <small class="neutral-50 block mb-2">{{ t('views.responsible') }}</small>
        <dropdown-filter
          ref="dropdown_responsible"
          :items="responsiblesList"
          :list-check="selectedResponsibleId"
          :radio-inputs="true"
          @onFilter="responsibleSelected"
          :edit-permissions="ifEdit"
          :text="t(`calendar.responsible_meeting`)"/>
      </section>
      <section v-if="itemSelected">
        <h4 class="mb-2">{{ t('errand.add_comment') }}</h4>
        <b-form-textarea
          v-model="itemSelected.comment"
          :placeholder="`${t('errand.add_comment')}...`"
          rows="3"
          max-rows="6"
          :disabled="!ifEdit"
        ></b-form-textarea>
      </section>
    </section>

    <template #modal-footer>
      <button class="button button-secondary mrp-24" @click="$bvModal.hide('modal-calendar-info')">{{ t('views.cancel') }}</button>
      <button class="button button-primary" @click="onHandleSave" :disabled="isSelected">{{ t('views.save') }}</button>
    </template>
  </b-modal>
</template>
<script>
import DropdownFilter from '../../../dynamics_form/DropdownFilter.vue'
import i18n from '../../../mixins/i18n'

export default {
  name: 'modal-info',
  mixins: [i18n],
  components: { DropdownFilter },
  props: {
    calendarItems: Array,
    responsiblesList: Array,
    massiveEdit: false,
    ifEdit: false,
    onSave: {
      type: Function,
      default: () => {}
    },
  },
  data() {
    return {
      selectedResponsibleName: '',
      selectedResponsibleId: [],
      itemSelected: null,
      newResponsible: {},
      fields: ['operation', 'signature', 'responsible', 'newResponsible'],
    }
  },
  methods: {
    onHandleSave() {
      const idCalendars = []
      const listToUpdate = this.calendarItems.map(item => {
        idCalendars.push(item.uuidEvent)

        return {
          uuid_event: item.uuidEvent,
          responsible_id: this.selectedResponsibleId[0],
          comment: item.comment,
        }
      })

      this.$emit('onSave', listToUpdate, idCalendars, this.newResponsible)
      this.newResponsible = {};
      this.selectedResponsibleName = '';
      this.selectedResponsibleId = [];
      this.$bvModal.hide('modal-calendar-info')
    },
    responsibleSelected(item) {
      this.newResponsible = this.responsiblesList.find(responsible => responsible?.id === item[0])
      this.selectedResponsibleName = this.newResponsible?.name
      this.selectedResponsibleId = item
    },
    setResponsibleSelected() {
      this.selectedResponsibleId = this.massiveEdit ? [] : [this.itemSelected?.responsible?.id]
      this.newResponsible = this.responsiblesList.find(responsible => responsible?.id === this.selectedResponsibleId[0])
      this.$refs.dropdown_responsible?.setItemsCheck(this.selectedResponsibleId)
    }
  },
  computed: {
    isSelected() {
      return !this.selectedResponsibleId.length > 0
    },
    isResponsibleSelected() {
      return this.selectedResponsibleName
    }
  },
  watch: {
    calendarItems(newValue) {
      this.itemSelected = newValue[0]
      this.setResponsibleSelected()
    }
  },
}
</script>
