<template>
  <div class="card mb-4">
    <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
      <div class="subtitle neutral-70">{{ t(`activerecord.models.operation_errand.other`) }}</div>
    </div>
    <div class="card-body">
      <loader ref="loader" style="position: absolute"/>
      <!-- Mass editing interfaces for the future -->
      <!-- <div class="flex justify-content-end">
        <button class="button-outback" v-bind:class="{ select: fields[0] === 'selected' }" v-b-toggle.collapse-mass>
          {{ t(`extra_options.mass_editing_states`) }} <i class="fas fa-edit" style="margin-left: 9px"></i>
        </button>
      </div> -->
      <b-collapse id="collapse-mass">
        <div class="container-massive-edit relative mtp-20">
          <span class="close-button" v-on:click="closeCollapseMassEdition"><i class="fas fa-times"></i></span>
          <span class="title t4 text-white">{{ t(`extra_options.mass_editing_states`) }}</span>
          <div class="flex mtp-12">
            <button class="base-button" @click="openEditBaseStatus" :disabled="!isEditableBaseStatus">{{ t(`edit_states.change_base_state`) }}</button>
          </div>
        </div>
      </b-collapse>

      <b-table
        id="errands_table"
        responsive
        :items="items"
        :fields="fields"
        ref="selectableErrandsTable"
        :select-mode="selectErrandMode"
        :selectable="fields[0] === 'selected'"
        @row-selected="onRowErrandSelected">
        <template #head(selected)>
          <b-form-checkbox
            class="select_column"
            ref="select_column"
            v-model="allSelected"
            :indeterminate="indeterminateCheck"
            @change="toggleAll" />
        </template>
        <template #head(general_status)>
          {{ t(`activerecord.models.base_status`) }}
        </template>
        <template #head(errand_type)>
          {{ t(`activerecord.models.errands_type`) }}
        </template>
        <template #head(sub_status)>
          {{ t(`activerecord.models.sub_status_type`) }}
        </template>
        <template #head(operational_check)>
          {{ t(`activerecord.models.visa`) }}
        </template>
        <template #head(date)>
          {{ t(`activerecord.models.date_start_end`) }}
        </template>
        <template #head(sla)>
          {{ t(`activerecord.models.service_level_agreement`) }}
        </template>
        <template #head(condition)>
          {{ t(`activerecord.models.condition`) }}
        </template>
        <template #head(responsible)>
          {{ t(`activerecord.attributes.operation_errand.responsible`) }}
        </template>
        <template #head(actions)>
          {{ t(`views.actions`) }}
        </template>

        <template #cell(selected)="{ rowSelected }">
          <template v-if="rowSelected">
            <b-form-checkbox class="select_column" ref="select_column" checked="true" disabled/>
            <span class="sr-only">Selected</span>
          </template>
          <template v-else>
            <div ref="select_column"></div>
            <b-form-checkbox class="select_column" ref="select_column" disabled/>
            <span class="sr-only">Not selected</span>
          </template>
        </template>
        <template #cell(general_status)="data">
          <div class="flex align-items-center color-gray icon-table" v-html="iconCondition(data.value)"></div>
        </template>
        <template #cell(sub_status)="data">
          {{ data.value }}
        </template>
        <template #cell(date)="data">
          {{ data.value.start_date }} - {{ data.value.end_date !== '' ? data.value.end_date : t(`views.unfinished`) }}
        </template>
        <template #cell(sla)="data">
          {{ `${data.value} ${t(`datetime.prompts.days`)}` }}
        </template>
        <template #cell(condition)="data">
          <div class="flex align-items-center color-gray icon-table" v-html="iconCondition(data.value)"></div>
        </template>
        <template #cell(responsible)="data">
          {{ data.value.name }}
        </template>
        <template #cell(actions)="data">
          <button class="button-outback btn btn-outline-danger btn-sm" @click="openDeleteModal(data.item)">
            <i class="fas fa-trash"></i>
          </button>
        </template>

      </b-table>

      <button class="button button-dashed button-sm mtp-25 dashed-button-max" type="button" @click="openModalAddErrand()">
        <i class="fas fa-plus-circle"></i>
        <p class="m-0 font-s14">{{ t(`views.add_errand`) }}</p>
      </button>
    </div>

    <alert
      ref="notification"
      :text="textNotification"
      v-on:onUndoEvent="onUndoEventAlert"
      v-on:onEvent="onEventAlert"
      :is-undo-button="true"/>

    <modal-add-errand
      :errand-type-list="errandTypeList"
      :responsibles-list="responsiblesList"
      :errandable-id="errandableInstanceId"
      :errandable-type="errandableInstanceType"
      :errandable-number="errandableNumber"
      v-on:confirmEventModal="confirmAddErrand"/>

    <b-modal id="modal-delete-errand" :title="t('views.delete', { model_name: t('activerecord.models.errand') })">
      <div v-if="errandToDelete">
        <p>{{t('activerecord.models.errand')}}: {{ errandToDelete.errand_type }}</p>
        <p>{{t('activerecord.models.general_status')}}: {{ errandToDelete.general_status }} - {{ errandToDelete.sub_status }}</p>
      </div>

      <template #modal-footer="{ cancel }">
        <button class="footer-button base-button outline mrp-24" @click="cancel">{{ t(`views.cancel`) }}</button>
        <button class="footer-button base-button btn-danger" @click="destroyErrand" >{{ t(`views.destroy`) }}</button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import i18n from "../mixins/i18n"
import capitalize from "../mixins/capitalize"
import moment from 'moment'
import errand from '../../api/errands'
import errand_types from '../../api/errand_types'
import errandsMixin from '../mixins/errandStatusLabel'
import Loader from '../template/Loader.vue'
import ModalAddErrand from './ModalAddErrand.vue'
import Alert from '../template/Alert.vue'

export default {
  name: 'instantiate-errand',
  mixins: [i18n, capitalize, errandsMixin],
  components: {Loader, ModalAddErrand, Alert},
  props: {
    errandableInstanceId: [Number, String],
    errandableInstanceType: String,
    errandableNumber: String,
    errandableId: String,
  },
  data() {
    return {
      isEditableBaseStatus: false,
      selectErrandMode: 'multi',
      errandsSelected: [],
      allSelected: false,
      indeterminateCheck: false,
      fields: ['selected', 'general_status', 'errand_type', 'sub_status', 'operational_check', 'date', 'sla', 'condition', 'responsible', 'actions'],
      items: [],
      errandTypeList: [],
      responsiblesList: [],
      textNotification: '',
      undoEventAlert: () => {},
      eventAlert: () => {},
      errandableType: this.errandableInstanceType == 'Operation' ? 'ProductType' : 'InventoryType',
      errandToDelete: null
    }
  },
  methods: {
    getErrandsList: async function() {
      this.$refs.loader.isVisible()
      try {
        this.items = await errand.listByErrandableType(this.errandableInstanceId, this.errandableInstanceType)
        if(this.items.length > 0) {
          this.items.map = this.items.map((item, i) => {
            item.actions = {}
            return item
          })
        }
      } catch (error) {
        console.error(error);
      }
      this.$refs.loader.notVisible()
    },
    closeCollapseMassEdition() {
      this.$root.$emit('bv::toggle::collapse', 'collapse-mass')
    },
    resetCheck() {
      this.errandsSelected = []
      this.indeterminateCheck = false
      this.allSelected = false
      this.actionsCollapseMassEdition(false)
    },
    actionsCollapseMassEdition(baseStatus) {
      this.isEditableBaseStatus = baseStatus
    },
    openEditBaseStatus() {
      this.errandsSelected
      this.$bvModal.show('modal-mass-edit-base-state')
    },
    onRowErrandSelected(items) {
      this.errandsSelected = items
      if (this.errandsSelected.length === 0) {
        this.indeterminateCheck = false
        this.allSelected = false
      } else if (this.errandsSelected.length === this.items.length ) {
        this.indeterminateCheck = false
        this.allSelected = true
      } else {
        this.indeterminateCheck = true
        this.allSelected = false
      }
      this.checkTableCondition()
    },
    checkTableCondition() {
      let baseStatus = false
      if(this.errandsSelected.length > 0) {
        if(this.errandsSelected.length === 1) {
          this.actionsCollapseMassEdition(true)
          return
        }
        let noOpCondition = (errand) => errand.operation_number === this.errandsSelected[0].operation_number;
        baseStatus = this.errandsSelected.every(noOpCondition)
      }
      this.actionsCollapseMassEdition(baseStatus)
    },
    toggleAll(checked) {
      checked ? this.$refs.selectableErrandsTable.selectAllRows() : this.$refs.selectableErrandsTable.clearSelected()
      this.checkTableCondition()
    },
    confirmAddErrand: async function(errandType, responsible, summary) {
      const textType = this.errandableInstanceType == 'Operation' ? `la Op ${this.errandableNumber}` : `el Item ${this.errandableNumber}`
      this.textNotification = `Has agregado un trámite en ${textType}`
      this.$refs.notification.show()

      const newItem = {
        general_status: summary?.sub_statuses[0]?.general_status,
        errand_type: errandType?.name,
        sub_status: summary?.sub_statuses[0]?.name,
        operational_check: summary?.operational_check_at,
        date: {
          start_date: moment(new Date()).format('DD-MM-YYYY'),
          end_date: ''
        },
        sla: summary?.limit_days,
        condition: this.t(`activerecord.attributes.operation_errand.up_to_date`),
        responsible: responsible
      }
      this.items.push(newItem)
      this.eventAlert = async () => {
        await errand.newErrand(this.errandableInstanceId, errandType.id, responsible.id, this.errandableInstanceType)
      }
      this.undoEventAlert = () => {
        this.items.pop()
        this.$refs.notification.isUndo()
      }
    },
    onEventAlert() {
      this.eventAlert()
    },
    onUndoEventAlert() {
      this.undoEventAlert()
    },
    openModalAddErrand: async function() {
      this.errandTypeList = await errand_types.errandsAvailable(this.errandableId, this.errandableType, this.errandableInstanceId, this.errandableInstanceType)
      this.responsiblesList = await errand.responsibleAvailable()
      this.$bvModal.show('modal-add-errand')
    },
    openDeleteModal: async function(errand) {
      this.errandToDelete = errand
      this.$bvModal.show('modal-delete-errand')
    },
    destroyErrand: async function() {
      await errand.destroy(this.errandToDelete.id)
      this.$bvModal.hide('modal-delete-errand')
      this.getErrandsList();
    }
  },
  mounted() {
    this.getErrandsList();
    this.$root.$on('bv::collapse::state', (collapseId, isJustShown) => {
      if(collapseId === 'collapse-mass'){
        if(isJustShown) {
          this.fields.unshift('selected')
          $('td').children('.select_column').children('.custom-control-label').attr('for', '')
        } else {
          this.resetCheck()
          this.fields.shift()
        }
      }
    })
  }
}
</script>
