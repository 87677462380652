<template>
  <div>
    <b-alert v-if="showAlert" class="ml-3 mr-3" show :variant="alertVariant">{{alertText}}</b-alert>
    <box :title="title" md="12" sm="12">
      <template slot="content">
        <div class="collapsible-item mbp-10">
          <div class="row align-items-center mp-15">
            <div class="col-md-4 col-sm-12">
                <b-form-group
                  label="Nombre"
                  label-class="text-capitalize"
                  invalid-feedback="Nombre es requerido"
                >
                  <b-form-input type="text" v-model="inventory_type.name"></b-form-input>
                  <span class="text-danger" v-if="errors.name && typeof errors.name === 'string'">{{errors.name}}</span>
                </b-form-group>
            </div>
            <div class="col-md-4 col-sm-12">
                <b-form-group
                  label="Letra inicial del ID"
                  label-class="text-capitalize"
                  invalid-feedback="Nombre es requerido"
                >
                  <b-form-input type="text" v-model="inventory_type.prefix"></b-form-input>
                  <span class="text-danger" v-if="errors.prefix && typeof errors.prefix === 'string'">{{errors.prefix}}</span>
                </b-form-group>
            </div>
            <div class="col-12 row align-items-center">
              <b-form-group
                label="Color"
                label-class="text-capitalize"
                invalid-feedback="Color es requerido"
                class="mt-0 mip-15"
              >
                <color-picker
                  :color-selected="colorSelected"
                  v-on:onChange="onChangeIcon"/>
              </b-form-group>
              <b-form-group
                label="Icono"
                label-class="text-capitalize"
                invalid-feedback="Icono es requerido"
                class="mt-0"
              >
                <icons-list
                  :icon-selected="iconSelected"
                  v-on:onChange="onChangeIcon"/>
              </b-form-group>
              <span class="flex-vertical-center justify-content-center mip-15 relative" v-bind:style="{ width: '44px' }">
                <span id="icon_selected" v-bind:style="{ color: colorSelected }" :key="iconKey"></span>
                <div class="absolute back-icon" v-bind:style="{ background: colorSelected, opacity: '20%' }"></div>
              </span>
            </div>
          </div>
        </div>

        <b-tabs class="table-responsive">
          <b-tab active>
            <template v-slot:title >
              <span class="text-uppercase"> {{ t('inventory_type.attrs') }} </span>
            </template>

            <div class="p-3">
              <collapse-with-header title="Atributos transversales" >
                <template slot="header">
                  <b-badge class="text-muted ml-3 p-0 d-flex flex-row align-items-center" variant="light">
                    <span class="p-1">{{transversalFields.length}} {{ t('inventory_type.attrs')}}  </span>
                  </b-badge>
                </template>

                <template slot="body">
                  <hr class="horizontal-line mt-0">
                  <div class="table-header mb-2">
                    <div class="row col ">
                      <div class="col-md-3">{{ t('inventory_type.attr_name')}} </div>
                      <div class="col-md-3">{{ t('inventory_type.attr_description')}} </div>
                      <div class="col-md-2">{{ t('inventory_type.attr_type')}} </div>
                      <div class="col-md-1">{{ t('inventory_type.attr_required')}} </div>
                      <div class="col-md-3">{{ t('inventory_type.attr_notificable') }}
                        <b-button v-b-tooltip.hover.top="t('inventory_type.attr_notificable_tooltip')" class="p-0" variant="link" size="lg">
                          <i class="fas fa-info-circle"   ></i>
                        </b-button >
                      </div>
                    </div>
                  </div>
                  <div>
                    <div class=" pb-2 row" v-for="field in transversalFields" :key="field.name">
                      <div class="col text-table-color">
                        <div class="col pb-2 mb-2 border-bottom d-flex">
                          <div class="col-md-3">{{ t(`activerecord.attributes.inventory_item.${field.name}`) }}</div>
                          <div class="col-md-3">{{ field.description}}</div>
                          <div class="col-md-2">{{ field.type.text}}</div>
                          <div class="col-md-1">{{ field.required ? 'Si' : 'No' }}</div>
                          <div class="col-md-3">
                            <b-form-checkbox
                              :id="field.name"
                              :name="`checkbox-${field.name}`"
                              v-model="notificationTrigger"
                              :unchecked-value="null"
                              :value="{name: field.name, type: field.type.code}"
                              size="lg"
                              switch
                            ></b-form-checkbox>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </collapse-with-header>

              <collapse-with-header title="Atributos particulares">
                <template slot="header">
                  <b-badge class="text-muted ml-3 p-0 d-flex flex-row align-items-center" variant="light">
                    <span class="p-1">{{inventory_type.item_details.fields.length}} {{ t('inventory_type.attrs')}}  </span>
                  </b-badge>
                </template>

                <template slot="body">
                  <div>
                    <hr class=" mt-0">
                    <div class="table-header mb-2">
                      <div class="row col ">
                        <div class="col-md-3">{{ t('inventory_type.attr_name')}} </div>
                        <div class="col-md-3">{{ t('inventory_type.attr_description')}} </div>
                        <div class="col-md-2">{{ t('inventory_type.attr_type')}} </div>
                        <div class="col-md-1">{{ t('inventory_type.attr_required')}} </div>
                        <div class="col-md-2">
                          {{ t('inventory_type.attr_notificable') }}
                          <b-button v-b-tooltip.hover.top="t('inventory_type.attr_notificable_tooltip')" class="p-0" variant="link" size="lg">
                            <i class="fas fa-info-circle"   ></i>
                          </b-button >
                        </div>
                        <div class="col-md-1"></div>
                      </div>
                    </div>
                    <div>
                      <div
                        v-for="field in inventory_type.item_details.fields" :key="field.id"
                        class="mb-2 border-bottom pb-2"
                      >
                        <field-form
                          :readonly="true"
                          :init-field="field"
                          :notification-trigger-name="getNotificationTrigger(field.name)"
                          @updateNotificationTrigger="updateNotificationTrigger"
                          @addField="addField"
                          @updateField="updateField"
                          @deleteField="deleteField"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="" v-if="showAddField">
                    <field-form @addField="addField" />
                  </div>
                  <b-form-group v-else>
                    <b-button variant="btn btn-outline-primary" class="w-100" type="submit" @click="toggleShowAddField"> {{ t('inventory_type.add_field') }} </b-button>
                  </b-form-group>
                </template>
              </collapse-with-header>
            </div>
          </b-tab>

          <b-tab active>
            <template v-slot:title >
              <span class="text-uppercase"> {{ t('inventory_type.errand_template') }} </span>
            </template>

            <div class="p-3">
              <errand-invoice-template-index
                :inventory-type-id="inventory_type.id"
              ></errand-invoice-template-index>
            </div>
          </b-tab>
        </b-tabs>

        <b-form-group class="mt-4" >
          <b-button variant="primary" type="submit" :disabled="isSaving" @click="saveInventoryType">
            <b-spinner variant="light" type="grow" v-if="isSaving"></b-spinner>
            Guardar
          </b-button>
        </b-form-group>

      </template>
    </box>
  </div>
</template>

<script>
  // import _ from 'lodash';
  import i18n from "../mixins/i18n"
  import FieldForm from './FieldForm.vue';
  import ErrandInvoiceTemplateIndex from '../errand_invoice_template/Index.vue'
  import _ from 'lodash';
  import inventoryTypeAPI from '../../api/inventory_types'
  import Box from '../template/Box.vue'
  import CardItem from '../template/CardItem.vue'
  import CollapseWithHeader from '../template/CollapseWithHeader.vue'
  import { TRANSVERSAL_FIELDS } from '../../constants/inventory'
  import IconsList from './IconsList.vue';
  import ColorPicker from './ColorPicker.vue';
  import iconsList from '../mixins/icons'

  const DEFAULT_ERRORS = {
    name: null,
    category: null,
    prefix: null,
    item_details: null
  }

  export default {
    name: 'InventoryTypeForm',
    mixins: [iconsList, i18n],
    components: {
      FieldForm,
      Box,
      CardItem,
      CollapseWithHeader,
      IconsList,
      ColorPicker,
      ErrandInvoiceTemplateIndex
    },
    props: [
      'inventoryTypeId',
      'category',
      'isNew',
      'isCreating'
    ],
    data() {
      return {
        inventory_type: {
          id: this.inventoryTypeId,
          category: this.category || 'products',
          name: '',
          prefix: '',
          notification_trigger: [],
          item_details: {
            fields: []
          },
        },
        colorSelected: '#000',
        iconSelected: undefined,
        iconKey: 1,
        title: '',
        errors: DEFAULT_ERRORS,
        showAddField: false,
        isSaving: false,
        fieldsOpen: false,
        fieldsOk: false,
        categoryOptions: [
          { value: null, text: 'Seleccionar una opción', selected: true },
          { value: 'products', text: 'Productos' },
          { value: 'accessories', text: 'Accesorios' },
        ],
        transversalFields: TRANSVERSAL_FIELDS,
        showAlert: false,
        alertText: '',
        alertVariant: '',
        notificationTrigger: []
      }
    },
    mounted: async function() {
      if(this.inventoryTypeId) {
        this.inventory_type = await inventoryTypeAPI.get(this.inventoryTypeId)
        this.colorSelected = this.inventory_type.color ?? '#000'
        this.iconSelected = this.inventory_type.icon ?? ''
        this.notificationTrigger = [...(this.inventory_type.notification_trigger || [])]
        this.renderIcon(this.iconSelected, this.iconKey, 'icon_selected')
      }

      if(this.inventory_type.category === 'accessories')
        this.title = 'Línea de accesorio'
      else
        this.title = 'Línea de producto'

      if(this.isNew){
        this.alertText = `${this.title} creado correctamente`
        this.alertVariant = 'success'
        this.showAlert = true
      }
    },
    methods: {
      addField(value) {
        this.inventory_type.item_details.fields.push({...value})
        this.toggleShowAddField()
      },
      updateField(value) {
        let objIndex = this.inventory_type.item_details.fields.findIndex((obj => obj.id === value.id));
        this.inventory_type.item_details.fields[objIndex] = {...value}
      },
      deleteField(value) {
        this.inventory_type.item_details.fields = _.remove(this.inventory_type.item_details.fields, function(f) {
          return f.id !== value;
        })
      },
      toggleShowAddField() {
        this.showAddField = !this.showAddField
      },
      saveInventoryType: async function() {
        this.isSaving = true
        let response;

        this.inventory_type.notification_trigger = [...this.notificationTrigger]

        if(this.inventory_type.id){
          response = await inventoryTypeAPI.update(this.inventory_type.id, { inventory_type: this.inventory_type })
        } else {
          response = await inventoryTypeAPI.post({ inventory_type: this.inventory_type })
        }

        if(response.status === 422){
          this.errors.name = response?.data?.name ? response.data.name.join(', ') : ''
          this.errors.category = response?.data?.category ? response.data.category.join(', ') : ''
          this.errors.prefix = response?.data?.prefix ? response.data.prefix.join(', ') : ''
          this.errors.item_details = response?.data?.item_details ? response.data.item_details.join(', ') : ''
          this.showAlert = false
        } else if (response.id){
          if(!this.inventory_type.id){
            location.replace(`/inventory_types/${response.id}/edit?new=true`)
          }else{
            this.alertText = `${this.title} actualizado correctamente`
            this.alertVariant = 'success'
            this.showAlert = true
          }
        }
        this.isSaving = false
      },
      toggleFieldsOpen() {
        this.fieldsOpen = !this.fieldsOpen
      },
      onChangeIcon(info) {
        if(info.color) {
          this.inventory_type.color = this.colorSelected = info.color
          return;
        }
        if(info.icon) {
          this.inventory_type.icon = info.icon
          this.renderIcon(this.inventory_type.icon, this.iconKey, 'icon_selected')
          return;
        }
      },
      updateNotificationTrigger: function(data){
        if(data.action === 'add')
          this.notificationTrigger.push(data.value)
        else
          this.notificationTrigger = this.notificationTrigger.filter(val => data.value !== val.name)
      },
      getNotificationTrigger: function(name){
        return this.notificationTrigger.find(val => val.name === name)
      },
    },
  }
</script>
