<template>
  <div class="content-select" :style="`width: ${ inputWidth } !important`">
    <b-form-select
      :id="selectId"
      class="default-select"
      :style="`height: ${ inputHeight } !important`"
      v-model="selected"
      :options="getOptions"
      v-on:change="onChangeSelect()"
      :value-field="valueField"
      :text-field="textField"
      required>
    </b-form-select>
    <i></i>
  </div>
</template>
<script>
export default {
  name: "select-input",
  data() {
    return {
      selected: null,
    }
  },
  props: {
    placeholder: String,
    onChange: Function,
    options: {
      type: Array,
      default: () => []
    },
    selectId: {
      type: String,
      default: null
    },
    inputWidth: {
      type: String,
      default: 'fit-content'
    },
    inputHeight: {
      type: String,
      default: '32px'
    },
    valueField: {
      type: String,
      default: 'value'
    },
    textField: {
      type: String,
      default: 'text'
    },
    defaultValue: {
      type: String,
      default: null
    }
  },
  methods: {
    onChangeSelect() {
      this.$emit('onChange', this.selected)
    },
    setValue(value) {
      this.selected = value
    }
  },
  mounted() {
    if(this.defaultValue)
      this.selected = this.defaultValue
  },
  computed: {
    getOptions() {
      this.options.unshift({ [this.valueField]: null, [this.textField]: this.placeholder, disabled: true})
      return this.options
    }
  }
}
</script>
