<template>
  <div>
    <draggable v-if="simulationList" class="list-group" v-model="simulationList" @end="changeStatus" group="${this.status}" :key="this.status" :data-id="this.status">
      <div v-for="element in simulationList" :key="element.id" :data-id="element.id" class="cursor-grabbing px-2">
        <div class="mt-1 bg-white rounded p-3 border border-gray">
          <missing-info v-if="['approved', 'ready_to_execute'].includes(element.status)" :element="element"/>
          <div v-if="element.credit_code">
            {{ t('activerecord.models.op') }}: {{ element.credit_code }}
          </div>
          <div class="text-gray-700 font-semibold font-sans tracking-wide text-xs">
            {{element.client_name}}
          </div>
          <div class="flex flex-column mt-2 justify-between text-6em text-gray-600">
            <badge class="my-1" v-if="element.simulation_type == 'leasing'" :color="'blue'" >
              {{ t('activerecord.models.leasing') }}
            </badge>
            <badge class="my-1" v-else-if="element.product_type_name != null" :color="'purple'">
              {{ element.product_type_name }}
            </badge>
            <badge class="my-1" v-else :color="'purple'">
              {{ t('simulations.no_product_type') }}
            </badge>
            <badge class="my-1" :color="badgeColor">
              {{ t('simulations.clp') }} {{element.gross_amount | currency('$', 0, { thousandsSeparator: '.', decimalSeparator: ',' }) }}
            </badge>
            <badge class="my-1" color="red">
              {{ element.creator_full_name }}
            </badge>
            <span class="mt-2">{{ t('simulations.created') }}: {{ element.created_at | moment }}</span>
            <span class="mt-1">{{ t('simulations.update') }}: {{ element.updated_at | moment }}</span>
            <span class="mt-1">{{ t('activerecord.models.simulation') }}: #{{ element.id }}</span>
            <span class="my-1" v-show="hasQuickCode(element)">Quick code: #{{element.quick_code}}</span>
          </div>
          <button class="button button-secondary button-sm mt-1 w-100" @click="simulationUrl(element.id)">
            <small>{{ t('views.show') }} {{ t('activerecord.models.simulation') }}</small>
          </button>
        </div>
      </div>
    </draggable>
  </div>
</template>
<script>
  import Vue from 'vue';
  import Vue2Filters from 'vue2-filters'
  import moment from 'moment'
  Vue.use(Vue2Filters)

  import apiSimulation from '../../api/simulations'
  import draggable from 'vuedraggable'
  import MissingInfo from '../simulations/MissingInfo.vue'
  import badge from '../simulations/Badge.vue'
  import i18n from "../mixins/i18n"

  export default {
    name: 'simulation-card',
    mixins: [Vue2Filters.mixin, i18n],
    components: {
      draggable,
      badge,
      MissingInfo
    },
    props: {
      status: {
        type: String,
        default: ''
      },
      arrayHash: {
        type: Array,
        default: null
      }
    },
    data: function() {
      return {
        simulation: {},
        simulationList: this.arrayHash
      }
    },
    filters: {
      moment: function(date) {
        return moment(Date.parse(date)).format('DD/MM/YY, h:mm a')
      },
    },
    methods: {
      simulationUrl: function(simulationID) {
        window.open(`/simulations/${simulationID}`, '_blank')
      },
      changeStatus: async function(event) {
        const simulationId = event.item.dataset.id
        const from_status = event.from.dataset.id
        const to_status = event.to.dataset.id
        const response = await apiSimulation.update(simulationId, { status: to_status })
        this.updateSimulation(response)
      },
      updateSimulation: function(value) {
        if (value.status === 'error') {
          alert(value.message);
          location.reload();
          return
        }
        const oldValue = Object.assign({}, this.simulation)
        this.simulation = value
        if(Object.keys(oldValue).length) {
          if(oldValue.status == value.status) { return }
        }
        this.$emit('reloadColumns', { oldValue: oldValue, newValue: value })
      },
      hasQuickCode: function(element) {
        return !_.isNil(element.quick_code)
      }
    },
    computed: {
      badgeColor: function() {
        const mappings = {
          default: "teal"
        };
        return mappings.default;
      },
    },
    watch: {
      arrayHash () {
        this.simulationList = this.arrayHash
      }
    }
  }
</script>
<style>
  .text-6em {
    font-size: 0.6em !important;
  }
</style>
