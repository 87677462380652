import _ from 'lodash';

export default {
  data: function() {
    return {
      closeButton: true,
      successBox: false,
      simulation: {},
      guarantors: []
    }
  },
  methods: {
    closeMessage: function() {
      this.closeButton = false
    },
  },
  computed: {
    latestGuarantor: function() {
      const counter = this.simulation.guarantors.length - 1
      return this.simulation.guarantors[counter]
    },
    isNullValue: function() {
      return this.checkboxValue == null
    },
    isGuarantorDropdown: function() {
      return !this.isNullValue && this.checkboxValue == 'not_new'
    },
    isGuarantorNew: function() {
      return !this.isNullValue && this.checkboxValue == 'new'
    }
  },
  mounted: function() {
    this.simulation = this.element
  },
}
