<template>
  <div>
    <b-form-group
      id="input-group"
      label-class="font-bold"
      label-cols-sm="12"
      label-cols-md="4"
      content-cols-sm="12"
      content-cols-md="8"
      label="Ítem"
      label-for="input-1"
    >
      <div class="row">
        <div class="col-md-12 col-xl-8">
          <b-input-group>
            <b-form-select
              id="input-1"
              v-model="selectedInventoryItemId"
              :options="formattedInventoryItems"
              value-field="id"
              text-field="vehicle_name"
              @change="updateSelectedInventory"
            />
            <b-input-group-append>
              <b-button block @click="toggleModal()" variant="primary"><span class="fas fa-filter"></span></b-button>
            </b-input-group-append>
          </b-input-group>
        </div>
        <div class="col-md-12 col-xl-4">
          <button v-if="permissionRole && selectedInventoryItemId" class="base-button danger-button width-inherit"
            v-b-modal.modal_delete_item @click="onSetSelectedInvItem(productPrefId)">{{ t(`views.unlink`) }}</button>
        </div>
      </div>
    </b-form-group>
    <vehicle-search-modal
      :show-edit-modal="showModal"
      @filterVehicles="filterInventory"
    />
  </div>
</template>

<script>
import i18n from "../mixins/i18n"
import inventoryMixin from '../mixins/inventory'
import VehicleSearchModal from '../simulator/VehicleSearchModal.vue'

export default {
  name: 'vehicle-list',
  components: { VehicleSearchModal },
  mixins: [ inventoryMixin, i18n ],
  props: [
    'inventoryItemIdSelected',
    'vehicleModelId',
    'productPrefId',
    'setSelectedInvItem',
    'permissionRole',
    'campaign',
    'simulationProductAmount',
    'preferredProductPrice',
    'discountAmount'
  ],
  data: function() {
    return {
      showModal: false,
    }
  },
  mounted: async function() {
    if (!_.isNil(this.inventoryItemIdSelected)) {
      this.selectedInventoryItemId = this.inventoryItemIdSelected === 0 ? null : this.inventoryItemIdSelected
    }

    await this.getAvailableByListPrice(this.campaign, this.preferredProductPrice, this.discountAmount)

    this.inventoryItemsList = [
      {
        id: null,
        vehicle_name: 'Seleccione una opcion',
        value: ''
      },
      ...this.inventoryItemsList
    ]
  },
  methods: {
    toggleModal: function() {
      this.showModal = !this.showModal
    },
    filterInventory: function(value) {
      this.inventoryItemsList = this.inventoryItemsList.filter( (ii) =>
        (!value.inventoryNumber || ii.inventory_number === value.inventoryNumber) &&
        (!value.brand || ii.vehicle_brand === value.brand) &&
        (!value.model || ii.vehicle_model === value.model) &&
        (!value.color || ii.vehicle_colors.indexOf(value.color) != -1))
      this.showModal = !this.showModal
    },
    updateSelectedInventory: function(value) {
      const selectedInventoryItem = this.inventoryItemsList.filter((ii) => ii.id === value)[0]
      this.$emit('selectedInventory', {
        id: this.productPrefId,
        itemId: selectedInventoryItem.id
      })
    },
    onSetSelectedInvItem(itemId) {
      this.$emit('setSelectedInvItem', itemId)
    },
  },
  computed: {
    formattedInventoryItems: function() {
      let vehicle_name = '', price = 0
      return this.inventoryItemsList.map(ii => {
        vehicle_name = `${ii.vehicle_name}`

        price = this.$options.filters.currency(Number(ii.value), '', 0, { thousandsSeparator: '.', decimalSeparator: ',' })

        vehicle_name += ii.campaign
          ? ` - ${ii.campaign}`
          : ''

        vehicle_name += ii.value
          ? ` (${price})`
          : ''

        const emailAccount = ii.taken_by?.split('@')[0]
        vehicle_name += ii.taken
          ? ` - ${emailAccount}`
          : ''

        return {
          ...ii,
          vehicle_name
        }
      })
    },
  },
}
</script>
