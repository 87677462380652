export default {
  methods: {
    iconCondition(state) {
      switch (state) {
        case 'En proceso':
          return `<i class="fas fa-clock color-warning"></i> ${state}`
        case 'Finalizado':
        case 'Al día':
          return `<i class="fas fa-check-circle color-success"></i> ${state}`
        case 'Fallido':
        case 'Atrasado':
          return `<i class="fas fa-exclamation-circle color-error"></i> ${state}`
        case 'Por hacer':
          return `<i class="fas fa-minus-circle color-default"></i> ${state}`
        default:
          return state
      }
    },
  }
}
