<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-5">
        <div class="card">
          <div class="card-body">
            <b-form v-model.lazy="beenModified">
              <div class="row">
                <div v-if="!cashProductType" class="col-sm-12">
                  <b-form-group label="Periodicidad de pago" label-for="input-1" description="Periodicidad de Pago">
                    <b-form-select :disabled="disabledOptions" v-model="simulation.payment_frequency" :options="payment_frequencies"></b-form-select>
                  </b-form-group>
                </div>
                <div :class="{'col-sm-12': isLeasing, 'col-sm-6': !isLeasing}">
                  <b-form-group label="Fecha de curse" label-for="input-1" description="Fecha de firma">
                    <b-form-datepicker id="sign_date" v-model="simulation.sign_date" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" locale="es" class="mb-2"></b-form-datepicker>
                  </b-form-group>
                </div>
                <div v-if="!cashProductType" class="col-sm-6" v-show="this.simulation.payment_frequency == 'monthly'">
                  <b-form-group label="Día de pago" label-for="input-1" description="Día de pago de las cuotas">
                    <b-form-select id="payment_day" v-model="simulation.payment_day" :options="days" class="mb-2"></b-form-select>
                  </b-form-group>
                </div>
                <div class="col-sm-6" v-show="this.is_reprogrammed != 'reprogram'">
                  <b-form-group
                    :label="this.productValueLabel"
                    label-for="input-1"
                    description="Valor del Auto/Moto/Consumo"
                  >
                    <b-input-group>
                      <b-form-input
                        :disabled="hasInventoryItemSelectedOrPrice"
                        v-model.number="simulation.product_value"
                        type="number"
                        placeholder="100"
                        :no-wheel="true"
                        required></b-form-input>
                    </b-input-group>
                  </b-form-group>
                </div>
                <div v-if="!cashProductType" class="col-sm-6" v-show="this.is_reprogrammed != 'reprogram'">
                  <input type="radio" v-model="downPaymentType" value="0">
                  <b-form-group label="Monto pie" label-for="input-1"
                    :description="maxDownPaymentText">
                    <b-input-group>
                      <b-form-input
                        :disabled="downPaymentType=='1' || cashProductType"
                        v-model.number="simulation.down_payment"
                        :lazy="true"
                        type="number"
                        placeholder="100"
                        min="0"
                        :no-wheel="true">
                      </b-form-input>
                    </b-input-group>
                  </b-form-group>
                </div>
                <div v-if="!cashProductType" class="col-sm-6" v-show="this.is_reprogrammed != 'reprogram'">
                  <input type="radio" v-model="downPaymentType" value="1">
                  <b-form-group label="(%) Porcentaje pie" label-for="input-1" description="Porcentaje pie">
                    <b-input-group>
                      <b-form-input
                        :disabled="downPaymentType=='0' || cashProductType"
                        v-model.number="downPaymentPercentage"
                        type="number"
                        placeholder="20%"
                        max="100"
                        min="0"
                        :no-wheel="true" >
                      </b-form-input>%
                    </b-input-group>
                  </b-form-group>
                </div>
                <div v-if="!cashProductType" class="col-sm-6">
                  <b-form-group label="Cuotas" label-for="input-1" description="Especifica número de cuotas a pagar">
                    <b-form-input
                      v-model="simulation.installments_amount"
                      :disabled="cashProductType"
                      type="number"
                      placeholder="10"
                      required
                      :no-wheel="true">
                    </b-form-input>
                  </b-form-group>
                </div>
                <div v-if="!cashProductType" class="col-sm-6" v-show="this.is_reprogrammed != 'leasing'">
                  <b-form-group label="Periodos de gracia" label-for="input-1" description="Especifíca número de períodos de gracia">
                    <b-form-input v-model="simulation.grace_period" placeholder="" required></b-form-input>
                  </b-form-group>
                </div>
                <div v-if="!cashProductType" class="col-sm-6">
                  <b-form-group label="Valor de cuota (con IVA)" label-for="input-1" description="Indica valor de cuota que pagará el cliente">
                    <b-form-input
                      v-model="simulation.installment_value"
                      :disabled="isReprogrammedOrHasLeasingValue"
                      placeholder="0"
                      required
                    />
                  </b-form-group>
                </div>
                <!-- <div v-if="!cashProductType" class="col-sm-12">
                  <b-form-checkbox
                    v-model="forceLienInsuranceOldRate"
                    id="lien_insurance_checkbox"
                  >
                    Utilizar tasa antigua para seguro desgravamen (0.45)
                  </b-form-checkbox>
                </div> -->

                <div class="col-sm-12 mtp-12">
                  <b-button
                    block
                    variant="primary"
                    @click="calculateFerry"
                    :disabled="!canCreateSimulation || loading"
                    size="lg"
                  >
                    <b-spinner v-if="loading" />
                    <div v-else>
                      Calcular <span v-if="isEditingSimulation()">y guardar</span>
                    </div>
                  </b-button>
                </div>
              </div>
            </b-form>
          </div>
        </div>
      </div>
      <div class="col-sm-7 p-0">
        <div  v-if="!isCalculated || loading || isProductTypeUpdated" class="text-center">
          <h2>Simular crédito</h2>
          <p>Ingresa la información del crédito para calcular el cuadro de pago</p>
        </div>
        <div class="col-12" v-else>
          <div class="card">
            <div class="card-header py-3 d-flex flex-row align-item-center justify-content-between">
              <h6 class="m-0 font-weight-bold text-primary">Simulación:</h6>
            </div>
            <div class="card-body">
              <div class="message-card warning mb-4" v-if="cashProductType">
                <em class="fas fa-exclamation-triangle mr-3" />
                <p>{{ t(`simulator.credit_simulator.product_type.cash`) }}</p>
              </div>
              <div class="text-center">
                <h2>{{ this.simulation.installments_amount }} cuotas de:</h2>
                <br>
                <h1>{{ installment_amount }} </h1>
                <br>
                para un crédito de: {{ (this.simulation.net_amount || 0) | currency('$', 0, { thousandsSeparator: '.', decimalSeparator: ',' }) }}
                <br>
                Tasa de {{ this.simulation.interest_rate_per_month | percent(2) }}
                <div v-if="caeIsZero() && !cashProductType">
                  CAE no se pudo calcular
                </div>
                <div v-else>
                  CAE de: {{ this.simulation.cae | percent(2) }}
                </div>
                Primer Pago: {{ this.$moment(this.simulation.first_payment_date).format("DD/MM/YYYY") }}
                <br>
                Último pago: {{ this.simulation.formattedInstallments[this.simulation.formattedInstallments.length -1].due_date }}
                <br>
                <b-list-group class="mt-3" v-show="hasInstallments()" >
                  <b-list-group-item v-if="firstInstallmentIsZero() && !this.cashProductType" variant="warning">
                    El valor de la cuota es cero
                  </b-list-group-item>
                  <b-list-group-item v-if="!hasClientIncomesAverage()" variant="warning">
                    No se ha registrado el ingreso promedio
                  </b-list-group-item>
                  <b-list-group-item v-else>
                    Sueldo promedio: {{ this.clientIncomesAverage | currency('$', 0, { thousandsSeparator: '.', decimalSeparator: ',' }) }}
                  </b-list-group-item>
                  <b-list-group-item v-show="hasClientIncomesAverage()">
                    Ratio de cuota: {{ this.installmentRatio | percent(2) }}
                  </b-list-group-item>
                  <b-list-group-item v-show="hasClientIncomesAverage()">
                    Ratio financiamiento bruto / sueldo: {{ parseFloat(this.grossAmountIncomesRatio).toFixed(2) }}
                  </b-list-group-item>
                </b-list-group>
                <br>
                <b-button
                    block
                    variant="primary"
                    @click="toggleSimulationDetail"
                    :disabled="loading"
                    size="sm"
                  >
                    <b-spinner v-if="loading" />
                    <div v-else>
                      Ver detalles
                    </div>
                  </b-button>
              </div>
            </div>
          </div>
          <div class="card mtp-5" v-if="this.showSimulationDetail">
            <div class="card-header py-3 d-flex flex-row align-item-center justify-content-between">
              <h6 class="m-0 font-weight-bold text-primary">Simulación:</h6>

            </div>
            <div class="card-body">
              <dl class="row">
                <dt class="col-5">
                  Monto Líquido
                </dt>
                <dd class="col-7">{{ (this.simulation.net_amount || 0) | currency('$', 0, { thousandsSeparator: '.', decimalSeparator: ',' })   }}</dd>
                <dt class="col-5">
                  Monto Bruto
                </dt>
                <dd class="col-7">{{ (this.simulation.gross_amount || 0) | currency('$', 0, { thousandsSeparator: '.', decimalSeparator: ',' })  }}</dd>
              </dl>
              <ul class="extra-costs-list">
                <li v-for="item in this.simulation.extra_costs_attributes" :key="item.name">
                  {{ item.human_name }}: {{ item.amount | currency('$', 0, { thousandsSeparator: '.', decimalSeparator: ',' }) }}
                </li>
              </ul>
            </div>
          </div>
        </div>

      </div>
    </div>
    <div class='row mtp-20'>
      <div class='col-2'></div>
      <div class="col-8 col-span-2" v-if="this.showSimulationDetail">
        <b-jumbotron v-if="cdpError && !loading" header="" lead="">
          <div class="text-center">
            <h2>CDP Incorrecto</h2>
            <p>Hemos detectado que la última cuota de tu CDP es incorrecta</p>
            <p>No se encontró un valor de cuota adecuado para el número de cuotas indicadas</p>
            <p>Te recomendamos realizar el cálculo nuevamente revisando los datos ingresados</p>
          </div>
        </b-jumbotron>
        <b-table v-if="isCalculated && !cdpError" striped hover :items="simulation.formattedInstallments" :fields="fields">
        </b-table>
        <b-jumbotron v-if="!isCalculated" header="" lead="">
          <div  v-if="!loading" class="text-center">
            <h2>Simula tu crédito</h2>
            <p>Ingresa la información del crédito para calcular el cuadro de pago</p>
          </div>
          <div  v-if="loading" class="text-center">
            <h2>Calculando</h2>
            <p>Estamos generando su cuadro de pago</p>
            <b-spinner variant="primary" label="Calculando" style="width: 3rem; height: 3rem;"></b-spinner>
          </div>
        </b-jumbotron>
      </div>
    </div>

    <b-alert variant="danger" show v-if="!this.clientId && !isEditingSimulation()">
      <p>No se encontró un cliente para esta simulación, busca el enlace correcto en el trato de pipedrive</p>
    </b-alert>

    <div
      class="card"
      v-if="this.isCalculated && !isEditingSimulation() && this.clientId"
    >
      <div class="card-body">
        <div class="row">
          <div class="col-sm-12">
            <b-button
              block
              variant="success"
              @click="saveSimulation"
              size="lg"
              :disabled="loadingSave || loading"
            >
              <b-spinner v-if="loadingSave" />
              <span v-else>Guardar</span>
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import simulationApi from '../../api/simulations';
import clientsApi from '../../api/clients';
import i18n from "../mixins/i18n";

import _ from 'lodash'

import {
  tableFields,
  paymentDaysDropDownList,
  creditTypeDropDownList,
  paymentFrecuencyDropDownList,
  FIXED_LEASING_INSTALLMENTS_COUNT,
} from '../../constants/simulator'

export default {
  name: 'CreditSimulatorV2',
  mixins: [i18n],
  components: {},
  props: [
    'clientId',
    'currentSimulation',
    'initialInstallments',
    'pipedriveId',
    'productType',
    'simModelColorPreference',
    'selectedExtraCosts',
    'fogape'
  ],
  data: function() {
    return {
      simulation: {
        client_id: this.clientId,
        down_payment: 0,
        down_payment_percentage: 0,
        extra_costs_attributes: [],
        first_payment_date: "",
        formattedInstallments: [],
        grace_period: 1,
        gross_amount: this.initialGrossAmount || 0,
        installment_value: this.originCreditInstallmentAmount || 0,
        installments: [],
        installments_amount: this.installmentsCount || 12,
        interest_rate_per_month: this.tmc || 0,
        last_date: "",
        lienInsuranceAmount: this.lienInsuranceAmount || 0,
        net_amount: this.initialNetAmount || 0,
        origin_credit_id: this.originCreditId,
        payment_day: 1,
        payment_frequency: this.payment_frequency || 'monthly',
        pipedrive_id: this.pipedriveId || null,
        product_type_id: null,
        product_value: this.initialNetAmount || 0,
        sign_date: new Date(),
        simulation_type: this.simulationType || 'credit',
        stampDutyAmount: this.stampDutyAmount || 0,
        totalNetAmount: this.initialTotalNetAmount || 0,
      },
      extraCosts: this.selectedExtraCosts,
      isProductTypeUpdated: false,
      loadingSave: false,
      beenModified: '',
      cdpError: false,
      clientIncomesAverage: null,
      days: paymentDaysDropDownList,
      disabledOptions: false,
      disabled_input: true,
      downPaymentPercentage: 0,
      downPaymentType: 1,
      fields: tableFields,
      installmentValue: null,
      isCalculated: false,
      isLeasing: false,
      is_reprogrammed: this.simulationType,
      latestOutstandingBalance: 0,
      loading: false,
      options: creditTypeDropDownList,
      payment_frequencies: paymentFrecuencyDropDownList,
      validateSimulation: false,
      responseFerry: null,
      showSimulationDetail: false,
      forceLienInsuranceOldRate: false
    }
  },
  methods: {
    firstInstallmentIsZero: function() {
      return this.installmentValue === 0
    },
    updateMonthsForLienInsurance: function() {
      const monthsForLienInsurance = this.$moment(this.simulation.last_date).diff(this.$moment(this.simulation.sign_date), 'months')
      this.$emit('monthsForLienInsuranceUpdated', monthsForLienInsurance);
    },
    checkSimulationDate: function() {
      const simulation = _.mapKeys(this.simulation, (v, k) => _.camelCase(k))

      let { signDate, gracePeriod, installmentsAmount, paymentDay } = simulation //we need to overwrite with correct format
      const { isReprogrammed, simulationType, paymentFrequency } = simulation

      gracePeriod = parseInt(gracePeriod)
      signDate = this.$moment(signDate)
      installmentsAmount = parseInt(installmentsAmount)
      paymentDay = parseInt(paymentDay)

      let frecuencyType;
      let firstPayment;
      let lastPayment;
      let periodsInFirstPayment = 1 + gracePeriod;
      let periodsInLastPayment = periodsInFirstPayment + installmentsAmount;

      switch(paymentFrequency) {
        case 'weekly': {
          frecuencyType = 'weeks';
          break;
        }
        case 'monthly': {
          frecuencyType = 'months';
          break;
        }
        case 'byweekly': {
          frecuencyType = 'days';
          periodsInFirstPayment = (1 + gracePeriod) * 15;
          periodsInLastPayment = (1 + gracePeriod + installmentsAmount) * 15;
          break;
        }
        default: {
          console.log(`We don't support this simulation.paymentFrequency: ${paymentFrequency}`)
          break;
        }
      }

      firstPayment = signDate.clone().add(periodsInFirstPayment, frecuencyType)
      lastPayment = signDate.clone().add(periodsInLastPayment, frecuencyType)

      if (paymentFrequency == 'monthly') {
        firstPayment = firstPayment.date(paymentDay)
        lastPayment = lastPayment.date(paymentDay)
      }

      this.simulation.first_payment_date = firstPayment.format('YYYY-MM-DD')
      this.simulation.last_date = lastPayment.clone().subtract(1, frecuencyType).format('YYYY-MM-DD')
    },
    setClientIncomesAverage: async function() {
      const clientId = this.clientId || this.simulation.client_id
      const response = await clientsApi.getIncomesAverage(clientId)
      this.clientIncomesAverage = response
    },
    toggleSimulationDetail: function(){
      this.showSimulationDetail = !this.showSimulationDetail
    },
    calculateFerry: async function() {
      this.loading = true
      this.isCalculated = true
      let params = {
        downpayment: this.cashProductType ? 0 : this.simulation.down_payment,
        amount: this.simulation.product_value,
        term: this?.simulation?.installments_amount || null,
        course_date: this.simulation.sign_date,
        grace_period: this.simulation.grace_period,
        payment_day: this.simulation.payment_day,
        payment_frequency: this.simulation.payment_frequency,
        product_type: this.productType.id,
        credit_type_name: this.productType.credit_type,
        pipedrive_id: this.simulation.pipedrive_id,
        extra_costs: this.extraCosts,
        special_installments: this.simulation.special_installments,
        sim_model_color_preferences_attributes: this.simModelColorPreference,
        force_lien_insurance_old_rate: this.forceLienInsuranceOldRate,
        product_financial_type: this.productType.type,
        fogape: this.fogape,
        first_payment_date: this.simulation.first_payment_date
      }

      let resp = {}
      const isEditingSimulation = this.isEditingSimulation()

      if (isEditingSimulation){
        const result = this.showPreApprovalAlert()
        if (result) {
          try {
            resp = await simulationApi.recalculateFromFerry(this.currentSimulation.id, { ...params })
            if(!resp.data?.gross_amount){
              alert(this.t('simulator.credit_simulator.alerts.something-went-wrong'))
              this.isCalculated = false
              this.loading = false
            }
          } catch {
            alert(this.t('simulator.credit_simulator.alerts.failed-ferry-connection'))
            this.loading = false
          }

          this.$emit('clearProducts')
          this.$emit('updateProducts', resp.data?.sim_model_color_preferences.map(sim => ({...sim, productModel: {...params.sim_model_color_preferences_attributes.find(simParam => simParam.id === sim.id)?.productModel}})))
        } else {
          this.loading = false
          this.isCalculated = false
          return
        }
      } else {
        resp = await simulationApi.createFromFerry(params)
      }

      this.simulation.client_id = this.clientId
      this.responseFerry = resp.data
      this.simulation = {...this.simulation, ...resp.data}
      this.simulation.gross_amount = resp.data.gross_amount
      if (!_.isNil(this.productType)){
        this.simulation.product_type_id = this.productType.id
      }

      this.simulation.sim_model_color_preferences_attributes = this.simModelColorPreference
      this.simulation.totalNetAmount = this.simulation.net_amount

      this.isProductTypeUpdated = false
      // these installments are shown on the installments table
      this.simulation.formattedInstallments = this.responseFerry.installments.map((installment) => {
        const formattedInstallment = {
          ...installment,
          due_date: this.$moment(installment.due_date).format("DD/MM/YYYY"),
          outstanding_balance: this.$options.filters.currency(installment.outstanding_balance, '$', 0, { thousandsSeparator: '.', decimalSeparator: ',' }),
          capital: this.$options.filters.currency(installment.capital, '$', 0, { thousandsSeparator: '.', decimalSeparator: ',' }),
          interest_paid: this.$options.filters.currency(installment.interest_paid, '$', 0, { thousandsSeparator: '.', decimalSeparator: ',' }),
          installment_value: this.$options.filters.currency(installment.installment_value, '$', 0, { thousandsSeparator: '.', decimalSeparator: ',' }),
        }

        if (formattedInstallment.installment_value === '$0' && formattedInstallment.period > 0) {
          formattedInstallment['_rowVariant'] = 'warning'
        }

        if(this.is_reprogrammed == 'leasing') {
          formattedInstallment.vat = this.$options.filters.currency(Number(installment.vat), '$', 0, { thousandsSeparator: '.', decimalSeparator: ',' })
        }

        return formattedInstallment
      })

      this.simulation.installments = this.responseFerry.installments.map((installment) => {
        const newInstallment = {
          ...installment,
          due_date: this.$moment(installment.due_date),
        }

        if(this.is_reprogrammed == 'leasing') {
          newInstallment.vat = Number(installment.vat)
        }

        return newInstallment
      })
      this.loading = false
    },
    saveSimulation: async function() {
      this.loadingSave = true

      const isEditingSimulation = this.isEditingSimulation()
      this.simulation.fogape = this.fogape
      let response = {}
      if (!isEditingSimulation){
        this.simulation.status = 'unconfirmed'
        response = await simulationApi.create({...this.simulation})
      } else {
        response = await simulationApi.update(this.currentSimulation.id, { ...this.simulation })
      }
      this.loadingSave = false
      window.location.href = `${window.location.origin}/simulations/${response.data.id}`
    },
    setInstallmentValue: function() {
      const firstPeriodInstallment = _.find(this.simulation.installments, { period: 1 })
      this.installmentValue = firstPeriodInstallment?.installment_value
    },
    disableGrossAmountInput: function() {
      return this.is_reprogrammed != 'reprogram'
    },
    calculateDefaultGracePeriod: function() {
      if(this.simulation.grace_period > 0) { return }

      const day = this.$moment(this.simulation.sign_date).format('D');
      if (this.simulation.payment_frequency == 'monthly' && day > 15) {
        this.simulation.grace_period = 1
      } else {
        this.simulation.grace_period = 0
      }
    },
    isEditingSimulation: function(){
      return !_.isNil(this.currentSimulation)
    },
    hasInstallments: function() {
      return !_.isEmpty(this.simulation.installments)
    },
    hasClientIncomesAverage: function() {
      return !(_.isNil(this.clientIncomesAverage) || this.clientIncomesAverage === 0)
    },
    caeIsZero: function() {
      var value = Number(this.simulation.cae)
      return value === 0 || _.isNaN(value)
    },
    showPreApprovalAlert: function() {
      return this.currentSimulation.risk_verification ? confirm(this.t('simulator.credit_simulator.alerts.risk-confirm-alert')) : true
    },
    calculateNetAmount: function(productValue, extraCostsForProductValue, downPayment) {
      if (this.cashProductType){
        this.simulation.net_amount = this.simulation.gross_amount
      } else {
        this.simulation.net_amount = productValue + (extraCostsForProductValue || 0) - (downPayment || 0)
      }
    }
  },
  computed: {
    hasInventoryItemSelectedOrPrice: function() {
      return (!_.isNil(this.simModelColorPreference) &&
              this.simModelColorPreference.length > 0 &&
              this.simModelColorPreference.reduce((acc, curr) => acc += Number(curr.productModel?.list_price || 0) , 0) > 0
             )
    },
    isReprogrammedOrHasLeasingValue: function() {
      return this.is_reprogrammed !== 'leasing'
        || (this.hasInventoryItemSelectedOrPrice &&
          this.simModelColorPreference.reduce((acc, curr) => acc += Number(curr.productModel?.leasing_installment_value || 0) , 0) > 0)
    },
    grace_period() {
      return this.simulation.grace_period || 0;
    },
    interest_rate_per_month() {
      return this.simulation.interest_rate_per_month || 0;
    },
    payment_day() {
      return this.simulation.payment_day || 0;
    },
    sign_date() {
      return this.simulation.sign_date;
    },
    canCreateSimulation() {
      if (this.simulation.product_value === 0 && this.simulationType !== 'reprogram') { return false }
      if (this.simulation.grace_period < 0) { return false }
      if(this.simulation.installments_amount <= 0 || this.simulation.installments_amount == null) { return false }
      if(this.simulation.sign_date == null) { return false }
      return true
    },
    installmentRatio() {
      if(!this.hasInstallments() || !this.hasClientIncomesAverage()) { return 0 }

      return this.installmentValue / this.clientIncomesAverage
    },
    grossAmountIncomesRatio() {
      if(!this.hasClientIncomesAverage()) { return 0 }

      return this.simulation.gross_amount / this.clientIncomesAverage
    },
    extraCostsForProductValue: function() {
      return 0
    },
    totalProductValue: function() {
      return (this.simulation.product_value + (this.extraCostsForProductValue || 0))
    },
    maxDownPaymentText: function() {
      const maxProductValue =
        this.$options.filters.currency(Number(this.simulation.down_payment * 5), '$', 0, { thousandsSeparator: '.', decimalSeparator: ',' })

      return `Valor máximo de producto: ${maxProductValue}`
    },
    productValueLabel: function() {
      return `Valor del producto ${this.isLeasing ? '(Sin IVA)' : ''}`
    },
    cashProductType: function() {
      return this.productType?.type == 'cash'
    },
    installment_amount: function() {
      let installment_amount = this.simulation.installments.filter((ins) => ins.period === 1)[0].installment_value || 0
      if (this.cashProductType)
        installment_amount = this.simulation.gross_amount;

      return this.$options.filters.currency(installment_amount, '$', 0, { thousandsSeparator: '.', decimalSeparator: ',' })
    }
  },
  watch: {
    selectedExtraCosts: function(newValue, oldValue) {
      this.extraCosts = newValue
    },
    simModelColorPreference: function(newValue, oldValue) {
      if(!this.productType?.has_inventory) return

      if (this.isLeasing) {
        this.simulation.product_value = Number(newValue.reduce((acc, curr) => acc += Number((curr.productModel || curr.product_model)?.list_price || curr.price || 0) , 0) / 1.19)
        this.simulation.installment_value = newValue.reduce((acc, curr) => acc += Number((curr.productModel || curr.product_model)?.leasing_installment_value || 0) , 0)
      } else {
        this.simulation.product_value = newValue.reduce((acc, curr) => acc += Number((curr.productModel || curr.product_model)?.list_price || curr.price || 0) , 0)
      }
    },
    productType: function(newValue, oldValue) {
      Object.assign(this.$data, this.$options.data.call(this));
      this.isCalculated = false
      this.isProductTypeUpdated = true
      // set simulationType as leasing if productTypeId is 'Moto Leasing'
      if (_.includes(_.lowerCase(newValue.name), 'leasing')) {
        this.is_reprogrammed = 'leasing'
        this.simulation.payment_frequency = 'weekly'
      } else {
        this.is_reprogrammed = 'normal'
        this.simulation.payment_frequency = 'monthly'
      }
      if (this.cashProductType) {
        this.simulation.installments_amount = 1
        this.simulation.simulationType = 'cash'
        this.downPaymentPercentage = 100
        }
    },
    'simulation.product_value': function(newValue, oldValue) {
      this.calculateNetAmount(newValue, this.extraCostsForProductValue, this.simulation.down_payment)

      if (this.downPaymentType === "0"){
        if (this.totalProductValue > 0){
          this.downPaymentPercentage = this.simulation.down_payment / this.totalProductValue * 100;
        }
      }else{
        this.simulation.down_payment = Math.round((newValue + (this.extraCostsForProductValue || 0)) * this.simulation.down_payment_percentage);
      }
      this.$emit('productValueUpdated', newValue)
      this.$emit('netAmountUpdated', this.simulation.net_amount)
    },
    'extraCostsForProductValue': function(newValue, oldValue) {
      this.calculateNetAmount(this.simulation.product_value, newValue, this.simulation.down_payment)

      if (this.downPaymentType === "0"){
        if (this.totalProductValue > 0){
          this.downPaymentPercentage = this.simulation.down_payment / this.totalProductValue * 100;
        }
      }else{
        this.simulation.down_payment = Math.round((this.simulation.product_value + (newValue || 0)) * this.simulation.down_payment_percentage);
      }
      this.$emit('netAmountUpdated', this.simulation.net_amount)
    },
    'simulation.down_payment': function(newValue, oldValue) {
      this.calculateNetAmount(this.simulation.product_value, newValue, newValue)

      if (this.totalProductValue > 0){
        this.downPaymentPercentage = newValue / this.totalProductValue  * 100;
      }
      this.$emit('netAmountUpdated', this.simulation.net_amount)
    },
    'simulation.down_payment_percentage': function(newValue, oldValue) {
      this.simulation.down_payment = Math.round((this.simulation.product_value + (this.extraCostsForProductValue || 0)) * newValue);
    },
    'downPaymentPercentage': function(newValue, oldValue) {
      this.simulation.down_payment_percentage = newValue / 100;
    },

    'simulation.last_date': function(newValue, oldValue) {
      this.updateMonthsForLienInsurance()
    },
    'simulation.installments': function(newValue, oldValue) {
      this.setInstallmentValue()
    },
    clientId: function(newValue, oldValue) {
      this.setClientIncomesAverage()
    },
    'simulation.client_id': function() {
      this.setClientIncomesAverage()
    },
    beenModified: function() {
      this.isCalculated = false
      this.loading = false
    },
    isCalculated: function(newValue, oldValue) {
      this.$emit('hideSaveButton', newValue)
    },
    initialInstallments: function(newValue, oldValue) {
      this.simulation.special_installments = newValue
    },
    is_reprogrammed: function(newValue, oldValue) {
      if(this.is_reprogrammed == 'reprogram') {
        this.isLeasing = false
        this.disabled_input = true
        this.disabledOptions = true
      } else if (this.is_reprogrammed == 'normal') {
        this.isLeasing = false
        this.disabled_input = false
        this.disabledOptions = false
        this.simulation.installment_value = 0
      } else if (this.is_reprogrammed == 'leasing') {
        this.isLeasing = true
        this.disabled_input = false
        this.disabledOptions = false
        this.simulation.installment_value = 0//Number(this.vehicleModel?.leasing_installment_value)
      }
      this.simulation.simulation_type = this.is_reprogrammed
      this.$emit('simulationTypeUpdated', this.is_reprogrammed)
    },
    grace_period() { this.checkSimulationDate() },
    installments_amount() {
      this.checkSimulationDate()
    },
    payment_day() {
      this.checkSimulationDate()
    },
    sign_date(newValue, oldValue) {
      this.$emit('signDateUpdated', newValue)
      this.calculateDefaultGracePeriod()
      this.simulation.sign_date = newValue
      this.checkSimulationDate()
      this.updateMonthsForLienInsurance()
    },
    tmc: function(newValue, oldValue) {
      this.simulation.interest_rate_per_month = Number(newValue)
    },
    initialGrossAmount(newValue, oldValue) {
      this.simulation.gross_amount = Number(newValue)
    },
    initialNetAmount(newValue, oldValue) {
      this.simulation.net_amount = Number(newValue)
    },
    initialTotalNetAmount(newValue, oldValue) {
      this.simulation.totalNetAmount = Number(newValue)
    },
    installmentsCount(newValue, oldValue) {
      this.simulation.installments_amount = Number(newValue)
    },
    latestOutstandingBalance(newValue, oldValue) {
      if(newValue < -1) {
        this.validateSimulation = true
        this.cdpError = true
        this.$emit('disabledButton', true)
      } else {
        this.validateSimulation = false
        this.cdpError = false
      }
    },
    currentSimulation(newValue, oldValue) {
      this.options = this.options.filter((o) => o.value !== 'reprogram')
      this.simulation.client_id = newValue.client.id
      this.is_reprogrammed = newValue.simulation_type
      this.simulation.grace_period = newValue.grace_period
      this.simulation.payment_frequency = newValue.payment_frequency
      this.simulation.payment_day = this.$moment(newValue.first_payment_date).date()
      this.simulation.installment_value = Number(newValue.installment_value)
      this.simulation.sign_date = new Date(this.$moment(newValue.sign_date))
      this.simulation.interest_rate_per_month = newValue.interest_rate_per_month
      this.simulation.first_payment_date = newValue.first_payment_date

      this.simulation.product_value = Number(newValue.net_amount) + Number(newValue.down_payment || 0) - newValue.extra_costs.reduce((acc, cur) => cur.category === 'net' ? acc + cur.amount : acc + 0, 0) - newValue.extra_costs.reduce((acc, cur) => cur.category === 'accessory' ? acc + cur.amount : acc + 0, 0)

      if(this.cashProductType)
        this.simulation.product_value = Number(newValue.net_amount || 0) - newValue.extra_costs.reduce((acc, cur) => acc + cur.amount, 0)

      this.simulation.down_payment = Number(newValue.down_payment)
      this.simulation.down_payment_percentage = Number(newValue.down_payment_percentage)
      this.simulation.installments_amount = Number(newValue.installments_amount)
      // this.simulation.product_model_id = Number(newValue?.product_model?.id)
      // this.simulation.color_id = Number(newValue?.color?.id)
      this.sim_model_color_preferences_attributes = newValue.sim_model_color_preferences_attributes
      this.simulation.cae = Number(newValue?.cae)
      this.simulation.gross_amount = Number(newValue?.gross_amount)
      this.downPaymentType = "0"
      this.simulation.extra_costs_attributes = newValue.extra_costs

      // TODO: move this code to a method
      this.simulation.formattedInstallments = newValue.installments.map((installment) => {
        const formattedInstallment = {
          ...installment,
          due_date: this.$moment(installment.due_date).format("DD/MM/YYYY"),
          outstanding_balance: this.$options.filters.currency(installment.outstanding_balance, '$', 0, { thousandsSeparator: '.', decimalSeparator: ',' }),
          capital: this.$options.filters.currency(installment.capital, '$', 0, { thousandsSeparator: '.', decimalSeparator: ',' }),
          interest_paid: this.$options.filters.currency(installment.interest_paid, '$', 0, { thousandsSeparator: '.', decimalSeparator: ',' }),
          installment_value: this.$options.filters.currency(installment.installment_value, '$', 0, { thousandsSeparator: '.', decimalSeparator: ',' }),
        }

        if (formattedInstallment.installment_value === '$0' && formattedInstallment.period > 0) {
          formattedInstallment['_rowVariant'] = 'warning'
        }

        if (this.is_reprogrammed == 'leasing') {
          formattedInstallment.vat = this.$options.filters.currency(Number(installment.vat), '$', 0, { thousandsSeparator: '.', decimalSeparator: ',' })
        }

        return formattedInstallment
      })

      this.simulation.installments = newValue.installments.map((installment) => {
        const newInstallment = {
          ...installment,
          due_date: this.$moment(installment.due_date),
        }

        if(this.is_reprogrammed == 'leasing') {
          newInstallment.vat = Number(installment.vat)
        }

        return newInstallment
      })

      if(this.simulation.installments) {
        this.isCalculated = true
      }


      this.$emit('currentSimulationUpdated', newValue);
    },
    'simulation.payment_frequency': function() {
      this.checkSimulationDate()
    }
  },
  mounted() {
    if(this.simulationType === 'reprogram') {
      this.options = this.options.filter((o) => o.value === 'reprogram')
    } else if(this.simulationType === 'leasing') {
      this.options = this.options.filter((o) => o.value === 'leasing')
    } else {
      this.options = this.options.filter((o) => o.value !== 'reprogram')
    }
    this.checkSimulationDate()
    if(this.clientId) {
      this.setClientIncomesAverage()
    }
    if (this.cashProductType) {
      this.simulation.installments_amount = 1
      this.simulation.simulationType   = 'cash'
      this.downPaymentPercentage = 100
      }
  },
}
</script>
