import axios from 'axios'

export default {
  async atDate(date) {
    const path = `/uf/${date}.json`;

    const config = {
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      }
    }

    const response = await axios.get(path, config)
    return response
  }
}
