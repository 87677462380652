<template>
  <div>
    <div v-if="bankAccounts.length">
      <BankAccountSelector
        class="my-2"
        :bankAccounts="bankAccounts"
        :bankAccountSelected="selected"
        :hideTags="true"
        @onSelectedBankAccount="handleBankAccount"
      ></BankAccountSelector>
      <b-button v-if="hasOnSaveButtonListener" @click="handleSaveButton" variant="primary">
        Confirmar
      </b-button>
    </div>
    <div v-if="!bankAccounts.length" class="d-flex flex-column align-items-center">
      <em class="fas fa-thumbs-down fa-6x mb-4"></em>
      <h5>Este cliente no tiene cuentas registradas</h5>
    </div>
  </div>
</template>
<script>
  import BankAccountSelector from './BankAccountSelector.vue';

  export default {
    name: "transference-selector",
    components: { BankAccountSelector },
    props: {
      clientId: Number,
      selected: Number,
      bankAccounts: {
        type: Array,
        required: true
      }
    },
  computed:{
    hasOnSaveButtonListener(){
      return Boolean(this.$listeners && this.$listeners.onSaveButton)
    }
  },
  methods: {
    handleBankAccount(item) {
      this.$emit('onSelected', this.bankAccounts.find(ba => ba.bank_account_id === item));
    },
    handleSaveButton() {
      this.$emit('onSaveButton');
    }
  }
};
</script>
