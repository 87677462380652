<template>
  <div class="neutral-10 mtp-16 flex">
    <div id="input-group" class="mrp-44">
      <span class="normal-text">Selecciona la Marca/ Modelo</span>
      <div class="content-select">
        <b-form-select
          :id="`model-${productReferenceId}`"
          class="default-select mtp-12"
          style="height: 40px !important"
          v-model="selectedVehicleModelId"
          @change="updateSelectedVehicleModel"
          :options="formattedModelsList"
          value-field="id"
          text-field="description"
          required>
        </b-form-select>
        <i></i>
      </div>
    </div>
    <div id="input-group" class="disabled-container mrp-44">
      <span class="normal-text">{{t(`simulator.credit_simulator.vehicle.select`)}}</span>
      <div class="content-select">
        <select
          class="default-select mtp-14 custom-select"
          :text="t(`simulator.credit_simulator.vehicle.select`)"
          @change="itemSelected()"
          v-model="selectedInventoryItem"
        >
          <option :value="null">{{t(`simulator.credit_simulator.vehicle.select`)}}</option>
          <optgroup
            v-for="(warehouseStatus, index) in listModelsCampaign"
            :label="warehouseStatus.name"
            :key="index"
          >
            <option
              v-for="(
                inventoryItem, index
              ) in warehouseStatus.inventoryItemByPriceAndYear"
              :value="inventoryItem"
              :key="index"
            >
              {{ `${$options.filters.currency(inventoryItem.list_price, '$', 0, { thousandsSeparator: '.', decimalSeparator: ',' })} - Año ${inventoryItem.year} (${inventoryItem.items_count})`}}
              <span class="chip-label">
                [{{inventoryItem.discount_amount && (inventoryItem.discount_amount || 0) > 0 ? 'Con descuento' : 'Precio de lista'}}]
              </span>
            </option>
          </optgroup>
        </select>
        <i></i>
      </div>
    </div>
    <div id="input-group" class="disabled-container mrp-44">
      <span class="normal-text">Selecciona el color</span>
      <div class="content-select">
        <b-form-select
          :id="`color-${productReferenceId}`"
          class="default-select mtp-12"
          style="height: 40px !important; min-width: 188px;"
          v-model="selectedColorId"
          @change="updateSelectedColor"
          :options="availableColorsByModelList"
          value-field="id"
          text-field="name"
          :disabled="!showColorsList"
          required>
        </b-form-select>
        <i></i>
      </div>
      <div v-if="!showColorsList" class="opacity-div"></div>
    </div>

    <div class="flex-vertical-center remove">
      <b-button variant="outline-danger flex-vertical-center justify-content-center"
        v-bind:style="removeButtonStyle" @click="remove">
        <i class="fas fa-times"></i>
      </b-button>
    </div>
  </div>
</template>

<script>
import DropdownFilter from '../dynamics_form/DropdownFilter.vue'
import inventoryMixin from '../mixins/inventory'
import i18n from "../mixins/i18n"

export default {
  name: 'vehicle-models-list',
  components: { DropdownFilter },
  props: [
    'vehicleModelId',
    'modelIdNumber',
    'colorId',
    'productReferenceId',
    'inventoryTypeId',
    'itemSelectedStep',
    'typePrice',
    'productValue',
    'inventoryItemId'
  ],
  mixins: [ inventoryMixin, i18n ],
  data: function() {
    return {
      selectedVehicleModelId: this.vehicleModelId ? { brand_id: this.vehicleModelId.brand_id, name: this.vehicleModelId.name } : null,
      selectedModelIdNumber: this.modelIdNumber,
      selectedColorId: this.colorId,
      selectedInventoryItem: null,
      typePriceSelected: this.typePrice ? true : false,
      removeButtonStyle: {width: '37px', height: '37px'}
    }
  },
  mounted: async function() {
    await this.getAvailableModelsList(this.inventoryTypeId, this.vehicleModelId?.id)

    if (!_.isNil(this.vehicleModelId)) {
      //Set Vehicle Model
      let modelList = this.formattedModelsList
      this.selectedVehicleModelId = modelList.find(model => model?.brand_id === this.selectedVehicleModelId?.brand_id && model?.name === this.selectedVehicleModelId?.name)?.id
      await this.updateSelectedVehicleModel(this.selectedVehicleModelId, true, this.inventoryItemId)

      //Set color
      await this.getAvailableColorsByModelList(this.vehicleModelId)
      if (!_.isNil(this.colorId)) this.selectedColorId = this.colorId
      //Set Inventory Item
      const inventoryItemsList = [].concat.apply([], ((this.listModelsCampaign || []).map(group => group.inventoryItemByPriceAndYear)))
      this.selectedInventoryItem = inventoryItemsList.find(inventoryItem => {
        const { list_price, id, year, discount_description} = inventoryItem
        const { list_price: saved_list_price, id: saved_id , year: saved_year, discount_description: saved_discount_description} = this.vehicleModelId
        return list_price === saved_list_price && id === saved_id && year === saved_year && discount_description === saved_discount_description
      })

      this.itemSelected()
    }

    if (_.isEmpty(this.availableColorsByModelList))
      this.availableColorsByModelList = [{
        id: null,
        name: 'Color',
        disabled: true
      }]
  },
  methods: {
    updateSelectedVehicleModel: async function(_value, isMounting = false, inventoryItemId = null) {
      this.selectedInventoryItem = null
      if(!isMounting){
        this.$emit('vehicleModelIdSelected', {
          id: this.productReferenceId,
          modelId: null,
          model: null,
          price: Number(0),
          hasDiscount: null,
          discount_amount: null
        })
      }
      await this.getAvailableModelsByCampaign(this.typePriceSelected, this.selectedVehicleModelId, this.productValue, inventoryItemId, true)
    },
    remove: function() {
      this.$emit('removeModel', this.productReferenceId)
    },
    updateSelectedColor: function(value) {
      this.$emit('colorIdSelected', {
        id: this.productReferenceId,
        colorId: value
      })
    },
    itemSelected() {
      this.$emit('update:itemSelectedStep', true)
      this.getColorsList(this.selectedInventoryItem)
      this.$emit('vehicleModelIdSelected', {
        id: this.productReferenceId,
        modelId: this.selectedInventoryItem.id,
        model: this.selectedVehicleModelId,
        price: Number(this.selectedInventoryItem.list_price || 0),
        hasDiscount: this.selectedInventoryItem.discount_description,
        discount_amount: this.selectedInventoryItem.discount_amount
      })
    },
    getColorsList: async function (itemCampaign) {
      await this.getAvailableColorsByModelList(itemCampaign)
    }
  },
  computed: {
    formattedModelsList: function() {
      const formatted = this.availableModelsList.map((model) => {
        return {
          ...model,
          description: `${model.brand_name} - ${model.name} (${model.items_count})`,
          id: {
            brand_id: model.brand_id,
            name: model.name,
            urban_performance: model.urban_performance,
            nox_emissions: model.nox_emissions,
            gross_premium_pt: model.gross_premium_pt
          }
        }
      })
      formatted.unshift({
        id: null,
        description: 'Selecciona la Marca/Modelo',
        disabled: true
      })

      return formatted
    },
    showColorsList: function() {
      if (!_.isNil(this.colorId))
        return true
      return this.availableColorsByModelList.length > 1
    }
  },
  watch: {
    productValue(new_value, _old_value){
      this.getAvailableModelsByCampaign(this.typePriceSelected, this.selectedVehicleModelId, new_value, this.inventoryItemId, true)
    }
  }
}
</script>
<style>
@import "../../../assets/stylesheets/simulation.scss";

@media screen and (max-width: 1120px) {
  .neutral-10 {
    display: block;
  }

  .neutral-10 .mrp-44 {
    margin-top: 15px;
  }

  .neutral-10 .remove {
    justify-content: end;
  }
}
</style>

