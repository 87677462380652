<template>
  <div>
    <div class="font-weight-bold">
      <b-row>
        <b-col>
          <b-form-group
            label="Nombre"
            label-for="input-1"
          >
            <b-form-input v-model="client.first_name" />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Apellido"
            label-for="input-2"
          >
            <b-form-input v-model="client.last_name" />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            label="RUT"
            label-for="input-3"
            description="Número de RUT - Digito verificador"
          >
            <b-input-group>
              <b-form-input v-model="client.rut"/>
              <b-input-group-prepend is-text><strong>-</strong></b-input-group-prepend>
              <b-form-input v-model="client.dv" class="dv"/>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Pasaporte" label-for="input-4">
            <b-form-input v-model="client.passport" />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            label="Correo Electrónico"
            label-for="input-5"
          >
            <b-form-input v-model="client.email" />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Teléfono celular"
            label-for="input-6"
            description="Incluir prefijo telefónico. Ejemplo: 56987654321"
          >
            <b-form-input v-model="client.phone" />
          </b-form-group>
        </b-col>
      </b-row>
    </div>
    <b-button
      @click="saveGuarantor"
      variant="primary"
    >
      Guardar Datos
    </b-button>
  </div>
</template>
<script>
  import BoardMixin from '../mixins/board'
  import simulationsApi from '../../api/simulations';

  import includes from 'lodash/includes'

  export default {
    name: 'guarantor-form',
    props: ['simulationId', 'guarantor', 'isDisabled'],
    mixins: [BoardMixin],
    data: function() {
      return {
        client: {
          first_name: '',
          last_name: '',
          rut: '',
          dv: '',
          passport: '',
          phone: '',
          email: ''
        }
      }
    },
    methods: {
      saveGuarantor: async function() {
        const simulationId = this.simulationId
        const client = this.client
        const response = await simulationsApi.addGuarantor(simulationId,
          client)
        let updateData = {}
        if (includes([422, 500], response.status)) {
          updateData = {
            error: {
              message: response.data
            }
          }
        } else {
          updateData = response
        }
        this.$emit('simulationUpdated', updateData)
      }
    },
    watch: {
      guarantor: function(newValue) {
        if (newValue) {
          this.client = {...newValue}
        } else {
          this.client = {
            first_name: '',
            last_name: '',
            rut: '',
            dv: '',
            passport: '',
            phone: '',
            email: ''
          }
        }
      }
    }
  }
</script>
