import axios from "axios"
import * as humps from "humps"
const WAREHOUSE_STATUS_PATH = '/warehouse_statuses'

const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute("content")
const config = {
  headers: {
    "X-CSRF-Token": csrfToken,
  }
}

/**
 * Method that returns list of stored warehouse statuses
 * @param {Boolean} includeInactive if this param is true, the service will return all warehouse statuses, including removed
 * @returns {Promise}
 */
const getWarehouseStatuses = (includeInactive = false) => {
  const path = `${WAREHOUSE_STATUS_PATH}.json${includeInactive ? '?all=true' : ''}`;
  return axios.get(path, config).then(res => res.data);
}

/**
 * Method for create a warehouse status
 * @param {Object} warehouseStatus
 * @returns {Promise}
 */
const createWarehouseStatus = warehouseStatus => {
  const body = humps.decamelizeKeys({ warehouseStatus })
  return axios.post(WAREHOUSE_STATUS_PATH, body, config).then((res) => res.data);
}

/**
 * Method for update a warehouse status
 * @param {Object} warehouseStatus
 * @returns {Promise}
 */
const updateWarehouseStatus = warehouseStatus => {
  const path =  `${WAREHOUSE_STATUS_PATH}/${warehouseStatus['id']}`;
  const body = humps.decamelizeKeys({ warehouseStatus })
  return axios.put(path, body, config).then((res) => res.data);
}

/**
 * Method for update a warehouse status position
 * @param {Array} warehouseStatusesList
 * @returns {Promise}
 */
const updatePositions = warehouseStatusesList => {
  const path = `${WAREHOUSE_STATUS_PATH}/update_positions`;
  return axios.put(path, { warehouse_status_list: warehouseStatusesList  }, config).then((res) => res.data);
}

/**
 * Method for delete a warehouse status
 * @param {Object} warehouseStatus
 * @returns {Promise}
 */
const deleteWarehouseStatus = warehouseStatus => {
  const path = `${WAREHOUSE_STATUS_PATH}/${warehouseStatus['id']}`;
  return axios.delete(path, config).then((res) => res.data);
}



export default {
  getWarehouseStatuses,
  createWarehouseStatus,
  updateWarehouseStatus,
  updatePositions,
  deleteWarehouseStatus
};
