<template>
  <dl class="row">
    <dt class="col-5">
      Cliente:
    </dt>
    <dt class="col-7">
      <a :href="`/clients/${this.clientId}`" target="_blank">{{ clientName }}</a>
    </dt>
    <dt class="col-5">
      Persona en Pipedrive
    </dt>
    <dt class="col-7">
      <a :href="`https://migrantesf.pipedrive.com/person/${pipedrivePersonId}`" target="_blank" >
        {{ pipedrivePersonId }}
      </a>
    </dt>
    <dt class="col-5">
      Trato en Pipedrive
    </dt>
    <dt class="col-7">
      <a :href="`https://migrantesf.pipedrive.com/deal/${pipedriveDealId}`" target="_blank" >
        <badge-status :color="badgeColor">{{ pipedriveDealId }}</badge-status>
      </a>
    </dt>
  </dl>
</template>
<script>
  import BadgeStatus from '../simulations/BadgeStatus'

  export default{
    name: 'client-information',
    components: { BadgeStatus },
    props: [
      'clientId',
      'clientName',
      'pipedriveDealId',
      'pipedrivePersonId',
      'guarantors'
    ],
    computed: {
      badgeColor: function() {
        return 'teal'
      }
    }
  }
</script>
