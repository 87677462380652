<template>
  <form class="set-search" @submit.prevent="handleClick">
    <label class="position-relative label-search-input" :style="`width: ${ inputWidth }`" v-b-tooltip.hover :title="tooltipTitle">
      <i class="fas fa-search position-absolute h-100"></i>
      <input class="search-input input-default" type="text" :placeholder="`${inputPlaceholder}...`" @keyup="submit" v-model="query" />
      <span v-show="!isSearchable" class="erase-input" @click="eraseInput"><i class="far fa-times-circle"></i></span>
    </label>
    <button v-if="button" class="button button-primary search-button" type="submit" :disabled="isSearchable">{{ t(`views.search`) }}</button>
  </form>
</template>
<script>
import i18n from "../mixins/i18n"
import capitalize from "../mixins/capitalize"

export default {
  name: "searcher",
  mixins: [i18n, capitalize],
  props: {
    button: {
      type: Boolean,
      default: true
    },
    onSearch: {
      type: Function,
      default: () => {},
    },
    inputSearch: {
      type: Function,
      default: () => {}
    },
    inputWidth: {
      type: String,
      default: '245px'
    },
    tooltipTitle: {
      type: String,
      default: ''
    },
    inputPlaceholder: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      query: "",
      isSearchable: true
    };
  },
  methods: {
    handleClick: function() {
      this.$emit('onSearch', this.query)
    },
    submit: function() {
      this.isSearchable = this.query == ''
      this.$emit('inputSearch', this.query)
    },
    eraseInput: function() {
      this.query = ""
      this.submit()
      this.$emit('onSearch', this.query)
    }
  }
}
</script>
<style scoped>
  @import '../../../assets/stylesheets/searcher.scss';
</style>
