import i18n from "./i18n"
import capitalize from "../mixins/capitalize"

export default {
  mixins: [i18n, capitalize],
  data() {
    return {
      money_evaluators: ['client_incomes_average', 'gross_amount'],
      months_evaluators: ['job_continuity', 'job_seniority', 'continuous_contributions'],
    }
  },
  methods: {
    translateCriteria: function(rule) {
      if (rule.criteria)
        return this.capitalize([this.t(`admission.show_requirements.by_evaluator.${rule.evaluator}.operators.${rule.criteria.operator}`), this.translateValue(rule)].join(" ").trim())
      return null
    },
    translateValue: function(rule) {
      if (this.money_evaluators.includes(rule.evaluator)) {
        return this.toCurrency(rule.criteria.value, { precision: 0 })
      }
      if (rule.evaluator == 'down_payment') {
        return this.toPercentage(rule.criteria.value * 100, { precision: 0 })
      }
      if (rule.evaluator == 'rut_due_date') {
        if (rule.criteria.value === 'today') {
          return this.t(`admission.rut_due_date.today`)
        }
        else {
          return this.t(`admission.rut_due_date.invalid`)
        }
      }
      if (this.months_evaluators.includes(rule.evaluator)) {
        return this.t("datetime.distance_in_words.x_months", { count: rule.criteria.value })
      }
      // Use translateOptionValue instead
      return this.t(`admission.show_requirements.by_evaluator.${rule.evaluator}.value.${rule.criteria.value}`, { defaultValue: rule.criteria.value })
    },
  }
}
