<template>
  <b-sidebar id="sidebar-filter" title="Todos los filtros" v-model="viewSidebar" backdrop shadow>
    <loader ref="loader"/>
    <div class="sidebar-body">
      <div v-bind:style="{ display: nextStep ? 'block' : 'none' }">
        <p class="title t4 color-gray mtp-12">{{ t(`filter.sort_by_date`) }}</p>
        <b-form-group v-slot="{ groupSelectDateOrder }">
          <b-form-radio-group
            id="radio-group-date"
            v-model="date_order_selected"
            :options="date_options"
            :selected="stateSelected"
            :aria-describedby="groupSelectDateOrder"
            name="radio-options"
          ></b-form-radio-group>
        </b-form-group>

        <p class="title t4 color-gray mtp-12 mbp-14 mtp-34">{{ t(`activerecord.attributes.product_type.type`) }} </p>
      </div>
      <p class="normal-text" v-bind:style="{ display: !nextStep ? 'block' : 'none' }">{{ t(`filter.have_selected`) }}</p>
      <div class="mtp-14 mbp-30 options-group">
        <select-options
          ref="options_inventory_types"
          :list="inventoryTypesList"
          :items-selected.sync="selectedInventoryTypes"
          :block-selected.sync="nextStep"
          v-on:onDeselect="deselectInventoryTypes"/>
      </div>

      <button class="base-button search-button mbp-34" type="button"
        @click="nextStepEvent"
        v-bind:style="{ display: nextStep ? 'block' : 'none' }"
        :disabled="isSelectedInventoryTypes">{{ t(`views.next`) }}</button>

      <div v-if="dynamicFilterSelectedValue.length == 0">
        <button v-if="selectedInventoryTypes.length < 2"
          id="btn_advanced_filer"
          class="button-link-collapse mtp-30 mbp-30"
          v-bind:class="collapseAdvancedFiltersVisible ? 'active' : ''"
          v-b-toggle.collapse-advanced-filters
          :disabled="nextStep">
          {{ t(`filter.advanced_filters`) }}
        </button>

        <b-collapse id="collapse-advanced-filters" v-model="collapseAdvancedFiltersVisible">
          <div class="mbp-30">
            <p class="title t4 color-gray mbp-12">{{ t(`filter.select_dynamic_field`) }}</p>
            <dropdown-filter
              ref="dynamic_filter"
              :items="dynamicFiltersList.format"
              :radio-inputs="true"
              :text="t(`filter.dynamic_field`)"
              :if-cancel="false"
              v-on:onFilter="selectDynamicFilter"
              input-width="100%"/>

            <div v-if="isNullDynamicFilter">
              <div v-if="dynamic_field_type == 'input'">
                <b-form-checkbox v-if="dynamicFilterSelected.field_type.type == 'number'"
                  v-model="rangeChecked"
                  name="check-button"
                  class="mtp-14 normal-text input-switch"
                  switch>
                  {{ t(`filter.range_values`) }}
                </b-form-checkbox>
                <div class="mtp-12">
                  <input v-if="!rangeChecked" :type="dynamicFilterSelected.field_type.type" class="input-default"
                    v-model="dynamicFilterValue"
                    :placeholder="`${t(`filter.enter`)} ${dynamicFilterSelected.name}`">
                  <div v-if="isRangeChecked" class="flex">
                    <div class="width-moz-available">
                      <span class="normal-text">{{ t(`filter.from`) }}</span>
                      <input type="number" :max="dynamicFilterValue.end" class="input-default" v-model="dynamicFilterValue.init" placeholder="Ej: 0">
                    </div>
                    <div style="margin: 11px"></div>
                    <div class="width-moz-available">
                      <span class="normal-text">{{ t(`filter.to`) }}</span>
                      <input type="number" :min="dynamicFilterValue.init" class="input-default" v-model="dynamicFilterValue.end" placeholder="Ej: 0">
                    </div>
                  </div>
                </div>
              </div>
              <div v-else-if="dynamic_field_type == 'date'" class="form-group flex justify-content-between">
                <div class="width-moz-available">
                  <span class="normal-text color-gray">{{ t(`views.date_start`) }}</span>
                  <b-form-datepicker class="mtp-12"
                    v-model="dynamicFilterValue.init"
                    :min="setInitDateMin()"
                    :max="limitEndDate"
                    placeholder="dd/mm/aaaa"
                    :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                    locale="es">
                  </b-form-datepicker>
                </div>
                <div style="margin: 11px"></div>
                <div class="width-moz-available">
                  <span class="normal-text color-gray">{{ t(`views.date_end`) }}</span>
                  <b-form-datepicker class="mtp-12"
                    v-model="dynamicFilterValue.end"
                    :min="limitInitDate"
                    :max="setEndDateMax()"
                    placeholder="dd/mm/aaaa"
                    :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                    locale="es">
                  </b-form-datepicker>
                </div>
              </div>
              <b-form-radio-group v-else-if="dynamic_field_type == 'select'"
                class="mtp-12"
                v-model="dynamicFilterValue"
                :options="dynamicFilterSelected.field_options"
                name="radio-options"
              ></b-form-radio-group>
              <b-form-checkbox-group v-else-if="dynamic_field_type == 'checkbox'"
                class="mtp-12"
                v-model="dynamicFilterValue"
                :options="dynamicFilterSelected.field_options"
              ></b-form-checkbox-group>

              <button class="base-button search-button mtp-14" type="button" :disabled="dynamicInput" @click="stepDynamicValue">{{ t(`views.next`) }}</button>
            </div>
          </div>
        </b-collapse>
      </div>

      <div v-if="dynamicFilterSelectedValue.length > 0" class="mbp-30">
        <span class="normal-text">Campo dinámico: <b>{{ dynamicFilterName }}</b></span>
          <div class="mtp-14 options-group">
          <select-options
            ref="options_dynamic_filter_selected"
            :list="dynamicFilterSelectedValue"
            v-on:onDeselect="deselectDynamicValue"/>
        </div>
      </div>

      <div class="horizontal-line"></div>

      <div v-if="brands.length == 0 && isDynamicSelected" class="text-center mtp-30">
        <span class="title t6">{{ t(`no_found_message.no_found`) }}</span>
      </div>
      <div v-else>
        <p class="title t4 color-gray mbp-12 mtp-30" v-bind:class="nextStep ? 'disabled' : ''">{{ t(`filter.select_brands`) }}</p>
        <dropdown-filter
          ref="brands_filter"
          :items="updateBrands"
          text="Marcas"
          :if-cancel="false"
          :is-disabled="nextStep"
          v-on:onFilter="brandsStepEvent"
          input-width="100%"/>

        <p class="title t4 color-gray mbp-12 mtp-30" v-bind:class="!brandsStep ? 'disabled' : ''">{{ t(`filter.select_models`) }}</p>
        <dropdown-filter
          ref="models_filter"
          :items="updateModels"
          text="Modelos"
          :if-cancel="false"
          :is-disabled="!brandsStep"
          v-on:onFilter="modelsStepEvent"
          input-width="100%"/>

        <p class="title t4 color-gray mbp-12 mtp-30" v-bind:class="!modelsStep ? 'disabled' : ''">{{ t(`filter.select_colors`) }}</p>
        <dropdown-filter
          ref="colors_filter"
          :items="updateColors"
          text="Colores"
          :if-cancel="false"
          :is-disabled="!modelsStep"
          v-on:onFilter="colorsStepEvent"
          input-width="100%"/>

        <button
          class="button-link-collapse mtp-30"
          v-bind:class="collapse_visible ? 'active' : ''"
          id="btn_more_filer"
          v-b-toggle.collapse-more-filters :disabled="!colorsStep">
          {{ t(`filter.more_filters`) }}
        </button>
        <b-collapse id="collapse-more-filters" v-model="collapse_visible">
          <p class="title t4 color-gray mbp-12 mtp-30" v-bind:class="!colorsStep ? 'disabled' : ''">{{ t(`activerecord.models.location`) }}</p>
          <dropdown-filter
            ref="locations_filter"
            :items="updateLocations"
            text="Ubicación"
            :if-cancel="false"
            :is-disabled="!colorsStep"
            v-on:onFilter="locationsStepEvent"
            :translate="false"
            input-width="100%"/>

          <p class="title t4 color-gray mbp-14 mtp-30" v-bind:class="!locationsStep ? 'disabled' : ''">{{ t(`activerecord.models.destination`) }}</p>
          <div class="mtp-14 mbp-34 options-group">
            <select-options
              ref="options_destinations"
              :list="updateDestinations"
              :items-selected.sync="selectedDestinations"
              :is-disabled="!locationsStep"
              :translate="true"/>
          </div>
        </b-collapse>
      </div>
    </div>
    <template #footer="{}">
    <div class="flex justify-content-between" style="padding: 13px 22px;">
      <button class="base-button outline mrp-24" @click="cleanFilter" :disabled="nextStep">{{ t(`views.clear_filter`) }}</button>
      <button class="base-button" :disabled="nextStep || (brands.length == 0 && isDynamicSelected)" @click="applyFilters">{{ t(`filter.apply_filter`) }}</button>
    </div>
    </template>
  </b-sidebar>
</template>
<script>
import _ from 'lodash'
import i18n from "../mixins/i18n"
import capitalize from "../mixins/capitalize"
import DropdownFilter from '../dynamics_form/DropdownFilter.vue';
import SelectOptions from '../dynamics_form/SelectOptions.vue';
import Loader from '../template/Loader.vue';
import inventoryTypes from "../../api/inventory_types";

export default {
  name: "sidebar-filter",
  components: { SelectOptions, DropdownFilter, Loader },
  mixins: [i18n, capitalize],
  props: {
    inventoryTypesList: Array,
    brands: Array,
    models: Array,
    colors: Array,
    locations: Array,
    destinations: Array,
    saveFilterParams: Function,
    cleanFilterParams: Function,
    updateDateOrder: Function,
    inventoryTypeStep: Function,
    dynamicFilterStep: Function,
    brandsStepFunction: Function,
    modelsStepFunction: Function,
    colorsStepFunction: Function,
    locationsStepFunction: Function,
    destinationsStepFunction: Function,
    filtersObject: Object,
  },
  data() {
    return {
      date_order_selected: '',
      date_options: [
        { text: 'Más pronto', value: 'DESC' },
        { text: 'Más lejano', value: 'ASC' },
      ],
      selectedInventoryTypes: [],
      selectedDestinations: [],
      nextStep: true,
      brandsStep: false,
      modelsStep: false,
      colorsStep: false,
      locationsStep: false,
      collapse_visible: false,
      collapseAdvancedFiltersVisible: false,
      viewSidebar: false,
      isApplied: false,
      filtersObjectTemporal: {},
      temporalInventory: [],
      dynamicFiltersList: {
        format: [],
      },
      dynamicFilterSelected: null,
      dynamic_field_type: null,
      rangeChecked: false,
      dynamicFilterValue: null,
      dynamicFilterSelectedValue: [],
      isDynamicSelected: false,
    }
  },
  methods: {
    nextStepEvent: async function(ite) {
      this.$refs.loader.isVisible()
      this.nextStep = false
      this.$emit('inventoryTypeStep', this.selectedInventoryTypes)
      this.dynamicFiltersList.list = await inventoryTypes.dynamicFieldsByInventoryType(this.selectedInventoryTypes[0].id)
      this.dynamicFiltersList.list = this.dynamicFiltersList.list.item_details.fields
      this.dynamicFiltersList.format = this.dynamicFiltersList.list.map((item, index) => {
        return {
          id: index,
          name: item.name
        }
      })
      this.$refs.loader.notVisible()
    },
    brandsStepEvent(list) {
      this.$refs.loader.isVisible()
      this.collapse_visible = false
      this.brandsStep = false
      this.modelsStep = this.colorsStep = this.locationsStep = false
      this.$emit('update:models', [])
      this.$emit('update:colors', [])
      this.$emit('update:locations', [])
      this.$emit('update:destinations', [])
      this.selectedDestinations = []
      if(list.length > 0){
        this.brandsStep = true
        this.$emit('brandsStepFunction', list)
      }
      this.$refs.loader.notVisible()
    },
    modelsStepEvent(list) {
      this.$refs.loader.isVisible()
      this.collapse_visible = false
      this.modelsStep = this.colorsStep = this.locationsStep = false
      this.$emit('update:colors', [])
      this.$emit('update:locations', [])
      this.$emit('update:destinations', [])
      this.selectedDestinations = []
      if(list.length > 0){
        this.modelsStep = true
        this.$emit('modelsStepFunction', list)
      }
      this.$refs.loader.notVisible()
    },
    colorsStepEvent(list) {
      this.$refs.loader.isVisible()
      this.collapse_visible = false
      this.colorsStep = this.locationsStep = false
      this.$emit('update:locations', [])
      this.$emit('update:destinations', [])
      this.selectedDestinations = []
      if(list.length > 0){
        this.colorsStep = true
        this.$emit('colorsStepFunction', list)
      }
      this.$refs.loader.notVisible()
    },
    locationsStepEvent(list) {
      this.$refs.loader.isVisible()
      this.locationsStep = false
      this.$emit('update:destinations', [])
      this.selectedDestinations = []
      if(list.length > 0){
        this.locationsStep = true
        this.$emit('locationsStepFunction', list)
      }
      this.$refs.loader.notVisible()
    },
    selectDynamicFilter(itemSelect) {
      if (!_.isNil(itemSelect[0])) {
        this.dynamicFilterSelected = this.dynamicFiltersList.list[itemSelect[0]]
        this.dynamicFilterValue = null
        this.rangeChecked = false
        this.setFilterFieldType()
      }
    },
    setFilterFieldType() {
      switch (this.dynamicFilterSelected.field_type.type) {
        case 'number':
        case 'text':
        case 'textarea':
          this.dynamic_field_type = 'input'
          break;
        case 'date':
          this.dynamicFilterValue = { init: null, end: null }
          this.dynamic_field_type = 'date'
          break;
        case 'select':
          this.dynamic_field_type = 'select'
          break;
        case 'checkbox':
          this.dynamic_field_type = 'checkbox'
          break;

        default:
          this.dynamic_field_type = 'input'
      }
    },
    applyFilters() {
      this.$emit('destinationsStepFunction', this.selectedDestinations)
      this.filtersObject.inventory_type_id = this.selectedInventoryTypes.map(i => i.id)
      this.filtersObject.brand_id = this.$refs.brands_filter.getItemsCheck().itemsCheck
      this.filtersObject.product_model_id = this.$refs.models_filter.getItemsCheck().itemsCheck
      this.filtersObject.color_id = this.$refs.colors_filter.getItemsCheck().itemsCheck
      this.filtersObject.inventory_location_id = this.$refs.locations_filter.getItemsCheck().itemsCheck
      this.filtersObjectTemporal = {... this.filtersObject}
      this.temporalInventory.selectedInventoryTypes = [... this.selectedInventoryTypes]
      this.temporalInventory.brands = this.$refs.brands_filter.getItemsCheck()
      this.temporalInventory.models = this.$refs.models_filter.getItemsCheck()
      this.temporalInventory.colors = this.$refs.colors_filter.getItemsCheck()
      this.temporalInventory.locations = this.$refs.locations_filter.getItemsCheck()
      this.temporalInventory.destinations = [... this.selectedDestinations]
      this.$emit('saveFilterParams')
      this.viewSidebar = false
      this.isApplied = true
    },
    deselectInventoryTypes() {
      this.collapse_visible = false
      this.nextStep = true
      this.selectedDestinations = []
      if(this.$refs.options_destinations !== undefined)
        this.$refs.options_destinations.updateSelectedItems([])
      this.brandsStep = this.modelsStep = this.colorsStep = this.locationsStep = false
      this.collapseAdvancedFiltersVisible = false
      this.dynamicFilterSelected = null
      this.rangeChecked = false
      this.dynamicFilterSelectedValue = []
      this.$refs.dynamic_filter?.setItemsCheck([])
      this.isDynamicSelected = false
      this.$emit('update:brands', [])
      this.$emit('update:models', [])
      this.$emit('update:colors', [])
      this.$emit('update:locations', [])
      this.$emit('update:destinations', [])
      this.$emit('update:filtersObject', {})
    },
    stepDynamicValue() {
      let selectedValue = null
      if(this.dynamic_field_type == 'date' || this.rangeChecked) {
        if(this.rangeChecked)
          if(Number(this.dynamicFilterValue.end) < Number(this.dynamicFilterValue.init))
            this.dynamicFilterValue.end = this.dynamicFilterValue.init
        this.dynamicFilterSelectedValue = [`Desde ${this.dynamicFilterValue.init}`, `Hasta ${this.dynamicFilterValue.end}`]
        selectedValue = [this.dynamicFilterValue.init, this.dynamicFilterValue.end]
        this.rangeChecked = true
      } else {
        this.dynamicFilterSelectedValue = Array.isArray(this.dynamicFilterValue) ? this.dynamicFilterValue : [this.dynamicFilterValue]
        selectedValue = [...this.dynamicFilterSelectedValue]
      }

      this.isDynamicSelected = true
      this.$emit('dynamicFilterStep', {
        id: this.dynamicFilterSelected.id,
        value: selectedValue,
        type: this.dynamicFilterSelected.field_type.type,
        range: this.rangeChecked
      })
    },
    deselectDynamicValue() {
      this.$refs.options_dynamic_filter_selected.updateSelectedItems([])
      this.dynamicFilterSelectedValue = []
      this.isDynamicSelected = false
      this.$emit('dynamicFilterStep', null)
    },
    cleanFilter() {
      this.$refs.options_inventory_types.updateSelectedItems([])
      this.deselectInventoryTypes()
      this.date_order_selected = ""
      this.$emit('updateDateOrder')
      this.$emit('cleanFilterParams')
      this.isApplied = false
    },
    sideBarVisible() {
      if(this.isApplied){
        this.deselectInventoryTypes()
        this.$emit('update:filtersObject', this.filtersObjectTemporal)
        this.$refs.options_inventory_types.updateSelectedItems(this.temporalInventory.selectedInventoryTypes)
        this.nextStep = false
        this.$emit('update:brands', this.temporalInventory.brands.items)
        this.$refs.brands_filter.setItemsCheck(this.temporalInventory.brands.itemsCheck)
        if(this.temporalInventory.brands.itemsCheck.length > 0){
          this.brandsStep = true
          this.$emit('update:models', this.temporalInventory.models.items)
          this.$refs.models_filter.setItemsCheck(this.temporalInventory.models.itemsCheck)
          if(this.temporalInventory.models.itemsCheck.length > 0){
            this.modelsStep = true
            this.$emit('update:colors', this.temporalInventory.colors.items)
            this.$refs.colors_filter.setItemsCheck(this.temporalInventory.colors.itemsCheck)
            if(this.temporalInventory.colors.itemsCheck.length > 0){
              this.colorsStep = true
              this.$emit('update:locations', this.temporalInventory.locations.items)
              this.$refs.locations_filter.setItemsCheck(this.temporalInventory.locations.itemsCheck)
              if(this.temporalInventory.locations.itemsCheck.length > 0){
                this.locationsStep = true
                this.$refs.options_destinations.updateSelectedItems(this.temporalInventory.destinations)
              }
            }
          }
        }
      }
    },
    setInitDateMin() {
      const now = new Date(2018, 0, 1)
      return now.toISOString().split('T')[0]
    },
    setEndDateMax() {
      const now = new Date()
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
      return today.toISOString().split('T')[0]
    },
  },
  computed: {
    isSelectedInventoryTypes() {
      return this.selectedInventoryTypes.length == 0
    },
    stateSelected() {
      this.$emit('updateDateOrder', this.date_order_selected)
      return this.date_order_selected
    },
    updateBrands() {
      return this.brands
    },
    updateModels() {
      return this.models
    },
    updateColors() {
      return this.colors
    },
    updateLocations() {
      return this.locations
    },
    updateDestinations() {
      return this.destinations
    },
    isNullDynamicFilter() {
      return !_.isNil(this.dynamicFilterSelected)
    },
    isRangeChecked() {
      this.dynamicFilterValue = null
      if(this.rangeChecked)
        this.dynamicFilterValue = { init: null, end: null }
      return this.rangeChecked
    },
    limitInitDate() {
      return this.dynamicFilterValue.init ?? this.setInitDateMin()
    },
    limitEndDate() {
      return this.dynamicFilterValue.end ?? this.setEndDateMax()
    },
    dynamicFilterName() {
      return this.dynamicFilterSelected?.name ?? ''
    },
    dynamicFilterSelectedValueFormat() {
      this.dynamicFilterSelectedValue.map(function(item, index, object) {
        object[index] = {
          id: item,
          name: item
        }
      })
      return this.dynamicFilterSelectedValue
    },
    dynamicInput() {
      if(typeof this.dynamicFilterValue == 'object')
        return _.isNil(this.dynamicFilterValue?.init) || this.dynamicFilterValue?.init == '' || _.isNil(this.dynamicFilterValue?.end) || this.dynamicFilterValue?.end == ''
      return _.isNil(this.dynamicFilterValue) || this.dynamicFilterValue == ''
    }
  },
}
</script>
<style>
  @import '../../../assets/stylesheets/sidebar.scss';
  .form-group {
    margin-top: 18px !important;
  }

  p.disabled {
    opacity: 0.65;
  }

  #sidebar-filter {
    max-width: 400px !important;
    width: max-content !important;
  }
</style>
