<template>
  <div>
    <loader ref="loader" />
    <div
      class="py-3 d-flex flex-row align-items-center justify-content-between"
    >
      <h6 class="m-0 font-weight-bold text-primary">
        {{ t(`${warehouseStatusI18}.menu`) }}
      </h6>

      <a
        href="javascript:void(0)"
        role="button"
        @click="callEditWarehouseStatusModal(blankWarehouseStatus)"
        class="btn text-primary"
      >
        {{ t(`${warehouseStatusI18}.add`) }}
        <i class="fa fa-plus-circle ml-1" aria-hidden="true" />
      </a>
    </div>

    <div class="mb-4">
      <table class="table b-table table-hover">
        <thead>
          <tr>
            <th scope="col"></th>
            <th scope="col">
              {{ t(`${warehouseStatusI18}.name`) }}
            </th>
            <th scope="col">
              {{ t(`${warehouseStatusI18}.active`) }}
            </th>
            <th scope="col"></th>
          </tr>
        </thead>
        <draggable
          v-model="warehouseStatusesList"
          tag="tbody"
          :preventOnFilter="false"
        >
          <tr v-for="item in data" :key="item.id" class="light-border-bottom">
            <td class="drag-column">
              <i class="fa fa-grip-vertical" aria-hidden="true" />
            </td>
            <td class="omit-drag">
              {{ item.name }}
            </td>
            <td class="omit-drag">
              {{ item.active ? t(`views.yes`) : t(`views.no`) }}
            </td>
            <td>
              <div>
                <a
                  href="javascript:void(0)"
                  role="button"
                  @click="callEditWarehouseStatusModal(item)"
                  class="btn btn-sm btn-outline-primary"
                >
                  <i class="fa fa-pen" aria-hidden="true" />
                </a>
                <a
                  href="javascript:void(0)"
                  role="button"
                  @click="callDeleteModal(item)"
                  class="btn btn-sm btn-outline-danger"
                  ><span class="fa fa-trash"></span>
                </a>
              </div>
            </td>
          </tr>
        </draggable>
      </table>
    </div>

    <b-modal
      ref="warehouse-status-modal"
      id="warehouse-status-modal"
      no-close-on-backdrop
      :title="
        warehouseStatus.id
          ? t(`${warehouseStatusI18}.edit`)
          : t(`${warehouseStatusI18}.new`)
      "
      :hide-footer="true"
    >
      <form @submit.stop.prevent="onSubmit">
        <b-form-group
          :label="t(`${warehouseStatusI18}.name`)"
          :invalid-feedback="t(`${warehouseStatusI18}.required_field`)"
          :state="nameValidation"
        >
          <b-form-input
            id="name"
            autocomplete="off"
            v-model="warehouseStatus.name"
            :placeholder="t(`${warehouseStatusI18}.name`)"
          ></b-form-input>
        </b-form-group>

        <b-form-checkbox
          id="checkboxActive"
          v-model="warehouseStatus.active"
          name="checkboxActive"
        >
          {{ t(`${warehouseStatusI18}.active`) }}
        </b-form-checkbox>
      </form>
      <template>
        <div class="actions mt-4">
          <b-button
            class="btn btn-primary"
            @click="onSave()"
            :disabled="!nameValidation"
          >
            {{ t(`${warehouseStatusI18}.save`) }}
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import i18n from "../../mixins/i18n";
import Loader from "../../template/Loader.vue";
import Alert from "../../template/Alert.vue";
import warehouseStatusApi from "../../../api/warehouse_statuses";
import draggable from "vuedraggable";
import { get, cloneDeep } from "lodash";

export default {
  name: "warehouse-status-component",
  mixins: [i18n],
  components: { Loader, Alert, draggable },
  mounted() {
    this.loadWarehouseStatuses();
  },
  data() {
    return {
      warehouseStatusI18: "activerecord.attributes.warehouse_status",
      fields: [
        {
          key: "name",
          label: this.t(`${this.warehouseStatusI18}.name`),
          sortable: true,
        },
        {
          key: "active",
          label: this.t(`${this.warehouseStatusI18}.active`),
          sortable: true,
        },
        {
          key: "content_type",
          label: "",
        },
      ],
      data: [],
      warehouseStatus: {
        active: true,
        name: "",
      },
      blankWarehouseStatus: {
        active: true,
        name: "",
      },
    };
  },
  methods: {
    loadWarehouseStatuses() {
      this.$refs.loader.isVisible();
      warehouseStatusApi.getWarehouseStatuses(true).then((data) => {
        this.data = data;
        this.$refs.loader.notVisible();
      });
    },
    initializeWarehouseStatusObject() {
      this.warehouseStatus = this.blankWarehouseStatus;
    },
    onSave() {
      if (this.warehouseStatus.id)
        this.editWarehouseStatus(this.warehouseStatus);
      else this.createWarehouseStatus();
    },
    onServiceResponse(response, { mode, defaultSuccessStatus }) {
      this.$refs.loader.notVisible();
      const status = get(response, "status", defaultSuccessStatus);
      if (status !== defaultSuccessStatus) {
        const invalidName = get(response, "message.name", []);
        const error =
          Array.isArray(invalidName) &&
          invalidName.length > 0 &&
          invalidName[0];
        let message = null;
        if (error) {
          const prefix = this.t(
            `${this.warehouseStatusI18}.warehouse_status_prefix`
          );
          message = `${prefix} ${error}`;
        }
        this.showMessage(`${mode}.error`, "danger", message);
      } else {
        this.$bvModal.hide("warehouse-status-modal");
        this.initializeWarehouseStatusObject();
        this.showMessage(`${mode}.ok`);
        this.loadWarehouseStatuses();
      }
    },
    createWarehouseStatus() {
      if (this.warehouseStatus) {
        this.$refs.loader.isVisible();
        warehouseStatusApi
          .createWarehouseStatus(this.warehouseStatus)
          .then((data) => {
            const options = {
              defaultSuccessStatus: "created",
              mode: "create",
            };
            this.onServiceResponse(data, options);
          })
          .catch((error) => {
            const message = get(error, "response.data.message", null);
            this.showMessage("create.error", "danger", message);
          });
      }
    },
    async editWarehouseStatus(warehouseStatus) {
      this.$refs.loader.isVisible();
      await this.editWarehouseStatusService(warehouseStatus);
      this.$refs.loader.notVisible();
    },
    async editWarehouseStatusService(warehouseStatus) {
      await warehouseStatusApi
        .updateWarehouseStatus(warehouseStatus)
        .then((data) => {
          const options = {
            defaultSuccessStatus: "ok",
            mode: "update",
          };
          this.onServiceResponse(data, options);
        })
        .catch((error) => {
          const message = get(error, "response.data.message", null);
          this.showMessage("update.error", "danger", message);
        });
    },
    async updateWarehouseStatusPosition(warehouseStatusesToUpdate) {
      await warehouseStatusApi
        .updatePositions(warehouseStatusesToUpdate)
        .then((_data) => {
          return;
        })
        .catch((error) => {
          const message = get(error, "response.data.message", null);
          this.showMessage("update.error", "danger", message);
          this.loadWarehouseStatuses();
        });
    },
    deleteWarehouseStatus(warehouseStatus) {
      this.$refs.loader.isVisible();
      warehouseStatusApi
        .deleteWarehouseStatus(warehouseStatus)
        .then((data) => {
          this.initializeWarehouseStatusObject();
          this.showMessage("delete.ok", "success", data.message);
          this.loadWarehouseStatuses();
        })
        .catch((error) => {
          const message = get(error, "response.data.message", null);
          this.showMessage("delete.error", "danger", message);
          this.$refs.loader.notVisible();
        });
    },
    callEditWarehouseStatusModal(warehouseStatus) {
      this.warehouseStatus = cloneDeep(warehouseStatus);
      this.$bvModal.show("warehouse-status-modal");
    },
    callDeleteModal(warehouseStatus) {
      this.warehouseStatus = cloneDeep(warehouseStatus);
      const model_name = this.t(this.warehouseStatusI18 + ".warehouse_status");
      const message = this.t("views.delete", { model_name });
      if (confirm(message)) this.deleteWarehouseStatus(warehouseStatus);
    },
    showMessage(messageType, variant = "success", customMessage = null) {
      const title = this.t(this.warehouseStatusI18 + ".responses.create.title");
      const message =
        customMessage ||
        this.t(`${this.warehouseStatusI18}.responses.${messageType}`);
      const config = {
        title,
        variant,
        solid: true,
      };
      this.$bvToast.toast(message, config);
    },
    async bulkUpdateOfWarehouseStatuses(warehouseStatusesList) {
      this.$refs.loader.isVisible();
      let warehouseStatusesToUpdate = {};
      warehouseStatusesList.map((warehouseStatus, index) => {
        warehouseStatusesToUpdate[warehouseStatus["id"]] = index;
        return null;
      });
      await this.updateWarehouseStatusPosition(warehouseStatusesToUpdate);
      this.$refs.loader.notVisible();
    },
  },
  computed: {
    nameValidation() {
      const name = this.warehouseStatus.name || "";
      return name !== "";
    },
    warehouseStatusesList: {
      get() {
        return this.data;
      },
      set(warehouseStatusesListWithNewPosition) {
        this.data = warehouseStatusesListWithNewPosition;
        this.bulkUpdateOfWarehouseStatuses(
          warehouseStatusesListWithNewPosition
        );
      },
    },
  },
};
</script>

<style scoped>
@import "../../../../assets/stylesheets/inv_warehouse_statuses.scss";
</style>
