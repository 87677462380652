<template>
  <div v-if="hasInformation">
    <cmf-data-table
      :direct-debt="directDebt"
      :indirect-debt="indirectDebt"
      :credit-lines="creditLines"
      :other-credits="otherCredits"
    />
  </div>
  <div v-else>
    <strong>{{this.noInfoMessage}}</strong>
  </div>
</template>
<script>
import CmfDataTable from "./CmfDataTable.vue"

export default {
  name: "conditional-cmf-table",
  components: {
    CmfDataTable
  },
  props: [
    'hasInformation',
    'directDebt',
    'indirectDebt',
    'creditLines',
    'otherCredits',
    'noInfoMessage'
  ]
}
</script>
