<template>
  <table class="my-2">
    <thead>
      <tr v-if="!hasGuarantors">
        <th>No se ha registrado ningún aval</th>
      </tr>
      <tr v-else>
        <th class="px-2 py-1">Nombre</th>
        <th class="px-2 py-1">RUT</th>
        <th class="px-2 py-1">Pasaporte</th>
        <th class="px-2 py-1">Teléfono</th>
        <th class="px-2 py-1">Correo de contacto</th>
        <th class="px-2 py-1">Acciones</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(guarantor) in guarantors" :key="`guarantor-${guarantor.id}`">
        <td class="px-2 py-1">
          <a :href="`/clients/${guarantor.client.id}`" target="_blank">{{ guarantor.client.name }}</a>
        </td>
        <td class="px-2 py-1">{{ guarantor.client.rut }}-{{ guarantor.client.dv }}</td>
        <td class="px-2 py-1">{{ guarantor.client.passport }}</td>
        <td class="px-2 py-1">{{ guarantor.client.phone }}</td>
        <td class="px-2 py-1">{{ guarantor.client.email }}</td>
        <td class="px-2 py-1">
          <button @click="removeGuarantor(guarantor)" class="btn btn-danger">
            Eliminar
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</template>
<script>
  import BoardMixin from '../mixins/board'
  import simulationsApi from '../../api/simulations'

  import isEmpty from 'lodash/isEmpty'
  import includes from 'lodash/includes'

  export default {
    name: 'guarantor-list',
    props: ['element'],
    mixins: [BoardMixin],
    data: function() {
      return {
        guarantors: this.element.guarantors
      }
    },
    methods: {
      removeGuarantor: async function(guarantor) {
        const response = await simulationsApi.removeGuarantor(this.element.id, guarantor.id)
        let updateData = {}
        if (includes([422, 500], response.status)) {
          updateData = {
            error: {
              message: response.data
            }
          }
        } else {
          this.guarantors = { ...response.guarantors }
          updateData = response
        }
        this.$emit('simulationUpdated', updateData)
      }
    },
    computed: {
      hasGuarantors: function() {
        return !isEmpty(this.guarantors)
      }
    },
    watch: {
      element(newValue, oldValue) {
        this.guarantors = { ...newValue.guarantors }
      }
    },
    mounted: async function () {
      const simulation = await simulationsApi.getSimulation(this.element.id)
      this.guarantors = { ...simulation.guarantors }
    }
  }
</script>
